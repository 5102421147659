import {
	ON_CLIENT_INPUT_CHANGE,
	SET_SHOW_CREATE_CLIENT_MODAL,
	SUBMIT_CLIENT,
	SUBMIT_CLIENT_SUCCESS,
	GET_CLIENTS,
	GET_CLIENTS_SUCCESS,
	SET_SHOW_CREATE_NEW_CLIENT_SUCCESS_MODAL,
	RESET_CREATE_NEW_CLIENT_FORM,
	GET_CLIENT,
	GET_CLIENT_SUCCESS,
	SET_CLIENT_LOADING,
	SEARCH_CLIENT,
	SEARCH_CLIENT_SUCCESS
} from "./actionTypes";

export const setClientLoading = (isLoading) => {
	return {
		type: SET_CLIENT_LOADING,
		payload: isLoading
	}
}

export const onInputChange = (field, value) => {
	return {
        type: ON_CLIENT_INPUT_CHANGE,
        payload: {
        	field,
        	value
        }
    }
}

export const searchClient = (query) => {
	return {
		type: SEARCH_CLIENT,
		payload: query
	}
}

export const searchClientSuccess = (clients) => {
	return {
		type: SEARCH_CLIENT_SUCCESS,
		payload: clients
	}
}

export const setShowCreateClientModal = (isVisible, type="create", editId=null, index=null) => {
	return {
		type: SET_SHOW_CREATE_CLIENT_MODAL,
		payload: {
			isVisible,
			type,
			editId,
			index
		}
	}
}

export const submitClient = (data, type) => {
	return {
		type: SUBMIT_CLIENT,
		payload: {
			data,
			type
		}
	}
}

export const submitClientSuccess = (data) => {
	return {
		type: SUBMIT_CLIENT_SUCCESS,
		payload: data
	}
}

export const setShowCreateNewClientSuccessModal = isVisible => {
	return {
		type: SET_SHOW_CREATE_NEW_CLIENT_SUCCESS_MODAL,
		payload: isVisible
	}
}

export const getClients = (pageNum=0) => {
	return {
		type: GET_CLIENTS,
		payload: pageNum
	}
}

export const getClientsSuccess = data => {
	return {
		type: GET_CLIENTS_SUCCESS,
		payload: data
	}
}

export const resetCreateNewClientForm = () => {
	return {
		type: RESET_CREATE_NEW_CLIENT_FORM
	}
}

export const getClient = id => {
	return {
		type: GET_CLIENT,
		payload: id
	}
}

export const getClientSuccess = (id, businessType) => {
	return {
		type: GET_CLIENT_SUCCESS,
		payload: {
			id,
			businessType
		}
	}
}
