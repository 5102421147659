import {
  SET_TEMPLATE_LOADING,
  ON_TEMPLATE_INPUT_CHANGE,
  MOVE_ELEMENT,
  ADD_ELEMENT_BLOCK,
  DELETE_ELEMENT,
  GET_TEMPLATES,
  GET_TEMPLATES_SUCCESS,
  GET_TEMPLATE,
  GET_TEMPLATE_SUCCESS,
  SUBMIT_TEMPLATE,
  SUBMIT_TEMPLATE_SUCCESS,
  RESET_CREATE_NEW_TEMPLATE_FORM,
  SET_SHOW_CREATE_NEW_TEMPLATE_SUCCESS_MODAL,
  CREATE_NEW_TEMPLATE_FAIL,
  SET_SHOW_CREATE_NEW_TEMPLATE_FAILED_MODAL,
  GET_TEMPLATES_CLIENT,
  GET_TEMPLATES_CLIENTS_SUCCESS,
} from "./actionTypes";

const initialState = {
  loading: false,
  templates: [],
  showCreateNewTemplateSuccessModal: false,
  showCreateNewTemplateFailedModal: false,
  elementBlocks: [],
};

const template = (state = initialState, action) => {
  let newState;
  let elementBlocks;
  switch (action.type) {
    case SET_TEMPLATE_LOADING:
      return { ...state, loading: action.payload };
    case ON_TEMPLATE_INPUT_CHANGE:
      return { ...state, [action.payload.field]: action.payload.value };
    case MOVE_ELEMENT:
      elementBlocks = [...state.elementBlocks];
      let dragCode = elementBlocks[action.payload.dragIndex];
      elementBlocks.splice(action.payload.dragIndex, 1);
      elementBlocks.splice(action.payload.hoverIndex, 0, dragCode);
      return { ...state, elementBlocks };
    case ADD_ELEMENT_BLOCK:
      elementBlocks = [...state.elementBlocks, action.payload];
      return { ...state, elementBlocks };
    case DELETE_ELEMENT:
      return {
        ...state,
        elementBlocks: state.elementBlocks.filter(
          (item) => item !== action.payload
        ),
      };
    case GET_TEMPLATES:
      return { ...state, loading: true };
    case GET_TEMPLATES_SUCCESS:
      return { ...state, loading: false, templates: action.payload };
    case GET_TEMPLATE:
      return { ...state, loading: true };
    case GET_TEMPLATE_SUCCESS:
      newState = { ...state, loading: false };
      newState.templateId = action.payload._id;
      newState.templateName = action.payload.name;
      newState.templateType = action.payload.type;
      newState.clientBusinessType = action.payload.client_business_type;
      newState.businessType = action.payload.organisation_business_type;
      newState.elementBlocks = [];
      if (action.payload.type === "tnc") {
        newState.tncBlock = action.payload.template[0].tncBlock;
      } else {
        let index = 0;
        for (let item of action.payload.template) {
          newState.elementBlocks.push(index);
          newState[`element${index}Type`] = item.type;
          switch (item.type) {
            case "heading":
              newState[`element${index}Heading`] = item.heading;
              break;
            case "textBlock":
              newState[`element${index}TextBlock`] = item.textBlock;
              break;
            case "fullPageHeading":
              newState[`element${index}Heading`] = item.heading;
              newState[`element${index}Description`] = item.description;
              break;
            default:
              break;
          }
          index++;
        }
      }
      return newState;
    case SUBMIT_TEMPLATE:
      return { ...state, loading: true };
    case SUBMIT_TEMPLATE_SUCCESS:
      return {
        ...state,
        loading: false,
        showCreateNewTemplateSuccessModal: true,
      };
    case SET_SHOW_CREATE_NEW_TEMPLATE_SUCCESS_MODAL:
      return { ...state, showCreateNewTemplateSuccessModal: action.payload };
    case CREATE_NEW_TEMPLATE_FAIL:
      return (newState = {
        ...state,
        loading: false,
        showCreateNewTemplateFailedModal: true,
      });
    case SET_SHOW_CREATE_NEW_TEMPLATE_FAILED_MODAL:
      return { ...state, showCreateNewTemplateFailedModal: action.payload };
    case RESET_CREATE_NEW_TEMPLATE_FORM:
      return initialState;
    case GET_TEMPLATES_CLIENT:
      return { ...state, loading: true };
    case GET_TEMPLATES_CLIENTS_SUCCESS:
      return { ...state, loading: false, templates: action.payload };
    default:
      return state;
  }
};

export default template;
