import {
  SET_DASHBOARD_LOADING,
  GET_DASHBOARD,
  GET_DASHBOARD_SUCCESS,
  GET_FILTER_DASHBOARD_DATA,
  GET_FILTER_DASHBOARD_DATA_SUCCESS,
} from "./actionTypes";

const initialState = {
  loading: false,
  completedContracts: 0,
  pendingContracts: 0,
  draftContracts: 0,
  role: null,
  declinedContracts: 0,
};

const dashboard = (state = initialState, action) => {
  switch (action.type) {
    case SET_DASHBOARD_LOADING:
      return { ...state, loading: action.payload };
    case GET_DASHBOARD:
      return { ...state, loading: true };
    case GET_DASHBOARD_SUCCESS:
      return {
        ...state,
        loading: false,
        completedContracts: action.payload.completedContracts,
        pendingContracts: action.payload.pendingContracts,
        draftContracts: action.payload.draftContracts,
        sentQuotes: action.payload.sentQuotes,
        draftQuotes: action.payload.draftQuotes,
        pendingQuotes: action.payload.pendingQuotes,
        acceptedQuotes: action.payload.acceptedQuotes,
        declinedQuotes: action.payload.declinedQuotes,
        role: action.payload.role,
        declinedContracts: action.payload.declinedContracts,
        completedContractsData: action.payload.completedContractsData,
        pendingContractsData: action.payload.pendingContractsData,
        draftContractsData: action.payload.draftContractsData,
        sentQuotesData: action.payload.sentQuotesData,
        draftQuotesData: action.payload.draftQuotesData,
        pendingQuotesData: action.payload.pendingQuotesData,
        acceptedQuotesData: action.payload.acceptedQuotesData,
        declinedQuotesData: action.payload.declinedQuotesData,
        declinedContractsData: action.payload.declinedContractsData,
      };
      case GET_FILTER_DASHBOARD_DATA:
      return {
        ...state,
        loading: true,
        // clients: action.payload.clients,
      };

    case GET_FILTER_DASHBOARD_DATA_SUCCESS:
      return {
        ...state,
        loading:false,
        filterCompletedContracts: action.payload.completedContracts,
        filterPendingContracts: action.payload.pendingContracts,
        filterDraftContracts: action.payload.draftContracts,
        filterDeclinedContracts: action.payload.declinedContracts,
        filterSentQuotes: action.payload.sentQuotes,
        filterDraftQuotes: action.payload.draftQuotes,
        filterPendingQuotes: action.payload.pendingQuotes,
        filterAcceptedQuotes: action.payload.acceptedQuotes,
        filterDeclinedQuotes: action.payload.declinedQuotes,
      };
    default:
      return state;
  }
};

export default dashboard;
