import {
	ON_ACCESS_KEY_INPUT_CHANGE,
	GET_ACCESS_KEYS,
	GET_ACCESS_KEYS_SUCCESS,
	CREATE_ACCESS_KEY,
	CREATE_ACCESS_KEY_SUCCESS,
	SET_SHOW_CREATE_ACCESS_KEY_MODAL,
	SET_SHOW_CREATE_ACCESS_KEY_SUCCESS_MODAL,
	RESET_ACCESS_KEY_FORM
} from "./actionTypes";

const initialState = {
	loading: false,
	accessKeys: [],
	showCreateAccessKeyModal: false,
	showCreateNewAccessKeySuccessModal: false
}

const accessKeys = (state = initialState, action) => {
	let newState;
	switch (action.type) {
		case ON_ACCESS_KEY_INPUT_CHANGE:
			return {...state, [action.payload.field]: action.payload.value};
		case GET_ACCESS_KEYS:
			return {...state, loading: true}
		case GET_ACCESS_KEYS_SUCCESS:
			return {...state, loading: false, accessKeys: action.payload}
		case CREATE_ACCESS_KEY:
			return {...state, loading: true}
		case CREATE_ACCESS_KEY_SUCCESS:
			return {...state, loading: false, accessKeys: action.payload.accessKeys, showCreateNewAccessKeySuccessModal: true}
		case SET_SHOW_CREATE_ACCESS_KEY_MODAL:
			return {...state, showCreateAccessKeyModal: action.payload}
		case SET_SHOW_CREATE_ACCESS_KEY_SUCCESS_MODAL:
			return {...state, showCreateNewAccessKeySuccessModal: action.payload}
		case RESET_ACCESS_KEY_FORM:
			newState = initialState;
			newState.accessKeys = state.accessKeys;
			return newState;
		default:
			return state;
	}
}

export default accessKeys;
