import React, { Component } from "react";
import { Alert, Modal, ModalBody } from "reactstrap";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import SweetAlert from "react-bootstrap-sweetalert";
import SimpleReactValidator from "simple-react-validator";
import Loader from "./Loader";
import {
  setShowCreateCategoryModal,
  onInputChange,
  createCategory,
  updateCategory,
  setShowCreateNewCategorySuccessModal,
  setShowCreateNewCategoryFailedModal,
} from "../store/service/actions";

class CreateCategoryModal extends Component {
  constructor(props) {
    super(props);
    this.validator = new SimpleReactValidator();
    this.createNewCategory = this.createNewCategory.bind(this);
    this.editCategory = this.editCategory.bind(this);
    this.toggle = this.toggle.bind(this);
    this.clearModal = this.clearModal.bind(this);
  }
  createNewCategory() {
    const data = {
      name: this.props.createNewCategoryName,
      description: this.props.createNewCategoryDescription,
    };
    if (this.validator.allValid()) {
      this.props.createCategory(data);
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  }
  editCategory() {
    const data = {
      id: this.props.createCategoryModalEditId,
      name: this.props.createNewCategoryName,
      description: this.props.createNewCategoryDescription,
    };
    if (this.validator.allValid()) {
      this.props.updateCategory(data);
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  }
  toggle() {
    this.validator.hideMessages();
    this.props.setShowCreateCategoryModal(!this.props.showCreateCategoryModal);
  }
  clearModal() {
    this.validator.hideMessages();
    this.props.onInputChange("createNewCategoryName", "");
    this.props.onInputChange("createNewCategoryDescription", "");
    this.props.setShowCreateCategoryModal(false);
    this.props.setShowCreateNewCategorySuccessModal(false);
  }
  render() {
    const texts = {
      create: {
        header: "Create New Category",
        submitButton: "Create Category",
      },
      edit: {
        header: "Edit Category",
        submitButton: "Update Category",
      },
    };
    return (
      <React.Fragment>
        {this.props.loading && <Loader />}
        <Modal
          isOpen={this.props.showCreateCategoryModal}
          toggle={this.toggle}
          size="lg"
        >
          <ModalBody>
            <h4>{texts[this.props.createCategoryModalType].header}</h4>
            <div className="separator mb-2" />
            <div className="p-3">
              <div className="row fieldset">
                <div className="col-4 text-right">
                  <label className="fieldset-label required">
                    Category Name
                  </label>
                </div>
                <div className="col-8">
                  <input
                    onChange={(e) =>
                      this.props.onInputChange(
                        "createNewCategoryName",
                        e.target.value
                      )
                    }
                    value={this.props.createNewCategoryName || ""}
                    className="input-text"
                    type="text"
                    placeholder="Category Name"
                  />
                  {this.validator.message(
                    "Category Name",
                    this.props.createNewCategoryName,
                    "required",
                    { className: "text-danger" }
                  )}
                </div>
              </div>
              <div className="row fieldset">
                <div className="col-4 text-right">
                  <label className="fieldset-label">Category Description</label>
                </div>
                <div className="col-8">
                  <textarea
                    onChange={(e) =>
                      this.props.onInputChange(
                        "createNewCategoryDescription",
                        e.target.value
                      )
                    }
                    value={this.props.createNewCategoryDescription || ""}
                    className="input-text"
                    placeholder="Category Description"
                  ></textarea>
                </div>
              </div>
            </div>
            <div className="separator mt-3 mb-3" />
            <div className="row fieldset">
              <div className="col-12 text-right">
                <button onClick={this.toggle} className="btn">
                  Cancel
                </button>
                {this.props.createCategoryModalType === "create" && (
                  <button
                    className="btn create-item ml-2"
                    onClick={this.createNewCategory}
                  >
                    {texts[this.props.createCategoryModalType].submitButton}
                  </button>
                )}
                {this.props.createCategoryModalType === "edit" && (
                  <button
                    className="btn create-item ml-2"
                    onClick={this.editCategory}
                  >
                    {texts[this.props.createCategoryModalType].submitButton}
                  </button>
                )}
              </div>
            </div>
          </ModalBody>
        </Modal>
        {this.props.showCreateNewCategorySuccessModal && (
          <SweetAlert
            title=""
            success
            confirmBtnBsStyle="success"
            onConfirm={this.clearModal}
          >
            {this.props.createCategoryModalType === "create" && (
              <div className="mb-2 success-alert-message">
                Category <b>{this.props.createNewCategoryName || ""}</b> has
                been created successfully!
              </div>
            )}
            {this.props.createCategoryModalType === "edit" && (
              <div className="mb-2 success-alert-message">
                Category <b>{this.props.createNewCategoryName || ""}</b> has
                been updated successfully!
              </div>
            )}
            {this.props.match.path !== "/categories" && (
              <Link onClick={this.clearModal} to="/categories">
                View All Categories
              </Link>
            )}
          </SweetAlert>
        )}
        {this.props.showCreateNewCategoryFailedModal && (
          <SweetAlert
            title=""
            danger
            confirmBtnBsStyle="success"
            onConfirm={() =>
              this.props.setShowCreateNewCategoryFailedModal(false)
            }
          >{`Category with name ${this.props.createNewCategoryName} already exists!`}</SweetAlert>
        )}
      </React.Fragment>
    );
  }
}

const mapStatetoProps = (state) => {
  const {
    showCreateCategoryModal,
    createCategoryModalType,
    createCategoryModalEditId,
    createNewCategoryName,
    createNewCategoryDescription,
    showCreateNewCategorySuccessModal,
    loading,
    showCreateNewCategoryFailedModal,
  } = state.Service;
  return {
    showCreateCategoryModal,
    createCategoryModalType,
    createCategoryModalEditId,
    createNewCategoryName,
    createNewCategoryDescription,
    showCreateNewCategorySuccessModal,
    loading,
    showCreateNewCategoryFailedModal,
  };
};

export default withRouter(
  connect(mapStatetoProps, {
    setShowCreateCategoryModal,
    onInputChange,
    createCategory,
    updateCategory,
    setShowCreateNewCategorySuccessModal,
    setShowCreateNewCategoryFailedModal,
  })(CreateCategoryModal)
);
