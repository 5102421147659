import {
	SET_DELETE_LOADING,
	DELETE_OBJECT,
	DELETE_OBJECT_SUCCESS,
	SET_SHOW_CONFIRM_DELETE_MODAL,
	SET_SHOW_DELETE_OBJECT_SUCCESS_MODAL
} from "./actionTypes";

export const setDeleteLoading = (isLoading) => {
	return {
		type: SET_DELETE_LOADING,
		payload: isLoading
	}
}

export const deleteObject = (id, module) => {
	return {
		type: DELETE_OBJECT,
		payload: {
			id,
			module
		}
	}
}

export const deleteObjectSuccess = () => {
	return {
		type: DELETE_OBJECT_SUCCESS
	}
}

export const setShowConfirmDeleteModal = (isVisible, id=null, module=null) => {
	return {
		type: SET_SHOW_CONFIRM_DELETE_MODAL,
		payload: {
			isVisible,
			id,
			module
		}
	}
}

export const setShowDeleteObjectSuccessModal = isVisible => {
	return {
		type: SET_SHOW_DELETE_OBJECT_SUCCESS_MODAL,
		payload: isVisible
	}
}
