import {
	SET_SHOW_API_ERROR_MODAL,
	GET_AVAILABLE_PAYMENT_METHODS,
	GET_AVAILABLE_PAYMENT_METHODS_SUCCESS, SET_SHOW_UPGRADE_MODAL
} from "./actionTypes";

const initialState = {
	showApiErrorModal: false,
	message: "",
	showUpgradeModal: false,
	availablePaymentMethods: [],
	loading: false
}

const common = (state = initialState, action) => {
	switch (action.type) {
		case SET_SHOW_API_ERROR_MODAL:
			return {...state, showApiErrorModal: action.payload.isVisible, message: action.payload.message}
		case SET_SHOW_UPGRADE_MODAL:
			return {...state, showUpgradeModal: action.payload.isVisible}
		case GET_AVAILABLE_PAYMENT_METHODS:
			return {...state, loading: true}
		case GET_AVAILABLE_PAYMENT_METHODS_SUCCESS:
			return {...state, loading: false, availablePaymentMethods: action.payload}
		default:
			return state;
	}
}

export default common;
