import React, { Component } from "react";
import { connect } from "react-redux";
import Loader from "../../components/Loader";
import AccessKeysTable from "../../components/Settings/AccessKeysTable";
import CreateAccessKeyModal from "../../components/Settings/CreateAccessKeyModal";
import ConfirmDeleteModal from "../../components/ConfirmDeleteModal";
import DeleteSuccessModal from "../../components/DeleteSuccessModal";
import { setShowConfirmDeleteModal } from "../../store/delete/actions";
import { getAccessKeys, setShowCreateAccessKeyModal } from "../../store/accessKey/actions";
const delObject = "Access Key";

class AccessKeys extends Component {
	componentDidMount() {
		this.props.getAccessKeys();
	}
	render() {
    	return (
			<React.Fragment>
		        <div className="page-info-header">
		          <div className="container-fluid">
		            <div className="page-display-title">Access Keys</div>
		            <div className="text-right flex1">
		            	<button onClick={() => this.props.setShowCreateAccessKeyModal(true)} className="btn btn-success create-item" disabled><i className="ion ion-md-add-circle"></i> <span>Create Access Key</span></button>
		            </div>
		          </div>
		        </div>
		        <div className="container-fluid">
		          <div className="row">
		            <div className="col-12">
		              <div className="card cus-card">
		                <div className="card-body">
							<AccessKeysTable delete={this.props.setShowConfirmDeleteModal} accessKeys={this.props.accessKeys} />
						</div>
		              </div>
		            </div>
		          </div>
		        </div>
		        <CreateAccessKeyModal />
		        <ConfirmDeleteModal />
        		<DeleteSuccessModal delObject={delObject}/>
		        {this.props.loading && <Loader />}
			</React.Fragment>
    	);
	}
}

const mapStateToProps = state => {
	const { accessKeys, loading } = state.AccessKey;
	return { accessKeys, loading };
}

export default connect(mapStateToProps, {getAccessKeys, setShowCreateAccessKeyModal, setShowConfirmDeleteModal})(AccessKeys);
