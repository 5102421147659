import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import {
  onInputChange,
  submitEmailTemplate,
  resetCreateNewEmailTemplateForm,
  getEmailTemplate,
  setShowCreateNewEmailTemplateSuccessModal,
  uploadEmailTemplate,
  deleteEmailTemplate,
  setShowCreateNewEmailFailedModal,
} from "../store/emailTemplate/actions";
import SweetAlert from "react-bootstrap-sweetalert";
import Select from "react-select";
import { getFirebaseBackend } from "../helpers/authUtils";
import MyEditor from "../components/MyEditor";
import Loader from "../components/Loader";
import AccountantVariables from "../components/Template/VariableHelper/AccountantVariables";
import { getValidator } from "../Utils";
import { businessTypes, emailTemplateTypes } from "../config";

class CreateNewEmailTemplate extends Component {
  constructor(props) {
    super(props);
    this.userRole = getFirebaseBackend().getUserRole();
    this.cancel = this.cancel.bind(this);
    this.formatDataAndSubmitEmailTemplate =
      this.formatDataAndSubmitEmailTemplate.bind(this);
    this.validator = getValidator();
    this.deleteFile = this.deleteFile.bind(this);
    this.state = { file: null, fileUploadError: false };
  }

  onFileChange = (event) => {
    var parts = event.target.files[0].name.split(".");
    var ext = parts[parts.length - 1];
    if (this.props.emailTemplateType === "pdf" && ext.toLowerCase() !== "pdf") {
      event.target.files = null;
      event.target.value = "";
      this.setState({
        fileUploadError: true,
      });
    } else {
      this.setState({
        file: event.target.files[0],
        fileUploadError: false,
      });
      setTimeout(() => {
        this.props.uploadEmailTemplate(this.state.file);
      }, 500);
    }
  };

  cancel() {
    this.props.history.goBack();
  }

  formatDataAndSubmitEmailTemplate() {
    if (this.validator.allValid()) {
      let data = {
        name: this.props.emailTemplateName,
        type: "email-template",
        email_template_type: this.props.emailTemplateType,
        scope_type: this.userRole == 'superadmin' ? 'predefined' : 'private',
        template: [
          {
            type: "emailTemplateTextBlock",
            tncBlock: this.props.emailTemplateContent,
          },
        ],
      };
      if (this.props.fileUrl) {
        data["templateUrl"] = this.props.fileUrl;
      }
      if (this.type === "edit") {
        data.id = this.props.emailTemplateId;
      }
      this.props.submitEmailTemplate(data, this.type);
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  }
  componentDidMount() {
    this.props.resetCreateNewEmailTemplateForm();
    if (this.type === "edit") {
      this.props.getEmailTemplate(this.props.match.params.id);
      if (this.props.emailTemplateType === "pdf") {
        // this.setState({
        // 	file: this.props.fileUrl
        // });
        console.log("file:: ", this.props.fileUrl.name);
      }
    }
  }
  componentWillUnmount() {
    this.props.resetCreateNewEmailTemplateForm();
  }
  deleteFile() {
    this.setState({
      file: null,
    });
    this.props.deleteEmailTemplate();
  }

  render() {
    const templateTypes = [
      // { value: "proposal", label: "Proposal" },
      // { value: "engagement letter", label: "Engagement Letter" },
      // // { value: "reset-password", label: "Reset Password Request Template" },
      // { value: "invitation", label: "Email Invite" },
      // { value: "proposal_pdf", label: "Proposal PDF" },
      // { value: "engagement letter_viewed", label: "Engagement Letter Viewed" },
      // { value: "engagement letter_accepted", label: "Engagement Letter Accepted" },
      // { value: "engagement letter_declined", label: "Engagement Letter Declined" },
      { value: "quote", label: "Quote" },
      { value: "contract", label: "Contract" },
      // { value: "reset-password", label: "Reset Password Request Template" },
      { value: "invitation", label: "Email Invite" },
      { value: "quote_pdf", label: "Quote PDF" },
      { value: "contract_viewed", label: "Contract Viewed" },
      { value: "contract_accepted", label: "Contract Accepted" },
      { value: "contract_declined", label: "Contract Declined" },
    ];
    this.type = this.props.match.path
      .replace("/email-template/", "")
      .replace("/:id", "");
    const texts = {
      new: {
        header: "Create New Email Template",
        submitButton: "Create Template",
        actionName: "created",
      },
      edit: {
        header: "Edit Email Template",
        submitButton: "Update Template",
        actionName: "updated",
      },
    };
    return (
      <React.Fragment>
        {this.props.loading && <Loader />}
        <div className="container-fluid new-item-page-container">
          <div className="new-item-page-nav">
            <i
              onClick={this.cancel}
              className="ion ion-md-arrow-round-back back-button"
            ></i>
          </div>
          <div className="new-item-page-content">
            <div className="row form-row">
              <div className="col-12">
                <h3>{texts[this.type].header}</h3>
                <div className="separator mt-3" />
                <div className="p-3">
                  <div className="row fieldset">
                    <div className="col-4 text-right">
                      <label className="fieldset-label required">
                        Template Name
                      </label>
                    </div>
                    <div className="col-8">
                      <input
                        onChange={(e) =>
                          this.props.onInputChange(
                            "emailTemplateName",
                            e.target.value
                          )
                        }
                        value={this.props.emailTemplateName || ""}
                        className="input-text"
                        type="text"
                        placeholder="Template Name"
                      />
                      {this.validator.message(
                        "Template Name",
                        this.props.emailTemplateName,
                        "required",
                        { className: "text-danger" }
                      )}
                    </div>
                  </div>
                  {(this.userRole === "admin" ||
                    this.userRole === "superadmin") && (
                      <div className="row fieldset">
                        <div className="col-4 text-right">
                          <label className="fieldset-label required">
                            Template Type
                          </label>
                        </div>
                        <div className="col-8">
                          <Select
                            options={templateTypes}
                            onChange={(selectedOption) => {
                              this.props.onInputChange(
                                "emailTemplateType",
                                selectedOption.value
                              );
                              this.setState({ fileUploadError: false });
                            }}
                            value={
                              this.props.emailTemplateType
                                ? templateTypes.filter(
                                  (option) =>
                                    option.value ===
                                    this.props.emailTemplateType
                                )
                                : ""
                            }
                          />
                          {this.validator.message(
                            "Template Type",
                            this.props.emailTemplateType,
                            "required",
                            { className: "text-danger" }
                          )}
                        </div>
                      </div>
                    )}
                  {/* {this.userRole === "superadmin" && (
                    <div className="row fieldset">
                      <div className="col-4 text-right">
                        <label className="fieldset-label required">
                          Organisation Business Type
                        </label>
                      </div>
                      <div className="col-8">
                        <Select
                          options={businessTypes}
                          onChange={(selectedOption) =>
                            this.props.onInputChange(
                              "businessType",
                              selectedOption.value
                            )
                          }
                          value={
                            this.props.businessType
                              ? businessTypes.filter(
                                  (option) =>
                                    option.value === this.props.businessType
                                )
                              : ""
                          }
                        />
                        {this.validator.message(
                          "Organisation Business Type",
                          this.props.businessType,
                          "required",
                          { className: "text-danger" }
                        )}
                      </div>
                    </div>
                  )} */}

                  <div className="row fieldset">
                    <div className="col-12">
                      <h4 className="mt-2">Template Content</h4>
                      <div className="separator mb-3" />
                      <div className="fieldset-group helper-variables-div">
                        <label className="fieldset-group-label">
                          Variables
                        </label>
                        <AccountantVariables
                          businessType={this.props.emailTemplateType}
                        />
                      </div>
                      {((this.props.emailTemplateId && this.type === "edit") ||
                        this.type === "new") &&
                        !this.props.loading && (
                          <MyEditor
                            value={this.props.emailTemplateContent}
                            variable={"emailTemplateContent"}
                            onChange={this.props.onInputChange}
                          />
                        )}
                      {this.validator.message(
                        "Template Content",
                        this.props.emailTemplateContent,
                        "required",
                        { className: "text-danger" }
                      )}
                    </div>
                  </div>
                </div>
                <div className="separator mt-3 mb-3" />
                <div className="row fieldset">
                  <div className="col-12 text-right">
                    <button onClick={this.cancel} className="btn">
                      Cancel
                    </button>
                    <button
                      onClick={this.formatDataAndSubmitEmailTemplate}
                      className="btn create-item"
                    >
                      {texts[this.type].submitButton}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {this.props.showCreateNewEmailTemplateSuccessModal && (
          <SweetAlert
            title=""
            success
            confirmBtnBsStyle="success"
            onConfirm={() => {
              this.props.setShowCreateNewEmailTemplateSuccessModal(false);
              this.props.history.replace("/email-template");
            }}
          >
            <div className="mb-2 success-alert-message">
              Template <b>{this.props.emailTemplateName || ""}</b> has been{" "}
              {texts[this.type].actionName} successfully!
            </div>
          </SweetAlert>
        )}
        {this.props.showCreateNewEmailFailedModal && (
          <SweetAlert
            title=""
            danger
            confirmBtnBsStyle="success"
            onConfirm={() => this.props.setShowCreateNewEmailFailedModal(false)}
          >{this.props.errorMessage}</SweetAlert>
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => state.EmailTemplate;

export default withRouter(
  connect(mapStateToProps, {
    onInputChange,
    submitEmailTemplate,
    resetCreateNewEmailTemplateForm,
    getEmailTemplate,
    setShowCreateNewEmailTemplateSuccessModal,
    uploadEmailTemplate,
    deleteEmailTemplate,
    setShowCreateNewEmailFailedModal,
  })(CreateNewEmailTemplate)
);
