import { takeEvery, fork, put, all, call } from "redux-saga/effects";
import axios from "axios";

import {
  GET_NOTIFICATIONS,
  LOAD_MORE_NOTIFICATIONS,
  UPDATE_NOTIFICATIONS_READ_STATUS,
} from "./actionTypes";

import {
  getNotificationsSuccess,
  loadMoreNotificationsSuccess,
  updateNotificationsReadStatusSuccess,
} from "./actions";
import { setShowApiErrorModal } from "../common/actions";

// AUTH related methods
import { getFirebaseBackend } from "../../helpers/authUtils";

const fireBaseBackend = getFirebaseBackend();

function* getNotifications() {
  try {
    const accessToken = yield fireBaseBackend.getAccessToken();
    const response = yield axios({
      method: "GET",
      url: `notifications`,
      headers: { authorization: `Bearer ${accessToken}` },
    });
    if (response.data.success) {
      yield put(
        getNotificationsSuccess(
          response.data.result,
          0,
          response.data?.totalCount ?? 0
        )
      );
    } else {
      // yield put(createOrganisationFailed());
    }
  } catch (error) {
    yield put(setShowApiErrorModal());
    console.log(error);
    // if (error.response && error.response.data && error.response.data.error) {
    //     yield put(loginError(error.response.data.error));
    // } else {
    //     yield put(loginError("Something went Wrong!"));
    // }
    // yield put(createOrganisationFailed());
  }
}

function* updateNotificationsReadStatus() {
  try {
    const accessToken = yield fireBaseBackend.getAccessToken();
    const response = yield axios({
      method: "GET",
      url: `update-notifications-read-status`,
      headers: { authorization: `Bearer ${accessToken}` },
    });
    if (response.data.success) {
      yield put(updateNotificationsReadStatusSuccess());
    } else {
      // yield put(createOrganisationFailed());
    }
  } catch (error) {
    yield put(setShowApiErrorModal());
    console.log(error);
    // if (error.response && error.response.data && error.response.data.error) {
    //     yield put(loginError(error.response.data.error));
    // } else {
    //     yield put(loginError("Something went Wrong!"));
    // }
    // yield put(createOrganisationFailed());
  }
}

function* loadMoreNotifications({ payload: lastId }) {
  try {
    const accessToken = yield fireBaseBackend.getAccessToken();
    const response = yield axios({
      method: "GET",
      url: `notifications/load-more?lastid=${lastId}`,
      headers: { authorization: `Bearer ${accessToken}` },
    });
    if (response.data.success) {
      yield put(loadMoreNotificationsSuccess(response.data.result));
    } else {
      // yield put(createOrganisationFailed());
    }
  } catch (error) {
    yield put(setShowApiErrorModal());
    console.log(error);
    // if (error.response && error.response.data && error.response.data.error) {
    //     yield put(loginError(error.response.data.error));
    // } else {
    //     yield put(loginError("Something went Wrong!"));
    // }
    // yield put(createOrganisationFailed());
  }
}

export function* watchGetNotifications() {
  yield takeEvery(GET_NOTIFICATIONS, getNotifications);
}

export function* watchLoadMoreNotifications() {
  yield takeEvery(LOAD_MORE_NOTIFICATIONS, loadMoreNotifications);
}

export function* watchUpdateNotificationsReadStatus() {
  yield takeEvery(
    UPDATE_NOTIFICATIONS_READ_STATUS,
    updateNotificationsReadStatus
  );
}

function* notificationSaga() {
  yield all([
    fork(watchGetNotifications),
    fork(watchLoadMoreNotifications),
    fork(watchUpdateNotificationsReadStatus),
  ]);
}

export default notificationSaga;
