import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import {
  getService,
  setServiceLoading,
  setShowCreateCategoryModal,
} from "../store/service/actions";

import { useDispatch, useSelector } from "react-redux";
import Switch from "react-switch";
import Loader from "../components/Loader";
import SweetAlert from "react-bootstrap-sweetalert";
import ConfirmDeleteModal from "../components/ConfirmDeleteModal";
import DeleteSuccessModal from "../components/DeleteSuccessModal";
import ConfirmActiveDeActiveModal from "./ConfirmActiveDeActiveModal";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const delObject = "Service";
toast.configure()
const ServiceListTable = (props) => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showActiveDeactiveModal, setShowActiveDeactiveModal] = useState(false);
  const [serviceItemState, setServiceItemState] = useState(null);
  const [open, setOpen] = useState(false);


  const servicesAffectedPackages = useSelector(
    (state) => state?.Service?.serviceAffectedPackages
  );
  const QuoteCount = useSelector(
    (state) => state?.Service?.QuoteCount
  );
  const ContractCount = useSelector(
    (state) => state?.Service?.ContractCount
  );
  const serviceActivated = () => {

    // Calling toast method by passing string
    setOpen(true)


  }
  const serviceDeactivated = () => {

    // Calling toast method by passing string
    setOpen(true)
  }
  const dispatch = useDispatch();
  useEffect(() => {
    if (serviceItemState) {
      if (servicesAffectedPackages?.length > 0 || ContractCount > 0 || QuoteCount > 0) {
        props.setShowConfirmActiveDeActiveModal(
          true,
          serviceItemState.id,
          "service"
        );
        setServiceItemState(null)

      } else {

        let serviceItems = serviceItemState;
        serviceItems["is_active"] = !serviceItems["is_active"];
        if (!serviceItems["is_active"]) {
          serviceDeactivated()
        }
        props.updateServiceActiveKey(serviceItems);
        setServiceItemState(null)
      }
    }

  }, [servicesAffectedPackages, ContractCount, QuoteCount]);
  if (
    Object.keys(props.servicesByCategory).length === 0 &&
    props.servicesByCategory.constructor === Object
  ) {
    return <div className="text-center">No Services Found!</div>;
  }

  const changeServiceStatus = async (serviceItem) => {

    try {
      setServiceItemState(serviceItem);

      if (serviceItem.is_active) {
        dispatch(setServiceLoading(true));
        dispatch(getService(serviceItem.id));

        return;
      }
      serviceItem["is_active"] = !serviceItem["is_active"];

      dispatch(setServiceLoading(true));

      serviceActivated()

      await props?.updateServiceActiveKey(serviceItem);
      setServiceItemState(null)
    } catch (error) {
      dispatch(setServiceLoading(false));

      console.log(error);
    }
  };
  const servicesItemsJsx = [];
  for (let categoryKey in props.servicesByCategory) {
    let categoryItem = props.servicesByCategory[categoryKey];

    //  if(serviceItem["is_active"] === true){
    servicesItemsJsx.push(
      <tr className="head-row" key={`c${categoryKey}`}>
        <td colSpan={4}>
          {categoryItem.categoryName.substring(0, 30)}
          {/*<i className="ion ion-md-create float-right" />*/}
        </td>
      </tr>
    );
    for (let serviceItem of categoryItem.services) {
      // if(serviceItem.is_active === true){
      servicesItemsJsx.push(

        <tr key={`s${categoryKey}${serviceItem.id}`}>

          <td>{serviceItem.name.substring(0, 50)}</td>
          <td>-</td>
          <td>-</td>
          <td className="text-right">
            <div className="d-flex align-item-center justify-content-end">
              <button
                onClick={() =>
                  props.history.push(`/services/edit/${serviceItem.id}`)
                }
                data-service-id="id-here"
                data-toggle="tooltip"
                title="Edit Service" disabled
                className="btn btn-sm btn-dark"
              >
                <i className="ion ion-md-create" />
              </button>
              {/*<button data-toggle="tooltip" title="Clone Service" className="btn btn-sm btn-dark ml-2"><i className="ion ion-ios-copy" /></button>*/}
              <button
                onClick={() => {
                  dispatch(getService(serviceItem.id));
                  props.delete(true, serviceItem.id, "service");
                }}
                data-toggle="tooltip"
                title="Delete Services" disabled
                className="btn btn-sm btn-dark ml-2"
              >
                <i className="ion ion-md-trash" />
              </button>

              <Switch
                className="ml-2" disabled
                onChange={() => {
                  changeServiceStatus(serviceItem);
                }}

                checked={serviceItem.is_active}
                height={20}
                width={45}

                offColor={"#ff0000"}
              />

            </div>
          </td>
        </tr>
      );
      // }
    }

  }


  return (
    <div className="table-rep-plugin">
      <div className="table-responsive mb-0" data-pattern="priority-columns">
        <table className="table table-striped">
          <tbody>{servicesItemsJsx}</tbody>

          {servicesAffectedPackages || ContractCount > 0 || QuoteCount > 0 ? (
            <>
              {props.showConfirmDeleteModal && (
                <ConfirmDeleteModal
                  setShowDeleteModal={setShowDeleteModal}
                  servicesAffectedPackages={servicesAffectedPackages}
                  QuoteCount={QuoteCount}
                  ContractCount={ContractCount}
                />
              )}
              {props.showDeleteObjectSuccessModal && <DeleteSuccessModal delObject={delObject} />}
              {/* {props.showConfirmActiveDeActiveModal && (
                <ConfirmActiveDeActiveModal
                  setShowActiveDeactiveModal={setShowActiveDeactiveModal}
                  servicesAffectedPackages={servicesAffectedPackages}
                />
              )} */}
            </>
          ) : null}
          {servicesAffectedPackages || ContractCount > 0 || QuoteCount > 0 ? (
            <>
              {props.showConfirmActiveDeActiveModal && (
                <ConfirmActiveDeActiveModal
                  setShowActiveDeactiveModal={setShowActiveDeactiveModal}
                  servicesAffectedPackages={servicesAffectedPackages}
                  QuoteCount={QuoteCount}
                  ContractCount={ContractCount}
                />
              )}
            </>
          ) : null}

          {/* {servicesAffectedPackages && (
            <>
              {props.showConfirmActiveDeActiveModal && (
                <ConfirmActiveDeActiveModal
                  setShowActiveDeactiveModal={setShowActiveDeactiveModal}
                  servicesAffectedPackages={servicesAffectedPackages}
                />
              )}
            </>
          )} */}
        </table>
      </div>
      {open && (<SweetAlert
        title=""
        success
        confirmBtnBsStyle="success"
        onConfirm={() => { setOpen(false) }}
      >
        <div className="mb-2 success-alert-message">Service has been updated successfully.</div>
      </SweetAlert>)}
    </div>
  );
};

export default withRouter(ServiceListTable);
