import { takeEvery, fork, put, all, call } from "redux-saga/effects";
import axios from "axios";

//Account Redux states
import { ACTIVATE_USER, VALIDATE_TOKEN_ACTIVATE_USER } from "./actionTypes";
import {
  activateUserSuccessful,
  activateUserFailed,
  validateUserTokenSuccess,
  validateUserTokenFailed,
} from "./actions";
// import { setShowApiErrorModal } from '../../common/actions';

//AUTH related methods
import { getFirebaseBackend } from "../../../helpers/authUtils";

const fireBaseBackend = getFirebaseBackend();

function* activateUser({
  payload: { password, confirmPassword, token, history },
}) {
  try {
    const activateResponse = yield axios({
      method: "POST",
      url: "activate",
      data: {
        password,
        confirmPassword,
        token,
      },
    });
    if (activateResponse.data.success) {
      const firebaseToken = yield call(
        fireBaseBackend.signInWithCustomToken,
        activateResponse.data.result.accessToken
      );
      // 2. set name params in redux of confirm email page
      // 1. redirect to confirm email page
      yield put(activateUserSuccessful());
      history.push("/dashboard");
    } else {
      yield put(activateUserFailed(activateResponse.data.error));
    }
  } catch (error) {
    console.log(error);
    console.log(error.response);
    if (error.response && error.response.data && error.response.data.error) {
      yield put(activateUserFailed(error.response.data.error));
    } else {
      yield put(activateUserFailed("Something went Wrong!"));
    }
  }
}

function* validateUserToken({ payload: { token } }) {
  try {
    const activateResponse = yield axios({
      method: "POST",
      url: "validate-token-user-active",
      data: {
        token,
      },
    });
    if (activateResponse.data.success) {
      yield put(validateUserTokenSuccess());
    } else {
      yield put(validateUserTokenFailed());
    }
  } catch (error) {
    yield put(validateUserTokenFailed());
  }
}

export function* watchUserActivate() {
  yield takeEvery(ACTIVATE_USER, activateUser);
}
export function* watchValidateToken() {
  yield takeEvery(VALIDATE_TOKEN_ACTIVATE_USER, validateUserToken);
}

function* accountSaga() {
  yield all([fork(watchUserActivate), fork(watchValidateToken)]);
}

export default accountSaga;
