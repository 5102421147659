import {
	SET_ACTIVE_DEACTIVE_LOADING,
	ACTIVE_DEACTIVE_OBJECT,
	ACTIVE_DEACTIVE_OBJECT_SUCCESS,
	SET_SHOW_CONFIRM_ACTIVE_DEACTIVE_MODAL,
	SET_SHOW_ACTIVE_DEACTIVE_OBJECT_SUCCESS_MODAL
} from "./actionTypes";

export const setActiveDeActiveLoading = (isLoading) => {
	return {
		type: SET_ACTIVE_DEACTIVE_LOADING,
		payload: isLoading
	}
}

export const activeDeActiveObject = (id, module) => {
	return {
		type: ACTIVE_DEACTIVE_OBJECT,
		payload: {
			id,
			module
		}
	}
}

export const activeDeActiveObjectSuccess = () => {
	return {
		type: ACTIVE_DEACTIVE_OBJECT_SUCCESS
	}
}

export const setShowConfirmActiveDeActiveModal = (isVisible, id=null, module=null) => {
	return {
		type: SET_SHOW_CONFIRM_ACTIVE_DEACTIVE_MODAL,
		payload: {
			isVisible,
			id,
			module
		}
	}
}

export const setShowActiveDeActiveObjectSuccessModal = isVisible => {
	return {
		type: SET_SHOW_ACTIVE_DEACTIVE_OBJECT_SUCCESS_MODAL,
		payload: isVisible
	}
}
