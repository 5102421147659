import {
	GET_INVOICES_SUCCESS,
	ON_SUBSCRIPTION_PACKAGE_INPUT_CHANGE,
	SUBMIT_SUBSCRIPTION_PACKAGE,
	SUBMIT_SUBSCRIPTION_PACKAGE_SUCCESS,
	DELETE_SUBSCRIPTION_PACKAGE,
	DELETE_SUBSCRIPTION_PACKAGE_SUCCESS,
	GET_SUBSCRIPTION_PACKAGES,
	GET_SUBSCRIPTION_PACKAGES_SUCCESS,
	GET_DEFAULT_SUBSCRIPTION_PACKAGE,
	GET_DEFAULT_SUBSCRIPTION_PACKAGE_SUCCESS,
	SET_SHOW_CREATE_NEW_SUBSCRIPTION_SUCCESS_MODAL,
	SET_SHOW_CREATE_NEW_SUBSCRIPTION_PACKAGE_SUCCESS_MODAL,
	SET_SHOW_CREATE_NEW_TEMPLATE_SUCCESS_MODAL,
	CREATE_SUBSCRIPTION_PACKAGE_FAIL,
	SET_SHOW_CREATE_NEW_TEMPLATE_FAILED_MODAL,
	GET_SUBSCRIPTION_PACKAGE,
	GET_SUBSCRIPTION_PACKAGE_SUCCESS,
	TOGGLE_SUBSCRIPTION_PACKAGE,
	TOGGLE_SUBSCRIPTION_PACKAGE_SUCCESS,
	GET_INVOICES,
	SEARCH_INVOICES,
	SEARCH_INVOICES_SUCCESS,
	TOGGLE_SUBSCRIPTION_SUCCESS,
	TOGGLE_SUBSCRIPTION
} from "./actionTypes";

export const onInputChange = (field, value) => {
	return {
		type: ON_SUBSCRIPTION_PACKAGE_INPUT_CHANGE,
		payload: {
			field,
			value
		}
	}
}

export const submitSubscriptionPackageSuccess = () => {
	return {
		type: SUBMIT_SUBSCRIPTION_PACKAGE_SUCCESS
	};
};

export const getInvoices = ({limit, starting_after, page_num, period, status}) => {
	return {
		type: GET_INVOICES,
		payload: {
			limit,
			starting_after,
			page_num,
			period,
			status
		}
	}
}

export const searchInvoices = ({limit, starting_after, page_num, customer, number}) => {
	return {
		type: SEARCH_INVOICES,
		payload: {
			limit,
			starting_after,
			page_num,
			customer,
			number
		}
	}
}

export const getInvoicesSuccess = (payload) => {
	return {
		type: GET_INVOICES_SUCCESS,
		payload
	};
};

export const searchInvoicesSuccess = (payload) => {
	return {
		type: SEARCH_INVOICES_SUCCESS,
		payload
	};
};

export const setShowCreateNewSubscriptionPackageSuccessModal = (isVisible) => {
	return {
		type: SET_SHOW_CREATE_NEW_SUBSCRIPTION_PACKAGE_SUCCESS_MODAL,
		payload: isVisible,
	};
};

export const setShowCreateNewSubscriptionSuccessModal = (isVisible) => {
	return {
		type: SET_SHOW_CREATE_NEW_SUBSCRIPTION_SUCCESS_MODAL,
		payload: isVisible,
	};
};

export const createSubscriptionPackage = (payload) => {
	return {
		type: SUBMIT_SUBSCRIPTION_PACKAGE,
		payload: {
			data: payload,
			type: 'new'
		}
	}
}

export const updateSubscriptionPackage = (payload) => {
	return {
		type: SUBMIT_SUBSCRIPTION_PACKAGE,
		payload: {
			data: payload,
			type: 'edit'
		}
	}
}

export const deleteSubscriptionPackage = (payload) => {
	return {
		type: DELETE_SUBSCRIPTION_PACKAGE,
		payload: {
			data: payload,
		}
	}
}

export const deleteSubscriptionPackageSuccess = (payload) => {
	return {
		type: DELETE_SUBSCRIPTION_PACKAGE_SUCCESS,
		payload: {
			data: payload,
		}
	}
}

export const toggleSubscriptionPackage = (payload) => {
	return {
		type: TOGGLE_SUBSCRIPTION_PACKAGE,
		payload: {
			data: payload,
		}
	}
}

export const toggleSubscriptionPackageSuccess = (payload) => {
	return {
		type: TOGGLE_SUBSCRIPTION_PACKAGE_SUCCESS,
		payload: {
			data: payload,
		}
	}
}

export const toggleSubscription = (payload) => {
	return {
		type: TOGGLE_SUBSCRIPTION,
		payload: {
			data: payload,
		}
	}
}

export const toggleSubscriptionSuccess = (payload) => {
	return {
		type: TOGGLE_SUBSCRIPTION_SUCCESS,
		payload: {
			data: payload,
		}
	}
}

export const getSubscriptionPackages = (query) => {
	return {
		type: GET_SUBSCRIPTION_PACKAGES,
		payload: query
	}
}

export const getDefaultSubscriptionPackage = () => {
	return {
		type: GET_DEFAULT_SUBSCRIPTION_PACKAGE,
	}
}

export const getDefaultSubscriptionPackageSuccess = (data) => {
	return {
		type: GET_DEFAULT_SUBSCRIPTION_PACKAGE_SUCCESS,
		payload: data
	}
}

export const getSubscriptionPackage = (query) => {
	return {
		type: GET_SUBSCRIPTION_PACKAGE,
		payload: query
	}
}

export const getSubscriptionPackageSuccess = (query) => {
	return {
		type: GET_SUBSCRIPTION_PACKAGE_SUCCESS,
		payload: query
	}
}

export const getSubscriptionPackagesSuccess = data => {
	return {
		type: GET_SUBSCRIPTION_PACKAGES_SUCCESS,
		payload: data
	}
}

export const setShowCreateNewTemplateSuccessModal = isVisible => {
	return {
		type: SET_SHOW_CREATE_NEW_TEMPLATE_SUCCESS_MODAL,
		payload: isVisible
	}
}

export const createSubscriptionPackageFailed = (data) => {
	return {
	  type: CREATE_SUBSCRIPTION_PACKAGE_FAIL,
	  payload: data,
	};
  };

export const setShowCreateNewTemplateFailedModal = (isVisible) => {
	return {
	  type: SET_SHOW_CREATE_NEW_TEMPLATE_FAILED_MODAL,
	  payload: isVisible,
	};
  };
