import React from "react";

const CustomPackagesList = props => {
	let jsxRows = [];
	for (let key in props.packages) {
		jsxRows.push(
			<tr className="packaged-pricing-list-item" key={key}>
				<td>{props.packages[key].name}</td>
				<td className="text-right">
					<button onClick={() => props.editAddCustomPackageModal(key)} className="btn btn-sm btn-dark"><i className="ion ion-md-create mr-0"></i></button>
				</td>
			</tr>
		);
	}
	return (
		<div className="row">
			<div className="col-12">
				<table className="table table-hover a-la-carte-services-review-table mb-0">
					<thead>
						<tr>
							<td>Custom Packages</td>
							<td></td>
						</tr>
					</thead>
					<tbody>
						{jsxRows}
					</tbody>
				</table>
			</div>
		</div>
	);
}

export default CustomPackagesList;
