import {
	SET_TEMPLATE_LOADING,
	ON_INPUT_CHANGE,
	MOVE_ELEMENT,
	ADD_ELEMENT_BLOCK,
	DELETE_ELEMENT,
	HANDLE_PAYMENT_INTENT,
	HANDLE_SETUP_INTENT,
	GET_TEMPLATES,
	HANDLE_CARD,
	HANDLE_CARD_ACTION,
	HANDLE_CARD_SUCCESS,
	GET_TEMPLATE,
	GET_TEMPLATE_SUCCESS,
	CANCEL_SUBSCRIPTION,
	CANCEL_SUBSCRIPTION_SUCCESS,
	SUBMIT_TEMPLATE,
	SUBMIT_TEMPLATE_SUCCESS,
	RESET_CREATE_NEW_TEMPLATE_FORM,
	SET_SHOW_HANDLE_CARD_SUCCESS_MODAL,
	SET_SHOW_CREATE_NEW_TEMPLATE_SUCCESS_MODAL,
	CREATE_NEW_TEMPLATE_FAIL,
	SET_SHOW_CREATE_NEW_TEMPLATE_FAILED_MODAL,
	SET_SHOW_CHANGE_SUBSCRIPTION_SUCCESS_MODAL,
	SET_SHOW_CANCEL_SUBSCRIPTION_SUCCESS_MODAL,
	GET_SUBSCRIPTION_PACKAGES_SUCCESS,
	CHANGE_SUBSCRIPTION,
	CHANGE_SUBSCRIPTION_SUCCESS
} from "./actionTypes";

const initialState = {
	loading: false,
	templates: [],
	showCreateNewTemplateSuccessModal: false,
	showCreateNewTemplateFailedModal: false,
	showChangeSubscriptionSuccessModal: false,
	showCancelSubscriptionSuccessModal: false,
	showHandleCardSuccessModal: false,
	subscriptionPackages: [],
	handleCardAction: null,
	handlePaymentIntent: null,
	handleSetupIntent: null,
	interval: 'month',
	pagination: {}
}

const template = (state = initialState, action) => {
	let newState;
	let elementBlocks;
	switch (action.type) {
		// case GET_SUBSCRIPTION_PACKAGES_SUCCESS:
		// 	console.log('action.payload - ', action.payload);
		// 	const pagination = {count: action.payload.count, page_num: action.payload.page_num, total_pages: action.payload.total_pages}
		// 	const subscriptionPackages = action.payload.count ? action.payload.subscriptionPackages : action.payload;
		// 	return {...state, loading: false, subscriptionPackages, pagination: action.payload.count ? pagination: null}
		case CHANGE_SUBSCRIPTION:
			return {...state, loading: true}
		case ON_INPUT_CHANGE:
			return {...state, [action.payload.field]: action.payload.value};
		case CHANGE_SUBSCRIPTION_SUCCESS:
			return {...state, loading: false, showChangeSubscriptionSuccessModal: true}
			// console.log('action.payload - ', action.payload);
			// if('client_secret' in action.payload) {
			// 	if(action.payload.is_setup) {
			// 		newState.handleSetupIntent = action.payload.client_secret;
			// 	} else {
			// 		newState.handlePaymentIntent = action.payload.client_secret;
			// 	}
			// }
			// return newState;
		case HANDLE_CARD:
			return {...state, loading: true}
		case HANDLE_CARD_ACTION:
			return {...state, loading: true, handleCardAction: action.payload.setupIntent.client_secret}
		case HANDLE_PAYMENT_INTENT:
			console.log('action.payload - ', action.payload);
			return {...state, loading: true, handlePaymentIntent: action.payload.clientSecret}
		case HANDLE_SETUP_INTENT:
			return {...state, loading: true, handleSetupIntent: action.payload.clientSecret}
		case HANDLE_CARD_SUCCESS:
			return {...state, loading: false, showHandleCardSuccessModal: true}
		case SET_SHOW_HANDLE_CARD_SUCCESS_MODAL:
			return {...state, loading: false, showHandleCardSuccessModal: action.payload}
		case CANCEL_SUBSCRIPTION:
			return {...state, loading: true}
		case CANCEL_SUBSCRIPTION_SUCCESS:
			return {...state, loading: false, showCancelSubscriptionSuccessModal: true}
		case SET_SHOW_CANCEL_SUBSCRIPTION_SUCCESS_MODAL:
			return {...state, loading: false, showCancelSubscriptionSuccessModal: action.payload}
		case SET_SHOW_CHANGE_SUBSCRIPTION_SUCCESS_MODAL:
			return {...state, loading: false, showChangeSubscriptionSuccessModal: action.payload}
		default:
			return state;
	}
}

export default template;
