import React, { Component } from "react";
import JoditEditor from "jodit-react";
import draftToHtml from "draftjs-to-html";
import { stateFromHTML } from "draft-js-import-html";

class MyEditor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      content: this.props.value ? this.props.value : "",
    };
    this.fileInputRef = React.createRef(); // Create a ref for the file input
  }

  componentDidUpdate(prevProps) {
    if (this.props.value !== prevProps.value) {
      this.setState({
        content: this.props.value ? this.props.value : "",
      });
    }
  }

  handleEditorChange = (newContent) => {
    this.setState({
      content: newContent,
    });
    this.props.onChange(this.props.variable, newContent);
  };
  handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const imageData = reader.result;
        const imgHtml = `<img src="${imageData}" alt="Uploaded Image">`;
        this.setState(
          (prevState) => ({
            content: prevState.content + imgHtml,
          }),
          () => {
            this.props.onChange(this.props.variable, this.state.content);
          }
        );
      };
      reader.readAsDataURL(file);
    }
  };

  render() {
    return (
      <div>
        <div style={{ display: "flex", alignItems: "center" }}>
          <input
            id={`fileInput-${this.props.variable}`} // Use a unique identifier for each file input
            type="file"
            accept=".jpg, .jpeg, .png, .gif, .bmp, .tiff, .tif, .webp, .heif, .heic, .svg"
            onChange={this.handleFileChange}
            style={{ display: "none" }}
            ref={this.fileInputRef} // Assign the ref to the file input
          />
          <label
            htmlFor={`fileInput-${this.props.variable}`} // Associate the label with the corresponding file input
            style={{
              cursor: "pointer",
              marginRight: "10px",
              padding: "8px 12px",
              backgroundColor: "#007bff",
              color: "#fff",
              borderRadius: "5px",
              textAlign: "center",
            }}
          >
            Choose Image
          </label>
          <span style={{ fontSize: "14px" }}>
            (Accepted formats: JPEG, PNG, BMP, TIFF, WebP, HEIF, SVG)
          </span>
        </div>
        <JoditEditor
          value={this.state.content}
          tabIndex={1} // tabIndex of textarea
          onBlur={(newContent) => this.handleEditorChange(newContent)}
        />
      </div>
    );
  }
}
export default MyEditor;
