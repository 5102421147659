import React from 'react';
import { Badge } from 'reactstrap';
import Paginator from "./Paginator";

const renderOrganisations = (item, index) => {
	return (
		<tr key={index}>
			<td className="organisation-item">
				<span className="organisation-item-name">{item.trading_business_name}</span>
				<Badge color="dark" style={{pointerEvents:"none"}}>{item.user_count} User</Badge>
				<Badge color="dark" style={{pointerEvents:"none"}}>Pricing Plan: Free Plan</Badge>
			</td>
		</tr>
	);
}

export default (props) => {
	return (
		<div className="table-rep-plugin">
			<div className="table-responsive mb-0" data-pattern="priority-columns">
			  <table className="table table-striped">
			  	<thead>
					{props.renderHeaders ? props.renderHeaders() : <tr>
						<th>Organisations</th>
					</tr>}
			  	</thead>
			    <tbody>
			    	{props.organisations.map(props.renderOrganisations ? props.renderOrganisations : renderOrganisations)}
			    </tbody>
			  </table>
			  <Paginator
					totalPages={props.pagination.total_pages}
					pageNum={props.pagination.page_num}
					getObjects={props.getSuperAdminOrganisations}
				/>
			</div>
		</div>
	);
}

// export default withRouter(connect(null, { getSuperAdminOrganisations })(OrganisationsTable));
