import React from "react";
import Select from "react-select";
import { signatoryColumnPositions } from "../config";
import { stringifyNumber,getValidator } from "../Utils";

const ContractSignatory = props => {
	
	return (
		<div className="fieldset-group">
			<label className="fieldset-group-label">{stringifyNumber(props.index+1)} Signatory</label>
			<button onClick={() => props.delete(props.code)} className="btn btn-sm btn-danger delete-fieldset-group"><i className="ion ion-md-trash mr-1"></i>Delete Signatory</button>
			<div className="row fieldset">
				<div className="col-4">
					<label className="fieldset-label required">First Name</label>
				</div>
				<div className="col-8">
					<input value={props.firstName || ""} onChange={e => props.onInputChange(`signatory${props.code}FirstName`, e.target.value)} className="input-text" type="text" placeholder="First Name" />	
					{
						props.validator.message(
							`signatory${props.code}FirstName`,
						  props.firstName,
						  `_required|_name`,
						  { className: "text-danger" }
						)}
					
					</div>
			</div>

			<div className="row fieldset">
				<div className="col-4">
					<label className="fieldset-label required">Last Name</label>
				</div>
				<div className="col-8">
					<input value={props.lastName || ""} onChange={e => props.onInputChange(`signatory${props.code}LastName`, e.target.value)} className="input-text" type="text" placeholder="Last Name" />
					{
						props.validator.message(
							`signatory${props.code}LastName`,
						  props.lastName,
						  `_required|_name`,
						  { className: "text-danger" }
						)}
				</div>
			</div>
			<div className="row fieldset">
				<div className="col-4">
					<label className="fieldset-label required">Email</label>
				</div>
				<div className="col-8">
					<input value={props.email || ""} onChange={e => props.onInputChange(`signatory${props.code}Email`, e.target.value)} className="input-text" type="text" placeholder="Email" />
					{
						props.validator.message(
							`signatory${props.code}Email`,
						  props.email,
						  `_required|_email`,
						  { className: "text-danger" }
						)}

				</div>
			</div>
			<div className="row fieldset">
				<div className="col-4">
					<label className="fieldset-label required">Signature Position</label>
				</div>
				<div className="col-8">
					<Select
						options={signatoryColumnPositions}
						onChange={selectedOption => props.onInputChange(`signatory${props.code}SignaturePosition`, selectedOption.value)}
						value={props.signaturePosition ? signatoryColumnPositions.filter(option => option.value === props.signaturePosition) : ""}
					/>
					{
						props.validator.message(
							`signatory${props.code}SignaturePosition`,
						  props.signaturePosition,
						  `_required`,
						  { className: "text-danger" }
						)}
				</div>
			</div>
		</div>
	);
}

export default ContractSignatory
