import {
	SET_TEMPLATE_LOADING,
	ADD_ELEMENT_BLOCK,
	MOVE_ELEMENT,
	DELETE_ELEMENT,
	GET_TEMPLATES,
	GET_SUBSCRIPTION_PACKAGES,
	GET_SUBSCRIPTION_PACKAGES_SUCCESS,
	SET_SHOW_HANDLE_CARD_SUCCESS_MODAL,
	GET_TEMPLATE,
	GET_TEMPLATE_SUCCESS,
	HANDLE_PAYMENT_INTENT,
	HANDLE_CARD,
	HANDLE_CARD_SUCCESS,
	SUBMIT_TEMPLATE_SUCCESS,
	RESET_CREATE_NEW_TEMPLATE_FORM,
	SET_SHOW_CREATE_NEW_TEMPLATE_SUCCESS_MODAL,
	ADD_SIGNATORY,
	CREATE_NEW_TEMPLATE_FAIL,
	SET_SHOW_CREATE_NEW_TEMPLATE_FAILED_MODAL,
	CHANGE_SUBSCRIPTION,
	HANDLE_SETUP_INTENT,
	CHANGE_SUBSCRIPTION_SUCCESS,
	CANCEL_SUBSCRIPTION,
	CANCEL_SUBSCRIPTION_SUCCESS,
	SET_SHOW_CHANGE_SUBSCRIPTION_SUCCESS_MODAL,
	HANDLE_CARD_ACTION,
	SET_SHOW_CANCEL_SUBSCRIPTION_SUCCESS_MODAL,
	ON_INPUT_CHANGE
} from "./actionTypes";

export const setTemplateLoading = (isLoading) => {
	return {
		type: SET_TEMPLATE_LOADING,
		payload: isLoading
	}
}

export const onInputChange = (field, value) => {
	return {
		type: ON_INPUT_CHANGE,
		payload: {
			field,
			value
		}
	}
}

export const addElementBlock = code => {
	return {
		type: ADD_ELEMENT_BLOCK,
		payload: code
	}
}

export const moveElement = (dragIndex, hoverIndex) => {
	return {
		type: MOVE_ELEMENT,
		payload: {
			dragIndex,
			hoverIndex
		}
	}
}

export const deleteElement = (code) => {
	return {
		type: DELETE_ELEMENT,
		payload: code
	}
}

export const getSubscriptionPackages = (query) => {
	return {
		type: GET_SUBSCRIPTION_PACKAGES,
		payload: query
	}
}

export const getSubscriptionPackagesSuccess = data => {
	return {
		type: GET_SUBSCRIPTION_PACKAGES_SUCCESS,
		payload: data
	}
}

export const getTemplate = id => {
	return {
		type: GET_TEMPLATE,
		payload: id
	}
}

export const getTemplateSuccess = data => {
	return {
		type: GET_TEMPLATE_SUCCESS,
		payload: data
	}
}

export const changeSubscription = data => {
	return {
		type: CHANGE_SUBSCRIPTION,
		payload: data
	}
}

export const changeSubscriptionSuccess = data => {
	return {
		type: CHANGE_SUBSCRIPTION_SUCCESS,
		payload: data
	}
}

export const setShowChangeSubscriptionSuccessModal = (isVisible) => {
	return {
		type: SET_SHOW_CHANGE_SUBSCRIPTION_SUCCESS_MODAL,
		payload: isVisible,
	};
};

export const setShowHandleCardSuccessModal = (isVisible) => {
	return {
		type: SET_SHOW_HANDLE_CARD_SUCCESS_MODAL,
		payload: isVisible,
	};
};

export const handleCard = data => {
	return {
		type: HANDLE_CARD,
		payload: data
	}
}

export const handleCardSuccess = (data) => {
	return {
		type: HANDLE_CARD_SUCCESS,
		payload: data,
	};
};

export const cancelSubscription = data => {
	return {
		type: CANCEL_SUBSCRIPTION,
		payload: data
	}
}

export const cancelSubscriptionSuccess = (data) => {
	return {
		type: CANCEL_SUBSCRIPTION_SUCCESS,
		payload: data,
	};
};

export const setShowCancelSubscriptionModal = isVisible => {
	return {
		type: SET_SHOW_CANCEL_SUBSCRIPTION_SUCCESS_MODAL,
		payload: isVisible
	}
}

export const handleCardAction = (data) => {
	return {
		type: HANDLE_CARD_ACTION,
		payload: data,
	};
};

export const handlePaymentIntent = (data) => {
	return {
		type: HANDLE_PAYMENT_INTENT,
		payload: data,
	};
};

export const handleSetupIntent = (data) => {
	return {
		type: HANDLE_SETUP_INTENT,
		payload: data,
	};
};

export const submitTemplateSuccess = () => {
	return {
		type: SUBMIT_TEMPLATE_SUCCESS
	}
}

export const resetCreateNewTemplateForm = () => {
	return {
		type: RESET_CREATE_NEW_TEMPLATE_FORM
	}
}

export const setShowCreateNewTemplateSuccessModal = isVisible => {
	return {
		type: SET_SHOW_CREATE_NEW_TEMPLATE_SUCCESS_MODAL,
		payload: isVisible
	}
}

export const createTemplateFailed = (data) => {
	return {
	  type: CREATE_NEW_TEMPLATE_FAIL,
	  payload: data,
	};
  };

export const setShowCreateNewTemplateFailedModal = (isVisible) => {
	return {
	  type: SET_SHOW_CREATE_NEW_TEMPLATE_FAILED_MODAL,
	  payload: isVisible,
	};
  };
