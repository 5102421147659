import React, { Component } from "react";
import { Row, Col, Card, CardBody, Button } from "reactstrap";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";

// actions
import { acceptInvite, validateUserAcceptToken } from "../store/user/actions";

import acceptInviteIMG from "../assets/images/accept-invite.jpg";
import Loader from "../components/Loader";

class AcceptInvite extends Component {
  constructor(props) {
    super(props);
    this.validateToken = this.validateToken.bind(this);
    this.validateToken();
  }

  validateToken() {
    const params = new URLSearchParams(window.location.search);
    this.props.validateUserAcceptToken(params.get("token"));
  }

  render() {
    const params = new URLSearchParams(window.location.search);
    return !this.props.validateLoading ? (
      <React.Fragment>
        <div className="authentication-bg d-flex align-items-center pb-0 vh-100">
          <div className="content-center w-100">
            <div className="container">
              <Card className="mo-mt-2">
                <CardBody>
                  <Row className="align-items-center">
                    <Col lg="4" className="ml-auto">
                      {this.props.tokenValid ? (
                        <div className="ex-page-content">
                          {/* <h1 className="text-dark display-1 mt-4">404!</h1> */}
                          <h4 className="mb-4">
                            Yes, I would like to accept the invite
                          </h4>

                          {/* <p className="mb-5">
                                                    It will be as simple as Occidental in fact, it will be
                                                    Occidental to an English person
                        </p> */}
                          <Button
                            color="success"
                            onClick={() =>
                              this.props.acceptInvite(
                                params.get("token"),
                                this.props.history
                              )
                            }
                          >
                            Accept Invite
                          </Button>
                        </div>
                      ) : (
                        <div className="ex-page-content">
                          {/* <h1 className="text-dark display-1 mt-4">404!</h1> */}
                          <h4 className="mb-4">Link Expired</h4>
                        </div>
                      )}
                    </Col>
                    <Col lg="5" className="mx-auto">
                      <img
                        src={acceptInviteIMG}
                        alt=""
                        className="img-fluid mx-auto d-block"
                      />
                    </Col>
                  </Row>
                  <Row className="align-items-center">
                    <Col sm="12" className="text-center">
                      <p>
                        <i>
                          <strong>Note:</strong>
                          This link will expire in 60 minutes. And once accepted
                          cannot be used again
                        </i>
                      </p>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </div>
          </div>
        </div>
      </React.Fragment>
    ) : (
      <Loader />
    );
  }
}

const mapStatetoProps = (state) => {
  const { validateLoading, tokenValid } = state.User;
  return { validateLoading, tokenValid };
};

export default withRouter(
  connect(mapStatetoProps, { acceptInvite, validateUserAcceptToken })(
    AcceptInvite
  )
);
