import {
	SET_TEXT_TEMPLATE_LOADING,
	ON_TEXT_TEMPLATE_INPUT_CHANGE,
	GET_TEXT_TEMPLATES,
	GET_TEXT_TEMPLATES_SUCCESS,
	GET_TEXT_TEMPLATE,
	GET_TEXT_TEMPLATE_SUCCESS,
	SUBMIT_TEXT_TEMPLATE,
	SUBMIT_TEXT_TEMPLATE_SUCCESS,
	RESET_CREATE_NEW_TEXT_TEMPLATE_FORM
} from "./actionTypes";

const initialState = {
	loading: false,
	textTemplates: []
}

const textTemplate = (state = initialState, action) => {
	let newState;
	switch (action.type) {
		case SET_TEXT_TEMPLATE_LOADING:
			return {...state, loading: action.payload}
		case ON_TEXT_TEMPLATE_INPUT_CHANGE:
			return {...state, [action.payload.field]: action.payload.value};
		case GET_TEXT_TEMPLATES:
			return {...state, loading: true}
		case GET_TEXT_TEMPLATES_SUCCESS:
			return {...state, loading: false, textTemplates: action.payload}
		case GET_TEXT_TEMPLATE:
			return {...state, loading: true}
		case GET_TEXT_TEMPLATE_SUCCESS:
			newState = {...state, loading: false};
			newState.textTemplateId = action.payload._id;
			newState.textTemplateName = action.payload.name;
			newState.textTemplateDescription = action.payload.description;
			newState.textTemplateContent = action.payload.content;
			return newState;
		case SUBMIT_TEXT_TEMPLATE:
			return {...state, loading: true}
		case SUBMIT_TEXT_TEMPLATE_SUCCESS:
			return {...state, loading: false}
		case RESET_CREATE_NEW_TEXT_TEMPLATE_FORM:
			return initialState
		default:
			return state;
	}
}

export default textTemplate;
