import React, { Component } from "react";
import { Modal, ModalBody } from 'reactstrap';
import SimpleReactValidator from 'simple-react-validator';
import Select from "react-select";
import SweetAlert from "react-bootstrap-sweetalert";
import { connect } from "react-redux";
import { setShowEditUserSuccessModal, setShowEditUserModal, onInputChange, updateUserRole } from "../../store/user/actions";
import { userRoles } from "../../config";

class EditUserModal extends Component {
	constructor(props) {
		super(props);
		this.validator = new SimpleReactValidator();
		this.cancel = this.cancel.bind(this);
		this.toggle = this.toggle.bind(this);
		this.formatAndSubmitData = this.formatAndSubmitData.bind(this);
	}
	cancel() {
		this.props.setShowEditUserModal(false);
	}
	toggle() {
		this.props.setShowEditUserModal(!this.props.showEditUserModal);
	}
	formatAndSubmitData() {
		if (this.validator.allValid()) {
			let data = {
				email: this.props.editUserEmail,
				role: this.props.editUserRole
			}
			this.props.updateUserRole(data);
		} else {
			this.validator.showMessages();
			this.forceUpdate();
		}
	}
	render() {
		return (
			<React.Fragment>
				<Modal isOpen={this.props.showEditUserModal} toggle={this.toggle} size="lg">
					<ModalBody>
						<h4>Edit User Role</h4>
						<div className="separator mb-2" />
						<div className="p-3">
							<div className="row fieldset">
								<div className="col-2 text-right">
								    <label className="fieldset-label required">Email</label>
								</div>
								<div className="col-10">
								    <input readOnly value={this.props.editUserEmail || ""} className="input-text" type="text" placeholder="Email" />
								</div>
							</div>
							<div className="row fieldset">
								<div className="col-2 text-right">
								    <label className="fieldset-label required">User Role</label>
								</div>
								<div className="col-10">
									<Select
										options={userRoles}
										onChange={selectedOption => this.props.onInputChange("editUserRole", selectedOption.value)}
										value={this.props.editUserRole ? userRoles.filter(option => option.value === this.props.editUserRole) : ""}
									/>
									{this.validator.message('User Role', this.props.editUserRole, 'required', { className: 'text-danger' })}
								</div>
							</div>
						</div>
						<div className="separator mt-3 mb-3" />
						<div className="row fieldset">
							<div className="col-12 text-right">
								<button onClick={this.cancel} className="btn">Cancel</button>
								<button onClick={this.formatAndSubmitData} className="btn create-item ml-2">Update User Role</button>
							</div>
						</div>
					</ModalBody>
				</Modal>
				{this.props.showEditUserSuccessModal && <SweetAlert
	                title=""
	                success
	                confirmBtnBsStyle="success"
	                onConfirm={() => { this.props.setShowEditUserSuccessModal(false); this.props.setShowEditUserModal(false); }}
	            >
	                <div className="mb-2 success-alert-message">User Role has been updated successfully!</div>
	            </SweetAlert>}
			</React.Fragment>
		);
	}
}

const mapStateToProps = state => state.User;

export default connect(mapStateToProps, { setShowEditUserSuccessModal, setShowEditUserModal, onInputChange, updateUserRole })(EditUserModal);
