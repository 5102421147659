import React, {Component, useEffect, useState} from "react";
import { connect } from "react-redux";
import {debounce, formatCurrencyOfYourChoice} from "../../Utils";
import Paginator from "../../components/Paginator";
import {getMyOrganisations} from "../../store/roleManagement/actions";
import OrganisationsTable from "../../components/OrganisationsTable";
import {getSuperAdminOrganisations} from "../../store/organisation/actions";
import {
  setShowCreateNewSubscriptionPackageSuccessModal, setShowCreateNewSubscriptionSuccessModal,
  toggleSubscription
} from "../../store/subscriptionPackage/actions";
import ChooseSubscriptionPackage from "../../components/ChooseSubscriptionPackage";
import Loader from "../../components/Loader";
import SweetAlert from "react-bootstrap-sweetalert";
import {setShowChangeSubscriptionSuccessModal, onInputChange} from "../../store/subscription/actions";

function renderHeaders() {
    return (
      <tr>
          <th scope="col">Practice Name</th>
          <th scope="col">Email</th>
          <th scope="col">Number</th>
          <th scope="col">Package Name</th>
          <th scope="col">Package Price</th>
          <th scope="col">Next Renewal Date</th>
          <th scope="col">Subscription Status</th>
      </tr>
    )
}

function OrganisationItem({item, toggleSubscription}) {
  const [active, setActive] = useState(item.package?.is_active);

  useEffect(() => {
    console.log('item?.package - '. item?.package);
    if(item?.package) {
      setActive(!!item.package.is_active);
    }
  }, [item])

 return (
   <tr>
       <td>{item.trading_business_name}</td>
       <td>{item.email ?? 'dummy@email.com'}</td>
       <td>
           {item.phone ? item.phone.code + ' ' + item.phone.number : '+91 92893XXXXX'}
       </td>
       <td>
           {item.package?.plan?.name ? item.package?.plan?.name : 'Free'}
       </td>
       <td>{item.package?.plan?.fee_per_month ? formatCurrencyOfYourChoice(item.currency ?? "GBP")(item.package?.plan?.fee_per_month) : 'N/A'}</td>
       <td>{item.package?.renewal_date ? new Date(item.package?.renewal_date).toLocaleDateString() : 'N/A'}</td>
       <td>
           <div className="d-flex">
                <span>
                    {active ? 'Enable' : 'Disable'}
                </span>
               <span>
                  <div className="tg-list">
                      <div className="tg-list-item">
                        <input className="tgl tgl-light" id={item._id}
                               type="checkbox" onChange={(e) => {
                          console.log('item._id - ', item._id, item);
                          // setActive(e.target.checked);
                          item.customer_id && toggleSubscription({customer: item.customer_id, is_active: e.target.checked, uid: item._id});
                        }} checked={active}/>
                          <label className="tgl-btn"
                                 htmlFor={item._id}></label>
                      </div>
                  </div>
              </span>
           </div>

       </td>
   </tr>
 )
}

class Practice extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      uid: null
    }
    this.func = debounce(this.props.getSuperAdminOrganisations, 800);
  }
    componentDidMount() {
        this.props.getSuperAdminOrganisations();
    }
    componentDidUpdate() {
      console.log('toggled - ', this.props.showCreateNewSubscriptionSuccessModal);
    }
    toggleSubscription({customer, is_active, uid}) {
      console.log('is_active - ', is_active);
      if(!is_active) {
        return this.props.toggleSubscription({customer, is_active});
      }
      this.setState({open: true, uid, customer});
    }
    getSuperAdminOrganisationsWithQuery(page_num, status) {
      this.props.getSuperAdminOrganisations(page_num, this.state.searchOrgValue);
    }
    filterOrganisations(val) {
      this.props.onInputChange("searchOrgValue", val.target.value);
      this.setState({searchOrgValue: val.target.value});

      // this.props.getSuperAdminOrganisations(0, val.target.value);
      this.func(0, val.target.value);
      // let myOrgs = this.props.organisations.filter(function (org) {
      //   return org.trading_business_name.toLowerCase().includes(val.target.value.toLowerCase())
      // })
      // this.onChange("organisations", myOrgs);
    }
    render() {
        return (
            <React.Fragment>
              {(this.props.sub_loading || this.props.loading || this.props.package_loading) && <Loader />}
                <div className="page-info-header">
                    <div className="container-fluid">
                        <div className="page-display-title">Subscribed Practice</div>
                    </div>
                </div>
                <div className="container-fluid">
                <div style={{marginTop:"75px",marginBottom:"95px"}}>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="box-typ1">
                                <div className="box-typ2-content row">
                                    <div className="col-md-3">
                                        <div className="form-group position-relative">
                                            <input type="text" placeholder="Search Practice" value={this.props.searchOrgValue} onChange={this.filterOrganisations.bind(this)} className="form-control" />
                                            <i className="fa fa-search serchicon"></i>
                                        </div>
                                    </div>

                                    <div class="col-md-12">
                                        <OrganisationsTable getSuperAdminOrganisations={this.getSuperAdminOrganisationsWithQuery.bind(this)} renderHeaders={renderHeaders} renderOrganisations={(c) => <OrganisationItem toggleSubscription={this.toggleSubscription.bind(this)} key={c._id} item={c} />} organisations={this.props.organisations} pagination={this.props.pagination} />
                                      <ChooseSubscriptionPackage customer={this.state.customer} isSuperAdmin={true} uid={this.state.uid} elements={this.props.elements} stripe={this.props.stripe} setOpen={e => this.setState({open: e})} open={this.state.open} />
                                        {/*<div className="table-responsive">*/}
                                        {/*    <table className="table table-striped ">*/}
                                        {/*        <thead className="thead-dark ">*/}
                                        {/*            <tr>*/}
                                        {/*                <th scope="col">Practice Name</th>*/}
                                        {/*                <th scope="col">Contact Name</th>*/}
                                        {/*                <th scope="col">Email</th>*/}
                                        {/*                <th scope="col">Number</th>*/}
                                        {/*                <th scope="col">Package Name</th>*/}
                                        {/*                <th scope="col">Package Price</th>*/}
                                        {/*                <th scope="col">Next Renewal Date</th>*/}
                                        {/*                <th scope="col">Subscription Status</th>*/}
                                        {/*            </tr>*/}
                                        {/*        </thead>*/}
                                        {/*        <tbody>*/}
                                        {/*            {this.props.myOrganisations.map((item, index) => (*/}
                                        {/*              <tr>*/}
                                        {/*                  <td>{item.trading_business_name}</td>*/}
                                        {/*                  <td>User Name</td>*/}
                                        {/*                  <td>{item.email ?? 'dummy@email.com'}</td>*/}
                                        {/*                  <td>*/}
                                        {/*                      {item.phone ? item.phone.code + ' ' + item.phone.number : '+91 92893XXXXX'}*/}
                                        {/*                  </td>*/}
                                        {/*                  <td>*/}
                                        {/*                      {item.package?.plan?.name}*/}
                                        {/*                  </td>*/}
                                        {/*                  <td>{formatCurrencyOfYourChoice(item.currency ?? "GBP")(item.package?.plan?.fee_per_month)}</td>*/}
                                        {/*                  <td>{new Date(item.package?.renewal_date).toLocaleDateString()}</td>*/}
                                        {/*                  <td>*/}
                                        {/*                      <div className="d-flex">*/}
                                        {/*                    <span>*/}
                                        {/*                        Enable*/}
                                        {/*                    </span>*/}
                                        {/*                          <span>*/}
                                        {/*                        <div className="tg-list">*/}
                                        {/*                            <div className="tg-list-item">*/}
                                        {/*                                <input className="tgl tgl-light" id={item._id}*/}
                                        {/*                                       type="checkbox"/>*/}
                                        {/*                                <label className="tgl-btn"*/}
                                        {/*                                       htmlFor={item._id}></label>*/}
                                        {/*                            </div>*/}
                                        {/*                        </div>*/}
                                        {/*                    </span>*/}
                                        {/*                      </div>*/}

                                        {/*                  </td>*/}
                                        {/*              </tr>*/}
                                        {/*            ))}*/}
                                        {/*        </tbody>*/}
                                        {/*    </table>*/}
                                        {/*</div>*/}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
              {this.props.showChangeSubscriptionSuccessModal && <SweetAlert
                title=""
                success
                confirmBtnBsStyle="success"
                onConfirm={() => {
                  this.props.setShowChangeSubscriptionSuccessModal(false);
                  this.props.getSuperAdminOrganisations();
                }}
              >
                <div className="mb-2 success-alert-message">Subscription Plan has been changed successfully!</div>
              </SweetAlert>}
              {this.props.showCreateNewSubscriptionSuccessModal === 'disabled' && <SweetAlert
                title=""
                success
                confirmBtnBsStyle="success"
                onConfirm={() => {
                  this.props.setShowCreateNewSubscriptionSuccessModal(false);
                  this.getSuperAdminOrganisationsWithQuery(this.props.pagination.page_num - 1, this.state.searchOrgValue);
                }}
              >
                <div className="mb-2 success-alert-message">Subscription has been disabled successfully!</div>
              </SweetAlert>}
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    const { users } = state.User;
    const { showCreateNewSubscriptionSuccessModal, loading: package_loading } = state.SubscriptionPackage;
    const { loading: sub_loading, showChangeSubscriptionSuccessModal } = state.Subscription;
    const {organisations, pagination, loading} = state.Organisation;
    return { users, organisations, pagination, loading, sub_loading, package_loading, showChangeSubscriptionSuccessModal, showCreateNewSubscriptionSuccessModal }
}

export default connect(mapStateToProps, {getSuperAdminOrganisations, toggleSubscription, getMyOrganisations, setShowChangeSubscriptionSuccessModal, setShowCreateNewSubscriptionSuccessModal, onInputChange})(Practice);
