import React, { useState, useRef } from "react";
import {
  Modal, ModalBody
} from 'reactstrap';
import { connect } from "react-redux";
import { v4 as uuidv4 } from 'uuid';
import SelectServices from "./SelectServices";
import { getValidator } from "../../../Utils";
import { addCustomPackage, setAddCustomPackageModalServices, onInputChange } from '../../../store/quote/actions'

const AddCustomPackageModal = props => {
	console.log("add",props.addCustomPackageModalId);
	const texts = {
		"add": {
			"heading": "Add Custom Package",
			"submitText": "Add Package"
		},
		"edit": {
			"heading": "Edit Custom Package",
			"submitText": "Update Package"
		}
	}
	const validator = useRef(getValidator())
	const [, forceUpdate] = useState();
	const addService = (id) => {
		props.setAddCustomPackageModalServices([...props.addCustomPackageModalServices, id])
	}
	const removeService = (id) => {
		props.setAddCustomPackageModalServices(props.addCustomPackageModalServices.filter(item => item!==id))
	}
	const submitPackage = () => {
		if (props.addCustomPackageModalServices.length>0 && validator.current.allValid()) {
			if (props.addCustomPackageModalMode==="add") {
				props.addCustomPackage(uuidv4(), props.addCustomPackageModalName, props.addCustomPackageModalServices);
			} else if (props.addCustomPackageModalMode==="edit") {
				// console.log(props.addCustomPackageModalId, props.addCustomPackageModalName, props.addCustomPackageModalServices)
				props.addCustomPackage(props.addCustomPackageModalId, props.addCustomPackageModalName, props.addCustomPackageModalServices);
			}
		} else {
			validator.current.showMessages();
			forceUpdate(1);
		}
	}
	return (
        <Modal isOpen={props.showAddCustomPackageModal} toggle={() => props.setShowAddCustomPackageModal(!props.showAddCustomPackageModal)} size="lg">
          <ModalBody>
            <h4>{texts[props.addCustomPackageModalMode].heading}</h4>
            <div className="separator mb-2" />
            <div className="add-custom-package-content">
              <div className="row fieldset mb-2">
                <div className="col-12">
                  <label>Package Name</label>
                  <input value={props.addCustomPackageModalName} onChange={e => props.onInputChange("addCustomPackageModalName", e.target.value)} type="text" className="input-text" placeholder="Package Name" />
                  {validator.current.message('packageName', props.addCustomPackageModalName, '_required', { className: 'text-danger' })}
                </div>
              </div>
              <div className="row">
                <div className="col-6 border-right-separator">
                  <h5>Select Ongoing/Recurring Services</h5>
                  <div className="separator mb-2" />
				  <SelectServices
					servicesByCategory={props.servicesByCategory}
					chargeType="recurring"
					services={props.addCustomPackageModalServices}
					addService={addService}
					removeService={removeService}
				  />
                </div>
                <div className="col-6">
                  <h5>Select One-Off/Ad hoc Services</h5>
                  <div className="separator mb-2" />
                  <SelectServices
					servicesByCategory={props.servicesByCategory}
					chargeType="oneoff"
					services={props.addCustomPackageModalServices}
					addService={addService}
					removeService={removeService}
				  />
                </div>
              </div>
            </div>
            {validator.current.messagesShown && props.addCustomPackageModalServices.length===0 && <div className="text-danger text-center">Please select at least one service!</div>}
            <div className="add-custom-package-footer text-right">
              <button className="btn create-item" onClick={submitPackage}>{texts[props.addCustomPackageModalMode].submitText}</button>
              <button onClick={() => props.setShowAddCustomPackageModal(false)} className="btn ml-2">Cancel</button>
            </div>
          </ModalBody>
        </Modal>
	);
}

const mapStateToProps = state => {
	const { addCustomPackageModalName, addCustomPackageModalServices, addCustomPackageModalId, addCustomPackageModalMode } = state.Quote;
	return { addCustomPackageModalName, addCustomPackageModalServices, addCustomPackageModalId, addCustomPackageModalMode };
}

export default connect(mapStateToProps, { addCustomPackage, setAddCustomPackageModalServices, onInputChange })(AddCustomPackageModal);
