import React, { Component } from "react";
import SettingMenu from "../Shared/SettingMenu";
import { Row, Col, Collapse, Card, CardBody, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { Link } from "react-router-dom";
// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation";
// Redux
import { connect } from "react-redux";
// actions
import { getRoleManagementPage, setActiveRole, setRolePermission, setShowCreateRoleModal, createNewRole, saveRoleChanges, deleteRole, openDeleteRolePopup, closeDeleteRolePopup } from "../../store/actions";
import SweetAlert from "react-bootstrap-sweetalert";

import Loader from "../../components/Loader";

class RoleManagement extends Component {
  constructor(props) {
    super(props);
    this.renderRolesSelector = this.renderRolesSelector.bind(this);
    this.setActiveRole = this.setActiveRole.bind(this);
    this.renderPermissionsSelector = this.renderPermissionsSelector.bind(this);
    this.setRolePermission = this.setRolePermission.bind(this);
    this.toggleCreateRoleModal = this.toggleCreateRoleModal.bind(this);
    this.onCreateRoleSubmit = this.onCreateRoleSubmit.bind(this);
    this.saveRoleChanges = this.saveRoleChanges.bind(this);
    this.deleteRole = this.deleteRole.bind(this);
    this.openDeleteRolePopup = this.openDeleteRolePopup.bind(this);
  }
  componentDidMount() {
    this.props.getRoleManagementPage();
    this.props.setActiveRole("admin");
  }

  setActiveRole(event) {
    this.props.setActiveRole(event.target.dataset.role);
  }

  setRolePermission(event) {
    this.props.setRolePermission(event.currentTarget.dataset.permissionid, !(event.currentTarget.dataset.ispermissionchecked==="true"));
  }

  renderRolesSelector() {
    return this.props.roles.map((item, idx) => <button data-role={item.role_slug} key={idx} onClick={this.setActiveRole} className={`list-group-item list-group-item-action roles-list-item ${this.props.activeRole===item.role_slug ? 'active' : ''}`}>{item.role_name}<a data-role={item.role_slug} onClick={this.openDeleteRolePopup} className="btn btn-sm btn-danger float-right"><i className="ion delete ion-md-trash"></i></a></button>);
  }

  toggleCreateRoleModal() {
    this.props.setShowCreateRoleModal(!this.props.showCreateRoleModal);
  }

  onCreateRoleSubmit(event, values) {
    this.props.createNewRole(values.role);
  }

  saveRoleChanges() {
    this.props.saveRoleChanges(this.props.activeRole, this.props.activeRolePermissions);
  }

  openDeleteRolePopup(event) {
    event.stopPropagation();
    this.props.openDeleteRolePopup(event.currentTarget.dataset.role);
  }

  deleteRole() {
    this.props.deleteRole(this.props.deleteRolePopupSlug);
  }

  renderPermissionsSelector() {
    let jsx = [];
    for (let key in this.props.permissionModuleMap) {
      let innerJsx = [];
      for (let permission of this.props.permissionModuleMap[key].permissions) {
        let isPermissionChecked = this.props.activeRolePermissions.includes(permission.id);
        innerJsx.push(
          <Col data-ispermissionchecked={isPermissionChecked} data-permissionid={permission.id} onClick={this.setRolePermission} key={permission.id} sm={6}>
            <div className="list-group-item list-group-item-action permissions-list-item">
              <div className="permission-item-details">
                <div className="permission-item-header">{permission.permissionName}</div>
                <div className="permission-item-description">Description</div>
              </div>
              <i className={`ion ${isPermissionChecked ? "ion-md-checkmark-circle" : "ion-md-square-outline"} float-right`}></i></div>
          </Col>
        );
      }
      jsx.push(
        <div key={key} className="card mb-3">
          <div className="card-header p-3">
            <h6 className="m-0 font-14">
              <span className="text-dark heading">{this.props.permissionModuleMap[key].moduleName}</span>
            </h6>
            <div className="sub-heading">{this.props.permissionModuleMap[key].permissions.length} Permissions Available</div>
            <i className="ion ion-ios-arrow-dropdown-circle collapser"></i>
          </div>

          <Collapse isOpen={true}>
                <div className="list-group permissions-list row">
                  {/*<Col sm={12}>
                    <div className="list-group-item list-group-item-action permissions-list-item">
                      <div className="permission-item-details">
                        <div className="permission-item-header">Select All Permissions</div>
                      </div>
                      <i className="ion ion-md-square-outline float-right"></i></div>
                  </Col>*/}
                  {innerJsx}
                </div>
          </Collapse>
        </div>
      );
    }
    return jsx;
  }

  render() {
    return (
      <React.Fragment>
        <div className="page-info-header">
          <div className="container-fluid">
            <div className="page-display-title">Role Management</div>
            <div className="text-right flex1">
              <button onClick={this.saveRoleChanges} className="btn btn-success"><i className="ion ion-md-save"></i> <span>Save Changes</span></button>
            </div>
          </div>
        </div>
        <div className="container-fluid role-management-page">
          <Row>
            <Col sm={3}>
              <div className="list-group roles-list">
                {this.renderRolesSelector()}
                <button onClick={this.toggleCreateRoleModal} className="list-group-item list-group-item-action roles-list-item create-role-item"><i className="ion ion-md-add-circle"></i> <span>Create New Role</span></button>
              </div>
            </Col>
            <Col sm={9}>
              <div className="card role-management-tab-view">
                <div className="card-header">
                <ul className="nav nav-tabs">
                  {/*<li className="nav-item">
                    <a className="nav-link" href="#">Users</a>
                  </li>*/}
                  <li className="nav-item">
                    <a className="nav-link active" href="#">Permissions</a>
                  </li>
                </ul>
                </div>
                <div className="card-body">
                  <div className="permissions-accordion">
                    {this.renderPermissionsSelector()}
                  </div>
                </div>
              </div>
              <div className="float-right d-none d-md-block">
                <button onClick={this.saveRoleChanges} className="btn btn-success"><i className="ion ion-md-save"></i> <span>Save Changes</span></button>
              </div>
            </Col>
          </Row>
        </div>
        <Modal isOpen={this.props.showCreateRoleModal} toggle={this.toggleCreateRoleModal}>
          <AvForm
            className="form-horizontal"
            onValidSubmit={this.onCreateRoleSubmit}
          >
          <ModalHeader>Create Role</ModalHeader>
          <ModalBody>   
              <div className="form-group">
                <AvField
                  name="role"
                  label="Role Name"
                  className="form-control"
                  value=""
                  placeholder="Enter New Role Name"
                  type="text"
                  required
                />
              </div>
          </ModalBody>
          <ModalFooter>
            <button className="btn btn-danger" onClick={this.toggleCreateRoleModal}>Cancel</button>
            <button type="submit" className="btn btn-success">Create New Role</button>
          </ModalFooter>
          </AvForm>
        </Modal>
        {this.props.loading && <Loader/>}
        {this.props.deleteRolePopupSlug && <SweetAlert
          title="Are you sure?"
          warning
          showCancel
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={this.deleteRole}
          onCancel={this.props.closeDeleteRolePopup}
        >
          Are you sure you want to delete this role?
        </SweetAlert>}
      </React.Fragment>
    );
  }
}

const mapStatetoProps = state => {
  const { roles, activeRole, permissionModuleMap, activeRolePermissions, showCreateRoleModal, loading, deleteRolePopupSlug } = state.RoleManagement;
  return { roles, activeRole, permissionModuleMap, activeRolePermissions, showCreateRoleModal, loading, deleteRolePopupSlug };
};

export default connect(mapStatetoProps, { getRoleManagementPage, setActiveRole, setRolePermission, setShowCreateRoleModal, createNewRole, saveRoleChanges, deleteRole, openDeleteRolePopup, closeDeleteRolePopup })(RoleManagement);
