export const SET_ORGANISATION_LOADING = "SET_ORGANISATION_LOADING";
export const ON_INPUT_CHANGE = "ON_INPUT_CHANGE";
export const SET_ACTIVE_STEP = "SET_ACTIVE_STEP";
export const ADD_PARTNER = "ADD_PARTNER";
export const DELETE_PARTNER = "DELETE_PARTNER";
export const ADD_OFFICER = "ADD_OFFICER";
export const DELETE_OFFICER = "DELETE_OFFICER";
export const COMPANY_SEARCH = "COMPANY_SEARCH";
export const COMPANY_SEARCH_SUCCESS = "COMPANY_SEARCH_SUCCESS";
export const COMPANY_SEARCH_FAILED = "COMPANY_SEARCH_FAILED";
export const SELECT_COMPANY_SEARCH_ITEM = "SELECT_COMPANY_SEARCH_ITEM";
export const SELECT_COMPANY_SEARCH_ITEM_SUCCESS =
  "SELECT_COMPANY_SEARCH_ITEM_SUCCESS";
export const SELECT_COMPANY_SEARCH_ITEM_FAILED =
  "SELECT_COMPANY_SEARCH_ITEM_FAILED";
export const CONFIRM_PAYMEMT = "CONFIRM_PAYMEMT";
export const CREATE_ORGANISATION = "CREATE_ORGANISATION";
export const UPDATE_PAYMENT_METHOD = "UPDATE_PAYMENT_METHOD";
export const UPDATE_PAYMENT_METHOD_SUCCESS = "UPDATE_PAYMENT_METHOD_SUCCESS";
export const CREATE_ORGANISATION_SUCCESS = "CREATE_ORGANISATION_SUCCESS";
export const CREATE_ORGANISATION_FAILED = "CREATE_ORGANISATION_FAILED";
export const RESET_ORGANISATION_FORM = "RESET_ORGANISATION_FORM";
export const SET_ORGANISATION_FORM_FROM_CLIENT_DATA =
  "SET_ORGANISATION_FORM_FROM_CLIENT_DATA";
export const GET_MY_ORGANISATION = "GET_MY_ORGANISATION";
export const GET_MY_ORGANISATION_SUCCESS = "GET_MY_ORGANISATION_SUCCESS";
export const UPDATE_MY_ORGANISATION = "UPDATE_MY_ORGANISATION";
export const UPDATE_MY_ORGANISATION_SUCCESS = "UPDATE_MY_ORGANISATION_SUCCESS";
export const SET_SHOW_IMAGE_UPLOAD_MODAL = "SET_SHOW_IMAGE_UPLOAD_MODAL";
export const UPLOAD_LOGO = "UPLOAD_LOGO";
export const UPLOAD_LOGO_SUCCESS = "UPLOAD_LOGO_SUCCESS";
export const UPLOAD_SIGNATURE = "UPLOAD_SIGNATURE";
export const UPLOAD_SIGNATURE_SUCCESS = "UPLOAD_SIGNATURE_SUCCESS";
export const SET_SHOW_UPDATE_ORGANISATION_SUCCESS_MODAL =
  "SET_SHOW_UPDATE_ORGANISATION_SUCCESS_MODAL";
export const SET_CURRENT_ORGANISATION = "SET_CURRENT_ORGANISATION";
export const GET_SUPER_ADMIN_ORGANISATIONS = "GET_SUPER_ADMIN_ORGANISATIONS";
export const GET_SUPER_ADMIN_ORGANISATIONS_SUCCESS =
  "GET_SUPER_ADMIN_ORGANISATIONS_SUCCESS";
export const SET_SHOW_UPDATE_PAYMENT_METHOD_SUCCESS_MODAL =
  "SET_SHOW_UPDATE_PAYMENT_METHOD_SUCCESS_MODAL";
export const GET_PRICING_SETTINGS = "GET_PRICING_SETTINGS";
export const GET_PRICING_SETTINGS_SUCCESS = "GET_PRICING_SETTINGS_SUCCESS";
export const UPDATE_PRICING_SETTINGS = "UPDATE_PRICING_SETTINGS";
export const UPDATE_PRICING_SETTINGS_SUCCESS =
  "UPDATE_PRICING_SETTINGS_SUCCESS";
export const GET_SIGNATURE_BLOCKS = "GET_SIGNATURE_BLOCKS";
export const GET_SIGNATURE_BLOCKS_SUCCESS = "GET_SIGNATURE_BLOCKS_SUCCESS";
export const ADD_SIGNATORY = "ADD_SIGNATORY";
export const SET_SIGNATORIES = "SET_SIGNATORIES";
export const GET_TNC_TEMPLATES = "GET_TNC_TEMPLATES";
export const GET_TNC_TEMPLATES_SUCCESS = "GET_TNC_TEMPLATES_SUCCESS";
export const DELETE_SIGNATORY = "DELETE_SIGNATORY";
export const ON_INPUT_CHANGE_CURRENT = "ON_INPUT_CHANGE_CURRENT";
export const GET_EMAIL_CONFIGS = "GET_EMAIL_CONFIGS";
export const GET_EMAIL_CONFIGS_SUCCESS = "GET_EMAIL_CONFIGS_SUCCESS";
export const UPDATE_EMAIL_CONFIGS = "UPDATE_EMAIL_CONFIGS";
export const UPDATE_EMAIL_CONFIGS_SUCCESS =
  "UPDATE_EMAIL_CONFIGS_SUCCESS";