import React, { Component } from "react";
import AutoCompleteInput from "../components/AutoCompleteInput";
import ContractListTable from "../components/ContractListTable";
import Loader from "../components/Loader";
import {
  getContracts,
  onInputChange,
  searchContract,
  resetSearchInput,
} from "../store/contract/actions";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import queryString from "query-string";
import { setShowUpgradeModal } from "../store/common/actions";
import { getMyOrganisation } from "../store/organisation/actions";

class Contracts extends Component {
  constructor(props) {
    super(props);
    this.selectSearchContract = this.selectSearchContract.bind(this);

    const query = queryString.parse(this.props.location.search);
    let showUpgrade = query.upgrade === "true" && query.sign === "true";
    if (showUpgrade) {
      props.setShowUpgradeModal("sign");
    }
    this.state = { status: query.status };
    const filterQuery = queryString.parse(this.props.location.search);
    this.filterState = { filter: filterQuery.filter };
  }
  componentDidMount() {
    this.props.getContracts(0, this.state.status, this.filterState.filter);
    this.props.getMyOrganisation();
    this.props.resetSearchInput();
  }
  selectSearchContract(contract) {
    this.props.history.push(`/contracts/${contract._id}`);
  }
  render() {
    return (
      <React.Fragment>
        {(this.props.loading || this.props.subscriptionLoading) && <Loader />}
        <div className="page-info-header">
          <div className="container-fluid">
            <div className="page-display-title">Engagement Letters</div>
            <div className="text-right flex1">
              <button
                onClick={() => {
                  console.log("this.props.subscriptionPackage - ", this.props);
                  const should_allow = this.props.allow_prepare_contract;
                  should_allow
                    ? this.props.history.push("/contracts/new")
                    : this.props.setShowUpgradeModal(true);
                }}
                className="btn btn-success create-item" disabled
              >
                <i className="ion ion-md-add-circle"></i>{" "}
                <span>Create New Engagement Letter</span>
              </button>
            </div>
          </div>
        </div>
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="card cus-card">
                <div className="card-body">
                  <div className="mb-3">
                    <AutoCompleteInput
                      className="table-search"
                      value={this.props.contractSearchInput || ""}
                      onInputChange={this.props.onInputChange}
                      onSelect={this.selectSearchContract}
                      search={this.props.searchContract}
                      name="contractSearchInput"
                      placeholder="Search Engagement Letter"
                      items={this.props.searchedContracts}
                      valueProperty="prefix,code"
                    />
                  </div>
                  <div>
                  {!this.filterState.filter ||
                    (this.filterState.filter !== undefined &&
                      this.filterState.filter !== "All" && (
                        <p style={{ marginLeft: 300, marginTop: -45 }}>
                          Showing results for your selected filter:{" "}
                          {this.filterState.filter},{" "}
                          {typeof this.state.status === "object"
                            ? "sent"
                            : this.state.status === "completed"
                            ? "signed"
                            : this.state.status === "pending"
                            ? "awaiting signature"
                            : this.state.status}{" "}
                          Engagement Letters. Click{" "}
                          <a href="/contracts">here</a> to display all
                          Engagement Letters.
                        </p>
                      ))}
                  </div>
                  <ContractListTable
                    contracts={this.props.contracts}
                    pagination={this.props.pagination}
                    status={this.state.status}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  const {
    contracts,
    pagination,
    contractSearchInput,
    searchedContracts,
    loading,
  } = state.Contract;
  const { loading: subscriptionLoading, allow_prepare_contract } =
    state.Organisation;
  return {
    contracts,
    pagination,
    contractSearchInput,
    subscriptionLoading,
    searchedContracts,
    allow_prepare_contract,
    loading,
  };
};

export default connect(mapStateToProps, {
  getContracts,
  onInputChange,
  searchContract,
  getMyOrganisation,
  setShowUpgradeModal,
  resetSearchInput,
})(Contracts);
