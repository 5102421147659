import React, {Component, useEffect, useState} from "react";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import logoDark from "../../assets/images/outbooks.png";
import Checkbox from '@mui/material/Checkbox';
import {
    getSubscriptionPackages,
    onInputChange,
    createSubscriptionPackage,
    setShowCreateNewSubscriptionPackageSuccessModal,
    getSubscriptionPackage,
    deleteSubscriptionPackage,
    toggleSubscriptionPackage,
    updateSubscriptionPackage
} from '../../store/subscriptionPackage/actions';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import Typography from '@mui/material/Typography';
import DialogContent from '@mui/material/DialogContent';
import Divider from '@mui/material/Divider';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import { Row, Col } from "reactstrap";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import FormLabel from '@mui/material/FormLabel';
import SweetAlert from "react-bootstrap-sweetalert";
import Loader from "../../components/Loader";
import ConfirmDeleteModal from "../../components/ConfirmDeleteModal";
import {setShowConfirmDeleteModal} from "../../store/delete/actions";
import DeleteSuccessModal from "../../components/DeleteSuccessModal";
import SimpleReactValidator from "simple-react-validator";
import Paginator from "../../components/Paginator";
import {debounce} from "../../Utils";


const label = { inputProps: { 'aria-label': 'Enable' } };

export function MaxWidthDialog({type, open, setOpen, parentProps, onInputChange, validator}) {
    const [fullWidth, setFullWidth] = React.useState(true);
    const [maxWidth, setMaxWidth] = React.useState('md');
    const [value, setValue] = React.useState(null);
    const [, setUpdate] = React.useState(0);


    console.log('parentProps - ', parentProps);

    useEffect(() => {
        if(type === 'new' && open) {
            resetForm();
        }
    }, [type, open])

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        validator.hideMessages();
        resetForm();
    };

    const handleMaxWidthChange = (event) => {
        setMaxWidth(
            // @ts-expect-error autofill of arbitrary value is not handled.
            event.target.value,
        );
    };

    const resetForm = () => {
        parentProps.onInputChange('name', '');
        parentProps.onInputChange('prepare_quote', false);
        parentProps.onInputChange('send_quote', false);
        parentProps.onInputChange('send_contract', false);
        parentProps.onInputChange('prepare_contract', false);
        parentProps.onInputChange('sign_contract', false);
        parentProps.onInputChange('e_sign_per_month', 0);
        parentProps.onInputChange('fee_per_month', 0);
        parentProps.onInputChange('fee_per_year', 0);
        parentProps.onInputChange('discount', 0);
        parentProps.onInputChange('discount_enabled', false);
        parentProps.onInputChange('value', 0);
        parentProps.onInputChange('months_free', 0);
        parentProps.onInputChange('months_free_enabled', false);
        parentProps.onInputChange('got_months', 0);
        parentProps.onInputChange('month_discount_enabled', false);
        parentProps.onInputChange('in_price_of_month', 0);
        parentProps.onInputChange('subscriptionPackageId', undefined);
        parentProps.onInputChange('discounted_price_for_month_enabled', false);
        parentProps.onInputChange('discounted_price_for_month_price', 0);
        parentProps.onInputChange('discounted_price_for_month_count', 0);
        parentProps.onInputChange('discount_price_enabled', false);
        parentProps.onInputChange('discounted_price', 0);
    }

    const handleFullWidthChange = (event) => {
        setFullWidth(event.target.checked);
    };

    const handleSubmit = () => {
        // return;
        if (validator.allValid()) {
            const data = {
                _id: parentProps.subscriptionPackageId,
                name: parentProps.name,
                prepare_quote: parentProps.prepare_quote,
                send_quote: parentProps.send_quote,
                send_contract: parentProps.send_contract,
                prepare_contract: parentProps.prepare_contract,
                sign_contract: true,
                e_sign_per_month: +parentProps.e_sign_per_month,
                fee_per_year: parentProps.is_default ? 0 : +parentProps.fee_per_year,
                fee_per_month: parentProps.is_default ? 0 : (+parentProps.fee_per_year / 12).toFixed(2),
                offers: {
                    discount: {
                        enabled: parentProps.discount_enabled ?? false,
                        value: +parentProps.discount
                    },
                    months_free: {
                        enabled: parentProps.months_free_enabled ?? false,
                        value: +parentProps.months_free
                    },
                    month_discount: {
                        enabled: parentProps.month_discount_enabled ?? false,
                        value: {
                            got_months: +parentProps.got_months,
                            in_price_of_month: +parentProps.in_price_of_month
                        }
                    },
                    discounted_price: {
                        enabled: parentProps.discount_price_enabled ?? false,
                        value: +parentProps.discounted_price
                    }
                    // discounted_price_for_month: {
                    //     enabled: parentProps.discounted_price_for_month_enabled ?? false,
                    //     value: {
                    //         discounted_price_for_month_price: +parentProps.discounted_price_for_month_price,
                    //         discounted_price_for_month_count: +parentProps.discounted_price_for_month_count,
                    //     }
                    // }
                }
            }
            if(type === "edit") {
                parentProps.updateSubscriptionPackage(data);
            } else if(type === "new") {
                parentProps.createSubscriptionPackage(data);
            }
        } else {
            validator.showMessages();
            setUpdate(c => c + 1);
        }
    }

    validator.purgeFields();
    return (
        <React.Fragment>
            {/*<Button variant="outlined" onClick={handleClickOpen}>*/}
            {/*    open*/}
            {/*</Button>*/}
            <Dialog
                fullWidth={fullWidth}
                maxWidth={maxWidth}
                open={open}
                onClose={handleClose}
            >
                <DialogTitle>Add Package</DialogTitle>
                <DialogContent>
                    <Box className="mt-2 p-2">
                        <TextField size="small" id="outlined-basic" value={parentProps.name ?? ''} onChange={e => onInputChange('name', e.target.value)} label="Package Name" variant="outlined" className='w-100' />
                        {validator.message('Package Name', parentProps.name, 'required', { className: 'text-danger' })}
                        <Grid container className="mt-1" spacing={2}>
                            <Grid item xs={4}>
                                <FormControl row>
                                    <Typography variant="p" component="p">
                                        Prepare Proposal
                                    </Typography>
                                    <RadioGroup
                                      row
                                      aria-labelledby="demo-row-radio-buttons-group-label"
                                      name="row-radio-buttons-group"
                                      onChange={e => onInputChange('prepare_quote', e.target.value === 'yes')}
                                      value={parentProps.prepare_quote ? 'yes' : 'no'}
                                    >
                                        <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                                        <FormControlLabel value="no" control={<Radio />} label="No" />
                                    </RadioGroup>
                                </FormControl>
                            </Grid>
                            <Grid item xs={4}>
                                <FormControl row>
                                    <Typography variant="p" component="p">
                                        Send Proposal
                                    </Typography>
                                    <RadioGroup
                                      row
                                      aria-labelledby="demo-row-radio-buttons-group-label"
                                      name="row-radio-buttons-group"
                                      onChange={e => onInputChange('send_quote', e.target.value === 'yes')}
                                      value={parentProps.send_quote ? 'yes' : 'no'}
                                    >
                                        <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                                        <FormControlLabel value="no" control={<Radio />} label="No" />
                                    </RadioGroup>
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid container className="mt-1" spacing={2}>
                            {/*<Grid item xs={4}>*/}
                            {/*    <FormControl row>*/}
                            {/*        <Typography variant="p" component="p">*/}
                            {/*            Send Contract*/}
                            {/*        </Typography>*/}
                            {/*        <RadioGroup*/}
                            {/*          row*/}
                            {/*          aria-labelledby="demo-row-radio-buttons-group-label"*/}
                            {/*          name="row-radio-buttons-group"*/}
                            {/*          onChange={e => onInputChange('send_contract', e.target.value === 'yes')}*/}
                            {/*          value={parentProps.send_contract ? 'yes' : 'no'}*/}
                            {/*        >*/}
                            {/*            <FormControlLabel value="yes" control={<Radio />} label="Yes" />*/}
                            {/*            <FormControlLabel value="no" control={<Radio />} label="No" />*/}
                            {/*        </RadioGroup>*/}
                            {/*    </FormControl>*/}
                            {/*</Grid>*/}
                            <Grid item xs={4}>
                                <FormControl row>
                                    <Typography variant="p" component="p">
                                        Prepare Engagement Letter
                                    </Typography>
                                    <RadioGroup
                                      row
                                      aria-labelledby="demo-row-radio-buttons-group-label"
                                      name="row-radio-buttons-group"
                                      onChange={e => onInputChange('prepare_contract', e.target.value === 'yes')}
                                      value={parentProps.prepare_contract ? 'yes' : 'no'}
                                    >
                                        <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                                        <FormControlLabel value="no" control={<Radio />} label="No" />
                                    </RadioGroup>
                                </FormControl>
                            </Grid>
                            <Grid item xs={4}>
                                <FormControl row>
                                    <TextField disabled={parentProps?.is_default} size="small" label={"Yearly Value for the Plan"} value={parentProps.is_default ? 0 : parentProps.fee_per_year ?? 0} type={"number"} onChange={e => onInputChange('fee_per_year', e.target.value)} id="outlined-basic" variant="outlined" className='w-100' />
                                    {!parentProps?.is_default && validator.message('Fee per year', parentProps.fee_per_year, 'required|numeric|min:1,num', { className: 'text-danger' })}
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid container className="mt-1" spacing={2}>
                            <Grid item xs={4}>
                                <FormControl row>
                                    <Typography variant="p" component="p">
                                        Send and digitally sign the Engagement Letter
                                    </Typography>
                                    <RadioGroup
                                      row
                                      aria-labelledby="demo-row-radio-buttons-group-label"
                                      name="row-radio-buttons-group"
                                      onChange={e => onInputChange('sign_contract', e.target.value === 'yes')}
                                      value={parentProps.sign_contract ? 'yes' : 'no'}
                                    >
                                        <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                                        <FormControlLabel value="no" control={<Radio />} label="No" />
                                    </RadioGroup>
                                </FormControl>
                            </Grid>
                            <Grid item xs={4}>
                                <FormControl row>
                                    <TextField disabled={!parentProps.sign_contract} size="small" label="E-signatures per month" value={parentProps.e_sign_per_month ?? ''} type={"number"} onChange={e => onInputChange('e_sign_per_month', e.target.value)} id="outlined-basic" variant="outlined" className='w-100' />
                                    {parentProps.sign_contract && validator.message('E-signatures per month', parentProps.e_sign_per_month, 'required', { className: 'text-danger' })}
                                </FormControl>
                            </Grid>
                        </Grid>
                        {/*<Grid container className="mt-1" spacing={2}>*/}
                        {/*    <Grid item xs={4}>*/}
                        {/*        <FormControl row>*/}
                        {/*            <TextField size="small" label="Fee per month" value={parentProps.fee_per_month ?? ''} type={"number"} onChange={e => onInputChange('fee_per_month', e.target.value)} id="outlined-basic" variant="outlined" className='w-100' />*/}
                        {/*            {validator.message('Fee per month', parentProps.fee_per_month, 'required', { className: 'text-danger' })}*/}
                        {/*        </FormControl>*/}
                        {/*    </Grid>*/}
                        {/*    <Grid item xs={4}>*/}
                        {/*        <FormControl row>*/}
                        {/*            <TextField size="small" label={"Fee per year"} value={parentProps.fee_per_year ?? ''} type={"number"} onChange={e => onInputChange('fee_per_year', e.target.value)} id="outlined-basic" variant="outlined" className='w-100' />*/}
                        {/*            {validator.message('Fee per year', parentProps.fee_per_year, 'required', { className: 'text-danger' })}*/}
                        {/*        </FormControl>*/}
                        {/*    </Grid>*/}
                        {/*</Grid>*/}
                        <Divider sx={{m: 2}} />
                        <Grid container className="mt-1" spacing={2}>
                            <Grid item xs={4}>
                                <FormControl style={{flexDirection: 'row'}}>
                                    <Checkbox disabled={parentProps?.is_default} {...label} checked={parentProps.discount_enabled ?? false} onChange={e => {
                                        onInputChange('discount_enabled', e.target.checked);
                                    }} />
                                    <TextField disabled={parentProps?.is_default} size="small" label={"Discount (%)"} aria-valuemax={100} aria-valuemin={0} value={parentProps.discount ?? 0} type={"number"} onChange={e => onInputChange('discount', e.target.value)} id="outlined-basic" variant="outlined" className='w-100' />
                                </FormControl>
                                {!parentProps?.is_default && validator.message('discount', parentProps.discount, 'numeric|min:0,num|max:100,num', { className: 'text-danger' })}
                            </Grid>
                            <Grid item xs={4}>
                                <FormControl style={{flexDirection: 'row'}}>
                                    <Checkbox disabled={parentProps?.is_default} {...label} checked={parentProps.discount_price_enabled ?? false} onChange={e => {
                                        onInputChange('discount_price_enabled', e.target.checked);
                                    }} />
                                    <TextField disabled={parentProps?.is_default} size="small" label={"Discounted Price"} aria-valuemax={100} aria-valuemin={0} value={parentProps.discounted_price ?? 0} type={"number"} onChange={e => onInputChange('discounted_price', e.target.value)} id="outlined-basic" variant="outlined" className='w-100' />
                                </FormControl>
                                {!parentProps?.is_default && validator.message('discount', parentProps.discounted_price, 'numeric|min:0', { className: 'text-danger' })}
                            </Grid>
                        </Grid>
                        <Grid container className="mt-4" spacing={0}>
                            <Grid item xs={8}>
                                <Box style={{display: 'flex', alignItems: 'center'}}>
                                    <Checkbox disabled={parentProps?.is_default} {...label} checked={parentProps.months_free_enabled ?? false} onChange={e => {
                                        onInputChange('months_free_enabled', e.target.checked);
                                    }} />
                                    <FormControl row>
                                        <TextField disabled={parentProps?.is_default} size="small" value={parentProps.months_free ?? 0} type={"number"} onChange={e => onInputChange('months_free', e.target.value)} id="outlined-basic" variant="outlined" className='w-100' />
                                    </FormControl>
                                    <Typography variant="p" component="p" sx={{ml: 1}}>
                                        Months free
                                    </Typography>
                                </Box>
                                {!parentProps?.is_default && validator.message('months_free', parentProps.months_free, 'numeric|min:0,num|max:12,num', { className: 'text-danger' })}
                            </Grid>
                        </Grid>
                        <Grid container className="mt-4" spacing={0}>
                            <Grid item xs={10}>
                                <Box style={{display: 'flex', alignItems: 'center'}}>
                                    <Checkbox disabled={parentProps?.is_default} {...label} checked={parentProps.month_discount_enabled ?? false} onChange={e => {
                                        onInputChange('month_discount_enabled', e.target.checked);
                                    }}/>
                                    <FormControl row>
                                        <TextField disabled={parentProps?.is_default} size="small" value={parentProps.got_months ?? 0} type={"number"} onChange={e => onInputChange('got_months', e.target.value)} id="outlined-basic" variant="outlined" className='w-100' />
                                    </FormControl>
                                    <Typography variant="p" component="p" sx={{ml: 1, mr: 1}}>
                                        Months for the Price of
                                    </Typography>
                                    <FormControl row>
                                        <TextField disabled={parentProps?.is_default} size="small" value={parentProps.in_price_of_month ?? 0} type={"number"} onChange={e => onInputChange('in_price_of_month', e.target.value)} id="outlined-basic" variant="outlined" className='w-100' />
                                    </FormControl>
                                    <Typography variant="p" component="p" sx={{ml: 1}}>
                                        Months
                                    </Typography>
                                </Box>
                                {!parentProps?.is_default && validator.message('got_months', parentProps.months_free, 'numeric|min:0,num|max:12,num', { className: 'text-danger' })}
                                {!parentProps?.is_default && validator.message('in_price_of_month', parentProps.months_free, 'numeric|min:0,num|max:12,num', { className: 'text-danger' })}
                            </Grid>
                        </Grid>
                        {/*<Grid container className="mt-4" spacing={0}>*/}
                        {/*    <Grid item xs={10}>*/}
                        {/*        <Box style={{display: 'flex', alignItems: 'center'}}>*/}
                        {/*            <Checkbox disabled={parentProps?.is_default} {...label} checked={parentProps.discounted_price_for_month_enabled ?? false} onChange={e => {*/}
                        {/*                onInputChange('discounted_price_for_month_enabled', e.target.checked);*/}
                        {/*            }}/>*/}
                        {/*            <FormControl row>*/}
                        {/*                <TextField disabled={parentProps?.is_default} size="small" value={parentProps.discounted_price_for_month_price ?? 0} type={"number"} onChange={e => onInputChange('discounted_price_for_month_price', e.target.value)} id="outlined-basic" variant="outlined" className='w-100' />*/}
                        {/*            </FormControl>*/}
                        {/*            <Typography variant="p" component="p" sx={{ml: 1, mr: 1}}>*/}
                        {/*                Discounted Price for*/}
                        {/*            </Typography>*/}
                        {/*            <FormControl row>*/}
                        {/*                <TextField disabled={parentProps?.is_default} size="small" value={parentProps.discounted_price_for_month_count ?? 0} type={"number"} onChange={e => onInputChange('discounted_price_for_month_count', e.target.value)} id="outlined-basic" variant="outlined" className='w-100' />*/}
                        {/*            </FormControl>*/}
                        {/*            <Typography variant="p" component="p" sx={{ml: 1}}>*/}
                        {/*                Months*/}
                        {/*            </Typography>*/}
                        {/*        </Box>*/}
                        {/*        {!parentProps?.is_default && validator.message('got_months', parentProps.months_free, 'numeric|min:0,num|max:12,num', { className: 'text-danger' })}*/}
                        {/*        {!parentProps?.is_default && validator.message('in_price_of_month', parentProps.months_free, 'numeric|min:0,num|max:12,num', { className: 'text-danger' })}*/}
                        {/*    </Grid>*/}
                        {/*</Grid>*/}
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleSubmit}>Submit</Button>
                    <Button onClick={handleClose}>Close</Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}

function SubscriptionPackageItem({item, handleEditPackage, setShowConfirmDeleteModal, toggleSubscriptionPackage}) {
    const [active, setActive] = useState(item.is_active ?? false);

    return (
      <tr>
          <td>{item.name}</td>
          <td>{item.fee_per_month}</td>
          <td>{item.fee_per_year}</td>
          <td>
              {!item.is_default && <div className="d-flex">
                <span>
                    {active ? "Active" : "InActive"}
                </span>
                  <span>
                    <div className="tg-list">
                        <div className="tg-list-item">
                            <input className="tgl tgl-light" id={item._id}
                                   type="checkbox" onChange={(e) => {
                                console.log('item._id - ', item._id, item);
                                setActive(e.target.checked);
                                toggleSubscriptionPackage({_id: item._id, is_active: e.target.checked});
                            }} checked={active}/>
                            <label className="tgl-btn" htmlFor={item._id}/>
                        </div>
                    </div>
                </span>
              </div>}
          </td>
          <td>
              <button className="btn btn-secondary btn-xs mr-2" onClick={() => {
                  handleEditPackage(item._id)
              }}><i className="fa fa-edit"></i></button>
              {!item.is_default && <button className="btn btn-danger btn-xs " onClick={() => {
                  console.log('modal - ')
                  setShowConfirmDeleteModal(true, item._id, 'subscriptionPackage');
              }}><i className="fa fa-trash"></i></button>}
          </td>
      </tr>
    )
}

class Billing extends Component {
    constructor(props) {
        super(props);
        this.validator = new SimpleReactValidator();
        this.state = {
            openPackageModal: false,
            type: 'new',
            showDeleteModal: false,
        }
        this.func = debounce(this.props.getSubscriptionPackages, 800);
    }
    componentDidMount() {
        this.props.getSubscriptionPackages({page_num: 0});
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        console.log('this.props.subscriptionPackages - ', this.props.subscriptionPackages);
        if(prevProps.showCreateNewSubscriptionPackageSuccessModal !== this.props.showCreateNewSubscriptionPackageSuccessModal && this.props.showCreateNewSubscriptionPackageSuccessModal) {
            this.setState({openPackageModal: false});
        }
        if(prevProps.showConfirmDeleteModal !== this.props.showConfirmDeleteModal && !this.props.showConfirmDeleteModal) {
            this.props.getSubscriptionPackages({page_num: 0});
        }
    }
    filterList(val) {
        this.props.onInputChange("searchValue", val.target.value);

        this.func({page_num: 0, text: val.target.value});
    }
    handleNewPackage() {
        this.setState({type: 'new', openPackageModal: true})
    }

    handleEditPackage(_id) {
        this.props.getSubscriptionPackage({id: _id});
        this.setState({type: 'edit', openPackageModal: true});
    }
    render() {
        return (
          <React.Fragment>
              {this.props.loading && <Loader />}
              <div className="page-info-header">
                  <div className="container-fluid">
                      <div className="page-display-title">Subscription Packages</div>
                  </div>
              </div>
              <div className="container-fluid">
                  <div className="row">
                      <div className="col-md-12">
                          <div className="box-typ1" style={{marginTop: '72px'}}>
                              <div className="col-md-12">
                                  <div className="box-typ2-content row">
                                      <div className="col-md-3">
                                          <div className="form-group position-relative">
                                              <input type="text" placeholder="Search Package" value={this.props.searchValue} onChange={this.filterList.bind(this)} className="form-control" />
                                              <i className="fa fa-search serchicon"></i>
                                          </div>
                                      </div>
                                      <div className="co-md-9">
                                          <div className="text-right d-flex">
                                              <button onClick={this.handleNewPackage.bind(this)} className="btn btn-secondary "><i className="fa fa-plus"></i> <span>Add New </span></button>
                                          </div>
                                      </div>
                                      <div class="col-md-12">
                                          <div className="table-responsive">
                                              <table className="table table-striped ">
                                                  <thead className="thead-dark ">
                                                  <tr>
                                                      <th scope="col">Package Name</th>
                                                      <th scope="col">Monthly Price</th>
                                                      <th scope="col">Yearly Price</th>
                                                      <th scope="col">Status</th>
                                                      <th scope="col">Action</th>
                                                  </tr>
                                                  </thead>
                                                  <tbody>
                                                  {this.props.subscriptionPackages && this.props.subscriptionPackages.map((item) =>
                                                    <SubscriptionPackageItem
                                                      setShowConfirmDeleteModal={this.props.setShowConfirmDeleteModal}
                                                      item={item}
                                                      handleEditPackage={this.handleEditPackage.bind(this)}
                                                      getSubscriptionPackage={this.props.getSubscriptionPackage}
                                                      toggleSubscriptionPackage={this.props.toggleSubscriptionPackage}
                                                    />
                                                  )}
                                                  </tbody>
                                              </table>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
              {this.props.showCreateNewSubscriptionPackageSuccessModal && <SweetAlert
                title=""
                success
                confirmBtnBsStyle="success"
                onConfirm={() => {
                    this.props.setShowCreateNewSubscriptionPackageSuccessModal(false);
                    this.props.getSubscriptionPackages();
                }}
              >
                  <div className="mb-2 success-alert-message">Subscription Package has been {typeof this.props.showCreateNewSubscriptionPackageSuccessModal === 'string' ? this.props.showCreateNewSubscriptionPackageSuccessModal : this.state.type === 'new' ? 'created' : 'updated'} successfully!</div>
              </SweetAlert>}
              <ConfirmDeleteModal />
              <DeleteSuccessModal delObject={'Subscription Package'}/>
              <MaxWidthDialog validator={this.validator} type={this.state.type} parentProps={this.props} onInputChange={this.props.onInputChange} open={this.state.openPackageModal} setOpen={c => this.setState({openPackageModal: c})} />
              <Paginator
                totalPages={this.props.pagination?.total_pages}
                pageNum={this.props.pagination?.page_num}
                getObjects={this.props.getSubscriptionPackages}
                status={this.props.status}
              />
          </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    const { users, pagination, loading } = state.User;
    const { showConfirmDeleteModal } = state.Delete;
    return { showConfirmDeleteModal, users, pagination, loading, ...state.SubscriptionPackage }
}

export default connect(mapStateToProps, {
    getSubscriptionPackages,
    getSubscriptionPackage,
    createSubscriptionPackage,
    toggleSubscriptionPackage,
    updateSubscriptionPackage,
    setShowCreateNewSubscriptionPackageSuccessModal,
    setShowConfirmDeleteModal,
    onInputChange
})(Billing);
