import React, { Component } from "react";
import Select from "react-select";
import { getQuoteClient } from "../store/quote/actions";
import {
  reviewContractClient,
  sendContractClient,
  saveContractAsDraftClient,
} from "../store/contract/actions";
import { getTemplatesClient } from "../store/template/actions";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import {
  buildCalculatePricingJson,
  buildGlobalPricingDriversJson,
  formatCurrency,
} from "../Utils";
import ReviewServices from "../components/ReviewServices";
import Loader from "../components/Loader";

class GenerateContractClientReject extends Component {
  constructor(props) {
    super(props);
    this.state = {
      getOtherData: true,
      signEasyUrl: "",
      loading: true,
      selectedPackage: null,
    };
    this.sendContract = this.sendContract.bind(this);
    this.getSignatoriesData = this.getSignatoriesData.bind(this);
    this.draftContract = this.draftContract.bind(this);
    this.selectedReviewContract = this.selectedReviewContract.bind(this);
  }
  componentDidMount() {
    this.props.getQuoteClient(atob(this.props.match.params.id));
  }
  getSignatoriesData() {
    let signatoriesData = [];
    // for (let signatory of this.props.currentOrganisation.signatories) {
    // signatoriesData.push({
    //   firstName: this.props.quoteData.client.contact.first_name,
    //   lastName: this.props.quoteData.client.contact.last_name,
    //   email: this.props.quoteData.client.contact.email,
    //   signaturePosition: "right",
    // });
    if (this.props.quoteData.client.business_type == 'llp' || this.props.quoteData.client.business_type.toLowerCase() == 'ltd') {
      const officers = this.props.quoteData.client.officers.filter(x => x.is_authorised_signatory == true);
      if (officers.length > 0) {
        officers.map((officer) => {
          signatoriesData.push({
            firstName: officer.first_name,
            lastName: officer.last_name,
            email: officer.email,
            signaturePosition: "right",
          });
        })
      } else {
        signatoriesData.push({
          firstName: this.props.quoteData.client.officers[0].first_name,
          lastName: this.props.quoteData.client.officers[0].last_name,
          email: this.props.quoteData.client.officers[0].email,
          signaturePosition: "right",
        });
      }
    } else if (this.props.quoteData.client.business_type == 'partnership') {
      const officers = this.props.quoteData.client.partners.filter(x => x.is_authorised_signatory == true);
      if (officers.length > 0) {
        officers.map((officer) => {
          signatoriesData.push({
            firstName: officer.first_name,
            lastName: officer.last_name,
            email: officer.email,
            signaturePosition: "right",
          });
        })
      } else {
        signatoriesData.push({
          firstName: this.props.quoteData.client.partners[0].first_name,
          lastName: this.props.quoteData.client.partners[0].last_name,
          email: this.props.quoteData.client.partners[0].email,
          signaturePosition: "right",
        });
      }
    } else {
      signatoriesData.push({
        firstName: this.props.quoteData.client.contact.first_name,
        lastName: this.props.quoteData.client.contact.last_name,
        email: this.props.quoteData.client.contact.email,
        signaturePosition: "right",
      });
    }
    // }
    return signatoriesData;
    // return this.props.quoteData.client.officers.filter;
  }

  componentDidUpdate(prevProps) {
    console.log("updatedProps", this.props);
    if (this.state.loading && prevProps.quoteData != this.props.quoteData) {
      this.props.getTemplatesClient(this.props.quoteData.organisation._id);
      // this.setState({
      //   loading: false,
      // });
    }
    // if (prevProps.templates != this.props.templates) {
    //   // https://staging.outbooks.com/generate-contract/NjQ5OTExZTE4ZDg5ODQ0Y2M4OTFiNGIy/6363d34ecee9ec21a66ab944
    //   this.selectedReviewContract(this.props.match.params.packageId);
    //   this.setState({
    //     selectedPackage: this.props.match.params.packageId
    //   });
    // }
    if (prevProps.templates != this.props.templates) {
      this.draftContract();
    }
    // if (prevProps.quoteData != this.props.quoteData && this.state.selectedPackage == this.props.match.params.packageId) {
    //   this.sendContract()
    // }
    // if (this.state.getOtherData) {
    //   this.setState(
    //     {
    //       getOtherData: false,
    //     },
    //     () => {
    //       let reviewPackageData = {
    //         source: "fromQuote",
    //         // paymentFrequency: this.props.paymentFrequency,
    //       };
    //       reviewPackageData.quoteId = atob(this.props.match.params.id);
    //       reviewPackageData.packageRecurringPrice = parseFloat(
    //         this.props.packageRecurringPrice
    //       );
    //       reviewPackageData.quoteType = this.props.selectedQuoteType;
    //       reviewPackageData.services = buildCalculatePricingJson(
    //         this.props?.contractServices,
    //         this.props?.servicesByCategory,
    //         this.props?.contractPricingDrivers,
    //         this.props,
    //         this.props?.paymentFrequency
    //       );
    //       if (
    //         this.props.selectedQuoteType === "packagedPricing" ||
    //         this.props.selectedQuoteType === "customPackagedPricing"
    //       ) {
    //         reviewPackageData.quotePackageId = this.props.contractQuotePackage;
    //         reviewPackageData.quotePackageName = this.props.sentQuotes.filter(
    //           (item) => item._id === this.props.contractQuote
    //         )[0]?.data?.packagedPricingSelectedPackages[
    //           this.props.contractQuotePackage
    //         ].name;
    //       }
    // this.props.reviewContract(reviewPackageData);
    //     }
    //   );
    // }
    if (
      this.props.signEasyUrl &&
      this.state.signEasyUrl != this.props.signEasyUrl
    ) {
      window.location.href = this.props.signEasyUrl;
    }
  }
  sendContract() {
    this.setState({
      loading: true,
    });
    const now = new Date();
    const templateId = this.props?.quoteData.organisation[`${this.props.quoteData.template.client_business_type}_template_id`];
    const data = {
      source: "fromQuote",
      template: templateId ? templateId : this.props.templates.find(
        (x) =>
          x.client_business_type ==
          this.props.quoteData.template.client_business_type &&
          x.type == "contract"
      )._id,
      selectedServices:
        this.props.quoteData?.selectedServices ?? this.props.reviewedPrice,
      creationDate: [now.getFullYear(), now.getMonth(), now.getDate()],
      showFullBreakdown: this.props.quoteData.showFullBreakdown,
      packageRecurringPrice:
        this.props.quoteData.packageRecurringPrice ||
        this.props.quoteData.final_recurring_total ||
        this.props.quoteData.recurringTotal ||
        this.props?.recurringTotal,
      packageOneOffPrice:
        this.props.quoteData.packageOneOffPrice ||
        this.props.quoteData.final_oneOff_total ||
        this.props.quoteData.oneOffTotal ||
        this.props?.oneOffTotal,
      signatories: this.getSignatoriesData(),
      paymentMethod: this.props.quoteData.payment_method,
      tnc: this.props.tnc,
      tncType: this.props.tncType,
    };

    if (this.props?.tncType === "pdf") {
      data["template_url"] = this.props.template_url;
    }

    data.paymentFrequency =
      this.props.quoteData.paymentFrequency &&
        this.props.quoteData.paymentFrequency.trim().length > 0
        ? this.props.quoteData.paymentFrequency
        : "yearly";
    data.quoteId = atob(this.props.match.params.id);
    data.quoteType = this.props.quoteData.type;
    let globalDrivers = [];
    for (let key in this.props.quoteData.globalPricingDrivers) {
      globalDrivers.push({
        ...this.props.quoteData.globalPricingDrivers[key],
        _id: key,
      });
    }
    data.globalPricingDrivers = buildGlobalPricingDriversJson(
      globalDrivers,
      this.props.quoteData
    );
    if (
      this.props.quoteData.type === "packagedPricing" ||
      this.props.quoteData.type === "customPackagedPricing"
    ) {
      data.quotePackageId = this.state.selectedPackage;
      data.quotePackageName =
        this.props.quoteData.data?.packagedPricingSelectedPackages[
          this.state.selectedPackage
        ].name;
    }
    data.isNew = true;
    data.claims = {
      org: {
        id: this.props.quoteData.organisation._id,
      },
      userId: this.props.quoteData.user._id,
    };
    this.props.sendContractClient(data);
  }
  draftContract() {
    this.setState({
      loading: true,
    });
    const now = new Date();
    const data = {
      source: "fromQuote",
      template: this.props.templates.find(
        (x) =>
          x.client_business_type ==
          this.props.quoteData.template.client_business_type &&
          x.type == "contract"
      )._id,
      selectedServices:
        this.props.quoteData?.selectedServices ?? this.props.reviewedPrice,
      creationDate: [now.getFullYear(), now.getMonth(), now.getDate()],
      showFullBreakdown: this.props.quoteData.showFullBreakdown,
      packageRecurringPrice:
        this.props.quoteData.packageRecurringPrice ||
        this.props.quoteData.final_recurring_total ||
        this.props.quoteData.recurringTotal,
      packageOneOffPrice:
        this.props.quoteData.packageOneOffPrice ||
        this.props.quoteData.final_oneOff_total ||
        this.props.quoteData.oneOffTotal,
      signatories: this.getSignatoriesData(),
      paymentMethod: this.props.quoteData.payment_method,
      tnc: this.props.tnc,
      tncType: this.props.tncType,
    };

    if (this.props?.tncType === "pdf") {
      data["template_url"] = this.props.template_url;
    }

    data.paymentFrequency =
      this.props.quoteData.paymentFrequency &&
        this.props.quoteData.paymentFrequency.trim().length > 0
        ? this.props.quoteData.paymentFrequency
        : "yearly";
    data.quoteId = atob(this.props.match.params.id);
    data.quoteType = this.props.quoteData.type;
    let globalDrivers = [];
    for (let key in this.props.quoteData.globalPricingDrivers) {
      globalDrivers.push({
        ...this.props.quoteData.globalPricingDrivers[key],
        _id: key,
      });
    }
    data.globalPricingDrivers = buildGlobalPricingDriversJson(
      globalDrivers,
      this.props.quoteData
    );
    // if (
    //   this.props.quoteData.type === "packagedPricing" ||
    //   this.props.quoteData.type === "customPackagedPricing"
    // ) {
    //   data.quotePackageId = this.props.contractQuotePackage;
    //   data.quotePackageName = this.props.sentQuotes.filter(
    //     (item) => item._id === this.props.contractQuote
    //   )[0]?.data?.packagedPricingSelectedPackages[
    //     this.props.contractQuotePackage
    //   ].name;
    // }
    data.isNew = true;
    data.claims = {
      org: {
        id: this.props.quoteData.organisation._id,
      },
      userId: this.props.quoteData.user._id,
    };
    this.props.saveContractAsDraftClient(data);
    this.setState({
      loading: false,
    });
    window.location.href = "/";
  }
  selectedReviewContract(selectedPackage) {
    let data = {
      source: "fromQuote",
      // paymentFrequency: this.props.paymentFrequency,
    };

    data.quoteId = atob(this.props.match.params.id);
    data.packageRecurringPrice = null;
    data.quoteType = this.props.quoteData.type;
    data.services = buildCalculatePricingJson(
      this.props.quoteData?.contractServices ?? [],
      this.props.quoteData?.servicesByCategory ?? [],
      this.props.quoteData?.contractPricingDrivers ?? [],
      this.props.quoteData,
      this.props.quoteData?.paymentFrequency
    );
    if (
      this.props.quoteData.type === "packagedPricing" ||
      this.props.quoteData.type === "customPackagedPricing"
    ) {
      data.quotePackageId = selectedPackage;
      data.quotePackageName =
        this.props.quoteData?.data?.packagedPricingSelectedPackages[
          selectedPackage
        ].name;
    }
    data.claims = {
      org: {
        id: this.props.quoteData.organisation._id,
      },
      userId: this.props.quoteData.user._id,
    };
    // console.log(data, this.state.selectedPackage);
    this.props.reviewContractClient(data);
  }
  render() {
    return <Loader />;
  }
}

const mapStateToProps = (state) => {
  const { currentOrganisation } = state.Organisation;
  const { servicesByCategory, requiredGlobalPricingDrivers } = state.Service;
  const serviceLoading = state.Service.loading;
  const packageLoading = state.Package.loading;
  const packagePagination = state.Package.pagination;
  const clientLoading = state.Client.loading;
  const templateLoading = state.Template.loading;
  const { clients } = state.Client;
  const { templates } = state.Template;
  const { packages } = state.Package;
  return {
    currentOrganisation,
    servicesByCategory,
    requiredGlobalPricingDrivers,
    packageLoading,
    clientLoading,
    serviceLoading,
    templateLoading,
    clients,
    templates,
    packages,
    packagePagination,
    ...state.Quote,
    ...state.Contract,
  };
};

export default withRouter(
  connect(mapStateToProps, {
    getQuoteClient,
    getTemplatesClient,
    reviewContractClient,
    sendContractClient,
    saveContractAsDraftClient,
  })(GenerateContractClientReject)
);
