import React, { Component } from "react";
import "./style.css";
import "./style-m.css";
import logoImg from "./assets/outbooks-logo.webp";
import forgotPasswordImg from "./assets/icon-password.png";
import iconFacebook from "./assets/Icon-Facebook-White.png";
import iconX from "./assets/Icon-X-White.png";
import iconLinkedin from "./assets/Icon-Linkedin-White.png";
import iconYoutube from "./assets/Icon-YouTube-White.png";
import { Row, Col, Card, Alert } from "reactstrap";
// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation";
import { connect } from "react-redux";
import SweetAlert from "react-bootstrap-sweetalert";
import Loader from "../../components/Loader";
import { withRouter, Link } from "react-router-dom";
import {
  userForgetPassword,
  resetForgotPasswordForm,
} from "../../store/actions";
// import images
import bg from "../../assets/images/bg.jpg";
import logoDark from "../../assets/images/outbooks.png";
import { Redirect } from "react-router-dom";

class ForgotPasswordNew extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.handleValidSubmit = this.handleValidSubmit.bind(this);
    this.form = React.createRef();
    this.state = {
      showSuccessAlert: false, // Added state to control the SweetAlert visibility
      redirectToLogin: false,
      isVisible: true, // Define isVisible state variable for mbMenu
    };
  }

  handleValidSubmit(event, values) {
    this.setState({ userEmail: values.email }); // Store email in state
    this.props.userForgetPassword(values.email, (response) => {
      const isSuccess = response.data.success;
      if (isSuccess === true) {
        this.setState({ showSuccessAlert: true });
      } else {
        this.setState({ showSuccessAlert: false });
      }
    });
    this.form.current.reset();
  }

  componentDidMount() {
    this.props.resetForgotPasswordForm();
  }

  componentWillUnmount() {
    this.props.resetForgotPasswordForm();
  }
  closeSuccessAlert = () => {
    this.setState({ showSuccessAlert: false }, () => {
      this.setState({ redirectToLogin: true });
    });
  };

  toggleVisibility = () => {
    const divElement = document.getElementById('mbMenu');
    if (divElement) {
      divElement.style.display = this.isVisible ? 'none' : 'block';
      //setIsVisible(!isVisible);
      this.setState(prevState => ({ isVisible: !prevState.isVisible }));
    }
  };

  render() {
    const { redirectToLogin } = this.state;

    if (redirectToLogin) {
      return <Redirect to="/" />;
    }
    return (
      <React.Fragment>
        <div>
        <header className="lnheader">
            <div className="lnnav-bar">
              <div className="lnlogo">
                <a className="lna" href="https://outbooks.com/proposal/">
                  <img src={logoImg} alt="Outbooks" />
                </a>
                <div className="mbMenuCont">
                  <button className="mbTgl" onClick={this.toggleVisibility}>&#9776;</button>

                  <div id="mbMenu" className="lnmenu mb-lnmenu" style={{ display: this.state.isVisible ? 'none' : 'block' }}>
                    <ul className="lnul">
                      <li>
                        <a className="lna" href="https://outbooks.com/proposal/">HOME</a>
                      </li>
                      <li className="tosb-nav">
                        <a className="lna" href="https://outbooks.com/proposal/features/">FEATURES &#x25BE;</a>
                        <ul class="sb-nav">
                          <li><a className="lna" href="https://outbooks.com/proposal/engagement-letter/">Engagement Letter</a></li>
                          <li><a className="lna" href="https://outbooks.com/proposal/professional-proposal-renewals/">Professional Proposal & Renewal</a></li>
                          <li><a className="lna" href="https://outbooks.com/proposal/consistent-pricing/">Consistent Pricing</a></li>
                          <li><a className="lna" href="https://outbooks.com/proposal/client-payment/">Client Payment</a></li>
                        </ul>
                      </li>
                      <li>
                        <a className="lna" href="https://outbooks.com/proposal/contact-us/">CONTACT US</a>
                      </li>
                      <li>
                        <a className="lna" href="/signIn">Login</a>
                      </li>
                      <li>
                        <a className="lna" href="/signup">Signup</a>
                      </li>
                      <li>
                        <a className="lna" href="https://calendly.com/amit-outbooks/outbooksproposal" target="_Blank">Book a Free Demo</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              
              <div className="lnmenu lp-lnmenu">
                <ul className="lnul">
                  <li>
                    <a className="lna" href="https://outbooks.com/proposal/">HOME</a>
                  </li>
                  <li>
                    <a className="lna" href="https://outbooks.com/proposal/features/">FEATURES &#x25BE;</a>
                    <ul class="sb-nav">
                      <li><a className="lna" href="https://outbooks.com/proposal/engagement-letter/">Engagement Letter</a></li>
                      <li><a className="lna" href="https://outbooks.com/proposal/professional-proposal-renewals/">Professional Proposal & Renewal</a></li>
                      <li><a className="lna" href="https://outbooks.com/proposal/consistent-pricing/">Consistent Pricing</a></li>
                      <li><a className="lna" href="https://outbooks.com/proposal/client-payment/">Client Payment</a></li>
                    </ul>
                  </li>
                  <li>
                    <a className="lna" href="https://outbooks.com/proposal/contact-us/">CONTACT US</a>
                  </li>
                </ul>
              </div>
              <div className="lnbtn lp-lnmenu">
                <a href="signup"  class="top3btn t3btn3">SIGNUP</a>
                <a href="signIn"  class="top3btn t3btn2">LOGIN</a>   
                <a href="https://calendly.com/amit-outbooks/outbooksproposal" target="_blank" class="top3btn t3btn1">BOOK A FREE DEMO</a>
              </div>
            </div>
          </header>
          {/* {this.props.loading || this.state.showSuccessAlert === true ? (
            <Loader />
          ) : null} */}
          <div className="lnmain-content">
            <section className="lnsection-one">
              <div className="lnlogin-panel">
                <a className="lna" name="login"></a>
                <div className="lnlogin-right">
                  <table className="lntable" border="0" cellPadding={5}>
                    <tbody>
                      <tr>
                        <td vertical-align="middle" width={300} align="center">
                          <img
                            src={forgotPasswordImg}
                            alt="Proposl Tool Forgot Password"
                          />
                        </td>
                        <td width={25}></td>
                        <td>
                          <div className="sign-ar">
                            <div className="row-f">
                              <h1 className="lnh1 sign-f darkh">Reset Password</h1>
                            </div>
                            <AvForm
                              className="form-horizontal mt-4"
                              onValidSubmit={this.handleValidSubmit}
                              ref={this.form}
                            >
                              <div className="row-f marb">
                                <p className="lnp p-text sign-f">
                                  Forgot your password? No Problem! Please enter
                                  your email to reset the password. We will send
                                  you reset instructions by email. You may need
                                  to check your spam folder or unblock
                                  do-not-reply@proposal.outbooks.com
                                </p>
                              </div>
                              {this.props.forgetError &&
                              this.props.forgetError ? (
                                <Alert
                                  color="danger"
                                  style={{
                                    border: "none",
                                    backgroundColor: "transparent",
                                    color: "#F9060E",
                                  }}
                                >
                                  {this.props.forgetError}
                                </Alert>
                              ) : null}
                              {this.props.forgetSuccessMsg ? (
                                <Alert
                                  color="success"
                                  style={{
                                    border: "none",
                                    backgroundColor: "transparent",
                                  }}
                                >
                                  {this.props.forgetSuccessMsg}
                                </Alert>
                              ) : null}
                              {!this.props.forgetSuccessMsg ? (
                                <div className="row-f">
                                  <AvField
                                    type="email"
                                    name="email"
                                    className="lninput lntext sign-f big martno"
                                    placeholder="Enter Email*"
                                    validate={{
                                      required: {
                                        value: true,
                                        errorMessage: (
                                          <span>This field is required.</span>
                                        ),
                                      },
                                      pattern: {
                                        value:
                                          /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                        errorMessage: (
                                          <span>
                                            Please enter a valid email.
                                          </span>
                                        ),
                                      },
                                    }}
                                  />
                                </div>
                              ) : null}

                              {!this.props.forgetSuccessMsg ? (
                                <div className="row-f">
                                  <input
                                    type="submit"
                                    name="submit"
                                    className="lnpage-btn lnlogin sign-f"
                                    value="RESET PASSWORD"
                                  />
                                </div>
                              ) : null}
                            </AvForm>
                            <div className="row-f">
                              <div className="tc-div sign-f">
                                <span className="tc-2 sign-f">
                                  {" "}
                                  &nbsp;Remember It?
                                </span>{" "}
                                &nbsp;{" "}
                                <a className="lna" href="signin">
                                  <strong>SIGN IN HERE</strong>
                                </a>
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </section>
          </div>

          <footer className="lnfooter">
            <div className="lncol-1">
              <div className="lnlogo">
                <a className="lna" href="https://outbooks.com/proposal/">
                  <img src={logoImg} alt="Outbooks Proposal" />
                </a>
              </div>
              <div className="lnmenu">
                <ul className="lnul lnnav-list">
                  <li>
                    <a className="lna" href="https://outbooks.com/proposal/">HOME</a>
                  </li>
                  <li>
                    <a className="lna" href="signup">SIGN UP</a>
                  </li>
                  <li>
                    <a className="lna" href="https://outbooks.com/proposal/features/">FEATURES</a>
                  </li>
                  <li>
                    <a className="lna" href="https://outbooks.com/proposal/contact-us/">CONTACT US</a>
                  </li>
                </ul>
              </div>
              <p className="lnp lncopy-right">
                Copyright © Outbooks Proposal {new Date().getFullYear()} | All Rights Reserved
              </p>
            </div>
            <div className="lncol-2">
            <div className="lnmenu footer-m1">
                <ul className="lnul lnnav-list">
                    <li>
                      <a className="lna" href="https://outbooks.com/proposal/contact-us">Help Center</a>
                    </li>
                    <li>
                      <a className="lna" href="https://outbooks.com/proposal/articles/">Blogs</a>
                    </li>
                    <li>
                      <a className="lna" href="https://outbooks.com/proposal/terms-and-conditions/">Terms and Conditions</a>
                    </li>
                  </ul>
              </div>
            </div>
            <div className="lncol-3">
              <p className="lnp lnadr">
                Suite 18, Winsor & Newton Building,
                <br />
                Whitefriars Avenue, Harrow HA3 5RN
              </p>
              <p className="lnp lnregno">Registration No: 10746177</p>
              <ul className="lnul lncontact-links">
                <li>
                  <a className="lna" href="mailto:info@outbooks.com">
                    &#9993; &nbsp; info@outbooks.com
                  </a>
                </li>
                <li>
                  <a className="lna" href="tel:+443300578597">
                    &#9743; &nbsp; +44 330 057 8597
                  </a>
                </li>
              </ul>
              <ul className="lnul lnsocial-links">
                <li>
                  <a className="lna" href="https://www.facebook.com/profile.php?id=61556567720993">
                    <img src={iconFacebook} alt="Facebook" />
                  </a>
                </li>
                <li>
                  <a className="lna" href="https://www.linkedin.com/company/outbooksproposal/">
                    <img src={iconLinkedin} alt="Linkedin" />
                  </a>
                </li>
                <li>
                  <a className="lna" href="https://twitter.com/outbookproposal">
                    <img src={iconX} alt="Twitter / X" />
                  </a>
                </li>
                <li>
                  <a className="lna" href="https://www.youtube.com/channel/UCCucVxt5QuYrJI6SDCDW7sQ">
                    <img src={iconYoutube} alt="Youtube" />
                  </a>
                </li>
              </ul>
              <a
                href="https://outbooks.com/proposal/contact-us/"
                className="lna lnpage-btn"
              >
                CONTACT US
              </a>
            </div>
          </footer>
        </div>
        {this.state.showSuccessAlert && (
          <SweetAlert
            title=""
            success
            confirmBtnBsStyle="success"
            // onConfirm={this.clearModal}
            onConfirm={this.closeSuccessAlert}
          >
            <div className="mb-2 success-alert-message">
              Success! We've sent the password reset link to your email address:{" "}
              {this.state.userEmail}
            </div>
          </SweetAlert>
        )}
      </React.Fragment>
    );
  }
}

const mapStatetoProps = (state) => {
  const { forgetError, forgetSuccessMsg, loading } = state.ForgetPassword;
  return { forgetError, forgetSuccessMsg, loading };
};

export default withRouter(
  connect(mapStatetoProps, { userForgetPassword, resetForgotPasswordForm })(
    ForgotPasswordNew
  )
);
