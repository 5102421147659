import {
    LOGIN_USER,
    LOGIN_USER_WITH_PROVIDER,
    LOGIN_SUCCESS,
    LOGOUT_USER,
    LOGOUT_USER_SUCCESS,
    LOGIN_ERROR,
    SWITCH_ORGANISATION,
    ON_LOGIN_INPUT_CHANGE,
    RESET_LOGIN_FORM
} from './actionTypes';

const initialState = {
    error: null,
    loading: false
}

const login = (state = initialState, action) => {
    switch (action.type) {
        case ON_LOGIN_INPUT_CHANGE:
            return {...state, [action.payload.field]: action.payload.value}
        case LOGIN_USER:
            state = {
                ...state,
                loading: true
            };
            break;
        case LOGIN_USER_WITH_PROVIDER:
            state = {
                ...state,
                loading: true
            };
            break;
        case LOGIN_SUCCESS:
            state = {
                ...state,
                loading: false
            };
            break;
        case LOGOUT_USER:
            state = { ...state };
            break;
        case LOGOUT_USER_SUCCESS:
            state = { ...state };
            break;
        case LOGIN_ERROR:
            state = { ...state, error: action.payload, loading: false };
            break;
        case SWITCH_ORGANISATION:
            state = { ...state, loading: true };
            break;
        case RESET_LOGIN_FORM:
            return initialState;
        default:
            state = { ...state };
            break;
    }
    return state;
}

export default login;