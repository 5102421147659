import {
	SET_PACKAGE_LOADING,
	ON_PACKAGE_INPUT_CHANGE,
	SET_PACKAGE_ACTIVE_STEP,
	GET_PACKAGES,
	GET_PACKAGES_SUCCESS,
	SUBMIT_PACKAGE,
	SUBMIT_PACKAGE_SUCCESS,
	SET_SHOW_CREATE_NEW_PACKAGE_SUCCESS_MODAL,
	ADD_SERVICE_TO_PACKAGE,
	REMOVE_SERVICE_FROM_PACKAGE,
	GET_PACKAGE,
	GET_PACKAGE_SUCCESS,
	RESET_CREATE_NEW_PACKAGE_FORM,
	CREATE_NEW_PACKAGE_FAIL,
	SET_SHOW_CREATE_NEW_PACKAGE_FAILED_MODAL
} from "./actionTypes";

export const setPackageLoading = (isLoading) => {
	return {
		type: SET_PACKAGE_LOADING,
		payload: isLoading
	}
}

export const onInputChange = (field, value) => {
	return {
        type: ON_PACKAGE_INPUT_CHANGE,
        payload: {
        	field,
        	value
        }
    }
}

export const setActiveStep = step => {
	return {
		type: SET_PACKAGE_ACTIVE_STEP,
		payload: step
	}
}

export const getPackages = (pageNum=0) => {
	return {
		type: GET_PACKAGES,
		payload: pageNum
	}
}

export const getPackagesSuccess = (data) => {
	return {
		type: GET_PACKAGES_SUCCESS,
		payload: data
	}
}

export const submitPackage = (data, type) => {
	return {
		type: SUBMIT_PACKAGE,
		payload: {
			data,
			type
		}
	}
}

export const submitPackageSuccess = () => {
	return {
		type: SUBMIT_PACKAGE_SUCCESS
	}
}

export const setShowCreateNewPackageSuccessModal = isVisible => {
	return {
		type: SET_SHOW_CREATE_NEW_PACKAGE_SUCCESS_MODAL,
		payload: isVisible
	}
}

export const addServiceToPackage = (service, pricingDriverKeys) => {
	return {
		type: ADD_SERVICE_TO_PACKAGE,
		payload: {
			service,
			pricingDriverKeys
		}
	}
}

export const removeServiceFromPackage = (service, pricingDriverKeys) => {
	return {
		type: REMOVE_SERVICE_FROM_PACKAGE,
		payload: {
			service,
			pricingDriverKeys
		}
	}
}

export const resetCreateNewPackageForm = () => {
	return {
		type: RESET_CREATE_NEW_PACKAGE_FORM
	}
}

export const getPackage = id => {
	return {
		type: GET_PACKAGE,
		payload: id
	}
}

export const getPackageSuccess = (data, servicesByCategory) => {
	return {
		type: GET_PACKAGE_SUCCESS,
		payload: {
			package: data.package,
			packageServiceMap: data.packageServiceMap,
			servicesByCategory
		}
	}
}

export const createPackageFailed = (data) => {
	return {
	  type: CREATE_NEW_PACKAGE_FAIL,
	  payload: data,
	};
  };

export const setShowCreateNewPackageFailedModal = (isVisible) => {
	return {
	  type: SET_SHOW_CREATE_NEW_PACKAGE_FAILED_MODAL,
	  payload: isVisible,
	};
  };
