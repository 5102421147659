import React, {Component} from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import {connect} from "react-redux";
import {getSubscriptionPackages, changeSubscription, onInputChange} from "../store/subscription/actions";
import {Link} from "react-router-dom";
import logoDark from "../assets/images/outbooks0.png";
import {SetupPaymentModal} from "./SetupPaymentModal";
import {CardElement} from "@stripe/react-stripe-js";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import Checkbox from "@mui/material/Checkbox";
import {formatCurrency, getValidator} from "../Utils";

class ChooseSubscriptionPackage extends Component {
    constructor(props) {
        super(props);
        this.validator = getValidator();
        this.state = {
            selectedPlan: null,
            cardErrorMessage: null,
            selectedPlanObj: null,
        }
    }
    componentDidMount() {
        this.props.getSubscriptionPackages();
    }
    componentDidUpdate(prevProps, prevState) {
        if(this.props.subscription?.plan !== prevProps.subscription?.plan) {
            this.props.onInputChange('interval', this.getInterval(this.props.subscription?.plan?.interval, this.props.subscription?.plan?.interval_count));
        }
        if(this.props.open !== prevProps.open) {
            if(this.props.open) {
                this.props.getSubscriptionPackages();
            } else {
                this.setState({
                    selectedPlan: null,
                    cardErrorMessage: null,
                    selectedPlanObj: null,
                })
            }
        }
        if((prevProps.interval !== this.props.interval || prevState.discount_enabled !== this.state.discount_enabled || prevState.discounted_price_enabled !== this.state.discounted_price_enabled) && this.props.interval && this.state.selectedPlanObj?.item) {
            let yearlyPrice = this.getSubscriptionPackagePriceValue(this.state.selectedPlanObj.item).year;
            let _price = yearlyPrice;
            if(this.props.interval === 'month') _price = yearlyPrice / 12;
            if(this.props.interval === 'halfYear') _price = yearlyPrice / 2;
            if(this.props.interval === 'quarter') _price = yearlyPrice / 4;
            console.log('this.props.interval - ', this.props.interval, _price);
            this.setState({selectedPlanObj: {...this.state.selectedPlanObj, price: _price, year: this.state.selectedPlanObj.year, interval: this.props.interval}})
        }
    }
    componentWillUnmount() {
        this.setState({
            selectedPlan: null,
            cardErrorMessage: null,
            selectedPlanObj: null,
        })
    }

    handleClose() {
        this.props.setOpen(false);
    }
    handlePackageSelection(item, itemId, is_default, name, price, interval, fee_per_year) {
        console.log('this.props.interval - ', this.props.interval);
        this.setState({selectedPlan: itemId})
        let _price = price;
        if(this.props.interval === 'month') _price = price / 12;
        if(this.props.interval === 'halfYear') _price = price / 2;
        if(this.props.interval === 'quarter') _price = price / 4;
        this.setState({selectedPlanObj: {item, name, id: itemId, is_default, price: _price, year: price, interval: this.props.interval, fee_per_year}})
        // this.props.setOpen(false);
    }
    async handlePay(monthOffer) {
        const customer = this.props.currentOrganisation.customer_id;
        const package_id = this.state.selectedPlan;

        if(this.props.isSuperAdmin) {
            const obj = {
                name: this.props.currentOrganisation.trading_business_name,
                email: this.props.currentOrganisation.email,
                phone: this.props.currentOrganisation.phone,
                package_id,
                package: {
                    interval: this.props.interval
                },
                uid: this.props.uid,
                customer: this.props.customer,
                in_price_of_month_offer_enabled: Boolean(monthOffer)
            }
            this.props.changeSubscription(obj);
            this.handleClose();
        }

        const paymentMethod = await this.props.stripe?.createPaymentMethod({
            type: "card",
            card: this.props.elements?.getElement(CardElement),
            billing_details: {
                name: this.props.currentOrganisation.trading_business_name,
                email: this.props.currentOrganisation.email ?? 'saurs2000@gmail.com',
            },
        });

        if(paymentMethod.error) {
            return this.setState({cardErrorMessage: paymentMethod.error.message});
        }

        const obj = {
            name: this.props.currentOrganisation.trading_business_name,
            email: this.props.currentOrganisation.email,
            phone: this.props.currentOrganisation.phone,
            package_id,
            package: {
                interval: this.props.interval
            },
            customer,
            paymentMethodId: paymentMethod.paymentMethod.id,
            in_price_of_month_offer_enabled: Boolean(monthOffer)
        }
        this.props.changeSubscription(obj);
        this.handleClose();
        this.setState({openSetupPaymentModal: false});
    }
    getSubscriptionPackagePriceValue(item, original) {
        if(original) return {year: item.fee_per_year, month: item.fee_per_month, isDiscounted: false}
        const isDiscounted = this.state.discount_enabled;
        const isDiscountedPrice = this.state.discounted_price_enabled;
        if(isDiscounted) return {year: this.getDiscountedValue(item.fee_per_year, item.offers.discount.value), month: this.getDiscountedValue(item.fee_per_month, item.offers.discount.value)};
        if(isDiscountedPrice) return {year: item.offers.discounted_price?.value, month: item.offers.discounted_price?.value / 12}
        return {year: item.fee_per_year, month: item.fee_per_month, isDiscounted: false};
    }
    getDiscountedValue(value, perc) {
        return value - (perc * value / 100).toFixed(2);
    }
    handleSubmitChangePlan() {
        if(!this.validator.allValid()) {
            return this.validator.showMessages();
        }
        if(this.props.hasNoPaymentMethod && !this.props.isSuperAdmin) {
            this.setState({openSetupPaymentModal: true});
            return;
        }

        const customer = this.props.currentOrganisation.customer_id;
        const package_id = this.state.selectedPlan;

        if(this.props.isSuperAdmin) {
            let obj = {
                customer: this.props.customer,
                package_id,
                package: {
                    interval: this.props.interval
                },
                uid: this.props.uid,
                in_price_of_month_offer_enabled: this.state.in_price_of_month_offer_enabled?._id === this.state.selectedPlan
            }
            if(!this.props.customer) {
                obj = {
                    name: this.props.currentOrganisation.trading_business_name,
                    email: this.props.currentOrganisation.email,
                    phone: this.props.currentOrganisation.phone,
                    package: {
                        interval: 'month'
                    },
                    uid: this.props.uid,
                    package_id,
                    in_price_of_month_offer_enabled: this.state.in_price_of_month_offer_enabled?._id === this.state.selectedPlan
                }
            }
            this.props.changeSubscription(obj);
            this.handleClose();
            return;
        }

        let obj = {
            customer,
            package_id,
            package: {
              interval: this.props.interval
            },
            paymentMethodId: this.props.paymentMethods[0].id,
            in_price_of_month_offer_enabled: this.state.in_price_of_month_offer_enabled?._id === this.state.selectedPlan
        }
        if(!customer) {
            obj = {
                name: this.props.currentOrganisation.trading_business_name,
                email: this.props.currentOrganisation.email,
                phone: this.props.currentOrganisation.phone,
                package: {
                    interval: 'month'
                },
                package_id,
                in_price_of_month_offer_enabled: this.state.in_price_of_month_offer_enabled?._id === this.state.selectedPlan
            }
        }
        this.props.changeSubscription(obj);
        this.handleClose();
    }
    getInterval(_interval, interval_count) {
        if(!_interval || !interval_count) return null;
        let interval = 'year';
        if(_interval === 'month') {
            if(interval_count === 6) interval = 'halfYear';
            if(interval_count === 3) interval = 'quarter';
            if(interval_count === 1) interval = 'month';
        }
        return interval;
    }
    render() {
        // console.log('this.props.subscriptionPackage?.plan - ', this.props.subscription);
        const currentInterval = this.getInterval(this.props.subscription?.plan?.interval, this.props.subscription?.plan?.interval_count);
        const isCurrentPlan = (item) => item._id === this.props.subscriptionPackage?.plan?._id && this.props.interval === currentInterval;
        const isSelectedPlan = (item) => (item._id === this.state.selectedPlan);
        const tdClasses = (item) => (isCurrentPlan(item) ? ' xdrfeeef' : '') + (isSelectedPlan(item) ? ' xdrfeeef2' : '');
        return (
          <React.Fragment>
              <Dialog
                fullWidth={true}
                maxWidth={'lg'}
                open={this.props.open}
                onClose={this.handleClose.bind(this)}
              >
                  <DialogTitle>Choose Plan</DialogTitle>
                  <DialogContent>
                      <div className="container-fluid">
                          <div className="table-rep-plugin">
                              <div className="table-responsive mb-0" data-pattern="priority-columns">
                                  <table className="table table-striped pricing-table">
                                      <tbody>
                                      <tr>
                                          <td width="30%" className="pricing-table-text" style={{verticalAlign: 'middle', padding: '10px'}}>
                                              <Link to="/">
                                                  <img src={logoDark} height="50" alt="logo"/>
                                              </Link>
                                          </td>
                                          {this.props.subscriptionPackages.map((item, ind) => (
                                            <td className="pachd1" style={{background: ind % 2 === 0 ? '#0598ce' : '#00AFEF'}} width="17.5%">
                                                <div className="pricing-table-item">
                                                    <div className="pricing-table-item-head">
                                                        <p>{item.name}</p>
                                                    </div>
                                                </div>
                                            </td>
                                          ))}
                                      </tr>
                                      <tr className="pricing-table-list">
                                          <td>&nbsp;</td>
                                          {this.props.subscriptionPackages.map((item, ind) => (
                                            <td style={{background: ind % 2 === 0 ? '#f3f3f3' : '#ffffff'}} className={tdClasses(item)}>&nbsp;</td>
                                          ))}
                                      </tr>
                                      <tr className="pricing-table-list">
                                          <td>Prepare Quote</td>
                                          {this.props.subscriptionPackages.map((item, ind) => (
                                            item.prepare_quote ? <td style={{background: ind % 2 === 0 ? '#f3f3f3' : '#ffffff'}} className={tdClasses(item)}><span className="fa fa-check"></span></td> : <td style={{background: ind % 2 === 0 ? '#f3f3f3' : '#ffffff'}} className={tdClasses(item)}><span className="fa fa-times"></span></td>
                                          ))}
                                      </tr>
                                      <tr className="pricing-table-list" style={{background: 'grey'}}>
                                          <td>Prepare Contract</td>
                                          {this.props.subscriptionPackages.map((item, ind) => (
                                            item.prepare_contract ? <td style={{background: ind % 2 === 0 ? '#f3f3f3' : '#ffffff'}} className={tdClasses(item)}><span className="fa fa-check"></span></td> : <td style={{background: ind % 2 === 0 ? '#f3f3f3' : '#ffffff'}} className={tdClasses(item)}><span className="fa fa-times"></span></td>
                                          ))}
                                      </tr>
                                      <tr className="pricing-table-list">
                                          <td>Send Quote</td>
                                          {this.props.subscriptionPackages.map((item, ind) => (
                                            item.send_quote ? <td style={{background: ind % 2 === 0 ? '#f3f3f3' : '#ffffff'}} className={tdClasses(item)}><span className="fa fa-check"></span></td> : <td style={{background: ind % 2 === 0 ? '#f3f3f3' : '#ffffff'}} className={tdClasses(item)}><span className="fa fa-times"></span></td>
                                          ))}
                                      </tr>
                                      <tr className="pricing-table-list">
                                          <td>Send Contract</td>
                                          {this.props.subscriptionPackages.map((item, ind) => (
                                            item.send_contract ? <td style={{background: ind % 2 === 0 ? '#f3f3f3' : '#ffffff'}} className={tdClasses(item)}><span className="fa fa-check"></span></td> : <td style={{background: ind % 2 === 0 ? '#f3f3f3' : '#ffffff'}} className={tdClasses(item)}><span className="fa fa-times"></span></td>
                                          ))}
                                      </tr>
                                      <tr className="pricing-table-list">
                                          <td>Digitally Sign the Contract</td>
                                          {this.props.subscriptionPackages.map((item, ind) => (
                                            item.sign_contract ? <td style={{background: ind % 2 === 0 ? '#f3f3f3' : '#ffffff'}} className={tdClasses(item)}><span className="fa fa-check"></span></td> : <td style={{background: ind % 2 === 0 ? '#f3f3f3' : '#ffffff'}} className={tdClasses(item)}><span className="fa fa-times"></span></td>
                                          ))}
                                      </tr>
                                      <tr className="pricing-table-list">
                                          <td>Esignatures per month</td>
                                          {this.props.subscriptionPackages.map((item, ind) => (
                                            <td style={{background: ind % 2 === 0 ? '#f3f3f3' : '#ffffff'}} className={tdClasses(item)}>{item.e_sign_per_month ?? 0}</td>
                                          ))}
                                      </tr>
                                      <tr className="pricing-table-list">
                                          <td>Fee per month</td>
                                          {this.props.subscriptionPackages.map((item, ind) => (
                                            <td className={tdClasses(item)} style={{background: ind % 2 === 0 ? '#f3f3f3' : '#ffffff'}}>
                                                <p>£ {this.getSubscriptionPackagePriceValue(item, true).month ?? 0}</p>
                                            </td>
                                          ))}
                                      </tr>
                                      <tr className="pricing-table-list">
                                          <td>Fee per year</td>
                                          {this.props.subscriptionPackages.map((item, ind) => (
                                            <td className={tdClasses(item)} style={{background: ind % 2 === 0 ? '#f3f3f3' : '#ffffff'}}>
                                                <p>£ {this.getSubscriptionPackagePriceValue(item, true).year ?? 0}</p>
                                            </td>
                                          ))}
                                      </tr>
                                      <tr className="pricing-table-list">
                                          <td>Month Offer</td>
                                          {this.props.subscriptionPackages.map((item, ind) => (
                                            <td className={tdClasses(item)} style={{background: ind % 2 === 0 ? '#f3f3f3' : '#ffffff'}}>
                                                {item.offers.month_discount?.enabled ?
                                                  <>
                                                      <p style={{color: 'dodgerblue', marginBottom: '1px', whiteSpace: 'nowrap'}}>
                                                          <Checkbox disabled={this.state.selectedPlan !== item._id} checked={this.state.in_price_of_month_offer_enabled?._id === item._id} onChange={e => {
                                                              this.setState({in_price_of_month_offer_enabled: e.target.checked ? item : false});
                                                          }}/>
                                                          Get {item.offers.month_discount?.value.got_months} months
                                                      </p>
                                                      <p style={{color: 'dodgerblue', marginBottom: 0}}>in the price of {item.offers.month_discount?.value.in_price_of_month} months</p>
                                                  </> :
                                                  null
                                                }
                                            </td>
                                          ))}
                                      </tr>
                                      <tr className="pricing-table-list">
                                          <td>Discounted Price</td>
                                          {this.props.subscriptionPackages.map((item, ind) => (
                                            <td style={{background: ind % 2 === 0 ? '#f3f3f3' : '#ffffff'}}>
                                                {item.offers.discounted_price?.enabled ?
                                                  <>
                                                      <p style={{color: 'dodgerblue', marginBottom: '1px', whiteSpace: 'nowrap'}}>
                                                          <Checkbox disabled={this.state.discount_enabled?._id === item._id || this.state.in_price_of_month_offer_enabled?._id === item._id} checked={this.state.discounted_price_enabled?._id === item._id} onChange={e => {
                                                              this.setState({discounted_price_enabled: e.target.checked ? item : false});
                                                          }}/>
                                                          Get Package at <br />Discounted Price {formatCurrency(item.offers.discounted_price?.value)}
                                                      </p>
                                                  </> :
                                                  null
                                                }
                                            </td>
                                          ))}
                                      </tr><tr className="pricing-table-list">
                                          <td>Discount</td>
                                          {this.props.subscriptionPackages.map((item, ind) => (
                                            <td style={{background: ind % 2 === 0 ? '#f3f3f3' : '#ffffff'}}>
                                                {item.offers.discount?.enabled ?
                                                  <>
                                                      <p style={{color: 'dodgerblue', marginBottom: '1px', whiteSpace: 'nowrap'}}>
                                                          <Checkbox disabled={this.state.discounted_price_enabled?._id === item._id || this.state.in_price_of_month_offer_enabled?._id === item._id} checked={this.state.discount_enabled?._id === item._id} onChange={e => {
                                                              this.setState({discount_enabled: e.target.checked ? item : false});
                                                          }}/>
                                                          {item.offers.discount?.value}% Off
                                                      </p>
                                                  </> :
                                                  null
                                                }
                                            </td>
                                          ))}
                                      </tr>
                                      <tr className="pricing-table-list">
                                          <td>First Months Free</td>
                                          {this.props.subscriptionPackages.map((item, ind) => (
                                            <td className={tdClasses(item)} style={{background: ind % 2 === 0 ? '#f3f3f3' : '#ffffff'}}>
                                                {item.offers.months_free?.enabled ?
                                                  <>
                                                      <p style={{color: 'dodgerblue', marginBottom: '1px', whiteSpace: 'nowrap'}}>
                                                          First {item.offers.months_free?.value} months free
                                                      </p>
                                                  </> :
                                                  null
                                                }
                                            </td>
                                          ))}
                                      </tr>
                                      <tr className="pricing-table-list">
                                          <td>&nbsp;</td>
                                          {this.props.subscriptionPackages.map((item, ind) => (
                                            <td className={tdClasses(item)} style={{background: ind % 2 === 0 ? '#f3f3f3' : '#ffffff'}} className={tdClasses(item)}>
                                                <button type="button" disabled={isSelectedPlan(item) || isCurrentPlan(item)} className={"btn btn-free btn-block" + (isCurrentPlan(item) ? " xdrfeeef1 cursor-not-allowed" : '')} data-toggle="modal"
                                                        data-target="#exampleModalCenter" onClick={() => this.handlePackageSelection(item, item._id, item.is_default, item.name, this.getSubscriptionPackagePriceValue(item).year, this.props.interval, item.fee_per_year)}>
                                                    {isCurrentPlan(item)  ? "Current Plan" : isSelectedPlan(item) ? "Selected Plan" : "Select Plan"}
                                                </button>
                                            </td>
                                          ))}
                                          {/*<td>*/}
                                          {/*  <button type="button" className="btn btn-statup btn-block" data-toggle="modal"*/}
                                          {/*          data-target="#exampleModalCenter">*/}
                                          {/*    Select Package*/}
                                          {/*  </button>*/}
                                          {/*</td>*/}
                                          {/*<td>*/}
                                          {/*  <button type="button" className="btn btn-small btn-block" data-toggle="modal"*/}
                                          {/*          data-target="#exampleModalCenter">*/}
                                          {/*    Select Package*/}
                                          {/*  </button>*/}
                                          {/*</td>*/}
                                          {/*<td>*/}
                                          {/*  <button type="button" className="btn btn-medium btn-block" data-toggle="modal"*/}
                                          {/*          data-target="#exampleModalCenter">*/}
                                          {/*    Select Package*/}
                                          {/*  </button>*/}
                                          {/*</td>*/}
                                      </tr>
                                      <tr className="pricing-table-list">
                                          <td>Frequency</td>
                                          <td colSpan={this.props.subscriptionPackages.length}>
                                              <RadioGroup
                                                row
                                                aria-labelledby="demo-row-radio-buttons-group-label"
                                                className={"interval-radio-group"}
                                                name="interval-radio-group"
                                                onChange={e => {
                                                    console.log('interval - ', this.props.interval, e.target.value);
                                                    this.props.onInputChange('interval', e.target.value)
                                                }}
                                                value={this.props.interval}
                                                aria-disabled={true}
                                              >
                                                  <FormControlLabel disabled={this.state.in_price_of_month_offer_enabled?._id === this.state.selectedPlan} value="month" control={<Radio />} label="Monthly" />
                                                  <FormControlLabel disabled={this.state.in_price_of_month_offer_enabled?._id === this.state.selectedPlan} value="quarter" control={<Radio />} label="Quarterly" />
                                                  <FormControlLabel disabled={this.state.in_price_of_month_offer_enabled?._id === this.state.selectedPlan} value="halfYear" control={<Radio />} label="Half yearly" />
                                                  <FormControlLabel disabled={this.state.in_price_of_month_offer_enabled?._id === this.state.selectedPlan} value="year" control={<Radio />} label="Yearly" />
                                              </RadioGroup>
                                              {this.validator.message('interval', this.props.interval, '_required')}
                                          </td>
                                      </tr>
                                      <tr className="pricing-table-list">
                                          <td className="lastbox1">&nbsp;</td>
                                          {this.props.subscriptionPackages.map(item => (
                                            <td>&nbsp;</td>
                                          ))}
                                          {/*<td>&nbsp;</td>*/}
                                          {/*<td>&nbsp;</td>*/}
                                          {/*<td className="lastbox">&nbsp;</td>*/}
                                      </tr>
                                      </tbody>
                                  </table>

                              </div>
                          </div>
                      </div>
                  </DialogContent>
                  <DialogActions>
                      <button className="btn btn-primary btn-md" disabled={!Boolean(this.state.selectedPlan) || isCurrentPlan({_id: this.state.selectedPlan})} onClick={this.handleSubmitChangePlan.bind(this)}>Submit</button>
                      <button className="btn btn-secondary btn-md" onClick={this.handleClose.bind(this)}>Close</button>
                  </DialogActions>
              </Dialog>
              <SetupPaymentModal
                monthOffer={this.state.in_price_of_month_offer_enabled?._id === this.state.selectedPlan ? this.state.in_price_of_month_offer_enabled : null}
                discountedOffer={this.state.discounted_price_for_month_enabled?._id === this.state.selectedPlan ? this.state.discounted_price_for_month_enabled : null}
                discountedPriceOffer={this.state.discounted_price_enabled?._id === this.state.selectedPlan ? this.state.discounted_price_enabled : null}
                discount={this.state.discount_enabled?._id === this.state.selectedPlan ? this.state.discount_enabled : null}
                selectedPlan={this.state.selectedPlanObj}
                cardErrorMessage={this.state.cardErrorMessage}
                onChange={this.props.onInputChange}
                handlePay={this.handlePay.bind(this)}
                open={this.state.openSetupPaymentModal}
                setOpen={(e) => {this.setState({openSetupPaymentModal: e})}}
              />
          </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    const { subscriptionPackages } = state.SubscriptionPackage;
    const { subscriptionPackage, currentOrganisation, subscription } = state.Organisation;
    let subPackages = subscriptionPackages.filter(c => c.is_active && !c.is_default);
    return { currentOrganisation, subscriptionPackage, ...state.Subscription, subscriptionPackages: subPackages, subscription }
}

export default connect(mapStateToProps, {onInputChange, getSubscriptionPackages, changeSubscription})(ChooseSubscriptionPackage);
