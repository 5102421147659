import {
	GET_PAYMENT_GATEWAYS,
	GET_PAYMENT_GATEWAYS_SUCCESS,
	SAVE_PAYMENT_GATEWAY,
	SAVE_PAYMENT_GATEWAY_SUCCESS,
	ON_SETTING_INPUT_CHANGE,
	SAVE_PAYMENT_GATEWAY_SUCCESS_MODAL
} from "./actionTypes";

export const onInputChange = (field, value) => {
	return {
        type: ON_SETTING_INPUT_CHANGE,
        payload: {
        	field,
        	value
        }
    }
}

export const getPaymentGateways = () => {
	return {
		type: GET_PAYMENT_GATEWAYS
	}
}

export const getPaymentGatewaysSuccess = (gateways) => {
	return {
		type: GET_PAYMENT_GATEWAYS_SUCCESS,
		payload: gateways
	}
}

export const savePaymentGateway = (data) => {
	return {
		type: SAVE_PAYMENT_GATEWAY,
		payload: data
	}
}

export const savePaymentGatewaySuccess = () => {
	return {
		type: SAVE_PAYMENT_GATEWAY_SUCCESS
	}
}

export const setSavePaymentKeySuccessModal = (data) => {
	return {
		type: SAVE_PAYMENT_GATEWAY_SUCCESS_MODAL,
		payload: data
	}
}
