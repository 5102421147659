export const SET_QUOTE_LOADING = "SET_QUOTE_LOADING";
export const ON_QUOTE_INPUT_CHANGE = "ON_QUOTE_INPUT_CHANGE";
export const ADD_SERVICE_TO_A_LA_CARTE_PACKAGE =
  "ADD_SERVICE_TO_A_LA_CARTE_PACKAGE";
export const REMOVE_SERVICE_FROM_A_LA_CARTE_PACKAGE =
  "REMOVE_SERVICE_FROM_A_LA_CARTE_PACKAGE";
export const SET_QUOTE_ACTIVE_STEP = "SET_QUOTE_ACTIVE_STEP";
export const PREVIEW_QUOTE = "PREVIEW_QUOTE";
export const PREVIEW_QUOTE_SUCCESS = "PREVIEW_QUOTE_SUCCESS";
export const SAVE_QUOTATION_AS_DRAFT = "SAVE_QUOTATION_AS_DRAFT";
export const SAVE_QUOTATION_AS_DRAFT_SUCCESS =
  "SAVE_QUOTATION_AS_DRAFT_SUCCESS";
export const SEND_QUOTATION = "SEND_QUOTATION";
export const SEND_QUOTATION_SUCCESS = "SEND_QUOTATION_SUCCESS";
export const SKIP_QUOTATION = "SKIP_QUOTATION";
export const SKIP_QUOTATION_SUCCESS = "SKIP_QUOTATION_SUCCESS";
export const CALCULATE_PACKAGE_PRICE = "CALCULATE_PACKAGE_PRICE";
export const CALCULATE_PACKAGE_PRICE_SUCCESS =
  "CALCULATE_PACKAGE_PRICE_SUCCESS";
export const SET_SHOW_CREATE_NEW_QUOTE_SUCCESS_MODAL =
  "SET_SHOW_CREATE_NEW_QUOTE_SUCCESS_MODAL";
export const SET_SHOW_ADD_CUSTOM_PACKAGE_MODAL =
  "SET_SHOW_ADD_CUSTOM_PACKAGE_MODAL";
export const RESET_CREATE_NEW_QUOTE_FORM = "RESET_CREATE_NEW_QUOTE_FORM";

export const GET_QUOTES = "GET_QUOTES";
export const GET_QUOTES_SUCCESS = "GET_QUOTES_SUCCESS";
export const GET_QUOTE_STATUS = "GET_QUOTE_STATUS";
export const GET_QUOTE = "GET_QUOTE";
export const GET_QUOTE_SUCCESS = "GET_QUOTE_SUCCESS";

export const GET_SENT_QUOTES = "GET_SENT_QUOTES";
export const GET_SENT_QUOTES_SUCCESS = "GET_SENT_QUOTES_SUCCESS";

export const GET_SKIP_QUOTES = "GET_SKIP_QUOTES";
export const GET_SKIP_QUOTES_SUCCESS = "GET_SKIP_QUOTES_SUCCESS";

export const ADD_PACKAGED_PRICING_PACKAGE = "ADD_PACKAGED_PRICING_PACKAGE";
export const REMOVE_PACKAGED_PRICING_PACKAGE =
  "REMOVE_PACKAGED_PRICING_PACKAGE";

export const REVIEW_PACKAGED_PRICING_PACKAGES =
  "REVIEW_PACKAGED_PRICING_PACKAGES";
export const REVIEW_PACKAGED_PRICING_PACKAGES_SUCCESS =
  "REVIEW_PACKAGED_PRICING_PACKAGES_SUCCESS";

export const REVIEW_CUSTOM_PACKAGED_PRICING_PACKAGES =
  "REVIEW_CUSTOM_PACKAGED_PRICING_PACKAGES";
export const REVIEW_CUSTOM_PACKAGED_PRICING_PACKAGES_SUCCESS =
  "REVIEW_CUSTOM_PACKAGED_PRICING_PACKAGES_SUCCESS";

export const GET_PRICING_DRIVERS_BY_PACKAGE_IDS =
  "GET_PRICING_DRIVERS_BY_PACKAGE_IDS";
export const GET_PRICING_DRIVERS_BY_PACKAGE_IDS_SUCCESS =
  "GET_PRICING_DRIVERS_BY_PACKAGE_IDS_SUCCESS";

export const ADD_CUSTOM_PACKAGE = "ADD_CUSTOM_PACKAGE";
export const SET_ADD_CUSTOM_PACKAGE_MODAL_SERVICES =
  "SET_ADD_CUSTOM_PACKAGE_MODAL_SERVICES";
export const EDIT_ADD_CUSTOM_PACKAGE_MODAL = "EDIT_ADD_CUSTOM_PACKAGE_MODAL";

export const GET_PRICING_DRIVERS_FOR_CUSTOM_PACKAGED_PRICING =
  "GET_PRICING_DRIVERS_FOR_CUSTOM_PACKAGED_PRICING";
export const GET_PRICING_DRIVERS_FOR_CUSTOM_PACKAGED_PRICING_SUCCESS =
  "GET_PRICING_DRIVERS_FOR_CUSTOM_PACKAGED_PRICING_SUCCESS";

export const SEARCH_QUOTE = "SEARCH_QUOTE";
export const SEARCH_QUOTE_SUCCESS = "SEARCH_QUOTE_SUCCESS";
export const GET_QUOTE_CLIENT = "GET_QUOTE_CLIENT";
export const GET_QUOTE_CLIENT_SUCCESS = "GET_QUOTE_CLIENT_SUCCESS";
export const RESET_SEARCH_INPUT = "RESET_SEARCH_INPUT";