import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { getCategories, setShowCreateCategoryModal } from "../store/service/actions";
import { setShowConfirmDeleteModal } from "../store/delete/actions";
import Loader from "../components/Loader";
import ConfirmDeleteModal from "../components/ConfirmDeleteModal";
import DeleteSuccessModal from "../components/DeleteSuccessModal";
import CategoryListTable from "../components/CategoryListTable";
import CreateCategoryModal from "../components/CreateCategoryModal";
const delObject = "Service Category";

class ServiceCategories extends Component {
  componentDidMount() {
    this.props.getCategories();
  }
  render() {
    return (
      <React.Fragment>
        {this.props.loading && <Loader />} 
        <div className="page-info-header">
          <div className="container-fluid">
            <div className="page-display-title">Service Categories</div>
            <div className="text-right flex1">
                <button onClick={() => this.props.setShowCreateCategoryModal(true)} className="btn btn-success create-item" disabled><i className="ion ion-md-add-circle"></i> <span>Add New Category</span></button>
              </div>
          </div>
        </div>
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="card cus-card">
                <div className="card-body">
                  <CategoryListTable
                    delete={this.props.setShowConfirmDeleteModal}
                    categories={this.props.categories}
                    setShowCreateCategoryModal={this.props.setShowCreateCategoryModal}
                    pagination={this.props.pagination}
                    // linkedServices={this.props.linkedServices} {...this.props}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
     <CreateCategoryModal />
        <ConfirmDeleteModal />
        <DeleteSuccessModal delObject={delObject} /> 
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  const {
    categories, pagination, loading
  } = state.Service;	
  const { showConfirmDeleteModal } = state.Delete;

  return {
    categories, pagination,loading,showConfirmDeleteModal
  };
}

export default connect(mapStateToProps, { getCategories, setShowCreateCategoryModal, setShowConfirmDeleteModal })(ServiceCategories);
