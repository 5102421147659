import React, { Component } from "react";
import { Modal, ModalBody } from "reactstrap";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import SweetAlert from "react-bootstrap-sweetalert";
import Select from "react-select";
import DatePicker from "react-date-picker";
import AutoCompleteInput from "./AutoCompleteInput";
import Loader from "./Loader";
import OrganisationAddress from "./OrganisationAddress";
import OrganisationPartner from "./OrganisationPartner";
import OrganisationOfficer from "./OrganisationOfficer";
import InputPhone from "./InputPhone";
import {
  clientBusinessTypes,
  salutations,
  vatValues,
  incorporatedInCountryOptions,
} from "../config";
import {
  formatDate,
  formatAddress,
  utcDateToCurrentDate,
  getValidator,
  getPhoneObject,
} from "../Utils";
import {
  setShowCreateClientModal,
  setShowCreateNewClientSuccessModal,
  onInputChange,
  submitClient,
} from "../store/client/actions";
import {
  selectCompanySearchItem,
  companySearch,
  addPartner,
  deletePartner,
  addOfficer,
  deleteOfficer,
  onInputChange as onOrganisationInputChange,
  resetOrganisationForm,
} from "../store/organisation/actions";
import { resetCreateNewClientForm } from "../store/client/actions";
import * as EmailValidator from "email-validator";

class CreateClientModal extends Component {
  constructor(props) {
    super(props);
    this.props.resetOrganisationForm();
    this.props.resetCreateNewClientForm();
    this.validator = getValidator();
    this.formatDataAndSubmitClient = this.formatDataAndSubmitClient.bind(this);
    this.toggle = this.toggle.bind(this);
    this.clearModal = this.clearModal.bind(this);
    this.renderContactInformation = this.renderContactInformation.bind(this);
    this.renderIndividualClient = this.renderIndividualClient.bind(this);
    this.renderSoleTrader = this.renderSoleTrader.bind(this);
    this.renderPartnership = this.renderPartnership.bind(this);
    this.renderCompany = this.renderCompany.bind(this);
    this.validatePartners = this.validatePartners.bind(this);
    this.validateOfficers = this.validateOfficers.bind(this);
    this.state = this.initialState;
    this.validator.hideMessages();
  }
  get initialState() {
    return { phoneEmailValidator: false };
  }
  generateNewPartnerCode() {
    return new Date().getTime();
  }
  generateNewOfficerCode() {
    return new Date().getTime();
  }
  renderVatInput() {
    return (
      <React.Fragment>
        <div className="row fieldset">
          <div className="col-3 text-right">
            <label className="fieldset-label">VAT Registered</label>
          </div>
          <div className="col-9">
            <Select
              options={vatValues}
              value={
                typeof this.props.organisation.vatRegistered === "boolean"
                  ? vatValues.filter(
                    (option) =>
                      option.value === this.props.organisation.vatRegistered
                  )
                  : vatValues.filter((option) => option.value === false)
              }
              onChange={(selectedOption) =>
                this.props.onOrganisationInputChange(
                  "vatRegistered",
                  selectedOption.value
                )
              }
            />
            {/* {this.validator.message('vat Registered', this.props.organisation.vatRegistered, '', { className: 'text-danger' })} */}
          </div>
        </div>
        {this.props.organisation.vatRegistered && (
          <div className="row fieldset">
            <div className="col-3 text-right">
              <label className="fieldset-label">VAT Number</label>
            </div>
            <div className="col-9">
              <input
                value={this.props.organisation.vatNumber || ""}
                onChange={(e) =>
                  this.props.onOrganisationInputChange(
                    "vatNumber",
                    e.target.value
                  )
                }
                className="input-text"
                type="text"
                placeholder="VAT Number"
              />
              {/*   {this.validator.message('vat Number', this.props.organisation.vatNumber, '_required', { className: 'text-danger' })} */}
            </div>
          </div>
        )}
      </React.Fragment>
    );
  }
  validatePartners() {
    let valid = true;
    for (let code of this.props.organisation.partnerCodes) {
      if (
        !(
          this.validator.fields[`partner${code}FirstName`] &&
          this.validator.fields[`partner${code}LastName`] &&
          this.validator.fields[`partner${code}Email`] &&
          this.validator.fields[`partner${code}Phone`] &&
          ((this.props.organisation[`partner${code}Email`] &&
            EmailValidator.validate(
              this.props.organisation[`partner${code}Email`]
            )) ||
            (this.props.organisation[`partner${code}Phone`] &&
              this.props.organisation[`partner${code}Phone`].length === 10)) &&
          this.validator.fields[`partner${code}Address`]
        )
      ) {
        valid = false;
        break;
      }
    }
    return valid;
  }
  validateOfficers() {
    let valid = true;
    for (let code of this.props.organisation.officerCodes) {
      if (
        !(
          this.validator.fields[`officer${code}FirstName`] &&
          this.validator.fields[`officer${code}LastName`] &&
          this.validator.fields[`officer${code}Email`] &&
          this.validator.fields[`officer${code}Phone`] &&
          ((this.props.organisation[`officer${code}Email`] &&
            EmailValidator.validate(
              this.props.organisation[`officer${code}Email`]
            )) ||
            (this.props.organisation[`officer${code}Phone`] &&
              this.props.organisation[`officer${code}Phone`].length === 10)) &&
          this.validator.fields[`officer${code}Address`] &&
          this.validator.fields[`officer${code}Role`] &&
          this.validator.fields[`officer${code}AppointedOn`]
        )
      ) {
        valid = false;
        break;
      }
    }
    return valid;
  }
  formatDataAndSubmitClient(type = "create") {
    // console.log('validator - ', this.validator);
    // return;
    if (type === "create" ? this.validator.fields["Prospect Type"] : true) {
      const data = {
        businessType: this.props.clientBusinessType,
        website: this.props.organisation.website,
      };
      if (type === "edit") {
        data.id = this.props.clientId;
        delete data.businessType;
      }
      // if (
      //   this.props.clientBusinessType !== "individual" &&
      //   this.props.clientBusinessType !== "soleTrader"
      // ) {
      //   data.contact = {
      //     firstName: this.props.organisation.contactFirstName,
      //     lastName: this.props.organisation.contactLastName,
      //     phone: getPhoneObject(
      //       this.props.organisation.contactDialCode,
      //       this.props.organisation.contactPhone
      //     ),
      //     // phone: {code: this.props.organisation.contactDialCode, number: this.props.organisation.contactPhone},
      //     email: this.props.organisation.contactEmail,
      //   };
      // }
      if (this.props.clientBusinessType !== "individual") {
        data.tradingDetails = {
          name: this.props.organisation.tradingName,
          address: {
            premises: this.props.organisation.tradingPremises,
            addressLine1: this.props.organisation.tradingAddressLine1,
            addressLine2: this.props.organisation.tradingAddressLine2,
            locality: this.props.organisation.tradingLocality,
            region: this.props.organisation.tradingRegion,
            country: this.props.organisation.tradingCountry,
            postcode: this.props.organisation.tradingPostcode,
          },
        };
        data.vatRegistered = this.props.organisation.vatRegistered;
        data.vatNumber = this.props.organisation.vatNumber;
      }
      switch (this.props.clientBusinessType) {
        case "individual":
          let individual;
          if (
            this.validator.fields["individualFirstName"] &&
            this.validator.fields["individualLastName"] &&
            this.validator.fields["individualEmail"] &&
            this.validator.fields["individualPhone"] &&
            // (
            // 	(this.props.organisation.individualEmail && EmailValidator.validate(this.props.organisation.individualEmail)) ||
            // 	(this.props.organisation.individualPhone && this.props.organisation.individualPhone.length === 10)
            // ) &&
            this.validator.fields["individualAddress"] &&
            this.validator.fields["Website"]
          ) {
            individual = {
              firstName: this.props.organisation.individualFirstName,
              lastName: this.props.organisation.individualLastName,
              email: this.props.organisation.individualEmail,
              phone: getPhoneObject(
                this.props.organisation.individualDialCode,
                this.props.organisation.individualPhone
              ),
              // phone: {code: this.props.organisation.individualDialCode, number: this.props.organisation.individualPhone},
              address: {
                premises: this.props.organisation.individualPremises,
                addressLine1: this.props.organisation.individualAddressLine1,
                addressLine2: this.props.organisation.individualAddressLine2,
                locality: this.props.organisation.individualLocality,
                region: this.props.organisation.individualRegion,
                country: this.props.organisation.individualCountry,
                postcode: this.props.organisation.individualPostcode,
              },
            };
            this.props.submitClient(data, type);
          } else {
            // if (
            // 	(this.props.organisation.individualEmail || !EmailValidator.validate(this.props.organisation.individualEmail)) ||
            // 	(this.props.organisation.individualPhone || this.props.organisation.individualPhone.length != 10)
            // ) {
            // 	this.setState({ phoneEmailValidator: true })
            // }
            this.validator.showMessages();
            this.forceUpdate();
            break;
          }

          data.individual = individual;
          break;
        case "soleTrader":
          let soleTrader;
          if (
            // this.validator.fields['contactFirstName'] &&
            // this.validator.fields['contactLastName'] &&
            // this.validator.fields['contactPhone'] &&
            // this.validator.fields['contactEmail'] &&
            this.validator.fields["Trading Name"] &&
            this.validator.fields["Trading Address"] &&
            this.validator.fields["Website"] &&
            (this.validator.fields["vat Registered"] ||
              this.props.organisation.vatRegistered === true ||
              !this.props.organisation.vatRegistered) &&
            this.validator.fields["soleTraderFirstName"] &&
            this.validator.fields["soleTraderLastName"] &&
            this.validator.fields["soleTraderPhone"] &&
            this.validator.fields["soleTraderEmail"] &&



            // (
            // 	(this.props.organisation.soleTraderEmail && EmailValidator.validate(this.props.organisation.soleTraderEmail)) ||
            // 	(this.props.organisation.soleTraderPhone && this.props.organisation.soleTraderPhone.length === 10)
            // ) &&
            this.validator.fields["soleTraderAddress"]
          ) {
            soleTrader = {
              firstName: this.props.organisation.soleTraderFirstName,
              lastName: this.props.organisation.soleTraderLastName,
              phone: getPhoneObject(
                this.props.organisation.soleTraderDialCode,
                this.props.organisation.soleTraderPhone
              ),
              // phone: {code: this.props.organisation.soleTraderDialCode, number: this.props.organisation.soleTraderPhone},
              email: this.props.organisation.soleTraderEmail,
              residentialAddress: {
                premises: this.props.organisation.soleTraderPremises,
                addressLine1: this.props.organisation.soleTraderAddressLine1,
                addressLine2: this.props.organisation.soleTraderAddressLine2,
                locality: this.props.organisation.soleTraderLocality,
                region: this.props.organisation.soleTraderRegion,
                country: this.props.organisation.soleTraderCountry,
                postcode: this.props.organisation.soleTraderPostcode,
              },
            };
            this.props.submitClient(data, type);
          } else {
            // if (
            // 	(this.props.organisation.soleTraderEmail || !EmailValidator.validate(this.props.organisation.soleTraderEmail)) ||
            // 	(this.props.organisation.soleTraderPhone || this.props.organisation.soleTraderPhone.length != 10)
            // ) {
            // 	this.setState({ phoneEmailValidator: true })
            // }
            this.validator.showMessages();
            this.forceUpdate();
            break;
          }

          data.soleTrader = soleTrader;
          break;
        case "partnership":
          if (
            // this.validator.fields["contactFirstName"] &&
            // this.validator.fields["contactLastName"] &&
            // this.validator.fields["contactPhone"] &&
            // this.validator.fields["contactEmail"] &&
            // (
            // 	(this.props.organisation.contactEmail && EmailValidator.validate(this.props.organisation.contactEmail)) ||
            // 	(this.props.organisation.contactPhone && this.props.organisation.contactPhone.length === 10)
            // ) &&
            this.validator.fields["Trading Name"] &&
            this.validator.fields["Trading Address"] &&
            this.validator.fields["Website"] &&
            (this.validator.fields["vat Registered"] ||
              this.props.organisation.vatRegistered === true ||
              !this.props.organisation.vatRegistered) &&

            this.validatePartners() &&
            this.validator.fields["partnersArray"]
          ) {
            data.partners = [];
            this.props.organisation.partnerCodes.forEach((partnerCode) => {
              let partner = {
                id: partnerCode,
                isNew: this.props.organisation[`partner${partnerCode}IsNew`],
                isAuthorisedSignatory:
                  this.props.organisation[
                  `partner${partnerCode}IsAuthorisedSignatory`
                  ] || false,
                firstName:
                  this.props.organisation[`partner${partnerCode}FirstName`],
                lastName:
                  this.props.organisation[`partner${partnerCode}LastName`],
                phone: getPhoneObject(
                  this.props.organisation[`partner${partnerCode}DialCode`],
                  this.props.organisation[`partner${partnerCode}Phone`]
                ),
                // phone: {code: this.props.organisation[`partner${partnerCode}DialCode`], number: this.props.organisation[`partner${partnerCode}Phone`]},
                email: this.props.organisation[`partner${partnerCode}Email`],
                residentialAddress: {
                  premises:
                    this.props.organisation[`partner${partnerCode}Premises`],
                  addressLine1:
                    this.props.organisation[
                    `partner${partnerCode}AddressLine1`
                    ],
                  addressLine2:
                    this.props.organisation[
                    `partner${partnerCode}AddressLine2`
                    ],
                  locality:
                    this.props.organisation[`partner${partnerCode}Locality`],
                  region:
                    this.props.organisation[`partner${partnerCode}Region`],
                  country:
                    this.props.organisation[`partner${partnerCode}Country`],
                  postcode:
                    this.props.organisation[`partner${partnerCode}Postcode`],
                },
              };
              data.partners.push(partner);
            });
            this.props.submitClient(data, type);
          } else {
            // if (
            // 	(this.props.organisation.contactEmail || !EmailValidator.validate(this.props.organisation.contactEmail)) ||
            // 	(this.props.organisation.contactPhone || this.props.organisation.contactPhone.length != 10)
            // ) {
            // 	this.setState({ phoneEmailValidator: true })
            // }
            this.validator.showMessages();
            this.forceUpdate();
            break;
          }
          break;
        case "llp":
        case "ltd":
          if (
            // this.validator.fields["contactFirstName"] &&
            // this.validator.fields["contactLastName"] &&
            // this.validator.fields["contactPhone"] &&
            // this.validator.fields["contactEmail"] &&
            // (
            // 	(this.props.organisation.contactEmail && EmailValidator.validate(this.props.organisation.contactEmail)) ||
            // 	(this.props.organisation.contactPhone && this.props.organisation.contactPhone.length === 10)
            // ) &&
            this.validator.fields["Trading Name"] &&
            this.validator.fields["Trading Address"] &&
            this.validator.fields["Website"] &&
            (this.validator.fields["vat Registered"] ||
              this.props.organisation.vatRegistered === true ||
              !this.props.organisation.vatRegistered) &&
            this.validator.fields["companyName"] &&
            this.validator.fields["companyNumber"] &&
            this.validator.fields["companyIncorporatedIn"] &&
            this.validator.fields["companyAddress"] &&
            this.validateOfficers() &&
            this.validator.fields["officersArray"]
          ) {
            data.officers = [];
            this.props.organisation.officerCodes.forEach((officerCode) => {
              let officer = {
                id: officerCode,
                isNew: this.props.organisation[`officer${officerCode}IsNew`],
                isAuthorisedSignatory:
                  this.props.organisation[
                  `officer${officerCode}IsAuthorisedSignatory`
                  ] || false,
                firstName:
                  this.props.organisation[`officer${officerCode}FirstName`],
                lastName:
                  this.props.organisation[`officer${officerCode}LastName`],
                role: this.props.organisation[`officer${officerCode}Role`],
                appointedOn:
                  this.props.organisation[`officer${officerCode}AppointedOn`],
                nationality:
                  this.props.organisation[`officer${officerCode}Nationality`],
                countryOfResidence:
                  this.props.organisation[
                  `officer${officerCode}CountryOfResidence`
                  ],
                phone: getPhoneObject(
                  this.props.organisation[`officer${officerCode}DialCode`],
                  this.props.organisation[`officer${officerCode}Phone`]
                ),
                // phone: {code: this.props.organisation[`officer${officerCode}DialCode`], number: this.props.organisation[`officer${officerCode}Phone`]},
                email: this.props.organisation[`officer${officerCode}Email`],
                correspondenceAddress: {
                  premises:
                    this.props.organisation[`officer${officerCode}Premises`],
                  addressLine1:
                    this.props.organisation[
                    `officer${officerCode}AddressLine1`
                    ],
                  addressLine2:
                    this.props.organisation[
                    `officer${officerCode}AddressLine2`
                    ],
                  locality:
                    this.props.organisation[`officer${officerCode}Locality`],
                  region:
                    this.props.organisation[`officer${officerCode}Region`],
                  country:
                    this.props.organisation[`officer${officerCode}Country`],
                  postcode:
                    this.props.organisation[`officer${officerCode}Postcode`],
                },
              };
              data.officers.push(officer);
            });
            data.company = {
              name: this.props.organisation.companyName,
              number: this.props.organisation.companyNumber,
              incorporatedIn: this.props.organisation.companyIncorporatedIn,
            };
            this.props.submitClient(data, type);
          } else {
            // if (
            // 	(this.props.organisation.contactEmail || !EmailValidator.validate(this.props.organisation.contactEmail)) ||
            // 	(this.props.organisation.contactPhone || this.props.organisation.contactPhone.length != 10)
            // ) {
            // 	this.setState({ phoneEmailValidator: true })
            // }
            this.validator.showMessages();
            this.forceUpdate();
            break;
          }
          break;
        default:
          break;
      }
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
    // this.props.submitClient(data, type);
  }
  toggle() {
    this.validator.hideMessages();
    this.props.setShowCreateClientModal(!this.props.showCreateClientModal);
    this.props.resetCreateNewClientForm();
    this.props.resetOrganisationForm();
    this.setState(this.initialState);
  }
  clearModal() {
    this.validator.hideMessages();
    this.props.setShowCreateClientModal(false);
    this.props.setShowCreateNewClientSuccessModal(false);
    this.props.resetCreateNewClientForm();
    this.props.resetOrganisationForm();
    this.setState(this.initialState);
  }
  renderContactInformation() {
    return (
      <div className="fieldset-group">
        <label className="fieldset-group-label">Contact Information</label>
        <div className="row fieldset">
          <div className="col-3 text-right">
            <label className="fieldset-label required">First Name</label>
          </div>
          <div className="col-9">
            <input
              onChange={(e) =>
                this.props.onOrganisationInputChange(
                  "contactFirstName",
                  e.target.value
                )
              }
              value={this.props.organisation.contactFirstName || ""}
              className="input-text"
              type="text"
              placeholder="First Name"
            />
            {this.validator.message(
              "contactFirstName",
              this.props.organisation.contactFirstName,
              "_required|_name|_max:50",
              { className: "text-danger" }
            )}
          </div>
        </div>
        <div className="row fieldset">
          <div className="col-3 text-right">
            <label className="fieldset-label required">Last Name</label>
          </div>
          <div className="col-9">
            <input
              onChange={(e) =>
                this.props.onOrganisationInputChange(
                  "contactLastName",
                  e.target.value
                )
              }
              value={this.props.organisation.contactLastName || ""}
              className="input-text"
              type="text"
              placeholder="Last Name"
            />
            {this.validator.message(
              "contactLastName",
              this.props.organisation.contactLastName,
              "_required|_name|_max:50",
              { className: "text-danger" }
            )}
          </div>
        </div>
        <div className="row fieldset">
          <div className="col-3 text-right">
            <label className="fieldset-label">Phone</label>
          </div>
          <div className="col-9">
            <InputPhone
              placeholder="Phone"
              phoneFieldName="contactPhone"
              dialCodeFieldName="contactDialCode"
              dialCode={this.props.organisation.contactDialCode}
              phone={this.props.organisation.contactPhone}
              validator={this.validator}
              onChange={this.props.onOrganisationInputChange}
            />
          </div>
        </div>
        <div className="row fieldset">
          <div className="col-3 text-right">
            <label className="fieldset-label required">Email</label>
          </div>
          <div className="col-9">
            <input
              onChange={(e) =>
                this.props.onOrganisationInputChange(
                  "contactEmail",
                  e.target.value
                )
              }
              value={this.props.organisation.contactEmail || ""}
              className="input-text"
              type="text"
              placeholder="Email"
            />
            {this.validator.message(
              "contactEmail",
              this.props.organisation.contactEmail,
              "_required|_email",
              { className: "text-danger" }
            )}
          </div>
        </div>
      </div>
    );
  }
  renderIndividualClient() {
    return (
      <React.Fragment>
        <div className="row fieldset">
          <div className="col-3 text-right">
            <label className="fieldset-label required">Prospect Name</label>
          </div>
          <div className="col-9">
            <div className="row">
              <div className="col-6">
                <input
                  onChange={(e) =>
                    this.props.onOrganisationInputChange(
                      "individualFirstName",
                      e.target.value
                    )
                  }
                  value={this.props.organisation.individualFirstName || ""}
                  className="input-text required"
                  type="text"
                  placeholder="First Name"
                />
                {this.validator.message(
                  "individualFirstName",
                  this.props.organisation.individualFirstName,
                  "_required|_name|_max:50",
                  { className: "text-danger" }
                )}
              </div>
              <div className="col-6">
                <input
                  onChange={(e) =>
                    this.props.onOrganisationInputChange(
                      "individualLastName",
                      e.target.value
                    )
                  }
                  value={this.props.organisation.individualLastName || ""}
                  className="input-text"
                  type="text"
                  placeholder="Last Name"
                />
                {this.validator.message(
                  "individualLastName",
                  this.props.organisation.individualLastName,
                  "_required|_name|_max:50",
                  { className: "text-danger" }
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="row fieldset">
          <div className="col-3 text-right">
            <label className="fieldset-label required">Prospect Email</label>
          </div>
          <div className="col-9">
            <input
              onChange={(e) =>
                this.props.onOrganisationInputChange(
                  "individualEmail",
                  e.target.value
                )
              }
              value={this.props.organisation.individualEmail || ""}
              className="input-text"
              type="text"
              placeholder="Prospect Email"
            />
            {this.validator.message(
              "individualEmail",
              this.props.organisation.individualEmail,
              "_required|_email",
              { className: "text-danger" }
            )}
          </div>
        </div>
        <div className="row fieldset">
          <div className="col-3 text-right">
            <label className="fieldset-label">Prospect Phone</label>
          </div>
          <div className="col-9">
            <InputPhone
              placeholder="Prospect Phone"
              phoneFieldName="individualPhone"
              dialCodeFieldName="individualDialCode"
              dialCode={this.props.organisation.individualDialCode}
              phone={this.props.organisation.individualPhone}
              validator={this.validator}
              onChange={this.props.onOrganisationInputChange}
            />
          </div>
        </div>
        <div className="row fieldset">
          <div className="col-3 text-right">
            <label className="fieldset-label">Website</label>
          </div>
          <div className="col-9">
            <input
              onChange={(e) =>
                this.props.onOrganisationInputChange("website", e.target.value)
              }
              value={this.props.organisation.website || ""}
              className="input-text"
              type="text"
              placeholder="Website"
            />
            {this.validator.message(
              "Website",
              this.props.organisation.website,
              "_url",
              { className: "text-danger" }
            )}
          </div>
        </div>
        <div className="row fieldset">
          <div className="col-3 text-right">
            <label className="fieldset-label required">Address</label>
          </div>
          <div className="col-9">
            <OrganisationAddress
              addressHeader="Address"
              addressPrefix="individual"
              addressSearch={this.props.organisation.individualAddressSearch}
              premises={this.props.organisation.individualPremises}
              addressLine1={this.props.organisation.individualAddressLine1}
              addressLine2={this.props.organisation.individualAddressLine2}
              locality={this.props.organisation.individualLocality}
              region={this.props.organisation.individualRegion}
              country={this.props.organisation.individualCountry}
              postcode={this.props.organisation.individualPostcode}
              onInputChange={this.props.onOrganisationInputChange}
            />
            {this.validator.message(
              "individualAddress",
              formatAddress(
                this.props.organisation.individualPremises,
                this.props.organisation.individualAddressLine1,
                this.props.organisation.individualAddressLine2,
                this.props.organisation.individualLocality,
                this.props.organisation.individualRegion,
                this.props.organisation.individualCountry,
                this.props.organisation.individualPostcode
              ),
              "_required",
              { className: "text-danger" }
            )}
          </div>
        </div>
      </React.Fragment>
    );
  }
  renderSoleTrader() {
    return (
      <React.Fragment>
        {/*this.renderContactInformation()*/}
        <div className="row fieldset">
          <div className="col-3 text-right">
            <label className="fieldset-label required">Trading Name</label>
          </div>
          <div className="col-9">
            <input
              onChange={(e) =>
                this.props.onOrganisationInputChange(
                  "tradingName",
                  e.target.value
                )
              }
              value={this.props.organisation.tradingName || ""}
              className="input-text"
              type="text"
              placeholder="Trading Name"
            />
            {this.validator.message(
              "Trading Name",
              this.props.organisation.tradingName,
              "_required",
              { className: "text-danger" }
            )}
          </div>
        </div>
        <div className="row fieldset">
          <div className="col-3 text-right">
            <label className="fieldset-label required">Trading Address</label>
          </div>
          <div className="col-9">
            <OrganisationAddress
              addressHeader="Trading Address"
              addressPrefix="trading"
              addressSearch={this.props.organisation.tradingAddressSearch}
              premises={this.props.organisation.tradingPremises}
              addressLine1={this.props.organisation.tradingAddressLine1}
              addressLine2={this.props.organisation.tradingAddressLine2}
              locality={this.props.organisation.tradingLocality}
              region={this.props.organisation.tradingRegion}
              country={this.props.organisation.tradingCountry}
              postcode={this.props.organisation.tradingPostcode}
              onInputChange={this.props.onOrganisationInputChange}
            />
            {this.validator.message(
              "Trading Address",
              formatAddress(
                this.props.organisation.tradingPremises,
                this.props.organisation.tradingAddressLine1,
                this.props.organisation.tradingAddressLine2,
                this.props.organisation.tradingLocality,
                this.props.organisation.tradingRegion,
                this.props.organisation.tradingCountry,
                this.props.organisation.tradingPostcode
              ),
              "_required",
              { className: "text-danger" }
            )}
          </div>
        </div>
        {this.renderVatInput()}
        <div className="row fieldset">
          <div className="col-3 text-right">
            <label className="fieldset-label">Website</label>
          </div>
          <div className="col-9">
            <input
              onChange={(e) =>
                this.props.onOrganisationInputChange("website", e.target.value)
              }
              value={this.props.organisation.website || ""}
              className="input-text"
              type="text"
              placeholder="Website"
            />
            {this.validator.message(
              "Website",
              this.props.organisation.website,
              "_url",
              { className: "text-danger" }
            )}
          </div>
        </div>
        <div className="fieldset-group">
          <label className="fieldset-group-label required">
            Sole Trader Details
          </label>
          <div className="row fieldset">
            <div className="col-3 text-right required">
              <label className="fieldset-label">First Name</label>
            </div>
            <div className="col-9">
              <input
                onChange={(e) =>
                  this.props.onOrganisationInputChange(
                    "soleTraderFirstName",
                    e.target.value
                  )
                }
                value={this.props.organisation.soleTraderFirstName || ""}
                className="input-text"
                type="text"
                placeholder="First Name"
              />
              {this.validator.message(
                "soleTraderFirstName",
                this.props.organisation.soleTraderFirstName,
                "_required|_max:50",
                { className: "text-danger" }
              )}
            </div>
          </div>
          <div className="row fieldset">
            <div className="col-3 text-right required">
              <label className="fieldset-label">Last Name</label>
            </div>
            <div className="col-9">
              <input
                onChange={(e) =>
                  this.props.onOrganisationInputChange(
                    "soleTraderLastName",
                    e.target.value
                  )
                }
                value={this.props.organisation.soleTraderLastName || ""}
                className="input-text"
                type="text"
                placeholder="Last Name"
              />
              {this.validator.message(
                "soleTraderLastName",
                this.props.organisation.soleTraderLastName,
                "_required|_max:50",
                { className: "text-danger" }
              )}
            </div>
          </div>
          <div className="row fieldset">
            <div className="col-3 text-right">
              <label className="fieldset-label">Phone</label>
            </div>
            <div className="col-9">
              <InputPhone
                placeholder="Phone"
                phoneFieldName="soleTraderPhone"
                dialCodeFieldName="soleTraderDialCode"
                dialCode={this.props.organisation.soleTraderDialCode}
                phone={this.props.organisation.soleTraderPhone}
                validator={this.validator}
                onChange={this.props.onOrganisationInputChange}
              />
            </div>
          </div>
          <div className="row fieldset">
            <div className="col-3 text-right">
              <label className="fieldset-label required">Email</label>
            </div>
            <div className="col-9">
              <input
                onChange={(e) =>
                  this.props.onOrganisationInputChange(
                    "soleTraderEmail",
                    e.target.value
                  )
                }
                value={this.props.organisation.soleTraderEmail || ""}
                className="input-text"
                type="text"
                placeholder="Email"
              />
              {this.validator.message(
                "soleTraderEmail",
                this.props.organisation.soleTraderEmail,
                "_required|_email",
                { className: "text-danger" }
              )}
            </div>
          </div>
          <div className="row fieldset">
            <div className="col-3 text-right">
              <label className="fieldset-label required">
                Residential Address
              </label>
            </div>
            <div className="col-9">
              <OrganisationAddress
                addressHeader="Residential Address"
                addressPrefix="soleTrader"
                addressSearch={this.props.organisation.soleTraderAddressSearch}
                premises={this.props.organisation.soleTraderPremises}
                addressLine1={this.props.organisation.soleTraderAddressLine1}
                addressLine2={this.props.organisation.soleTraderAddressLine2}
                locality={this.props.organisation.soleTraderLocality}
                region={this.props.organisation.soleTraderRegion}
                country={this.props.organisation.soleTraderCountry}
                postcode={this.props.organisation.soleTraderPostcode}
                onInputChange={this.props.onOrganisationInputChange}
              />
              {this.validator.message(
                "soleTraderAddress",
                formatAddress(
                  this.props.organisation.soleTraderPremises,
                  this.props.organisation.soleTraderAddressLine1,
                  this.props.organisation.soleTraderAddressLine2,
                  this.props.organisation.soleTraderLocality,
                  this.props.organisation.soleTraderRegion,
                  this.props.organisation.soleTraderCountry,
                  this.props.organisation.soleTraderPostcode
                ),
                "_required",
                { className: "text-danger" }
              )}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
  renderPartners() {
    let jsx = [];
    let index = 0;
    for (let partnerCode of this.props.organisation.partnerCodes) {
      jsx.push(
        <OrganisationPartner
          key={partnerCode}
          index={index++}
          onInputChange={this.props.onOrganisationInputChange}
          partnerCode={partnerCode}
          isAuthorisedSignatory={
            this.props.organisation[
            `partner${partnerCode}IsAuthorisedSignatory`
            ]
          }
          firstName={this.props.organisation[`partner${partnerCode}FirstName`]}
          lastName={this.props.organisation[`partner${partnerCode}LastName`]}
          phone={this.props.organisation[`partner${partnerCode}Phone`]}
          dialCode={this.props.organisation[`partner${partnerCode}DialCode`]}
          email={this.props.organisation[`partner${partnerCode}Email`]}
          addressSearch={
            this.props.organisation[`partner${partnerCode}AddressSearch`]
          }
          premises={this.props.organisation[`partner${partnerCode}Premises`]}
          addressLine1={
            this.props.organisation[`partner${partnerCode}AddressLine1`]
          }
          addressLine2={
            this.props.organisation[`partner${partnerCode}AddressLine2`]
          }
          locality={this.props.organisation[`partner${partnerCode}Locality`]}
          region={this.props.organisation[`partner${partnerCode}Region`]}
          postcode={this.props.organisation[`partner${partnerCode}Postcode`]}
          country={this.props.organisation[`partner${partnerCode}Country`]}
          deletePartner={this.props.deletePartner}
          validator={this.validator}
        />
      );
    }
    return jsx;
  }
  renderPartnership() {
    return (
      <React.Fragment>
        {/* {this.renderContactInformation()} */}
        <div className="row fieldset">
          <div className="col-3 text-right">
            <label className="fieldset-label required">Trading Name</label>
          </div>
          <div className="col-9">
            <input
              onChange={(e) =>
                this.props.onOrganisationInputChange(
                  "tradingName",
                  e.target.value
                )
              }
              value={this.props.organisation.tradingName || ""}
              className="input-text"
              type="text"
              placeholder="Trading Name"
            />
            {this.validator.message(
              "Trading Name",
              this.props.organisation.tradingName,
              "_required",
              { className: "text-danger" }
            )}
          </div>
        </div>
        <div className="row fieldset">
          <div className="col-3 text-right">
            <label className="fieldset-label required">Trading Address</label>
          </div>
          <div className="col-9">
            <OrganisationAddress
              addressHeader="Trading Address"
              addressPrefix="trading"
              addressSearch={this.props.organisation.tradingAddressSearch}
              premises={this.props.organisation.tradingPremises}
              addressLine1={this.props.organisation.tradingAddressLine1}
              addressLine2={this.props.organisation.tradingAddressLine2}
              locality={this.props.organisation.tradingLocality}
              region={this.props.organisation.tradingRegion}
              country={this.props.organisation.tradingCountry}
              postcode={this.props.organisation.tradingPostcode}
              onInputChange={this.props.onOrganisationInputChange}
            />
            {this.validator.message(
              "Trading Address",
              formatAddress(
                this.props.organisation.tradingPremises,
                this.props.organisation.tradingAddressLine1,
                this.props.organisation.tradingAddressLine2,
                this.props.organisation.tradingLocality,
                this.props.organisation.tradingRegion,
                this.props.organisation.tradingCountry,
                this.props.organisation.tradingPostcode
              ),
              "_required",
              { className: "text-danger" }
            )}
          </div>
        </div>
        {this.renderVatInput()}
        <div className="row fieldset">
          <div className="col-3 text-right">
            <label className="fieldset-label">Website</label>
          </div>
          <div className="col-9">
            <input
              onChange={(e) =>
                this.props.onOrganisationInputChange("website", e.target.value)
              }
              value={this.props.organisation.website || ""}
              className="input-text"
              type="text"
              placeholder="Website"
            />
            {this.validator.message(
              "Website",
              this.props.organisation.website,
              "_url",
              { className: "text-danger" }
            )}
          </div>
        </div>
        <div className="fieldset-group">
          <label className="fieldset-group-label">Partnership Details</label>
          {this.renderPartners()}
          {this.validator.message(
            "partnersArray",
            this.props.organisation.partnerCodes,
            "_requiredArray:partner",
            { className: "text-center minimum-array-1" }
          )}
          {this.validator.message(
            "partnersArray",
            this.props.organisation.partnerCodes.filter((code) => `partner${code}IsAuthorisedSignatory` in this.props.organisation && this.props.organisation[`partner${code}IsAuthorisedSignatory`] == true),
            "_requiredArrayLength:partner",
            { className: "text-center minimum-array-1" }
          )}
          <div className="row fieldset">
            <div className="col-12 text-right">
              <button
                onClick={() =>
                  this.props.addPartner(this.generateNewPartnerCode())
                }
                className="btn btn-sm create-item"
              >
                <i className="ion ion-md-add-circle"></i>Add Partner
              </button>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
  renderOfficers() {
    let jsx = [];
    let index = 0;
    for (let officerCode of this.props.organisation.officerCodes) {
      jsx.push(
        <OrganisationOfficer
          key={officerCode}
          index={index++}
          onInputChange={this.props.onOrganisationInputChange}
          officerCode={officerCode}
          isAuthorisedSignatory={
            this.props.organisation[
            `officer${officerCode}IsAuthorisedSignatory`
            ]
          }
          firstName={this.props.organisation[`officer${officerCode}FirstName`]}
          lastName={this.props.organisation[`officer${officerCode}LastName`]}
          role={this.props.organisation[`officer${officerCode}Role`]}
          appointedOn={
            this.props.organisation[`officer${officerCode}AppointedOn`]
          }
          nationality={
            this.props.organisation[`officer${officerCode}Nationality`]
          }
          countryOfResidence={
            this.props.organisation[`officer${officerCode}CountryOfResidence`]
          }
          phone={this.props.organisation[`officer${officerCode}Phone`]}
          dialCode={this.props.organisation[`officer${officerCode}DialCode`]}
          email={this.props.organisation[`officer${officerCode}Email`]}
          addressSearch={
            this.props.organisation[`officer${officerCode}AddressSearch`]
          }
          premises={this.props.organisation[`officer${officerCode}Premises`]}
          addressLine1={
            this.props.organisation[`officer${officerCode}AddressLine1`]
          }
          addressLine2={
            this.props.organisation[`officer${officerCode}AddressLine2`]
          }
          locality={this.props.organisation[`officer${officerCode}Locality`]}
          region={this.props.organisation[`officer${officerCode}Region`]}
          postcode={this.props.organisation[`officer${officerCode}Postcode`]}
          country={this.props.organisation[`officer${officerCode}Country`]}
          deleteOfficer={this.props.deleteOfficer}
          validator={this.validator}
        />
      );
    }
    return jsx;
  }
  renderCompany() {
    return (
      <React.Fragment>
        {/* {this.renderContactInformation()} */}
        {this.props.createClientModalType === "create" && (
          <div className="row fieldset company-search">
            <div className="col-3 text-right">
              <label className="fieldset-label">Search Company</label>
            </div>
            <div className="col-9">
              <AutoCompleteInput
                value={this.props.organisation.companySearchInput || ""}
                onInputChange={this.props.onOrganisationInputChange}
                onSelect={this.props.selectCompanySearchItem}
                search={this.props.companySearch}
                name="companySearchInput"
                placeholder="Search by Company Name or Company Number"
                items={this.props.organisation.companySearchItems}
                valueProperty="title"
              />
            </div>
          </div>
        )}
        <div className="row fieldset">
          <div className="col-3 text-right">
            <label className="fieldset-label required">Company Name</label>
          </div>
          <div className="col-9">
            <input
              value={this.props.organisation.companyName || ""}
              readOnly
              className="input-text"
              type="text"
              placeholder="Company Name"
            />
            {this.validator.message(
              "companyName",
              this.props.organisation.companyName,
              "_required",
              { className: "text-danger" }
            )}
          </div>
        </div>
        <div className="row fieldset">
          <div className="col-3 text-right">
            <label className="fieldset-label required">Entity Type</label>
          </div>
          <div className="col-9">
            <input
              value={this.props.organisation.companyType}
              readOnly
              className="input-text"
              type="text"
              placeholder="Entity Type"
            />
            {this.validator.message(
              "companyType",
              this.props.organisation.companyType,
              "_required",
              { className: "text-danger" }
            )}
          </div>
        </div>
        <div className="row fieldset">
          <div className="col-3 text-right">
            <label className="fieldset-label required">Company Status</label>
          </div>
          <div className="col-9">
            <input
              value={this.props.organisation.companyStatus || ""}
              readOnly
              className="input-text"
              type="text"
              placeholder="Company Status"
            />
            {this.validator.message(
              "companyStatus",
              this.props.organisation.companyStatus,
              "_required",
              { className: "text-danger" }
            )}
          </div>
        </div>
        <div className="row fieldset">
          <div className="col-3 text-right">
            <label className="fieldset-label required">Company Number</label>
          </div>
          <div className="col-9">
            <input
              value={this.props.organisation.companyNumber || ""}
              readOnly
              className="input-text"
              type="text"
              placeholder="Company Number"
            />
            {this.validator.message(
              "companyNumber",
              this.props.organisation.companyNumber,
              "_required",
              { className: "text-danger" }
            )}
          </div>
        </div>
        <div className="row fieldset">
          <div className="col-3 text-right">
            <label className="fieldset-label required">
              Registered Office Address
            </label>
          </div>
          <div className="col-9">
            <OrganisationAddress
              addressHeader="Registered Office Address"
              addressPrefix="company"
              addressSearch={this.props.organisation.companyAddressSearch}
              premises={this.props.organisation.companyPremises}
              addressLine1={this.props.organisation.companyAddressLine1}
              addressLine2={this.props.organisation.companyAddressLine2}
              locality={this.props.organisation.companyLocality}
              region={this.props.organisation.companyRegion}
              country={this.props.organisation.companyCountry}
              postcode={this.props.organisation.companyPostcode}
              onInputChange={this.props.onOrganisationInputChange}
              readOnly
            />
            {this.validator.message(
              "companyAddress",
              formatAddress(
                this.props.organisation.companyPremises,
                this.props.organisation.companyAddressLine1,
                this.props.organisation.companyAddressLine2,
                this.props.organisation.companyLocality,
                this.props.organisation.companyRegion,
                this.props.organisation.companyCountry,
                this.props.organisation.companyPostcode
              ),
              "_required",
              { className: "text-danger" }
            )}
          </div>
        </div>
        <div className="row fieldset">
          <div className="col-3 text-right">
            <label className="fieldset-label">Incorporation Date</label>
          </div>
          <div className="col-9">
            <input
              value={
                this.props.organisation.companyIncorporationDate
                  ? formatDate(this.props.organisation.companyIncorporationDate)
                  : ""
              }
              readOnly
              className="input-text"
              type="text"
              placeholder="Incorporation Date"
            />
          </div>
        </div>
        <div className="row fieldset">
          <div className="col-3 text-right">
            <label className="fieldset-label required">Incorporated In</label>
          </div>
          <div className="col-9">
            <Select
              options={incorporatedInCountryOptions}
              value={
                this.props.organisation.companyIncorporatedIn
                  ? incorporatedInCountryOptions.filter(
                    (option) =>
                      option.value ===
                      this.props.organisation.companyIncorporatedIn
                  )
                  : ""
              }
              onChange={(selectedOption) =>
                this.props.onOrganisationInputChange(
                  "companyIncorporatedIn",
                  selectedOption.value
                )
              }
            />
            {this.validator.message(
              "companyIncorporatedIn",
              this.props.organisation.companyIncorporatedIn,
              "_required",
              { className: "text-danger" }
            )}
          </div>
        </div>
        {this.renderVatInput()}
        <div className="row fieldset">
          <div className="col-3 text-right">
            <label className="fieldset-label">Website</label>
          </div>
          <div className="col-9">
            <input
              onChange={(e) =>
                this.props.onOrganisationInputChange("website", e.target.value)
              }
              value={this.props.organisation.website || ""}
              className="input-text"
              type="text"
              placeholder="Website"
            />
            {this.validator.message(
              "Website",
              this.props.organisation.website,
              "_url",
              { className: "text-danger" }
            )}
          </div>
        </div>
        <div className="fieldset-group">
          <label className="fieldset-group-label">Trading Details</label>
          <div className="row fieldset">
            <div className="col-3 text-right">
              <label className="fieldset-label required">Trading Name</label>
            </div>
            <div className="col-9">
              <input
                value={this.props.organisation.tradingName || ""}
                onChange={(e) =>
                  this.props.onOrganisationInputChange(
                    "tradingName",
                    e.target.value
                  )
                }
                className="input-text"
                type="text"
                placeholder="Trading Name"
              />
              {this.validator.message(
                "Trading Name",
                this.props.organisation.tradingName,
                "_required",
                { className: "text-danger" }
              )}
            </div>
          </div>
          <div className="row fieldset">
            <div className="col-3 text-right">
              <label className="fieldset-label required">Trading Address</label>
            </div>
            <div className="col-9">
              <OrganisationAddress
                addressHeader="Trading Address"
                addressPrefix="trading"
                addressSearch={this.props.organisation.tradingAddressSearch}
                premises={this.props.organisation.tradingPremises}
                addressLine1={this.props.organisation.tradingAddressLine1}
                addressLine2={this.props.organisation.tradingAddressLine2}
                locality={this.props.organisation.tradingLocality}
                region={this.props.organisation.tradingRegion}
                country={this.props.organisation.tradingCountry}
                postcode={this.props.organisation.tradingPostcode}
                onInputChange={this.props.onOrganisationInputChange}
              />
              {this.validator.message(
                "Trading Address",
                formatAddress(
                  this.props.organisation.tradingPremises,
                  this.props.organisation.tradingAddressLine1,
                  this.props.organisation.tradingAddressLine2,
                  this.props.organisation.tradingLocality,
                  this.props.organisation.tradingRegion,
                  this.props.organisation.tradingCountry,
                  this.props.organisation.tradingPostcode
                ),
                "_required",
                { className: "text-danger" }
              )}
            </div>
          </div>
        </div>
        <div className="fieldset-group">
          <label className="fieldset-group-label">Officer Details</label>
          {this.renderOfficers()}
          {this.validator.message(
            "officersArray",
            this.props.organisation.officerCodes,
            "_requiredArray:officer",
            { className: "text-center minimum-array-1" }
          )}
          {this.validator.message(
            "officersArray",
            this.props.organisation.officerCodes.filter((code) => `officer${code}IsAuthorisedSignatory` in this.props.organisation && this.props.organisation[`officer${code}IsAuthorisedSignatory`] == true),
            "_requiredArrayLength:officer",
            { className: "text-center minimum-array-1" }
          )}
          <div className="row fieldset">
            <div className="col-12 text-right">
              <button
                onClick={() =>
                  this.props.addOfficer(this.generateNewOfficerCode())
                }
                className="btn btn-sm create-item"
              >
                <i className="ion ion-md-add-circle"></i>Add Officer
              </button>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
  render() {
    const texts = {
      create: {
        header: "Create New Prospect",
        submitButton: "Create Prospect",
      },
      edit: {
        header: "Edit Prospect",
        submitButton: "Update Prospect",
      },
    };
    return (
      <React.Fragment>
        {this.props.loading && <Loader />}
        <Modal
          isOpen={this.props.showCreateClientModal}
          toggle={this.toggle}
          size="lg"
        >
          <ModalBody>
            <h4>{texts[this.props.createClientModalType].header}</h4>
            <div className="separator mb-2" />
            <div className="p-3">
              <div className="row fieldset">
                <div className="col-3 text-right">
                  <label className="fieldset-label required">Prospect Type</label>
                </div>
                <div className="col-9">
                  {this.props.createClientModalType === "create" && (
                    <React.Fragment>
                      <Select
                        options={clientBusinessTypes}
                        value={
                          this.props.clientBusinessType
                            ? clientBusinessTypes.filter(
                              (option) =>
                                option.value === this.props.clientBusinessType
                            )
                            : ""
                        }
                        onChange={(selectedOption) => {
                          this.props.onInputChange(
                            "clientBusinessType",
                            selectedOption.value
                          );
                          this.validator.hideMessages();
                        }}
                      />
                      {this.validator.message(
                        "Prospect Type",
                        this.props.clientBusinessType,
                        "required",
                        { className: "text-danger" }
                      )}
                    </React.Fragment>
                  )}
                  {this.props.createClientModalType === "edit" && (
                    <input
                      className="input-text"
                      type="text"
                      readOnly
                      value={this.props.clientBusinessType || ""}
                    />
                  )}
                </div>
              </div>
              {this.props.clientBusinessType === "individual" &&
                this.renderIndividualClient()}
              {this.props.clientBusinessType === "soleTrader" &&
                this.renderSoleTrader()}
              {this.props.clientBusinessType === "partnership" &&
                this.renderPartnership()}
              {["llp", "ltd"].includes(this.props.clientBusinessType) &&
                this.renderCompany()}
            </div>
            <div className="separator mt-3 mb-3" />
            <div className="row fieldset">
              <div className="col-12 text-right">
                {this.state.phoneEmailValidator && (
                  <span className="alert alert-danger" role="alert">
                    Prospect Phone or Prospect Email is required.
                  </span>
                )}
                <button onClick={this.toggle} className="btn">
                  Cancel
                </button>
                <button
                  onClick={() =>
                    this.formatDataAndSubmitClient(
                      this.props.createClientModalType
                    )
                  }
                  className="btn create-item ml-2"
                >
                  {texts[this.props.createClientModalType].submitButton}
                </button>
              </div>
            </div>
          </ModalBody>
        </Modal>
        {this.props.showCreateNewClientSuccessModal && (
          <SweetAlert
            title=""
            success
            confirmBtnBsStyle="success"
            onConfirm={this.clearModal}
          >
            {this.props.createClientModalType === "create" && (
              <div className="mb-2 success-alert-message">
                Prospect <b>{this.props.companyName || ""}</b> has been created
                successfully!
              </div>
            )}
            {this.props.createClientModalType === "edit" && (
              <div className="mb-2 success-alert-message">
                Prospect <b>{this.props.companyName || ""}</b> has been updated
                successfully!
              </div>
            )}
          </SweetAlert>
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return { organisation: state.Organisation, ...state.Client };
};

export default withRouter(
  connect(mapStateToProps, {
    setShowCreateClientModal,
    setShowCreateNewClientSuccessModal,
    onInputChange,
    submitClient,
    addPartner,
    deletePartner,
    addOfficer,
    deleteOfficer,
    selectCompanySearchItem,
    companySearch,
    onOrganisationInputChange,
    resetOrganisationForm,
    resetCreateNewClientForm,
  })(CreateClientModal)
);
