export const SET_SERVICE_LOADING = "SET_SERVICE_LOADING";
export const ON_SERVICE_INPUT_CHANGE = "ON_SERVICE_INPUT_CHANGE";
export const SET_SERVICE_ACTIVE_STEP = "SET_SERVICE_ACTIVE_STEP";
export const ADD_PRICING_DRIVER = "ADD_PRICING_DRIVER";
export const DELETE_PRICING_DRIVER = "DELETE_PRICING_DRIVER";
export const ADD_VARIATION_TO_PRICING_DRIVER =
  "ADD_VARIATION_TO_PRICING_DRIVER";
export const DELETE_VARIATION_FROM_PRICING_DRIVER =
  "DELETE_VARIATION_FROM_PRICING_DRIVER";
export const ADD_SLAB_TO_PRICING_DRIVER = "ADD_SLAB_TO_PRICING_DRIVER";
export const DELETE_SLAB_FROM_PRICING_DRIVER =
  "DELETE_SLAB_FROM_PRICING_DRIVER";
export const ADD_NUMBER_RANGE_TO_PRICING_DRIVER =
  "ADD_NUMBER_RANGE_TO_PRICING_DRIVER";
export const DELETE_NUMBER_RANGE_FROM_PRICING_DRIVER =
  "DELETE_NUMBER_RANGE_FROM_PRICING_DRIVER";
export const GET_SERVICE = "GET_SERVICE";
export const GET_SERVICE_SUCCESS = "GET_SERVICE_SUCCESS";
export const GET_CATEGORIES = "GET_CATEGORIES";
export const GET_CATEGORIES_SUCCESS = "GET_CATEGORIES_SUCCESS";
export const CREATE_NEW_SERVICE = "CREATE_NEW_SERVICE";
export const SUBMIT_SERVICE = "SUBMIT_SERVICE";
export const CREATE_NEW_SERVICE_SUCCESS = "CREATE_NEW_SERVICE_SUCCESS";
export const SUBMIT_SERVICE_SUCCESS = "SUBMIT_SERVICE_SUCCESS";
export const SET_SHOW_CREATE_CATEGORY_MODAL = "SET_SHOW_CREATE_CATEGORY_MODAL";
export const SET_SHOW_CREATE_NEW_SERVICE_SUCCESS_MODAL =
  "SET_SHOW_CREATE_NEW_SERVICE_SUCCESS_MODAL";
export const CREATE_NEW_CATEGORY = "CREATE_NEW_CATEGORY";
export const CREATE_NEW_CATEGORY_SUCCESS = "CREATE_NEW_CATEGORY_SUCCESS";
export const UPDATE_CATEGORY = "UPDATE_CATEGORY";
export const UPDATE_CATEGORY_SUCCESS = "UPDATE_CATEGORY_SUCCESS";
export const SET_SHOW_CREATE_NEW_CATEGORY_SUCCESS_MODAL =
  "SET_SHOW_CREATE_NEW_CATEGORY_SUCCESS_MODAL";
export const GET_SERVICES_BY_CATEGORY = "GET_SERVICES_BY_CATEGORY";
export const GET_SERVICES_BY_CATEGORY_SUCCESS =
  "GET_SERVICES_BY_CATEGORY_SUCCESS";
export const RESET_CREATE_NEW_SERVICE_FORM = "RESET_CREATE_NEW_SERVICE_FORM";
export const GET_GLOBAL_PRICING_DRIVERS = "GET_GLOBAL_PRICING_DRIVERS";
export const GET_GLOBAL_PRICING_DRIVERS_SUCCESS =
  "GET_GLOBAL_PRICING_DRIVERS_SUCCESS";

export const GET_GLOBAL_PRICING_DRIVER = "GET_GLOBAL_PRICING_DRIVER";
export const GET_GLOBAL_PRICING_DRIVER_SUCCESS =
  "GET_GLOBAL_PRICING_DRIVER_SUCCESS";

export const SET_SHOW_CREATE_GLOBAL_PRICING_DRIVER_MODAL =
  "SET_SHOW_CREATE_GLOBAL_PRICING_DRIVER_MODAL";
export const SET_SHOW_CREATE_GLOBAL_PRICING_DRIVER_SUCCESS_MODAL =
  "SET_SHOW_CREATE_GLOBAL_PRICING_DRIVER_SUCCESS_MODAL";
export const SET_SHOW_UPDATE_SERVICE_CONFIRM_MODAL =
  "SET_SHOW_UPDATE_SERVICE_CONFIRM_MODAL";

export const SUBMIT_GLOBAL_PRICING_DRIVER = "SUBMIT_GLOBAL_PRICING_DRIVER";
export const SUBMIT_GLOBAL_PRICING_DRIVER_SUCCESS =
  "SUBMIT_GLOBAL_PRICING_DRIVER_SUCCESS";

export const GET_GLOBAL_DRIVERS_BY_SERVICES = "GET_GLOBAL_DRIVERS_BY_SERVICES";
export const GET_GLOBAL_DRIVERS_BY_SERVICES_SUCCESS =
  "GET_GLOBAL_DRIVERS_BY_SERVICES_SUCCESS";

export const RESET_PRICING_DRIVER_FORM = "RESET_PRICING_DRIVER_FORM";

export const SET_SHOW_CREATE_GLOBAL_CONSTANT_MODAL =
  "SET_SHOW_CREATE_GLOBAL_CONSTANT_MODAL";
export const SUBMIT_GLOBAL_CONSTANT = "SUBMIT_GLOBAL_CONSTANT";
export const SUBMIT_GLOBAL_CONSTANT_SUCCESS = "SUBMIT_GLOBAL_CONSTANT_SUCCESS";

export const UPDATE_SERVICE_ACTIVE_KEY = "UPDATE_SERVICE_ACTIVE_KEY";
export const UPDATE_SERVICE_ACTIVE_KEY_SUCCESS =
  "UPDATE_SERVICE_ACTIVE_KEY_SUCCESS";
export const ON_SERVICE_DRIVER_INPUT_CHANGE = "ON_SERVICE_DRIVER_INPUT_CHANGE";
export const ON_SERVICE_GLOBAL_DRIVER_INPUT_CHANGE =
  "ON_SERVICE_GLOBAL_DRIVER_INPUT_CHANGE";
export const CREATE_NEW_CATEGORY_FAIL = "CREATE_NEW_CATEGORY_FAIL";
export const CREATE_NEW_SERVICE_FAIL = "CREATE_NEW_SERVICE_FAIL";
export const SET_SHOW_CREATE_NEW_CATEGORY_FAILED_MODAL =
  "SET_SHOW_CREATE_NEW_CATEGORY_FAILED_MODAL";
  export const SET_SHOW_CREATE_NEW_SERVICE_FAILED_MODAL = "SET_SHOW_CREATE_NEW_SERVICE_FAILED_MODAL";
export const SUBMIT_GLOBAL_CONSTANT_FAIL = "SUBMIT_GLOBAL_CONSTANT_FAIL";
export const SET_SHOW_CREATE_GLOBAL_PRICING_DRIVER_FAIL_MODAL =
  "SET_SHOW_CREATE_GLOBAL_PRICING_DRIVER_FAIL_MODAL";
export const CREATE_NEW_GLOBAL_DRIVER_FAIL = "CREATE_NEW_GLOBAL_DRIVER_FAIL";
export const SET_SHOW_CREATE_NEW_GLOBAL_DRIVER_FAILED_MODAL = "SET_SHOW_CREATE_NEW_GLOBAL_DRIVER_FAILED_MODAL";
export const SET_SHOW_CHANGE_GLOBAL_PRICING_DRIVER_VALUES_MODAL = 'SET_SHOW_CHANGE_GLOBAL_PRICING_DRIVER_VALUES_MODAL';
