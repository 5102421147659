import React, { useState } from 'react';
import Tags from "@yaireo/tagify/dist/react.tagify";
import "@yaireo/tagify/dist/tagify.css";

export default function TagField(props) {
  // const handleChange = e => {
  //   console.log(e.type, " ==> ", e.detail.tagify.value.map(item => item.value));
  // };
 //  const settings = {
 //    mode: 'mix',
	// pattern: /@|#/,
	// whitelist : props.suggestions,
	// dropdown : {
	// 	enabled: 0,
	// 	highlightFirst: true
	// },
	// enforceWhitelist: true,
	// duplicates: true
 //  };

	return (<Tags
		settings={props.settings}
		whitelist={props.whitelist}
		className="input-text"
		onChange={e => (e.persist(), props.onInputChange("servicePricingFormula", e.target.value))}
	/>)

}
