import {
	ON_SETTING_INPUT_CHANGE,
	GET_PAYMENT_GATEWAYS,
	GET_PAYMENT_GATEWAYS_SUCCESS,
	SAVE_PAYMENT_GATEWAY,
	SAVE_PAYMENT_GATEWAY_SUCCESS,
	SAVE_PAYMENT_GATEWAY_SUCCESS_MODAL
} from "./actionTypes";

const initialState = {
	loading: false,
	paymentGateways: [],
	savePaymentKeySuccessModal: false,
	savePaymentKeySuccessModalMessage: ""
}

const setting = (state = initialState, action) => {
	switch (action.type) {
		case ON_SETTING_INPUT_CHANGE:
			return { ...state, [action.payload.field]: action.payload.value }
		case GET_PAYMENT_GATEWAYS:
			return { ...state, loading: true }
		case GET_PAYMENT_GATEWAYS_SUCCESS:
			let stripe_publishable_key;
			let stripe_secret_key;
			let gocardless_access_token;
			for (let paymentGateway of action.payload) {
				if (paymentGateway.gateway==="GoCardless") {
					gocardless_access_token = paymentGateway.gocardless_access_token;
				} else if (paymentGateway.gateway==="Stripe") {
					stripe_publishable_key = paymentGateway.stripe_publishable_key;
					stripe_secret_key = paymentGateway.stripe_secret_key;
				}
			}
			return { ...state, loading: false, stripe_publishable_key, stripe_secret_key, gocardless_access_token }
		case SAVE_PAYMENT_GATEWAY:
			return { ...state, loading: true }
		case SAVE_PAYMENT_GATEWAY_SUCCESS:
			return { ...state, loading: false, savePaymentKeySuccessModal: true, savePaymentKeySuccessModalMessage: "Saved successfully"}
		case SAVE_PAYMENT_GATEWAY_SUCCESS_MODAL:
			return {...state, savePaymentKeySuccessModal: action.payload.value}
		default:
			return state;
	}
}

export default setting;
