import React from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import { Modal, ModalBody } from 'reactstrap';
import { connect } from "react-redux";
import Loader from "./Loader";
import { setShowDeleteObjectSuccessModal } from "../store/delete/actions";
const DeleteSuccessModal = props => {
	return (
		
		<React.Fragment>
			{props.loading && <Loader />}
			{props.showDeleteObjectSuccessModal && <SweetAlert
				title=""
				success
				confirmBtnBsStyle="success"
				onConfirm={() => props.setShowDeleteObjectSuccessModal(false)}
			>
				{props.delObject} has been deleted successfully!
			</SweetAlert>}
		</React.Fragment>
	);
}

const mapStateToProps = state => {
  const { showDeleteObjectSuccessModal, loading } = state.Delete;
  return { showDeleteObjectSuccessModal, loading };
}

export default connect(mapStateToProps, {setShowDeleteObjectSuccessModal})(DeleteSuccessModal);
