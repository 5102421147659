import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import { CardElement } from "@stripe/react-stripe-js";
import { Row, Col } from "reactstrap";
import {formatCurrency} from "../Utils";


export function SetupPaymentModal({open, setOpen, cardErrorMessage, selectedPlan, handlePay, onChange, discount, monthOffer, discountedOffer, discountedPriceOffer}) {
    // const [open, setOpen] = React.useState(false);
    const [fullWidth, setFullWidth] = React.useState(true);
    const [maxWidth, setMaxWidth] = React.useState('md');
    const [value, setValue] = React.useState(null);

    console.log('discountedOffer - ', discountedOffer);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleMaxWidthChange = (event) => {
        setMaxWidth(
            // @ts-expect-error autofill of arbitrary value is not handled.
            event.target.value,
        );
    };

    const handleFullWidthChange = (event) => {
        setFullWidth(event.target.checked);
    };

    return (
        <React.Fragment>
            <Dialog
                fullWidth={fullWidth}
                maxWidth={maxWidth}
                open={open}
                onClose={handleClose}
            >
                <DialogTitle>Setup Payment</DialogTitle>
                <DialogContent>
                    <Box className="mt-2">
                        {selectedPlan && (monthOffer ? <div>
                            <p>Package
                                Selected: <strong style={{color: 'dodgerblue'}}>{selectedPlan.name}</strong> | First <strong style={{color: 'dodgerblue', textTransform: 'capitalize'}}>{monthOffer.offers.month_discount?.value.got_months}</strong> months in the price of <strong style={{color: 'dodgerblue', textTransform: 'capitalize'}}>{monthOffer.offers.month_discount?.value.in_price_of_month}</strong> months |
                                Price: <strong style={{color: 'dodgerblue'}}>{formatCurrency((selectedPlan.fee_per_year / 12 * monthOffer.offers.month_discount?.value.in_price_of_month).toFixed(2))}</strong></p>
                        </div> : discountedOffer ? <div>
                            <p>Package
                                Selected: <strong style={{color: 'dodgerblue'}}>{selectedPlan.name}</strong> | Get <strong style={{color: 'dodgerblue', textTransform: 'capitalize'}}>{discountedOffer.offers.discounted_price_for_month?.value.discounted_price_for_month_count}</strong> months at discounted price |
                                Price: <strong style={{color: 'dodgerblue'}}>{formatCurrency((discountedOffer.offers.discounted_price_for_month?.value.discounted_price_for_month_price).toFixed(2))}</strong></p>
                        </div> : discountedPriceOffer ?  <div>
                            <p>Package
                                Selected: <strong style={{color: 'dodgerblue'}}>{selectedPlan.name}</strong> | Get Package at Discounted Price <strong style={{color: 'dodgerblue', textTransform: 'capitalize'}}>{formatCurrency((discountedPriceOffer.offers.discounted_price.value).toFixed(2))}</strong> | Interval: <strong style={{color: 'dodgerblue', textTransform: 'capitalize'}}>{selectedPlan.interval}ly</strong> |
                                Price: <strong style={{color: 'dodgerblue'}}>{formatCurrency((selectedPlan.price).toFixed(2))}</strong></p>
                        </div> : discount ? <div>
                            <p>Package
                                Selected: <strong style={{color: 'dodgerblue'}}>{selectedPlan.name}</strong> | <strong style={{color: 'dodgerblue', textTransform: 'capitalize'}}>{discount.offers?.discount?.value}%</strong> Off of <strong style={{color: 'dodgerblue', textTransform: 'capitalize'}}>{formatCurrency((discount.fee_per_year).toFixed(2))}</strong> | Interval: <strong style={{color: 'dodgerblue', textTransform: 'capitalize'}}>{selectedPlan.interval}ly</strong> |
                                Price: <strong style={{color: 'dodgerblue'}}>{formatCurrency(selectedPlan.price)}</strong></p>
                        </div> : <div>
                            <p>Package
                                Selected: <strong style={{color: 'dodgerblue'}}>{selectedPlan.name}</strong> | Interval: <strong style={{color: 'dodgerblue', textTransform: 'capitalize'}}>{selectedPlan.interval}ly</strong> |
                                Price: <strong style={{color: 'dodgerblue'}}>{formatCurrency(selectedPlan.price)}</strong></p>
                        </div>)}
                        <TextField onChange={e => {
                            onChange('card_holder_name', e.target.value)
                        }} id="outlined-basic" label="Card holder Name" variant="outlined" className='w-100' />
                        <Row className="align-items-center stripe-card-input">
                            <CardElement options={{
                                style: {width: '100%', margin: '20px'}
                            }} />
                            {/*<CreditCardInput*/}
                            {/*  cardNumberInputProps={{ value: parentProps.card_number, onChange: (e) => onChange('card_number', e.target.value) }}*/}
                            {/*  cardExpiryInputProps={{ value: parentProps.card_expiry, onChange: (e) => onChange('card_expiry', e.target.value) }}*/}
                            {/*  cardCVCInputProps={{ value: parentProps.cvc, onChange: (e) => onChange('cvc', e.target.value) }}*/}
                            {/*  fieldClassName="input"*/}
                            {/*/>*/}
                        </Row>
                        {cardErrorMessage && <Row className="align-items-center stripe-card-input">
                            <p style={{padding: '0 20px', color: 'red'}}>{cardErrorMessage}</p>
                        </Row>}
                    </Box>
                </DialogContent>
                {selectedPlan && <DialogActions>
                    <Button onClick={() => handlePay(monthOffer)}>Pay {monthOffer ? formatCurrency((selectedPlan.fee_per_year / 12 * monthOffer.offers.month_discount?.value.in_price_of_month).toFixed(2)) : discountedOffer ? formatCurrency((discountedOffer.offers.discounted_price_for_month?.value.discounted_price_for_month_price).toFixed(2)) : formatCurrency(selectedPlan.price)}</Button>
                    <Button onClick={handleClose}>Close</Button>
                </DialogActions>}
            </Dialog>
        </React.Fragment>
    );
}
