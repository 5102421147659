import {
  SET_EMAIL_TEMPLATE_LOADING,
  ON_EMAIL_TEMPLATE_INPUT_CHANGE,
  GET_EMAIL_TEMPLATES,
  GET_EMAIL_TEMPLATES_SUCCESS,
  GET_EMAIL_TEMPLATE,
  GET_EMAIL_TEMPLATE_SUCCESS,
  SUBMIT_EMAIL_TEMPLATE,
  SUBMIT_EMAIL_TEMPLATE_SUCCESS,
  RESET_CREATE_NEW_EMAIL_TEMPLATE_FORM,
  SET_SHOW_CREATE_NEW_EMAIL_TEMPLATE_SUCCESS_MODAL,
  UPLOAD_EMAIL_TEMPLATE,
  UPLOAD_EMAIL_TEMPLATE_SUCCESS,
  DELETE_EMAIL_TEMPLATE,
  DELETE_EMAIL_TEMPLATE_SUCCESS,
  CREATE_NEW_EMAIL_TEMPLATES_FAIL,
  SET_SHOW_CREATE_NEW_EMAIL_TEMPLATES_FAILED_MODAL,
  GENERATE_EMAIL_TEMPLATES,
} from "./actionTypes";

const initialState = {
  loading: false,
  emailTemplates: [],
  showCreateNewEmailTemplateSuccessModal: false,
  showCreateNewEmailFailedModal: false,
  showPdfUploadModal: false,
  emailTemplateType: "",
  errorMessage: "Something Went Wrong!",
};

const emailTemplate = (state = initialState, action) => {
  let newState;
  switch (action.type) {
    case SET_EMAIL_TEMPLATE_LOADING:
      return { ...state, loading: action.payload };
    case ON_EMAIL_TEMPLATE_INPUT_CHANGE:
      return { ...state, [action.payload.field]: action.payload.value };
    case GET_EMAIL_TEMPLATES:
      return { ...state, loading: true };
    case GET_EMAIL_TEMPLATES_SUCCESS:
      return {
        ...state,
        loading: false,
        emailTemplates: action.payload,
      };
    case GET_EMAIL_TEMPLATE:
      return { ...state, loading: true };
    case GET_EMAIL_TEMPLATE_SUCCESS:
      newState = { ...state, loading: false };
      newState.emailTemplateId = action.payload._id;
      newState.emailTemplateName = action.payload.name;
      newState.emailTemplateContent = action.payload.template[0].tncBlock;
      newState.businessType = action.payload.organisation_business_type;
      newState.tncTemplateType = action.payload.tnc_template_type;
      newState.fileUrl = action.payload.template_url;
      newState.emailTemplateType = action.payload.email_template_type;
      return newState;
    case SUBMIT_EMAIL_TEMPLATE:
      return { ...state, loading: true };
    case SUBMIT_EMAIL_TEMPLATE_SUCCESS:
      return {
        ...state,
        loading: false,
        showCreateNewEmailTemplateSuccessModal: true,
      };
    case SET_SHOW_CREATE_NEW_EMAIL_TEMPLATE_SUCCESS_MODAL:
      return {
        ...state,
        showCreateNewEmailTemplateSuccessModal: action.payload,
      };
    case CREATE_NEW_EMAIL_TEMPLATES_FAIL:
      return (newState = {
        ...state,
        loading: false,
        showCreateNewEmailFailedModal: true,
        errorMessage: action.payload
      });
    case SET_SHOW_CREATE_NEW_EMAIL_TEMPLATES_FAILED_MODAL:
      return {
        ...state,
        showCreateNewEmailFailedModal: action.payload,
      };
    case RESET_CREATE_NEW_EMAIL_TEMPLATE_FORM:
      return initialState;
    case UPLOAD_EMAIL_TEMPLATE:
      return { ...state, loading: true };
    case UPLOAD_EMAIL_TEMPLATE_SUCCESS:
      return { ...state, loading: false, fileUrl: action.payload };
    case DELETE_EMAIL_TEMPLATE:
      return { ...state, loading: false, fileUrl: null };
    case DELETE_EMAIL_TEMPLATE_SUCCESS:
      return { ...state, loading: false, fileUrl: null };
    case GENERATE_EMAIL_TEMPLATES:
      return { ...state, loading: action.payload };
    default:
      return state;
  }
};

export default emailTemplate;
