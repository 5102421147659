import { takeEvery, fork, put, all, call } from "redux-saga/effects";
import axios from "axios";

import {
  GET_TEMPLATES,
  GET_TEMPLATE,
  SUBMIT_TEMPLATE,
  GET_TEMPLATES_CLIENT,
} from "./actionTypes";

import {
  getTemplatesSuccess,
  getTemplateSuccess,
  submitTemplateSuccess,
  createTemplateFailed,
  getTemplatesClientSuccess,
} from "./actions";
import { setShowApiErrorModal } from "../common/actions";

// AUTH related methods
import { getFirebaseBackend } from "../../helpers/authUtils";

const fireBaseBackend = getFirebaseBackend();

function* getTemplates({ payload: query }) {
  try {
    const accessToken = yield fireBaseBackend.getAccessToken();
    let queryString = "";
    if (query) {
      queryString += "?";
      for (let key in query) {
        queryString += `${key}=${query[key]}`;
      }
    }
    const response = yield axios({
      method: "GET",
      url: `templates${queryString}`,
      headers: { authorization: `Bearer ${accessToken}` },
    });
    if (response.data.success) {
      yield put(getTemplatesSuccess(response.data.result));
    } else {
      // yield put(createOrganisationFailed());
    }
  } catch (error) {
    yield put(setShowApiErrorModal());
    console.log(error);
    // if (error.response && error.response.data && error.response.data.error) {
    //     yield put(loginError(error.response.data.error));
    // } else {
    //     yield put(loginError("Something went Wrong!"));
    // }
    // yield put(createOrganisationFailed());
  }
}

function* getTemplate({ payload: id }) {
  try {
    const accessToken = yield fireBaseBackend.getAccessToken();
    const response = yield axios({
      method: "GET",
      url: `template/${id}`,
      headers: { authorization: `Bearer ${accessToken}` },
    });
    if (response.data.success) {
      yield put(getTemplateSuccess(response.data.result));
    } else {
      // yield put(createOrganisationFailed());
    }
  } catch (error) {
    yield put(setShowApiErrorModal());
    // if (error.response && error.response.data && error.response.data.error) {
    //     yield put(loginError(error.response.data.error));
    // } else {
    //     yield put(loginError("Something went Wrong!"));
    // }
    // yield put(createOrganisationFailed());
  }
}

function* submitTemplate({ payload: { data, type } }) {
  try {
    const accessToken = yield fireBaseBackend.getAccessToken();
    let url;
    switch (type) {
      case "new":
        url = "create-new-template";
        break;
      case "edit":
        url = "update-template";
        break;
      default:
        break;
    }
    const response = yield axios({
      method: "POST",
      url,
      headers: { authorization: `Bearer ${accessToken}` },
      data,
    });
    if (response.data.success) {
      yield put(submitTemplateSuccess());
    } else {
      yield put(createTemplateFailed(response.data.error));
      // yield put(createOrganisationFailed());
    }
  } catch (error) {
    yield put(setShowApiErrorModal());
    // if (error.response && error.response.data && error.response.data.error) {
    //     yield put(loginError(error.response.data.error));
    // } else {
    //     yield put(loginError("Something went Wrong!"));
    // }
    // yield put(createOrganisationFailed());
  }
}

function* getTemplatesClient({ payload: organisationId }) {
  try {
    const response = yield axios({
      method: "GET",
      url: `templates-client/${organisationId}`,
    });
    if (response.data.success) {
      yield put(getTemplatesClientSuccess(response.data.result));
    } else {
      // yield put(createOrganisationFailed());
    }
  } catch (error) {
    yield put(setShowApiErrorModal());
    console.log(error);
    // if (error.response && error.response.data && error.response.data.error) {
    //     yield put(loginError(error.response.data.error));
    // } else {
    //     yield put(loginError("Something went Wrong!"));
    // }
    // yield put(createOrganisationFailed());
  }
}

export function* watchGetTemplates() {
  yield takeEvery(GET_TEMPLATES, getTemplates);
}

export function* watchGetTemplate() {
  yield takeEvery(GET_TEMPLATE, getTemplate);
}

export function* watchSubmitTemplate() {
  yield takeEvery(SUBMIT_TEMPLATE, submitTemplate);
}

export function* watchGetTemplatesClient() {
  yield takeEvery(GET_TEMPLATES_CLIENT, getTemplatesClient);
}

function* templateSaga() {
  yield all([
    fork(watchGetTemplates),
    fork(watchGetTemplate),
    fork(watchSubmitTemplate),
    fork(watchGetTemplatesClient),
  ]);
}

export default templateSaga;
