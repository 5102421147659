import React, { Component } from "react";
import { Modal, ModalBody } from 'reactstrap';
import SweetAlert from "react-bootstrap-sweetalert";
import { connect } from "react-redux";
import {
  onInputChange,
  addVariationToPricingDriver,
  deleteVariationFromPricingDriver,
  addSlabToPricingDriver,
  deleteSlabFromPricingDriver,
  addNumberRangeToPricingDriver,
  deleteNumberRangeFromPricingDriver,
  setShowCreateGlobalPricingDriverModal,
  setShowCreateGlobalPricingDriverSuccessModal,
  submitGlobalPricingDriver,
  resetPricingDriverForm,
  setDefaultGlobalDriver, setShowChangeGlobalPricingDriverValuesModal
} from "../../store/service/actions";
import PricingDriver from "../PricingDriver";
import {getValidator} from "../../Utils";


class CreateGlobalPricingDriversModal extends Component {
  constructor(props) {
    super(props);
    this.validator = getValidator();
    this.cancel = this.cancel.bind(this);
    this.toggle = this.toggle.bind(this);
    this.formatAndSubmitData = this.formatAndSubmitData.bind(this);
    this.isDriverValid = this.isDriverValid.bind(this);
  }
  editModal(id, index) {
    this.props.setShowChangeGlobalPricingDriverValuesModal(true, "edit", id, index);
  }
  cancel() {
    this.props.resetPricingDriverForm();
    this.props.setShowChangeGlobalPricingDriverValuesModal(false);
  }
  toggle() {
    this.props.resetPricingDriverForm();
    this.props.setShowChangeGlobalPricingDriverValuesModal(!this.props.showChangeGlobalPricingDriverValuesModal);
  }
  isDriverValid() {
    let driversValid = true;
    let driverCode = "";
    // for (let driverCode of this.props.driverCodes) {
    if (!(this.validator.fields[`driver${driverCode}Name`] && this.validator.fields[`driver${driverCode}Type`])) {
      driversValid = false;
      // break;
    }
    if (this.props[`driver${driverCode}Type`]==="Variation") {
      if (this.props[`driver${driverCode}Variations`] && this.props[`driver${driverCode}Variations`].length>0) {
        for (let variationCode of this.props[`driver${driverCode}Variations`]) {
          if (!(this.validator.fields[`driver${driverCode}Variation${variationCode}Name`] && this.validator.fields[`driver${driverCode}Variation${variationCode}Price`])) {
            driversValid = false;
            break;
          }
        }
        // if (!driversValid) {
        // break;
        // }
      } else {
        driversValid = false;
        // break;
      }
    } else if (this.props[`driver${driverCode}Type`]==="Slab") {
      if (this.props[`driver${driverCode}Slabs`] && this.props[`driver${driverCode}Slabs`].length>0) {
        for (let slabCode of this.props[`driver${driverCode}Slabs`]) {

          if (this.validator.fields[`driver${driverCode}Slab${slabCode}Type`]) {
            if (["BlockBased", "UnitBased"].includes(this.props[`driver${driverCode}Slab${slabCode}Type`])) {
              if (!(this.validator.fields[`driver${driverCode}Slab${slabCode}Price`] && this.validator.fields[`driver${driverCode}Slab${slabCode}From`] && this.validator.fields[`driver${driverCode}Slab${slabCode}To`])) {
                driversValid = false;
                break;
              }
            } else if (["IncrementalBlock", "IncrementalUnit"].includes(this.props[`driver${driverCode}Slab${slabCode}Type`])) {
              if (!(this.validator.fields[`driver${driverCode}Slab${slabCode}Price`] && this.validator.fields[`driver${driverCode}Slab${slabCode}From`] && this.validator.fields[`driver${driverCode}Slab${slabCode}Incrementer`])) {
                driversValid = false;
                break;
              }
            }
          } else {
            driversValid = false;
            break;
          }
        }
        // if (!driversValid) {
        // 	break;
        // }
      } else {
        driversValid = false;
        // break;
      }
    }
    // }
    return driversValid;
  }

  formatAndSubmitData() {

    if (this.isDriverValid()) {
      let driver = {};
      if (this.props.createGlobalPricingDriverModalType==="edit") {
        driver.id = this.props.driverId;
      }
      driver.name = this.props.driverName;
      driver.type = this.props.driverType;

      if (driver.type==="Variation") {
        let variations = [];
        this.props.driverVariations.forEach(variationItem => {
          let variation = {};
          variation.isNew = this.props[`driverVariation${variationItem}IsNew`];
          if (variation.isNew===false) {
            variation.id = variationItem;
          }
          variation.name = this.props[`driverVariation${variationItem}Name`];
          variation.price = this.props[`driverVariation${variationItem}Price`];
          variation.is_default = this.props[`driverVariation${variationItem}IsDefault`];
          variation.decoupled = this.props[`driverVariation${variationItem}IsDeCoupled`];
          variations.push(variation);
        });
        driver.variations = variations;
      } else if (driver.type==="Slab") {
        let slabs = [];
        this.props.driverSlabs.forEach(slabItem => {
          let slab = {};
          slab.isNew = this.props[`driverSlab${slabItem}IsNew`];
          if (slab.isNew===false) {
            slab.id = slabItem;
          }
          slab.type = this.props[`driverSlab${slabItem}Type`];
          slab.price = this.props[`driverSlab${slabItem}Price`];
          slab.from = this.props[`driverSlab${slabItem}From`];
          slab.is_default = this.props[`driverSlab${slabItem}IsDefault`];
          slab.decoupled = this.props[`driverSlab${slabItem}IsDeCoupled`];
          if (["BlockBased", "UnitBased"].includes(slab.type)) {
            slab.to = this.props[`driverSlab${slabItem}To`];
          }
          if (["IncrementalBlock", "IncrementalUnit"].includes(slab.type)) {
            slab.incrementer = this.props[`driverSlab${slabItem}Incrementer`];
          }
          // variation.name = this.props[`driver${driverItem}Variation${variationItem}Name`];
          // variation.price = this.props[`driver${driverItem}Variation${variationItem}Price`];
          slabs.push(slab);
        });
        driver.slabs = slabs;
      } else if (driver.type==="Number Range") {
        let numberRanges = [];
        this.props[`driverNumberRanges`].forEach(numberRangeItem => {
          let numberRange = {};
          numberRange.isNew = this.props[`driverNumberRange${numberRangeItem}IsNew`];
          if (numberRange.isNew===false) {
            numberRange.id = numberRangeItem;
          }
          numberRange.from = this.props[`driverNumberRange${numberRangeItem}From`];
          numberRange.to = this.props[`driverNumberRange${numberRangeItem}To`];
          numberRange.price = this.props[`driverNumberRange${numberRangeItem}Price`];
          numberRanges.push(numberRange);
        });
        driver.numberRanges = numberRanges;
      }
      this.props.handleSubmitChangeGlobalPricingDriverValues(driver);
      // this.props.submitGlobalPricingDriver(driver, this.props.createGlobalPricingDriverModalType);
    } else {
      this.validator.showMessages();
      // this.updateStepperWarnings();
      this.forceUpdate();
    }
  }


  render() {
    const texts = {
      create: {
        header: "Create New Driver",
        submitButton: "Create Driver"
      },
      edit: {
        header: "Edit Driver",
        submitButton: "Update Driver"
      }
    }

    let item = ""
    let variationsVar = []
    let slabs = []
    let numberRanges = []

    if (this.props.hasOwnProperty(`driver${item}Variations`)) {
      this.props[`driver${item}Variations`].forEach((variationItem, index) => {
        variationsVar.push({
          code: variationItem,
          name: this.props[`driver${item}Variation${variationItem}Name`],
          price: this.props[`driver${item}Variation${variationItem}Price`],
          is_default: this.props[`driver${item}Variation${variationItem}IsDefault`]
        });
      });
    }
    if (this.props.hasOwnProperty(`driver${item}Slabs`)) {
      this.props[`driver${item}Slabs`].forEach((slabItem, index) => {
        slabs.push({
          code: slabItem,
          type: this.props[`driver${item}Slab${slabItem}Type`],
          price: this.props[`driver${item}Slab${slabItem}Price`],
          from: this.props[`driver${item}Slab${slabItem}From`],
          to: this.props[`driver${item}Slab${slabItem}To`],
          incrementer: this.props[`driver${item}Slab${slabItem}Incrementer`],
          is_default: this.props[`driver${item}Slab${slabItem}IsDefault`]
        });
      });
    }
    if (this.props.hasOwnProperty(`driver${item}NumberRanges`)) {
      this.props[`driver${item}NumberRanges`].forEach((numberRangeItem, index) => {
        numberRanges.push({
          code: numberRangeItem,
          from: this.props[`driver${item}NumberRange${numberRangeItem}From`],
          to: this.props[`driver${item}NumberRange${numberRangeItem}To`],
          price: this.props[`driver${item}NumberRange${numberRangeItem}Price`]
        });
      });
    }

    return (
      <React.Fragment>
        <Modal isOpen={this.props.showChangeGlobalPricingDriverValuesModal} toggle={this.toggle} size="lg">
          <ModalBody>
            <h4>{texts[this.props.createGlobalPricingDriverModalType].header}</h4>
            <div className="separator mb-2" />
            <div className="p-3">
              <PricingDriver
                editValuesOnly={true}
                onChange={this.props.onInputChange}
                setDefaultGlobalDriver={this.props.setDefaultGlobalDriver}
                name={this.props[`driver${item}Name`]}
                type={this.props[`driver${item}Type`]}
                code={item}
                variations={variationsVar}
                setDriverVariations = {(shuffledArr)=>this.props.onInputChange("driverVariations", shuffledArr)}
                numberRanges={numberRanges}
                slabs={slabs}
                addVariationToPricingDriver={this.props.addVariationToPricingDriver}
                deleteVariationFromPricingDriver ={this.props.deleteVariationFromPricingDriver}
                addSlabToPricingDriver={this.props.addSlabToPricingDriver}
                deleteSlabFromPricingDriver ={this.props.deleteSlabFromPricingDriver}
                addNumberRangeToPricingDriver={this.props.addNumberRangeToPricingDriver}
                deleteNumberRangeFromPricingDriver={this.props.deleteNumberRangeFromPricingDriver}
                validator={this.validator}
                parentProps={this.props}
                /*nameValidator={this.validator.message(`driver${item}Name`, this.props[`driver${item}Name`], '_required', { className: 'text-danger' })}
                typeValidator={this.validator.message(`driver${item}Type`, this.props[`driver${item}Type`], '_required', { className: 'text-danger' })}*/
              />
            </div>
            <div className="separator mt-3 mb-3" />
            <div className="row fieldset">
              <div className="col-12 text-right">
                <button onClick={this.cancel} className="btn">Cancel</button>
                <button onClick={this.formatAndSubmitData} className="btn create-item ml-2">{texts[this.props.createGlobalPricingDriverModalType].submitButton}</button>
              </div>
            </div>
          </ModalBody>
        </Modal>
        {this.props.showCreateGlobalPricingDriverSuccessModal && <SweetAlert
          title=""
          success
          confirmBtnBsStyle="success"
          onConfirm={() => { this.props.setShowCreateGlobalPricingDriverSuccessModal(false); this.props.setShowCreateGlobalPricingDriverModal(false); this.cancel() }}
        >
          <div className="mb-2 success-alert-message">The driver has been created successfully!</div>
        </SweetAlert>}
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => state.Service;

export default connect(mapStateToProps, {
  onInputChange,
  addVariationToPricingDriver,
  deleteVariationFromPricingDriver,
  addSlabToPricingDriver,
  deleteSlabFromPricingDriver,
  addNumberRangeToPricingDriver,
  deleteNumberRangeFromPricingDriver,
  setShowCreateGlobalPricingDriverModal,
  setShowChangeGlobalPricingDriverValuesModal,
  setShowCreateGlobalPricingDriverSuccessModal,
  submitGlobalPricingDriver,
  resetPricingDriverForm,
  setDefaultGlobalDriver
})(CreateGlobalPricingDriversModal);
