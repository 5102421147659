import firebase from "firebase/app";
// Add the Firebase products that you want to use
import "firebase/auth";
import "firebase/firestore";
import jwtDecode from "jwt-decode";

class FirebaseAuthBackend {
  constructor(firebaseConfig) {
    if (firebaseConfig) {
      // Initialize Firebase
      firebase.initializeApp(firebaseConfig);
      this.googleProvider = new firebase.auth.GoogleAuthProvider();
      this.microsoftProvider = new firebase.auth.OAuthProvider("microsoft.com");
      firebase.auth().onAuthStateChanged((user) => {
        if (user) {
          localStorage.setItem("authUser", JSON.stringify(user));
        } else {
          localStorage.removeItem("authUser");
          localStorage.removeItem("dashboardFilter");
        }
      });
    }
  }

  async forceRefreshToken() {
    console.log("firebase.auth().currentUser - ", firebase.auth().currentUser);
    if (firebase.auth().currentUser) {
      const idToken = await firebase.auth().currentUser.getIdToken(true);
      let authUser = JSON.parse(window.localStorage.getItem("authUser"));
      authUser.stsTokenManager.expirationTime = jwtDecode(idToken).exp * 1000;
      authUser.stsTokenManager.accessToken = idToken;
      window.localStorage.setItem("authUser", JSON.stringify(authUser));
      return idToken;
    }
  }

  /**
   * Registers the user with given details
   */
  registerUser = (email, password) => {
    return new Promise((resolve, reject) => {
      firebase
        .auth()
        .createUserWithEmailAndPassword(email, password)
        .then(
          (user) => {
            resolve(firebase.auth().currentUser);
          },
          (error) => {
            reject(this._handleError(error));
          }
        );
    });
  };

  /**
   * Login user with given details
   */
  loginUser = (email, password) => {
    return new Promise((resolve, reject) => {
      console.log("logging in - ");
      firebase
        .auth()
        .setPersistence(firebase.auth.Auth.Persistence.LOCAL)
        .then(() => {
          // Existing and future Auth states are now persisted in the current
          // session only. Closing the window would clear any existing state even
          // if a user forgets to sign out.
          // ...
          // New sign-in will be persisted with session persistence.
          return firebase
            .auth()
            .signInWithEmailAndPassword(email, password)
            .then(
              (user) => {
                resolve(firebase.auth().currentUser);
              },
              (error) => {
                reject(this._handleError(error));
              }
            );
        })
        .catch((error) => {
          // Handle Errors here.
          var errorCode = error.code;
          var errorMessage = error.message;
        });
    });
  };

  /**
   * Sign in with custom token
   */
  signInWithCustomToken = (token) => {
    return new Promise((resolve, reject) => {
      firebase
        .auth()
        .setPersistence(firebase.auth.Auth.Persistence.LOCAL)
        .then(() => {
          console.log("Logging - in ");
          // Existing and future Auth states are now persisted in the current
          // session only. Closing the window would clear any existing state even
          // if a user forgets to sign out.
          // ...
          // New sign-in will be persisted with session persistence.
          return firebase
            .auth()
            .signInWithCustomToken(token)
            .then(
              (user) => {
                resolve(firebase.auth().currentUser);
              },
              (error) => {
                reject(this._handleError(error));
              }
            );
        })
        .catch((error) => {
          // Handle Errors here.
          var errorCode = error.code;
          var errorMessage = error.message;
        });
    });
  };

  /**
   * forget Password user with given details
   */
  forgetPassword = (email) => {
    return new Promise((resolve, reject) => {
      firebase
        .auth()
        .sendPasswordResetEmail(email, {
          url:
            window.location.protocol + "//" + window.location.host + "/login",
        })
        .then(() => {
          console.log("yes authutils");
          resolve(true);
        })
        .catch((error) => {
          reject(this._handleError(error));
        });
    });
  };

  /**
   * Logout the user
   */
  logout = () => {
    return new Promise((resolve, reject) => {
      firebase
        .auth()
        .signOut()
        .then(() => {
          resolve(true);
        })
        .catch((error) => {
          reject(this._handleError(error));
        });
    });
  };

  setLoggeedInUser = (user) => {
    localStorage.setItem("authUser", JSON.stringify(user));
  };

  /**
   * Returns the authenticated user
   */
  getAuthenticatedUser = () => {
    if (!localStorage.getItem("authUser")) return null;
    return JSON.parse(localStorage.getItem("authUser"));
  };

  getAuthProviders = () => {
    return this.googleProvider;
  };

  getMicrosoftAuthProviders = () => {
    return this.microsoftProvider;
  };

  getAuthObject = () => {
    return firebase.auth();
  };

  getToken = async () => {
    return await firebase.auth().currentUser.getIdToken();
  };

  getAccessToken = async () => {
    // let d = await firebase.auth().currentUser;
    // console.log(d ? d.getIdToken(): d);
    // firebase.auth().onAuthStateChanged(user => {
    //   console.log(user);
    //   if (user) {
    //     console.log("state = definitely signed in")
    //   }
    //   else {
    //     console.log("state = definitely signed out")
    //   }
    // })
    // console.log(this.currentUser ? this.currentUser.getIdToken() : this.currentUser);
    const expirationTime = JSON.parse(localStorage.getItem("authUser"))
      .stsTokenManager.expirationTime;
    const isExpired = Date.now() > expirationTime;
    return isExpired
      ? await this.forceRefreshToken()
      : JSON.parse(localStorage.getItem("authUser")).stsTokenManager
          .accessToken;
    // return await this.forceRefreshToken();
  };

  getClaims = () => {
    if (!localStorage.getItem("authUser")) return null;
    return jwtDecode(
      JSON.parse(localStorage.getItem("authUser")).stsTokenManager.accessToken
    ).claims;
  };

  getUserRole = () => {
    if (!localStorage.getItem("authUser")) return null;
    return jwtDecode(
      JSON.parse(localStorage.getItem("authUser")).stsTokenManager.accessToken
    ).claims?.role;
  };

  getOrganistionName = () => {
    if (!localStorage.getItem("authUser")) return null;
    return jwtDecode(
      JSON.parse(localStorage.getItem("authUser")).stsTokenManager.accessToken
    ).claims.org.name;
  };

  getOrganistionId = () => {
    if (!localStorage.getItem("authUser")) return null;
    return jwtDecode(
      JSON.parse(localStorage.getItem("authUser")).stsTokenManager.accessToken
    ).claims.org.id;
  };

  /**
   * Handle the error
   * @param {*} error
   */
  _handleError(error) {
    // var errorCode = error.code;
    var errorMessage = error.message;
    return errorMessage;
  }
}

let _fireBaseBackend = null;

/**
 * Initilize the backend
 * @param {*} config
 */
const initFirebaseBackend = (config) => {
  if (!_fireBaseBackend) {
    _fireBaseBackend = new FirebaseAuthBackend(config);
  }
  return _fireBaseBackend;
};

/**
 * Returns the firebase backend
 */
const getFirebaseBackend = () => {
  return _fireBaseBackend;
};

export { initFirebaseBackend, getFirebaseBackend };
