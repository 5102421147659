import { takeEvery, fork, put, all, call } from 'redux-saga/effects';
import axios from 'axios';

import {
    GET_TERMS_AND_CONDITION_TEMPLATES,
    GET_TERMS_AND_CONDITION_TEMPLATES_SUCCESS,
    GET_TERMS_AND_CONDITION_TEMPLATE,
    GET_TERMS_AND_CONDITION_TEMPLATE_SUCCESS,
    SUBMIT_TERMS_AND_CONDITION_TEMPLATE,
    UPLOAD_TERMS_AND_CONDITION_TEMPLATE
} from "./actionTypes";

import {
    getTermsAndConditionTemplatesSuccess, getTermsAndConditionTemplateSuccess, submitTermsAndConditionTemplateSuccess, uploadTermsAndConditionTemplateSuccess,createTermsConditionFailed
} from './actions';
import { setShowApiErrorModal } from '../common/actions';

// AUTH related methods
import { getFirebaseBackend } from '../../helpers/authUtils';

const fireBaseBackend = getFirebaseBackend();

function* getTermsAndConditionTemplates() {
    try {
        const accessToken = yield fireBaseBackend.getAccessToken();
        const response = yield axios({
            method: "GET",
            url: `tnc-templates`,
            headers: {authorization: `Bearer ${accessToken}`}
        });
        if (response.data.success) {
            yield put(getTermsAndConditionTemplatesSuccess(response.data.result));
        } else {
            // yield put(createOrganisationFailed());
        }
    } catch (error) {
        yield put(setShowApiErrorModal());
        console.log(error);
        // if (error.response && error.response.data && error.response.data.error) {
        //     yield put(loginError(error.response.data.error));
        // } else {
        //     yield put(loginError("Something went Wrong!"));
        // }
        // yield put(createOrganisationFailed());
    }
}

function* getTermsAndConditionTemplate({payload: id}) {
    try {
        const accessToken = yield fireBaseBackend.getAccessToken();
        const response = yield axios({
            method: "GET",
            url: `template/${id}`,
            headers: {authorization: `Bearer ${accessToken}`}
        });
        if (response.data.success) {
            yield put(getTermsAndConditionTemplateSuccess(response.data.result));
        } else {
            // yield put(createOrganisationFailed());
        }
    } catch (error) {
        yield put(setShowApiErrorModal());
        // if (error.response && error.response.data && error.response.data.error) {
        //     yield put(loginError(error.response.data.error));
        // } else {
        //     yield put(loginError("Something went Wrong!"));
        // }
        // yield put(createOrganisationFailed());
    }
}

function* submitTermsAndConditionTemplate({payload: {data, type}}) {
    try {
        const accessToken = yield fireBaseBackend.getAccessToken();
        let url;
        switch(type) {
            case "new":
                url = "create-new-template";
                break;
            case "edit":
                url = "update-template";
                break;
            default:
                break;
        }
        const response = yield axios({
            method: "POST",
            url,
            headers: { authorization: `Bearer ${accessToken}` },
            data
        });
        if (response.data.success) {
            yield put(submitTermsAndConditionTemplateSuccess());
        } else {
            yield put(createTermsConditionFailed(response.data.error));
            // yield put(createOrganisationFailed());
        }
    } catch (error) {
        yield put(setShowApiErrorModal());
        // if (error.response && error.response.data && error.response.data.error) {
        //     yield put(loginError(error.response.data.error));
        // } else {
        //     yield put(loginError("Something went Wrong!"));
        // }
        // yield put(createOrganisationFailed());
    }
}

function* uploadTermsAndConditionTemplate({ payload: file }) {
    try {
        const accessToken = yield fireBaseBackend.getAccessToken();
        const data = new FormData()
        console.log("file :: ", file)
        data.append('tncFile', file)
        console.log("saga")
        // let url;
        // switch (type) {
        //     case "new":
        //         url = "create-new-template";
        //         break;
        //     case "edit":
        //         url = "update-template";
        //         break;
        //     default:
        //         break;
        // }
        const response = yield axios({
            method: "POST",
            url: "upload-tnc-template",
            headers: { authorization: `Bearer ${accessToken}` },
            data
        });
        if (response.data.success) {
            yield put(uploadTermsAndConditionTemplateSuccess(response.data.result));
        } else {
            // yield put(createOrganisationFailed());
        }
    } catch (error) {
        yield put(setShowApiErrorModal());
    }
}

export function* watchGetTermsAndConditionTemplates() {
    yield takeEvery(GET_TERMS_AND_CONDITION_TEMPLATES, getTermsAndConditionTemplates);
}

export function* watchGetTermsAndConditionTemplate() {
    yield takeEvery(GET_TERMS_AND_CONDITION_TEMPLATE, getTermsAndConditionTemplate);
}

export function* watchSubmitTermsAndConditionTemplate() {
    yield takeEvery(SUBMIT_TERMS_AND_CONDITION_TEMPLATE, submitTermsAndConditionTemplate);
}

export function* watchuploadTermsAndConditionTemplate() {
    yield takeEvery(UPLOAD_TERMS_AND_CONDITION_TEMPLATE, uploadTermsAndConditionTemplate);
}

function* textTemplateSaga() {
    yield all([
        fork(watchGetTermsAndConditionTemplates),
        fork(watchGetTermsAndConditionTemplate),
        fork(watchSubmitTermsAndConditionTemplate),
        fork(watchuploadTermsAndConditionTemplate),
    ]);
}

export default textTemplateSaga;

