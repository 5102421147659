import React, { useState } from "react";
import AccountantVariables from "./AccountantVariables";

const SoleTraderVariableHelper = (props) => {
  const [copiedMessage, setCopiedMessage] = useState("");
  const [cursorPosition, setCursorPosition] = useState({ x: 0, y: 0 });

  const handleVariableClick = (variable, event) => {
    // Copy to clipboard
    navigator.clipboard.writeText(variable);

    // Set cursor position
    setCursorPosition({ x: event.clientX, y: event.clientY });

    // Show the message
    setCopiedMessage("Variable copied!");

    // Clear the message after some time (e.g., 2 seconds)
    setTimeout(() => {
      setCopiedMessage("");
    }, 2000);
  };
  return (
    <div className="fieldset-group helper-variables-div">
      <label className="fieldset-group-label">Variables</label>
      <AccountantVariables businessType={props.businessType} />
      <div>
        <span
          className="badge badge-secondary"
          onClick={(e) =>
            handleVariableClick("$Client.SoleTrader.FirstName$", e)
          }
          style={{ cursor: "pointer" }}
        >
          $Client.SoleTrader.FirstName$
        </span>
        <span
          className="badge badge-secondary"
          onClick={(e) =>
            handleVariableClick("$Client.SoleTrader.LastName$", e)
          }
          style={{ cursor: "pointer" }}
        >
          $Client.SoleTrader.LastName$
        </span>
        <span
          className="badge badge-secondary"
          onClick={(e) => handleVariableClick("$Client.SoleTrader.Email$", e)}
          style={{ cursor: "pointer" }}
        >
          $Client.SoleTrader.Email$
        </span>
        <span
          className="badge badge-secondary"
          onClick={(e) => handleVariableClick("$Client.SoleTrader.Phone$", e)}
          style={{ cursor: "pointer" }}
        >
          $Client.SoleTrader.Phone$
        </span>
      </div>
      <div>
        <span
          className="badge badge-secondary"
          onClick={(e) => handleVariableClick("$Client.TradingName$", e)}
          style={{ cursor: "pointer" }}
        >
          $Client.TradingName$
        </span>
        <span
          className="badge badge-secondary"
          onClick={(e) => handleVariableClick("$Client.TradingAddress$", e)}
          style={{ cursor: "pointer" }}
        >
          $Client.TradingAddress$
        </span>
        <span
          className="badge badge-secondary"
          onClick={(e) =>
            handleVariableClick("$Client.TradingAddressWithLineBreak$", e)
          }
          style={{ cursor: "pointer" }}
        >
          $Client.TradingAddressWithLineBreak$
        </span>
      </div>
      <div>
        <span
          className="badge badge-secondary"
          onClick={(e) => handleVariableClick("$Client.SoleTrader.Address$", e)}
          style={{ cursor: "pointer" }}
        >
          $Client.SoleTrader.Address$
        </span>
        <span
          className="badge badge-secondary"
          onClick={(e) =>
            handleVariableClick("$Client.SoleTrader.AddressWithLineBreak$", e)
          }
          style={{ cursor: "pointer" }}
        >
          $Client.SoleTrader.AddressWithLineBreak$
        </span>
        <span
          className="badge badge-secondary"
          onClick={(e) =>
            handleVariableClick("$CurrentDate$", e)
          }
          style={{ cursor: "pointer" }}
        >
          $CurrentDate$
        </span>
        <span
          className="badge badge-secondary"
          onClick={(e) =>
            handleVariableClick("$DocumentId$", e)
          }
          style={{ cursor: "pointer" }}
        >
          $DocumentId$
        </span>
      </div>
      {/* Display the copied message */}
      {copiedMessage && (
        <div
          style={{
            position: "fixed",
            top: cursorPosition.y - 30 + "px",
            left: cursorPosition.x + "px",
            fontSize: "0.8em", // Adjust the font size as needed
            color: "green",
            padding: "5px",
            backgroundColor: "#f0f8ff", // Light blue background color
            borderRadius: "5px",
            boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.5)", // Box shadow for a subtle effect
          }}
        >
          {copiedMessage}
        </div>
      )}
    </div>
  );
};

export default SoleTraderVariableHelper;
