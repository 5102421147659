import { takeEvery, fork, put, all, call } from 'redux-saga/effects';
import axios from 'axios';

import {
    SUBMIT_SUBSCRIPTION_PACKAGE,
    GET_SUBSCRIPTION_PACKAGE,
    TOGGLE_SUBSCRIPTION,
    DELETE_SUBSCRIPTION_PACKAGE,
    TOGGLE_SUBSCRIPTION_PACKAGE,
    GET_SUBSCRIPTION_PACKAGES,
    GET_INVOICES, GET_DEFAULT_SUBSCRIPTION_PACKAGE, SEARCH_INVOICES,
} from "./actionTypes";

import {
    getSubscriptionPackagesSuccess,
    getSubscriptionPackageSuccess,
    submitSubscriptionPackageSuccess,
    toggleSubscriptionPackageSuccess,
    toggleSubscriptionSuccess,
    deleteSubscriptionPackageSuccess,
    createSubscriptionPackageFailed,
    getInvoicesSuccess, getDefaultSubscriptionPackageSuccess, searchInvoicesSuccess
} from './actions';
import { setShowApiErrorModal } from '../common/actions';

// AUTH related methods
import { getFirebaseBackend } from '../../helpers/authUtils';

const fireBaseBackend = getFirebaseBackend();

function* getSubscriptionPackages({payload: query}) {
    try {
        const accessToken = yield fireBaseBackend.getAccessToken();
        const response = yield axios({
            method: "GET",
            url: query ? `subscription-packages?page_num=${query.page_num}&text=${query.text ?? ''}` : `subscription-packages`,
            headers: {authorization: `Bearer ${accessToken}`}
        });
        if (response.data.success) {
            yield put(getSubscriptionPackagesSuccess(response.data.result));
        } else {
            // yield put(createOrganisationFailed());
        }
    } catch (error) {
        yield put(setShowApiErrorModal());
        console.log(error);
        // if (error.response && error.response.data && error.response.data.error) {
        //     yield put(loginError(error.response.data.error));
        // } else {
        //     yield put(loginError("Something went Wrong!"));
        // }
        // yield put(createOrganisationFailed());
    }
}

function* getSubscriptionPackage({payload: {id}}) {
    try {
        const accessToken = yield fireBaseBackend.getAccessToken();
        const response = yield axios({
            method: "GET",
            url: `subscription-package/${id}`,
            headers: {authorization: `Bearer ${accessToken}`}
        });
        if (response.data.success) {
            yield put(getSubscriptionPackageSuccess(response.data.result));
        } else {
            // yield put(createOrganisationFailed());
        }
    } catch (error) {
        yield put(setShowApiErrorModal());
        console.log(error);
        // if (error.response && error.response.data && error.response.data.error) {
        //     yield put(loginError(error.response.data.error));
        // } else {
        //     yield put(loginError("Something went Wrong!"));
        // }
        // yield put(createOrganisationFailed());
    }
}

function* getDefaultSubscriptionPackage() {
    try {
        const accessToken = yield fireBaseBackend.getAccessToken();
        const response = yield axios({
            method: "GET",
            url: `default-subscription-package`,
            headers: {authorization: `Bearer ${accessToken}`}
        });
        if (response.data.success) {
            yield put(getDefaultSubscriptionPackageSuccess(response.data.result));
        } else {
            // yield put(createOrganisationFailed());
        }
    } catch (error) {
        yield put(setShowApiErrorModal());
        console.log(error);
        // if (error.response && error.response.data && error.response.data.error) {
        //     yield put(loginError(error.response.data.error));
        // } else {
        //     yield put(loginError("Something went Wrong!"));
        // }
        // yield put(createOrganisationFailed());
    }
}

function* getInvoices({payload: {limit, starting_after, page_num, period, status}}) {
    try {
        const accessToken = yield fireBaseBackend.getAccessToken();
        let queryParams = new URLSearchParams();
        limit && queryParams.append('limit', limit);
        starting_after && queryParams.append('starting_after', starting_after);
        page_num && queryParams.append('page_num', page_num);
        period && queryParams.append('period', period);
        status && queryParams.append('status', status);
        const response = yield axios({
            method: "GET",
            url: `invoices?${queryParams.toString()}`,
            headers: {authorization: `Bearer ${accessToken}`}
        });
        if (response.data.success) {
            yield put(getInvoicesSuccess(response.data.result));
        } else {
            // yield put(createOrganisationFailed());
        }
    } catch (error) {
        yield put(setShowApiErrorModal());
        console.log(error);
        // if (error.response && error.response.data && error.response.data.error) {
        //     yield put(loginError(error.response.data.error));
        // } else {
        //     yield put(loginError("Something went Wrong!"));
        // }
        // yield put(createOrganisationFailed());
    }
}

function* searchInvoices({payload: {limit, starting_after, page_num, customer, number}}) {
    try {
        const accessToken = yield fireBaseBackend.getAccessToken();
        let queryParams = new URLSearchParams();
        limit && queryParams.append('limit', limit);
        starting_after && queryParams.append('starting_after', starting_after);
        page_num && queryParams.append('page_num', page_num);
        customer && queryParams.append('customer', customer);
        number && queryParams.append('number', number);
        const response = yield axios({
            method: "GET",
            url: `search-invoices?${queryParams.toString()}`,
            headers: {authorization: `Bearer ${accessToken}`}
        });
        if (response.data.success) {
            yield put(searchInvoicesSuccess(response.data.result));
        } else {
            // yield put(createOrganisationFailed());
        }
    } catch (error) {
        yield put(setShowApiErrorModal());
        console.log(error);
        // if (error.response && error.response.data && error.response.data.error) {
        //     yield put(loginError(error.response.data.error));
        // } else {
        //     yield put(loginError("Something went Wrong!"));
        // }
        // yield put(createOrganisationFailed());
    }
}

function* submitSubscriptionPackage({payload: {data, type}}) {
    try {
        const accessToken = yield fireBaseBackend.getAccessToken();
        let url;
        switch(type) {
            case "new":
                url = "create-subscription-package";
                break;
            case "edit":
                url = "update-subscription-package";
                break;
            default:
                break;
        }
        const response = yield axios({
            method: "POST",
            url,
            headers: {authorization: `Bearer ${accessToken}`},
            data
        });
        if (response.data.success) {
            yield put(submitSubscriptionPackageSuccess());
        } else {
            yield put(createSubscriptionPackageFailed(response.data.error));
            // yield put(createOrganisationFailed());
        }
    } catch (error) {
        yield put(setShowApiErrorModal());
        // if (error.response && error.response.data && error.response.data.error) {
        //     yield put(loginError(error.response.data.error));
        // } else {
        //     yield put(loginError("Something went Wrong!"));
        // }
        // yield put(createOrganisationFailed());
    }
}

function* deleteSubscriptionPackage({payload: {data}}) {
    try {
        const accessToken = yield fireBaseBackend.getAccessToken();
        const response = yield axios({
            method: "DELETE",
            url: 'delete-subscription-package',
            headers: {authorization: `Bearer ${accessToken}`},
            data
        });
        if (response.data.success) {
            yield put(deleteSubscriptionPackageSuccess());
        } else {
            // yield put(deleteSubscriptionPackageFailed(response.data.error));
            // yield put(createOrganisationFailed());
        }
    } catch (error) {
        yield put(setShowApiErrorModal());
        // if (error.response && error.response.data && error.response.data.error) {
        //     yield put(loginError(error.response.data.error));
        // } else {
        //     yield put(loginError("Something went Wrong!"));
        // }
        // yield put(createOrganisationFailed());
    }
}

function* toggleSubscriptionPackage({payload: {data}}) {
    try {
        const accessToken = yield fireBaseBackend.getAccessToken();
        const response = yield axios({
            method: "POST",
            url: 'toggle-subscription-package',
            headers: {authorization: `Bearer ${accessToken}`},
            data
        });
        if (response.data.success) {
            yield put(toggleSubscriptionPackageSuccess(response.data.message));
        } else {
            // yield put(deleteSubscriptionPackageFailed(response.data.error));
            // yield put(createOrganisationFailed());
        }
    } catch (error) {
        yield put(setShowApiErrorModal());
        // if (error.response && error.response.data && error.response.data.error) {
        //     yield put(loginError(error.response.data.error));
        // } else {
        //     yield put(loginError("Something went Wrong!"));
        // }
        // yield put(createOrganisationFailed());
    }
}

function* toggleSubscription({payload: {data}}) {
    try {
        const accessToken = yield fireBaseBackend.getAccessToken();
        const response = yield axios({
            method: "POST",
            url: 'toggle-subscription',
            headers: {authorization: `Bearer ${accessToken}`},
            data
        });
        if (response.data.success) {
            yield put(toggleSubscriptionSuccess(response.data.message));
        } else {
            // yield put(deleteSubscriptionPackageFailed(response.data.error));
            // yield put(createOrganisationFailed());
        }
    } catch (error) {
        yield put(setShowApiErrorModal());
        // if (error.response && error.response.data && error.response.data.error) {
        //     yield put(loginError(error.response.data.error));
        // } else {
        //     yield put(loginError("Something went Wrong!"));
        // }
        // yield put(createOrganisationFailed());
    }
}

export function* watchGetSubscriptionPackages() {
    yield takeEvery(GET_SUBSCRIPTION_PACKAGES, getSubscriptionPackages);
}

export function* watchGetSubscriptionPackage() {
    yield takeEvery(GET_SUBSCRIPTION_PACKAGE, getSubscriptionPackage);
}

export function* watchDeleteSubscriptionPackage() {
    yield takeEvery(DELETE_SUBSCRIPTION_PACKAGE, deleteSubscriptionPackage);
}

export function* watchToggleSubscriptionPackage() {
    yield takeEvery(TOGGLE_SUBSCRIPTION_PACKAGE, toggleSubscriptionPackage);
}

export function* watchToggleSubscription() {
    yield takeEvery(TOGGLE_SUBSCRIPTION, toggleSubscription);
}

export function* watchCreateSubscriptionPackage() {
    yield takeEvery(SUBMIT_SUBSCRIPTION_PACKAGE, submitSubscriptionPackage);
}

export function* watchGetDefaultSubscriptionPackage() {
    yield takeEvery(GET_DEFAULT_SUBSCRIPTION_PACKAGE, getDefaultSubscriptionPackage);
}

export function* watchGetInvoices() {
    yield takeEvery(GET_INVOICES, getInvoices);
}

export function* watchSearchInvoices() {
    yield takeEvery(SEARCH_INVOICES, searchInvoices);
}

function* templateSaga() {
    yield all([
        fork(watchGetSubscriptionPackages),
        fork(watchGetSubscriptionPackage),
        fork(watchDeleteSubscriptionPackage),
        fork(watchToggleSubscriptionPackage),
        fork(watchToggleSubscription),
        fork(watchCreateSubscriptionPackage),
        fork(watchGetDefaultSubscriptionPackage),
        fork(watchGetInvoices),
        fork(watchSearchInvoices),
    ]);
}

export default templateSaga;
