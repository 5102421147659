export default [
   {
      "value":"+44",
	  "label":"+44"
   },
   {
      "value":"+1",
	  "label":"+1"
   },
   {
      "value":"+7",
	  "label":"+7"
   },
   {
      "value":"+31",
	  "label":"+31"
   },
   {
      "value":"+32",
	  "label":"+32"
   },
   {
      "value":"+33",
	  "label":"+33"
   },
   {
      "value":"+34",
	  "label":"+34"
   },
   {
      "value":"+39",
	  "label":"+39"
   },
   {
      "value":"+41",
	  "label":"+41"
   },
   {
      "value":"+46",
	  "label":"+46"
   },
   {
      "value":"+48",
	  "label":"+48"
   },
   {
      "value":"+49",
	  "label":"+49"
   },
   {
      "value":"+52",
	  "label":"+52"
   },
   {
      "value":"+55",
	  "label":"+55"
   },
   {
      "value":"+61",
	  "label":"+61"
   },
   {
      "value":"+62",
	  "label":"+62"
   },
   {
      "value":"+66",
	  "label":"+66"
   },
   {
      "value":"+81",
	  "label":"+81"
   },
   {
      "value":"+82",
	  "label":"+82"
   },
   {
      "value":"+86",
	  "label":"+86"
   },
   {
      "value":"+90",
	  "label":"+90"
   },
   {
      "value":"+91",
	  "label":"+91"
   },
   {
      "value":"+234",
	  "label":"+234"
   },
   {
      "value":"+966",
	  "label":"+966"
   }
];
