import React from "react";
import Select from "react-select";

let loopCount = 0
let concatKeys = [];
let reg = /^\d+$/;

const renderDependents = (props, currDriver, inputVariableName, key, serviceId, pricingDriverKeys) => {
    const options = (item) => {
        let optionsArr = []
        if (item.type === "Variation") {
            const optionsMap = item?.variations?.map((ele) => {
                optionsArr.push({ value: ele._id, label: ele.name })
            })
            return optionsArr
        }
        if (item.type === "Slab") {
            const optionsMap = item?.slabs?.map((ele) => {
                if (["UnitBased", "BlockBased"].includes(ele.type)) {
                    optionsArr.push({ value: ele._id, label: `${ele.from}-${ele.to}` });
                }
                else if (["IncrementalUnit", "IncrementalBlock"].includes(ele.type)) {
                    optionsArr.push({ value: ele._id, label: "Other" })
                }
            })
            return optionsArr
        }
    }

    const setDefaultVariation = (item, driverInputVariableName) => {
        let defaultVariation
        if (item.type === "Variation") { defaultVariation = item?.variations?.find((e) => e.is_default === true) }
        if (item.type === "Slab") { defaultVariation = item?.slabs?.find((e) => e.is_default === true) }
        defaultVariation = { value: defaultVariation?._id, label: defaultVariation?.name }
        if (!options(item).filter(option => option.value === props.parentProps[driverInputVariableName]).length) {
            // console.log("setDefault", !options(item).filter(option => option.value === props.parentProps[driverInputVariableName]).length);                        
            props.onInputChange(driverInputVariableName, defaultVariation.value)
        }
    }

    const nestedDependentsJsx = (dependentsArray, driverInputVariableName, driversJsx) => {
        let nestedDriverInputVariableName
        const nestedDependents = dependentsArray.map((elem) => {
            let nestedDriverFromDependencyList = props?.dependencyArray.filter(e => e._id === elem._id && e.name === elem.name && e.serviceId == serviceId && e.categoryId == key)
            if (nestedDriverFromDependencyList?.length > 0) {
                console.log('nestedDriverFromDependencyList[0]?.variations - ', nestedDriverFromDependencyList[0]?.variations, driverInputVariableName, props?.parentProps[driverInputVariableName]);
                const selectedVarName = nestedDriverFromDependencyList[0]?.variations?.filter(ele => ele?._id === props?.parentProps[driverInputVariableName])[0]?.name
                const nestedDependentsArray = nestedDriverFromDependencyList[0]?.dependents?.filter(ele => ele?.dependencyVariation === selectedVarName);
                console.log('nestedDependentsArray - ', nestedDependentsArray);
                if (nestedDependentsArray?.length > 0) {
                    let nestedDependentJsx = nestedDependentsArray?.map((el) => {
                        nestedDriverInputVariableName = `pricingDriver-${key}-${serviceId}_${props.chargeType}-${el._id}`
                        let _nestedDriverInputVariableName = `pricingDriver-${key}-${serviceId}_${props.chargeType}-${el._id}`
                        pricingDriverKeys.push(_nestedDriverInputVariableName);
                        if (el.type === "Variation" || el.type === "Slab") { setDefaultVariation(el, _nestedDriverInputVariableName) }
                        driversJsx.push(
                            <div key={el?._id} style={{ fontSize: "13.86px" }} className="default-value-div mt-2 mb-2">
                                <label style={{ color: "#274b8a" }} className="required">{el?.name}</label>

                                {el.type === "Quantity" && <input
                                    value={props.parentProps[_nestedDriverInputVariableName] || ""}
                                    onChange={(e) => {
                                        if (!reg.test(e.target.value) && e.target.value != "") return;
                                        props.onInputChange(_nestedDriverInputVariableName, e.target.value)
                                    }}
                                    type="text"
                                    className="input-text"
                                    placeholder={el.name}
                                />}
                                {el.type === "Quantity" && props.validator.message(_nestedDriverInputVariableName, props.parentProps[_nestedDriverInputVariableName], `_required|_numeric|_min:0,num`, { className: 'text-danger' })}

                                {(el.type === "Variation" || el.type === "Slab") && <Select
                                    options={options(el, _nestedDriverInputVariableName)}
                                    onChange={selectedOption => { props.onInputChange(_nestedDriverInputVariableName, selectedOption.value); }}
                                    value={(props.parentProps[_nestedDriverInputVariableName] ? options(el)?.filter(option => option.value === props.parentProps[_nestedDriverInputVariableName]) : "")}
                                />}
                                {(el.type === "Variation" || el.type === "Slab") && props.validator.message(_nestedDriverInputVariableName, props.parentProps[_nestedDriverInputVariableName], `_required`, { className: 'text-danger' })}

                                {el.type === "Slab" && el.slabs.length > 0 && el.slabs[el.slabs.length - 1]._id === props.parentProps[_nestedDriverInputVariableName] && ["IncrementalBlock", "IncrementalUnit"].includes(el.slabs[el.slabs.length - 1].type) &&
                                    <React.Fragment>
                                        <input
                                            value={props.parentProps[`${_nestedDriverInputVariableName}-${options(el)[el.slabs.length - 1].value}_other`] || ""}
                                            onChange={(e) => {
                                                if (!reg.test(e.target.value) && e.target.value != "") return;
                                                props.onInputChange(`${_nestedDriverInputVariableName}-${options(el)[el.slabs.length - 1].value}_other`, e.target.value)
                                            }}
                                            type="text"
                                            className="input-text other-input-text"
                                            placeholder="Enter Other Value"
                                        />
                                        {props.validator.message(`${_nestedDriverInputVariableName}-${options(el)[el.slabs.length - 1].value}_other`, props.parentProps[`${_nestedDriverInputVariableName}-${options(el)[el.slabs.length - 1].value}_other`], `_required|_numeric`, { className: 'text-danger' })}
                                    </React.Fragment>
                                }

                            </div>)
                        nestedDependentsJsx(nestedDependentsArray, _nestedDriverInputVariableName, driversJsx)
                    })
                }
            }
        })
    }

    const currDriverFromDependencyList = props?.dependencyArray?.filter(ele => ele?._id === currDriver?._id && ele?.serviceId === currDriver?.service && ele?.dependents?.length > 0)[0]
    if (currDriverFromDependencyList) {
        const selectedVariationName = currDriverFromDependencyList?.variations?.filter(ele => ele?._id === props?.parentProps[inputVariableName])[0]?.name
        const dependentsArray = currDriverFromDependencyList?.dependents?.filter(ele => ele?.dependencyVariation === selectedVariationName)
        if (dependentsArray?.length > 0) {
            let driversJsx = [],
                driverInputVariableName,
                dependentsJsx = dependentsArray?.map((item) => {
                    driverInputVariableName = `pricingDriver-${key}-${serviceId}_${props.chargeType}-${item._id}`
                    let _driverInputVariableName = `pricingDriver-${key}-${serviceId}_${props.chargeType}-${item._id}`
                    // console.log('driverInputVariableName - ', driverInputVariableName);
                    pricingDriverKeys.push(_driverInputVariableName);
                    if (item.type === "Variation" || item.type === "Slab") {
                        setDefaultVariation(item, _driverInputVariableName)
                    }
                    driversJsx.push(
                        <div key={item?._id} style={{ fontSize: "13.86px" }} className="default-value-div mt-2 mb-2">
                            <label style={{ color: "#274b8a" }} className="required">{item?.name}</label>

                            {item.type === "Quantity" && <input
                                value={props.parentProps[_driverInputVariableName] || ""}
                                onChange={(e) => {
                                    if (!reg.test(e.target.value) && e.target.value != "") return;
                                    props.onInputChange(_driverInputVariableName, e.target.value)
                                }}
                                type="text"
                                className="input-text"
                                placeholder={item.name}
                            />}
                            {item.type === "Quantity" && props.validator.message(_driverInputVariableName, props.parentProps[_driverInputVariableName], `_required|_numeric|_min:0,num`, { className: 'text-danger' })}

                            {(item.type === "Variation" || item.type === "Slab") && <Select
                                options={options(item, _driverInputVariableName)}
                                onChange={selectedOption => {
                                    props.onInputChange(_driverInputVariableName, selectedOption.value);
                                }}
                                value={(props.parentProps[_driverInputVariableName] ? options(item)?.filter(option => option.value === props.parentProps[_driverInputVariableName]) : "")}
                            />}
                            {(item.type === "Variation" || item.type === "Slab") && props.validator.message(_driverInputVariableName, props.parentProps[_driverInputVariableName], `_required`, { className: 'text-danger' })}

                            {item.type === "Slab" && item.slabs.length > 0 && item.slabs[item.slabs.length - 1]._id === props.parentProps[_driverInputVariableName] && ["IncrementalBlock", "IncrementalUnit"].includes(item.slabs[item.slabs.length - 1].type) &&
                                <React.Fragment>
                                    <input
                                        value={props.parentProps[`${_driverInputVariableName}-${options(item)[item.slabs.length - 1].value}_other`] || ""}
                                        onChange={(e) => {
                                            if (!reg.test(e.target.value) && e.target.value != "") return;
                                            props.onInputChange(`${_driverInputVariableName}-${options(item)[item.slabs.length - 1].value}_other`, e.target.value)
                                        }}
                                        type="text"
                                        className="input-text other-input-text"
                                        placeholder="Enter Other Value"
                                    />
                                    {props.validator.message(`${_driverInputVariableName}-${options(item)[item.slabs.length - 1].value}_other`, props.parentProps[`${_driverInputVariableName}-${options(item)[item.slabs.length - 1].value}_other`], `_required|_numeric`, { className: 'text-danger' })}
                                </React.Fragment>
                            }

                        </div>
                    )
                    nestedDependentsJsx(dependentsArray, _driverInputVariableName, driversJsx)
                })
            console.log('dependentsArray - ', dependentsArray, inputVariableName, driversJsx);
            return driversJsx
        }
    }
}

const updateDynamicDrivers = (props, keys, key) => {
    props.onInputChange(key, keys)
}

const appendMultipleDrivers = (props, keys, key) => {
    if (loopCount < props?.services?.length) {
        concatKeys.push(keys)
        loopCount++
        return
    } else {
        const flatArray = concatKeys.flat()
        if (JSON.stringify(props.parentProps[key]) !== JSON.stringify(flatArray)) {
            updateDynamicDrivers(props, flatArray, key)
            loopCount = 0
            concatKeys = []
            return
        } else {
            loopCount = 0
            concatKeys = []
            return
        }
    }
}

const modifyPackageDriversArray = (props, pricingDriverKeys) => {
    const pricingDriversFromProps = props?.parentProps?.pricingDrivers
    if (pricingDriversFromProps.length > 0) {
        const splitDriverKey = pricingDriverKeys[0]?.split("-")
        const filterKey = `${splitDriverKey[0]}-${splitDriverKey[1]}-${splitDriverKey[2]}`
        const filteredArr = pricingDriversFromProps?.filter(e => e?.startsWith(filterKey))

        if (filteredArr.length > 0) {
            const uniqueElementsArr = filteredArr.filter(value => !pricingDriverKeys.includes(value)).filter((value, index, self) => self.indexOf(value) === index);
            if (!uniqueElementsArr.length) {
                const appendUniqueElements = pricingDriverKeys.filter(value => !filteredArr.includes(value)).filter((value, index, self) => self.indexOf(value) === index);
                if (appendUniqueElements.length > 0) {
                    appendUniqueElements.map((ele) => {
                        pricingDriversFromProps.push(ele)
                    })
                }
                JSON.stringify(pricingDriversFromProps) !== JSON.stringify(props.parentProps.pricingDrivers) && props.onInputChange("pricingDrivers", pricingDriversFromProps)
            }
            if (uniqueElementsArr.length > 0) {
                const newArr = pricingDriversFromProps.filter(value => !uniqueElementsArr.includes(value)).filter((value, index, self) => self.indexOf(value) === index);
                props.onInputChange("pricingDrivers", newArr)
            }
        }
    }
}

const SelectServices = (props) => {
    const servicePropKey = props.type === 'contract' ? 'contractServices' : 'aLaCarteServices';
    const pricingDriverPropKey = props.type === 'contract' ? 'contractPricingDrivers' : 'aLaCartePricingDrivers';

    let jsx = [];
    for (let key in props.servicesByCategory) {
        let innerJsx = [];
        let headerJsx = (
            <tr key={`header${key}`} className="head-row">
                <td>{props.servicesByCategory[key].categoryName.substring(0, 30)}</td>
            </tr>
        );
        for (let service of props.servicesByCategory[key].services) {
            if (service.chargeType === props.chargeType || service.chargeType === "recurring/oneoff") {
                let isServiceSelected = props.services.includes(`${key}-${service.id}_${props.chargeType}`);
                let isNotAllowed = false;
                if (!isServiceSelected && props.chargeType === "recurring") {
                    isNotAllowed = props.services.includes(`${key}-${service.id}_oneoff`);
                } else if (!isServiceSelected && props.chargeType === "oneoff") {
                    isNotAllowed = props.services.includes(`${key}-${service.id}_recurring`);
                }
                let pricingDriverJsx = [];
                let pricingDriverKeys = [];
                if (service.hasOwnProperty("pricingDrivers")) {
                    if (service?.pricingDrivers[0]?.hasOwnProperty("idx")) {
                        let sortDriversByIdx = service?.pricingDrivers?.sort((a, b) => a.idx - b.idx)
                    }
                    service.pricingDrivers.forEach((_item, index) => {
                        let item = _item;
                        if (item.globalDriverId) {
                            return;
                        }
                        let inputVariableName
                        if (!item.isDependent) {
                            inputVariableName = `pricingDriver-${key}-${service.id}_${props.chargeType}-${item._id}`;
                            pricingDriverKeys.push(inputVariableName);
                        }
                        let options;
                        let defaultVariation;
                        let defaultSlab;

                        if (item.type === "Variation" && !item.isDependent) {
                            options = item.variations.map(variationItem => ({ value: variationItem._id, label: variationItem.name }));
                            defaultVariation = item?.variations?.find((iv) => iv.is_default === true)
                            defaultVariation = {
                                value: defaultVariation?._id,
                                label: defaultVariation?.name
                            }
                            if (!options.filter(option => option.value === props.parentProps[inputVariableName]).length) {
                                props.onInputChange(inputVariableName, defaultVariation.value)
                            }
                        }
                        else if (item.type === "Slab" && !item.isDependent) {
                            options = item.slabs.map(slabItem => {
                                if (["UnitBased", "BlockBased"].includes(slabItem.type)) {
                                    return ({ value: slabItem._id, label: `${slabItem.from}-${slabItem.to}` });
                                }
                                else if (["IncrementalUnit", "IncrementalBlock"].includes(slabItem.type)) {
                                    return ({ value: slabItem._id, label: "Other" })
                                }
                            });
                            defaultSlab = item?.slabs?.find((iv) => iv.is_default === true)
                            defaultSlab = {
                                value: defaultSlab?._id,
                                label: defaultSlab?.name
                            }
                            if (!options.filter(option => option.value === props.parentProps[inputVariableName]).length) {
                                props.onInputChange(inputVariableName, defaultSlab.value)
                            }
                        }
                        else if (item.type === "Number Range" && !item.isDependent) {
                            options = item.number_ranges.map(numberRangeItem => ({ value: numberRangeItem._id, label: `${numberRangeItem.from}-${numberRangeItem.to}` }));
                        }

                        if(!item.isDependent) {
                            if(item.type === "Slab" && item.slabs.length > 0 && item.slabs[item.slabs.length - 1]._id === props.parentProps[inputVariableName] && ["IncrementalBlock", "IncrementalUnit"].includes(item.slabs[item.slabs.length - 1].type)) {
                                if(typeof props.parentProps[`${inputVariableName}-${options[item.slabs.length - 1].value}_other`] !== 'string') props.onInputChange(`${inputVariableName}-${options[item.slabs.length - 1].value}_other`, "", true)
                            }
                            pricingDriverJsx.push(
                              <div key={item._id} dataId={item._id} className="default-value-div">
                                  <label className="required mt-2"><b>{item.name}</b></label>
                                  {item.type === "Quantity" && <input
                                    value={props.parentProps[inputVariableName] || ""}
                                    onChange={(e) => {
                                        if (!reg.test(e.target.value) && e.target.value != "") return;
                                        props.onInputChange(inputVariableName, e.target.value, true)
                                    }}
                                    type="text"
                                    className="input-text"
                                    placeholder={item.name}
                                  />}
                                  {item.type === "Quantity" && props.validator.message(inputVariableName, props.parentProps[inputVariableName], `_required|_numeric|_min:0,num`, { className: 'text-danger' })}
                                  {item.type === "Variation" && <Select
                                    options={options}
                                    onChange={selectedOption => { props.onInputChange(inputVariableName, selectedOption.value, true); }}
                                    value={(props.parentProps[inputVariableName] ? options.filter(option => option.value === props.parentProps[inputVariableName]) : "")}
                                  />}
                                  {/* ----------render_Dependents--------------------- */}
                                  {item.type === "Variation" && renderDependents(props, item, inputVariableName, key, service.id, pricingDriverKeys)}
                                  {/* ---------------------------------------------- */}
                                  {item.type === "Slab" && <Select
                                    options={options}
                                    onChange={selectedOption => props.onInputChange(inputVariableName, selectedOption.value, true)}
                                    value={props.parentProps[inputVariableName] ? options.filter(option => option.value === props.parentProps[inputVariableName]) : ""}
                                  />}
                                  {item.type === "Number Range" && <Select
                                    options={options}
                                    onChange={selectedOption => props.onInputChange(inputVariableName, selectedOption.value, true)}
                                    value={props.parentProps[inputVariableName] ? options.filter(option => option.value === props.parentProps[inputVariableName]) : ""}
                                  />}
                                  {(item.type === "Variation" || item.type === "Slab" || item.type === "Number Range") && props.validator.message(inputVariableName, props.parentProps[inputVariableName], `_required`, { className: 'text-danger' })}
                                  {item.type === "Slab" && item.slabs.length > 0 && item.slabs[item.slabs.length - 1]._id === props.parentProps[inputVariableName] && ["IncrementalBlock", "IncrementalUnit"].includes(item.slabs[item.slabs.length - 1].type) && <React.Fragment>
                                      <input
                                        value={props.parentProps[`${inputVariableName}-${options[item.slabs.length - 1].value}_other`] || ""}
                                        onChange={(e) => {
                                            props.onInputChange(`${inputVariableName}-${options[item.slabs.length - 1].value}_other`, e.target.value, true)
                                        }}
                                        type="text"
                                        className="input-text other-input-text 23123"
                                        placeholder="Enter Other Value"
                                      />
                                      {props.validator.message(`${inputVariableName}-${options[item.slabs.length - 1].value}_other`, props.parentProps[`${inputVariableName}-${options[item.slabs.length - 1].value}_other`], `_required|_numeric`, { className: 'text-danger' })}
                                  </React.Fragment>}
                              </div>
                            );

                        }
                    });
                }
                if (service.is_active) {
                    innerJsx.push(
                        <tr onClick={isNotAllowed ? null : (isServiceSelected ? (() => props.removeService(`${key}-${service.id}_${props.chargeType}`, pricingDriverKeys)) : (() => {
                            console.log('adding service')
                            props.addService(`${key}-${service.id}_${props.chargeType}`, pricingDriverKeys)
                        }))} key={service.id}>
                            <td>
                                <div className={`select-row ${isNotAllowed ? "select-not-allowed" : ""}`}><i className={`ion ion-md-${isServiceSelected ? "checkbox" : "square-outline"}`}></i><span style={{ wordBreak: "break-word" }}>{service.name ? service.name : ''}</span></div>
                                {isServiceSelected && pricingDriverJsx.length >= 0 &&
                                    <div onClick={e => e.stopPropagation()}>
                                        {isServiceSelected && pricingDriverJsx.length > 0 && props?.parentProps && props?.parentProps[pricingDriverPropKey]?.length && props?.parentProps[servicePropKey]?.length === 1 && pricingDriverKeys?.length && JSON.stringify(props.parentProps[pricingDriverPropKey]) !== JSON.stringify(pricingDriverKeys) && updateDynamicDrivers(props, pricingDriverKeys, pricingDriverPropKey)}
                                        {isServiceSelected && pricingDriverJsx.length > 0 && props?.parentProps && props?.parentProps[pricingDriverPropKey]?.length && props?.parentProps[servicePropKey]?.length > 1 && pricingDriverKeys?.length && appendMultipleDrivers(props, pricingDriverKeys, pricingDriverPropKey)}
                                        {isServiceSelected && pricingDriverJsx?.length > 0 && props?.mode === "create_editPackage" && props?.services?.length && pricingDriverKeys?.length && modifyPackageDriversArray(props, pricingDriverKeys)}
                                        {pricingDriverJsx}
                                    </div>
                                }
                            </td>
                        </tr>
                    );
                }
            }
        }
        if (innerJsx.length > 0) {
            innerJsx.unshift(headerJsx);
            jsx.push(
                <table key={key} className="table table-striped select-service-table table-hover">
                    <tbody>
                        {innerJsx}
                    </tbody>
                </table>
            );
        }
    }
    if (jsx.length === 0) {
        return <div className="text-center">No Services Found!</div>
    }
    return jsx;
}

export default SelectServices;
