import {
	SET_TEMPLATE_LOADING,
	ON_SUBSCRIPTION_PACKAGE_INPUT_CHANGE,
	MOVE_ELEMENT,
	ADD_ELEMENT_BLOCK,
	SUBMIT_SUBSCRIPTION_PACKAGE,
	TOGGLE_SUBSCRIPTION_PACKAGE_SUCCESS,
	TOGGLE_SUBSCRIPTION_SUCCESS,
	TOGGLE_SUBSCRIPTION,
	TOGGLE_SUBSCRIPTION_PACKAGE,
	GET_INVOICES_SUCCESS,
	GET_INVOICES,
	SEARCH_INVOICES_SUCCESS,
	SEARCH_INVOICES,
	SET_SHOW_CREATE_NEW_SUBSCRIPTION_SUCCESS_MODAL,
	GET_SUBSCRIPTION_PACKAGE,
	GET_SUBSCRIPTION_PACKAGE_SUCCESS,
	RESET_CREATE_NEW_TEMPLATE_FORM,
	SET_SHOW_CREATE_NEW_TEMPLATE_SUCCESS_MODAL,
	CREATE_SUBSCRIPTION_PACKAGE_FAIL,
	SET_SHOW_CREATE_NEW_TEMPLATE_FAILED_MODAL, GET_SUBSCRIPTION_PACKAGES_SUCCESS, SUBMIT_SUBSCRIPTION_PACKAGE_SUCCESS,
	SET_SHOW_CREATE_NEW_SUBSCRIPTION_PACKAGE_SUCCESS_MODAL, GET_SUBSCRIPTION_PACKAGES,
	GET_DEFAULT_SUBSCRIPTION_PACKAGE,
	GET_DEFAULT_SUBSCRIPTION_PACKAGE_SUCCESS
} from "./actionTypes";

const initialState = {
	loading: false,
	templates: [],
	showCreateNewSubscriptionPackageSuccessModal: false,
	showCreateNewSubscriptionSuccessModal: false,
	showCreateNewSubscriptionPackageFailedModal: false,
	subscriptionPackages: [],
	defaultPackage: null,
	invoices: [],
	elementBlocks: [],
	pagination: {},
	hasMoreInvoices: false
}

const template = (state = initialState, action) => {
	let newState;
	let elementBlocks;
	switch (action.type) {
			case SET_TEMPLATE_LOADING:
					return {...state, loading: action.payload}
		case ON_SUBSCRIPTION_PACKAGE_INPUT_CHANGE:
			return {...state, [action.payload.field]: action.payload.value};
		case MOVE_ELEMENT:
			elementBlocks = [...state.elementBlocks];
			let dragCode = elementBlocks[action.payload.dragIndex];
			elementBlocks.splice(action.payload.dragIndex, 1);
			elementBlocks.splice(action.payload.hoverIndex, 0, dragCode);
			return {...state, elementBlocks};
		case ADD_ELEMENT_BLOCK:
			elementBlocks = [...state.elementBlocks, action.payload];
			return {...state, elementBlocks}
		case TOGGLE_SUBSCRIPTION_PACKAGE:
			return {...state, loading: true}
		case TOGGLE_SUBSCRIPTION_PACKAGE_SUCCESS:
			return {...state, loading: false, showCreateNewSubscriptionPackageSuccessModal: action.payload.data}
		case TOGGLE_SUBSCRIPTION:
			return {...state, loading: true}
		case TOGGLE_SUBSCRIPTION_SUCCESS:
			return {...state, loading: false, showCreateNewSubscriptionSuccessModal: action.payload.data}
		case SUBMIT_SUBSCRIPTION_PACKAGE:
			return {...state, loading: true}
		case SUBMIT_SUBSCRIPTION_PACKAGE_SUCCESS:
			return {...state, loading: false, showCreateNewSubscriptionPackageSuccessModal: true}
		case GET_DEFAULT_SUBSCRIPTION_PACKAGE:
			return {...state, loading: true}
		case GET_DEFAULT_SUBSCRIPTION_PACKAGE_SUCCESS:
			newState = {...state, loading: false};
			newState.defaultPackage = action.payload;
			return newState;
		case SET_SHOW_CREATE_NEW_SUBSCRIPTION_PACKAGE_SUCCESS_MODAL:
			return { ...state, showCreateNewSubscriptionPackageSuccessModal: action.payload };
		case SET_SHOW_CREATE_NEW_SUBSCRIPTION_SUCCESS_MODAL:
			return { ...state, showCreateNewSubscriptionSuccessModal: action.payload };
		case GET_SUBSCRIPTION_PACKAGES:
			return {...state, loading: true}
		case GET_SUBSCRIPTION_PACKAGES_SUCCESS:
			console.log('action.payload - ', action.payload);
			const pagination = {count: action.payload.count, page_num: action.payload.page_num, total_pages: action.payload.total_pages}
			const subscriptionPackages = action.payload.count ? action.payload.subscriptionPackages : action.payload;
			return {...state, loading: false, subscriptionPackages, pagination: action.payload.count ? pagination: null}
		case GET_INVOICES:
			return {...state, loading: true}
		case GET_INVOICES_SUCCESS:
			console.log('action.payload - ', action.payload);
			const list = action.payload.invoices.data.map(invoice => {
				const customer = action.payload.customers.find(c => c.customer_id === invoice.customer);
				return {
					practice_name: customer?.trading_business_name ?? 'Practice Name',
					date: invoice.created * 1000,
					amount: invoice.total / 100,
					currency: invoice.currency,
					number: invoice.number,
					paid: invoice.paid,
					payment_date: invoice.paid ? invoice.status_transitions.paid_at * 1000 : null,
					download: invoice.invoice_pdf,
					id: invoice.id
				}
			})
			return {
				...state,
				loading: false,
				invoices: action.payload.starting_after ? state.invoices.concat(list) : list,
				hasMoreInvoices: action.payload.invoices.has_more
			}
			case SEARCH_INVOICES:
				return {...state, loading: true}
			case SEARCH_INVOICES_SUCCESS:
				console.log('action.payload - ', action.payload);
				const _list = action.payload.invoices.data.map(invoice => {
					return {
						date: invoice.created * 1000,
						amount: invoice.total / 100,
						currency: invoice.currency,
						number: invoice.number,
						paid: invoice.paid,
						payment_date: invoice.paid ? invoice.status_transitions.paid_at * 1000 : null,
						download: invoice.invoice_pdf,
						id: invoice.id
					}
				})
				return {
					...state,
					loading: false,
					invoices: action.payload.starting_after ? state.invoices.concat(_list) : _list,
					hasMoreInvoices: action.payload.invoices.has_more
				}
		case GET_SUBSCRIPTION_PACKAGE:
			return {...state, loading: true}
		case GET_SUBSCRIPTION_PACKAGE_SUCCESS:
			newState = {...state, loading: false, ...action.payload};
			newState.subscriptionPackageId = action.payload._id;
			newState.discount = action.payload.offers.discount.value;
			newState.discount_enabled = action.payload.offers.discount.enabled;
			newState.discount_price_enabled = action.payload.offers.discounted_price?.enabled;
			newState.discounted_price = action.payload.offers.discounted_price?.value;
			newState.value = action.payload.offers.value;
			newState.months_free = action.payload.offers.months_free.value;
			newState.months_free_enabled = action.payload.offers.months_free.enabled;
			newState.got_months = action.payload.offers.month_discount.value.got_months;
			newState.month_discount_enabled = action.payload.offers.month_discount.enabled;
			newState.in_price_of_month = action.payload.offers.month_discount.value.in_price_of_month;
			return newState;
		case SET_SHOW_CREATE_NEW_TEMPLATE_SUCCESS_MODAL:
			return { ...state, showCreateNewTemplateSuccessModal: action.payload }
		case CREATE_SUBSCRIPTION_PACKAGE_FAIL:
			return (newState = {
					...state,
					loading: false,
					showCreateNewSubscriptionPackageFailedModal: true,
			});
		case SET_SHOW_CREATE_NEW_TEMPLATE_FAILED_MODAL:
            return { ...state, showCreateNewTemplateFailedModal: action.payload };
		case RESET_CREATE_NEW_TEMPLATE_FORM:
			return initialState
		default:
			return state;
	}
}

export default template;
