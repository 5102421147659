import { combineReducers } from "redux";

// Front
import Layout from "./layout/reducer";

// Authentication
import Login from "./auth/login/reducer";
import Account from "./auth/register/reducer";
import ActivateAccount from "./auth/activate/reducer";
import ForgetPassword from "./auth/forgetpwd/reducer";

// Role Management
import RoleManagement from "./roleManagement/reducer";
import Organisation from "./organisation/reducer";
import Service from "./service/reducer";
import Package from "./package/reducer";
import Client from "./client/reducer";
import Quote from "./quote/reducer";
import Contract from "./contract/reducer";
import Template from "./template/reducer";
import TextTemplate from "./textTemplate/reducer";
import TermsAndConditionTemplate from "./termsAndConditionTemplate/reducer";
import User from "./user/reducer";
import Dashboard from "./dashboard/reducer";
import Delete from "./delete/reducer";
import ActiveDeActive from "./activeDeActive/reducer";
import Common from "./common/reducer";
import Setting from "./setting/reducer";
import AccessKey from "./accessKey/reducer";
import AuditTrail from "./auditTrail/reducer";
import Notification from "./notification/reducer";
import Subscription from "./subscription/reducer";
import SubscriptionPackage from "./subscriptionPackage/reducer";
import EmailTemplate from './emailTemplate/reducer';

const rootReducer = combineReducers({
  // public
  Layout,
  Login,
  Account,
  ActiveDeActive,
  ActivateAccount,
  ForgetPassword,
  RoleManagement,
  Organisation,
  Service,
  Package,
  Client,
  Quote,
  Contract,
  Template,
  TextTemplate,
  TermsAndConditionTemplate,
  User,
  Dashboard,
  Delete,
  Common,
  Setting,
  AccessKey,
  AuditTrail,
  Notification,
  Subscription,
  SubscriptionPackage,
  EmailTemplate
});

export default rootReducer;
