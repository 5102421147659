import {
	SET_CLIENT_LOADING,
	ON_CLIENT_INPUT_CHANGE,
	SET_SHOW_CREATE_CLIENT_MODAL,
	SUBMIT_CLIENT,
	SUBMIT_CLIENT_SUCCESS,
	GET_CLIENTS,
	GET_CLIENTS_SUCCESS,
	SET_SHOW_CREATE_NEW_CLIENT_SUCCESS_MODAL,
	RESET_CREATE_NEW_CLIENT_FORM,
	GET_CLIENT,
	GET_CLIENT_SUCCESS,
	ADD_CLIENT_PARTNER,
	DELETE_CLIENT_PARTNER,
	ADD_CLIENT_OFFICER,
	DELETE_CLIENT_OFFICER,
	SEARCH_CLIENT_SUCCESS
} from "./actionTypes";

const initialState = {
	loading: false,
	showCreateClientModal: false,
	showCreateNewClientSuccessModal: false,
	createClientModalType: "create",
	clients: [],
	pagination: {},
	searchedClients: []
	// partnerCodes: [],
	// officerCodes: []
}

const client = (state = initialState, action) => {
	let newState;
	switch (action.type) {
		case SET_CLIENT_LOADING:
			return {...state, loading: action.payload}
		case ON_CLIENT_INPUT_CHANGE:
			return {...state, [action.payload.field]: action.payload.value}
		case SET_SHOW_CREATE_CLIENT_MODAL:
			return {...state, createClientModalType: action.payload.type, showCreateClientModal: action.payload.isVisible}
		case SUBMIT_CLIENT:
			return {...state, loading: true}
		case SUBMIT_CLIENT_SUCCESS:
			return {...state, loading: false, showCreateNewClientSuccessModal: true, clients: action.payload.clients, pagination: {count: action.payload.count, page_num: action.payload.page_num, total_pages: action.payload.total_pages}}
		case SET_SHOW_CREATE_NEW_CLIENT_SUCCESS_MODAL:
			return {...state, showCreateNewClientSuccessModal: false}
		case GET_CLIENTS:
			return {...state, loading: true}
		case GET_CLIENTS_SUCCESS:
			console.log('action.payload.clients - ', action.payload.clients);
			return { ...state, loading: false, clients: action.payload.clients, pagination: {count: action.payload.count, page_num: action.payload.page_num, total_pages: action.payload.total_pages} }
		case RESET_CREATE_NEW_CLIENT_FORM:
			newState = initialState;
			newState.clients = state.clients;
			return newState;
		case GET_CLIENT:
			return {...state, loading: true}
		case GET_CLIENT_SUCCESS:
			newState = {...state, loading: false, clientId: action.payload.id, clientBusinessType: action.payload.businessType}
			// newState.clientId = action.payload._id;
			// newState.clientBusinessType = action.payload.business_type;
			// newState.primaryContactSalutation = action.payload.primary_contact.salutation;
			// newState.primaryContactFirstName = action.payload.primary_contact.first_name;
			// newState.primaryContactLastName = action.payload.primary_contact.last_name;
			// newState.primaryContactLastName = action.payload.primary_contact.last_name;
			// newState.companyName = action.payload.company_name;
			// newState.clientEmail = action.payload.email;
			// newState.clientPhone = action.payload.phone;
			// newState.clientWebsite = action.payload.website;
			// newState.clientPremises = action.payload.address.premises;
			// newState.clientAddressLine1 = action.payload.address.address_line_1;
			// newState.clientAddressLine2 = action.payload.address.address_line_2;
			// newState.clientLocality = action.payload.address.locality;
			// newState.clientRegion = action.payload.address.region;
			// newState.clientCountry = action.payload.address.country;
			// newState.clientPostcode = action.payload.address.postcode;
			return newState
		case SEARCH_CLIENT_SUCCESS:
			return {...state, searchedClients: action.payload}
		default:
			return state;
	}
}

export default client;
