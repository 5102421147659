import { takeEvery, fork, put, all, call } from 'redux-saga/effects';
import axios from 'axios';

import {
    GET_TEXT_TEMPLATES,
    GET_TEXT_TEMPLATES_SUCCESS,
    GET_TEXT_TEMPLATE,
    GET_TEXT_TEMPLATE_SUCCESS,
    SUBMIT_TEXT_TEMPLATE
} from "./actionTypes";

import {
    getTextTemplatesSuccess, getTextTemplateSuccess, submitTextTemplateSuccess
} from './actions';
import { setShowApiErrorModal } from '../common/actions';

// AUTH related methods
import { getFirebaseBackend } from '../../helpers/authUtils';

const fireBaseBackend = getFirebaseBackend();

function* getTextTemplates() {
    try {
        const accessToken = yield fireBaseBackend.getAccessToken();
        const response = yield axios({
            method: "GET",
            url: `text-templates`,
            headers: {authorization: `Bearer ${accessToken}`}
        });
        if (response.data.success) {
            yield put(getTextTemplatesSuccess(response.data.result));
        } else {
            // yield put(createOrganisationFailed());
        }
    } catch (error) {
        yield put(setShowApiErrorModal());
        console.log(error);
        // if (error.response && error.response.data && error.response.data.error) {
        //     yield put(loginError(error.response.data.error));
        // } else {
        //     yield put(loginError("Something went Wrong!"));
        // }
        // yield put(createOrganisationFailed());
    }
}

function* getTextTemplate({payload: id}) {
    try {
        const accessToken = yield fireBaseBackend.getAccessToken();
        const response = yield axios({
            method: "GET",
            url: `text-template/${id}`,
            headers: {authorization: `Bearer ${accessToken}`}
        });
        if (response.data.success) {
            yield put(getTextTemplateSuccess(response.data.result));
        } else {
            // yield put(createOrganisationFailed());
        }
    } catch (error) {
        yield put(setShowApiErrorModal());
        // if (error.response && error.response.data && error.response.data.error) {
        //     yield put(loginError(error.response.data.error));
        // } else {
        //     yield put(loginError("Something went Wrong!"));
        // }
        // yield put(createOrganisationFailed());
    }
}

function* submitTextTemplate({payload: {data, type}}) {
    try {
        const accessToken = yield fireBaseBackend.getAccessToken();
        let url;
        switch(type) {
            case "new":
                url = "create-new-text-template";
                break;
            case "edit":
                url = "update-text-template";
                break;
            default:
                break;
        }
        const response = yield axios({
            method: "POST",
            url,
            headers: {authorization: `Bearer ${accessToken}`},
            data
        });
        if (response.data.success) {
            yield put(submitTextTemplateSuccess());
        } else {
            // yield put(createOrganisationFailed());
        }
    } catch (error) {
        yield put(setShowApiErrorModal());
        // if (error.response && error.response.data && error.response.data.error) {
        //     yield put(loginError(error.response.data.error));
        // } else {
        //     yield put(loginError("Something went Wrong!"));
        // }
        // yield put(createOrganisationFailed());
    }
}

export function* watchGetTextTemplates() {
    yield takeEvery(GET_TEXT_TEMPLATES, getTextTemplates);
}

export function* watchGetTextTemplate() {
    yield takeEvery(GET_TEXT_TEMPLATE, getTextTemplate);
}

export function* watchSubmitTextTemplate() {
    yield takeEvery(SUBMIT_TEXT_TEMPLATE, submitTextTemplate);
}

function* textTemplateSaga() {
    yield all([
        fork(watchGetTextTemplates),
        fork(watchGetTextTemplate),
        fork(watchSubmitTextTemplate),
    ]);
}

export default textTemplateSaga;

