import React from "react";
import Paginator from "../Paginator";
import { formatCurrency } from "../../Utils";

const SelectPackagesStep = props => {
	return (
		<div className="row">
			<div className="col-12">
				<table className="table table-hover a-la-carte-services-review-table mb-0">
					<thead>
						<tr>
							<td>Packages</td>
							<td>Recurring Fees (£)</td>
							<td>One-Off Fees (£)</td>
							{/*<td></td>*/}
						</tr>
					</thead>
					<tbody>
						{props.packages.map((item, index) => {
							if (item.is_active){
								let isItemChecked = props.packagedPricingSelectedPackages.includes(item._id);
								return (
									<tr className="packaged-pricing-list-item" key={item._id}>
										<td onClick={() => {isItemChecked?props.removePackagedPricingPackage(item._id):props.addPackagedPricingPackage(item._id)}} className="packaged-pricing-name"><i className={`ion ${isItemChecked ? "ion-md-checkbox" : "ion-md-square-outline"}`}></i>{item.name.substring(0,30)}</td>
										<td>{(item.recurring_default_price && item.recurring_original_price) && <span className="packaged-pricing-original-price">{formatCurrency(item.recurring_original_price)}</span>}<span className="packaged-pricing-default-price">{formatCurrency(item.recurring_default_price ? item.recurring_default_price : 0)}</span></td>
										<td>{(item.one_off_default_price && item.one_off_original_price) && <span className="packaged-pricing-original-price">{formatCurrency(item.one_off_original_price)}</span>}<span className="packaged-pricing-default-price">{formatCurrency(item.one_off_default_price ? item.one_off_default_price : 0)}</span></td>
										{/*<td><i className="ion ion-md-eye"></i></td>*/}
									</tr>
								);
							}
						})}
					</tbody>
				</table>
			{/*	<Paginator
					totalPages={props.pagination.total_pages}
					pageNum={props.pagination.page_num}
					getObjects={props.getPackages}
					/> */}
			</div>
		</div>
	);
}

export default SelectPackagesStep;
