import React, { Component } from "react";
import { Switch, BrowserRouter as Router } from "react-router-dom";
import { connect } from "react-redux";
import { v4 as uuidv4 } from "uuid";
// Import Routes
import { authProtectedRoutes, publicRoutes } from "./routes/";
import AppRoute from "./routes/route";

//timeout
import IdleTimerContainer from './pages/IdleTimerContainer';

// layouts
import VerticalLayout from "./components/VerticalLayout/";
import HorizontalLayout from "./components/HorizontalLayout/";
import NonAuthLayout from "./components/NonAuthLayout";
import ApiErrorModal from "./components/ApiErrorModal";

// Import scss
import "./assets/scss/theme.scss";
import "./assets/scss/custom.scss";

// Import Firebase Configuration file
import { initFirebaseBackend, getFirebaseBackend } from "./helpers/authUtils";
import {getDefaultSubscriptionPackage} from "./store/subscriptionPackage/actions";
import UpgradeModal from "./components/UpgradeModal";
import {getMyOrganisation} from "./store/organisation/actions";
import firebase from "firebase/app";
import { Route } from "react-router-dom";
import signIn from "./pages/LandingPage/sign-in";

// var firebaseConfig = {
//   apiKey: "AIzaSyCJEsYoDJ1r1EgvcbHlegsech9pgvTOp7g",
//   authDomain: "mail-prints.firebaseapp.com",
//   databaseURL: "https://mail-prints.firebaseio.com",
//   projectId: "mail-prints",
//   storageBucket: "mail-prints.appspot.com",
//   messagingSenderId: "805059677847",
//   appId: "1:805059677847:web:8a4ec12a70f071fdaaa3d2",
//   measurementId: "G-C4BGL7P5LD"
// };

// new firebase configuration - 29-11-2023
const firebaseConfig = {
  apiKey: "AIzaSyAt34OiAU3LpGCmTu-NLUmoLDx5QLE23Uk",
  authDomain: "proposal-outbooks.firebaseapp.com",
  databaseURL: "https://proposal-outbooks-489b9.firebaseio.com",
  projectId: "proposal-outbooks-489b9",
  storageBucket: "proposal-outbooks-489b9.appspot.com",
  messagingSenderId: "150959709651",
  appId: "1:150959709651:web:439b4b5b10e7ca8d77e326",
  measurementId: "G-QNQJWM5C4H"
};

// init firebase backend
initFirebaseBackend(firebaseConfig);

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isTimeout: false,
      loaded: false,
    };
    this.getLayout = this.getLayout.bind(this);
  }

  updateExpiredTime() {
    if (this.timeoutTracker) {
      clearTimeout(this.timeoutTracker);
    }
    this.timeoutTracker = setTimeout(() => {
      localStorage.setItem("_expiredTime", Date.now() + 30 * 1000);
    }, 300);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {

  }

  componentDidMount() {
    firebase.auth().onAuthStateChanged((user) => {
      console.log('user - ', user);
      this.setState({loaded: true}, () => {
        getFirebaseBackend().forceRefreshToken();
        setInterval(function(){
          // if (getFirebaseBackend().getAuthObject().currentUser) {
          //   getFirebaseBackend().getAuthObject().currentUser.getIdToken(true)
          //   .then(idToken => {
          //     let authUser = JSON.parse(window.localStorage.getItem("authUser"));
          //     authUser.stsTokenManager.accessToken = idToken;
          //     window.localStorage.setItem("authUser", JSON.stringify(authUser));
          //   });
          // }
          getFirebaseBackend().forceRefreshToken();
        }, 60000);
        const fireBaseBackend = getFirebaseBackend();
        const user = fireBaseBackend.getAuthenticatedUser();
        if(user) {
          console.log('this.props -0 ', this.props);
          this.props.getDefaultSubscriptionPackage();
          // this.props.getMyOrganisation();
        }

        window.addEventListener("mousemove", this.updateExpiredTime);
        window.addEventListener("scroll", this.updateExpiredTime);
        window.addEventListener("keydown", this.updateExpiredTime);
      })
    })


    // this.timer = new IdleTimer({
    //   timeout: 10, //expire after 10 seconds
    //   onTimeout: () => {
    //     this.setState({isTimeout: true});
    //   },
    //   onExpired: () => {
    //     // do something if expired on load
    //     this.setState({isTimeout: true});
    //   }
    // });
  }

  componentWillUnmount() {
    localStorage.removeItem("_expiredTime");
    window.removeEventListener("mousemove", this.updateExpiredTime);
    window.removeEventListener("scroll", this.updateExpiredTime);
    window.removeEventListener("keydown", this.updateExpiredTime);
  }

  /**
   * Returns the layout
   */
  getLayout = () => {
    let layoutCls = VerticalLayout;

    switch (this.props.layout.layoutType) {
      case "horizontal":
        layoutCls = HorizontalLayout;
        break;
      default:
        layoutCls = VerticalLayout;
        break;
    }
    return layoutCls;
  };

  handleRedirectClick = async (event) => {
    event.preventDefault(); // Prevent default link behavior

    // Retrieve the email from local storage
    const authUser = JSON.parse(localStorage.getItem('authUser'));
    const email = authUser ? authUser.uid : null;

    if (!email) {
      console.error('No email found in local storage');
      return;
    }
    const currentDate = new Date();
    const formattedTime = currentDate.toISOString(); // ISO 8601 format

    const authorizationToken = uuidv4();
    // Prepare data to send to the backend
    const data = {
      email: email, // Replace with actual data
      time: formattedTime, // Example data
      authorizationToken: authorizationToken
    };

    try {
      // Call the backend API
      const response = await fetch("https://proposal.outbooks.com/api/save-migrated-user-data", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(data)
      });

      const result = await response.json();

      if (response.ok) {
        // On success, redirect to the new URL with query parameters
        const newUrl = `https://app.proposal.outbooks.com/dashboard?EmailId=${email}&AuthorizationToken=${authorizationToken}`;
        window.location.href = newUrl;
      } else {
        // Handle error response
        console.error("Error:", result.response.error);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  }

  render() {
    const Layout = this.getLayout();
const shouldShowMessage = window.location.pathname !== "/" && window.location.pathname !== "/login" && window.location.pathname !== "/logout" && window.location.pathname!=="/signIn" && window.location.pathname !== "";
    this.state.isTimeout ? console.log('Timeout') : console.log('Active');

    return (
      this.state.loaded && <div onMouseMoveCapture={()=>{
        localStorage.setItem('movesmove',true)
      }}>
        <Router>

        { shouldShowMessage && (<div style={{ position: 'fixed', top: 0, width: '50%', backgroundColor: 'white', color: 'red', zIndex: 9999, padding: '10px', textAlign: 'center', left: '25%', right: '25%', borderBottomLeftRadius: '25px', borderBottomRightRadius: '25px', borderTopLeftRadius: '0', borderTopRightRadius: '0', fontSize:'large' }}>
  <span>We have migrated to a NEW platform. Please click <a onClick={this.handleRedirectClick} style={{ color: 'blue', textDecoration: 'underline', cursor: 'pointer' }}>here</a> to redirect to the new platform.</span>
</div>)}


          <Switch>
  <Route exact path="/" component={signIn} />
            {publicRoutes.map((route, idx) => (
              <AppRoute
                path={route.path}
                layout={NonAuthLayout}
                component={route.component}
                key={idx}
                isAuthProtected={false}
              />
            ))}

            {authProtectedRoutes.map((route, idx) => (
              <AppRoute
                path={route.path}
                layout={route.layout?route.layout:Layout}
                component={route.component}
                key={idx}
                isAuthProtected={true}
                roles={route.roles}
                subscriptionPackage={this.props.subscriptionPackage}
              />
            ))}

          </Switch>
        </Router>
        <ApiErrorModal />
        {window.location.hostname !== 'localhost' && <IdleTimerContainer/>}
        <UpgradeModal />
      </div>
    );
  }
}

const mapStateToProps = state => {
  const {subscriptionPackage} = state.Organisation;
  return {
    layout: state.Layout,
    subscriptionPackage
  };
};

export default connect(mapStateToProps, {getDefaultSubscriptionPackage, getMyOrganisation})(App);
