import {
  SET_TEMPLATE_LOADING,
  ON_TEMPLATE_INPUT_CHANGE,
  ADD_ELEMENT_BLOCK,
  MOVE_ELEMENT,
  DELETE_ELEMENT,
  GET_TEMPLATES,
  GET_TEMPLATES_SUCCESS,
  GET_TEMPLATE,
  GET_TEMPLATE_SUCCESS,
  SUBMIT_TEMPLATE,
  SUBMIT_TEMPLATE_SUCCESS,
  RESET_CREATE_NEW_TEMPLATE_FORM,
  SET_SHOW_CREATE_NEW_TEMPLATE_SUCCESS_MODAL,
  ADD_SIGNATORY,
  CREATE_NEW_TEMPLATE_FAIL,
  SET_SHOW_CREATE_NEW_TEMPLATE_FAILED_MODAL,
  GET_TEMPLATES_CLIENT,
  GET_TEMPLATES_CLIENTS_SUCCESS,
} from "./actionTypes";

export const setTemplateLoading = (isLoading) => {
  return {
    type: SET_TEMPLATE_LOADING,
    payload: isLoading,
  };
};

export const onInputChange = (field, value) => {
  return {
    type: ON_TEMPLATE_INPUT_CHANGE,
    payload: {
      field,
      value,
    },
  };
};

export const addElementBlock = (code) => {
  return {
    type: ADD_ELEMENT_BLOCK,
    payload: code,
  };
};

export const moveElement = (dragIndex, hoverIndex) => {
  return {
    type: MOVE_ELEMENT,
    payload: {
      dragIndex,
      hoverIndex,
    },
  };
};

export const deleteElement = (code) => {
  return {
    type: DELETE_ELEMENT,
    payload: code,
  };
};

export const getTemplates = (query) => {
  return {
    type: GET_TEMPLATES,
    payload: query,
  };
};

export const getTemplatesSuccess = (data) => {
  return {
    type: GET_TEMPLATES_SUCCESS,
    payload: data,
  };
};

export const getTemplate = (id) => {
  return {
    type: GET_TEMPLATE,
    payload: id,
  };
};

export const getTemplateSuccess = (data) => {
  return {
    type: GET_TEMPLATE_SUCCESS,
    payload: data,
  };
};

export const submitTemplate = (data, type = "new") => {
  console.log(data, type);
  return {
    type: SUBMIT_TEMPLATE,
    payload: {
      data,
      type,
    },
  };
};

export const submitTemplateSuccess = () => {
  return {
    type: SUBMIT_TEMPLATE_SUCCESS,
  };
};

export const resetCreateNewTemplateForm = () => {
  return {
    type: RESET_CREATE_NEW_TEMPLATE_FORM,
  };
};

export const setShowCreateNewTemplateSuccessModal = (isVisible) => {
  return {
    type: SET_SHOW_CREATE_NEW_TEMPLATE_SUCCESS_MODAL,
    payload: isVisible,
  };
};

export const createTemplateFailed = (data) => {
  return {
    type: CREATE_NEW_TEMPLATE_FAIL,
    payload: data,
  };
};

export const setShowCreateNewTemplateFailedModal = (isVisible) => {
  return {
    type: SET_SHOW_CREATE_NEW_TEMPLATE_FAILED_MODAL,
    payload: isVisible,
  };
};

export const getTemplatesClient = (query) => {
  return {
    type: GET_TEMPLATES_CLIENT,
    payload: query,
  };
};

export const getTemplatesClientSuccess = (data) => {
  return {
    type: GET_TEMPLATES_CLIENTS_SUCCESS,
    payload: data,
  };
};
