import React, { Component } from "react";
import { Modal, ModalBody } from "reactstrap";
import SimpleReactValidator from "simple-react-validator";
import Select from "react-select";
import SweetAlert from "react-bootstrap-sweetalert";
import { connect } from "react-redux";
import {
  onInputChange,
  inviteUser,
  setShowInviteUserModal,
  setShowInviteNewUserSuccessModal,
  resetInviteUserForm,
} from "../../store/user/actions";
import { userRoles } from "../../config";

class InviteUserModal extends Component {
  constructor(props) {
    super(props);
    this.validator = new SimpleReactValidator();
    this.cancel = this.cancel.bind(this);
    this.toggle = this.toggle.bind(this);
    this.formatAndSubmitData = this.formatAndSubmitData.bind(this);
  }
  cancel() {
    this.validator.hideMessages();
    this.forceUpdate();
    this.props.resetInviteUserForm();
    this.props.setShowInviteUserModal(false);
  }
  toggle() {
    this.validator.hideMessages();
    this.forceUpdate();
    this.props.resetInviteUserForm();
    this.props.setShowInviteUserModal(!this.props.showInviteUserModal);
  }
  formatAndSubmitData() {
    if (this.validator.allValid()) {
      let data = {
        firstName: this.props.inviteNewUserFirstName,
        lastName: this.props.inviteNewUserLastName,
        email: this.props.inviteNewUserEmail,
        role: this.props.inviteNewUserRole,
      };
      this.props.inviteUser(data);
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  }
  render() {
    return (
      <React.Fragment>
        <Modal
          isOpen={this.props.showInviteUserModal}
          toggle={this.toggle}
          size="lg"
        >
          <ModalBody>
            <h4>Invite User</h4>
            <div className="separator mb-2" />
            <div className="p-3">
              <div className="row fieldset">
                <div className="col-2 text-right">
                  <label className="fieldset-label required">First Name</label>
                </div>
                <div className="col-4">
                  <input
                    value={this.props.inviteNewUserFirstName || ""}
                    onChange={(e) =>
                      this.props.onInputChange(
                        "inviteNewUserFirstName",
                        e.target.value
                      )
                    }
                    className="input-text"
                    type="text"
                    placeholder="First Name"
                  />
                  {this.validator.message(
                    "First Name",
                    this.props.inviteNewUserFirstName,
                    "required|max:50",
                    { className: "text-danger" }
                  )}
                </div>
                <div className="col-2 text-right">
                  <label className="fieldset-label required">Last Name</label>
                </div>
                <div className="col-4">
                  <input
                    value={this.props.inviteNewUserLastName || ""}
                    onChange={(e) =>
                      this.props.onInputChange(
                        "inviteNewUserLastName",
                        e.target.value
                      )
                    }
                    className="input-text"
                    type="text"
                    placeholder="Last Name"
                  />
                  {this.validator.message(
                    "Last Name",
                    this.props.inviteNewUserLastName,
                    "required|max:50",
                    { className: "text-danger" }
                  )}
                </div>
              </div>
              <div className="row fieldset">
                <div className="col-2 text-right">
                  <label className="fieldset-label required">Email</label>
                </div>
                <div className="col-10">
                  <input
                    value={this.props.inviteNewUserEmail || ""}
                    onChange={(e) =>
                      this.props.onInputChange(
                        "inviteNewUserEmail",
                        e.target.value
                      )
                    }
                    className="input-text"
                    type="text"
                    placeholder="Email"
                  />
                  {this.validator.message(
                    "Email",
                    this.props.inviteNewUserEmail,
                    "required|email",
                    { className: "text-danger" }
                  )}
                </div>
              </div>
              <div className="row fieldset">
                <div className="col-2 text-right">
                  <label className="fieldset-label required">User Role</label>
                </div>
                <div className="col-10">
                  <Select
                    options={userRoles}
                    onChange={(selectedOption) =>
                      this.props.onInputChange(
                        "inviteNewUserRole",
                        selectedOption.value
                      )
                    }
                    value={
                      this.props.inviteNewUserRole
                        ? userRoles.filter(
                            (option) =>
                              option.value === this.props.inviteNewUserRole
                          )
                        : ""
                    }
                  />
                  {this.validator.message(
                    "User Role",
                    this.props.inviteNewUserRole,
                    "required",
                    { className: "text-danger" }
                  )}
                </div>
              </div>
            </div>
            <div className="separator mt-3 mb-3" />
            <div className="row fieldset">
              <div className="col-12 text-right">
                <button onClick={this.cancel} className="btn">
                  Cancel
                </button>
                <button
                  onClick={this.formatAndSubmitData}
                  className="btn create-item ml-2"
                >
                  Invite User
                </button>
              </div>
            </div>
          </ModalBody>
        </Modal>
        {this.props.showInviteNewUserSuccessModal && (
          <SweetAlert
            title=""
            success
            confirmBtnBsStyle="success"
            onConfirm={() => {
              this.props.setShowInviteNewUserSuccessModal(false);
              this.props.setShowInviteUserModal(false);
            }}
          >
            <div className="mb-2 success-alert-message">
              An invitation has been sent to{" "}
              <b>
                {this.props.inviteNewUserFirstName}{" "}
                {this.props.inviteNewUserLastName}
              </b>{" "}
              on email id <b>{this.props.inviteNewUserEmail}</b>!
            </div>
          </SweetAlert>
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => state.User;

export default connect(mapStateToProps, {
  onInputChange,
  inviteUser,
  setShowInviteUserModal,
  setShowInviteNewUserSuccessModal,
  resetInviteUserForm,
})(InviteUserModal);
