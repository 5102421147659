import React, { Component } from "react";
import { connect } from "react-redux";
import {
  getNotifications,
  loadMoreNotifications,
} from "../store/notification/actions";
import Loader from "../components/Loader";
import NotificationsTable from "../components/NotificationsTable";

class Notifications extends Component {
  componentDidMount() {
    this.props.getNotifications();
  }
  render() {
    return (
      <React.Fragment>
        <div className="page-info-header">
          <div className="container-fluid">
            <div className="page-display-title">Notifications</div>
          </div>
        </div>
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="card" style={{marginTop: '80px'}}>
                <div className="card-body">
                  <NotificationsTable
                    notifications={this.props.notifications}
                  />
                  {this.props.totalCount > this.props.notifications.length ? (
                    <div className="text-center">
                      <button
                        onClick={() =>
                          this.props.loadMoreNotifications(
                            this.props.notifications[
                              this.props.notifications.length - 1
                            ]._id
                          )
                        }
                        className="btn btn-primary"
                      >
                        Load More
                      </button>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
        {this.props.loading && <Loader />}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  const { notifications, loading, totalCount } = state.Notification;
  return { notifications, loading };
};

export default connect(mapStateToProps, {
  getNotifications,
  loadMoreNotifications,
})(Notifications);
