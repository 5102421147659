import React from "react";
import { withRouter } from "react-router-dom";
import { getFirebaseBackend } from "../helpers/authUtils";

let userRole;

function renderEmailTemplateTemplates(item, index) {
  return (
    <tr key={index}>
      <td>{item.name.substring(0, 70)}</td>
      <td>{item.email_template_type}</td>
      {/*<td>{item.description}</td>*/}
      <td className="serivce-actions text-right">
        <button
          onClick={() => this.history.push(`/email-template/edit/${item._id}`)}
          data-id={item._id}
          data-index={index}
          data-toggle="tooltip"
          title="Edit Email Template"
          className="btn btn-sm btn-dark"
        >
          <i className="ion ion-md-create" />
        </button>
        <button
          onClick={() => this.delete(true, item._id, "email-template")}
          data-toggle="tooltip"
          title="Delete Email Template" disabled
          className="btn btn-sm btn-dark ml-2"
        >
          <i className="ion ion-md-trash" />
        </button>
      </td>
    </tr>
  );
}

const EmailTemplateListTable = (props) => {
  userRole = getFirebaseBackend().getUserRole();
  return props.emailTemplates.length > 0 ? (
    <div className="table-rep-plugin">
      <div className="table-responsive mb-0" data-pattern="priority-columns">
        <table className="table table-striped">
          <thead>
            <tr>
              <th>Name</th>
              {/* <th>Description</th> */}
              <th>Template Type</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {props.emailTemplates.map(renderEmailTemplateTemplates.bind(props))}
          </tbody>
        </table>
      </div>
    </div>
  ) : (
    <div className="text-center">No Templates Found!</div>
  );
};

export default withRouter(EmailTemplateListTable);
