import React from "react";
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap';

const pageNumbers = (getObjects, activePage, status) => (number, index) => {
	return (
		<PaginationItem active={activePage===number} key={number}>
			<PaginationLink onClick={() => getObjects(index, status)}>
				{number}
			</PaginationLink>
		</PaginationItem>
	)
}

const Paginator = props => {
	const pages = []
	for (let i = 1; i <= props.totalPages; i++) {
		pages.push(i)
	}
	return (
		<Pagination>
			<PaginationItem>
				{
					(props.pageNum - 2) >= 0 ?
						<PaginationLink previous onClick={() => props.getObjects(props.pageNum - 2, props.status)} /> :
						<PaginationLink previous />
				}
			</PaginationItem>
			{pages.map(pageNumbers(props.getObjects, props.pageNum, props.status))}
			<PaginationItem>
				{
					props.pageNum < props.totalPages ?
						<PaginationLink next onClick={() => props.getObjects(props.pageNum, props.status)} /> :
						<PaginationLink next />
				}
			</PaginationItem>
		</Pagination>
	)
}

export default Paginator;
