export const SET_EMAIL_TEMPLATE_LOADING = "SET_EMAIL_TEMPLATE_LOADING";
export const ON_EMAIL_TEMPLATE_INPUT_CHANGE = "ON_EMAIL_TEMPLATE_INPUT_CHANGE";
export const GET_EMAIL_TEMPLATES = "GET_EMAIL_TEMPLATES";
export const GET_EMAIL_TEMPLATES_SUCCESS = "GET_EMAIL_TEMPLATES_SUCCESS";
export const GET_EMAIL_TEMPLATE = "GET_EMAIL_TEMPLATE";
export const GET_EMAIL_TEMPLATE_SUCCESS = "GET_EMAIL_TEMPLATE_SUCCESS";
export const SUBMIT_EMAIL_TEMPLATE = "SUBMIT_EMAIL_TEMPLATE";
export const SUBMIT_EMAIL_TEMPLATE_SUCCESS = "SUBMIT_EMAIL_TEMPLATE_SUCCESS";
export const RESET_CREATE_NEW_EMAIL_TEMPLATE_FORM =
  "RESET_CREATE_NEW_EMAIL_TEMPLATE_FORM";
export const SET_SHOW_CREATE_NEW_EMAIL_TEMPLATE_SUCCESS_MODAL =
  "SET_SHOW_CREATE_NEW_EMAIL_TEMPLATE_SUCCESS_MODAL";
export const UPLOAD_EMAIL_TEMPLATE = "UPLOAD_EMAIL_TEMPLATE";
export const UPLOAD_EMAIL_TEMPLATE_SUCCESS = "UPLOAD_EMAIL_TEMPLATE_SUCCESS";
export const DELETE_EMAIL_TEMPLATE = "DELETE_EMAIL_TEMPLATE";
export const DELETE_EMAIL_TEMPLATE_SUCCESS = "DELETE_EMAIL_TEMPLATE_SUCCESS";
export const CREATE_NEW_EMAIL_TEMPLATES_FAIL =
  "CREATE_NEW_EMAIL_TEMPLATES_FAIL";
export const SET_SHOW_CREATE_NEW_EMAIL_TEMPLATES_FAILED_MODAL =
  "SET_SHOW_CREATE_NEW_EMAIL_TEMPLATES_FAILED_MODAL";
export const GENERATE_EMAIL_TEMPLATES = "GENERATE_EMAIL_TEMPLATES";