import { all } from 'redux-saga/effects';

//public
import AccountSaga from './auth/register/saga';
import ActivateAccountSaga from './auth/activate/saga';
import AuthSaga from './auth/login/saga';
import ForgetSaga from './auth/forgetpwd/saga';
import LayoutSaga from './layout/saga';


import RoleManagementSaga from './roleManagement/saga';
import OrganisationSaga from './organisation/saga';
import ServiceSaga from './service/saga';
import PackageSaga from './package/saga';
import ClientSaga from './client/saga';
import QuoteSaga from './quote/saga';
import ContractSaga from './contract/saga';
import TextTemplateSaga from './textTemplate/saga';
import TermsAndConditionTemplateSaga from './termsAndConditionTemplate/saga';
import TemplateSaga from './template/saga';
import UserSaga from './user/saga';
import DashboardSaga from './dashboard/saga';
import DeleteSaga from './delete/saga';
import ActiveDeActive from './activeDeActive/saga';
import CommonSaga from './common/saga';
import SettingSaga from './setting/saga';
import AccessKeySaga from './accessKey/saga';
import AuditTrailSaga from './auditTrail/saga';
import NotificationSaga from './notification/saga';
import SubscriptionSaga from './subscription/saga';
import SubscriptionPackageSaga from './subscriptionPackage/saga';
import EmailTemplateSaga from './emailTemplate/saga';

export default function* rootSaga() {
    yield all([
        //public
        AccountSaga(),
        ActivateAccountSaga(),
        AuthSaga(),
        ForgetSaga(),
        LayoutSaga(),
        RoleManagementSaga(),
        OrganisationSaga(),
        ServiceSaga(),
        PackageSaga(),
        ClientSaga(),
        QuoteSaga(),
        ContractSaga(),
        TextTemplateSaga(),
        TermsAndConditionTemplateSaga(),
        TemplateSaga(),
        UserSaga(),
        DashboardSaga(),
        DeleteSaga(),
        CommonSaga(),
        SettingSaga(),
        AccessKeySaga(),
        AuditTrailSaga(),
        NotificationSaga(),
        ActiveDeActive(),
        SubscriptionSaga(),
        SubscriptionPackageSaga(),
        EmailTemplateSaga()
    ])
}
