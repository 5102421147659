import { takeEvery, fork, put, all, call } from 'redux-saga/effects';
import axios from 'axios';

import {
    COMPANY_SEARCH,
    UPDATE_PAYMENT_METHOD,
    SELECT_COMPANY_SEARCH_ITEM,
    CREATE_ORGANISATION,
    GET_MY_ORGANISATION,
    UPDATE_MY_ORGANISATION,
    UPLOAD_LOGO,
    UPLOAD_SIGNATURE,
    GET_SUPER_ADMIN_ORGANISATIONS,
    GET_PRICING_SETTINGS,
    UPDATE_PRICING_SETTINGS,
    GET_EMAIL_CONFIGS,
    UPDATE_EMAIL_CONFIGS,
} from "./actionTypes";
import { loginSuccess, logoutUserSuccess, loginError } from '../auth/login/actions';

import {
    companySearchSuccess, companySearchFailed,
    selectCompanySearchItemSuccess, selectCompanySearchItemFailed,
    getMyOrganisationSuccess, updateMyOrganisationSuccess,
    uploadLogoSuccess, uploadSignatureSuccess,
    getSuperAdminOrganisationsSuccess, confirmPayment,
    updatePaymentMethodSuccess,
    getPricingSettingsSuccess,
    updatePricingSettingsSuccess,
    getEmailConfigsSuccess,
    updateEmailConfigsSuccess,
    // createOrganisationSuccess, createOrganisationFailed
} from './actions';
import { setShowApiErrorModal } from '../common/actions';

import { switchOrganisation } from '../auth/login/actions';

// AUTH related methods
import { getFirebaseBackend } from '../../helpers/authUtils';

const fireBaseBackend = getFirebaseBackend();

function* companySearch({payload: search}) {
    try {
    	const accessToken = yield fireBaseBackend.getAccessToken();
        const response = yield axios({
            method: "GET",
            url: `company-search?search=${search}`,
            headers: {authorization: `Bearer ${accessToken}`}
        });
        if (response.data.success) {
            yield put(companySearchSuccess(response.data.result.items));
        } else {
            yield put(companySearchFailed());
        }
    } catch (error) {
        yield put(setShowApiErrorModal());
        // if (error.response && error.response.data && error.response.data.error) {
        //     yield put(loginError(error.response.data.error));
        // } else {
        //     yield put(loginError("Something went Wrong!"));
        // }
        yield put(companySearchFailed());
    }
}

function* selectCompanySearchItem({payload: item}) {
    try {
    	const accessToken = yield fireBaseBackend.getAccessToken();
        const response = yield axios({
            method: "GET",
            url: `company-details/${item.companyNumber}`,
            headers: {authorization: `Bearer ${accessToken}`}
        });
        if (response.data.success) {
            yield put(selectCompanySearchItemSuccess(response.data.result));
        } else {
            yield put(selectCompanySearchItemFailed());
        }
    } catch (error) {
        yield put(setShowApiErrorModal(true, 'Company data not found. Please contact Companies House'));
        // if (error.response && error.response.data && error.response.data.error) {
        //     yield put(loginError(error.response.data.error));
        // } else {
        //     yield put(loginError("Something went Wrong!"));
        // }
        yield put(selectCompanySearchItemFailed());
    }
}

function* createOrganisation({payload: {data, history}}) {
    try {
    	const accessToken = yield fireBaseBackend.getAccessToken();
        const response = yield axios({
            method: "POST",
            url: `create-organisation`,
            headers: {authorization: `Bearer ${accessToken}`},
            data
        });
        if (response.data.success) {
            if('clientSecret' in response.data.result) {
                return yield put(confirmPayment(response.data.result, history));
            }
            if('organisationId' in response.data.result) {
        	      yield put(switchOrganisation(response.data.result.organisationId, history));
            }
            // yield put(createOrganisationSuccess(response.data.result));
        } else {
            // yield put(createOrganisationFailed());
        }
    } catch (error) {
        yield put(setShowApiErrorModal());
        // if (error.response && error.response.data && error.response.data.error) {
        //     yield put(loginError(error.response.data.error));
        // } else {
        //     yield put(loginError("Something went Wrong!"));
        // }
        // yield put(createOrganisationFailed());
    }
}

function* getMyOrganisation() {
    try {
        const accessToken = yield fireBaseBackend.getAccessToken();
        const response = yield axios({
            method: "GET",
            url: `organisation/me`,
            headers: {authorization: `Bearer ${accessToken}`}
        });
        if (response.data.success) {
            yield put(getMyOrganisationSuccess(response.data.result));
        } else {
            // yield put(selectCompanySearchItemFailed());
        }
    } catch (error) {
        yield put(setShowApiErrorModal());
        console.log(error);
        // if (error.response && error.response.data && error.response.data.error) {
        //     yield put(loginError(error.response.data.error));
        // } else {
        //     yield put(loginError("Something went Wrong!"));
        // }
        // yield put(selectCompanySearchItemFailed());
    }
}

function* updateMyOrganisation({payload: data}) {
    try {
        const accessToken = yield fireBaseBackend.getAccessToken();
        const response = yield axios({
            method: "POST",
            url: `update-organisation/me`,
            headers: {authorization: `Bearer ${accessToken}`},
            data
        });
        if (response.data.success) {
            yield put(updateMyOrganisationSuccess(response.data.result));
            // yield call(fireBaseBackend.logout);
            const firebaseToken = yield call(fireBaseBackend.signInWithCustomToken, response.data.result.accessToken);
            yield put(loginSuccess(firebaseToken));
            window.location.reload(true);
        } else {
            // yield put(selectCompanySearchItemFailed());
        }
    } catch (error) {
        yield put(setShowApiErrorModal());
        console.log(error);
        // if (error.response && error.response.data && error.response.data.error) {
        //     yield put(loginError(error.response.data.error));
        // } else {
        //     yield put(loginError("Something went Wrong!"));
        // }
        // yield put(selectCompanySearchItemFailed());
    }
}

function* updatePricingSettingsCall({ payload: data }) {
    try {
        const accessToken = yield fireBaseBackend.getAccessToken();
        const response = yield axios({
            method: "POST",
            url: `update-pricing-settings`,
            headers: { authorization: `Bearer ${accessToken}` },
            data,
        });
        if (response.data.success) {
            yield put(updatePricingSettingsSuccess(response.data.result));
        } else {
            // yield put(selectCompanySearchItemFailed());
        }
    } catch (error) {
        yield put(setShowApiErrorModal());
        console.log(error);
        // if (error.response && error.response.data && error.response.data.error) {
        //     yield put(loginError(error.response.data.error));
        // } else {
        //     yield put(loginError("Something went Wrong!"));
        // }
        // yield put(selectCompanySearchItemFailed());
    }
}

function* updateEmailConfigs({ payload: data }) {
    try {
        const accessToken = yield fireBaseBackend.getAccessToken();
        const response = yield axios({
            method: "POST",
            url: `update-email-configs`,
            headers: { authorization: `Bearer ${accessToken}` },
            data,
        });
        if (response.data.success) {
            yield put(updateEmailConfigsSuccess(response.data.result));
        } else {
            // yield put(selectCompanySearchItemFailed());
        }
    } catch (error) {
        yield put(setShowApiErrorModal());
        console.log(error);
        // if (error.response && error.response.data && error.response.data.error) {
        //     yield put(loginError(error.response.data.error));
        // } else {
        //     yield put(loginError("Something went Wrong!"));
        // }
        // yield put(selectCompanySearchItemFailed());
    }
}

function* uploadLogo({ payload: fileObject }) {
    try {
        const accessToken = yield fireBaseBackend.getAccessToken();
        const  data = new FormData()
        data.append('logo', fileObject);
        const response = yield axios({
            method: "POST",
            url: `upload-logo/me`,
            headers: {authorization: `Bearer ${accessToken}`},
            data
        });
        if (response.data.success) {
            yield put(uploadLogoSuccess(response.data.result));
        } else {
            // yield put(companySearchFailed());
        }
    } catch (error) {
        yield put(setShowApiErrorModal());
        console.log(error);
        // if (error.response && error.response.data && error.response.data.error) {
        //     yield put(loginError(error.response.data.error));
        // } else {
        //     yield put(loginError("Something went Wrong!"));
        // }
        // yield put(upli());
    }
}

function* uploadSignature({ payload: fileObject }) {
    try {
        const accessToken = yield fireBaseBackend.getAccessToken();
        const  data = new FormData()
        data.append('signature', fileObject);
        const response = yield axios({
            method: "POST",
            url: `upload-signature/me`,
            headers: {authorization: `Bearer ${accessToken}`},
            data
        });
        if (response.data.success) {
            yield put(uploadSignatureSuccess(response.data.result));
        } else {
            // yield put(companySearchFailed());
        }
    } catch (error) {
        yield put(setShowApiErrorModal());
        console.log(error);
        // if (error.response && error.response.data && error.response.data.error) {
        //     yield put(loginError(error.response.data.error));
        // } else {
        //     yield put(loginError("Something went Wrong!"));
        // }
        // yield put(upli());
    }
}


function* updatePaymentMethod({ payload: data }) {
    try {
        const accessToken = yield fireBaseBackend.getAccessToken();
        const response = yield axios({
            method: "POST",
            url: `update-payment-method`,
            headers: {authorization: `Bearer ${accessToken}`},
            data
        });
        if (response.data.success) {
            yield put(updatePaymentMethodSuccess(response.data.result));
        } else {
            // yield put(companySearchFailed());
        }
    } catch (error) {
        yield put(setShowApiErrorModal());
        console.log(error);
        // if (error.response && error.response.data && error.response.data.error) {
        //     yield put(loginError(error.response.data.error));
        // } else {
        //     yield put(loginError("Something went Wrong!"));
        // }
        // yield put(upli());
    }
}


function* getSuperAdminOrganisations({ payload }) {
    try {
        const {pageNum: page_num, text} = payload;
        const accessToken = yield fireBaseBackend.getAccessToken();
        const response = yield axios({
            method: "GET",
            url: `all-organisations?page_num=${page_num}&text=${text}`,
            headers: {authorization: `Bearer ${accessToken}`}
        });
        if (response.data.success) {
            yield put(getSuperAdminOrganisationsSuccess(response.data.result));
        } else {
            // yield put(createOrganisationFailed());
        }
    } catch (error) {
        yield put(setShowApiErrorModal());
        console.log(error);
        // if (error.response && error.response.data && error.response.data.error) {
        //     yield put(loginError(error.response.data.error));
        // } else {
        //     yield put(loginError("Something went Wrong!"));
        // }
        // yield put(createOrganisationFailed());
    }
}

function* getPricingSettings() {
    try {
        const accessToken = yield fireBaseBackend.getAccessToken();
        const response = yield axios({
            method: "GET",
            url: `organisation/pricing-settings`,
            headers: { authorization: `Bearer ${accessToken}` },
        });
        if (response.data.success) {
            yield put(getPricingSettingsSuccess(response.data.result));
        } else {
            // yield put(selectCompanySearchItemFailed());
        }
    } catch (error) {
        yield put(setShowApiErrorModal());
        console.log(error);
        // if (error.response && error.response.data && error.response.data.error) {
        //     yield put(loginError(error.response.data.error));
        // } else {
        //     yield put(loginError("Something went Wrong!"));
        // }
        // yield put(selectCompanySearchItemFailed());
    }
}

function* getEmailConfigs() {
    try {
        const accessToken = yield fireBaseBackend.getAccessToken();
        const response = yield axios({
            method: "GET",
            url: `organisation/email-configs`,
            headers: { authorization: `Bearer ${accessToken}` },
        });
        if (response.data.success) {
            yield put(getEmailConfigsSuccess(response.data.result));
        } else {
            // yield put(selectCompanySearchItemFailed());
        }
    } catch (error) {
        yield put(setShowApiErrorModal());
        console.log(error);
        // if (error.response && error.response.data && error.response.data.error) {
        //     yield put(loginError(error.response.data.error));
        // } else {
        //     yield put(loginError("Something went Wrong!"));
        // }
        // yield put(selectCompanySearchItemFailed());
    }
}

export function* watchCompanySearch() {
    yield takeEvery(COMPANY_SEARCH, companySearch);
}

export function* watchSelectCompanySearchItem() {
    yield takeEvery(SELECT_COMPANY_SEARCH_ITEM, selectCompanySearchItem);
}

export function* watchCreateOrganisation() {
    yield takeEvery(CREATE_ORGANISATION, createOrganisation);
}

export function* watchGetMyOrganisation() {
    yield takeEvery(GET_MY_ORGANISATION, getMyOrganisation);
}

export function* watchUpdateMyOrganisation() {
    yield takeEvery(UPDATE_MY_ORGANISATION, updateMyOrganisation);
}

export function* watchUpdatePricingSettings() {
    yield takeEvery(UPDATE_PRICING_SETTINGS, updatePricingSettingsCall);
}

export function* watchUploadLogo() {
    yield takeEvery(UPLOAD_LOGO, uploadLogo);
}

export function* watchUploadSignature() {
    yield takeEvery(UPLOAD_SIGNATURE, uploadSignature);
}

export function* watchGetSuperAdminOrganisations() {
    yield takeEvery(GET_SUPER_ADMIN_ORGANISATIONS, getSuperAdminOrganisations);
}

export function* watchUpdatePaymentMethod() {
    yield takeEvery(UPDATE_PAYMENT_METHOD, updatePaymentMethod);
}

export function* watchGetPricingSettings() {
    yield takeEvery(GET_PRICING_SETTINGS, getPricingSettings);
}

export function* watchGetEmailConfigs() {
    yield takeEvery(GET_EMAIL_CONFIGS, getEmailConfigs);
}

export function* watchUpdateEmailConfigs() {
    yield takeEvery(UPDATE_EMAIL_CONFIGS, updateEmailConfigs);
}

function* organisationSaga() {
    yield all([
        fork(watchCompanySearch),
        fork(watchSelectCompanySearchItem),
        fork(watchCreateOrganisation),
        fork(watchGetMyOrganisation),
        fork(watchUpdateMyOrganisation),
        fork(watchUploadLogo),
        fork(watchUploadSignature),
        fork(watchGetSuperAdminOrganisations),
        fork(watchUpdatePaymentMethod),
        fork(watchGetPricingSettings),
        fork(watchUpdatePricingSettings),
        fork(watchGetEmailConfigs),
        fork(watchUpdateEmailConfigs),
    ]);
}

export default organisationSaga;
