import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { formatDate, formatAddress, utcDateToCurrentDate } from "../Utils";
import Loader from "../components/Loader";
import InputPhone from '../components/InputPhone';
import OrganisationAddress from "../components/OrganisationAddress";
import OrganisationOfficer from "../components/OrganisationOfficer";
import OrganisationPartner from "../components/OrganisationPartner";
import { getClient, onInputChange } from "../store/client/actions";

function cancel() {
	this.history.goBack();
}

class ViewClient extends Component {
	constructor(props) {
		super(props);
		this.renderContactInformation = this.renderContactInformation.bind(this);
		this.renderIndividualClient = this.renderIndividualClient.bind(this);
		this.renderSoleTrader = this.renderSoleTrader.bind(this);
		this.renderCompany = this.renderCompany.bind(this);
	}
	componentDidMount() {
		this.props.getClient(this.props.match.params.id);
	}
	renderVatInput() {
		return (
			<div className="row fieldset">
				<div className="col-2">
					<label className="fieldset-label">VAT Number</label>
				</div>
				<div className="col-4">
					<input value={this.props.organisation.vatNumber && this.props.organisation.vatNumber !== "" ? this.props.organisation.vatNumber : "NA"} className="input-text" type="text" placeholder="VAT Number" readOnly />
				</div>
			</div>
		);
	}
	renderIndividualClient() {
		return (
			<React.Fragment>
				<div className="row fieldset">
					<div className="col-2">
						<label className="fieldset-label">Prospect Name</label>
					</div>
					<div className="col-4">
						<div className="row">
							<div className="col-6">
								<input value={this.props.organisation.individualFirstName || ""} className="input-text" type="text" placeholder="First Name" readOnly />
							</div>
							<div className="col-6">
								<input value={this.props.organisation.individualLastName || ""} className="input-text" type="text" placeholder="Last Name" readOnly />
							</div>
						</div>
					</div>
				</div>
				<div className="row fieldset">
					<div className="col-2">
						<label className="fieldset-label">Prospect Email</label>
					</div>
					<div className="col-4">
						<input value={this.props.organisation.individualEmail || ""} className="input-text" type="text" placeholder="Client Email" readOnly />
					</div>
				</div>
				<div className="row fieldset">
					<div className="col-2">
						<label className="fieldset-label">Prospect Phone</label>
					</div>
					<div className="col-4">
						<InputPhone readOnly placeholder="Client Phone" dialCode={this.props.organisation.individualDialCode} phone={this.props.organisation.individualPhone} onChange={this.props.onInputChange} />
					</div>
				</div>
				<div className="row fieldset">
					<div className="col-2">
						<label className="fieldset-label">Website</label>
					</div>
					<div className="col-4">
						<input className="input-text" type="text" placeholder="Website" readOnly />
					</div>
				</div>
				<div className="row fieldset">
					<div className="col-2">
						<label className="fieldset-label">Address</label>
					</div>
					<div className="col-4">
						<OrganisationAddress
							addressHeader="Address"
							addressPrefix="individual"
							addressSearch={this.props.organisation.individualAddressSearch}
							premises={this.props.organisation.individualPremises}
							addressLine1={this.props.organisation.individualAddressLine1}
							addressLine2={this.props.organisation.individualAddressLine2}
							locality={this.props.organisation.individualLocality}
							region={this.props.organisation.individualRegion}
							country={this.props.organisation.individualCountry}
							postcode={this.props.organisation.individualPostcode}
							readOnly
						/>
					</div>
				</div>
			</React.Fragment>
		);
	}
	renderOfficers() {
		let jsx = [];
		let index = 0;
		for (let officerCode of this.props.organisation.officerCodes) {
			jsx.push(<OrganisationOfficer
				key={officerCode}
				index={index++}
				officerCode={officerCode}
				isAuthorisedSignatory={this.props.organisation[`officer${officerCode}IsAuthorisedSignatory`]}
				firstName={this.props.organisation[`officer${officerCode}FirstName`]}
				lastName={this.props.organisation[`officer${officerCode}LastName`]}
				role={this.props.organisation[`officer${officerCode}Role`]}
				appointedOn={this.props.organisation[`officer${officerCode}AppointedOn`]}
				nationality={this.props.organisation[`officer${officerCode}Nationality`]}
				countryOfResidence={this.props.organisation[`officer${officerCode}CountryOfResidence`]}
				dialCode={this.props.organisation[`officer${officerCode}DialCode`]}
				phone={this.props.organisation[`officer${officerCode}Phone`]}
				email={this.props.organisation[`officer${officerCode}Email`]}
				addressSearch={this.props.organisation[`officer${officerCode}AddressSearch`]}
				premises={this.props.organisation[`officer${officerCode}Premises`]}
				addressLine1={this.props.organisation[`officer${officerCode}AddressLine1`]}
				addressLine2={this.props.organisation[`officer${officerCode}AddressLine2`]}
				locality={this.props.organisation[`officer${officerCode}Locality`]}
				region={this.props.organisation[`officer${officerCode}Region`]}
				postcode={this.props.organisation[`officer${officerCode}Postcode`]}
				country={this.props.organisation[`officer${officerCode}Country`]}
				readOnly
			/>);
		}
		return jsx;
	}
	renderSoleTrader() {
		return (
			<React.Fragment>
				<div className="row fieldset">
					<div className="col-2">
						<label className="fieldset-label">Trading Name</label>
					</div>
					<div className="col-4">
						<input value={this.props.organisation.tradingName || ""} className="input-text" type="text" placeholder="Trading Name" readOnly />
					</div>
				</div>
				<div className="row fieldset">
					<div className="col-2">
						<label className="fieldset-label">Trading Address</label>
					</div>
					<div className="col-4">
						<OrganisationAddress
							addressHeader="Trading Address"
							addressPrefix="trading"
							addressSearch={this.props.organisation.tradingAddressSearch}
							premises={this.props.organisation.tradingPremises}
							addressLine1={this.props.organisation.tradingAddressLine1}
							addressLine2={this.props.organisation.tradingAddressLine2}
							locality={this.props.organisation.tradingLocality}
							region={this.props.organisation.tradingRegion}
							country={this.props.organisation.tradingCountry}
							postcode={this.props.organisation.tradingPostcode}
							readOnly
						/>
					</div>
				</div>
				{this.renderVatInput()}
				<div className="row fieldset">
					<div className="col-2">
						<label className="fieldset-label">Website</label>
					</div>
					<div className="col-4">
						<input value={this.props.organisation.website || ""} className="input-text" type="text" placeholder="Website" readOnly />
					</div>
				</div>
				<div className="fieldset-group">
					<label className="fieldset-group-label">Sole Trader Details</label>
					<div className="row fieldset">
						<div className="col-2">
							<label className="fieldset-label">First Name</label>
						</div>
						<div className="col-4">
							<input value={this.props.organisation.soleTraderFirstName || ""} className="input-text" type="text" placeholder="First Name" readOnly />
						</div>
					</div>
					<div className="row fieldset">
						<div className="col-2">
							<label className="fieldset-label">Last Name</label>
						</div>
						<div className="col-4">
							<input value={this.props.organisation.soleTraderLastName || ""} className="input-text" type="text" placeholder="Last Name" readOnly />
						</div>
					</div>
					<div className="row fieldset">
						<div className="col-2">
							<label className="fieldset-label">Phone</label>
						</div>
						<div className="col-4">
							<InputPhone readOnly placeholder="Phone" dialCode={this.props.organisation.soleTraderDialCode} phone={this.props.organisation.soleTraderPhone} onChange={this.props.onInputChange} />
						</div>
					</div>
					<div className="row fieldset">
						<div className="col-2">
							<label className="fieldset-label">Email</label>
						</div>
						<div className="col-4">
							<input value={this.props.organisation.soleTraderEmail || ""} className="input-text" type="text" placeholder="Email" readOnly />
						</div>
					</div>
					<div className="row fieldset">
						<div className="col-2">
							<label className="fieldset-label">Residential Address</label>
						</div>
						<div className="col-4">
							<OrganisationAddress
								addressHeader="Residential Address"
								addressPrefix="soleTrader"
								addressSearch={this.props.organisation.soleTraderAddressSearch}
								premises={this.props.organisation.soleTraderPremises}
								addressLine1={this.props.organisation.soleTraderAddressLine1}
								addressLine2={this.props.organisation.soleTraderAddressLine2}
								locality={this.props.organisation.soleTraderLocality}
								region={this.props.organisation.soleTraderRegion}
								country={this.props.organisation.soleTraderCountry}
								postcode={this.props.organisation.soleTraderPostcode}
								readOnly
							/>
						</div>
					</div>
				</div>
			</React.Fragment>
		);
	}
	renderPartners() {
		let jsx = [];
		let index = 0;
		for (let partnerCode of this.props.organisation.partnerCodes) {
			jsx.push(<OrganisationPartner
				key={partnerCode}
				index={index++}
				partnerCode={partnerCode}
				isAuthorisedSignatory={this.props.organisation[`partner${partnerCode}IsAuthorisedSignatory`]}
				firstName={this.props.organisation[`partner${partnerCode}FirstName`]}
				lastName={this.props.organisation[`partner${partnerCode}LastName`]}
				dialCode={this.props.organisation[`partner${partnerCode}DialCode`]}
				phone={this.props.organisation[`partner${partnerCode}Phone`]}
				email={this.props.organisation[`partner${partnerCode}Email`]}
				addressSearch={this.props.organisation[`partner${partnerCode}AddressSearch`]}
				premises={this.props.organisation[`partner${partnerCode}Premises`]}
				addressLine1={this.props.organisation[`partner${partnerCode}AddressLine1`]}
				addressLine2={this.props.organisation[`partner${partnerCode}AddressLine2`]}
				locality={this.props.organisation[`partner${partnerCode}Locality`]}
				region={this.props.organisation[`partner${partnerCode}Region`]}
				postcode={this.props.organisation[`partner${partnerCode}Postcode`]}
				country={this.props.organisation[`partner${partnerCode}Country`]}
				readOnly
			/>);
		}
		return jsx;
	}
	renderPartnership() {
		return (
			<React.Fragment>
				{/* {this.renderContactInformation()} */}
				<div className="row fieldset">
					<div className="col-2">
						<label className="fieldset-label">Trading Name</label>
					</div>
					<div className="col-4">
						<input value={this.props.organisation.tradingName || ""} className="input-text" type="text" placeholder="Trading Name" readOnly />
					</div>
				</div>
				<div className="row fieldset">
					<div className="col-2">
						<label className="fieldset-label">Trading Address</label>
					</div>
					<div className="col-4">
						<OrganisationAddress
							addressHeader="Trading Address"
							addressPrefix="trading"
							addressSearch={this.props.organisation.tradingAddressSearch}
							premises={this.props.organisation.tradingPremises}
							addressLine1={this.props.organisation.tradingAddressLine1}
							addressLine2={this.props.organisation.tradingAddressLine2}
							locality={this.props.organisation.tradingLocality}
							region={this.props.organisation.tradingRegion}
							country={this.props.organisation.tradingCountry}
							postcode={this.props.organisation.tradingPostcode}
							readOnly
						/>
					</div>
				</div>
				{this.renderVatInput()}
				<div className="row fieldset">
					<div className="col-2">
						<label className="fieldset-label">Website</label>
					</div>
					<div className="col-4">
						<input value={this.props.organisation.website || ""} className="input-text" type="text" placeholder="Website" readOnly />
					</div>
				</div>
				<div className="fieldset-group">
					<label className="fieldset-group-label">Partnership Details</label>
					{this.renderPartners()}
				</div>
			</React.Fragment>
		);
	}
	renderCompany() {
		return (
			<React.Fragment>
				{/* {this.renderContactInformation()} */}
				<div className="row fieldset">
					<div className="col-2">
						<label className="fieldset-label">Company Name</label>
					</div>
					<div className="col-4">
						<input value={this.props.organisation.companyName || ""} readOnly className="input-text" type="text" placeholder="Company Name" />
					</div>
				</div>
				<div className="row fieldset">
					<div className="col-2">
						<label className="fieldset-label">Entity Type</label>
					</div>
					<div className="col-4">
						<input value={this.props.organisation.companyType} className="input-text" type="text" placeholder="Entity Type" readOnly />
					</div>
				</div>
				<div className="row fieldset">
					<div className="col-2">
						<label className="fieldset-label">Company Number</label>
					</div>
					<div className="col-4">
						<input value={this.props.organisation.companyNumber || ""} readOnly className="input-text" type="text" placeholder="Company Number" />
					</div>
				</div>
				<div className="row fieldset">
					<div className="col-2">
						<label className="fieldset-label">Registered Office Address</label>
					</div>
					<div className="col-4">
						<OrganisationAddress
							addressHeader="Registered Office Address"
							addressPrefix="company"
							addressSearch={this.props.organisation.companyAddressSearch}
							premises={this.props.organisation.companyPremises}
							addressLine1={this.props.organisation.companyAddressLine1}
							addressLine2={this.props.organisation.companyAddressLine2}
							locality={this.props.organisation.companyLocality}
							region={this.props.organisation.companyRegion}
							country={this.props.organisation.companyCountry}
							postcode={this.props.organisation.companyPostcode}
							readOnly
						/>
					</div>
				</div>
				<div className="row fieldset">
					<div className="col-2">
						<label className="fieldset-label">Incorporation Date</label>
					</div>
					<div className="col-4">
						<input value={this.props.organisation.companyIncorporationDate ? formatDate(this.props.organisation.companyIncorporationDate) : ""} readOnly className="input-text" type="text" placeholder="Incorporation Date" />
					</div>
				</div>
				<div className="row fieldset">
					<div className="col-2">
						<label className="fieldset-label">Incorporated In</label>
					</div>
					<div className="col-4">
						<input value={this.props.organisation.companyIncorporatedIn || ""} readOnly className="input-text" type="text" placeholder="Company Number" />
					</div>
				</div>
				{this.renderVatInput()}
				<div className="row fieldset">
					<div className="col-2">
						<label className="fieldset-label">Website</label>
					</div>
					<div className="col-4">
						<input value={this.props.organisation.website || ""} className="input-text" type="text" placeholder="Website" readOnly />
					</div>
				</div>
				<div className="fieldset-group">
					<label className="fieldset-group-label">Trading Details</label>
					<div className="row fieldset">
						<div className="col-2">
							<label className="fieldset-label">Trading Name</label>
						</div>
						<div className="col-4">
							<input value={this.props.organisation.tradingName || ""} className="input-text" type="text" placeholder="Trading Name" readOnly />
						</div>
					</div>
					<div className="row fieldset">
						<div className="col-2">
							<label className="fieldset-label">Trading Address</label>
						</div>
						<div className="col-4">
							<OrganisationAddress
								addressHeader="Trading Address"
								addressPrefix="trading"
								addressSearch={this.props.organisation.tradingAddressSearch}
								premises={this.props.organisation.tradingPremises}
								addressLine1={this.props.organisation.tradingAddressLine1}
								addressLine2={this.props.organisation.tradingAddressLine2}
								locality={this.props.organisation.tradingLocality}
								region={this.props.organisation.tradingRegion}
								country={this.props.organisation.tradingCountry}
								postcode={this.props.organisation.tradingPostcode}
								readOnly
							/>
						</div>
					</div>
				</div>
				<div className="fieldset-group">
					<label className="fieldset-group-label">Officer Details</label>
					{this.renderOfficers()}
				</div>
			</React.Fragment>
		);
	}
	renderContactInformation() {
		return (
			<div className="fieldset-group">
				<label className="fieldset-group-label">Contact Information</label>
				<div className="row fieldset">
					<div className="col-2">
						<label className="fieldset-label">First Name</label>
					</div>
					<div className="col-4">
						<input value={this.props.organisation.contactFirstName || ""} className="input-text" type="text" placeholder="First Name" readOnly />
					</div>
				</div>
				<div className="row fieldset">
					<div className="col-2">
						<label className="fieldset-label">Last Name</label>
					</div>
					<div className="col-4">
						<input value={this.props.organisation.contactLastName || ""} className="input-text" type="text" placeholder="Last Name" readOnly />
					</div>
				</div>
				<div className="row fieldset">
					<div className="col-2">
						<label className="fieldset-label">Phone</label>
					</div>
					<div className="col-4">
						<InputPhone readOnly placeholder="Phone" dialCode={this.props.organisation.contactDialCode} phone={this.props.organisation.contactPhone} onChange={this.props.onInputChange} />
					</div>
				</div>
				<div className="row fieldset">
					<div className="col-2">
						<label className="fieldset-label">Email</label>
					</div>
					<div className="col-4">
						<input value={this.props.organisation.contactEmail || ""} className="input-text" type="text" placeholder="Email" readOnly />
					</div>
				</div>
			</div>
		);
	}
	render() {
		return (
			<React.Fragment>
				{this.props.loading && <Loader />}
				<div className="page-info-header">
					<div className="container-fluid">
						{/* <div style={{marginTop:"0px",marginBottom:"95px"}}> */}
						<div className="card-body">
							<div className="page-display-title"><button style={{ background: "#5b626b", color: "white" }} onClick={cancel.bind(this.props)} className="btn btn-secondary">Back</button>
								<div style={{ marginTop: "20px", fontSize: "20px" }} className="page-display-title">Prospect Details: </div>
							</div>
						</div>
					</div>
				</div>
				{/* </div> */}


				<div className="container-fluid">
					{/* <div style={{marginTop:"75px",marginBottom:"95px"}}> */}

					<div className="row">
						<div className="col-12">
							<div style={{ marginTop: "140px" }}>
								<div className="card">
									<div className="card-body">
										<div className="row fieldset">
											<div className="col-2">
												<label className="fieldset-label">Prospect Type</label>
											</div>
											<div className="col-4">
												<input value={this.props.clientBusinessType || ""} className="input-text" type="text" placeholder="Prospect Type" readOnly />
											</div>
										</div>
										{this.props.clientBusinessType === "individual" && this.renderIndividualClient()}
										{this.props.clientBusinessType === "soleTrader" && this.renderSoleTrader()}
										{this.props.clientBusinessType === "partnership" && this.renderPartnership()}
										{["llp", "ltd"].includes(this.props.clientBusinessType) && this.renderCompany()}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				{/* </div> */}
			</React.Fragment>
		)
	}
}

// const mapStateToProps = state => state.Client;

const mapStateToProps = state => {
	return { organisation: state.Organisation, ...state.Client }
}

export default withRouter(connect(mapStateToProps, { getClient, onInputChange })(ViewClient));
