import {
  SET_EMAIL_TEMPLATE_LOADING,
  ON_EMAIL_TEMPLATE_INPUT_CHANGE,
  GET_EMAIL_TEMPLATES,
  GET_EMAIL_TEMPLATES_SUCCESS,
  GET_EMAIL_TEMPLATE,
  GET_EMAIL_TEMPLATE_SUCCESS,
  SUBMIT_EMAIL_TEMPLATE,
  SUBMIT_EMAIL_TEMPLATE_SUCCESS,
  RESET_CREATE_NEW_EMAIL_TEMPLATE_FORM,
  SET_SHOW_CREATE_NEW_EMAIL_TEMPLATE_SUCCESS_MODAL,
  UPLOAD_EMAIL_TEMPLATE,
  UPLOAD_EMAIL_TEMPLATE_SUCCESS,
  DELETE_EMAIL_TEMPLATE,
  DELETE_EMAIL_TEMPLATE_SUCCESS,
  CREATE_NEW_EMAIL_TEMPLATES_FAIL,
  SET_SHOW_CREATE_NEW_EMAIL_TEMPLATES_FAILED_MODAL,
  GENERATE_EMAIL_TEMPLATES,
} from "./actionTypes";

export const setEmailTemplateLoading = (isLoading) => {
  return {
    type: SET_EMAIL_TEMPLATE_LOADING,
    payload: isLoading,
  };
};

export const onInputChange = (field, value) => {
  console.log("field :: ", field);
  console.log("value :: ", value);
  return {
    type: ON_EMAIL_TEMPLATE_INPUT_CHANGE,
    payload: {
      field,
      value,
    },
  };
};

export const getEmailTemplates = () => {
  return {
    type: GET_EMAIL_TEMPLATES,
  };
};

export const getEmailTemplatesSuccess = (data) => {
  return {
    type: GET_EMAIL_TEMPLATES_SUCCESS,
    payload: data,
  };
};

export const getEmailTemplate = (id) => {
  return {
    type: GET_EMAIL_TEMPLATE,
    payload: id,
  };
};

export const getEmailTemplateSuccess = (data) => {
  return {
    type: GET_EMAIL_TEMPLATE_SUCCESS,
    payload: data,
  };
};

export const submitEmailTemplate = (data, type = "new") => {
  return {
    type: SUBMIT_EMAIL_TEMPLATE,
    payload: {
      data,
      type,
    },
  };
};

export const submitEmailTemplateSuccess = () => {
  return {
    type: SUBMIT_EMAIL_TEMPLATE_SUCCESS,
  };
};

export const resetCreateNewEmailTemplateForm = () => {
  return {
    type: RESET_CREATE_NEW_EMAIL_TEMPLATE_FORM,
  };
};

export const setShowCreateNewEmailTemplateSuccessModal = (isVisible) => {
  return {
    type: SET_SHOW_CREATE_NEW_EMAIL_TEMPLATE_SUCCESS_MODAL,
    payload: isVisible,
  };
};

export const uploadEmailTemplate = (file) => {
  return {
    type: UPLOAD_EMAIL_TEMPLATE,
    payload: file,
  };
};

export const uploadEmailTemplateSuccess = (fileUrl) => {
  return {
    type: UPLOAD_EMAIL_TEMPLATE_SUCCESS,
    payload: fileUrl,
  };
};

export const deleteEmailTemplate = () => {
  return {
    type: DELETE_EMAIL_TEMPLATE,
  };
};

export const deleteEmailTemplateSuccess = () => {
  return {
    type: DELETE_EMAIL_TEMPLATE_SUCCESS,
  };
};

export const createEmailFailed = (data) => {
  return {
    type: CREATE_NEW_EMAIL_TEMPLATES_FAIL,
    payload: data,
  };
};

export const setShowCreateNewEmailFailedModal = (isVisible) => {
  return {
    type: SET_SHOW_CREATE_NEW_EMAIL_TEMPLATES_FAILED_MODAL,
    payload: isVisible,
  };
};

export const generateEmailTemplate = () => {
  return {
    type: GENERATE_EMAIL_TEMPLATES,
  };
};
