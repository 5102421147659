import React, { Component } from "react";
import { connect } from "react-redux";
import SweetAlert from "react-bootstrap-sweetalert";
import Loader from "../../components/Loader";
import {
  onInputChange,
  addPartner,
  deletePartner,
  addOfficer,
  deleteOfficer,
  getMyOrganisation,
  updatePricingSettings,
  setShowImageUploadModal,
  setShowUpdateOrganisationSuccessModal,
  uploadLogo,
  uploadSignature,
  resetOrganisationForm,
  companySearch,
  selectCompanySearchItem,
  getPricingSettings,
} from "../../store/organisation/actions";

class PricingSettings extends Component {
  constructor(props) {
    super(props);
    this.updateOrganisation = this.updateOrganisation.bind(this);
  }
  componentDidMount() {
    // this.props.resetOrganisationForm();
    this.props.getPricingSettings();
  }
  //   componentDidUpdate() {}
  //   componentWillUnmount() {
  //     this.props.resetOrganisationForm();
  //   }
  updateOrganisation() {
    this.props.updatePricingSettings({
      minPricing: this.props.minPricing,
      minPricingMonth: this.props.minPricingMonth,
      maxDiscount: this.props.maxDiscount,
    });
  }
  render() {
    return (
      <React.Fragment>
        <div className="page-info-header">
          <div className="container-fluid">
            <div className="page-display-title">Pricing Settings</div>
          </div>
        </div>
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="card cus-card">
                <div className="card-body">
                  <div className="row fieldset">
                    <div className="col-3">
                      <label className="fieldset-label">Min. One-Off Price for Proposal/Engagement Letter (£)</label>
                    </div>
                    <div className="col-2">
                      <input
                        type="text"
                        className="input-text"
                        min={0}
                        onChange={(e) => {
                          var reg = /^\d+$/;
                          if (!reg.test(e.target.value) && e.target.value != "")
                            return;
                          if (e.target.value.length > 8) return;
                          const value = e.target.value < 0 ? 0 : e.target.value;
                          this.props.onInputChange("minPricing", value);
                        }}
                        value={this.props.minPricing}
                      />
                    </div>
                  </div>
                  <div className="row fieldset">
                    <div className="col-3">
                      <label className="fieldset-label">Min. Monthly Price for Proposal/Engagement Letter (£)</label>
                    </div>
                    <div className="col-2">
                      <input
                        type="text"
                        className="input-text"
                        min={0}
                        onChange={(e) => {
                          var reg = /^\d+$/;
                          if (!reg.test(e.target.value) && e.target.value != "")
                            return;
                          if (e.target.value.length > 8) return;
                          const value = e.target.value < 0 ? 0 : e.target.value;
                          this.props.onInputChange("minPricingMonth", value);
                        }}
                        value={this.props.minPricingMonth}
                      />
                    </div>
                  </div>
                  <div className="row fieldset">
                    <div className="col-3">
                      <label className="fieldset-label">
                        Max. Discount (%) for Proposal/Engagement Letter
                      </label>
                    </div>
                    <div className="col-2">
                      <input
                        type="text"
                        min={0}
                        max={100}
                        className="input-text"
                        onChange={(e) => {
                          var reg = /^\d+$/;
                          if (!reg.test(e.target.value) && e.target.value != "")
                            return;
                          if (e.target.value > 100) return;
                          const value = e.target.value < 0 ? 0 : e.target.value;
                          this.props.onInputChange("maxDiscount", value);
                        }}
                        value={this.props.maxDiscount}
                      />
                    </div>
                  </div>
                  <div className="row fieldset">
                    <div className="col-12">
                      <button
                        onClick={this.updateOrganisation}
                        className="btn btn-lg btn-block mt-3 create-item" disabled
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {this.props.showUpdateOrganisationSuccessModal && (
          <SweetAlert
            title=""
            success
            confirmBtnBsStyle="success"
            onConfirm={() =>
              this.props.setShowUpdateOrganisationSuccessModal(false)
            }
          >
            <div className="mb-2 success-alert-message">
              Pricing Details has been updated successfully!
            </div>
          </SweetAlert>
        )}
        {this.props.pricingSettingsLoading && <Loader />}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => state.Organisation;

export default connect(mapStateToProps, {
  onInputChange,
  addPartner,
  deletePartner,
  addOfficer,
  deleteOfficer,
  getMyOrganisation,
  updatePricingSettings,
  setShowImageUploadModal,
  setShowUpdateOrganisationSuccessModal,
  uploadLogo,
  uploadSignature,
  resetOrganisationForm,
  companySearch,
  selectCompanySearchItem,
  getPricingSettings,
})(PricingSettings);
