export const ON_LOGIN_INPUT_CHANGE = 'ON_LOGIN_INPUT_CHANGE';

export const LOGIN_USER = 'LOGIN_USER';
export const LOGIN_USER_WITH_PROVIDER = 'LOGIN_USER_WITH_PROVIDER';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';

export const LOGOUT_USER = 'LOGOUT_USER';
export const LOGOUT_USER_SUCCESS = 'LOGOUT_USER_SUCCESS';
export const LOGIN_ERROR = 'LOGIN_API_ERROR';

export const SWITCH_ORGANISATION = 'SWITCH_ORGANISATION';

export const RESET_LOGIN_FORM = 'RESET_LOGIN_FORM';
