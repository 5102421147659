import React, { useState, useEffect } from "react";
import { Route, Redirect } from "react-router-dom";
import axios from 'axios';

//AUTH related methods
import { getFirebaseBackend } from "../helpers/authUtils";

const AppRoute = ({
  component: Component,
  layout: Layout,
  isAuthProtected,
  roles,
  path,
  subscriptionPackage,
  ...rest
}) => {
  const [error, setError] = useState(null);
  var [organisationData, setOrganisationData] = useState(null);

  useEffect(() => {
    const fetchOrganisationData = async () => {
      try {
        const token = JSON.parse(localStorage.getItem('authUser'));
        if (token && token.stsTokenManager && token.stsTokenManager.accessToken) {
          const response = await axios.get('https://proposal.outbooks.com/api/organisation/me', {
            headers: {
              'Authorization': `Bearer ${token.stsTokenManager.accessToken}`
            }
          });
          setOrganisationData(response.data);
        } else {
          setError('Token not found or invalid.');
        }
      } catch (error) {
        setError(error.message);
      }
    };

    fetchOrganisationData();
  }, []);

  return (
    <Route
      {...rest}
      render={(props) => {
        const fireBaseBackend = getFirebaseBackend();
        const userRole = fireBaseBackend.getUserRole();
        if (path === "/signup" || path === "/SignUp") {
          window.location.href = "https://app.proposal.outbooks.com/registration"; // Directly navigate to the URL
          return null; // Prevent further rendering
        }
        if (path === "/login" && fireBaseBackend.getAuthenticatedUser()) {
          // return <Redirect to={{ pathname: "/dashboard" }} />;
        }
        if (path === "/contracts" && organisationData && !organisationData?.result?.isVisible) {
          return <Redirect to={{ pathname: "/dashboard" }} />;
        }
        if (isAuthProtected && !fireBaseBackend.getAuthenticatedUser()) {
          return (
            <Redirect
              to={{ pathname: "/signIn", state: { from: props.location } }}
            />
          );
        } else if (roles && !roles.includes(userRole)) {
          return (
            <Redirect
              to={{
                pathname:
                  userRole === "unassigned" ? "/get-started" : "/dashboard",
                state: { from: props.location },
              }}
            />
          );
        }
      // Check for the role
      // const prepare_quote =
      //   subscriptionPackage?.plan?.prepare_quote;
      // const prepare_contract =
      //   subscriptionPackage?.plan?.prepare_contract;
      // console.log('subscriptionPackage - ', subscriptionPackage);
      // if(path === '/quotes/new' && !prepare_quote) {
      //   return (
      //     <Redirect
      //       to={{
      //         pathname: "/quotes",
      //         // search: 'upgrade=true',
      //         state: { from: props.location },
      //       }}
      //     />
      //   )
      // }
      // if(path === '/contracts/new' && !prepare_contract) {
      //   return (
      //     <Redirect
      //       to={{
      //         pathname: "/contracts",
      //         // search: 'upgrade=true',
      //         state: { from: props.location },
      //       }}
      //     />
      //   )
      // }

      return (
        <Layout>
          <Component {...props} />
        </Layout>
      );
    }}
  />
);
};

export default AppRoute;
