import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";
import Loader from "../components/Loader";
import ReviewServices from "../components/ReviewServices";
import ReviewPackagesStep from "../components/Quote/PackagedPricing/ReviewPackagesStep";
import ViewQuoteClientDetails from "../components/Quote/ViewQuote/ViewQuoteClientDetails";
import { getQuote, setActiveStep } from "../store/quote/actions";
import { useDispatch, useSelector } from "react-redux";

const DisplayPdfs = (props) => {
  const dispatch = useDispatch();
  const quoteStatus = useSelector((state) => state?.Quote?.quoteStatus);
  useEffect(() => {
    props.getQuote(atob(props.match.params.id));
    dispatch(getQuote(atob(props.match.params.id)));
  }, []);
  if (!quoteStatus) return <Loader />;
  return (
    <React.Fragment>
      {props.loading && <Loader />}
      <iframe
        src={props.quotePdfLink}
        style={{ height: "100vh", width: "100vw" }}
      />
    </React.Fragment>
  );
};

const mapStateToProps = (state) => state.Quote;

export default withRouter(connect(mapStateToProps, { getQuote })(DisplayPdfs));
