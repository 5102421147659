import React, { Component } from "react";
import { Row, Col, Card, Alert } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import Loader from "../../components/Loader";
import { withRouter, Link } from "react-router-dom";
import {
  userResetPassword,
  resetForgotPasswordForm,
  validateResetToken,
} from "../../store/actions";
import logoDark from "../../assets/images/outbooks.png";
import SweetAlert from "react-bootstrap-sweetalert";

class ResetPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      password: "",
      confirmPassword: "",
      passwordError: "",
      redirectToLogin: false,
      isPasswordValid: false, // Flag to track if password meets criteria
      isLengthValid: false,
      hasLetter: false,
      hasNumber: false,
      hasSpecialChar: false,
      showSuccessAlert: false, // Added state to control the SweetAlert visibility
      redirectToLogin: false,
      passwordCriteria: true,
    };
  }
  // state = {
  //   password: "",
  //   confirmPassword: "",
  //   passwordError: "",
  //   redirectToLogin: false,
  //   isPasswordValid: false, // Flag to track if password meets criteria
  //   isLengthValid: false,
  //   hasLetter: false,
  //   hasNumber: false,
  //   hasSpecialChar: false,
  // };

  handleValidSubmit = (event, values) => {
    const { password, confirmPassword, isPasswordValid } = this.state;

    // Check if passwords match
    if (password !== confirmPassword) {
      this.setState({
        passwordError: "Password and Confirm Password do not match.",
        isPasswordValid: false,
      });
      return;
    }

    // Check if password meets criteria
    const passwordPattern =
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{10,}$/;
    const isValidPassword = passwordPattern.test(password);

    // if (!isValidPassword) {
    //   this.setState({
    //     passwordError:
    //       "Your password must be a minimum of 10 characters long and include at least one letter, one number, and one special character (@$!%*#?&).",
    //     isPasswordValid: false,
    //   });
    //   return;
    // }
    if (
      password.length >= 8 &&
      /[a-zA-Z]/.test(password) &&
      /\d/.test(password) &&
      /[-@$!%*#?&]/.test(password)
    ) {
      this.props.userResetPassword(
        this.props.match.params.userId,
        password,
        this.props.match.params.token,
        (response) => {
          var isSuccess = response.data.success;
          if (isSuccess === true) {
            this.setState({ showSuccessAlert: true, passwordCriteria: false });
          } else {
            this.setState({ showSuccessAlert: false });
          }
        }
      );
    } else {
      return;
    }

    // this.setState({
    //   resetSuccessMsg: "Password reset successful. Redirecting to login...",
    //   isPasswordValid: true,
    // });

    // setTimeout(() => {
    //   this.setState({ redirectToLogin: true });
    // }, 3000);
  };

  handlePasswordChange = (event) => {
    const password = event.target.value;
    const isLengthValid = password.length >= 8;
    const hasLetter = /[a-zA-Z]/.test(password);
    const hasNumber = /\d/.test(password);
    const hasSpecialChar = /[-@$!%*#?&]/.test(password);

    const isPasswordValid =
      isLengthValid && hasLetter && hasNumber && hasSpecialChar;

    this.setState({
      password,
      passwordError: "",
      isPasswordValid,
      isLengthValid,
      hasLetter,
      hasNumber,
      hasSpecialChar,
    });
  };

  handleConfirmPasswordChange = (event) => {
    this.setState({ confirmPassword: event.target.value, passwordError: "" });
  };

  closeSuccessAlert = () => {
    this.setState({ showSuccessAlert: false }, () => {
      this.setState({ redirectToLogin: true });
    });
  };

  render() {
    const {
      password,
      confirmPassword,
      passwordError,
      redirectToLogin,
      isPasswordValid,
      isLengthValid,
      hasLetter,
      hasNumber,
      hasSpecialChar,
      passwordCriteria,
    } = this.state;
    const isPasswordMatched = password === confirmPassword;

    // if (this.props.resetSuccessMsg) {
    //   setTimeout(() => {
    //     this.setState({ redirectToLogin: true });
    //   }, 3000);
    // }

    if (redirectToLogin) {
      return <Redirect to="/login" />;
    }

    return (
      <React.Fragment>
        <div className="accountbg"></div>
        <div className="wrapper-page account-page-full">
          <Card className="shadow-none">
            <div className="card-block">
              <div className="account-box">
                {this.props.loading || this.state.showSuccessAlert === true ? (
                  <Loader />
                ) : null}
                <div className="card-box shadow-none p-4">
                  <div className="p-2">
                    <div className="text-center mt-4">
                      <Link to="/">
                        <img src={logoDark} height="22" alt="logo" />
                      </Link>
                    </div>

                    <h4 className="font-size-18 mt-5 text-center">
                      Reset Password
                    </h4>

                    <AvForm
                      className="form-horizontal mt-4"
                      onValidSubmit={this.handleValidSubmit}
                    >
                      {passwordCriteria && (
                        <div style={{ marginBottom: "10px" }}>
                          <p>
                            Please enter your new password. Password must meet
                            the following criteria:
                          </p>
                          <ul className="list-unstyled">
                            {/* Minimum length criterion */}
                            <li
                              className={`text-${
                                isLengthValid ? "success" : "danger"
                              }`}
                            >
                              Minimum of 8 characters long.{" "}
                              {isLengthValid ? <span>&#10004;</span> : ""}
                            </li>
                            {/* Include at least one letter criterion */}
                            <li
                              className={`text-${
                                hasLetter ? "success" : "danger"
                              }`}
                            >
                              Include at least one letter.{" "}
                              {hasLetter ? <span>&#10004;</span> : ""}
                            </li>
                            {/* Include at least one number criterion */}
                            <li
                              className={`text-${
                                hasNumber ? "success" : "danger"
                              }`}
                            >
                              Include at least one number.{" "}
                              {hasNumber ? <span>&#10004;</span> : ""}
                            </li>
                            {/* Include at least one special character criterion */}
                            <li
                              className={`text-${
                                hasSpecialChar ? "success" : "danger"
                              }`}
                            >
                              Include at least one special character (-@$!%*#?&).{" "}
                              {hasSpecialChar ? <span>&#10004;</span> : ""}
                            </li>
                          </ul>
                        </div>
                      )}
                      {this.props.resetError && this.props.resetError ? (
                        <Alert color="danger" style={{ marginTop: "13px" }}>
                          {this.props.resetError}
                        </Alert>
                      ) : null}
                      {this.props.resetSuccessMsg ? (
                        <Alert color="success" style={{ marginTop: "13px" }}>
                          {this.props.resetSuccessMsg}
                        </Alert>
                      ) : null}

                      {!this.props.resetError &&
                        !this.props.resetSuccessMsg && (
                          <>
                            {passwordError && (
                              <Alert
                                color="danger"
                                style={{ marginBottom: "10px" }}
                              >
                                {passwordError}
                              </Alert>
                            )}

                            <AvField
                              name="password"
                              label="New Password"
                              className="form-control"
                              placeholder="Enter your new password"
                              type="password"
                              value={password}
                              onChange={this.handlePasswordChange}
                              validate={{
                                required: {
                                  value: true,
                                  errorMessage: "This field is required.",
                                },
                              }}
                            />

                            <AvField
                              name="confirmPassword"
                              label="Confirm New Password"
                              className="form-control"
                              placeholder="Re-enter your new password"
                              type="password"
                              value={confirmPassword}
                              onChange={this.handleConfirmPasswordChange}
                              validate={{
                                required: {
                                  value: true,
                                  errorMessage: "This field is required.",
                                },
                              }}
                            />

                            {/* {!isPasswordMatched && confirmPassword && (
                              <Alert
                                color="danger"
                                style={{ marginTop: "10px" }}
                              >
                                Password and Confirm Password do not match.
                              </Alert>
                            )} */}

                            <Row className="form-group">
                              <Col className="text-right">
                                <button
                                  className="btn btn-primary w-md waves-effect waves-light"
                                  type="submit"
                                  // disabled={
                                  //   !isPasswordValid || !isPasswordMatched
                                  // }
                                  // style={{
                                  //   cursor:
                                  //     isPasswordValid && isPasswordMatched
                                  //       ? "pointer"
                                  //       : "not-allowed",
                                  // }}
                                >
                                  Reset Password
                                </button>
                              </Col>
                            </Row>
                          </>
                        )}
                    </AvForm>

                    <div className="mt-5 pt-4 text-center">
                      {!this.props.resetSuccessMsg ? (
                        <p>
                          Remember It ?{" "}
                          <Link
                            to="/login"
                            className="font-weight-medium text-primary"
                          >
                            {" "}
                            Sign In here{" "}
                          </Link>{" "}
                        </p>
                      ) : null}
                      <p>© {new Date().getFullYear()} Outbooks</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Card>
        </div>
        {this.state.showSuccessAlert && (
          <SweetAlert
            title=""
            success
            confirmBtnBsStyle="success"
            // onConfirm={this.clearModal}
            onConfirm={this.closeSuccessAlert}
          >
            <div className="mb-2 success-alert-message">
              Success! Your password has been changed successfully.
              {this.state.userEmail}
            </div>
          </SweetAlert>
        )}
      </React.Fragment>
    );
  }
}

const mapStatetoProps = (state) => {
  const { resetError, resetSuccessMsg, loading } = state.ForgetPassword;
  return { resetError, resetSuccessMsg, loading };
};

export default withRouter(
  connect(mapStatetoProps, {
    userResetPassword,
    resetForgotPasswordForm,
    validateResetToken,
  })(ResetPassword)
);
