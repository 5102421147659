const apiBaseUrl = "https://proposal.outbooks.com/api";
// const apiBaseUrl = 'http://localhost:8080/api';

const salutations = [
  { value: "Mr.", label: "Mr." },
  { value: "Mrs.", label: "Mrs." },
  { value: "Ms.", label: "Ms." },
  { value: "Miss.", label: "Miss." },
  { value: "Dr.", label: "Dr." },
];

const businessTypes = [
  { value: "soleTrader", label: "Sole Trader" },
  { value: "partnership", label: "Partnership" },
  { value: "llp", label: "LLP" },
  { value: "ltd", label: "Company" },
];

const templateTypes = [
  { value: "quote", label: "Quote" },
  { value: "contract", label: "Contract" },
];

const signatoryColumnPositions = [
  { value: "left", label: "Left Column" },
  { value: "right", label: "Right Column" },
];

const countryOptions = [
  {
    value: "United Kingdom",
    label: "United Kingdom",
    inputValue: "United Kingdom",
  },
  {
    value: "Great Britain",
    label: "Great Britain",
    inputValue: "Great Britain",
  },
  { value: "England", label: "England", inputValue: "England" },
  {
    value: "Northern Ireland",
    label: "Northern Ireland",
    inputValue: "Northern Ireland",
  },
  { value: "Scotland", label: "Scotland", inputValue: "Scotland" },
  { value: "Wales", label: "Wales", inputValue: "Wales" },
];

const incorporatedInCountryOptions = [
  {
    value: "England and Wales (EW)",
    label: "England and Wales (EW)",
    inputValue: "England and Wales (EW)",
  },
  {
    value: "Northern Ireland (NI)",
    label: "Northern Ireland (NI)",
    inputValue: "Northern Ireland (NI)",
  },
  {
    value: "Scotland (SC)",
    label: "Scotland (SC)",
    inputValue: "Scotland (SC)",
  },
  { value: "Wales (WA)", label: "Wales (WA)", inputValue: "Wales (WA)" },
];

const quotePricingOptions = [
  {
    value: "customPackagedPricing",
    label: "Packaged (Customizable) (Single/Multiple)",
  },
  { value: "packagedPricing", label: "Packaged (Standard) (Single/Multiple)" },
  { value: "aLaCartePricing", label: "Custom (Single)" },
];

const contractSourceOptions = [
  { value: "fromScratch", label: "From Scratch" },
  { value: "fromQuote", label: "From Proposal" },
];

const clientBusinessTypes = [
  { value: "individual", label: "Individual" },
].concat(businessTypes);

const vatValues = [
  { value: true, label: "Yes" },
  { value: false, label: "No" },
];

const currencyValues = [
  { value: "GBP", label: "GBP (£)" },
  { value: "EUR", label: "EUR (€)" },
  { value: "AUD", label: "AUD (AU$)" },
  { value: "USD", label: "USD (US$)" },
];

const serviceChargeTypes = [
  { value: "recurring", label: "Recurring" },
  { value: "oneoff", label: "One Off" },
  { value: "recurring/oneoff", label: "Both Recurring & One Off" },
];

const servicePricingTypes = [
  { value: "fixedPricing", label: "Fixed Price" },
  { value: "formulaBasedPricing", label: "Formula Based Pricing" },
];

const driverOptions = [
  { value: "Quantity", label: "Quantity" },
  { value: "Variation", label: "Variation" },
  { value: "Slab", label: "Slab Based" },
  /*{value: "Number Range", label: "Number Range"},*/
];

const slabOptions = [
  // {value: "BlockBased", label: "Block Based"},
  { value: "BlockBased", label: "Slab Block" },
  // {value: "UnitBased", label: "Unit Based"},
  // {value: "IncrementalBlock", label: "Incremental Block"},
  { value: "IncrementalBlock", label: "Incremental Slab" },
  // {value: "IncrementalUnit", label: "Incremental Unit"}
];

const paymentFrequencyOptions = [
  { value: "yearly", label: "Yearly" },
  { value: "halfYearly", label: "Half Yearly" },
  { value: "quarterly", label: "Quarterly" },
  { value: "monthly", label: "Monthly" },
];

// const paymentMethodOptions = [
//   {value: "GoCardless", label: "Go Cardless"},
//   {value: "Stripe", label: "Stripe"}
// ];

const userRoles = [{ value: "admin", label: "Admin" }];

const showFullBreakdownOptions = [
  { value: true, label: "Show Full Breakdown" },
  { value: false, label: "Show Totals Only" },
];

const tncTemplateTypes = [
  { value: "pdf", label: "PDF" },
  { value: "customTemplate", label: "Custom Template" },
];

export {
  salutations,
  apiBaseUrl,
  businessTypes,
  clientBusinessTypes,
  countryOptions,
  incorporatedInCountryOptions,
  vatValues,
  currencyValues,
  serviceChargeTypes,
  servicePricingTypes,
  driverOptions,
  slabOptions,
  paymentFrequencyOptions,
  quotePricingOptions,
  userRoles,
  contractSourceOptions,
  templateTypes,
  signatoryColumnPositions,
  showFullBreakdownOptions,
  tncTemplateTypes,
  // paymentMethodOptions
};
