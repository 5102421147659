import {
  FORGET_PASSWORD,
  FORGET_PASSWORD_SUCCESS,
  FORGET_PASSWORD_ERROR,
  RESET_PASSWORD,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_ERROR,
  RESET_FORGET_PASSWORD_FORM,
  VALIDATE_RESET_TOKEN,
  VALIDATE_RESET_TOKEN_SUCCESS,
  VALIDATE_RESET_TOKEN_FAILURE,
} from "./actionTypes";

const initialState = {
  forgetSuccessMsg: null,
  forgetError: null,
  resetSuccessMsg: null,
  resetError: null,
  loading: false,
};

const forgetPassword = (state = initialState, action) => {
  switch (action.type) {
    case FORGET_PASSWORD:
      state = {
        ...state,
        forgetSuccessMsg: null,
        forgetError: null,
        loading: true,
      };
      break;
    case FORGET_PASSWORD_SUCCESS:
      state = {
        ...state,
        forgetSuccessMsg: action.payload,
        loading: false,
      };
      break;
    case FORGET_PASSWORD_ERROR:
      state = { ...state, forgetError: action.payload, loading: false };
      break;
    case RESET_PASSWORD:
      state = {
        ...state,
        resetSuccessMsg: null,
        resetError: null,
        loading: true,
      };
      break;
    case RESET_PASSWORD_SUCCESS:
      state = {
        ...state,
        resetSuccessMsg: action.payload,
        loading: false,
      };
      break;
    case RESET_PASSWORD_ERROR:
      state = { ...state, resetError: action.payload, loading: false };
      break;
    case VALIDATE_RESET_TOKEN:
      state = { ...state, loading: true };
      break;
    case VALIDATE_RESET_TOKEN_SUCCESS:
      state = { ...state, loading: false };
      break;
    case VALIDATE_RESET_TOKEN_FAILURE:
      state = { ...state, resetError: action.payload, loading: false };
      break;
    case RESET_FORGET_PASSWORD_FORM:
      return initialState;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default forgetPassword;
