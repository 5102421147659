import {
  SET_CONTRACT_LOADING,
  ON_CONTRACT_INPUT_CHANGE,
  GET_CONTRACTS,
  GET_CONTRACTS_SUCCESS,
  GET_CONTRACT,
  GET_CONTRACT_SUCCESS,
  DOWNLOAD_CONTRACT,
  DOWNLOAD_CONTRACT_SUCCESS,
  SET_CONTRACT_ACTIVE_STEP,
  ADD_SERVICE_TO_CONTRACT,
  REMOVE_SERVICE_FROM_CONTRACT,
  PREVIEW_CONTRACT,
  PREVIEW_CONTRACT_SUCCESS,
  REVIEW_CONTRACT,
  REVIEW_CONTRACT_SUCCESS,
  SAVE_CONTRACT_AS_DRAFT,
  SAVE_CONTRACT_AS_DRAFT_SUCCESS,
  SEND_CONTRACT,
  SEND_CONTRACT_SUCCESS,
  RESET_CREATE_NEW_CONTRACT_FORM,
  SET_SHOW_CREATE_NEW_CONTRACT_SUCCESS_MODAL,
  GET_SIGNATURE_BLOCKS,
  GET_SIGNATURE_BLOCKS_SUCCESS,
  ADD_SIGNATORY,
  DELETE_SIGNATORY,
  SET_SIGNATORIES,
  GET_TNC_TEMPLATES,
  GET_TNC_TEMPLATES_SUCCESS,
  SEARCH_CONTRACT,
  SEARCH_CONTRACT_SUCCESS,
  GET_CONTRACT_STATUS,
  GET_CONTRACT_PDF_URL,
  SEND_CONTRACT_CLIENT,
  SEND_CONTRACT_CLIENT_SUCCESS,
  SAVE_CONTRACT_AS_DRAFT_CLIENT,
  SAVE_CONTRACT_AS_DRAFT_CLIENT_SUCCESS,
  REVIEW_CONTRACT_CLIENT,
  REVIEW_CONTRACT_CLIENT_SUCCESS,
  RESET_CONTRACT_SEARCH_INPUT,
} from "./actionTypes";

export const setContractLoading = (isLoading) => {
  return {
    type: SET_CONTRACT_LOADING,
    payload: isLoading,
  };
};

export const onInputChange = (field, value, isPricingDrivers = false) => {
  return {
    type: ON_CONTRACT_INPUT_CHANGE,
    payload: {
      field,
      isPricingDrivers,
      value,
    },
  };
};

export const searchContract = (query) => {
  return {
    type: SEARCH_CONTRACT,
    payload: query,
  };
};

export const searchContractSuccess = (contracts) => {
  return {
    type: SEARCH_CONTRACT_SUCCESS,
    payload: contracts,
  };
};

export const addServiceToContract = (service, pricingDriverKeys) => {
  return {
    type: ADD_SERVICE_TO_CONTRACT,
    payload: {
      service,
      pricingDriverKeys,
    },
  };
};

export const removeServiceFromContract = (service, pricingDriverKeys) => {
  return {
    type: REMOVE_SERVICE_FROM_CONTRACT,
    payload: {
      service,
      pricingDriverKeys,
    },
  };
};

export const previewContract = (data) => {
  return {
    type: PREVIEW_CONTRACT,
    payload: data,
  };
};

export const previewContractSuccess = (data) => {
  return {
    type: PREVIEW_CONTRACT_SUCCESS,
    payload: data,
  };
};

export const reviewContract = (data) => {
  return {
    type: REVIEW_CONTRACT,
    payload: data,
  };
};

export const reviewContractSuccess = (data) => {
  return {
    type: REVIEW_CONTRACT_SUCCESS,
    payload: data,
  };
};

export const setActiveStep = (step) => {
  return {
    type: SET_CONTRACT_ACTIVE_STEP,
    payload: step,
  };
};

export const getContracts = (pageNum = 0, status = "",filter = "") => {
  return {
    type: GET_CONTRACTS,
    payload: { pageNum, status, filter },
  };
};

export const getContractsSuccess = (data) => {
  return {
    type: GET_CONTRACTS_SUCCESS,
    payload: data,
  };
};

export const getContract = (id) => {
  return {
    type: GET_CONTRACT,
    payload: id,
  };
};

export const getContractStatus = (data) => {
  return {
    type: GET_CONTRACT_STATUS,
    payload: data,
  };
};
export const getContractPdf = (data) => {
  return {
    type: GET_CONTRACT_PDF_URL,
    payload: data,
  };
};

export const getContractSuccess = (data) => {
  return {
    type: GET_CONTRACT_SUCCESS,
    payload: data,
  };
};

export const downloadContract = (id) => {
  return {
    type: DOWNLOAD_CONTRACT,
    payload: id,
  };
};

export const downloadContractSuccess = () => {
  return {
    type: DOWNLOAD_CONTRACT_SUCCESS,
  };
};

export const saveContractAsDraft = (data) => {
  return {
    type: SAVE_CONTRACT_AS_DRAFT,
    payload: data,
  };
};

export const saveContractAsDraftSuccess = () => {
  return {
    type: SAVE_CONTRACT_AS_DRAFT_SUCCESS,
  };
};

export const sendContract = (data) => {
  return {
    type: SEND_CONTRACT,
    payload: data,
  };
};

export const sendContractSuccess = () => {
  return {
    type: SEND_CONTRACT_SUCCESS,
  };
};

export const resetCreateNewContractForm = () => {
  return {
    type: RESET_CREATE_NEW_CONTRACT_FORM,
  };
};

export const setShowCreateNewContractSuccessModal = (isVisible) => {
  return {
    type: SET_SHOW_CREATE_NEW_CONTRACT_SUCCESS_MODAL,
    payload: isVisible,
  };
};

export const getSignatureBlocks = (templateId) => {
  return {
    type: GET_SIGNATURE_BLOCKS,
    payload: templateId,
  };
};

export const getSignatureBlocksSuccess = (signatureBlocks) => {
  return {
    type: GET_SIGNATURE_BLOCKS_SUCCESS,
    payload: signatureBlocks,
  };
};

export const addSignatory = (code) => {
  return {
    type: ADD_SIGNATORY,
    payload: code,
  };
};

export const deleteSignatory = (code) => {
  return {
    type: DELETE_SIGNATORY,
    payload: code,
  };
};

export const setSignatories = (signatories = []) => {
  return {
    type: SET_SIGNATORIES,
    payload: signatories,
  };
};

export const getTncTemplates = () => {
  return {
    type: GET_TNC_TEMPLATES,
  };
};

export const getTncTemplatesSuccess = (templates) => {
  return {
    type: GET_TNC_TEMPLATES_SUCCESS,
    payload: templates,
  };
};

export const sendContractClient = (data) => {
  return {
    type: SEND_CONTRACT_CLIENT,
    payload: data,
  };
};

export const sendContractSuccessClient = (url) => {
  return {
    type: SEND_CONTRACT_CLIENT_SUCCESS,
    payload: url,
  };
};

export const saveContractAsDraftClient = (data) => {
  return {
    type: SAVE_CONTRACT_AS_DRAFT_CLIENT,
    payload: data,
  };
};

export const saveContractAsDraftClientSuccess = () => {
  return {
    type: SAVE_CONTRACT_AS_DRAFT_CLIENT_SUCCESS,
  };
};
export const reviewContractClient = (data) => {
  return {
    type: REVIEW_CONTRACT_CLIENT,
    payload: data,
  };
};

export const reviewContractClientSuccess = (data) => {
  return {
    type: REVIEW_CONTRACT_CLIENT_SUCCESS,
    payload: data,
  };
};

export const resetSearchInput = () => {
  return {
    type: RESET_CONTRACT_SEARCH_INPUT,
  };
}