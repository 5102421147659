import React, { useState, useRef } from 'react'
import { useIdleTimer } from 'react-idle-timer'
import SweetAlert from "react-bootstrap-sweetalert";

function IdleTimerContainer () {
  const [isLoggedIn, setIsLoggedIn] = useState(true)
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const idleTimerRef = useRef(null)
  const sessionTimeoutRef = useRef(null)
  const idleTimer = useIdleTimer({
    crossTab: true,
    ref: idleTimerRef,
    timeout: 1200 * 1000,
    onIdle: onIdle,
  });
  function onIdle() {
    const expiredTime = parseInt(
      localStorage.getItem("_expiredTime") || 0,
      10
    )
    console.log('Da -- ', Date.now(), expiredTime);
    if(Date.now() >= expiredTime){
      console.log('User is idle')
      if (window.location.pathname !== "/signIn" && window.location.pathname !== "/register") {
        setModalIsOpen(true)
      } 
      
      sessionTimeoutRef.current = setTimeout(() => {
        const interaction = localStorage.getItem('_timeout-interaction');
        if(interaction !== 'active') {
          logOut();
        } else {
          setModalIsOpen(false);
        }
      }, 10000)
    }
   
  }
  

  const logOut = () => {
    setModalIsOpen(false)
    setIsLoggedIn(false)
    clearTimeout(sessionTimeoutRef.current)
    if (window.location.pathname !== "/signIn" && window.location.pathname !== "/register") {
      window.location = "/logout"
    }    
    console.log('User has been logged out')
  }
  const stayActive = () => {
    setModalIsOpen(false)
    clearTimeout(sessionTimeoutRef.current)
    console.log('User is active')
  }
  return (
    <><div idleTimer={idleTimer}></div>
    {modalIsOpen &&  <SweetAlert
        showCancel
        confirmBtnText="Stay Connected"
        cancelBtnText="Logout"
        confirmBtnBsStyle="success"
        cancelBtnBsStyle="danger"
        title="Oops"
        onCancel={() => {
          localStorage.setItem('_timeout-interaction', 'logout');
          setTimeout(() => {
            localStorage.removeItem('_timeout-interaction');
          }, 10200)
          logOut();
        }}
        onConfirm={() => {
          localStorage.setItem('_timeout-interaction', 'active');
          setTimeout(() => {
            localStorage.removeItem('_timeout-interaction');
          }, 10200)
          stayActive();
        }}
      >
        Your Session is About to Expire!
      </SweetAlert>}
    </>
     
  )
}

export default IdleTimerContainer

export class IdleTimer {
  constructor({ timeout, onTimeout, onExpired }) {
    this.timeout = timeout;
    this.onTimeout = onTimeout;

    const expiredTime = parseInt(localStorage.getItem("_expiredTime") || 0, 10);
    if (expiredTime > 0 && expiredTime < Date.now()) {
      onExpired();
      return;
    }

    this.eventHandler = this.updateExpiredTime.bind(this);
    this.tracker();
    this.startInterval();
  }

  startInterval() {
    this.updateExpiredTime();

    this.interval = setInterval(() => {
      const expiredTime = parseInt(
        localStorage.getItem("_expiredTime") || 0,
        10
      );
      if (expiredTime < Date.now()) {
        if (this.onTimeout) {
          this.onTimeout();
          this.cleanUp();
        }
      }
    }, 1000);
  }

  updateExpiredTime() {
    if (this.timeoutTracker) {
      clearTimeout(this.timeoutTracker);
    }
    this.timeoutTracker = setTimeout(() => {
      localStorage.setItem("_expiredTime", Date.now() + this.timeout * 1000);
    }, 300);
  }

  tracker() {
    window.addEventListener("mousemove", this.eventHandler);
    window.addEventListener("scroll", this.eventHandler);
    window.addEventListener("keydown", this.eventHandler);
  }

  cleanUp() {
    localStorage.removeItem("_expiredTime");
    clearInterval(this.interval);
    window.removeEventListener("mousemove", this.eventHandler);
    window.removeEventListener("scroll", this.eventHandler);
    window.removeEventListener("keydown", this.eventHandler);
  }
}
