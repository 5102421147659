import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import {
  onInputChange,
  submitTermsAndConditionTemplate,
  resetCreateNewTermsAndConditionTemplateForm,
  getTermsAndConditionTemplate,
  setShowCreateNewTermsAndConditionSuccessModal,
  uploadTermsAndConditionTemplate,
  deleteTermsAndConditionTemplate,
  setShowCreateNewTermsConditionFailedModal
} from "../store/termsAndConditionTemplate/actions";
import SweetAlert from "react-bootstrap-sweetalert";
import Select from "react-select";
import { getFirebaseBackend } from "../helpers/authUtils";
import MyEditor from "../components/MyEditor";
import Loader from "../components/Loader";
import AccountantVariables from "../components/Template/VariableHelper/AccountantVariables";
import { getValidator } from "../Utils";
import { businessTypes, tncTemplateTypes } from "../config";

class CreateNewTermsAndConditionTemplate extends Component {
  constructor(props) {
    super(props);
    this.userRole = getFirebaseBackend().getUserRole();
    this.cancel = this.cancel.bind(this);
    this.formatDataAndSubmitTermsAndConditionTemplate =
      this.formatDataAndSubmitTermsAndConditionTemplate.bind(this);
    this.validator = getValidator();
    this.deleteFile = this.deleteFile.bind(this);
    this.state = { file: null, fileUploadError: false };
  }

  onFileChange = (event) => {
    var parts = event.target.files[0].name.split(".");
    var ext = parts[parts.length - 1];
    if (this.props.tncTemplateType === "pdf" && ext.toLowerCase() !== "pdf") {
      event.target.files = null;
      event.target.value = "";
      this.setState({
        fileUploadError: true,
      });
    } else {
      this.setState({
        file: event.target.files[0],
        fileUploadError: false,
      });
      setTimeout(() => {
        this.props.uploadTermsAndConditionTemplate(this.state.file);
      }, 500);
    }
  };

  cancel() {
    this.props.history.goBack();
  }

  formatDataAndSubmitTermsAndConditionTemplate() {
    if (
      this.validator.fields["Template Name"] &&
      (this.userRole === "superadmin"
        ? this.validator.fields["Organisation Business Type"]
        : true) &&
      ((this.validator.fields["Template Content"] &&
        this.props.tncTemplateType === "customTemplate") ||
        (this.validator.fields["pdf Template"] &&
          this.props.tncTemplateType === "pdf"))
    ) {
      let data = {
        name: this.props.termsAndConditionTemplateName,
        type: "tnc",
        organisationBusinessType: this.props.businessType,
        template: [
          {
            type: "tncTextBlock",
            tncBlock: this.props.termsAndConditionTemplateContent,
          },
        ],
        tncTemplateType: this.props.tncTemplateType,
      };
      if (this.props.fileUrl) {
        data["templateUrl"] = this.props.fileUrl;
      }
      if (this.type === "edit") {
        data.id = this.props.termsAndConditionTemplateId;
      }
      this.props.submitTermsAndConditionTemplate(data, this.type);
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  }
  componentDidMount() {
    this.props.resetCreateNewTermsAndConditionTemplateForm();
    if (this.type === "edit") {
      this.props.getTermsAndConditionTemplate(this.props.match.params.id);
      if (this.props.tncTemplateType === "pdf") {
        // this.setState({
        // 	file: this.props.fileUrl
        // });
        console.log("file:: ", this.props.fileUrl.name);
      }
    }
  }
  componentWillUnmount() {
    this.props.resetCreateNewTermsAndConditionTemplateForm();
  }
  deleteFile() {
    this.setState({
      file: null,
    });
    this.props.deleteTermsAndConditionTemplate();
  }

  render() {
    this.type = this.props.match.path
      .replace("/terms-and-conditions/", "")
      .replace("/:id", "");
    const texts = {
      new: {
        header: "Create New Terms And Condition Template",
        submitButton: "Create Template",
        actionName: "created",
      },
      edit: {
        header: "Edit Terms And Condition Template",
        submitButton: "Update Template",
        actionName: "updated",
      },
    };
    return (
      <React.Fragment>
        {this.props.loading && <Loader />}
        <div className="container-fluid new-item-page-container">
          <div className="new-item-page-nav">
            <i
              onClick={this.cancel}
              className="ion ion-md-arrow-round-back back-button"
            ></i>
          </div>
          <div className="new-item-page-content">
            <div className="row form-row">
              <div className="col-12">
                <h3>{texts[this.type].header}</h3>
                <div className="separator mt-3" />
                <div className="p-3">
                  <div className="row fieldset">
                    <div className="col-4 text-right">
                      <label className="fieldset-label required">
                        Template Name
                      </label>
                    </div>
                    <div className="col-8">
                      <input
                        onChange={(e) =>
                          this.props.onInputChange(
                            "termsAndConditionTemplateName",
                            e.target.value
                          )
                        }
                        value={this.props.termsAndConditionTemplateName || ""}
                        className="input-text"
                        type="text"
                        placeholder="Template Name"
                      />
                      {this.validator.message(
                        "Template Name",
                        this.props.termsAndConditionTemplateName,
                        "required",
                        { className: "text-danger" }
                      )}
                    </div>
                  </div>
                  {(this.userRole === "admin" ||
                    this.userRole === "superadmin") && (
                    <div className="row fieldset">
                      <div className="col-4 text-right">
                        <label className="fieldset-label required">
                          Template Type
                        </label>
                      </div>
                      <div className="col-8">
                        <Select
                          options={tncTemplateTypes}
                          onChange={(selectedOption) => {
                            this.props.onInputChange(
                              "tncTemplateType",
                              selectedOption.value
                            );
                            this.setState({ fileUploadError: false });
                          }}
                          value={
                            this.props.tncTemplateType
                              ? tncTemplateTypes.filter(
                                  (option) =>
                                    option.value === this.props.tncTemplateType
                                )
                              : ""
                          }
                        />
                        {this.validator.message(
                          "Template Type",
                          this.props.tncTemplateType,
                          "required",
                          { className: "text-danger" }
                        )}
                      </div>
                    </div>
                  )}
                  {this.userRole === "superadmin" && (
                    <div className="row fieldset">
                      <div className="col-4 text-right">
                        <label className="fieldset-label required">
                          Organisation Business Type
                        </label>
                      </div>
                      <div className="col-8">
                        <Select
                          options={businessTypes}
                          onChange={(selectedOption) =>
                            this.props.onInputChange(
                              "businessType",
                              selectedOption.value
                            )
                          }
                          value={
                            this.props.businessType
                              ? businessTypes.filter(
                                  (option) =>
                                    option.value === this.props.businessType
                                )
                              : ""
                          }
                        />
                        {this.validator.message(
                          "Organisation Business Type",
                          this.props.businessType,
                          "required",
                          { className: "text-danger" }
                        )}
                      </div>
                    </div>
                  )}
                  {this.props.tncTemplateType === "customTemplate" && (
                    <div className="row fieldset">
                      <div className="col-12">
                        <h4 className="mt-2">Template Content</h4>
                        <div className="separator mb-3" />
                        <div className="fieldset-group helper-variables-div">
                          <label className="fieldset-group-label">
                            Variables
                          </label>
                          <AccountantVariables
                            businessType={this.props.businessType}
                          />
                        </div>
                        {((this.props.termsAndConditionTemplateId &&
                          this.type === "edit") ||
                          this.type === "new") &&
                          !this.props.loading && (
                            <MyEditor
                              value={
                                this.props.termsAndConditionTemplateContent
                              }
                              variable={"termsAndConditionTemplateContent"}
                              onChange={this.props.onInputChange}
                            />
                          )}
                        {this.validator.message(
                          "Template Content",
                          this.props.termsAndConditionTemplateContent,
                          "required",
                          { className: "text-danger" }
                        )}
                      </div>
                    </div>
                  )}
                  {this.props.tncTemplateType === "pdf" && (
                    <div className="row fieldset">
                      <div className="col-4 text-right">
                        <label className="fieldset-label">PDF Template</label>
                      </div>
                      <div className="col-8">
                        {!this.props.fileUrl && (
                          <>
                            <input
                              type="file"
                              // onChange={e => this.props.onInputChange("templateFile", e.target.files[0])}
                              onChange={this.onFileChange}
                              placeholder="Template File"
                            />
                            <br />
                            <i>
                              <small>
                                Supported filetypes are .PDF up to a file size
                                of 2MB.
                              </small>
                            </i>
                            {this.state.fileUploadError ? (
                              <p style={{ color: "red" }}>
                                Please upload a valid PDF
                              </p>
                            ) : null}
                          </>
                        )}
                        {this.validator.message(
                          "pdf Template",
                          this.props.fileUrl,
                          "required",
                          { className: "text-danger" }
                        )}
                        {this.props.fileUrl && (
                          <button onClick={this.deleteFile}>Delete</button>
                        )}
                        {this.props.tncTemplateType === "pdf" &&
                          this.props.fileUrl && (
                            <iframe
                              src={this.props.fileUrl}
                              className="pdfIframe"
                            />
                          )}
                      </div>
                    </div>
                  )}
                </div>
                <div className="separator mt-3 mb-3" />
                <div className="row fieldset">
                  <div className="col-12 text-right">
                    <button onClick={this.cancel} className="btn">
                      Cancel
                    </button>
                    <button
                      onClick={
                        this.formatDataAndSubmitTermsAndConditionTemplate
                      }
                      className="btn create-item"
                    >
                      {texts[this.type].submitButton}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {this.props.showCreateNewTermsAndConditionTemplateSuccessModal && (
          <SweetAlert
            title=""
            success
            confirmBtnBsStyle="success"
            onConfirm={() => {
              this.props.setShowCreateNewTermsAndConditionSuccessModal(false);
              this.props.history.replace("/terms-and-conditions");
            }}
          >
            <div className="mb-2 success-alert-message">
              Template <b>{this.props.termsAndConditionTemplateName || ""}</b>{" "}
              has been {texts[this.type].actionName} successfully!
            </div>
          </SweetAlert>
        )}
       {this.props.showCreateNewTermsAndConditionFailedModal && (
					<SweetAlert
					  title=""
					  danger
					  confirmBtnBsStyle="success"
					  onConfirm={() =>
						this.props.setShowCreateNewTermsConditionFailedModal(false)
					  }
					>{`Terms and Conditions with name ${this.props.termsAndConditionTemplateName} already exists!`}</SweetAlert>
				  )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => state.TermsAndConditionTemplate;

export default withRouter(
  connect(mapStateToProps, {
    onInputChange,
    submitTermsAndConditionTemplate,
    resetCreateNewTermsAndConditionTemplateForm,
    getTermsAndConditionTemplate,
    setShowCreateNewTermsAndConditionSuccessModal,
    uploadTermsAndConditionTemplate,
    deleteTermsAndConditionTemplate,
    setShowCreateNewTermsConditionFailedModal
  })(CreateNewTermsAndConditionTemplate)
);
