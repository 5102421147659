import React, { useState, useEffect, useRef } from "react";
import { Modal, ModalBody } from "reactstrap";
import Select, { getOptionValue } from "react-select";
import { loadScript, isAddressEmpty, formatAddress } from '../Utils';
import { countryOptions } from '../config';

const OrganisationAddress = props => {


  const [modalOpen, setModalOpen] = useState(false);
  const autoCompleteRef = useRef(null);
  let autoComplete;
  const [values, setValues] = useState(props);

  function onInputChange(field, value) {
    let keyPair = {
      [`${props.addressPrefix}AddressSearch`]: 'addressSearch',
      [`${props.addressPrefix}Premises`]: 'premises',
      [`${props.addressPrefix}AddressLine1`]: 'addressLine1',
      [`${props.addressPrefix}AddressLine2`]: 'addressLine2',
      [`${props.addressPrefix}Locality`]: 'locality',
      [`${props.addressPrefix}Region`]: 'region',
      [`${props.addressPrefix}Country`]: 'country',
      [`${props.addressPrefix}Postcode`]: 'postcode',
    }
    setValues(c => ({...c, [field]: value, [keyPair[field]]: value}))
  }

  function handleScriptLoad() {
    const observerHack = new MutationObserver(function () {
      observerHack.disconnect();
      autoCompleteRef.current.setAttribute("autocomplete", `new-address-${new Date().getTime()}`);
    });
    observerHack.observe(autoCompleteRef.current, {
      attributes: true,
      attributeFilter: ['autocomplete']
    });
    autoComplete = new window.google.maps.places.Autocomplete(
      autoCompleteRef.current,
      { types: ["geocode", "establishment"], componentRestrictions: { country: "uk" } }
    );
    autoCompleteRef.current.focus();
    autoComplete.setFields(["address_components", "formatted_address"]);
    autoComplete.addListener("place_changed", () =>
      handlePlaceSelect()
    );
  }

  async function handlePlaceSelect() {
    clearAddress()
    const addressObject = autoComplete.getPlace();
    if (addressObject.hasOwnProperty("address_components")) {
      let address = "";
      for (let i = addressObject.address_components.length - 1; i >= 0; i--) {
        let comp = addressObject.address_components[i];
        if (comp.types.includes("street_number") || comp.types.includes("premise")) {
          onInputChange(`${props.addressPrefix}Premises`, comp.long_name);
        }
        else if (comp.types.includes("postal_code")) {
          onInputChange(`${props.addressPrefix}Postcode`, comp.long_name);
        }
        else if (comp.types.includes("country")) {
          onInputChange(`${props.addressPrefix}Country`, comp.long_name);
        }
        else if (comp.types.includes("administrative_area_level_2")) {
          onInputChange(`${props.addressPrefix}Region`, comp.long_name);
        }
        else if (comp.types.includes("locality")) {
          onInputChange(`${props.addressPrefix}AddressLine1`, comp.long_name);
        }
        else if (comp.types.includes("route")) {
          if (props[`${props.addressPrefix}AddressLine1`]?.trim().length > 0) {
            onInputChange(`${props.addressPrefix}AddressLine2`, comp.long_name);
          } else {
            onInputChange(`${props.addressPrefix}AddressLine1`, comp.long_name);
          }
        }
        else if (comp.types.includes("postal_town")) {
          onInputChange(`${props.addressPrefix}Locality`, comp.long_name);
        }
      }
      for (let i = addressObject.address_components.length - 1; i >= 0; i--) {
        let arr = [
          'street_number',
          'premise',
          'postal_code',
          'country',
          'administrative_area_level_2',
          'locality',
          'route',
          'postal_town'
        ];
        let isThere = arr.some(c => comp.types.includes(c));
        if (isThere) continue;
        let comp = addressObject.address_components[i];
        address = `${comp.long_name}, ${address}`;
      }
      address = address.slice(0, -2);
      if (address.trim().length > 0) onInputChange(`${props.addressPrefix}AddressLine2`, address);
      onInputChange(`${props.addressPrefix}AddressSearch`, "");
    }
  }

  function toggleWithUpdate() {
    Object.keys(values).forEach(key => {
      if(key.startsWith(`${props.addressPrefix}`)) {
        props.onInputChange(key, values[key]);
      }
    })
    toggleModal();
  }
  function toggleModal() {
    setModalOpen(prevState => !prevState);
  }

  useEffect(() => {
    if (modalOpen) {
      setValues(props);
      setTimeout(() => handleScriptLoad(), 1000);
    }
  }, [modalOpen]);

  function clearAddress() {
    onInputChange(`${props.addressPrefix}Premises`, "");
    onInputChange(`${props.addressPrefix}AddressLine1`, "");
    onInputChange(`${props.addressPrefix}AddressLine2`, "");
    onInputChange(`${props.addressPrefix}Locality`, "");//
    onInputChange(`${props.addressPrefix}Region`, "");//
    onInputChange(`${props.addressPrefix}Country`, "");//
    onInputChange(`${props.addressPrefix}Postcode`, "");//
    onInputChange(`${props.addressPrefix}AddressSearch`, "")
  }

  function cancel() {
    this.props.setActiveStep(1);
    this.props.history.goBack();
  }

  const isAddressBlank = isAddressEmpty(props.premises, props.addressLine1, props.addressLine2, props.locality, props.region, props.country, props.postcode);

  let formattedAddress = formatAddress(props.premises, props.addressLine1, props.addressLine2, props.locality, props.region, props.country, props.postcode);

  return (
    <React.Fragment>
      <div
        onClick={props.readOnly ? null : toggleModal}
        className={`input-text address-input ${props.readOnly ? "read-only" : ""} ${isAddressBlank ? "placeholder-input-div" : ""}`}
      >
        {isAddressBlank ? props.addressHeader : formattedAddress}
      </div>
      {!props.readOnly && <Modal isOpen={modalOpen} toggle={toggleModal} size="lg" className="address-modal">
        <ModalBody>
          <div className="fieldset-group">
            <label className="fieldset-group-label">{props.addressHeader}</label>
            <div className="row fieldset address-search">
              <div className="col-4 text-right">
                <label className="fieldset-label">Search Address</label>
              </div>
              <div className="col-8">
                <input className="input-text" type="text" autoComplete="femvkefkvj" name="addressSearch" placeholder="Search Address"
                  ref={autoCompleteRef}
                  onChange={e => onInputChange(`${props.addressPrefix}AddressSearch`, e.target.value)}
                  value={values.addressSearch || ""}
                />
              </div>
            </div>
            <div className="row fieldset">
              <div className="col-4 text-right">
                <label className="fieldset-label">Premises</label>
              </div>
              <div className="col-8">
                <input value={values.premises || ""} onChange={e => onInputChange(`${props.addressPrefix}Premises`, e.target.value)} className="input-text" type="text" placeholder="Premises" />
              </div>
            </div>
            <div className="row fieldset">
              <div className="col-4 text-right">
                <label className="fieldset-label">Address Line 1</label>
              </div>
              <div className="col-8">
                <input value={values.addressLine1 || ""} onChange={e => onInputChange(`${props.addressPrefix}AddressLine1`, e.target.value)} className="input-text" type="text" placeholder="Address Line 1" />
              </div>
            </div>
            <div className="row fieldset">
              <div className="col-4 text-right">
                <label className="fieldset-label">Address Line 2</label>
              </div>
              <div className="col-8">
                <input value={values.addressLine2 || ""} onChange={e => onInputChange(`${props.addressPrefix}AddressLine2`, e.target.value)} className="input-text" type="text" placeholder="Address Line 2" />
              </div>
            </div>
            <div className="row fieldset">
              <div className="col-4 text-right">
                <label className="fieldset-label">Locality (Town/City)</label>
              </div>
              <div className="col-8">
                <input value={values.locality || ""} onChange={e => onInputChange(`${props.addressPrefix}Locality`, e.target.value)} className="input-text" type="text" placeholder="Locality" />
              </div>
            </div>
            <div className="row fieldset">
              <div className="col-4 text-right">
                <label className="fieldset-label">Region (County/State)</label>
              </div>
              <div className="col-8">
                <input value={values.region || ""} onChange={e => onInputChange(`${props.addressPrefix}Region`, e.target.value)} className="input-text" type="text" placeholder="Region" />
              </div>
            </div>
            <div className="row fieldset">
              <div className="col-4 text-right">
                <label className="fieldset-label">Country</label>
              </div>
              <div className="col-8">
                {/*<input value={props.country || ""} onChange={e => props.onInputChange(`${props.addressPrefix}Country`, e.target.value)} className="input-text" type="text" placeholder="Country" />*/}
                <Select
                  options={countryOptions}
                  value={values.country ? countryOptions.filter(option => option.value === values.country) : ""}
                  onChange={selectedOption => onInputChange(`${props.addressPrefix}Country`, selectedOption.value)}
                />
              </div>
            </div>
            <div className="row fieldset">
              <div className="col-4 text-right">
                <label className="fieldset-label">Postcode</label>
              </div>
              <div className="col-8">
                <input value={values.postcode || ""} onChange={e => onInputChange(`${props.addressPrefix}Postcode`, e.target.value)} className="input-text" type="text" placeholder="Postcode" />
              </div>
            </div>
          </div>
          <div className="text-right">
            <button onClick={toggleModal} className="btn">Cancel</button>
            <button onClick={clearAddress} className="btn">Clear Address</button>
            <button onClick={toggleWithUpdate} className="btn btn-success ml-2">OK</button>
          </div>
        </ModalBody>
      </Modal>}
    </React.Fragment>
  );
}

export default OrganisationAddress;
