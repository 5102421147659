import { takeEvery, fork, put, all, call } from "redux-saga/effects";
import axios from "axios";

import {
  GET_CONTRACTS,
  GET_CONTRACT,
  DOWNLOAD_CONTRACT,
  PREVIEW_CONTRACT,
  SEND_CONTRACT,
  SAVE_CONTRACT_AS_DRAFT,
  GET_SIGNATURE_BLOCKS,
  REVIEW_CONTRACT,
  GET_TNC_TEMPLATES,
  SEARCH_CONTRACT,
  SEND_CONTRACT_CLIENT,
  SAVE_CONTRACT_AS_DRAFT_CLIENT,
  REVIEW_CONTRACT_CLIENT,
} from "./actionTypes";

import {
  getContractsSuccess,
  getContractSuccess,
  downloadContractSuccess,
  previewContractSuccess,
  saveContractAsDraftSuccess,
  sendContractSuccess,
  getSignatureBlocksSuccess,
  reviewContractSuccess,
  getTncTemplatesSuccess,
  searchContractSuccess,
  getContractStatus,
  getContractPdf,
  sendContractSuccessClient,
  saveContractAsDraftClientSuccess,
  reviewContractClientSuccess,
} from "./actions";
import { setShowApiErrorModal } from "../common/actions";

// AUTH related methods
import { getFirebaseBackend } from "../../helpers/authUtils";

const fireBaseBackend = getFirebaseBackend();

function* getContracts({ payload: data }) {
  try {
    const accessToken = yield fireBaseBackend.getAccessToken();
    const response = yield axios({
      method: "GET",
      url: `contracts?page_num=${data.pageNum}&status=${data.status}&filter=${data.filter}`,
      headers: { authorization: `Bearer ${accessToken}` },
    });
    if (response.data.success) {
      console.log("get constrac tdata :: ", response.data);
      yield put(getContractsSuccess(response.data.result));
    } else {
      // yield put(createOrganisationFailed());
    }
  } catch (error) {
    yield put(setShowApiErrorModal());
    console.log(error);
    // if (error.response && error.response.data && error.response.data.error) {
    //     yield put(loginError(error.response.data.error));
    // } else {
    //     yield put(loginError("Something went Wrong!"));
    // }
    // yield put(createOrganisationFailed());
  }
}

function* getContract({ payload: id }) {
  try {
    const accessToken = yield fireBaseBackend.getAccessToken();
    const response = yield axios({
      method: "GET",
      url: `contract/${id}`,
      headers: { authorization: `Bearer ${accessToken}` },
    });
    if (response.data.success) {
      yield put(getContractSuccess(response.data.result));
      yield put(getContractStatus(response));
      yield put(getContractPdf(response));
    } else {
      // yield put(createOrganisationFailed());
    }
  } catch (error) {
    yield put(setShowApiErrorModal());
    console.log(error);
    // if (error.response && error.response.data && error.response.data.error) {
    //     yield put(loginError(error.response.data.error));
    // } else {
    //     yield put(loginError("Something went Wrong!"));
    // }
    // yield put(createOrganisationFailed());
  }
}

function* downloadContract({ payload: id }) {
  try {
    const accessToken = yield fireBaseBackend.getAccessToken();
    const response = yield axios({
      method: "GET",
      url: `contract/download/${id}`,
      headers: { authorization: `Bearer ${accessToken}` },
      responseType: "blob",
    });
    yield put(downloadContractSuccess());
    let headers = response.headers;
    console.log(response.data);
    let blob = new Blob([response.data]);
    console.log(blob);
    let link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = `Contract-${id}.zip`;
    link.click();
  } catch (error) {
    yield put(setShowApiErrorModal());
    console.log(error);
    // if (error.response && error.response.data && error.response.data.error) {
    //     yield put(loginError(error.response.data.error));
    // } else {
    //     yield put(loginError("Something went Wrong!"));
    // }
    // yield put(createOrganisationFailed());
  }
}

function* getSignatureBlocks({ payload: id }) {
  try {
    const accessToken = yield fireBaseBackend.getAccessToken();
    const response = yield axios({
      method: "GET",
      url: `template/${id}/signature-blocks`,
      headers: { authorization: `Bearer ${accessToken}` },
    });
    if (response.data.success) {
      yield put(getSignatureBlocksSuccess(response.data.result));
    } else {
      // yield put(createOrganisationFailed());
    }
  } catch (error) {
    yield put(setShowApiErrorModal());
    // if (error.response && error.response.data && error.response.data.error) {
    //     yield put(loginError(error.response.data.error));
    // } else {
    //     yield put(loginError("Something went Wrong!"));
    // }
    // yield put(createOrganisationFailed());
  }
}

function* getTncTemplates() {
  try {
    const accessToken = yield fireBaseBackend.getAccessToken();
    const response = yield axios({
      method: "GET",
      url: `tnc-templates`,
      headers: { authorization: `Bearer ${accessToken}` },
    });
    if (response.data.success) {
      yield put(getTncTemplatesSuccess(response.data.result));
    } else {
      // yield put(createOrganisationFailed());
    }
  } catch (error) {
    yield put(setShowApiErrorModal());
    // if (error.response && error.response.data && error.response.data.error) {
    //     yield put(loginError(error.response.data.error));
    // } else {
    //     yield put(loginError("Something went Wrong!"));
    // }
    // yield put(createOrganisationFailed());
  }
}

function* previewContract({ payload: data }) {
  try {
    const accessToken = yield fireBaseBackend.getAccessToken();
    const response = yield axios({
      method: "POST",
      url: `preview-contract`,
      headers: { authorization: `Bearer ${accessToken}` },
      data,
    });
    if (response.data.success) {
      yield put(previewContractSuccess(response.data.result));
    } else {
      // yield put(createOrganisationFailed());
    }
  } catch (error) {
    yield put(setShowApiErrorModal());
    // if (error.response && error.response.data && error.response.data.error) {
    //     yield put(loginError(error.response.data.error));
    // } else {
    //     yield put(loginError("Something went Wrong!"));
    // }
    // yield put(createOrganisationFailed());
  }
}

function* sendContract({ payload: data }) {
  try {
    const accessToken = yield fireBaseBackend.getAccessToken();
    const response = yield axios({
      method: "POST",
      url: `send-contract`,
      headers: { authorization: `Bearer ${accessToken}` },
      data,
    });
    if (response.data.success) {
      yield put(sendContractSuccess());
    } else {
      // yield put(createOrganisationFailed());
    }
  } catch (error) {
    yield put(setShowApiErrorModal());
    // if (error.response && error.response.data && error.response.data.error) {
    //     yield put(loginError(error.response.data.error));
    // } else {
    //     yield put(loginError("Something went Wrong!"));
    // }
    // yield put(createOrganisationFailed());
  }
}

function* saveContractAsDraft({ payload: data }) {
  try {
    const accessToken = yield fireBaseBackend.getAccessToken();
    const response = yield axios({
      method: "POST",
      url: `save-contract-as-draft`,
      headers: { authorization: `Bearer ${accessToken}` },
      data,
    });
    if (response.data.success) {
      yield put(saveContractAsDraftSuccess());
    } else {
      // yield put(createOrganisationFailed());
    }
  } catch (error) {
    yield put(setShowApiErrorModal());
    // if (error.response && error.response.data && error.response.data.error) {
    //     yield put(loginError(error.response.data.error));
    // } else {
    //     yield put(loginError("Something went Wrong!"));
    // }
    // yield put(createOrganisationFailed());
  }
}

function* reviewContract({ payload: data }) {
  try {
    const accessToken = yield fireBaseBackend.getAccessToken();
    const response = yield axios({
      method: "POST",
      url: `review-contract`,
      headers: { authorization: `Bearer ${accessToken}` },
      data,
    });
    if (response.data.success) {
      yield put(reviewContractSuccess(response.data.result));
    } else {
      // yield put(createOrganisationFailed());
    }
  } catch (error) {
    yield put(setShowApiErrorModal());
    // if (error.response && error.response.data && error.response.data.error) {
    //     yield put(loginError(error.response.data.error));
    // } else {
    //     yield put(loginError("Something went Wrong!"));
    // }
    // yield put(createOrganisationFailed());
  }
}

function* reviewContractClient({ payload: data }) {
  try {
    const response = yield axios({
      method: "POST",
      url: `review-contract-client`,
      data,
    });
    if (response.data.success) {
      yield put(reviewContractClientSuccess(response.data.result));
    } else {
      // yield put(createOrganisationFailed());
    }
  } catch (error) {
    yield put(setShowApiErrorModal());
    // if (error.response && error.response.data && error.response.data.error) {
    //     yield put(loginError(error.response.data.error));
    // } else {
    //     yield put(loginError("Something went Wrong!"));
    // }
    // yield put(createOrganisationFailed());
  }
}

function* searchContract({ payload: query }) {
  try {
    const accessToken = yield fireBaseBackend.getAccessToken();
    const response = yield axios({
      method: "GET",
      url: `search/contract?query=${query}`,
      headers: { authorization: `Bearer ${accessToken}` },
    });
    if (response.data.success) {
      console.log(response.data);
      yield put(searchContractSuccess(response.data.result));
    } else {
      // yield put(createOrganisationFailed());
    }
  } catch (error) {
    yield put(setShowApiErrorModal());
    console.log(error);
    // if (error.response && error.response.data && error.response.data.error) {
    //     yield put(loginError(error.response.data.error));
    // } else {
    //     yield put(loginError("Something went Wrong!"));
    // }
    // yield put(createOrganisationFailed());
  }
}

export function* watchGetContracts() {
  yield takeEvery(GET_CONTRACTS, getContracts);
}

export function* watchGetContract() {
  yield takeEvery(GET_CONTRACT, getContract);
}

export function* watchDownloadContract() {
  yield takeEvery(DOWNLOAD_CONTRACT, downloadContract);
}

export function* watchPreviewContract() {
  yield takeEvery(PREVIEW_CONTRACT, previewContract);
}

export function* watchSendContract() {
  yield takeEvery(SEND_CONTRACT, sendContract);
}

export function* watchSaveContractAsDraft() {
  yield takeEvery(SAVE_CONTRACT_AS_DRAFT, saveContractAsDraft);
}

export function* watchGetSignatureBlocks() {
  yield takeEvery(GET_SIGNATURE_BLOCKS, getSignatureBlocks);
}

export function* watchReviewContract() {
  yield takeEvery(REVIEW_CONTRACT, reviewContract);
}

export function* watchReviewContractClient() {
  yield takeEvery(REVIEW_CONTRACT_CLIENT, reviewContractClient);
}

export function* watchGetTncTemplates() {
  yield takeEvery(GET_TNC_TEMPLATES, getTncTemplates);
}

export function* watchSearchContract() {
  yield takeEvery(SEARCH_CONTRACT, searchContract);
}

export function* watchSendContractClient() {
  yield takeEvery(SEND_CONTRACT_CLIENT, sendContractClient);
}

function* sendContractClient({ payload: data }) {
  try {
    const response = yield axios({
      method: "POST",
      url: `send-contract-client`,
      data,
    });
    if (response.data.success) {
      yield put(
        sendContractSuccessClient(response.data?.signEasyContractUrl ?? "")
      );
    } else {
      // yield put(createOrganisationFailed());
    }
  } catch (error) {
    yield put(setShowApiErrorModal());
    // if (error.response && error.response.data && error.response.data.error) {
    //     yield put(loginError(error.response.data.error));
    // } else {
    //     yield put(loginError("Something went Wrong!"));
    // }
    // yield put(createOrganisationFailed());
  }
}

export function* watchSaveContractAsDraftClient() {
  yield takeEvery(SAVE_CONTRACT_AS_DRAFT_CLIENT, saveContractAsDraftClient);
}

function* saveContractAsDraftClient({ payload: data }) {
  try {
    const response = yield axios({
      method: "POST",
      url: `save-contract-as-draft-client`,
      data,
    });
    if (response.data.success) {
      yield put(saveContractAsDraftClientSuccess());
    } else {
      // yield put(createOrganisationFailed());
    }
  } catch (error) {
    yield put(setShowApiErrorModal());
    // if (error.response && error.response.data && error.response.data.error) {
    //     yield put(loginError(error.response.data.error));
    // } else {
    //     yield put(loginError("Something went Wrong!"));
    // }
    // yield put(createOrganisationFailed());
  }
}

function* contractSaga() {
  yield all([
    fork(watchGetContracts),
    fork(watchGetContract),
    fork(watchDownloadContract),
    fork(watchPreviewContract),
    fork(watchSendContract),
    fork(watchSaveContractAsDraft),
    fork(watchGetSignatureBlocks),
    fork(watchReviewContract),
    fork(watchGetTncTemplates),
    fork(watchSearchContract),
    fork(watchSendContractClient),
    fork(watchSaveContractAsDraftClient),
    fork(watchReviewContractClient),
  ]);
}

export default contractSaga;
