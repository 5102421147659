import React from 'react';
import {
  Modal, ModalBody,
  TabContent, TabPane,
  InputGroup, InputGroupAddon, InputGroupText, Input
} from "reactstrap";
import { AvForm, AvField, AvGroup, AvInput } from "availity-reactstrap-validation";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import StepperWidget from "./StepperWidget";

export default (props) => {
	return (
		<Modal isOpen={props.isOpen} toggle={props.toggle} size="lg">
          <AvForm
            className="form-horizontal"
            onValidSubmit={props.onServiceSubmit}
          >
            <ModalBody>
              <h4>Create New Service</h4>
              <StepperWidget onClick={props.setActiveStep} activeStep={props.activeStep} steps={["Basic Information", "Description", "Pricing Drivers", "Pricing Formula"]} />
              <div className="create-service-content">
                <TabContent activeTab={props.activeStep}>
                  <TabPane tabId={1} className="p-3">
                    <div className="row">
                      <div className="col-6">
                        <div className="form-group">
                          <AvField
                            name="serviceName"
                            label="Service Name"
                            className="form-control"
                            value=""
                            placeholder="Enter New Service Name"
                            type="text"
                            required
                          />
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="form-group">
                          <AvField
                            name="serviceCategory"
                            label="Service Category"
                            className="form-control"
                            value=""
                            placeholder="Select Service Category"
                            type="select"
                            required
                          >
                            <option>Bookkeeping Services</option>
                            <option>Compliance Services</option>
                          </AvField>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-6">
                        <AvField
                          name="serviceChargetype"
                          label="Service Charge Type"
                          className="form-control"
                          value=""
                          placeholder="Select Service Charge Type"
                          type="select"
                          required
                        >
                          <option value="recurring">Recurring</option>
                          <option value="oneoff">One-Off</option>
                        </AvField>
                      </div>
                      <div className="col-6">
                        <AvField
                          name="pricingType"
                          label="Pricing Type"
                          className="form-control"
                          value=""
                          placeholder="Select Pricing Type"
                          type="select"
                          required
                        >
                          <option value="fixed">Fixed Price</option>
                          <option value="calculative">Formula Based Pricing</option>
                        </AvField>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12">
                        <AvField
                          name="servicePrice"
                          label="Price"
                          className="form-control"
                          value=""
                          placeholder="Enter Price"
                          type="text"
                          required
                        />
                      </div>
                    </div>
                  </TabPane>
                  <TabPane tabId={2} className="p-3">
                    <div className="row">
                      <div className="col-12 variable-header-row"><div className="flex1">Variable 1</div><button className="btn btn-sm btn-dark"><i className="ion ion-md-trash" /></button></div>
                      <div className="col-6">
                        <AvField
                          name="servicePricingFieldName[]"
                          className="form-control"
                          value=""
                          placeholder="Enter Variable Name"
                          type="text"
                          required
                        />
                      </div>
                      <div className="col-6">
                        <AvField
                          name="servicePricingFieldType[]"
                          className="form-control"
                          value=""
                          placeholder="Select Variable Type"
                          type="select"
                          required
                        >
                          <option>Quantity</option>
                          <option>Number Range</option>
                          <option>Variation</option>
                        </AvField>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12 variable-header-row"><div className="flex1">Variable 2</div><button className="btn btn-sm btn-dark"><i className="ion ion-md-trash" /></button></div>
                      <div className="col-6">
                        <AvField
                          name="servicePricingFieldName[]"
                          className="form-control"
                          value=""
                          placeholder="Enter Variable Name"
                          type="text"
                          required
                        />
                      </div>
                      <div className="col-6">
                        <AvField
                          name="servicePricingFieldType[]"
                          className="form-control"
                          value=""
                          placeholder="Select Variable Type"
                          type="select"
                          required
                        >
                          <option>Quantity</option>
                          <option>Number Range</option>
                          <option>Variation</option>
                        </AvField>
                      </div>
                      <div className="col-6">
                        <AvField
                          name="servicePricingFieldName[]"
                          className="form-control"
                          value=""
                          placeholder="Enter Variable Name"
                          type="text"
                          required
                        />
                      </div>
                      <div className="col-6">
                        <AvField
                          name="servicePricingFieldName[]"
                          className="form-control"
                          value=""
                          placeholder="Enter Variable Name"
                          type="text"
                          required
                        />
                      </div>
                      <div className="col-12 variation-row">
                        <AvField
                          name="servicePricingFieldName[]"
                          className="form-control"
                          value=""
                          placeholder="Enter Variable Name"
                          type="text"
                          required
                        />
                        <AvField
                          name="servicePricingFieldName[]"
                          className="form-control"
                          value=""
                          placeholder="Enter Variable Name"
                          type="text"
                          required
                        />
                        <button className="btn btn-sm btn-dark delete-variation-btn"><i className="ion ion-md-trash" /></button>
                      </div>
                      <div className="col-12 variation-row">
                        <AvField
                          name="servicePricingFieldName[]"
                          className="form-control"
                          value=""
                          placeholder="Enter Variable Name"
                          type="text"
                          required
                        />
                        <AvField
                          name="servicePricingFieldName[]"
                          className="form-control"
                          value=""
                          placeholder="Enter Variable Name"
                          type="text"
                          required
                        />
                        <button className="btn btn-sm btn-dark delete-variation-btn"><i className="ion ion-md-trash" /></button>
                      </div>
                      <div className="col-12 variation-row">
                        <AvField
                          name="servicePricingFieldName[]"
                          className="form-control"
                          value=""
                          placeholder="Enter Variable Name"
                          type="text"
                          required
                        />
                        <AvField
                          name="servicePricingFieldName[]"
                          className="form-control"
                          value=""
                          placeholder="Enter Variable Name"
                          type="text"
                          required
                        />
                        <button className="btn btn-sm btn-dark delete-variation-btn"><i className="ion ion-md-trash" /></button>
                      </div>
                      <div className="col-12">
                        <button className="addBtn btn btn-secondary">Add Variation</button>
                      </div>
                    </div>


                    <div className="row">
                      <div className="col-12 variable-header-row"><div className="flex1">Variable 3</div><button className="btn btn-sm btn-dark"><i className="ion ion-md-trash" /></button></div>
                      <div className="col-6">
                        <AvField
                          name="servicePricingFieldName[]"
                          className="form-control"
                          value=""
                          placeholder="Enter Variable Name"
                          type="text"
                          required
                        />
                      </div>
                      <div className="col-6">
                        <AvField
                          name="servicePricingFieldType[]"
                          className="form-control"
                          value=""
                          placeholder="Select Variable Type"
                          type="select"
                          required
                        >
                          <option>Quantity</option>
                          <option>Number Range</option>
                          <option>Variation</option>
                        </AvField>
                      </div>
                      <div className="col-12 number-range-row">
                        <AvGroup className="input-group">
                          <InputGroup>
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                From
                              </InputGroupText>
                            </InputGroupAddon>
                            <AvInput name="field" required />
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                To
                              </InputGroupText>
                            </InputGroupAddon>
                            <AvInput name="field" required />
                          </InputGroup>
                        </AvGroup>
                        <AvGroup className="input-group">
                          <InputGroup>
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                £
                              </InputGroupText>
                            </InputGroupAddon>
                            <AvInput placeholder="Price per unit" name="field" required />
                          </InputGroup>
                        </AvGroup>
                        <button className="btn btn-sm btn-dark delete-number-range-btn"><i className="ion ion-md-trash" /></button>
                      </div>

                      <div className="col-12 number-range-row">
                        <AvGroup className="input-group">
                          <InputGroup>
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                From
                              </InputGroupText>
                            </InputGroupAddon>
                            <AvInput name="field" required />
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                To
                              </InputGroupText>
                            </InputGroupAddon>
                            <AvInput name="field" required />
                          </InputGroup>
                        </AvGroup>
                        <AvGroup className="input-group">
                          <InputGroup>
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                £
                              </InputGroupText>
                            </InputGroupAddon>
                            <AvInput placeholder="Price per unit" name="field" required />
                          </InputGroup>
                        </AvGroup>
                        <button className="btn btn-sm btn-dark delete-number-range-btn"><i className="ion ion-md-trash" /></button>
                      </div>

                      <div className="col-12 number-range-row">
                        <AvGroup className="input-group">
                          <InputGroup>
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                From
                              </InputGroupText>
                            </InputGroupAddon>
                            <AvInput name="field" required />
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                To
                              </InputGroupText>
                            </InputGroupAddon>
                            <AvInput name="field" required />
                          </InputGroup>
                        </AvGroup>
                        <AvGroup className="input-group">
                          <InputGroup>
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                £
                              </InputGroupText>
                            </InputGroupAddon>
                            <AvInput placeholder="Price per unit" name="field" required />
                          </InputGroup>
                        </AvGroup>
                        <button className="btn btn-sm btn-dark delete-number-range-btn"><i className="ion ion-md-trash" /></button>
                      </div>
                      <div className="col-12">
                        <button className="addBtn btn btn-secondary">Add Range</button>
                      </div>
                    </div>
                    <hr />
                    <div className="row">
                      <div className="col-12">
                        <button className="btn btn-secondary">Add Variable</button>
                      </div>
                    </div>
                  </TabPane>
                  <TabPane tabId={3}>
                    <div className="row">
                      <div className="col-12">
                        <label>Description</label>
                        <Editor
                          toolbarClassName="toolbarClassName"
                          wrapperClassName="wrapperClassName"
                          editorClassName="editorClassName"
                        />
                      </div>
                    </div>
                  </TabPane>
                  <TabPane tabId={4}>
                    <div className="row">
                      <div className="col-12">
                        <label>Pricing Formula</label>
                        <div className="custom-formula-block">

                        </div>
                      </div>
                    </div>
                  </TabPane>
                </TabContent>
              </div>
              <div className="create-service-footer">
                <div className="flex1">Step 1 of 4</div>
                <button className="btn btn-secondary ml-2">Previous</button>
                <button className="btn btn-dark ml-2">Next</button>
                <button className="btn btn-success ml-2">Save Service</button>
              </div>
            </ModalBody>
          </AvForm>
        </Modal>
	);
}