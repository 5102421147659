import {
  GET_AUDIT_TRAILS,
  GET_AUDIT_TRAILS_SUCCESS,
  LOAD_MORE_AUDIT_TRAILS,
  LOAD_MORE_AUDIT_TRAILS_SUCCESS,
} from "./actionTypes";

const initialState = {
  loading: false,
  auditTrails: [],
  totalCount: 0,
};

const auditTrails = (state = initialState, action) => {
  switch (action.type) {
    case GET_AUDIT_TRAILS:
      return { ...state, loading: true };
    case GET_AUDIT_TRAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        auditTrails: action.payload.auditTrails,
        totalCount: action.payload.totalCount,
      };
    case LOAD_MORE_AUDIT_TRAILS:
      return { ...state, loading: true };
    case LOAD_MORE_AUDIT_TRAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        auditTrails: [...state.auditTrails, ...action.payload],
      };
    default:
      return state;
  }
};

export default auditTrails;
