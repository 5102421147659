import React, { Component } from "react";
import { Row, Col, Card, CardBody, Alert } from "reactstrap";

// Redux
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation";

import Loader from "../../components/Loader";
// actions
import { activateUser, validateUserActivateToken } from "../../store/actions";

// import images
import logoSm from "../../assets/images/logo-sm.png";

class Activate extends Component {
  constructor(props) {
    super(props);
    this.state = {};

    this.handleValidSubmit = this.handleValidSubmit.bind(this);
    this.validateToken = this.validateToken.bind(this);
    this.validateToken();
  }

  // handleValidSubmit
  handleValidSubmit(event, values) {
    const params = new URLSearchParams(window.location.search);
    this.props.activateUser(
      values.password,
      values.confirmPassword,
      params.get("token"),
      this.props.history
    );
  }

  validateToken() {
    const params = new URLSearchParams(window.location.search);
    this.props.validateUserActivateToken(params.get("token"));
  }

  render() {
    return !this.props.validateTokenLoading ? (
      <React.Fragment>
        <div className="home-btn d-none d-sm-block">
          <Link to="/" className="text-dark">
            <i className="fas fa-home h2"></i>
          </Link>
        </div>
        <div className="account-pages my-5 pt-5">
          <div className="container">
            <Row className="justify-content-center">
              <Col md={8} lg={6} xl={5}>
                <div className="position-relative">
                  {this.props.loading ? <Loader /> : null}
                  {this.props.tokenExpired ? (
                    <Card className="overflow-hidden">
                      <CardBody className="p-4">
                        <div className="p-3">
                          <h4 className="mt-4 text-center">Link Expired!</h4>
                        </div>
                      </CardBody>
                    </Card>
                  ) : (
                    <Card className="overflow-hidden">
                      <div className="bg-primary">
                        <div className="text-primary text-center p-4">
                          <h5 className="text-white font-size-20">
                            Activate your Account
                          </h5>
                          <p className="text-white-50">
                            Activate your Outbooks Account
                          </p>
                          <Link to="/" className="logo logo-admin">
                            <img src={logoSm} height="24" alt="logo" />
                          </Link>
                        </div>
                      </div>

                      <CardBody className="p-4">
                        <div className="p-3">
                          <div className="mt-4 text-center">
                            Your password needs to contain at least 8
                            characters, including one or more numbers
                          </div>
                          <AvForm
                            className="form-horizontal mt-4"
                            onValidSubmit={this.handleValidSubmit}
                          >
                            {this.props.activationError ? (
                              <Alert color="danger">
                                {this.props.activationError}
                              </Alert>
                            ) : null}
                            <div className="form-group">
                              <AvField
                                name="password"
                                label="Create Password"
                                type="password"
                                required
                                value=""
                                placeholder="Enter Password"
                              />
                            </div>
                            <div className="form-group">
                              <AvField
                                name="confirmPassword"
                                label="Confirm Password"
                                type="password"
                                required
                                value=""
                                placeholder="Confirm Password"
                              />
                            </div>

                            <Row className="form-group">
                              <Col sm={12} className="text-right">
                                <button
                                  className="btn btn-primary w-md waves-effect waves-light btn-block"
                                  type="submit"
                                >
                                  Activate your Account
                                </button>
                              </Col>
                            </Row>
                          </AvForm>
                        </div>
                      </CardBody>
                    </Card>
                  )}
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </React.Fragment>
    ) : (
      <Loader />
    );
  }
}

const mapStatetoProps = (state) => {
  const { activationError, loading, validateTokenLoading, tokenExpired } =
    state.ActivateAccount;
  return { activationError, loading, validateTokenLoading, tokenExpired };
};

export default withRouter(
  connect(mapStatetoProps, { activateUser, validateUserActivateToken })(
    Activate
  )
);
