import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Badge } from 'reactstrap';
import Paginator from "./Paginator";
import { getFirebaseBackend } from "../helpers/authUtils";
import { getUsers, setShowEditUserModal, updateUser } from '../store/user/actions'
import Switch from "react-switch";

class UsersTable extends Component {
	constructor(props) {
		super(props)
		this.renderUsers = this.renderUsers.bind(this)
		this.userRole = getFirebaseBackend().getUserRole();
	}

	renderSuperAdminRoles(roles) {
		let jsx = [];
		if (roles) {
			for (let role of roles) {
				jsx.push(<div key={`${role.organisation._id}-${role.role._id}`}><Badge color="dark">{role.organisation.trading_business_name}: {role.role.role_name}</Badge></div>)
			}
		}
		return jsx;
	}

	changeUserStatus(user){
		user['active'] = !user['active']
		this.props.updateUser(user)
	}

	renderUsers(item, index) {
		
		return (
			<tr key={index}>
				<td>{item.first_name} {item.last_name}</td>
				<td>{item.email}</td>
				<td>{this.userRole==="superadmin" ? this.renderSuperAdminRoles(item.roles) : item.role_name}</td>
				{this.userRole=="superadmin" && <td>
				<div style={{display:"-ms-inline-grid"}}>
			<span>	{item.active ? 'Active' : 'Inactive'}</span>
				
			<span style={{marginLeft:"10px"}}>	<Switch onChange={() => this.changeUserStatus(item)} checked={item.active} height={20} width={45} offColor={'#ff0000'}/>
			</span>
			</div>
				</td>}
				{this.userRole!=="superadmin" && <td className="text-right">
					<button onClick={() => this.props.setShowEditUserModal(true, item.email, index)} data-toggle="tooltip" disabled title="Edit User" className="btn btn-sm btn-dark">Edit<i className="ion ion-md-create ml-1" /></button>
					<button onClick={() => this.props.delete(true, item.email, "user")} data-toggle="tooltip" disabled title="Delete User" className="btn btn-sm btn-dark ml-2"><i className="ion ion-md-trash" /></button>
				</td>}
			</tr>
		);
	}

	render() {
		
		return (
			<div className="table-rep-plugin">
				<div className="table-responsive mb-0" data-pattern="priority-columns">
					<table className="table table-striped">
						<thead>
							<tr>
								<th>Name</th>
								<th>Email</th>
								<th>{this.userRole==="superadmin" ? "Roles" : "Role"}</th>
								<th>{this.userRole==="superadmin" && "Status"}</th>
								{this.userRole!=="superadmin" && <th></th>}
							</tr>
						</thead>
						<tbody>
							{this.props.users.map(this.renderUsers)}
						</tbody>
					</table>
					<Paginator
						totalPages={this.props.pagination.total_pages}
						pageNum={this.props.pagination.page_num}
						getObjects={this.props.getUsers}
					/>
				</div>
			</div>
		);
	}
}

export default withRouter(connect(null, { getUsers, setShowEditUserModal, updateUser })(UsersTable));