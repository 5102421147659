import {
  SET_USER_LOADING,
  ON_USER_INPUT_CHANGE,
  GET_USERS,
  GET_USERS_SUCCESS,
  INVITE_USER,
  INVITE_USER_SUCCESS,
  SET_SHOW_INVITE_USER_MODAL,
  SET_SHOW_INVITE_NEW_USER_SUCCESS_MODAL,
  SET_SHOW_EDIT_USER_MODAL,
  ACCEPT_INVITE,
  USER_INVITE_ERROR,
  UPDATE_USER_ROLE,
  UPDATE_USER_ROLE_SUCCESS,
  SET_SHOW_EDIT_USER_SUCCESS_MODAL,
  RESET_INVITE_USER_FORM,
  UPDATE_USER,
  UPDATE_USER_SUCCESS,
  VALIDATE_ACCEPT_TOKEN,
  VALIDATE_ACCEPT_TOKEN_FAIL,
  VALIDATE_ACCEPT_TOKEN_SUCCESS,
} from "./actionTypes";

export const setUserLoading = (isLoading) => {
  return {
    type: SET_USER_LOADING,
    payload: isLoading,
  };
};

export const getUsers = (pageNum = 0) => {
  return {
    type: GET_USERS,
    payload: pageNum,
  };
};

export const onInputChange = (field, value) => {
  return {
    type: ON_USER_INPUT_CHANGE,
    payload: {
      field,
      value,
    },
  };
};

export const getUsersSuccess = (users) => {
  return {
    type: GET_USERS_SUCCESS,
    payload: users,
  };
};

export const inviteUser = (data) => {
  return {
    type: INVITE_USER,
    payload: data,
  };
};

export const inviteUserSuccess = () => {
  return {
    type: INVITE_USER_SUCCESS,
  };
};

export const setShowInviteUserModal = (isVisible) => {
  return {
    type: SET_SHOW_INVITE_USER_MODAL,
    payload: isVisible,
  };
};

export const setShowEditUserModal = (
  isVisible,
  editUserEmail = null,
  index = null
) => {
  return {
    type: SET_SHOW_EDIT_USER_MODAL,
    payload: {
      isVisible,
      editUserEmail,
      index,
    },
  };
};

export const setShowInviteNewUserSuccessModal = (isVisible) => {
  return {
    type: SET_SHOW_INVITE_NEW_USER_SUCCESS_MODAL,
    payload: isVisible,
  };
};

export const setShowEditUserSuccessModal = (isVisible) => {
  return {
    type: SET_SHOW_EDIT_USER_SUCCESS_MODAL,
    payload: isVisible,
  };
};

export const validateUserAcceptToken = (token) => {
  return {
    type: VALIDATE_ACCEPT_TOKEN,
    payload: { token },
  };
};

export const validateUserAcceptTokenFail = (token) => {
  return {
    type: VALIDATE_ACCEPT_TOKEN_FAIL,
    payload: { token },
  };
};
export const validateUserAcceptTokenSuccess = (token) => {
  return {
    type: VALIDATE_ACCEPT_TOKEN_SUCCESS,
    payload: { token },
  };
};

export const acceptInvite = (token, history) => {
  return {
    type: ACCEPT_INVITE,
    payload: { token, history },
  };
};

export const userError = (error) => {
  return {
    type: USER_INVITE_ERROR,
    payload: error,
  };
};

export const updateUserRole = (data) => {
  console.log("data", data);
  return {
    type: UPDATE_USER_ROLE,
    payload: data,
  };
};

export const updateUserRoleSuccess = () => {
  return {
    type: UPDATE_USER_ROLE_SUCCESS,
  };
};

export const resetInviteUserForm = () => {
  return {
    type: RESET_INVITE_USER_FORM,
  };
};

export const updateUser = (data) => {
  return {
    type: UPDATE_USER,
    payload: data,
  };
};

export const updateUserSuccess = () => {
  return {
    type: UPDATE_USER_SUCCESS,
  };
};
