import React from "react";
import { withRouter } from "react-router-dom";

function cancel() {
	this.history.goBack();
}

function createNewCategory() {

}

const CreateNewCategory = props => {
	return (
		<React.Fragment>
			<div className="container-fluid new-item-page-container">
        		<div className="new-item-page-nav"><i onClick={cancel.bind(props)} className="ion ion-md-arrow-round-back back-button"></i></div>
        		<div className="new-item-page-content">
        			<div className="row form-row">
            			<div className="col-12">
            				<h3>Create New Category</h3>
            				<div className="separator mb-2" />
            				<div className="p-3">
	            				<div className="row fieldset">
									<div className="col-4 text-right">
										<label className="fieldset-label">Category Name</label>
									</div>
									<div className="col-8">
										<input className="input-text" type="text" placeholder="Category Name" />
									</div>
								</div>

								<div className="row fieldset">
									<div className="col-4 text-right">
										<label className="fieldset-label">Category Description</label>
									</div>
									<div className="col-8">
										<textarea className="input-text" placeholder="Category Description"></textarea>
									</div>
								</div>
							</div>
							<div className="separator mt-3 mb-3" />
							<div className="row fieldset">
								<div className="col-12 text-right">
									<button onClick={cancel.bind(props)} className="btn">Cancel</button>
									<button className="btn create-item ml-2" onClick={createNewCategory.bind(props)}>Create Category</button>
								</div>
							</div>
            			</div>
        			</div>
        		</div>
        	</div>
		</React.Fragment>
	);
}

export default withRouter(CreateNewCategory);
