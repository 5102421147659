import React, { useEffect, useRef, Component } from "react";
import { Modal, TabContent, TabPane } from "reactstrap";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Select from "react-select";
import Tags from "@yaireo/tagify/dist/react.tagify";
import "@yaireo/tagify/dist/tagify.css";
import SweetAlert from "react-bootstrap-sweetalert";
import Loader from "../components/Loader";
import TagField from "../components/TagField";
import MyEditor from "../components/MyEditor";
import CreateCategoryModal from "../components/CreateCategoryModal";
import StepperWidget from "../components/StepperWidget";
import PricingDriver from "../components/PricingDriver";
import {
  setActiveStep,
  addSlabToPricingDriver,
  deleteNumberRangeFromPricingDriver,
  addPricingDriver,
  deletePricingDriver,
  addVariationToPricingDriver,
  addNumberRangeToPricingDriver,
  deleteSlabFromPricingDriver,
  deleteVariationFromPricingDriver,
  onInputChange,
  getCategories,
  getGlobalPricingDriver,
  getGlobalPricingDrivers,
  getService,
  resetCreateNewServiceForm,
  setDefaultDriver,
  setServiceLoading,
  setShowChangeGlobalPricingDriverValuesModal,
  setShowCreateCategoryModal,
  setShowCreateGlobalPricingDriverModal,
  setShowCreateNewServiceSuccessModal,
  setShowUpdateServiceConfirmModal,
  submitService,
  setShowCreateNewServiceFailedModal,
} from "../store/service/actions";
import GlobalPricingDriversTable from "../components/Settings/GlobalPricingDriversTable";
import ChangeGlobalPricingDriverValuesModal from "../components/Services/ChangeGlobalPricingDriverValuesModal";

import { serviceChargeTypes, servicePricingTypes } from "../config";
import { getValidator } from "../Utils";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import { arrayMoveImmutable } from "array-move";

const SortableItem = SortableElement((props) =>
  props.renderPricingDriver(props.value, props.i, props.driversArray)
);
const SortableList = SortableContainer((props) => {
  return (
    <div>
      {props.items.map((value, index) => (
        <SortableItem
          key={index}
          index={index}
          value={value}
          i={index}
          renderPricingDriver={props.renderPricingDriver}
          driversArray={props.driversArray}
        />
      ))}
    </div>
  );
});

class CreateNewService extends Component {
  timeoutRef;
  constructor(props) {
    super(props);
    this.validator = getValidator();
    this.tagifyRef = React.createRef();
    this.formatDataAndSubmitService =
      this.formatDataAndSubmitService.bind(this);
    this.nextStep = this.nextStep.bind(this);
    this.backStep = this.backStep.bind(this);
    this.cancel = this.cancel.bind(this);
    this.renderPricingDriver = this.renderPricingDriver.bind(this);
    this.generateDriverCode = this.generateDriverCode.bind(this);
    this.isValidPricingFormula = this.isValidPricingFormula.bind(this);
    this.areDriversValid = this.areDriversValid.bind(this);
    this.updateService = this.updateService.bind(this);
    this.validateSelectors = this.validateSelectors.bind(this);
    this.state = {
      warningSteps: [],
      pricingFormulaError: "",
      isPricingFormulaFocused: false,
      flag: false,
      showValidatorWarning: false,
      showChangeGlobalPricingDriverValues: false,
      globalPricingDrivers: [],
      driverProps: {},
    };
    this.validator = getValidator();
  }

  allowNext = true;
  validationBools = [];
  setTimer;
  formulas = [];

  componentDidUpdate(prevProps) {
    console.log('this.tagifyRef.current - ', this.tagifyRef.current);
    let updateStepperWarnings = false;
    if (
      this.props.activeStep !== prevProps.activeStep ||
      this.props.serviceName !== prevProps.serviceName ||
      this.props.fixedPrice !== prevProps.fixedPrice ||
      this.props.serviceChargeType !== prevProps.serviceChargeType ||
      this.props.servicePricingType !== prevProps.servicePricingType
    ) {
      updateStepperWarnings = true;
      this.updateStepperWarnings();
    }
    if (!updateStepperWarnings) {
      if (this.props.driverCodes.length !== prevProps.driverCodes.length) {
        updateStepperWarnings = true;
      } else {
        for (let driverCode of this.props.driverCodes) {
          if (
            this.props[`driver${driverCode}Name`] !==
            prevProps[`driver${driverCode}Name`] ||
            this.props[`driver${driverCode}Type`] !==
            prevProps[`driver${driverCode}Type`]
          ) {
            updateStepperWarnings = true;
            break;
          }
          if (this.props.hasOwnProperty(`driver${driverCode}Variations`)) {
            for (let variationCode of this.props[
              `driver${driverCode}Variations`
            ]) {
              if (
                this.props[
                `driver${driverCode}Variation${variationCode}Name`
                ] !==
                prevProps[
                `driver${driverCode}Variation${variationCode}Name`
                ] ||
                this.props[
                `driver${driverCode}Variation${variationCode}Price`
                ] !==
                prevProps[`driver${driverCode}Variation${variationCode}Price`]
              ) {
                updateStepperWarnings = true;
                break;
              }
            }
            if (updateStepperWarnings) {
              break;
            }
          } else if (this.props.hasOwnProperty(`driver${driverCode}Slabs`)) {
            for (let slabCode of this.props[`driver${driverCode}Slabs`]) {
              if (
                this.props[`driver${driverCode}Slab${slabCode}Type`] !==
                prevProps[`driver${driverCode}Slab${slabCode}Type`] ||
                this.props[`driver${driverCode}Variation${slabCode}From`] !==
                prevProps[`driver${driverCode}Variation${slabCode}From`] ||
                this.props[`driver${driverCode}Variation${slabCode}To`] !==
                prevProps[`driver${driverCode}Variation${slabCode}To`] ||
                this.props[`driver${driverCode}Variation${slabCode}Price`] !==
                prevProps[`driver${driverCode}Variation${slabCode}Price`] ||
                this.props[
                `driver${driverCode}Variation${slabCode}Incrementer`
                ] !==
                prevProps[
                `driver${driverCode}Variation${slabCode}Incrementer`
                ]
              ) {
                updateStepperWarnings = true;
                break;
              }
            }
            if (updateStepperWarnings) {
              break;
            }
          }
        }
      }
    }
    if (updateStepperWarnings) {
      this.updateStepperWarnings();
    }
    console.log('this.props.servicePricingFormula - ', this.props.servicePricingFormula);
    if (this.props.servicePricingFormula && !this.state.formulaJoined && this.type === "edit") {
      let formula = this.props.servicePricingFormula + "";
      let newFormula;
      let formulaArr = formula.split("[[{");
      newFormula = formulaArr.map((elem) => {
        if (elem.includes("value")) {
          elem = elem.replaceAll(" ", "_");
        }
        return elem;
      });
      let joinedFormula = newFormula.join(" [[{");
      let index = 0;

      console.log('joinedFormula - ', joinedFormula);
      while (
        joinedFormula.indexOf("[[{") >= 0 &&
        joinedFormula.indexOf("}]]") > 0
      ) {
        this.formulas.push(joinedFormula.substring(joinedFormula.indexOf("[[{"), joinedFormula.indexOf("}]]") + 3))
        joinedFormula =
          joinedFormula.substring(0, joinedFormula.indexOf("[[{")) +
          "<<" + index + ">>" +
          joinedFormula.substring(joinedFormula.indexOf("}]]") + 3);
        index++;
      }
      joinedFormula = joinedFormula.replaceAll(' ', '').replaceAll('_', ' ')
      // console.log('this.formulas - ', this.formulas.length);

      for (let i = 0; i < this.formulas.length; i++) {
        joinedFormula = joinedFormula.replace(`<<${i}>>`, this.formulas[i]);
      }

      joinedFormula = joinedFormula.replaceAll('[[{', ' [[{');

      console.log('newFormula.join("[[{") - ', joinedFormula, this.formulas);

      this.props.onInputChange("servicePricingFormula", joinedFormula);
      this.setState({ formulaJoined: true });
      this.updateStepperWarnings();
    }
    if (
      prevProps.globalServiceDrivers !== this.props.globalServiceDrivers &&
      this.props.globalServiceDrivers
    ) {
      this.setState({ globalPricingDrivers: this.props.globalServiceDrivers });
    }
  }
  updateStepperWarnings() {
    if (this.validator.messagesShown) {
      let warningSteps = [];
      if (
        this.props.servicePricingType === "fixedPricing" &&
        (!this.validator.fields["Service Name"] ||
          !this.validator.fields["Service Category"] ||
          !this.validator.fields["Service Charge Type"] ||
          !this.validator.fields["Price"])
      ) {
        warningSteps.push(1);
      } else if (
        this.props.servicePricingType === "formulaBasedPricing" &&
        (!this.validator.fields["Service Name"] ||
          !this.validator.fields["Service Category"] ||
          !this.validator.fields["Service Charge Type"])
      ) {
        warningSteps.push(1);
      }
      if (this.props.servicePricingType === "formulaBasedPricing") {
        if (!this.areDriversValid()) {
          warningSteps.push(3);
        }
      }
      if (this.tagifyRef?.current) this.tagifyRef.current.update();
      if (
        this.props.servicePricingType === "formulaBasedPricing" &&
        this.tagifyRef.current &&
        !this.isValidPricingFormula(
          this.tagifyRef.current.state.lastOriginalValueReported
        ) && this.props.activeStep === 4
      ) {
        warningSteps.push(4);
      }
      // if (this.props.servicePricingType==="formulaBasedPricing" && !this.validator.fields["Pricing Formula"]) {
      // 	warningSteps.push(4);
      // }
      if (warningSteps.join() !== this.state.warningSteps.join()) {
        this.setState({
          warningSteps,
        });
      }
    }
  }
  areDriversValid() {
    let driversValid = true;
    for (let driverCode of this.props.driverCodes) {
      if (
        !(
          this.validator.fields[`driver${driverCode}Name`] &&
          this.validator.fields[`driver${driverCode}Type`]
        )
      ) {
        driversValid = false;
        break;
      }
      if (this.props[`driver${driverCode}Type`] === "Variation") {
        if (
          this.props[`driver${driverCode}Variations`] &&
          this.props[`driver${driverCode}Variations`].length > 0
        ) {
          for (let variationCode of this.props[
            `driver${driverCode}Variations`
          ]) {
            if (
              !(
                this.validator.fields[
                `driver${driverCode}Variation${variationCode}Name`
                ] &&
                this.validator.fields[
                `driver${driverCode}Variation${variationCode}Price`
                ]
              )
            ) {
              driversValid = false;
              break;
            }
          }
          if (!driversValid) {
            break;
          }
        } else {
          driversValid = false;
          break;
        }
      } else if (this.props[`driver${driverCode}Type`] === "Slab") {
        if (
          this.props[`driver${driverCode}Slabs`] &&
          this.props[`driver${driverCode}Slabs`].length > 0
        ) {
          for (let slabCode of this.props[`driver${driverCode}Slabs`]) {
            if (
              this.validator.fields[`driver${driverCode}Slab${slabCode}Type`]
            ) {
              if (
                ["BlockBased", "UnitBased"].includes(
                  this.props[`driver${driverCode}Slab${slabCode}Type`]
                )
              ) {
                console.log(
                  this.props[`driver${driverCode}Slab${slabCode}Type`],
                  "based"
                );
                if (
                  !(
                    this.validator.fields[
                    `driver${driverCode}Slab${slabCode}Price`
                    ] &&
                    this.validator.fields[
                    `driver${driverCode}Slab${slabCode}From`
                    ] &&
                    this.validator.fields[
                    `driver${driverCode}Slab${slabCode}To`
                    ] &&
                    this.props[`driver${driverCode}Slab${slabCode}From`] <
                    this.props[`driver${driverCode}Slab${slabCode}To`]
                  )
                ) {
                  driversValid = false;
                  break;
                }
              } else if (
                ["IncrementalBlock", "IncrementalUnit"].includes(
                  this.props[`driver${driverCode}Slab${slabCode}Type`]
                )
              ) {
                console.log(
                  this.props[`driver${driverCode}Slab${slabCode}Type`],
                  "Incremental"
                );
                if (
                  !(
                    this.validator.fields[
                    `driver${driverCode}Slab${slabCode}Price`
                    ] &&
                    this.validator.fields[
                    `driver${driverCode}Slab${slabCode}From`
                    ] &&
                    this.validator.fields[
                    `driver${driverCode}Slab${slabCode}Incrementer`
                    ]
                  )
                ) {
                  driversValid = false;
                  break;
                }
              }
            } else {
              driversValid = false;
              break;
            }
          }
          if (!driversValid) {
            break;
          }
        } else {
          driversValid = false;
          break;
        }
      }
    }
    return driversValid;
  }
  isValidPricingFormula(pricingFormula) {
    let isValidFormula = true;
    if (pricingFormula === "") isValidFormula = false;
    if (isValidFormula) {
      const regex = /"key":"var(.+?)"/gm;
      let m;
      while ((m = regex.exec(pricingFormula)) !== null && isValidFormula) {
        // This is necessary to avoid infinite loops with zero-width matches
        if (m.index === regex.lastIndex) {
          regex.lastIndex++;
        }
        isValidFormula =
          this.props.driverCodes.includes(m[1]) ||
          this.props.globalDrivers.map((item) => item._id).includes(m[1]) ||
          this.state.globalPricingDrivers
            .map((item) => item._id)
            .includes(m[1]);
      }
    }
    pricingFormula = pricingFormula.split(' ').join('')
    pricingFormula = pricingFormula
      .split("[[{")
      .join(" _[[{_")
      .split("}]]")
      .join("_}]]_ ");
    let formulaParse = pricingFormula.split(" ");
    formulaParse = formulaParse.filter((elem) => elem != "");
    formulaParse = formulaParse.map((elem) => elem.trim());
    formulaParse = formulaParse.filter((elem) => elem !== "");
    let index = 0;
    let varType;
    // for (let elem of formulaParse) {
    //   let parseElem = parseFloat(
    //     elem
    //       .split("+")
    //       .join("")
    //       .split("-")
    //       .join("")
    //       .split("*")
    //       .join("")
    //       .split("/")
    //       .join("")
    //       .split(" ")
    //       .join("")
    //       .trim()
    //   );
    //   if (
    //     (index === 0 || formulaParse.length - 1 === index) &&
    //     (elem == "+" ||
    //       elem == "-" ||
    //       elem == "*" ||
    //       elem == "/" ||
    //       elem == "//" ||
    //       elem == "++" ||
    //       elem == "--" ||
    //       elem == "**" ||
    //       elem == "*+" ||
    //       elem == "+*" ||
    //       elem == "-+" ||
    //       elem == "+-")
    //   ) {
    //     // check first and last index is not arithmetic
    //     varType = "";
    //     isValidFormula = false;
    //     break;
    //   } else if (
    //     ((elem.includes("global-tag") && varType !== "global-tag") ||
    //       (elem.includes("private-tag") && varType !== "global-tag")) &&
    //     varType !== "numbers"
    //   ) {
    //     // check if elem is tag nothing other at all index
    //     varType = "global-tag";
    //   } else if (
    //     (elem == "+" || elem == "-" || elem == "*" || elem == "/") &&
    //     varType !== "arithmetic"
    //   ) {
    //     varType = "arithmetic";
    //   } else if (parseElem && parseElem !== NaN) {
    //     if (
    //       elem[elem.length - 1] == "+" ||
    //       elem[elem.length - 1] == "-" ||
    //       elem[elem.length - 1] == "*" ||
    //       elem[elem.length - 1] == "/"
    //     ) {
    //       varType = "numbersmix";
    //     } else {
    //       varType = "numbers";
    //     }
    //   } else {
    //     varType = "";
    //     isValidFormula = false;
    //     break;
    //   }
    //   index++;
    // }
    if (isValidFormula) {
      let parseFormula = pricingFormula;
      while (
        parseFormula.indexOf("_[[{_") >= 0 &&
        parseFormula.indexOf("_}]]_") > 0
      ) {
        parseFormula =
          parseFormula.substring(0, parseFormula.indexOf("_[[{_")) +
          "1" +
          parseFormula.substring(parseFormula.indexOf("_}]]_") + 5);
      }

      parseFormula = parseFormula.replaceAll('{', '(');
      parseFormula = parseFormula.replaceAll('}', ')');
      try {
        console.log('parseFormula - ', parseFormula);
        eval(parseFormula);
      } catch (error) {
        isValidFormula = false;
      }
    }
    if (!isValidFormula) {
      this.setState({
        pricingFormulaError: "Please enter a valid Pricing Formula.",
      });
    } else {
      this.setState({
        pricingFormulaError: "",
      });
    }

    return isValidFormula;
  }
  formatDataAndSubmitService(e) {
    let driversValid;
    let validPricingFormula;
    // console.log('this.tagifyRef.current - ', this.tagifyRef.current, this.isValidPricingFormula(
    //   this.tagifyRef.current.state.lastOriginalValueReported
    // ))
    // return;
    if (this.props.servicePricingType === "formulaBasedPricing") {
      // driversValid = this.props.driverCodes.reduce((cur, acc, index) => (acc && this.validator.fields[`Driver ${index+1} Name`] && this.validator.fields[`Driver ${index+1} Type`]), true);
      driversValid = this.areDriversValid();
      this.tagifyRef.current.update();
      validPricingFormula = this.isValidPricingFormula(
        this.tagifyRef.current.state.lastOriginalValueReported
      );
    }
    if (
      (this.validator.fields["Price"] &&
        this.validator.fields["Service Charge Type"] &&
        this.validator.fields["Service Name"] &&
        this.validator.fields["Service Category"] &&
        this.props.servicePricingType === "fixedPricing") ||
      (this.validator.fields["Service Charge Type"] &&
        this.validator.fields["Service Name"] &&
        this.validator.fields["Service Category"] &&
        validPricingFormula &&
        driversValid &&
        this.props.servicePricingType === "formulaBasedPricing")
    ) {
      const data = {
        name: this.props.serviceName,
        categories: this.props.serviceCategories
          ? this.props.serviceCategories
          : [],
        chargeType: this.props.serviceChargeType,
        pricingType: this.props.servicePricingType,
        description: this.props.serviceDescription,
      };
      if (this.type === "edit") {
        data.id = this.props.serviceId;
      }
      switch (this.props.servicePricingType) {
        case "fixedPricing":
          data.fixedPrice = this.props.fixedPrice;
          // console.log(this.props.fixedPrice,"hiiii");
          break;
        case "formulaBasedPricing":
          const globalDrivers = this.state.globalPricingDrivers.map((c) => ({
            id: c._id ?? c.id,
            ...c,
          }));
          let drivers = [...globalDrivers];
          if (this.props.hasOwnProperty("driverCodes")) {
            this.props.driverCodes.forEach((driverItem) => {
              let driver = {};
              driver.isNew = this.props[`driver${driverItem}IsNew`];
              if (driver.isNew === false) {
                driver.id = driverItem;
              }
              driver.name = this.props[`driver${driverItem}Name`];
              driver.type = this.props[`driver${driverItem}Type`];
              driver.code = `${driverItem}`;
              if (
                !this.props[`driver${driverItem}dependancyDriver`] ||
                !this.props[`driver${driverItem}dependencyVariation`]
              ) {
                driver.isDependent = false;
                driver.dependancyDriver = "";
                driver.dependencyVariation = "";
              } else {
                driver.isDependent =
                  this.props[`driver${driverItem}isDependent`];
                driver.dependancyDriver =
                  this.props[`driver${driverItem}dependancyDriver`];
                driver.dependencyVariation =
                  this.props[`driver${driverItem}dependencyVariation`];
              }
              if (driver.type === "Variation") {
                let variations = [];
                this.props[`driver${driverItem}Variations`].forEach(
                  (variationItem) => {
                    let variation = {};
                    variation.isNew =
                      this.props[
                      `driver${driverItem}Variation${variationItem}IsNew`
                      ];
                    if (variation.isNew === false) {
                      variation.id = variationItem;
                    }
                    variation.name =
                      this.props[
                      `driver${driverItem}Variation${variationItem}Name`
                      ];
                    variation.price =
                      this.props[
                      `driver${driverItem}Variation${variationItem}Price`
                      ];
                    variation.is_default =
                      this.props[
                      `driver${driverItem}Variation${variationItem}IsDefault`
                      ];
                    variations.push(variation);
                  }
                );
                driver.variations = variations;
              } else if (driver.type === "Slab") {
                let slabs = [];
                this.props[`driver${driverItem}Slabs`].forEach((slabItem) => {
                  let slab = {};
                  slab.isNew =
                    this.props[`driver${driverItem}Slab${slabItem}IsNew`];
                  if (slab.isNew === false) {
                    slab.id = slabItem;
                  }
                  slab.type =
                    this.props[`driver${driverItem}Slab${slabItem}Type`];
                  slab.price =
                    this.props[`driver${driverItem}Slab${slabItem}Price`];
                  slab.from =
                    this.props[`driver${driverItem}Slab${slabItem}From`];
                  slab.is_default =
                    this.props[`driver${driverItem}Slab${slabItem}IsDefault`];
                  if (["BlockBased", "UnitBased"].includes(slab.type)) {
                    slab.to =
                      this.props[`driver${driverItem}Slab${slabItem}To`];
                  }
                  if (
                    ["IncrementalBlock", "IncrementalUnit"].includes(slab.type)
                  ) {
                    slab.incrementer =
                      this.props[
                      `driver${driverItem}Slab${slabItem}Incrementer`
                      ];
                  }

                  // variation.name = this.props[`driver${driverItem}Variation${variationItem}Name`];
                  // variation.price = this.props[`driver${driverItem}Variation${variationItem}Price`];
                  slabs.push(slab);
                });
                driver.slabs = slabs;
              } else if (driver.type === "Number Range") {
                let numberRanges = [];
                this.props[`driver${driverItem}NumberRanges`].forEach(
                  (numberRangeItem) => {
                    let numberRange = {};
                    numberRange.isNew =
                      this.props[
                      `driver${driverItem}NumberRange${numberRangeItem}IsNew`
                      ];
                    if (numberRange.isNew === false) {
                      numberRange.id = numberRangeItem;
                    }
                    numberRange.from =
                      this.props[
                      `driver${driverItem}NumberRange${numberRangeItem}From`
                      ];
                    numberRange.to =
                      this.props[
                      `driver${driverItem}NumberRange${numberRangeItem}To`
                      ];
                    numberRange.price =
                      this.props[
                      `driver${driverItem}NumberRange${numberRangeItem}Price`
                      ];
                    numberRanges.push(numberRange);
                  }
                );
                driver.numberRanges = numberRanges;
              }
              drivers.push(driver);
            });
          }
          data.pricingDrivers = drivers;
          data.pricingFormula =
            this.tagifyRef.current.state.lastOriginalValueReported;
          break;
        default:
          break;
      }
      this.props.submitService(data, this.type);
    } else {
      this.validator.showMessages();
      this.updateStepperWarnings();
      // this.forceUpdate();
    }
  }
  updateService() {
    this.setState({ flag: true });
    setTimeout(() => {
      this.setState({ flag: false });
    }, 2500);
if (this.tagifyRef && this.tagifyRef.current) {
      this.tagifyRef.current.update();
    }
//    this.tagifyRef.current.update();
    if (this.tagifyRef.current != null) {
      if (
        !this.isValidPricingFormula(
          this.tagifyRef.current.state.lastOriginalValueReported
        )
      ) {
        // this.updateStepperWarnings()
      } else {
        if (
          this.props.serviceAffectedPackages &&
          this.props.serviceAffectedPackages.length > 0
        ) {
          this.props.setShowUpdateServiceConfirmModal(true);
        } else {
          this.formatDataAndSubmitService();
        }
      }
    } else {
      this.formatDataAndSubmitService();
    }
  }

  validateSelectors() {
    Object.keys(this.props)
      .filter((e) => e.endsWith("isDependent"))
      .map((e) => {
        if (this.props[e]) {
          let driverId = e.split("driver")[1].split("isDependent")[0];
          let dependancyDriver = `driver${driverId}dependancyDriver`;
          let dependencyVariation = `driver${driverId}dependencyVariation`;
          if (
            !this.props[dependancyDriver] ||
            !this.props[dependencyVariation]
          ) {
            if (!this.props[dependancyDriver]) {
              this.allowNext = false;
              this.validationBools.push("false");
              this.validator.showMessageFor(dependancyDriver);
            }
            if (!this.props[dependencyVariation]) {
              this.allowNext = false;
              this.validationBools.push("false");
              this.validator.showMessageFor(dependencyVariation);
            }
          } else {
            this.allowNext = true;
          }
        } else {
          if (this.props.activeStep === 3) {
            if (this.areDriversValid()) {
              this.allowNext = true;
            } else {
              this.allowNext = false;
            }
          }
        }
        this.forceUpdate();
      });
    return;
  }
  setShowMessage() {
    this.setState({ showValidatorWarning: false });
  }
  nextStep() {
    console.log('this.props - ', this.props, this.validator);
    let that = this;
    this.props.activeStep < 3 && (this.allowNext = true);
    this.validationBools = [];
    clearTimeout(this.setTimer);
    if (this.props.activeStep === 1) {
      const fields = ['Service Name', 'Service Charge Type', 'Service Category'];
      if (this.props.servicePricingType !== 'formulaBasedPricing') fields.push('Price');
      console.log('fields - ', fields);
      const bool = fields.every(a => this.validator.fields[a]);
      if (bool) {
        this.allowNext = true;
        this.setState({ warningSteps: [] });
        this.validator.hideMessages();
        this.props.setActiveStep(this.props.activeStep + 1);
        this.forceUpdate();
      } else {
        this.allowNext = false;
        this.setState({ warningSteps: [1] });
        this.validator.showMessages();
        this.forceUpdate();
      }
    } else if (this.props.activeStep === 3) {
      this.validateSelectors();
      if (!this.allowNext || this.validationBools.length > 0) {
        this.setState({ showValidatorWarning: true });
        // this.setTimer = setTimeout(function () {
        // 	that.setShowMessage();
        // }, 5000);
      }
    }
    !this.validationBools.length &&
      this.allowNext &&
      this.props.setActiveStep(this.props.activeStep + 1);
  }
  backStep() {
    this.setState({ showValidatorWarning: false });
    clearTimeout(this.setTimer);
    this.props.activeStep < 3 && (this.allowNext = true);
    this.props.setActiveStep(this.props.activeStep - 1);
  }
  cancel() {
    this.props.history.goBack();
  }
  editGlobalPricingDriver(item, coupled) {
    const getGlobalDriverId = (item) =>
      item && item.globalDriverId
        ? item.globalDriverId._id ?? item.globalDriverId
        : item._id;
    if (coupled) {
      this.props.getGlobalPricingDriver(getGlobalDriverId(item));
      return this.props.setShowChangeGlobalPricingDriverValuesModal(true);
    } else {
      // this.props.getGlobalPricingDriver(item.globalDriverId);
      const id = item.id ?? item._id;
      const driver = this.state.globalPricingDrivers.find(
        (c) => c._id === id || c.id === id
      );
      const action = { payload: { driver } };
      let newState = {};
      newState.driverId = action.payload.driver._id ?? action.payload.driver.id;
      newState.driverName = action.payload.driver.name;
      newState.driverType = action.payload.driver.type;
      newState.isDependent = action.payload.driver.isDependent || false;
      newState.dependancyDriver = action.payload.driver.dependancyDriver || "";
      newState.dependencyVariation =
        action.payload.driver.dependencyVariation || "";
      newState.driverValue = action.payload.driver.value;
      newState.driverVariations = [];
      newState.driverSlabs = [];
      newState.linkedServices = action.payload.linkedServices;
      newState.driverNumberRanges = [];
      if (action.payload.driver.type === "Variation") {
        action.payload.driver.variations.forEach(
          (_variationItem, variationIndex) => {
            let variationItem = _variationItem;
            const id = variationItem._id ?? variationItem.id;
            if (!_variationItem.decoupled) {
              if (item.globalDriverId.variations)
                variationItem = item.globalDriverId.variations.find(
                  (c) => c._id === id
                );
              else variationItem = item.variations.find((c) => c.id === id);
            }
            console.log("variationItem - ", variationItem, this.props);
            newState[`driverVariations`].push(id);
            newState[`driverVariation${id}IsNew`] = false;
            newState[`driverVariation${id}Name`] = variationItem.name;
            newState[`driverVariation${id}Price`] = variationItem.price;
            newState[`driverVariation${id}IsDefault`] =
              variationItem.is_default;
            newState[`driverVariation${id}IsDeCoupled`] =
              variationItem.decoupled;
          }
        );
      } else if (action.payload.driver.type === "Slab") {
        action.payload.driver.slabs.forEach((slabItem, slabIndex) => {
          const id = slabItem._id ?? slabItem.id;
          if (!slabItem.decoupled) {
            console.log("");
            if (item.globalDriverId.slabs)
              slabItem = item.globalDriverId.slabs.find((c) => c._id === id);
            else slabItem = item.slabs.find((c) => (c.id ?? c._id) === id);
          }
          newState[`driverSlabs`].push(id);
          newState[`driverSlab${id}IsNew`] = false;
          newState[`driverSlab${id}Type`] = slabItem.type;
          newState[`driverSlab${id}Price`] = slabItem.price;
          newState[`driverSlab${id}From`] = slabItem.from;
          newState[`driverSlab${id}To`] = slabItem.to;
          newState[`driverSlab${id}Incrementer`] = slabItem.incrementer;
          newState[`driverSlab${id}IsDefault`] = slabItem.is_default;
          newState[`driverSlab${id}IsDeCoupled`] = slabItem.decoupled;
        });
      } else if (action.payload.driver.type === "Number Range") {
        action.payload.driver.number_ranges.forEach(
          (numberRangeItem, numberRangeIndex) => {
            const id = numberRangeItem._id ?? numberRangeItem.id;
            newState[`driverNumberRanges`].push(id);
            newState[`driverNumberRange${id}IsNew`] = false;
            newState[`driverNumberRange${id}From`] = numberRangeItem.from;
            newState[`driverNumberRange${id}To`] = numberRangeItem.to;
            newState[`driverNumberRange${id}Price`] = numberRangeItem.price;
          }
        );
      }
      this.setState({ driverProps: newState }, () => {
        this.props.setShowChangeGlobalPricingDriverValuesModal(true);
      });
    }
  }
  handleSubmitChangeGlobalPricingDriverValues(driver) {
    // TODO: Check for the value change
    // const key = driver.type.toLowerCase() + 's';
    // if(driver[key] && driver[key].length) {
    // 	const isChanged = driver[key].reduce((acc, cur) => {
    //
    // 	}, false)
    // }
    let drivers = [...this.state.globalPricingDrivers];
    const driverIndex = drivers.findIndex(
      (c) =>
        c._id === driver.id ||
        c.id === driver.id ||
        (c.globalDriverId?._id ?? c.globalDriverId) === driver.id
    );
    // console.log('driver - ', driver.variations, driverIndex, drivers);

    function compareArr(arr, arr1) {
      return arr1.map((c) => {
        const inArr = arr.find((a) => (a._id ?? a.id) === (c.id ?? c._id));
        if (c.price.toString() === inArr.price.toString()) return c;
        return { ...c, decoupled: true };
      });
    }

    const currentDriver = drivers[driverIndex];

    if (currentDriver.type === "Variation") {
      currentDriver.variations = compareArr(
        currentDriver.variations,
        driver.variations
      );
    } else if (currentDriver.type === "Slab") {
      currentDriver.slabs = compareArr(currentDriver.slabs, driver.slabs);
    }

    currentDriver.coupled = false;
    // const driverIdToCheck = currentDriver.globalDriverId?._id ?? currentDriver.globalDriverId;
    // const currentValue = this.tagifyRef.current.value.find(c => c.key === ('var' + driverIdToCheck));
    // const tagElm = this.tagifyRef.current.getTagElms().find(c => c.getAttribute('key') === ('var' + driverIdToCheck));
    //
    // if(tagElm) {
    // 	this.tagifyRef.current.replaceTag(tagElm, {
    // 		...currentValue,
    // 		key: 'var' + currentDriver._id
    // 	})
    // }

    // console.log('drivers - ', drivers);

    this.setState({ globalPricingDrivers: drivers }, () => {
      this.props.setShowChangeGlobalPricingDriverValuesModal(false);
    });
  }
  renderGlobalPricingDrivers() {
    if (
      !this.state.globalPricingDrivers ||
      this.state.globalPricingDrivers.length === 0
    )
      return null;
    return (
      <div className="fieldset-group-pricing-driver">
        <label className="fieldset-group-label">Global Pricing Drivers</label>
        <button
          onClick={() => this.setState({ globalPricingDrivers: [] })}
          className="btn btn-sm btn-danger delete-fieldset-group"
        >
          <i className="ion ion-md-trash mr-1"></i>Delete All Global Pricing
          Drivers
        </button>
        <div className="row">
          <table className="table table-striped">
            <tbody>
              {this.state.globalPricingDrivers.map((item, index) => (
                <tr key={item._id}>
                  <td>
                    {item.name.substring(0, 70)}{" "}
                    {!item.coupled && (
                      <i
                        title="The value is changed and no more coupled with global pricing driver."
                        className="ion ion-md-alert"
                        style={{ color: "var(--yellow)" }}
                      />
                    )}
                  </td>
                  <td>{item.type}</td>
                  <td className="serivce-actions text-right flex">
                    <button
                      onClick={() =>
                        this.editGlobalPricingDriver(item, item.coupled)
                      }
                      title="Edit Driver"
                      className="btn btn-sm btn-dark"
                    >
                      <i className="ion ion-md-create" />
                    </button>
                    <button
                      onClick={() => {
                        // Delete it
                        // this.props.delete(true, item._id, "globalPricingDriver");
                        // this.deleteModal(item._id)
                        this.deleteGlobalPricingDriverFromService(item);
                      }}
                      data-toggle="tooltip"
                      title="Delete Service"
                      className="btn btn-sm btn-dark ml-2"
                    >
                      <i className="ion ion-md-trash" />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    );
  }
  renderPricingDriver(item, index, driversArray) {
    let variations = [];
    let slabs = [];
    let numberRanges = [];
    if (this.props.hasOwnProperty(`driver${item}Variations`)) {
      this.props[`driver${item}Variations`].forEach((variationItem, index) => {
        variations.push({
          code: variationItem,
          name: this.props[`driver${item}Variation${variationItem}Name`],
          price: this.props[`driver${item}Variation${variationItem}Price`],
          is_default:
            this.props[`driver${item}Variation${variationItem}IsDefault`],
        });
      });
    }
    if (this.props.hasOwnProperty(`driver${item}Slabs`)) {
      this.props[`driver${item}Slabs`].forEach((slabItem, index) => {
        slabs.push({
          code: slabItem,
          type: this.props[`driver${item}Slab${slabItem}Type`],
          price: this.props[`driver${item}Slab${slabItem}Price`],
          from: this.props[`driver${item}Slab${slabItem}From`],
          to: this.props[`driver${item}Slab${slabItem}To`],
          incrementer: this.props[`driver${item}Slab${slabItem}Incrementer`],
          is_default: this.props[`driver${item}Slab${slabItem}IsDefault`],
        });
      });
    }
    if (this.props.hasOwnProperty(`driver${item}NumberRanges`)) {
      this.props[`driver${item}NumberRanges`].forEach(
        (numberRangeItem, index) => {
          numberRanges.push({
            code: numberRangeItem,
            from: this.props[`driver${item}NumberRange${numberRangeItem}From`],
            to: this.props[`driver${item}NumberRange${numberRangeItem}To`],
            price:
              this.props[`driver${item}NumberRange${numberRangeItem}Price`],
          });
        }
      );
    }
    return (
      <div key={index} className="fieldset-group-pricing-driver">
        <label className="fieldset-group-label">
          Pricing Driver {index + 1}
        </label>
        <button
          onClick={() => this.props.deletePricingDriver(item)}
          className="btn btn-sm btn-danger delete-fieldset-group"
        >
          <i className="ion ion-md-trash mr-1"></i>Delete Pricing Driver
        </button>
        <PricingDriver
          index={index}
          onChange={this.props.onInputChange}
          setDefaultDriver={this.props.setDefaultDriver}
          name={this.props[`driver${item}Name`]}
          type={this.props[`driver${item}Type`]}
          code={item}
          setDependency={(code, e) =>
            this.props.onInputChange(`driver${code}isDependent`, e)
          }
          setDependencyId={(code, id) =>
            this.props.onInputChange(`driver${code}dependancyDriver`, id)
          }
          setDependencyVariation={(code, variationId) =>
            this.props.onInputChange(
              `driver${code}dependencyVariation`,
              variationId
            )
          }
          setDriverVariations={(shuffledArr, driverCode) =>
            this.props.onInputChange(
              `driver${driverCode}Variations`,
              shuffledArr
            )
          }
          driversArray={driversArray}
          variations={variations}
          numberRanges={numberRanges}
          slabs={slabs}
          addVariationToPricingDriver={this.props.addVariationToPricingDriver}
          deleteVariationFromPricingDriver={
            this.props.deleteVariationFromPricingDriver
          }
          addSlabToPricingDriver={this.props.addSlabToPricingDriver}
          deleteSlabFromPricingDriver={this.props.deleteSlabFromPricingDriver}
          addNumberRangeToPricingDriver={
            this.props.addNumberRangeToPricingDriver
          }
          deleteNumberRangeFromPricingDriver={
            this.props.deleteNumberRangeFromPricingDriver
          }
          deletePricingDriver={this.props.deletePricingDriver}
          validator={this.validator}
          setStateValidators={() => this.setShowMessage()}
          parentProps={this.props}
        /*nameValidator={this.validator.message(`driver${item}Name`, this.props[`driver${item}Name`], '_required', { className: 'text-danger' })}
      typeValidator={this.validator.message(`driver${item}Type`, this.props[`driver${item}Type`], '_required', { className: 'text-danger' })}*/
        />
      </div>
    );
  }
  generateDriverCode() {
    return `${new Date().getTime()}`;
  }

  addGlobalPricingDriverToService(driver) {
    const drivers = this.state.globalPricingDrivers;
    driver.isNew = true;
    driver.scope = "local";
    driver.coupled = true;
    driver.driverScopeInService = "global";
    driver.globalDriverId = driver._id ?? driver.id;
    drivers.push(driver);
    this.setState({ globalPricingDrivers: drivers });
  }

  deleteGlobalPricingDriverFromService(driver) {
    const drivers = this.state.globalPricingDrivers.filter(
      (c) => c._id !== driver._id
    );
    this.setState({ globalPricingDrivers: drivers });
  }

  componentDidMount() {
    this.props.resetCreateNewServiceForm();
    this.props.getCategories(-1);
    this.props.getGlobalPricingDrivers("");
    // if (this.type==="new") {
    // 	this.props.resetCreateNewServiceForm();
    // }
    if (this.type === "edit") {
      // this.props.resetCreateNewServiceForm();
      // console.log('setServiceLoading - ');
      this.props.setServiceLoading(true);
      this.props.getService(this.props.match.params.id);
      // setTimeout(() => {
      //
      // }, 2000);
    }
  }
  componentWillUnmount() {
    // this.props.setActiveStep(1);
    this.props.resetCreateNewServiceForm();
  }

  onSortEnd = ({ oldIndex, newIndex }) => {
    let shuffledIdx = arrayMoveImmutable(
      this.props.driverCodes,
      oldIndex,
      newIndex
    );
    this.props.onInputChange("driverCodes", shuffledIdx);
  };

  decodePricingFormula(a) {
    let abc = [];
    for (let i = 0; i < a.split("+").length; i++) {
      abc.push(...JSON.parse(a.split("+")[i].trim()));
    }
    return abc;
  }

  encodePricingFormula(obj) {
    let arr = obj.map((c) => JSON.stringify([c]));
    return arr.join("");
  }

  render() {
    this.type = this.props.match.path
      .replace("/services/", "")
      .replace("/:id", "");
    const texts = {
      new: {
        header: "Create New Service",
        submitButton: "Create Service",
        actionName: "created",
      },
      edit: {
        header: "Edit Service",
        submitButton: "Update Service",
        actionName: "updated",
      },
    };
    const fixedPricingSteps = ["Basic Information", "Description"];
    const formulaBasedPricingSteps = [
      "Basic Information",
      "Description",
      "Pricing Drivers",
      "Pricing Formula",
    ];
    let activeStepCount = 1;
    const categoryOptions = this.props.categories.map((item) => ({
      value: item._id,
      label: item.name,
    }));
    // console.log('this.props - ', this.props);
    const localWhitelistVariables = this.props.driverCodes.map((item) => {
      return {
        value: this.props[`driver${item}Name`],
        key: `var${item}`,
        class: item.globalDriverId ? "global-tag" : "private-tag",
      };
    });
    const globalWhitelistVariables = this.props.globalDrivers.map((item) => {
      const inServiceGlobalList = this.state.globalPricingDrivers.find(
        (c) => (c.globalDriverId?._id ?? c.globalDriverId) === item._id
      );

      // if(inServiceGlobalList) {
      // 	return {
      // 		value: item.name,
      // 		key: `var${inServiceGlobalList._id}`,
      // 		class: "global-tag",
      // 		type: item.type
      // 	}
      // }

      return {
        value: item.name,
        key: `var${item._id}`,
        class: "global-tag",
        type: item.type,
      };
    });

    // console.log('globalWhitelistVariables, localWhitelistVariables - ', globalWhitelistVariables, localWhitelistVariables);

    const whitelistVariables = [
      ...localWhitelistVariables,
      ...globalWhitelistVariables,
    ];
    whitelistVariables.map((elem) => {
      elem.value = elem.value.replaceAll(" ", "_");
      return elem;
    });

    // console.log('this.props.globalDrivers - ', this.tagifyRef.current, this.state.globalPricingDrivers);

    const driverVariationsMap = (item) => {
      let variations = [];
      this.props[`driver${item}Variations`].forEach((variationItem, index) => {
        variations.push({
          code: variationItem,
          name: this.props[`driver${item}Variation${variationItem}Name`],
          price: this.props[`driver${item}Variation${variationItem}Price`],
          is_default:
            this.props[`driver${item}Variation${variationItem}IsDefault`],
        });
      });
      return variations;
    };

    const driverSlabsMap = (item) => {
      let slabs = [];
      this.props[`driver${item}Slabs`].forEach((slabItem, index) => {
        slabs.push({
          code: slabItem,
          type: this.props[`driver${item}Slab${slabItem}Type`],
          price: this.props[`driver${item}Slab${slabItem}Price`],
          incrementer: this.props[`driver${item}Slab${slabItem}Incrementer`],
          from: this.props[`driver${item}Slab${slabItem}From`],
          to: this.props[`driver${item}Slab${slabItem}To`],
          is_default: this.props[`driver${item}Slab${slabItem}IsDefault`],
        });
      });
      return slabs;
    };

    let driversArray = [];
    const driversMap = this.props?.driverCodes?.map((item, index) => {
      if (
        this.props.hasOwnProperty(`driver${item}Name`) &&
        this.props[`driver${item}Type`] === "Variation"
      ) {
        driversArray.push({
          id: item,
          idx: index,
          name: this.props[`driver${item}Name`],
          type: this.props[`driver${item}Type`],
          variations: this.props[`driver${item}Variations`]
            ? driverVariationsMap(item)
            : [],
        });
      }
      if (
        this.props.hasOwnProperty(`driver${item}Name`) &&
        this.props[`driver${item}Type`] === "Slab"
      ) {
        driversArray.push({
          id: item,
          idx: index,
          name: this.props[`driver${item}Name`],
          type: this.props[`driver${item}Type`],
          slabs: this.props[`driver${item}Slabs`] ? driverSlabsMap(item) : [],
        });
      }
      if (
        this.props.hasOwnProperty(`driver${item}Name`) &&
        this.props[`driver${item}Type`] === "Quantity"
      ) {
        driversArray.push({
          id: item,
          idx: index,
          name: this.props[`driver${item}Name`],
          type: this.props[`driver${item}Type`],
        });
      }
    });

    return (
      <React.Fragment>
        {this.props.loading && <Loader />}
        <div className="container-fluid new-item-page-container">
          <div className="new-item-page-nav">
            <i
              onClick={this.cancel}
              className="ion ion-md-arrow-round-back back-button"
            ></i>
          </div>
          <div className="new-item-page-content">
            <div className="row form-row">
              <div className="col-12">
                <h3>{texts[this.type].header}</h3>
                <StepperWidget
                  warningSteps={this.state.warningSteps}
                  onClick={this.props.setActiveStep}
                  activeStep={this.props.activeStep}
                  steps={eval(this.props.servicePricingType + "Steps")}
                  custom={true}
                />
                <TabContent activeTab={this.props.activeStep}>
                  <TabPane tabId={activeStepCount++} className="p-3">
                    <div className="row">
                      <div className="col-6">
                        <div className="fieldset2">
                          <label className="fieldset-label required">
                            Service Name
                          </label>
                          <input
                            value={this.props.serviceName || ""}
                            onChange={(e) =>
                              this.props.onInputChange(
                                "serviceName",
                                e.target.value
                              )
                            }
                            className="input-text"
                            type="text"
                            placeholder="Service Name"
                          />
                          {this.validator.message(
                            "Service Name",
                            this.props.serviceName,
                            "required",
                            { className: "text-danger" }
                          )}
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="fieldset2">
                          <label className="fieldset-label required">
                            Service Category
                          </label>
                          <Select
                            isMulti
                            options={categoryOptions}
                            onChange={(selectedOptions) =>
                              this.props.onInputChange(
                                "serviceCategories",
                                selectedOptions
                                  ? selectedOptions.map((item) => item.value)
                                  : null
                              )
                            }
                            value={
                              this.props.serviceCategories
                                ? categoryOptions.filter((item) =>
                                  this.props.serviceCategories.includes(
                                    item.value
                                  )
                                )
                                : null
                            }
                          />
                          {this.validator.message(
                            "Service Category",
                            this.props.serviceCategories,
                            "required",
                            { className: "text-danger" }
                          )}
                          <span
                            className="crete-new-category-link"
                            onClick={() =>
                              this.props.setShowCreateCategoryModal(true)
                            }
                          >
                            + Create New Category
                          </span>
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="fieldset2">
                          <label className="fieldset-label required">
                            Service Charge Type
                          </label>
                          <Select
                            options={serviceChargeTypes}
                            onChange={(selectedOption) =>
                              this.props.onInputChange(
                                "serviceChargeType",
                                selectedOption.value
                              )
                            }
                            value={
                              this.props.serviceChargeType
                                ? serviceChargeTypes.filter(
                                  (option) =>
                                    option.value ===
                                    this.props.serviceChargeType
                                )
                                : ""
                            }
                          />
                          {this.validator.message(
                            "Service Charge Type",
                            this.props.serviceChargeType,
                            "required",
                            { className: "text-danger" }
                          )}
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="fieldset2">
                          <label className="fieldset-label required">
                            Pricing Type
                          </label>
                          <Select
                            options={servicePricingTypes}
                            onChange={(selectedOption) => {
                              this.props.onInputChange(
                                "servicePricingType",
                                selectedOption.value
                              );
                              this.props.onInputChange(
                                "servicePricingFormula",
                                ""
                              );
                            }}
                            value={
                              this.props.servicePricingType
                                ? servicePricingTypes.filter(
                                  (option) =>
                                    option.value ===
                                    this.props.servicePricingType
                                )
                                : ""
                            }
                          />
                        </div>
                      </div>
                      {this.props.servicePricingType === "fixedPricing" && (
                        <div className="col-12">
                          <div className="fieldset2">
                            <label className="fieldset-label required">
                              Price
                            </label>
                            <input
                              value={this.props.fixedPrice || ""}
                              onChange={(e) =>
                                this.props.onInputChange(
                                  "fixedPrice",
                                  e.target.value
                                )
                              }
                              className="input-text"
                              type="text"
                              placeholder="Price"
                            />
                            {this.validator.message(
                              "Price",
                              this.props.fixedPrice,
                              "required|numeric|min:0,num",
                              { className: "text-danger" }
                            )}
                          </div>
                        </div>
                      )}
                    </div>
                  </TabPane>
                  <TabPane tabId={activeStepCount++} className="p-3">
                    <div className="fieldset2">
                      <label className="fieldset-label">
                        Service Description
                      </label>
                      {((this.props.serviceId && this.type === "edit") ||
                        this.type === "new") &&
                        !this.props.loading && (
                          <MyEditor
                            value={this.props.serviceDescription}
                            variable={"serviceDescription"}
                            onChange={this.props.onInputChange}
                          />
                        )}
                    </div>
                  </TabPane>
                  {this.props.servicePricingType === "formulaBasedPricing" && (
                    <TabPane tabId={activeStepCount++} className="p-3">
                      {/* {this.props.driverCodes.map(this.renderPricingDriver)} */}

                      {this.renderGlobalPricingDrivers()}

                      {
                        <SortableList
                          items={this.props.driverCodes}
                          onSortEnd={this.onSortEnd}
                          pressDelay={150}
                          useWindowAsScrollContainer={true}
                          lockAxis="y"
                          renderPricingDriver={this.renderPricingDriver}
                          driversArray={driversArray}
                        />
                      }

                      <div className="row fieldset">
                        <div
                          className={`col-12 text-${this.props.driverCodes.length > 0
                            ? "right"
                            : "center"
                            }`}
                        >
                          <button
                            onClick={() =>
                              this.props.addPricingDriver(
                                this.generateDriverCode()
                              )
                            }
                            className={`btn create-item`}
                          >
                            <i className="ion ion-md-add-circle"></i>Add Pricing
                            Driver
                          </button>
                          <button
                            onClick={() =>
                              this.setState({
                                showChangeGlobalPricingDriverValues: true,
                              })
                            }
                            className={`btn create-item ml-3`}
                          >
                            <i className="ion ion-md-add-circle"></i>Add Global
                            Pricing Driver
                          </button>
                        </div>
                      </div>
                    </TabPane>
                  )}
                  {this.props.servicePricingType === "formulaBasedPricing" && (
                    <TabPane tabId={activeStepCount++} className="p-3">
                      <div className="fieldset2">
                        <label className="fieldset-label required">
                          Pricing Formula
                        </label>
                        {this.props.activeStep === 4 && <Tags
                          settings={{
                            mode: "mix",
                            pattern: /@/,
                            dropdown: {
                              enabled: 0,
                              highlightFirst: true,
                              maxItems: 30,
                            },
                            enforceWhitelist: true,
                            duplicates: false,
                            autoFocus: true,
                            // delimiters: " "
                          }}
                          tagifyRef={this.tagifyRef}
                          whitelist={whitelistVariables}
                          className="input-text"
                          value={this.props.servicePricingFormula}
                          onFocus={() =>
                            this.setState({ isPricingFormulaFocused: true })
                          }
                          onBlur={() =>
                            this.setState({ isPricingFormulaFocused: false })
                          }
                          onChange={(e) => {
                            e.persist();
                            this.updateStepperWarnings();
                          }}
                        />}
                        {
                          <div className="text-danger">
                            {this.state.pricingFormulaError}
                          </div>
                        }
                        {/*this.tagifyRef.current && this.validator.message('Pricing Formula', this.tagifyRef.current.state.lastOriginalValueReported, 'required|pricingFormula', { className: 'text-danger' })*/}
                      </div>
                    </TabPane>
                  )}
                </TabContent>
                {this.props.activeStep === 3 &&
                  this.state.showValidatorWarning && (
                    <div className="text-danger text-center">
                      Please input all valid values!
                    </div>
                  )}
                <div className="separator mt-3 mb-3" />
                <div className="row fieldset">
                  <div className="col-12 text-right">
                    <button onClick={this.cancel} className="btn">
                      Cancel
                    </button>
                    {this.props.activeStep > 1 && (
                      <button
                        onClick={this.backStep}
                        className="btn create-item ml-2"
                      >
                        Back
                      </button>
                    )}
                    {this.props.activeStep <
                      eval(this.props.servicePricingType + "Steps").length && (
                        <button
                          onClick={this.nextStep}
                          className="btn create-item ml-2"
                        >
                          Next
                        </button>
                      )}
                    {this.props.activeStep ===
                      eval(this.props.servicePricingType + "Steps").length && (
                        <button
                          className="btn create-item ml-2"
                          onClick={
                            this.type === "edit"
                              ? this.updateService
                              : this.formatDataAndSubmitService
                          }
                        >
                          {texts[this.type].submitButton}
                        </button>
                      )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <CreateCategoryModal />
        <ChangeGlobalPricingDriverValuesModal
          {...this.state.driverProps}
          handleSubmitChangeGlobalPricingDriverValues={this.handleSubmitChangeGlobalPricingDriverValues.bind(
            this
          )}
        />
        <Modal
          toggle={() => {
            if (this.state.showChangeGlobalPricingDriverValues) {
              if (this.timeoutRef) clearTimeout(this.timeoutRef);
              this.timeoutRef = setTimeout(() => {
                window.scrollTo({
                  top: 0,
                  behavior: "smooth",
                });
              }, 400);
            }
            this.setState({
              showChangeGlobalPricingDriverValues:
                !this.state.showChangeGlobalPricingDriverValues,
            });
          }}
          className="change-global-pricing-driver-modal-content"
          isOpen={this.state.showChangeGlobalPricingDriverValues}
          size="xl"
        >
          <div className="card-body">
            <div
              className="flex mb-3"
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <h4>Global Pricing Drivers</h4>
              <i
                className="mdi mdi-close cursor-pointer font-size-24"
                onClick={() => {
                  if (this.timeoutRef) clearTimeout(this.timeoutRef);
                  this.timeoutRef = setTimeout(() => {
                    window.scrollTo({
                      top: 0,
                      behavior: "smooth",
                    });
                  }, 400);
                  this.setState({ showChangeGlobalPricingDriverValues: false });
                }}
              ></i>
            </div>
            <GlobalPricingDriversTable
              renderActionButtons={(item) => {
                const isAdded = this.state.globalPricingDrivers.find(
                  (c) =>
                    c._id === item._id ||
                    (c.globalDriverId?._id ?? c.globalDriverId) === item._id
                );

                return (
                  <button
                    onClick={() => {
                      if (isAdded)
                        return this.deleteGlobalPricingDriverFromService(item);
                      this.addGlobalPricingDriverToService(item);
                    }}
                    title="Add Driver"
                    className={
                      "btn btn-sm" + (isAdded ? " btn-danger" : " btn-dark")
                    }
                  >
                    {isAdded ? (
                      <i className="mdi mdi-delete" />
                    ) : (
                      <i className="ion ion-md-add" />
                    )}
                  </button>
                );
              }}
              getGlobalPricingDriver={this.props.getGlobalPricingDriver}
              setShowCreateGlobalPricingDriverModal={
                this.props.setShowCreateGlobalPricingDriverModal
              }
              drivers={this.props.globalDrivers.filter((c) =>
                ["Variation", "Slab"].includes(c.type)
              )}
              linkedServices={this.props.linkedServices}
              {...this.props}
            />
          </div>
        </Modal>
        {this.props.showCreateNewServiceSuccessModal && (
          <SweetAlert
            title=""
            success
            confirmBtnBsStyle="success"
            onConfirm={() => {
              this.props.setShowCreateNewServiceSuccessModal(false);
              this.props.history.replace("/services");
            }}
          >
            <div className="mb-2 success-alert-message">
              Service <b>{this.props.serviceName || ""}</b> has been{" "}
              {texts[this.type].actionName} successfully!
            </div>
          </SweetAlert>
        )}
        {this.props.showUpdateServiceConfirmModal &&
          this.props.serviceAffectedPackages.length > 0 && (
            <SweetAlert
              title=""
              warning
              cancelBtnBsStyle="warning"
              confirmBtnBsStyle="success"
              showCancel
              onConfirm={() => {
                this.props.setShowUpdateServiceConfirmModal(false);
                this.formatDataAndSubmitService();
              }}
              onCancel={() => {
                this.props.setShowUpdateServiceConfirmModal(false);
              }}
            >
              <div className="mb-2 success-alert-message text-left">
                <div>
                  Following packages may get affected on updating the service:
                </div>
                <ul>
                  {this.props.serviceAffectedPackages &&
                    this.props.serviceAffectedPackages.length > 0 &&
                    this.props.serviceAffectedPackages.map((item) => (
                      <li key={item.id}>{item.name}</li>
                    ))}
                </ul>
              </div>
            </SweetAlert>
          )}
        {this.props.showCreateNewServiceFailedModal && (
          <SweetAlert
            title=""
            danger
            confirmBtnBsStyle="success"
            onConfirm={() =>
              this.props.setShowCreateNewServiceFailedModal(false)
            }
          >{`Service with name ${this.props.serviceName} already exists!`}</SweetAlert>
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => state.Service;

export default withRouter(
  connect(mapStateToProps, {
    setActiveStep,
    addPricingDriver,
    deletePricingDriver,
    addVariationToPricingDriver,
    deleteVariationFromPricingDriver,
    addNumberRangeToPricingDriver,
    deleteNumberRangeFromPricingDriver,
    addSlabToPricingDriver,
    deleteSlabFromPricingDriver,
    onInputChange,
    getCategories,
    getService,
    submitService,
    resetCreateNewServiceForm,
    setShowCreateCategoryModal,
    setShowCreateNewServiceSuccessModal,
    getGlobalPricingDrivers,
    setShowUpdateServiceConfirmModal,
    setServiceLoading,
    setDefaultDriver,
    setShowCreateGlobalPricingDriverModal,
    setShowChangeGlobalPricingDriverValuesModal,
    setShowCreateNewServiceFailedModal,
    getGlobalPricingDriver,
  })(CreateNewService)
);
