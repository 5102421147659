import React from "react";
import MyEditor from "../MyEditor";

const TncBlockContent = (props) => {
	return (
		<MyEditor
			value={props.tncBlock}
			variable={"tncBlock"}
			onChange={props.onInputChange}
		/>
	);
}

export default TncBlockContent;
