import React, { Component } from "react";
import { Row, Col, Card, CardBody, Button } from "reactstrap";
import acceptInviteIMG from "../assets/images/accept-invite.jpg";
import Loader from "../components/Loader";
import axios from 'axios';

class HtmlProposalAccepted extends Component {
  constructor(props) {
    super(props);
    this.state={
      quotesData:null,quotesBasedOnId:null,isLinkValid:null
    }
  }


  async componentDidMount() {
    try {
      // const params = new URLSearchParams(window.location.search);
      // const quoteId = params.get('id');
      const path = window.location.pathname;
const parts = path.split('/');
const quoteId = parts[parts.length - 1].length === 24 ? parts[parts.length - 2] : parts[parts.length - 1];
const pkgId = parts[parts.length - 1].length === 24 ? parts[parts.length - 1] : null;

      const token = JSON.parse(localStorage.getItem('authUser'));
        
        const response = await axios.get(`https://proposal.outbooks.com/api/get-quotation/${atob(quoteId)}`
        // , {
        //   headers: {
        //     'Authorization': `Bearer ${token.stsTokenManager.accessToken}`
        //   }
        // }
        );
        const quotesBasedOnId = response.data.result;
        this.setState({ quotesBasedOnId });
        if (quotesBasedOnId[0].status === "accepted" || quotesBasedOnId[0].status === "declined") {
          this.setState({ isLinkValid: false });
        } else {
          const updateResponse = await axios.put('https://proposal.outbooks.com/api/update-quotation', { status: "accepted", id: atob(quoteId), packageId:pkgId }
          // , {
          //   headers: {
          //     'Authorization': `Bearer ${token.stsTokenManager.accessToken}`
          //   }
          // }
          );
          this.setState({ quotesData: updateResponse.data });
          this.setState({ isLinkValid: true });
        }
      
    } catch (error) {
      this.setState({ error: error.message });
    }
  }
  

  render() {
    return (
      <React.Fragment>
        { this.state.quotesData==null || this.state.quotesBasedOnId==null && <Loader />}
        { this.state.isLinkValid==null  && <Loader />}
        <div className="authentication-bg d-flex align-items-center pb-0 vh-100">
          <div className="content-center w-100">
            <div className="container">
              <Card className="mo-mt-2">
                <CardBody>
                  <Row className="align-items-center">
                    <Col lg="4" className="ml-auto">
                    <div className="ex-page-content">
    {this.state.isLinkValid ? (
        <h4 className="mb-4">
            Thank you for accepting the proposal, you will receive a call shortly to discuss further
        </h4>
    ) : this.state.quotesBasedOnId && this.state.quotesBasedOnId.length > 0 ? (
        this.state.quotesBasedOnId[0].status === "accepted" ? (
            <h4 className="mb-4">
                You have already accepted this proposal. No further action is required.
            </h4>
        ) : (
            <h4 className="mb-4">
                Sorry, you have already declined this proposal. Please feel free to call us to discuss further.
            </h4>
        )
    ) :""}
</div>
                    </Col>
                    <Col lg="5" className="mx-auto">
                      <img
                        src={acceptInviteIMG}
                        alt=""
                        className="img-fluid mx-auto d-block"
                      />
                    </Col>
                  </Row>

                </CardBody>
              </Card>
            </div>
          </div>
        </div>
      </React.Fragment>
    ) 
  }
}

export default HtmlProposalAccepted
