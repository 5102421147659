import React, { useEffect, useState } from "react";
import { withRouter, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import { TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";
import Loader from "../components/Loader";
import ReviewServices from "../components/ReviewServices";
import ViewQuoteClientDetails from "../components/Quote/ViewQuote/ViewQuoteClientDetails";
import {
  getContract,
  downloadContract,
  setActiveStep,
} from "../store/contract/actions";
import { useDispatch, useSelector } from "react-redux";

function cancel() {
  this.history.goBack();
}
const DisplayPdfsContract = (props) => {
  const dispatch = useDispatch();
  const contractStatus = useSelector(
    (state) => state?.Contract?.contractStatus
  );
  const contractPdf = useSelector((state) => state?.Contract?.contractPdf);

  useEffect(() => {
    props.getContract(atob(props.match.params.id));
    dispatch(getContract(atob(props.match.params.id)));
  }, []);

  if (!contractStatus) return <Loader />;
  // console.log(contractStatus)
  // console.log(contractPdf)
  return (
    <React.Fragment>
      {props.loading && <Loader />}
      <iframe
        src={props.contractPdf}
        style={{ height: "100vh", width: "100vw" }}
      />
    </React.Fragment>
  );
};

const mapStateToProps = (state) => state.Contract;

export default withRouter(
  connect(mapStateToProps, { getContract, downloadContract, setActiveStep })(
    DisplayPdfsContract
  )
);
