import React from "react";

const HeadingContent = (props) => {
	return (
		<div className="row fieldset">
			<div className="col-2">
				<label className="fieldset-label required">Heading</label>
			</div>
			<div className="col-10">
				<input value={props.heading || ""} onChange={(e) => props.onInputChange(`element${props.code}Heading`, e.target.value)} className="input-text" type="text" placeholder="Heading" />
				{props.validator.message(`element${props.code}Heading`, props.heading, '_required', { className: 'text-danger' })}
			</div>
		</div>
	);
}

export default HeadingContent;
