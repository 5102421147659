export const SET_CLIENT_LOADING = 'SET_CLIENT_LOADING';
export const ON_CLIENT_INPUT_CHANGE = 'ON_CLIENT_INPUT_CHANGE';
export const SET_SHOW_CREATE_CLIENT_MODAL = 'SET_SHOW_CREATE_CLIENT_MODAL';
export const SUBMIT_CLIENT = 'SUBMIT_CLIENT';
export const SUBMIT_CLIENT_SUCCESS = 'SUBMIT_CLIENT_SUCCESS';
export const SET_SHOW_CREATE_NEW_CLIENT_SUCCESS_MODAL = 'SET_SHOW_CREATE_NEW_CLIENT_SUCCESS_MODAL';
export const GET_CLIENTS = 'GET_CLIENTS';
export const GET_CLIENTS_SUCCESS = 'GET_CLIENTS_SUCCESS';
export const RESET_CREATE_NEW_CLIENT_FORM = 'RESET_CREATE_NEW_CLIENT_FORM';
export const GET_CLIENT = 'GET_CLIENT';
export const GET_CLIENT_SUCCESS = 'GET_CLIENT_SUCCESS';
export const SEARCH_CLIENT = 'SEARCH_CLIENT';
export const SEARCH_CLIENT_SUCCESS = 'SEARCH_CLIENT_SUCCESS';
