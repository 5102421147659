import React from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import { Modal, ModalBody } from 'reactstrap';
import { connect } from "react-redux";
import {setShowUpgradeModal} from "../store/common/actions";
import {useHistory} from "react-router-dom";

const UpgradeModal = props => {
	const history = useHistory();
	return (
		props.showUpgradeModal ? <SweetAlert
			title=""
			danger
			confirmBtnBsStyle="success"
			onConfirm={() => {
				// history.push('/Subscription');
				props.setShowUpgradeModal(false);
			}}
			onCancel={() => {
				props.setShowUpgradeModal(false);
			}}
		>
			{props.showUpgradeModal === 'sign' ? 'You have reached your monthly e-sign limit, Please upgrade your subscription to access the feature.' : 'Please upgrade your subscription to access the feature.'}
		</SweetAlert> : null
	);
}

const mapStateToProps = state => {
  const { showUpgradeModal, message } = state.Common;
  return { showUpgradeModal, message };
}

export default connect(mapStateToProps, {setShowUpgradeModal})(UpgradeModal);
