import React, { useEffect, useState } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import { Modal, ModalBody } from "reactstrap";
import { connect } from "react-redux";
import {
  setShowConfirmDeleteModal,
  deleteObject,
} from "../store/delete/actions";
import Loader from "./Loader";

const ConfirmDeleteModal = (props) => {
  const [flag, setFlag] = useState(false);

  useEffect(() => {
    if (props.servicesAffectedPackages || props.linkedServices) {
      setTimeout(() => {
        setFlag(true);
      }, 1000);
    }
    else {
      setFlag(true);
    }
    return () => {
      setFlag(false);
    };
  }, [props.showConfirmDeleteModal]);

  // if (props.showConfirmDeleteModal && !flag) {
  //   return <Loader />;
  // }

  return props.showConfirmDeleteModal ? (
    <SweetAlert
      title=""
      warning={
        props.servicesAffectedPackages?.length > 0 ||
        props.linkedServices?.length > 0 || props?.ContractCount > 0 || props?.QuoteCount > 0
      }
      danger={props.servicesAffectedPackages?.length == 0 && (!props?.ContractCount || props?.ContractCount == 0) && (!props?.QuoteCount || props?.QuoteCount == 0)}
      cancelBtnBsStyle={
        props.servicesAffectedPackages?.length > 0 ||
          props.linkedServices?.length > 0 || props?.ContractCount > 0 || props?.QuoteCount > 0
          ? "warning"
          : "danger"
      }
      confirmBtnBsStyle="success"
      showCancel
      showConfirm={
        props.servicesAffectedPackages?.length > 0 ||
          props.linkedServices?.length > 0 || props?.ContractCount > 0 || props?.QuoteCount > 0
          ? false
          : true
      }
      onConfirm={() => {
        props.deleteObject(props.id, props.module);
        props.setShowConfirmDeleteModal(false);
        setFlag(false);
      }}
      onCancel={() => {
        props.setShowConfirmDeleteModal(false);
        setFlag(false);
      }}
    >
      <> {(props.servicesAffectedPackages?.length > 0 || props.linkedServices?.length > 0 || props?.ContractCount > 0 || props?.QuoteCount > 0) ?
        props.servicesAffectedPackages?.length > 0 ? (
          <div className="mb-2 success-alert-message text-left">
            <div style={{ marginBottom: "1rem" }}>
              This service is used in below packages. You must remove the
              service from these packages before you can delete it.
            </div>
            <ul>
              {props.servicesAffectedPackages &&
                props.servicesAffectedPackages.length > 0 &&
                props.servicesAffectedPackages.map((item) => (
                  <li key={item.id}>{item.name}</li>
                ))}
            </ul>
          </div>
        ) :
          (
            <>
              {props.linkedServices &&
                props.linkedServices.length > 0 ? (
                <div className="mb-2 success-alert-message text-left">
                  <div style={{ marginBottom: "1rem" }}>
                    These services have been affected, please update them by reconfiguring these services.
                  </div>
                  <ul>
                    {props.linkedServices &&
                      props.linkedServices.length > 0 &&
                      props.linkedServices.map((item) => (
                        <li key={item}>{item}</li>
                      ))}
                  </ul>
                </div>) : (
                <>
                  <div style={{ marginBottom: "1rem" }}>
                    {`This service is used in some ${props?.QuoteCount > 0 ? `Quotes` : 'Contracts'}. You must remove the service from those ${props?.QuoteCount > 0 ? `Quotes` : 'Contracts'} before you can deactivate it.`}
                  </div>
                </>
              )}
            </>
          ) :
        <div>Are you sure you want to delete this?</div>
      }
      </>
    </SweetAlert>
  ) : null;
};

const mapStateToProps = (state) => {
  const { showConfirmDeleteModal, id, module } = state.Delete;
  return { showConfirmDeleteModal, id, module };
};

export default connect(mapStateToProps, {
  setShowConfirmDeleteModal,
  deleteObject,
})(ConfirmDeleteModal);
