import React, { Component } from "react";
import { Collapse } from "reactstrap";
import { Link, withRouter } from "react-router-dom";
import classname from "classnames";
import { getFirebaseBackend } from "../../helpers/authUtils";
import navMenu from "../../navMenu";
import axios from 'axios';

class Navbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      organisationData: null,
      error: null,
    };
    const fireBaseBackend = getFirebaseBackend();
    this.userRole = fireBaseBackend.getUserRole();
  }

  componentDidMount() {
    var matchingMenuItem = null;
    var ul = document.getElementById("navigation");
    var items = ul.getElementsByTagName("a");
    for (var i = 0; i < items.length; ++i) {
      if (this.props.location.pathname === items[i].pathname) {
        matchingMenuItem = items[i];
        break;
      }
    }
    if (matchingMenuItem) {
      this.activateParentDropdown(matchingMenuItem);
    }

    const token = JSON.parse(localStorage.getItem('authUser'));
  
    if (token && token.stsTokenManager && token.stsTokenManager.accessToken) {
      axios.get('https://proposal.outbooks.com/api/organisation/me', {
        headers: {
          'Authorization': `Bearer ${token.stsTokenManager.accessToken}`
        }
      })
      .then(response => {
        this.setState({ organisationData: response.data });
      })
      .catch(error => {
        this.setState({ error: error.message });
      });
    } else {
      this.setState({ error: 'Token not found or invalid.' });
    }
  }

  activateParentDropdown = item => {
    item.classList.add("active");
    const parent = item.parentElement;

    if (parent) {
      parent.classList.add("active"); // li
      const parent2 = parent.parentElement;
      parent2.classList.add("active"); // li
      const parent3 = parent2.parentElement;
      if (parent3) {
        parent3.classList.add("active"); // li
        const parent4 = parent3.parentElement;
        if (parent4) {
          parent4.classList.add("active"); // li
          const parent5 = parent4.parentElement;
          if (parent5) {
            parent5.classList.add("active"); // li
          }
        }
      }
    }
    return false;
  };

  renderNavMenu(navMenu) {
      let jsx = [];
      navMenu.forEach((item, index) => {
        if (item.roles.includes(this.userRole)) {
          const childLength = item.children?.filter(childItem => childItem.roles.includes(this.userRole)).length;
          if (item.title === "Engagement Letter" && this.state.organisationData?.result?.isVisible !== true) {
            return; // Skip rendering the item
        }
          console.log('childLength - ', item, childLength);
          if (!item.hasOwnProperty("children") || childLength === 0) {
            jsx.push(
              <li key={`${item.to}-${index}`} className="nav-item">
                <Link className="nav-link" to={item.to}>
                  <i className={`${item.icon} mr-2`}></i>{item.title}
                </Link>
              </li>
            );
          } else {
            let childJsx = [];
            item.children.forEach((childItem, childIndex) => {
              if (childItem.roles.includes(this.userRole)) {
                childJsx.push(
                  <div key={`${childItem.to}-${childIndex}`}>
                    <Link
                      to={childItem.to}
                      className="dropdown-item"
                    >
                      {childItem.title}
                    </Link>
                  </div>
                );
              }
            });
            jsx.push(
              <li key={`${item.to}-${index}`} className="nav-item dropdown mega-dropdown">
                <Link
                  className="nav-link dropdown-toggle arrow-none"
                  to={item.to}
                  onClick={e => {
                    e.preventDefault();
                    this.setState({
                      [item.childStateName]: !this.state[item.childStateName]
                    });
                  }}
                >
                  <i className={`${item.icon} mr-2`}></i> {item.title}
                </Link>
                {childJsx.length > 0 && <div
                  className={classname("dropdown-menu", {
                    // show: this.state[item.childStateName]
                  })}
                >
                  <div className="row">
                    <div className="col-lg-6">
                      {childJsx}
                    </div>
                  </div>
                </div>}
              </li>
            );
          }
        }
      });
      return jsx;
  }

  render() {
    return (
      <React.Fragment>
        <div className="topnav">
          <div className="container-fluid">
            <nav className="navbar navbar-light navbar-expand-lg topnav-menu">
              <Collapse
                isOpen={this.props.menuOpen}
                className="navbar-collapse"
              >
                <div id="navigation">
                  <ul className="navbar-nav">
                    {this.renderNavMenu.bind(this)(navMenu)}
                  </ul>
                </div>
              </Collapse>
            </nav>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(Navbar);
