import { takeEvery, fork, put, all, call } from 'redux-saga/effects';
import axios from 'axios';

import {
    SET_SHOW_API_ERROR_MODAL,
    GET_AVAILABLE_PAYMENT_METHODS
} from "./actionTypes";

import {
    setClientLoading,
    setContractLoading,
    setDashboardLoading,
    setDeleteLoading,
    setOrganisationLoading,
    setPackageLoading,
    setQuoteLoading,
    setRoleManagementLoading,
    setServiceLoading,
    setTemplateLoading,
    setTermsAndConditionTemplateLoading,
    setTextTemplateLoading,
    setUserLoading
} from "../actions";

import {
    getAvailablePaymentMethodsSuccess, setShowApiErrorModal as actionSetShowApiErrorModal
} from './actions';

// AUTH related methods
import { getFirebaseBackend } from '../../helpers/authUtils';

const fireBaseBackend = getFirebaseBackend();

function* setShowApiErrorModal({ payload: {isVisible, message}}) {
    yield put(setClientLoading(false));
    yield put(setContractLoading(false));
    yield put(setDashboardLoading(false));
    yield put(setDeleteLoading(false));
    yield put(setOrganisationLoading(false));
    yield put(setPackageLoading(false));
    yield put(setQuoteLoading(false));
    yield put(setRoleManagementLoading(false));
    yield put(setServiceLoading(false));
    yield put(setTemplateLoading(false));
    yield put(setTermsAndConditionTemplateLoading(false));
    yield put(setTextTemplateLoading(false));
    yield put(setUserLoading(false));
}

function* getAvailablePaymentMethods() {
    try {
        const accessToken = yield fireBaseBackend.getAccessToken();
        const response = yield axios({
            method: "GET",
            url: `available-payment-gateways`,
            headers: {authorization: `Bearer ${accessToken}`}
        });
        if (response.data.success) {
            yield put(getAvailablePaymentMethodsSuccess(response.data.result));
        } else {
            // yield put(createOrganisationFailed());
        }
    } catch (error) {
        yield put(actionSetShowApiErrorModal());
        console.log(error);
        // if (error.response && error.response.data && error.response.data.error) {
        //     yield put(loginError(error.response.data.error));
        // } else {
        //     yield put(loginError("Something went Wrong!"));
        // }
        // yield put(createOrganisationFailed());
    }
}

export function* watchSetShowApiErrorModal() {
    yield takeEvery(SET_SHOW_API_ERROR_MODAL, setShowApiErrorModal);
}

export function* watchGetAvailablePaymentMethods() {
    yield takeEvery(GET_AVAILABLE_PAYMENT_METHODS, getAvailablePaymentMethods);
}

function* commonSaga() {
    yield all([
        fork(watchSetShowApiErrorModal),
        fork(watchGetAvailablePaymentMethods)
    ]);
}

export default commonSaga;

