import {
  SET_QUOTE_LOADING,
  ON_QUOTE_INPUT_CHANGE,
  ADD_SERVICE_TO_A_LA_CARTE_PACKAGE,
  REMOVE_SERVICE_FROM_A_LA_CARTE_PACKAGE,
  SET_QUOTE_ACTIVE_STEP,
  PREVIEW_QUOTE,
  PREVIEW_QUOTE_SUCCESS,
  SAVE_QUOTATION_AS_DRAFT,
  SAVE_QUOTATION_AS_DRAFT_SUCCESS,
  SKIP_QUOTATION,
  SKIP_QUOTATION_SUCCESS,
  SEND_QUOTATION,
  SEND_QUOTATION_SUCCESS,
  SET_SHOW_CREATE_NEW_QUOTE_SUCCESS_MODAL,
  SET_SHOW_ADD_CUSTOM_PACKAGE_MODAL,
  CALCULATE_PACKAGE_PRICE,
  CALCULATE_PACKAGE_PRICE_SUCCESS,
  RESET_CREATE_NEW_QUOTE_FORM,
  GET_QUOTES,
  GET_QUOTES_SUCCESS,
  GET_QUOTE,
  GET_QUOTE_SUCCESS,
  GET_SENT_QUOTES,
  GET_SENT_QUOTES_SUCCESS,
  GET_SKIP_QUOTES,
  GET_SKIP_QUOTES_SUCCESS,
  ADD_PACKAGED_PRICING_PACKAGE,
  REMOVE_PACKAGED_PRICING_PACKAGE,
  REVIEW_PACKAGED_PRICING_PACKAGES,
  REVIEW_PACKAGED_PRICING_PACKAGES_SUCCESS,
  REVIEW_CUSTOM_PACKAGED_PRICING_PACKAGES,
  REVIEW_CUSTOM_PACKAGED_PRICING_PACKAGES_SUCCESS,
  GET_PRICING_DRIVERS_BY_PACKAGE_IDS,
  GET_PRICING_DRIVERS_BY_PACKAGE_IDS_SUCCESS,
  GET_PRICING_DRIVERS_FOR_CUSTOM_PACKAGED_PRICING,
  GET_PRICING_DRIVERS_FOR_CUSTOM_PACKAGED_PRICING_SUCCESS,
  ADD_CUSTOM_PACKAGE,
  SET_ADD_CUSTOM_PACKAGE_MODAL_SERVICES,
  EDIT_ADD_CUSTOM_PACKAGE_MODAL,
  SEARCH_QUOTE,
  SEARCH_QUOTE_SUCCESS,
  GET_QUOTE_STATUS,
  GET_QUOTE_CLIENT,
  GET_QUOTE_CLIENT_SUCCESS,
  RESET_SEARCH_INPUT,
} from "./actionTypes";

export const setQuoteLoading = (isLoading) => {
  return {
    type: SET_QUOTE_LOADING,
    payload: isLoading,
  };
};

export const onInputChange = (field, value, isPricingDrivers = false) => {
  return {
    type: ON_QUOTE_INPUT_CHANGE,
    payload: {
      field,
      value,
      isPricingDrivers,
    },
  };
};

export const searchQuote = (query) => {
  return {
    type: SEARCH_QUOTE,
    payload: query,
  };
};

export const searchQuoteSuccess = (quotes) => {
  return {
    type: SEARCH_QUOTE_SUCCESS,
    payload: quotes,
  };
};

export const addServiceToALaCartePackage = (service, pricingDriverKeys) => {
  return {
    type: ADD_SERVICE_TO_A_LA_CARTE_PACKAGE,
    payload: {
      service,
      pricingDriverKeys,
    },
  };
};

export const removeServiceFromALaCartePackage = (
  service,
  pricingDriverKeys
) => {
  return {
    type: REMOVE_SERVICE_FROM_A_LA_CARTE_PACKAGE,
    payload: {
      service,
      pricingDriverKeys,
    },
  };
};

export const setActiveStep = (step) => {
  return {
    type: SET_QUOTE_ACTIVE_STEP,
    payload: step,
  };
};

export const previewQuote = (data) => {
  return {
    type: PREVIEW_QUOTE,
    payload: data,
  };
};

export const previewQuoteSuccess = (data) => {
  return {
    type: PREVIEW_QUOTE_SUCCESS,
    payload: data,
  };
};

export const sendQuotation = (data) => {
  return {
    type: SEND_QUOTATION,
    payload: data,
  };
};

export const sendQuotationSuccess = () => {
  return {
    type: SEND_QUOTATION_SUCCESS,
  };
};

export const skipQuotation = (data, history) => {
  return {
    type: SKIP_QUOTATION,
    payload: { data, history },
  };
};

export const skipQuotationSuccess = (data, history) => {
  return {
    type: SKIP_QUOTATION_SUCCESS,
    payload: { data, history },
  };
};

export const saveQuotationAsDraft = (data) => {
  return {
    type: SAVE_QUOTATION_AS_DRAFT,
    payload: data,
  };
};

export const saveQuotationAsDraftSuccess = () => {
  return {
    type: SAVE_QUOTATION_AS_DRAFT_SUCCESS,
  };
};

export const setShowCreateNewQuoteSuccessModal = (isVisible) => {
  return {
    type: SET_SHOW_CREATE_NEW_QUOTE_SUCCESS_MODAL,
    payload: isVisible,
  };
};

export const getQuotes = (pageNum = 0, status = "",filter="") => {
  return {
    type: GET_QUOTES,
    payload: { pageNum, status, filter },
  };
};

export const getQuotesSuccess = (data) => {
  return {
    type: GET_QUOTES_SUCCESS,
    payload: data,
  };
};

export const getSentQuotes = () => {
  return {
    type: GET_SENT_QUOTES,
  };
};

export const getSentQuotesSuccess = (data) => {
  return {
    type: GET_SENT_QUOTES_SUCCESS,
    payload: data,
  };
};

export const getSkipQuotes = () => {
  return {
    type: GET_SKIP_QUOTES,
  };
};

export const getSkipQuotesSuccess = (data) => {
  return {
    type: GET_SKIP_QUOTES_SUCCESS,
    payload: data,
  };
};

export const calculatePackagePrice = (
  servicesByCategory,
  globalPricingDrivers,
  packageRecurringPrice,
  viaSelect,
  paymentFrequency,
  packageOneOffPrice
) => {
  return {
    type: CALCULATE_PACKAGE_PRICE,
    payload: {
      servicesByCategory,
      globalPricingDrivers,
      packageRecurringPrice,
      viaSelect,
      prevPaymentFrequency: paymentFrequency,
      packageOneOffPrice
    },
  };
};

export const calculatePackagePriceSuccess = (data) => {
  return {
    type: CALCULATE_PACKAGE_PRICE_SUCCESS,
    payload: data,
  };
};

export const resetCreateNewQuoteForm = () => {
  return {
    type: RESET_CREATE_NEW_QUOTE_FORM,
  };
};

export const getQuote = (id) => {
  return {
    type: GET_QUOTE,
    payload: id,
  };
};

export const getQuoteClient = (id) => {
  return {
    type: GET_QUOTE_CLIENT,
    payload: id,
  };
};

export const getQuoteClientSuccess = (data) => {
  return {
    type: GET_QUOTE_CLIENT_SUCCESS,
    payload: data,
  };
};

export const getQuoteStatus = (data) => {
  return {
    type: GET_QUOTE_STATUS,
    payload: data,
  };
};

export const getQuoteSuccess = (data) => {
  return {
    type: GET_QUOTE_SUCCESS,
    payload: data,
  };
};

export const addPackagedPricingPackage = (id) => {
  return {
    type: ADD_PACKAGED_PRICING_PACKAGE,
    payload: id,
  };
};

export const removePackagedPricingPackage = (id) => {
  return {
    type: REMOVE_PACKAGED_PRICING_PACKAGE,
    payload: id,
  };
};

export const reviewPackagedPricingPackages = (packages, paymentFrequency) => {
  return {
    type: REVIEW_PACKAGED_PRICING_PACKAGES,
    payload: {
      packages,
      paymentFrequency,
    },
  };
};

export const reviewPackagedPricingPackagesSuccess = (
  packages,
  servicesByCategory,
  globalPricingDrivers
) => {
  return {
    type: REVIEW_PACKAGED_PRICING_PACKAGES_SUCCESS,
    payload: {
      packages,
      servicesByCategory,
      globalPricingDrivers,
    },
  };
};

export const reviewCustomPackagedPricingPackages = (
  packageIds,
  customPackages,
  pricingDrivers,
  globalPricingDrivers,
  paymentFrequency
) => {
  return {
    type: REVIEW_CUSTOM_PACKAGED_PRICING_PACKAGES,
    payload: {
      packageIds,
      customPackages,
      pricingDrivers,
      globalPricingDrivers,
      paymentFrequency,
    },
  };
};

export const reviewCustomPackagedPricingPackagesSuccess = (
  packages,
  servicesByCategory
) => {
  return {
    type: REVIEW_CUSTOM_PACKAGED_PRICING_PACKAGES_SUCCESS,
    payload: {
      packages,
      servicesByCategory,
    },
  };
};

export const getPricingDriversByPackageIds = (packageIds) => {
  return {
    type: GET_PRICING_DRIVERS_BY_PACKAGE_IDS,
    payload: packageIds,
  };
};

export const getPricingDriversByPackageIdsSuccess = () => {
  return {
    type: GET_PRICING_DRIVERS_BY_PACKAGE_IDS_SUCCESS,
  };
};

export const getPricingDriversForCustomPackagedPricing = (
  packageIds,
  customPackages
) => {
  return {
    type: GET_PRICING_DRIVERS_FOR_CUSTOM_PACKAGED_PRICING,
    payload: {
      packageIds,
      customPackages,
    },
  };
};

export const getPricingDriversForCustomPackagedPricingSuccess = (data) => {
  return {
    type: GET_PRICING_DRIVERS_FOR_CUSTOM_PACKAGED_PRICING_SUCCESS,
    payload: data,
  };
};

export const setShowAddCustomPackageModal = (isVisible) => {
  return {
    type: SET_SHOW_ADD_CUSTOM_PACKAGE_MODAL,
    payload: isVisible,
  };
};

export const addCustomPackage = (code, name, services) => {
  return {
    type: ADD_CUSTOM_PACKAGE,
    payload: {
      code,
      name,
      services,
    },
  };
};

export const setAddCustomPackageModalServices = (services) => {
  return {
    type: SET_ADD_CUSTOM_PACKAGE_MODAL_SERVICES,
    payload: services,
  };
};

export const editAddCustomPackageModal = (id) => {
  return {
    type: EDIT_ADD_CUSTOM_PACKAGE_MODAL,
    payload: id,
  };
};

export const resetSearchInput = () => {
  return {
    type: RESET_SEARCH_INPUT,
  };
}