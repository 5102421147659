import React, { Component } from "react";
import { connect } from "react-redux";
import Loader from "../../components/Loader";
import UsersTable from "../../components/UsersTable";
import InviteUserModal from "../../components/Settings/InviteUserModal";
import EditUserRoleModal from "../../components/Settings/EditUserRoleModal";
import ConfirmDeleteModal from "../../components/ConfirmDeleteModal";
import DeleteSuccessModal from "../../components/DeleteSuccessModal";
import { getUsers, setShowInviteUserModal } from "../../store/user/actions";
import { setShowConfirmDeleteModal } from "../../store/delete/actions";
const delObject = "User";

class Users extends Component {
	componentDidMount() {
		this.props.getUsers();
	}
	render() {
    	return (
			<React.Fragment>
		        <div className="page-info-header">
		          <div className="container-fluid">
		            <div className="page-display-title">Users</div>
		            <div className="text-right flex1">
		            	<button onClick={() => this.props.setShowInviteUserModal(true)} className="btn btn-success create-item" disabled><i className="ion ion-md-add-circle"></i> <span>Invite New User</span></button>
		            </div>
		          </div>
		        </div>
		        <div className="container-fluid">
		          <div className="row">
		            <div className="col-12">
		              <div className="card cus-card">
		                <div className="card-body">
							<UsersTable delete={this.props.setShowConfirmDeleteModal} users={this.props.users} pagination={this.props.pagination} />
						</div>
		              </div>
		            </div>
		          </div>
		        </div>
		        <InviteUserModal />
		        <EditUserRoleModal />
		        <ConfirmDeleteModal />
        		{<DeleteSuccessModal delObject={delObject} />}
		        {this.props.loading && <Loader />}
			</React.Fragment>
    	);
	}
}

const mapStateToProps = state => {
  const { users, pagination, loading } = state.User;
  return { users, pagination, loading }
}

export default connect(mapStateToProps, { getUsers, setShowInviteUserModal, setShowConfirmDeleteModal })(Users);
