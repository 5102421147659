import {
  GET_NOTIFICATIONS,
  GET_NOTIFICATIONS_SUCCESS,
  LOAD_MORE_NOTIFICATIONS,
  LOAD_MORE_NOTIFICATIONS_SUCCESS,
  UPDATE_NOTIFICATIONS_READ_STATUS_SUCCESS,
} from "./actionTypes";

const initialState = {
  loading: false,
  notifications: [],
  unreadNotificationsCount: 0,
  lastReadAt: 0,
  totalCount: 0,
};

const notifications = (state = initialState, action) => {
  switch (action.type) {
    case GET_NOTIFICATIONS:
      return { ...state, loading: true };
    case GET_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        notifications: action.payload.notifications,
        unreadNotificationsCount: action.payload.unreadNotificationsCount,
        totalCount: action.payload?.totalCount ?? 0,
      };
    case LOAD_MORE_NOTIFICATIONS:
      return { ...state, loading: true };
    case LOAD_MORE_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        notifications: [...state.notifications, ...action.payload],
      };
    case UPDATE_NOTIFICATIONS_READ_STATUS_SUCCESS:
      return { ...state, unreadNotificationsCount: 0 };
    default:
      return state;
  }
};

export default notifications;
