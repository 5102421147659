import React, { Component } from "react";
import { connect } from "react-redux";
import {
  getAuditTrails,
  loadMoreAuditTrails,
} from "../store/auditTrail/actions";
import Loader from "../components/Loader";
import ActivityLogsTable from "../components/ActivityLogsTable";

class ActivityLogs extends Component {
  componentDidMount() {
    this.props.getAuditTrails();
  }
  render() {
    return (
      <React.Fragment>
        <div className="page-info-header">
          <div className="container-fluid">
            <div className="page-display-title">Activity Logs</div>
          </div>
        </div>
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 cus-card">
              <div className="card">
                <div className="card-body">
                  <ActivityLogsTable auditTrails={this.props.auditTrails} />
                  {this.props.totalCount > this.props.auditTrails.length ? (
                    <div className="text-center">
                      <button
                        onClick={() =>
                          this.props.loadMoreAuditTrails(
                            this.props.auditTrails[
                              this.props.auditTrails.length - 1
                            ]._id
                          )
                        }
                        className="btn btn-primary"
                      >
                        Load More
                      </button>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
        {this.props.loading && <Loader />}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  const { auditTrails, loading, totalCount } = state.AuditTrail;
  return { auditTrails, loading, totalCount };
};

export default connect(mapStateToProps, {
  getAuditTrails,
  loadMoreAuditTrails,
})(ActivityLogs);
