import React, { Component } from "react";
import { Row, Col, Card, Alert } from "reactstrap";
// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation";
import { connect } from "react-redux";
import SweetAlert from "react-bootstrap-sweetalert";
import Loader from "../../components/Loader";
import { withRouter, Link } from "react-router-dom";
import {
  userForgetPassword,
  resetForgotPasswordForm,
} from "../../store/actions";
// import images
import bg from "../../assets/images/bg.jpg";
import logoDark from "../../assets/images/outbooks.png";
import { Redirect } from "react-router-dom";

class ForgetPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.handleValidSubmit = this.handleValidSubmit.bind(this);
    this.form = React.createRef();
    this.state = {
      showSuccessAlert: false, // Added state to control the SweetAlert visibility
      redirectToLogin: false,
    };
  }

  handleValidSubmit(event, values) {
    this.setState({ userEmail: values.email }); // Store email in state
    this.props.userForgetPassword(values.email, (response) => {
      const isSuccess = response.data.success;
      if (isSuccess === true) {
        this.setState({ showSuccessAlert: true });
      } else {
        this.setState({ showSuccessAlert: false });
      }
    });
    this.form.current.reset();
  }

  componentDidMount() {
    this.props.resetForgotPasswordForm();
  }

  componentWillUnmount() {
    this.props.resetForgotPasswordForm();
  }
  closeSuccessAlert = () => {
    this.setState({ showSuccessAlert: false }, () => {
      this.setState({ redirectToLogin: true });
    });
  };

  render() {
    const { redirectToLogin } = this.state;

    if (redirectToLogin) {
      return <Redirect to="/login" />;
    }
    return (
      <React.Fragment>
        <div className="accountbg"></div>

        <div className="wrapper-page account-page-full">
          <Card className="shadow-none">
            <div className="card-block">
              {this.props.loading || this.state.showSuccessAlert === true ? (
                <Loader />
              ) : null}
              <div className="account-box">
                <div className="card-box shadow-none p-4">
                  <div className="p-2">
                    <div className="text-center mt-4">
                      <Link to="/">
                        <img src={logoDark} height="22" alt="logo" />
                      </Link>
                    </div>

                    <h4 className="font-size-18 mt-5 text-center">
                      Reset Password
                    </h4>

                    <AvForm
                      className="form-horizontal mt-4"
                      onValidSubmit={this.handleValidSubmit}
                      ref={this.form}
                    >
                      {!(
                        this.props.forgetError || this.props.forgetSuccessMsg
                      ) && (
                        <div className="alert alert-success mt-4" role="alert">
                          {/* Enter your Email and instructions will be sent to you! */}
                          Forgot your password? No Problem! Please enter your
                          email to reset the password. We will send you reset
                          instructions by email. You may need to check your spam
                          folder or unblock do-not-reply@proposal.outbooks.com.
                        </div>
                      )}

                      {this.props.forgetError && this.props.forgetError ? (
                        <Alert color="danger" style={{ marginTop: "13px" }}>
                          {this.props.forgetError}
                        </Alert>
                      ) : null}
                      {this.props.forgetSuccessMsg ? (
                        <Alert color="success" style={{ marginTop: "13px" }}>
                          {this.props.forgetSuccessMsg}
                        </Alert>
                      ) : null}

                      {!this.props.forgetSuccessMsg ? (
                        <AvField
                          name="email"
                          label="Email"
                          className="form-control"
                          placeholder="Enter email"
                          type="email"
                          validate={{
                            required: {
                              value: true,
                              errorMessage: "This field is required.",
                            },
                            pattern: {
                              value:
                                /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                              errorMessage: "Please enter a valid email.",
                            },
                          }}
                        />
                      ) : null}
                      {!this.props.forgetSuccessMsg ? (
                        <Row className="form-group">
                          <Col className="text-right">
                            <button
                              className="btn btn-primary w-md waves-effect waves-light"
                              type="submit"
                            >
                              Reset Password
                            </button>
                          </Col>
                        </Row>
                      ) : null}
                    </AvForm>

                    <div className="mt-5 pt-4 text-center">
                      <p>
                        Remember It ?{" "}
                        <Link
                          to="/login"
                          className="font-weight-medium text-primary"
                        >
                          {" "}
                          Sign In here{" "}
                        </Link>{" "}
                      </p>
                      <p>
                        © {new Date().getFullYear()} Outbooks
                        {/* Crafted with{" "}
                        <i className="mdi mdi-heart text-danger"></i> by
                        StartupFlux */}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Card>
        </div>
        {this.state.showSuccessAlert && (
          <SweetAlert
            title=""
            success
            confirmBtnBsStyle="success"
            // onConfirm={this.clearModal}
            onConfirm={this.closeSuccessAlert}
          >
            <div className="mb-2 success-alert-message">
              Success! We've sent the password reset link to your email address:{" "}
              {this.state.userEmail}
            </div>
          </SweetAlert>
        )}
      </React.Fragment>
    );
  }
}

const mapStatetoProps = (state) => {
  const { forgetError, forgetSuccessMsg, loading } = state.ForgetPassword;
  return { forgetError, forgetSuccessMsg, loading };
};

export default withRouter(
  connect(mapStatetoProps, { userForgetPassword, resetForgotPasswordForm })(
    ForgetPassword
  )
);
