import React from 'react';
import { withRouter } from "react-router-dom";

function renderTextTemplates(item, index) {
	return (
		<tr key={index}>
			<td>{item.name}</td>
			<td>{item.description}</td>
			<td className="serivce-actions text-right">
		      <button onClick={() => this.history.push(`/text-templates/edit/${item._id}`)} data-id={item._id} data-index={index} data-toggle="tooltip" title="Edit Service" className="btn btn-sm btn-dark"><i className="ion ion-md-create" /></button>
		      <button data-toggle="tooltip" title="Delete Template" className="btn btn-sm btn-dark ml-2"><i className="ion ion-md-trash" /></button>
		    </td>
		</tr>
	);
}

const TextTemplateListTable = (props) => {
	return (
		props.textTemplates.length>0 ? (<div className="table-rep-plugin">
			<div className="table-responsive mb-0" data-pattern="priority-columns">
			  <table className="table table-striped">
			  	<thead>
			  		<tr>
			  			<th>Name</th>
			  			<th>Description</th>
			  			<th></th>
			  		</tr>
			  	</thead>
			    <tbody>
			    	{props.textTemplates.map(renderTextTemplates.bind(props))}
			    </tbody>
			  </table>
			</div>
		</div>) : (<div className="text-center">No Text Templates Found!</div>)
	);
}

export default withRouter(TextTemplateListTable);
