import React, { Component } from "react";
import { connect } from "react-redux";
import { getSuperAdminOrganisations } from "../store/organisation/actions";
import OrganisationsTable from "../components/OrganisationsTable";
import { Input } from 'reactstrap';
import { Link } from "react-router-dom";
import {debounce} from "../Utils";




class Organisations extends Component {
  constructor(props) {
    super(props);
    this.func = debounce(this.props.getSuperAdminOrganisations, 800);
    this.state = {
      searchOrgValue: '',
      organisations: this.props.organisations,
      initialiseOrg: true,
      currentPage: 1,
      initialisePage: true
    }
  }
  onChange(field, value) {
    this.setState({ [field]: value });
  }
  getSuperAdminOrganisationsWithQuery(page_num, status) {
    this.props.getSuperAdminOrganisations(page_num, this.state.searchOrgValue);
  }
  componentDidMount() {
    this.props.getSuperAdminOrganisations();
  }
  componentDidUpdate(prevProps) {
    if (this.props.organisations != this.state.organisations && this.state.initialiseOrg) {
      this.onChange("organisations", this.props.organisations);
      this.onChange("initialiseOrg", false);
    }
    if (this.props && this.props.pagination && this.props.pagination.page_num != this.state.currentPage) {
      this.onChange("organisations", this.props.organisations);
      this.onChange("initialisePage", false);
      this.onChange("currentPage", this.props.pagination.page_num);
      // this.onChange("searchOrgValue", '');
    }
  }
  filterOrganisations(val) {
    this.onChange("searchOrgValue", val.target.value);
    // this.setState({currentPage: 1});

    // this.props.getSuperAdminOrganisations(0, val.target.value);
    this.func(0, val.target.value);
    // let myOrgs = this.props.organisations.filter(function (org) {
    //   return org.trading_business_name.toLowerCase().includes(val.target.value.toLowerCase())
    // })
    // this.onChange("organisations", myOrgs);
  }
  render() {
    return (
      <React.Fragment>
        <div className="page-info-header">
          <div className="container-fluid">
            <div className="page-display-title">Organisations</div>
            <div className="text-right flex1">
              <Input className="table-search" value={this.state.searchOrgValue} placeholder="Search User by Organisation" onChange={this.filterOrganisations.bind(this)} />
            </div>
          </div>
        </div>
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="card cus-card">
                <div className="card-body">
                  <OrganisationsTable organisations={this.props.organisations} onChange={this.onChange.bind(this)} pagination={this.props.pagination} getSuperAdminOrganisations={this.getSuperAdminOrganisationsWithQuery.bind(this)} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  const { organisations, loading, pagination } = state.Organisation;
  return { organisations, loading, pagination };
}

export default connect(mapStateToProps, { getSuperAdminOrganisations })(Organisations);
