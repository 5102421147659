import React from "react";
import { formatAddress, formatDate } from "../../../Utils";

const ViewQuoteClientDetails = props => {
	return (
		<div className="row">
			<div className="col-12">
				{props.data && <table className="table table-striped view-details-table">
					<tbody>
						<tr>
							<td>Business Type</td>
							<td className="text-right">{props.data.business_type}</td>
						</tr>
						{props.data.vatRegistered && <tr>
							<td>VAT Number</td>
							<td className="text-right">{props.data.vatNumber}</td>
						</tr>}
						<tr><td className="break-table" colSpan={2} /></tr>
						{props.data.business_type !== "individual" && <React.Fragment>
							<tr>
								<th colSpan={2}>Trading Details</th>
							</tr>
							<tr>
								<td>Trading Name</td>
								<td className="text-right">{props.data.trading_business_name}</td>
							</tr>
							<tr>
								<td>Trading Address</td>
								<td className="text-right">{formatAddress(props.data.trading_address.premises, props.data.trading_address.address_line_1, props.data.trading_address.address_line_2, props.data.trading_address.locality, props.data.trading_address.region, props.data.trading_address.country, props.data.trading_address.postcode)}</td>
							</tr>
							<tr><td className="break-table" colSpan={2} /></tr>
						</React.Fragment>}
						{["llp", "ltd"].includes(props.data.business_type) && <React.Fragment>
							<tr>
								<th colSpan={2}>Company Details</th>
							</tr>
							<tr>
								<td>Company Name</td>
								<td className="text-right">{props.data.company.company_name}</td>
							</tr>
							<tr>
								<td>Company Number</td>
								<td className="text-right">{props.data.company.company_number}</td>
							</tr>
							<tr>
								<td>Company Incorporated In</td>
								<td className="text-right">{props.data.company.incorporated_in}</td>
							</tr>
							<tr>
								<td>Company Incorporation Date</td>
								<td className="text-right">{formatDate(props.data.company.incorporation_date)}</td>
							</tr>
							<tr>
								<td>Company Registered Office Address</td>
								<td className="text-right">{formatAddress(props.data.company.registered_office_address.premises, props.data.company.registered_office_address.address_line_1, props.data.company.registered_office_address.address_line_2, props.data.company.registered_office_address.locality, props.data.company.registered_office_address.region, props.data.company.registered_office_address.country, props.data.company.registered_office_address.postcode)}</td>
							</tr>
							<tr><td className="break-table" colSpan={2} /></tr>
						</React.Fragment>}
						{/* <React.Fragment>
							<tr>
								<th colSpan={2}>Contact Details</th>
							</tr>
							<tr>
								<td>First Name</td>
								<td className="text-right">{props.data.contact.first_name}</td>
							</tr>
							<tr>
								<td>Last Name</td>
								<td className="text-right">{props.data.contact.last_name}</td>
							</tr>
							<tr>
								<td>Email</td>
								<td className="text-right">{props.data.contact.email}</td>
							</tr>
							<tr>
								<td>Phone</td>
								<td className="text-right">{props.data.contact.phone && props.data.contact.phone.code} {props.data.contact.phone && props.data.contact.phone.number}</td>
							</tr>
						</React.Fragment> */}
					</tbody>
				</table>}
			</div>
		</div>
	);
}

export default ViewQuoteClientDetails;
