import React, { Component } from "react";
import { Input } from 'reactstrap';
import { Link } from "react-router-dom";
import { AvForm, AvField, AvGroup, AvInput } from "availity-reactstrap-validation";

class CreateNewClient extends Component {
  render() {
    return (
      <React.Fragment>
        <div className="container-fluid">
          <div className="row align-items-center">
            <div className="col-12">
              <div className="page-title-box">
                <h4 className="font-size-18">Create New Prospect</h4>
                <ol className="breadcrumb mb-0">
                  <li className="breadcrumb-item">
                    <Link to="/">Home</Link>
                  </li>
                  <li className="breadcrumb-item active">Create New Prospect</li>
                </ol>
              </div>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <AvForm>
                    <h4>Create New Prospect</h4>
                    <div className="separator mb-3" />
                    <div className="create-client-content">
                      <div className="row">
                        <div className="col-3">
                          <label htmlFor="clientType">Prospect Type</label>
                        </div>
                        <div className="col-9">
                          <AvField
                            name="clientType"
                            className="form-control"
                            placeholder="Select Prospect Type"
                            type="select"
                            required
                          >
                            <option value="">Select Prospect Type</option>
                            <option value="Individual">Individual</option>
                            <option value="Sole Trader">Sole Trader</option>
                            <option value="Partnership">Partnership</option>
                            <option value="LLP">LLP</option>
                            <option value="Company">Company</option>
                          </AvField>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-3">
                          <label htmlFor="primaryContactSalutation">Primary Contact</label>
                        </div>
                        <div className="col-9">
                          <div className="row">
                            <div className="col-4">
                              <AvField
                                name="primaryContactSalutation"
                                className="form-control"
                                placeholder="Saluatation"
                                type="select"
                                required
                              >
                                <option>Saluatation</option>
                                <option>Mr.</option>
                                <option>Mrs.</option>
                                <option>Ms.</option>
                                <option>Miss.</option>
                                <option>Dr.</option>
                              </AvField>
                            </div>
                            <div className="col-4">
                              <AvField
                                name="primaryContactFirstName"
                                className="form-control"
                                placeholder="First Name"
                                type="text"
                                required
                              />
                            </div>
                            <div className="col-4">
                              <AvField
                                name="primaryContactLastName"
                                className="form-control"
                                placeholder="Last Name"
                                type="text"
                                required
                              />
                            </div>
                          </div>
                          
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-3">
                          <label htmlFor="companyName">Company Name</label>
                        </div>
                        <div className="col-9">
                          <AvField
                            name="companyName"
                            className="form-control"
                            placeholder="Enter Company Name"
                            type="text"
                            required
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-3">
                          <label htmlFor="clientEmail">Prospect Email</label>
                        </div>
                        <div className="col-9">
                          <AvField
                            name="clientEmail"
                            className="form-control"
                            placeholder="Enter Prospect Email"
                            type="text"
                            required
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-3">
                          <label htmlFor="clientPhone">Prospect Phone</label>
                        </div>
                        <div className="col-9">
                          <AvField
                            name="clientPhone"
                            className="form-control"
                            placeholder="Enter Prospect Phone"
                            type="text"
                            required
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-3">
                          <label htmlFor="website">Website</label>
                        </div>
                        <div className="col-9">
                          <AvField
                            name="website"
                            className="form-control"
                            placeholder="Enter Website"
                            type="text"
                            required
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-3">
                          <label className="font-size-125">Address</label>
                        </div>
                        <div className="col-9"></div>
                        <div className="col-6">
                          <AvField
                            label="Address Line 1"
                            name="addressLine1"
                            className="form-control"
                            placeholder="Enter Address Line 1"
                            type="text"
                            required
                          />
                        </div>
                        <div className="col-6">
                          <AvField
                            label="Address Line 2"
                            name="addressLine2"
                            className="form-control"
                            placeholder="Enter Address Line 2"
                            type="text"
                            required
                          />
                        </div>
                        <div className="col-6">
                          <AvField
                            label="City"
                            name="city"
                            className="form-control"
                            placeholder="Enter City"
                            type="text"
                            required
                          />
                        </div>
                        <div className="col-6">
                          <AvField
                            label="State"
                            name="state"
                            className="form-control"
                            placeholder="Enter State"
                            type="text"
                            required
                          />
                        </div>
                        <div className="col-6">
                          <AvField
                            label="Zip Code"
                            name="zipCode"
                            className="form-control"
                            placeholder="Enter Zip Code"
                            type="text"
                            required
                          />
                        </div>
                      </div>
                    </div>
                    <div className="create-client-footer text-right">
                      <button className="btn btn-secondary">Cancel</button>
                      <button type="submit" className="btn btn-success ml-2">Save Prospect</button>
                    </div>
                  </AvForm>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default CreateNewClient;
