export const SET_USER_LOADING = "SET_USER_LOADING";
export const ON_USER_INPUT_CHANGE = "ON_USER_INPUT_CHANGE";
export const GET_USERS = "GET_USERS";
export const GET_USERS_SUCCESS = "GET_USERS_SUCCESS";
export const INVITE_USER = "INVITE_USER";
export const INVITE_USER_SUCCESS = "INVITE_USER_SUCCESS";
export const SET_SHOW_INVITE_USER_MODAL = "SET_SHOW_INVITE_USER_MODAL";
export const SET_SHOW_INVITE_NEW_USER_SUCCESS_MODAL =
  "SET_SHOW_INVITE_NEW_USER_SUCCESS_MODAL";
export const SET_SHOW_EDIT_USER_MODAL = "SET_SHOW_EDIT_USER_MODAL";
export const ACCEPT_INVITE = "ACCEPT_INVITE";
export const ACCEPT_INVITE_SUCCESS = "ACCEPT_INVITE_SUCCESS";
export const USER_INVITE_ERROR = "USER_INVITE_ERROR";
export const UPDATE_USER_ROLE = "UPDATE_USER_ROLE";
export const UPDATE_USER_ROLE_SUCCESS = "UPDATE_USER_ROLE_SUCCESS";
export const SET_SHOW_EDIT_USER_SUCCESS_MODAL =
  "SET_SHOW_EDIT_USER_SUCCESS_MODAL";
export const RESET_INVITE_USER_FORM = "RESET_INVITE_USER_FORM";
export const UPDATE_USER = "UPDATE_USER";
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";
export const VALIDATE_ACCEPT_TOKEN = "VALIDATE_ACCEPT_TOKEN";
export const VALIDATE_ACCEPT_TOKEN_SUCCESS = "VALIDATE_ACCEPT_TOKEN_SUCCESS";
export const VALIDATE_ACCEPT_TOKEN_FAIL = "VALIDATE_ACCEPT_TOKEN_FAIL";
