import React from "react";

const AddElementBlock = (props) => {
	return (
		<div className="element-block add-element-block">
			<a onClick={props.onClick} className="add-element-icon" title="Add Element">
				<i className="ion ion-md-add"></i>
			</a>
		</div>
	);
}

export default AddElementBlock;
