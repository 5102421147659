import { takeEvery, fork, put, all, call } from "redux-saga/effects";
import axios from "axios";

import {
  PREVIEW_QUOTE,
  SEND_QUOTATION,
  SKIP_QUOTATION,
  SAVE_QUOTATION_AS_DRAFT,
  CALCULATE_PACKAGE_PRICE,
  GET_QUOTES,
  GET_SENT_QUOTES,
  GET_SKIP_QUOTES,
  GET_QUOTE,
  REVIEW_PACKAGED_PRICING_PACKAGES,
  REVIEW_CUSTOM_PACKAGED_PRICING_PACKAGES,
  GET_PRICING_DRIVERS_BY_PACKAGE_IDS,
  GET_PRICING_DRIVERS_FOR_CUSTOM_PACKAGED_PRICING,
  SEARCH_QUOTE,
  GET_QUOTE_CLIENT,
} from "./actionTypes";

import {
  previewQuoteSuccess,
  sendQuotationSuccess,
  calculatePackagePriceSuccess,
  getQuotesSuccess,
  getQuoteSuccess,
  getSentQuotesSuccess,
  getSkipQuotesSuccess,
  saveQuotationAsDraftSuccess,
  reviewPackagedPricingPackagesSuccess,
  reviewCustomPackagedPricingPackagesSuccess,
  getPricingDriversByPackageIdsSuccess,
  getPricingDriversForCustomPackagedPricingSuccess,
  searchQuoteSuccess,
  skipQuotationSuccess,
  getQuoteStatus,
  getQuoteClientSuccess,
} from "./actions";
import { setShowApiErrorModal } from "../common/actions";
import { getGlobalDriversByServicesSuccess } from "../service/actions";

// AUTH related methods
import { getFirebaseBackend } from "../../helpers/authUtils";

const fireBaseBackend = getFirebaseBackend();

function* previewQuote({ payload: data }) {
  try {
    const accessToken = yield fireBaseBackend.getAccessToken();
    const response = yield axios({
      method: "POST",
      url: `preview-quote`,
      headers: { authorization: `Bearer ${accessToken}` },
      data,
    });
    if (response.data.success) {
      yield put(previewQuoteSuccess(response.data.result));
    } else {
      // yield put(createOrganisationFailed());
    }
  } catch (error) {
    yield put(setShowApiErrorModal());
    // if (error.response && error.response.data && error.response.data.error) {
    //     yield put(loginError(error.response.data.error));
    // } else {
    //     yield put(loginError("Something went Wrong!"));
    // }
    // yield put(createOrganisationFailed());
  }
}

function* sendQuotation({ payload: data }) {
  try {
    const accessToken = yield fireBaseBackend.getAccessToken();
    const response = yield axios({
      method: "POST",
      url: `send-quotation`,
      headers: { authorization: `Bearer ${accessToken}` },
      data,
    });
    if (response.data.success) {
      yield put(sendQuotationSuccess());
    } else {
      // yield put(createOrganisationFailed());
    }
  } catch (error) {
    yield put(setShowApiErrorModal());
    // if (error.response && error.response.data && error.response.data.error) {
    //     yield put(loginError(error.response.data.error));
    // } else {
    //     yield put(loginError("Something went Wrong!"));
    // }
    // yield put(createOrganisationFailed());
  }
}

function* skipQuotation({ payload: { data, history } }) {
  try {
    const accessToken = yield fireBaseBackend.getAccessToken();
    const response = yield axios({
      method: "POST",
      url: `skip-quotation`,
      headers: { authorization: `Bearer ${accessToken}` },
      data,
    });
    if (response.data.success) {
      yield put(skipQuotationSuccess(response.data.result, history));
      history.push({
        pathname: "/contracts/new",
        state: {
          quoteId: response.data.result._id,
          quoteType: response.data.result.type,
          quoteClient: response.data.result.client,
          quoteClientBusinessType:
            response.data.result.data.client.business_type,
          skip: true,
          recurringPriceDiscountedPricePercentage: data.recurringPriceDiscountedPricePercentage,
          oneOffDiscountedPricePercentage: data.oneOffDiscountedPricePercentage,
        },
      });
    }
  } catch (error) {
    yield put(setShowApiErrorModal());
  }
}

function* saveQuotationAsDraft({ payload: data }) {
  try {
    const accessToken = yield fireBaseBackend.getAccessToken();
    const response = yield axios({
      method: "POST",
      url: `save-quotation-as-draft`,
      headers: { authorization: `Bearer ${accessToken}` },
      data,
    });
    if (response.data.success) {
      yield put(saveQuotationAsDraftSuccess());
    } else {
      // yield put(createOrganisationFailed());
    }
  } catch (error) {
    yield put(setShowApiErrorModal());
    // if (error.response && error.response.data && error.response.data.error) {
    //     yield put(loginError(error.response.data.error));
    // } else {
    //     yield put(loginError("Something went Wrong!"));
    // }
    // yield put(createOrganisationFailed());
  }
}

function* calculatePackagePrice({
  payload: { servicesByCategory, globalPricingDrivers, packageRecurringPrice, viaSelect, prevPaymentFrequency, packageOneOffPrice },
}) {
  try {
    const accessToken = yield fireBaseBackend.getAccessToken();
    const response = yield axios({
      method: "POST",
      url: `calculate-price`,
      headers: { authorization: `Bearer ${accessToken}` },
      data: {
        servicesByCategory,
        globalPricingDrivers,
        packageRecurringPrice,
        viaSelect,
        prevPaymentFrequency,
        packageOneOffPrice
      },
    });
    if (response.data.success) {
      yield put(calculatePackagePriceSuccess(response.data.result));
    } else {
      // yield put(createOrganisationFailed());
    }
  } catch (error) {
    yield put(setShowApiErrorModal());
    // if (error.response && error.response.data && error.response.data.error) {
    //     yield put(loginError(error.response.data.error));
    // } else {
    //     yield put(loginError("Something went Wrong!"));
    // }
    // yield put(createOrganisationFailed());
  }
}

function* reviewPackagedPricingPackages({
  payload: { packages, paymentFrequency },
}) {
  try {
    const accessToken = yield fireBaseBackend.getAccessToken();
    const response = yield axios({
      method: "POST",
      url: `review-packaged-pricing-packages`,
      headers: { authorization: `Bearer ${accessToken}` },
      data: {
        packages,
        paymentFrequency,
      },
    });
    if (response.data.success) {
      yield put(
        reviewPackagedPricingPackagesSuccess(
          response.data.result.packages,
          response.data.result.servicesByCategory,
          response.data.result.globalPricingDrivers
        )
      );
    } else {
      // yield put(createOrganisationFailed());
    }
  } catch (error) {
    yield put(setShowApiErrorModal());
    // if (error.response && error.response.data && error.response.data.error) {
    //     yield put(loginError(error.response.data.error));
    // } else {
    //     yield put(loginError("Something went Wrong!"));
    // }
    // yield put(createOrganisationFailed());
  }
}

function* reviewCustomPackagedPricingPackages({
  payload: {
    packageIds,
    customPackages,
    pricingDrivers,
    globalPricingDrivers,
    paymentFrequency,
  },
}) {
  try {
    const accessToken = yield fireBaseBackend.getAccessToken();
    const response = yield axios({
      method: "POST",
      url: `review-custom-packaged-pricing-packages`,
      headers: { authorization: `Bearer ${accessToken}` },
      data: {
        packageIds,
        customPackages,
        pricingDrivers,
        globalPricingDrivers,
        paymentFrequency,
      },
    });
    if (response.data.success) {
      yield put(
        reviewCustomPackagedPricingPackagesSuccess(
          response.data.result.packages,
          response.data.result.servicesByCategory
        )
      );
    } else {
      // yield put(createOrganisationFailed());
    }
  } catch (error) {
    yield put(setShowApiErrorModal());
    // if (error.response && error.response.data && error.response.data.error) {
    //     yield put(loginError(error.response.data.error));
    // } else {
    //     yield put(loginError("Something went Wrong!"));
    // }
    // yield put(createOrganisationFailed());
  }
}

function* getQuotes({ payload: data }) {
  try {
    const accessToken = yield fireBaseBackend.getAccessToken();
    const response = yield axios({
      method: "GET",
      url: `quotes?page_num=${data.pageNum}&status=${data.status}&filter=${data.filter}`,
      headers: { authorization: `Bearer ${accessToken}` },
    });
    if (response.data.success) {
      yield put(getQuotesSuccess(response.data.result));
    } else {
      // yield put(createOrganisationFailed());
    }
  } catch (error) {
    yield put(setShowApiErrorModal());
    console.log(error);
    // if (error.response && error.response.data && error.response.data.error) {
    //     yield put(loginError(error.response.data.error));
    // } else {
    //     yield put(loginError("Something went Wrong!"));
    // }
    // yield put(createOrganisationFailed());
  }
}

function* getSkipQuotes() {
  try {
    const accessToken = yield fireBaseBackend.getAccessToken();
    const response = yield axios({
      method: "GET",
      url: `quotes?status=skipped`,
      headers: { authorization: `Bearer ${accessToken}` },
    });
    if (response.data.success) {
      yield put(getSkipQuotesSuccess(response.data.result));
    } else {
    }
  } catch (error) {
    yield put(setShowApiErrorModal());
  }
}

function* getSentQuotes() {
  try {
    const accessToken = yield fireBaseBackend.getAccessToken();
    const response = yield axios({
      method: "GET",
      url: `quotes?status=sent`,
      headers: { authorization: `Bearer ${accessToken}` },
    });
    if (response.data.success) {
      yield put(getSentQuotesSuccess(response.data.result));
    } else {
      // yield put(createOrganisationFailed());
    }
  } catch (error) {
    yield put(setShowApiErrorModal());
    console.log(error);
    // if (error.response && error.response.data && error.response.data.error) {
    //     yield put(loginError(error.response.data.error));
    // } else {
    //     yield put(loginError("Something went Wrong!"));
    // }
    // yield put(createOrganisationFailed());
  }
}

function* getQuote({ payload: id }) {
  try {
    const accessToken = yield fireBaseBackend.getAccessToken();
    const response = yield axios({
      method: "GET",
      url: `quote/${id}`,
      headers: { authorization: `Bearer ${accessToken}` },
    });
    if (response.data.success) {
      yield put(getQuoteSuccess(response.data.result));
      yield put(getQuoteStatus(response));
    } else {
      // yield put(createOrganisationFailed());
    }
  } catch (error) {
    yield put(setShowApiErrorModal());
    console.log(error);
    // if (error.response && error.response.data && error.response.data.error) {
    //     yield put(loginError(error.response.data.error));
    // } else {
    //     yield put(loginError("Something went Wrong!"));
    // }
    // yield put(createOrganisationFailed());
  }
}

function* getPricingDriversByPackageIds({ payload: packageIds }) {
  try {
    const accessToken = yield fireBaseBackend.getAccessToken();
    const response = yield axios({
      method: "POST",
      url: `pricing-drivers-by-package-ids`,
      headers: { authorization: `Bearer ${accessToken}` },
      data: packageIds,
    });
    if (response.data.success) {
      yield put(getPricingDriversByPackageIdsSuccess());
    } else {
      // yield put(createOrganisationFailed());
    }
  } catch (error) {
    yield put(setShowApiErrorModal());
    // if (error.response && error.response.data && error.response.data.error) {
    //     yield put(loginError(error.response.data.error));
    // } else {
    //     yield put(loginError("Something went Wrong!"));
    // }
    // yield put(createOrganisationFailed());
  }
}

function* getPricingDriversForCustomPackagedPricing({
  payload: { packageIds, customPackages },
}) {
  try {
    const accessToken = yield fireBaseBackend.getAccessToken();
    const response = yield axios({
      method: "POST",
      url: `pricing-drivers-for-custom-packaged-pricing`,
      headers: { authorization: `Bearer ${accessToken}` },
      data: {
        packageIds,
        customPackages,
      },
    });
    if (response.data.success) {
      yield put(
        getPricingDriversForCustomPackagedPricingSuccess(
          response.data.result.servicesByCategory
        )
      );
      yield put(
        getGlobalDriversByServicesSuccess(
          response.data.result.requiredGlobalPricingDrivers
        )
      );
    } else {
      // yield put(createOrganisationFailed());
    }
  } catch (error) {
    yield put(setShowApiErrorModal());
    // if (error.response && error.response.data && error.response.data.error) {
    //     yield put(loginError(error.response.data.error));
    // } else {
    //     yield put(loginError("Something went Wrong!"));
    // }
    // yield put(createOrganisationFailed());
  }
}

function* searchQuote({ payload: query }) {
  try {
    const accessToken = yield fireBaseBackend.getAccessToken();
    const response = yield axios({
      method: "GET",
      url: `search/quote?query=${query}`,
      headers: { authorization: `Bearer ${accessToken}` },
    });
    if (response.data.success) {
      yield put(searchQuoteSuccess(response.data.result));
    } else {
      // yield put(createOrganisationFailed());
    }
  } catch (error) {
    yield put(setShowApiErrorModal());
    console.log(error);
    // if (error.response && error.response.data && error.response.data.error) {
    //     yield put(loginError(error.response.data.error));
    // } else {
    //     yield put(loginError("Something went Wrong!"));
    // }
    // yield put(createOrganisationFailed());
  }
}

function* getQuoteClient({ payload: id }) {
  try {
    const response = yield axios({
      method: "GET",
      url: `quote-client/${id}`,
    });
    if (response.data.success) {
      yield put(getQuoteClientSuccess(response.data.result));
      // yield put(getQuoteStatus(response));
    } else {
      // yield put(createOrganisationFailed());
    }
  } catch (error) {
    yield put(setShowApiErrorModal());
    console.log(error);
    // if (error.response && error.response.data && error.response.data.error) {
    //     yield put(loginError(error.response.data.error));
    // } else {
    //     yield put(loginError("Something went Wrong!"));
    // }
    // yield put(createOrganisationFailed());
  }
}

export function* watchPreviewQuote() {
  yield takeEvery(PREVIEW_QUOTE, previewQuote);
}

export function* watchSendQuotation() {
  yield takeEvery(SEND_QUOTATION, sendQuotation);
}

export function* watchSkipQuotation() {
  yield takeEvery(SKIP_QUOTATION, skipQuotation);
}

export function* watchSaveQuotationAsDraft() {
  yield takeEvery(SAVE_QUOTATION_AS_DRAFT, saveQuotationAsDraft);
}

export function* watchCalculatePackagePrice() {
  yield takeEvery(CALCULATE_PACKAGE_PRICE, calculatePackagePrice);
}

export function* watchGetQuotes() {
  yield takeEvery(GET_QUOTES, getQuotes);
}

export function* watchGetSentQuotes() {
  yield takeEvery(GET_SENT_QUOTES, getSentQuotes);
}

export function* watchGetSkipQuotes() {
  yield takeEvery(GET_SKIP_QUOTES, getSkipQuotes);
}

export function* watchGetQuote() {
  yield takeEvery(GET_QUOTE, getQuote);
}

export function* watchReviewPackagedPricingPackages() {
  yield takeEvery(
    REVIEW_PACKAGED_PRICING_PACKAGES,
    reviewPackagedPricingPackages
  );
}

export function* watchReviewCustomPackagedPricingPackages() {
  yield takeEvery(
    REVIEW_CUSTOM_PACKAGED_PRICING_PACKAGES,
    reviewCustomPackagedPricingPackages
  );
}

export function* watchGetPricingDriversByPackageIds() {
  yield takeEvery(
    GET_PRICING_DRIVERS_BY_PACKAGE_IDS,
    getPricingDriversByPackageIds
  );
}

export function* watchGetPricingDriversForCustomPackagedPricing() {
  yield takeEvery(
    GET_PRICING_DRIVERS_FOR_CUSTOM_PACKAGED_PRICING,
    getPricingDriversForCustomPackagedPricing
  );
}

export function* watchSearchQuote() {
  yield takeEvery(SEARCH_QUOTE, searchQuote);
}

export function* watchGetQuoteClient() {
  yield takeEvery(GET_QUOTE_CLIENT, getQuoteClient);
}

function* quoteSaga() {
  yield all([
    fork(watchPreviewQuote),
    fork(watchSendQuotation),
    fork(watchSkipQuotation),
    fork(watchCalculatePackagePrice),
    fork(watchGetQuotes),
    fork(watchGetSentQuotes),
    fork(watchGetSkipQuotes),
    fork(watchGetQuote),
    fork(watchSaveQuotationAsDraft),
    fork(watchReviewPackagedPricingPackages),
    fork(watchReviewCustomPackagedPricingPackages),
    fork(watchGetPricingDriversByPackageIds),
    fork(watchGetPricingDriversForCustomPackagedPricing),
    fork(watchSearchQuote),
    fork(watchGetQuoteClient),
  ]);
}

export default quoteSaga;
