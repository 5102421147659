export const SET_PACKAGE_LOADING = 'SET_PACKAGE_LOADING';
export const ON_PACKAGE_INPUT_CHANGE = 'ON_PACKAGE_INPUT_CHANGE';
export const SET_PACKAGE_ACTIVE_STEP = 'SET_PACKAGE_ACTIVE_STEP';
export const GET_PACKAGES = 'GET_PACKAGES';
export const GET_PACKAGES_SUCCESS = 'GET_PACKAGES_SUCCESS';
export const SUBMIT_PACKAGE = 'SUBMIT_PACKAGE';
export const SUBMIT_PACKAGE_SUCCESS = 'SUBMIT_PACKAGE_SUCCESS';
export const SET_SHOW_CREATE_NEW_PACKAGE_SUCCESS_MODAL = 'SET_SHOW_CREATE_NEW_PACKAGE_SUCCESS_MODAL';
export const ADD_SERVICE_TO_PACKAGE = 'ADD_SERVICE_TO_PACKAGE';
export const REMOVE_SERVICE_FROM_PACKAGE = 'REMOVE_SERVICE_FROM_PACKAGE';
export const GET_PACKAGE = 'GET_PACKAGE';
export const GET_PACKAGE_SUCCESS = 'GET_PACKAGE_SUCCESS';
export const RESET_CREATE_NEW_PACKAGE_FORM = 'RESET_CREATE_NEW_PACKAGE_FORM';
export const CREATE_NEW_PACKAGE_FAIL = "CREATE_NEW_PACKAGE_FAIL";
export const SET_SHOW_CREATE_NEW_PACKAGE_FAILED_MODAL = "SET_SHOW_CREATE_NEW_PACKAGE_FAILED_MODAL";
