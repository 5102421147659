export const SET_ROLE_MANAGEMENT_LOADING = 'SET_ROLE_MANAGEMENT_LOADING';

export const GET_ROLE_MANAGEMENT_PAGE = 'GET_ROLE_MANAGEMENT_PAGE';
export const GET_ROLE_MANAGEMENT_PAGE_SUCCESS = 'GET_ROLE_MANAGEMENT_PAGE_SUCCESS';
export const GET_ROLE_MANAGEMENT_PAGE_FAIL = 'GET_ROLE_MANAGEMENT_PAGE_FAIL';

export const SET_ACTIVE_ROLE = 'SET_ACTIVE_ROLE';
export const SET_ACTIVE_ROLE_SUCCESS = 'SET_ACTIVE_ROLE_SUCCESS';
export const SET_ACTIVE_ROLE_FAIL = 'SET_ACTIVE_ROLE_FAIL';

export const SET_ROLE_PERMISSION = 'SET_ROLE_PERMISSION';

export const SET_SHOW_CREATE_ROLE_MODAL = 'SET_SHOW_CREATE_ROLE_MODAL';

export const CREATE_NEW_ROLE = 'CREATE_NEW_ROLE';
export const CREATE_NEW_ROLE_SUCCESS = 'CREATE_NEW_ROLE_SUCCESS';
export const CREATE_NEW_ROLE_FAIL = 'CREATE_NEW_ROLE_FAIL';

export const SAVE_ROLE_CHANGES = 'SAVE_ROLE_CHANGES';
export const SAVE_ROLE_CHANGES_SUCCESS = 'SAVE_ROLE_CHANGES_SUCCESS';
export const SAVE_ROLE_CHANGES_FAIL = 'SAVE_ROLE_CHANGES_FAIL';

export const DELETE_ROLE = 'DELETE_ROLE';
export const DELETE_ROLE_SUCCESS = 'DELETE_ROLE_SUCCESS';
export const DELETE_ROLE_FAIL = 'DELETE_ROLE_FAIL';

export const OPEN_DELETE_ROLE_POPUP = 'OPEN_DELETE_ROLE_POPUP';
export const CLOSE_DELETE_ROLE_POPUP = 'CLOSE_DELETE_ROLE_POPUP';

export const GET_MY_ORGANISATIONS = 'GET_MY_ORGANISATIONS';
export const GET_MY_ORGANISATIONS_SUCCESS = 'GET_MY_ORGANISATIONS_SUCCESS';
export const GET_MY_ORGANISATIONS_FAIL = 'GET_MY_ORGANISATIONS_FAIL';
