
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import logoDark from "../../assets/images/outbooks.png";
import {getInvoices, onInputChange} from "../../store/subscriptionPackage/actions";
import Loader from "../../components/Loader";
import {formatCurrencyOfYourChoice} from "../../Utils";
import Paginator from "../../components/Paginator";


class Invoice extends Component {
    componentDidMount() {
        this.props.getInvoices({limit: 10});
    }
    componentWillUnmount() {
        this.props.onInputChange('invoices', []);
    }
    searchInvoices() {
        const data = {
            period: this.props.period_filter,
            status: this.props.payment_status_filter
        }
        this.props.getInvoices({limit: 10, ...data});
    }
    render() {
        return (
            <React.Fragment>
                {(this.props.loading || this.props.subscriptionLoading) && <Loader />}
                <div className="page-info-header">
                    <div className="container-fluid">
                        <div className="page-display-title">Invoices</div>
                    </div>
                </div>
                <div className="container-fluid">
                <div style={{marginTop:"75px",marginBottom:"95px"}}>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="box-typ1">
                                <div className="box-typ2-content row">
                                    {/*<div className="col-md-3">*/}
                                    {/*    <div className="form-group">*/}
                                    {/*        <label>Practice Name</label>*/}
                                    {/*        <input onChange={e => {*/}
                                    {/*            this.props.onInputChange('practice_name_filter', e.target.value)*/}
                                    {/*        }} type="text" className="form-control" />*/}
                                    {/*    </div>*/}
                                    {/*</div>*/}
                                    <div className="col-md-3">
                                        <div className="form-group">
                                            <label>Invoice Date</label>
                                            <input onChange={e => {
                                                this.props.onInputChange('period_filter', new Date(e.target.value).getTime())
                                            }} type="date" className="form-control" />
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="form-group">
                                            <label>Payment Status</label>
                                            <select className="form-control" onChange={(e) => {
                                                this.props.onInputChange('payment_status_filter', e.target.value === 'all' ? undefined : e.target.value)
                                            }}>
                                                <option value={'all'} selected>All</option>
                                                <option value={'paid'}>Paid</option>
                                                <option value={'unpaid'}>Unpaid</option>
                                            </select>                                     
                                        </div>
                                    </div>
                                    <div className="col-md-2">
                                    <button onClick={this.searchInvoices.bind(this)} class="btn btn-success create-item search"><span>Search</span></button>
                                    </div>
                                    <div class="col-md-12">
                                        <div className="table-responsive">
                                            <table className="table table-striped ">
                                                <thead className="thead-dark ">
                                                    <tr>
                                                        <th scope="col">Practice Name</th>
                                                        <th scope="col">Invoice Date</th>
                                                        <th scope="col">Amount</th>
                                                        <th scope="col">Invoice Number</th>
                                                        <th scope="col">Status</th>
                                                        <th scope="col">Payment Date</th>
                                                        <th scope="col">Action</th>                                                        
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                {this.props.invoices.map(invoice => (
                                                  <tr>
                                                      <td>{invoice.practice_name}</td>
                                                      <td>{new Date(invoice.date).toLocaleDateString()}</td>
                                                      <td>{formatCurrencyOfYourChoice(invoice.currency)(invoice.amount)}</td>
                                                      <td>
                                                          {invoice.number}
                                                      </td>
                                                      <td>{invoice.paid ? 'Paid' : 'Unpaid'}</td>
                                                      <td>{invoice.paid ? new Date(invoice.payment_date).toLocaleDateString() : ''}</td>
                                                      <td className="text-center">
                                                          <a href={invoice.download} className="btn btn-secondary btn-xs "><i className="fa fa-download"></i></a>
                                                      </td>
                                                  </tr>
                                                ))}
                                                </tbody>
                                            </table>
                                            {/*<Paginator*/}
                                            {/*  totalPages={this.props.invoicePagination.total_pages}*/}
                                            {/*  pageNum={this.props.invoicePagination.page_num}*/}
                                            {/*  getObjects={(page_num) => {*/}
                                            {/*      this.props.getInvoices({*/}
                                            {/*          page_num,*/}
                                            {/*          starting_after: this.props.invoices.length > 0 ? this.props.invoices.at(-1).id : undefined,*/}
                                            {/*          limit: 10*/}
                                            {/*      });*/}
                                            {/*  }}*/}
                                            {/*/>*/}
                                        </div>
                                    </div>
                                    {this.props.hasMoreInvoices && <div className="col-md-12">
                                        <button onClick={() => {
                                            this.props.getInvoices({
                                                limit: 10,
                                                starting_after: this.props.invoices.at(-1).id,
                                                period: this.props.period_filter,
                                                status: this.props.payment_status_filter
                                            })
                                        }} className="btn btn-success create-item search"
                                                style={{display: 'block', margin: '0 auto'}}>Load more
                                        </button>
                                    </div>}
                                </div>
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    const { users, pagination, loading } = state.User;
    const { invoices, loading: subscriptionLoading, invoicePagination, hasMoreInvoices, ...rest } = state.SubscriptionPackage;
    return { ...rest, users, pagination, loading, subscriptionLoading, invoicePagination, hasMoreInvoices, invoices }
}

export default connect(mapStateToProps, {getInvoices, onInputChange})(Invoice);
