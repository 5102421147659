import React, { Component } from "react";
import { Row, Card, Alert, Modal, ModalHeader, ModalBody, ModalFooter,Button} from "reactstrap";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import Select from "react-select";
import { getValidator, getPhoneObject } from "../../Utils";
import { countryOptions } from "../../config";
import Loader from "../../components/Loader";
import InputPhone from "../../components/InputPhone";
import { registerUser, toggleRegistrationModal, resetRegisterForm } from "../../store/actions";
import { onInputChange } from "../../store/auth/register/actions";

// import images
import bg from "../../assets/images/bg.jpg";
import logoDark from "../../assets/images/outbooks.png";
import logoSm from "../../assets/images/logo-sm.png";
import mailIcon from "../../assets/images/mailticks-logo.png";
import "../../assets/scss/custom/pages/_register.scss";
import cancle from "../../assets/images/cancle.png";

class Register extends Component {
  constructor(props) {
    super(props);
    this.validator = getValidator();
    this.handleValidSubmit = this.handleValidSubmit.bind(this);
  }

  handleValidSubmit(event, values) {
    if (this.validator.allValid()) {
      this.props.registerUser(this.props.firstName, this.props.lastName, this.props.email, getPhoneObject(this.props.dialCode, this.props.phone), this.props.country, this.props.history); 
      this.props.resetRegisterForm();
      this.validator.hideMessages(); 
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
    // console.log(this.firstName.value, this.lastName.value);
    // this.props.firstName="";
    // this.props.lastName="";
    event.preventDefault();
  }
  componentDidMount() {
    this.props.resetRegisterForm();
  }
  componentWillUnmount() {
    this.props.resetRegisterForm();
  }
  handleClose = () => {
  
    console.log("Modal closed")
  
}
  
  render() {
    return (
      <React.Fragment>
        <div className="accountbg"></div>
        <div className="wrapper-page account-page-full">
          {this.props.loading ? <Loader /> : null}
          <Card className="shadow-none">
            <div className="card-block">
              <div className="account-box">
                <div className="card-box shadow-none p-4">
                  <div className="p-2">
                    <div className="text-center mt-4">
                      <Link to="index.html">
                        <img src={logoDark} height="22" alt="logo" />
                      </Link>
                    </div>

                    <h4 className="font-size-18 mt-5 text-center">
                      Free Register
                    </h4>
                    <p className="text-muted text-center">
                      Get your free Outbooks account now.
                    </p>

                      {this.props.user && this.props.user ? (
                        <Alert color="success">
                          Register User Successfully
                        </Alert>
                      ) : null}
                      {this.props.registrationError &&
                      this.props.registrationError ? (
                        <Alert color="danger">
                          {this.props.registrationError}
                        </Alert>
                      ) : null}
                      <form onSubmit={this.handleValidSubmit}>
                      <div className="fieldset">
                        <label className="required">First Name</label>
                        <input name="firstName" value={this.props.firstName || ""} onChange={(e) => this.props.onInputChange("firstName", e.target.value)} className="input-text" type="text" placeholder="First Name" />
                        {this.validator.message('First Name', this.props.firstName, 'required|max:50|_name', { className: 'text-danger' })}
                      </div>
                      <div className="fieldset">
                        <label className="required">Last Name</label>
                        <input name="lastName" value={this.props.lastName || ""} onChange={(e) => this.props.onInputChange("lastName", e.target.value)} className="input-text" type="text" placeholder="Last Name" />
                        {this.validator.message('Last Name', this.props.lastName, 'required|max:50|_name', { className: 'text-danger' })}
                      </div>
                      <div className="fieldset">
                        <label className="required">Email</label>
                        <input name="email" value={this.props.email || ""} onChange={(e) => this.props.onInputChange("email", e.target.value)} className="input-text" type="text" placeholder="Email" />
                        {this.validator.message('Email', this.props.email, 'required|email', { className: 'text-danger' })}
                      </div>
                      <div className="fieldset">
                        <label className="required">Phone</label>
                        <InputPhone required placeholder="Phone" phoneFieldName="phone" dialCodeFieldName="dialCode" dialCode={this.props.dialCode} phone={this.props.phone} validator={this.validator} onChange={this.props.onInputChange} />
                      </div>
                      <div className="fieldset">
                        <label className="required">Country</label>
                        <Select
                          options={countryOptions}
                          value={this.props.country ? countryOptions.filter(option => option.value === this.props.country) : ""}
                          onChange={selectedOption => this.props.onInputChange("country", selectedOption.value)}
                        />
                        {this.validator.message('Country', this.props.country, 'required', { className: 'text-danger' })}
                      </div>
                      <Row className="form-group">
                        <div className="col-12 text-right">
                          <button
                            className="btn btn-primary w-md waves-effect waves-light btn-block"
                            onClick={this.handleValidSubmit}
                          >
                            Register
                          </button>
                        </div>
                      </Row>
                      </form>
                      <Row className="form-group mt-2 mb-0">
                        <div className="col-12 mt-2">
                          <p className="mb-0">
                            By registering you agree to the Outbooks{" "}
                            <a target="_blank" href="https://outbooks.co.uk/terms-conditions/" className="text-primary">
                              Terms of Use
                            </a>
                          </p>
                        </div>
                        <div className="col-12 mt-2">
                          <p>
                            Already have an account ?{" "}
                            <Link
                              to="login"
                              className="font-weight-medium text-primary"
                            >
                              {" "}
                              Login{" "}
                            </Link>{" "}
                          </p>
                        </div>
                      </Row>

                    <div className="mt-5 pt-4 text-center">
                      <p>
                        © {new Date().getFullYear()} Outbooks
                        {/* Crafted with{" "}
                        <i className="mdi mdi-heart text-danger"></i> by
                        StartupFlux */}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Card>
        </div>
        <Modal id="modal" isOpen={this.props.showRegisterModal} centered size="lg" toggle={this.props.toggleRegistrationModal} className="registration-modal">
          <ModalHeader className="close-modal">
          <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
          {this.props.registerationModalName}, an email is on its way
          <img style = {{cursor:"pointer"}} height={30} width={30} onClick={this.props.toggleRegistrationModal} src={cancle} />
          </div>
          </ModalHeader>
          <ModalBody>
            <img src={mailIcon} />
            <p>Please verify the email you received on <span className="registeration-modal-email">{this.props.registerationModalEmail}</span> to activate your Outbooks account.</p>
          </ModalBody>
        </Modal>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  const { firstName, lastName, email, phone, dialCode, country, registrationError, loading, showRegisterModal, registerationModalName, registerationModalEmail } = state.Account;
  return { firstName, lastName, email, phone, dialCode, country, registrationError, loading, showRegisterModal, registerationModalName, registerationModalEmail };
};

export default withRouter(connect(mapStateToProps, { registerUser, toggleRegistrationModal, onInputChange, resetRegisterForm })(Register));
