import {
  GET_AUDIT_TRAILS,
  GET_AUDIT_TRAILS_SUCCESS,
  LOAD_MORE_AUDIT_TRAILS,
  LOAD_MORE_AUDIT_TRAILS_SUCCESS,
} from "./actionTypes";

export const getAuditTrails = () => {
  return {
    type: GET_AUDIT_TRAILS,
  };
};

export const getAuditTrailsSuccess = (auditTrails, totalCount) => {
  return {
    type: GET_AUDIT_TRAILS_SUCCESS,
    payload: { auditTrails, totalCount },
  };
};

export const loadMoreAuditTrails = (lastId) => {
  return {
    type: LOAD_MORE_AUDIT_TRAILS,
    payload: lastId,
  };
};

export const loadMoreAuditTrailsSuccess = (auditTrails) => {
  return {
    type: LOAD_MORE_AUDIT_TRAILS_SUCCESS,
    payload: auditTrails,
  };
};
