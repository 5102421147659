import React from "react";
import Select from "react-select";

const SelectServices = (props) => {
    let jsx = [];
    for (let key in props.servicesByCategory) {
        let innerJsx = [];
        let headerJsx = (
            <tr key={`header${key}`} className="head-row">
                <td>{props.servicesByCategory[key].categoryName}</td>
            </tr>
        );
        for (let service of props.servicesByCategory[key].services) {
            if (service.chargeType===props.chargeType || service.chargeType==="recurring/oneoff") {
                let isServiceSelected = props.services.includes(`${key}-${service.id}_${props.chargeType}`);
                let isNotAllowed = false;
                if (!isServiceSelected && props.chargeType==="recurring") {
                    isNotAllowed = props.services.includes(`${key}-${service.id}_oneoff`);
                } else if (!isServiceSelected && props.chargeType==="oneoff") {
                    isNotAllowed = props.services.includes(`${key}-${service.id}_recurring`);
                }
                let pricingDriverJsx = [];
                let pricingDriverKeys = [];
                innerJsx.push(
                    <tr onClick={isNotAllowed ? null : (isServiceSelected ? (() => props.removeService(`${key}-${service.id}_${props.chargeType}`/*, pricingDriverKeys*/)) : (() => props.addService(`${key}-${service.id}_${props.chargeType}`/*, pricingDriverKeys*/))) } key={service.id}>
                        <td>
                            <div className={`select-row ${isNotAllowed?"select-not-allowed":""}`}><i className={`ion ion-md-${isServiceSelected ? "checkbox" : "square-outline"}`}></i><span>{service.name ? service.name.substring(0,30) : ''}</span></div>
                        </td>
                    </tr>
                );
            }
        }
        if (innerJsx.length>0) {
            innerJsx.unshift(headerJsx);
            jsx.push(
                <table key={key} className="table table-striped select-service-table table-hover">
                    <tbody>
                        {innerJsx}
                    </tbody>
                </table>
            );
        }
    }
    if (jsx.length===0) {
        return <div className="text-center">No Services Found!</div>
    }
    return jsx;
}

export default SelectServices;
