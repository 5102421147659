import React, { Component } from "react";
import { Modal, ModalBody } from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";
import { connect } from "react-redux";
import {
  onInputChange,
  addVariationToPricingDriver,
  deleteVariationFromPricingDriver,
  addSlabToPricingDriver,
  deleteSlabFromPricingDriver,
  addNumberRangeToPricingDriver,
  deleteNumberRangeFromPricingDriver,
  setShowCreateGlobalPricingDriverModal,
  setShowCreateGlobalPricingDriverSuccessModal,
  submitGlobalPricingDriver,
  resetPricingDriverForm,
  setShowCreateGlobalConstantModal,
  submitGlobalConstant,
  setShowCreateGlobalPricingDriverFailModal,
} from "../../store/service/actions";
import { getValidator } from "../../Utils";

class CreateGlobalConstantModal extends Component {
  constructor(props) {
    super(props);
    this.validator = getValidator();
    this.cancel = this.cancel.bind(this);
    this.toggle = this.toggle.bind(this);
    this.formatAndSubmitData = this.formatAndSubmitData.bind(this);
    this.isDriverValid = this.isDriverValid.bind(this);
    this.props.resetPricingDriverForm();
    this.validator.hideMessages();
    // this.updateStepperWarnings();
    this.forceUpdate();
  }
  editModal(id, index) {
    this.props.setShowCreateGlobalConstantModal(true, "edit", id, index);
  }

  isDriverValid() {
    let driversValid = true;
    let driverCodes = " ";
    // for (let driverCode of this.props.driverCodes) {
    console.log(this.validator.fields["Driver Name"]);
    if (
      !(
        this.validator.fields["Driver Name"] &&
        this.validator.fields["Driver Value"]
      )
    ) {
      driversValid = false;
      // break;
    }
    return driversValid;
  }

  toggle() {
     this.validator.hideMessages();
    this.props.resetPricingDriverForm();
    this.props.setShowCreateGlobalConstantModal(
      !this.props.setShowCreateGlobalConstantModal
    );
  }

  formatAndSubmitData() {
    console.log("New");
    console.log(this.isDriverValid());
    if (this.isDriverValid()) {
      let driver = {};
      driver["name"] = this.props.driverName;
      driver["type"] = "Constant";
      driver["value"] = this.props.driverValue;

      if (this.props.createGlobalConstantModalType === "edit") {
        driver.id = this.props.driverId;
      }

      this.props.submitGlobalConstant(
        driver,
        this.props.createGlobalConstantModalType
      );
    } else {
      this.validator.showMessages();
      // this.updateStepperWarnings();
      this.forceUpdate();
    }
  }
  cancel() {
    // this.props.resetPricingDriverForm();
    this.validator.hideMessages();
    // this.updateStepperWarnings();
    this.forceUpdate();
    this.props.resetPricingDriverForm();
    this.props.setShowCreateGlobalConstantModal(false);
  }

  render() {
    const texts = {
      create: {
        header: "Create New Constant",
        submitButton: "Create Constant",
      },
      edit: {
        header: "Edit Constant",
        submitButton: "Update Constant",
      },
    };
    let item = "";

    return (
      <React.Fragment>
        <Modal
          isOpen={this.props.showCreateGlobalConstantModal}
          toggle={this.toggle}
          size="lg"
        >
          <ModalBody>
            <h4>{texts[this.props.createGlobalConstantModalType].header}</h4>
            <div className="separator mb-2" />
            <div className="p-3">
              <div className="row">
                <div className="col-6">
                  <div className="fieldset2">
                    <label className="fieldset-label required">
                      Driver Name
                    </label>
                    <input
                      value={this.props[`driver${item}Name`] || ""}
                      onChange={(e) =>
                        this.props.onInputChange(
                          `driver${item}Name`,
                          e.target.value
                        )
                      }
                      className="input-text"
                      type="text"
                      placeholder="Driver Name"
                    />
                    {/* this.validator.message('Package Name', this.props[`driver${item}Name`], 'required', { className: 'text-danger' }) */}
                    {/*props.nameValidator*/}
                    {/* {props.validator.message(`driver${props.code}Name`, props.parentProps[`driver${props.code}Name`], '_required', { className: 'text-danger' })} */}
                    {this.validator.message(
                      "Driver Name",
                      this.props[`driver${item}Name`],
                      "required",
                      { className: "text-danger" }
                    )}
                  </div>
                </div>
                <div className="col-6">
                  <div className="fieldset2">
                    <label className="fieldset-label required">
                      Driver Value
                    </label>
                    <input
                      value={this.props[`driver${item}Value`] || ""}
                      onChange={(e) =>
                        this.props.onInputChange(
                          `driver${item}Value`,
                          e.target.value
                        )
                      }
                      className="input-text"
                      type="text"
                      onKeyPress={(event) => {
                        if (!/^\d*\.?\d*$/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      placeholder="Driver Value"
                    />
                    {/*props.nameValidator*/}
                    {/* {props.validator.message(`driver${props.code}Name`, props.parentProps[`driver${props.code}Name`], '_required', { className: 'text-danger' })} */}
                    {this.validator.message(
                      "Driver Value",
                      this.props[`driver${item}Value`],
                      "required",
                      { className: "text-danger" }
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="separator mt-3 mb-3" />
            <div className="row fieldset">
              <div className="col-12 text-right">
                <button onClick={this.cancel} className="btn">
                  Cancel
                </button>
                <button
                  onClick={this.formatAndSubmitData}
                  className="btn create-item ml-2"
                >
                  {texts[this.props.createGlobalConstantModalType].submitButton}
                </button>
              </div>
            </div>
          </ModalBody>
        </Modal>
        {this.props.showCreateGlobalPricingDriverSuccessModal && (
          <SweetAlert
            title=""
            success
            confirmBtnBsStyle="success"
            onConfirm={() => {
              this.props.setShowCreateGlobalPricingDriverSuccessModal(false);
              this.props.setShowCreateGlobalConstantModal(false);
              this.cancel();
            }}
          >
            <div className="mb-2 success-alert-message">
              The driver has been created successfully!
            </div>
          </SweetAlert>
        )}
        {this.props.showCreateGlobalPricingDriverFailModal && (
          <SweetAlert
            title=""
            danger
            onConfirm={() => {
              this.props.setShowCreateGlobalPricingDriverFailModal(false);
            }}
          >
            <div className="mb-2 success-alert-message">
              The driver with name {this.props.driverName} already exists!
            </div>
          </SweetAlert>
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => state.Service;

export default connect(mapStateToProps, {
  onInputChange,
  // addVariationToPricingDriver,
  // deleteVariationFromPricingDriver,
  // addSlabToPricingDriver,
  // deleteSlabFromPricingDriver,
  // addNumberRangeToPricingDriver,
  // deleteNumberRangeFromPricingDriver,
  // setShowCreateGlobalPricingDriverModal,
  setShowCreateGlobalPricingDriverSuccessModal,
  // submitGlobalPricingDriver,
  resetPricingDriverForm,
  setShowCreateGlobalConstantModal,
  submitGlobalConstant,
  setShowCreateGlobalPricingDriverFailModal,
})(CreateGlobalConstantModal);
