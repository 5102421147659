import {
	SET_DELETE_LOADING,
	DELETE_OBJECT,
	DELETE_OBJECT_SUCCESS,
	SET_SHOW_CONFIRM_DELETE_MODAL,
	SET_SHOW_DELETE_OBJECT_SUCCESS_MODAL
} from "./actionTypes";

const initialState = {
	loading: false,
	showConfirmDeleteModal: false,
	showDeleteObjectSuccessModal: false,
	id: null,
	module: null
}

const _delete = (state = initialState, action) => {
	switch (action.type) {
		case SET_DELETE_LOADING:
			return {...state, loading: action.payload}
		case DELETE_OBJECT:
			return { ...state, loading: true, showConfirmDeleteModal: false }
		case DELETE_OBJECT_SUCCESS:
			return { ...state, loading: false, showDeleteObjectSuccessModal: true, id: null, module: null }
		case SET_SHOW_CONFIRM_DELETE_MODAL:
			return { ...state, showConfirmDeleteModal: action.payload.isVisible, id: action.payload.id, module: action.payload.module }
		case SET_SHOW_DELETE_OBJECT_SUCCESS_MODAL:
			return { ...state, showDeleteObjectSuccessModal: action.payload }
		default:
			return state;
	}
}

export default _delete;
