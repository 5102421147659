import {
	ON_ACCESS_KEY_INPUT_CHANGE,
	GET_ACCESS_KEYS,
	GET_ACCESS_KEYS_SUCCESS,
	CREATE_ACCESS_KEY,
	CREATE_ACCESS_KEY_SUCCESS,
	SET_SHOW_CREATE_ACCESS_KEY_MODAL,
	SET_SHOW_CREATE_ACCESS_KEY_SUCCESS_MODAL,
	RESET_ACCESS_KEY_FORM
} from "./actionTypes";

export const onInputChange = (field, value) => {
	return {
		type: ON_ACCESS_KEY_INPUT_CHANGE,
		payload: {
			field,
			value
		}
	}
}

export const getAccessKeys = () => {
	return {
		type: GET_ACCESS_KEYS
	}
}

export const getAccessKeysSuccess = accessKeys => {
	return {
		type: GET_ACCESS_KEYS_SUCCESS,
		payload: accessKeys
	}
}

export const createAccessKey = (data) => {
	return {
		type: CREATE_ACCESS_KEY,
		payload: data
	}
}

export const createAccessKeySuccess = data => {
	console.log("createAccessKey", data);
	return {
		type: CREATE_ACCESS_KEY_SUCCESS,
		payload: data
	}
}

export const setShowCreateAccessKeyModal = isVisible => {
	return {
		type: SET_SHOW_CREATE_ACCESS_KEY_MODAL,
		payload: isVisible
	}
}

export const setShowCreateNewAccessKeySuccessModal = isVisible => {
	return {
		type: SET_SHOW_CREATE_ACCESS_KEY_SUCCESS_MODAL,
		payload: isVisible
	}
}

export const resetAccessKeyForm = () => {
	return {
		type: RESET_ACCESS_KEY_FORM
	}
}
