import {
  ACTIVATE_USER,
  ACTIVATE_USER_SUCCESSFUL,
  ACTIVATE_USER_FAILED,
  VALIDATE_TOKEN_ACTIVATE_USER,
  VALIDATE_ACTIVE_TOKEN_SUCCESS,
  VALIDATE_ACTIVE_TOKEN_FAILED,
} from "./actionTypes";

export const activateUser = (password, confirmPassword, token, history) => {
  return {
    type: ACTIVATE_USER,
    payload: {
      password,
      confirmPassword,
      token,
      history,
    },
  };
};

export const validateUserActivateToken = (token) => {
  return {
    type: VALIDATE_TOKEN_ACTIVATE_USER,
    payload: {
      token,
    },
  };
};

export const validateUserTokenSuccess = () => {
  return {
    type: VALIDATE_ACTIVE_TOKEN_SUCCESS,
    payload: {
      success: true,
      validateTokenLoading: false,
      tokenExpired: false,
    },
  };
};

export const validateUserTokenFailed = () => {
  return {
    type: VALIDATE_ACTIVE_TOKEN_FAILED,
    payload: {
      success: false,
      validateTokenLoading: false,
      tokenExpired: true,
    },
  };
};

export const activateUserSuccessful = () => {
  return {
    type: ACTIVATE_USER_SUCCESSFUL,
  };
};

export const activateUserFailed = (error) => {
  return {
    type: ACTIVATE_USER_FAILED,
    payload: error,
  };
};
