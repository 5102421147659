import {
	SET_TEXT_TEMPLATE_LOADING,
	ON_TEXT_TEMPLATE_INPUT_CHANGE,
	GET_TEXT_TEMPLATES,
	GET_TEXT_TEMPLATES_SUCCESS,
	GET_TEXT_TEMPLATE,
	GET_TEXT_TEMPLATE_SUCCESS,
	SUBMIT_TEXT_TEMPLATE,
	SUBMIT_TEXT_TEMPLATE_SUCCESS,
	RESET_CREATE_NEW_TEXT_TEMPLATE_FORM
} from "./actionTypes";

export const setTextTemplateLoading = (isLoading) => {
	return {
		type: SET_TEXT_TEMPLATE_LOADING,
		payload: isLoading
	}
}

export const onInputChange = (field, value) => {
	return {
		type: ON_TEXT_TEMPLATE_INPUT_CHANGE,
		payload: {
			field,
			value
		}
	}
}

export const getTextTemplates = () => {
	return {
		type: GET_TEXT_TEMPLATES
	}
}

export const getTextTemplatesSuccess = data => {
	return {
		type: GET_TEXT_TEMPLATES_SUCCESS,
		payload: data
	}
}

export const getTextTemplate = id => {
	return {
		type: GET_TEXT_TEMPLATE,
		payload: id
	}
}

export const getTextTemplateSuccess = data => {
	return {
		type: GET_TEXT_TEMPLATE_SUCCESS,
		payload: data
	}
}

export const submitTextTemplate = (data, type="new") => {
	return {
		type: SUBMIT_TEXT_TEMPLATE,
		payload: {
			data,
			type
		}
	}
}

export const submitTextTemplateSuccess = () => {
	return {
		type: SUBMIT_TEXT_TEMPLATE_SUCCESS
	}
}

export const resetCreateNewTextTemplateForm = () => {
	return {
		type: RESET_CREATE_NEW_TEXT_TEMPLATE_FORM
	}
}
