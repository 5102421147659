import {
	SET_ACTIVE_DEACTIVE_LOADING,
	ACTIVE_DEACTIVE_OBJECT,
	ACTIVE_DEACTIVE_OBJECT_SUCCESS,
	SET_SHOW_CONFIRM_ACTIVE_DEACTIVE_MODAL,
	SET_SHOW_ACTIVE_DEACTIVE_OBJECT_SUCCESS_MODAL
} from "./actionTypes";

const initialState = {
	loading: false,
	showConfirmActiveDeActiveModal: false,
	showActiveDeActiveObjectSuccessModal: false,
	id: null,
	module: null
}

const ActiveDeActive = (state = initialState, action) => {
	switch (action.type) {
		case SET_ACTIVE_DEACTIVE_LOADING:
			return {...state, loading: action.payload}
		case ACTIVE_DEACTIVE_OBJECT:
			return { ...state, loading: true, showConfirmActiveDeActiveModal: false }
		case ACTIVE_DEACTIVE_OBJECT_SUCCESS:
			return { ...state, loading: false, showActiveDeActiveObjectSuccessModal: true, id: null, module: null }
		case SET_SHOW_CONFIRM_ACTIVE_DEACTIVE_MODAL:
			return { ...state, showConfirmActiveDeActiveModal: action.payload.isVisible, id: action.payload.id, module: action.payload.module }
		case SET_SHOW_ACTIVE_DEACTIVE_OBJECT_SUCCESS_MODAL:
			return { ...state, showActiveDeActiveObjectSuccessModal: action.payload }
		default:
			return state;
	}
}

export default ActiveDeActive;
