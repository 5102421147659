import { takeEvery, fork, put, all, call } from 'redux-saga/effects';
import axios from 'axios';

// Login Redux States
import {
    GET_ROLE_MANAGEMENT_PAGE,
    SET_ACTIVE_ROLE,
    CREATE_NEW_ROLE,
    SAVE_ROLE_CHANGES,
    DELETE_ROLE,
    GET_MY_ORGANISATIONS
} from './actionTypes';

import {
    getRoleManagementPageSuccess, getRoleManagementPageFail,
    setActiveRoleSuccess, setActiveRoleFail,
    createNewRoleSuccess, createNewRoleFail,
    saveRoleChangesSuccess, saveRoleChangesFail,
    deleteRoleSuccess, deleteRoleFail,
    getMyOrganisationsSuccess
} from './actions';

import { setShowApiErrorModal } from '../common/actions';

import { getNotificationsSuccess } from '../notification/actions';

import { setCurrentOrganisation } from "../organisation/actions";

// AUTH related methods
import { getFirebaseBackend } from '../../helpers/authUtils';

const fireBaseBackend = getFirebaseBackend();

function* getRoleManagementPage() {
    try {
        const response = yield axios({
            method: "GET",
            url: "access-management/role-management/page"
        });
        if (response.data.success) {
            yield put(getRoleManagementPageSuccess(response.data.result.roles, response.data.result.permissionModuleMap));
        } else {
            yield put(getRoleManagementPageFail());
        }
    } catch (error) {
        yield put(setShowApiErrorModal());
        // if (error.response && error.response.data && error.response.data.error) {
        //     yield put(loginError(error.response.data.error));
        // } else {
        //     yield put(loginError("Something went Wrong!"));
        // }
        yield put(getRoleManagementPageFail());
    }
}

function* setActiveRole({payload: activeRole}) {
    try {
        const response = yield axios({
            method: "GET",
            url: `access-management/role-management/active-role-permissions/${activeRole}`
        });
        if (response.data.success) {
            yield put(setActiveRoleSuccess(response.data.result.permissions));
        } else {
            yield put(setActiveRoleFail());
        }
    } catch (error) {
        yield put(setShowApiErrorModal());
        console.log(error);
        yield put(setActiveRoleFail());
    }
}

function* createNewRole({payload: role}) {
    try {
        const response = yield axios({
            method: "POST",
            url: `access-management/role-management/create-new-role`,
            data: {
                role
            }
        });
        if (response.data.success) {
            yield put(createNewRoleSuccess(response.data.result.roles, response.data.result.newRoleSlug));
        } else {
            yield put(createNewRoleFail());
        }
    } catch (error) {
        yield put(setShowApiErrorModal());
        console.log(error);
        yield put(createNewRoleFail());
    }
}

function* saveRoleChanges({payload: {activeRole, activeRolePermissions}}) {
    try {
        const response = yield axios({
            method: "POST",
            url: `access-management/role-management/save-role-changes`,
            data: {
                activeRole,
                activeRolePermissions
            }
        });
        if (response.data.success) {
            yield put(saveRoleChangesSuccess());
        } else {
            yield put(saveRoleChangesFail());
        }
    } catch (error) {
        yield put(setShowApiErrorModal());
        console.log(error);
        yield put(saveRoleChangesFail());
    }
}

function* deleteRole({payload: role}) {
    try {
        const response = yield axios({
            method: "POST",
            url: `access-management/role-management/delete-role`,
            data: {
                role
            }
        });
        if (response.data.success) {
            yield put(deleteRoleSuccess(response.data.result.roles));
        } else {
            yield put(deleteRoleFail());
        }
    } catch (error) {
        yield put(setShowApiErrorModal());
        console.log(error);
        yield put(deleteRoleFail());
    }
}

function* getMyOrganisations({ payload: page_num }) {
    try {
        const accessToken = yield fireBaseBackend.getAccessToken();
        const response = yield axios({
            method: "GET",
            url: `organisations?page_num=${page_num}`,
            headers: {authorization: `Bearer ${accessToken}`}
        });
        if (response.data.success) {
            let currentOrganisation = response.data.result.organisations.filter(item => item.current);
            if (currentOrganisation.length>0) {
                yield window.localStorage.setItem('isVatRegistered', currentOrganisation[0].vatRegistered);
                yield window.localStorage.setItem('currency', currentOrganisation[0].currency);
                yield put(setCurrentOrganisation({currentOrganisation: currentOrganisation[0], currentSubscription: response.data.result.subscriptionForCurrentOrganisation}));
            }
            yield put(getMyOrganisationsSuccess(response.data.result.organisations));
            yield put(getNotificationsSuccess(response.data.result.notifications, response.data.result.unreadNotificationsCount));
        } else {
            // yield put(getRoleManagementPageFail());
        }
    } catch (error) {
        yield put(setShowApiErrorModal());
        // yield put(getRoleManagementPageFail());
    }
}

export function* watchGetRoleManagementPage() {
    yield takeEvery(GET_ROLE_MANAGEMENT_PAGE, getRoleManagementPage)
}

export function* watchSetActiveRole() {
    yield takeEvery(SET_ACTIVE_ROLE, setActiveRole)
}

export function* watchCreateNewRole() {
    yield takeEvery(CREATE_NEW_ROLE, createNewRole);
}

export function* watchSaveRoleChanges() {
    yield takeEvery(SAVE_ROLE_CHANGES, saveRoleChanges);
}

export function* watchDeleteRole() {
    yield takeEvery(DELETE_ROLE, deleteRole);
}

export function* watchGetMyOrganisations() {
    yield takeEvery(GET_MY_ORGANISATIONS, getMyOrganisations);
}

function* roleManagementSaga() {
    yield all([
        fork(watchGetRoleManagementPage),
        fork(watchSetActiveRole),
        fork(watchCreateNewRole),
        fork(watchSaveRoleChanges),
        fork(watchDeleteRole),
        fork(watchGetMyOrganisations),
    ]);
}

export default roleManagementSaga;
