import {
	SET_TERMS_AND_CONDITION_TEMPLATE_LOADING,
	ON_TERMS_AND_CONDITION_TEMPLATE_INPUT_CHANGE,
	GET_TERMS_AND_CONDITION_TEMPLATES,
	GET_TERMS_AND_CONDITION_TEMPLATES_SUCCESS,
	GET_TERMS_AND_CONDITION_TEMPLATE,
	GET_TERMS_AND_CONDITION_TEMPLATE_SUCCESS,
	SUBMIT_TERMS_AND_CONDITION_TEMPLATE,
	SUBMIT_TERMS_AND_CONDITION_TEMPLATE_SUCCESS,
	RESET_CREATE_NEW_TERMS_AND_CONDITION_TEMPLATE_FORM,
	SET_SHOW_CREATE_NEW_TERMS_AND_CONDITION_SUCCESS_MODAL,
	UPLOAD_TERMS_AND_CONDITION_TEMPLATE,
	UPLOAD_TERMS_AND_CONDITION_TEMPLATE_SUCCESS,
	DELETE_TERMS_AND_CONDITION_TEMPLATE,
	DELETE_TERMS_AND_CONDITION_TEMPLATE_SUCCESS,
	CREATE_NEW_TERMS_AND_CONDITIONS_FAIL,
	SET_SHOW_CREATE_NEW_TERMS_AND_CONDITIONS_FAILED_MODAL
} from "./actionTypes";

const initialState = {
	loading: false,
	termsAndConditionTemplates: [],
	showCreateNewTermsAndConditionTemplateSuccessModal: false,
	showCreateNewTermsAndConditionFailedModal: false,
	showPdfUploadModal: false,
}

const termsAndConditionTemplate = (state = initialState, action) => {
	let newState;
	switch (action.type) {
		case SET_TERMS_AND_CONDITION_TEMPLATE_LOADING:
			return {...state, loading: action.payload}
		case ON_TERMS_AND_CONDITION_TEMPLATE_INPUT_CHANGE:
			return {...state, [action.payload.field]: action.payload.value};
		case GET_TERMS_AND_CONDITION_TEMPLATES:
			return {...state, loading: true}
		case GET_TERMS_AND_CONDITION_TEMPLATES_SUCCESS:
			return {...state, loading: false, termsAndConditionTemplates: action.payload}
		case GET_TERMS_AND_CONDITION_TEMPLATE:
			return {...state, loading: true}
		case GET_TERMS_AND_CONDITION_TEMPLATE_SUCCESS:
			newState = {...state, loading: false};
			newState.termsAndConditionTemplateId = action.payload._id;
			newState.termsAndConditionTemplateName = action.payload.name;
			newState.termsAndConditionTemplateContent = action.payload.template[0].tncBlock;
			newState.businessType = action.payload.organisation_business_type;
			newState.tncTemplateType = action.payload.tnc_template_type;
			newState.fileUrl = action.payload.template_url;
			return newState;
		case SUBMIT_TERMS_AND_CONDITION_TEMPLATE:
			return {...state, loading: true}
		case SUBMIT_TERMS_AND_CONDITION_TEMPLATE_SUCCESS:
			return {...state, loading: false, showCreateNewTermsAndConditionTemplateSuccessModal: true}
		case SET_SHOW_CREATE_NEW_TERMS_AND_CONDITION_SUCCESS_MODAL:
			return { ...state, showCreateNewTermsAndConditionTemplateSuccessModal: action.payload }
		case CREATE_NEW_TERMS_AND_CONDITIONS_FAIL:
			return (newState = {
					...state,
					loading: false,
					showCreateNewTermsAndConditionFailedModal: true,
				  });
		case SET_SHOW_CREATE_NEW_TERMS_AND_CONDITIONS_FAILED_MODAL:
            return { ...state, showCreateNewTermsAndConditionFailedModal: action.payload };
		case RESET_CREATE_NEW_TERMS_AND_CONDITION_TEMPLATE_FORM:
			return initialState
		case UPLOAD_TERMS_AND_CONDITION_TEMPLATE:
			return { ...state, loading: true }
		case UPLOAD_TERMS_AND_CONDITION_TEMPLATE_SUCCESS:
			return { ...state, loading: false, fileUrl: action.payload }
		case DELETE_TERMS_AND_CONDITION_TEMPLATE:
			return { ...state, loading: false, fileUrl: null }
		case DELETE_TERMS_AND_CONDITION_TEMPLATE_SUCCESS:
			return { ...state, loading: false, fileUrl: null }
		default:
			return state;
	}
}

export default termsAndConditionTemplate;
