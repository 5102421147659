import React from "react";
import { Row, Col, Button, Input, Card, CardBody } from "reactstrap";
import { Link } from "react-router-dom";

const getShortDate = (date) => {
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  let d = new Date(date);
  let t = d.toLocaleTimeString();
  let dateString = `${months[d.getMonth()]} ${d.getDate()} (${t.slice(
    0,
    -6
  )} ${t.slice(-2)})`;
  return dateString;
};

const renderDescription = (item) => {
  let subject = `${item.user.first_name} ${item.user.last_name}`;
  let verb;
  let object;
  switch (item.model_name) {
    case "package":
      object = (
        <React.Fragment>
          package <strong>{item.metadata.name}</strong>
        </React.Fragment>
      );
      break;
    case "template":
      object = (
        <React.Fragment>
          {item.metadata && item.metadata.type} template{" "}
          <strong>{item.metadata && item.metadata.name}</strong>
        </React.Fragment>
      );
      break;
    case "category":
      object = (
        <React.Fragment>
          category <strong>{item.metadata.name}</strong>
        </React.Fragment>
      );
      break;
    case "service":
      object = (
        <React.Fragment>
          service <strong>{item.metadata.name}</strong>
        </React.Fragment>
      );
      break;
    case "globalPricingDriver":
      if ("metadata" in item) {
        object = (
          <React.Fragment>
            global pricing driver <strong>{item.metadata.name}</strong>
          </React.Fragment>
        );
      } else {
        object = (
          <React.Fragment>
            global pricing driver <strong></strong>
          </React.Fragment>
        );
      }
      break;
    case "client":
      if ("metadata" in item) {
      object = (
        <React.Fragment>
          Prospect <strong>{item.metadata.display_name}</strong>
        </React.Fragment>
      );
      }else{
        object = (
          <React.Fragment>
            Prospect <strong></strong>
          </React.Fragment>
        );
      }
      break;
    case "userInvite":
      if ("metadata" in item) {
      object = (
        <React.Fragment>
          user <strong>{item.metadata.display_name}</strong>
        </React.Fragment>
      );
      }else{
        object = (
          <React.Fragment>
             user <strong></strong>
          </React.Fragment>
        );
      }
      break;
    case "contract":
      object = (
        <React.Fragment>
          engagement letter <strong>{item.metadata.ref}</strong>
        </React.Fragment>
      );
      break;
    case "quote":
      object = (
        <React.Fragment>
          proposal <strong>{item.metadata.ref}</strong>
        </React.Fragment>
      );
      break;
    case "organisation":
      object = "practice details";
      break;

    case "accessKey":
      object = (
        <React.Fragment>
          access key <strong>{item.metadata.name}</strong>
        </React.Fragment>
      );
      break;
    default:
      break;
  }
  switch (item.event_type) {
    case "Create":
      verb = "created new";
      break;
    case "Update":
      verb = "updated";
      break;
    case "Delete":
      verb = "deleted";
      break;
    case "Create Draft":
      verb = "created new draft";
      break;
    case "Update Draft":
      verb = "updated draft";
      break;
    case "Sent":
      verb = "sent";
      break;
    case "Invite":
        verb = "invited new ";
        break;
    default:
      break;
  }
  return (
    <React.Fragment>
      {subject} {verb} {object}
    </React.Fragment>
  );
};

const getLink = (item) => {
  switch (item.model_name) {
    case "package":
      return `/packages`;
    case "template":
      return item.metadata.type === "tnc"
      ? `/terms-and-conditions/`
      : `/templates`;
    case "category":
      return `/categories`;
    case "service":
      return `/services`;
    case "globalPricingDriver":
      return `/settings/global-pricing-drivers`;
    // case "client":
    //   return `/clients/${item.model_id}`;
    case "client":
        return `/clients/`;
    case "contract":
      return item.event_type === "Sent"
        ? `/contracts/${item.model_id}`
        : `/contracts`;
    case "quote":
      return item.event_type === "Sent"
        ? `/quotes/${item.model_id}`
        : `/quotes`;
    case "organisation":
      return `/settings/organisation-details`;
    case "accessKey":
      return `/settings/access-keys`;
    case "userInvite":
      return `/settings/users/`;
    default:
      break;
  }
};

const renderActivityLog = (item, index) => {
  return (
    <li key={item._id} className="feed-item">
      <div className="feed-item-list">
        <span className="date">{getShortDate(item.createdAt)}</span>
        <Link to={getLink(item)}>
          <span className="activity-text">{renderDescription(item)}</span>
        </Link>
      </div>
    </li>
  );
};

const ActivityLogsTable = (props) => {
  return (
    <ol className="activity-feed">
      {props.auditTrails.map(renderActivityLog)}
    </ol>
  );
};

export default ActivityLogsTable;
