import {
  SET_DASHBOARD_LOADING,
  GET_DASHBOARD,
  GET_DASHBOARD_SUCCESS,
  GET_FILTER_DASHBOARD_DATA,
  GET_FILTER_DASHBOARD_DATA_SUCCESS,
} from "./actionTypes";

export const setDashboardLoading = (isLoading) => {
  return {
    type: SET_DASHBOARD_LOADING,
    payload: isLoading,
  };
};

export const getDashboard = () => {
  return {
    type: GET_DASHBOARD,
  };
};

export const getDashboardSuccess = ({
  completedContracts,
  pendingContracts,
  draftContracts,
  sentQuotes,
  draftQuotes,
  pendingQuotes,
  acceptedQuotes,
  declinedQuotes,
  role,
  declinedContracts,
  completedContractsData,
  pendingContractsData,
  draftContractsData,
  sentQuotesData,
  draftQuotesData,
  pendingQuotesData,
  acceptedQuotesData,
  declinedQuotesData,
  declinedContractsData,
}) => {
  return {
    type: GET_DASHBOARD_SUCCESS,
    payload: {
      completedContracts,
      role,
      pendingContracts,
      draftContracts,
      sentQuotes,
      draftQuotes,
      pendingQuotes,
      acceptedQuotes,
      declinedQuotes,
      declinedContracts,
      completedContractsData,
      pendingContractsData,
      draftContractsData,
      sentQuotesData,
      draftQuotesData,
      pendingQuotesData,
      acceptedQuotesData,
      declinedQuotesData,
      declinedContractsData,
    },
  };
};

export const getFilteredDashboard = (data) => {
  return {
    type: GET_FILTER_DASHBOARD_DATA,
    payload: data,
  };
};

export const getFilteredDashboardDataAction = (data) => {
  return {
    type: GET_FILTER_DASHBOARD_DATA_SUCCESS,
    payload: data,
  };
};