import React, { useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import Select from "react-select";
import HeadingContent from "./HeadingContent";
import TextBlockContent from "./TextBlockContent";
import FullPageHeadingContent from "./FullPageHeadingContent";

const ElementBlock = props => {
	const elementTypes = [
		{ value: 'heading', label: 'Heading' },
		{ value: 'textBlock', label: 'Text Block' },
		{ value: 'servicePricingTable', label: 'Service Pricing Table' },
		{ value: 'serviceDescriptions', label: 'Service Descriptions' },
		{ value: 'fullPageHeading', label: 'Full Page Heading' },
		{ value: 'pageBreak', label: 'Page Break' },
        { value: 'signatureBlock', label: 'Signature Block (Only for Engagement Letter)' },
        { value: 'statementOfFacts', label: 'Statement of facts' }
	];
    const ref = useRef(null);
    const [, drop] = useDrop({
        accept: "ElementBlock",
        hover(item, monitor) {
            if (!ref.current) {
                return;
            }
            const dragIndex = item.index;
            const hoverIndex = props.index;
            // Don't replace items with themselves
            if (dragIndex === hoverIndex) {
                return;
            }
            // Determine rectangle on screen
            const hoverBoundingRect = ref.current?.getBoundingClientRect();
            // Get vertical middle
            const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
            // Determine mouse position
            const clientOffset = monitor.getClientOffset();
            // Get pixels to the top
            const hoverClientY = clientOffset.y - hoverBoundingRect.top;
            // Only perform the move when the mouse has crossed half of the items height
            // When dragging downwards, only move when the cursor is below 50%
            // When dragging upwards, only move when the cursor is above 50%
            // Dragging downwards
            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
                return;
            }
            // Dragging upwards
            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
                return;
            }
            // Time to actually perform the action
            props.moveElement(dragIndex, hoverIndex);
            // Note: we're mutating the monitor item here!
            // Generally it's better to avoid mutations,
            // but it's good here for the sake of performance
            // to avoid expensive index searches.
            item.index = hoverIndex;
        },
    });
    const [{ isDragging }, drag] = useDrag({
        item: { type: "ElementBlock", code: props.code, index: props.index },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    });
    const opacity = isDragging ? 0.25 : 1;
    drag(drop(ref));
	return (
		<div style={{ opacity }} ref={ref} className="element-block">
			<button onClick={props.deleteElement} className="btn btn-sm btn-danger delete-element-btn"><i className="ion ion-md-trash mr-1"></i>Delete Element</button>
			<div className="element">
				<div className="row fieldset">
					<div className="col-2">
						<label className="fieldset-label required">Element Type</label>
					</div>
					<div className="col-10">
						<Select
							options={elementTypes}
							onChange={selectedOption => props.onInputChange(`element${props.code}Type`, selectedOption.value)}
							value={props.elementType ? elementTypes.filter(option => option.value === props.elementType) : ""}
						/>
						{props.validator.message(`element${props.code}Type`, props.elementType, '_required', { className: 'text-danger' })}
					</div>
				</div>
				{props.elementType==="heading" && <HeadingContent {...props.elementProps} validator={props.validator} code={props.code} onInputChange={props.onInputChange} />}
				{props.elementType==="textBlock" && <TextBlockContent {...props.elementProps} validator={props.validator} code={props.code} onInputChange={props.onInputChange} />}
				{props.elementType==="fullPageHeading" && <FullPageHeadingContent {...props.elementProps} validator={props.validator} code={props.code} onInputChange={props.onInputChange} />}
			</div>
		</div>
	);
}

export default ElementBlock;
