import React, { Component } from "react";
import ServiceListTable from "../components/ServiceListTable";
import CreateServiceModal from "../components/CreateServiceModal";
import { Link } from "react-router-dom";

class DefaultServices extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openModal: false,
      activeStep: 1
    };
    this.onNewServiceSubmit = this.onNewServiceSubmit.bind(this);
    this.editService = this.editService.bind(this);
    this.toggleEditServiceModal = this.toggleEditServiceModal.bind(this);
    this.setActiveStep = this.setActiveStep.bind(this);
  }
  formulaInputChange() {

  }
  onNewServiceSubmit() {

  }
  editService() {
    this.setState((prevState) => ({openModal: !prevState.openModal}));
  }
  toggleEditServiceModal() {
    this.setState((prevState) => ({openModal: !prevState.openModal}));
  }
  setActiveStep(step) {
    this.setState({activeStep: step});
  }
  render() {
    const services = [
      {
        categoryName: "Bookkeeping Services",
        categoryItems: [
          "Raising & Sending Invoices",
          "Credit Control",
          "Bank Reconciliation",
          "Company Formation",
          "Loyalty Bonus"
        ]
      },
      {
        categoryName: "Compliance Services",
        categoryItems: [
          "Raising & Sending Invoices",
          "Credit Control",
          "Bank Reconciliation",
          "Company Formation",
          "Loyalty Bonus"
        ]
      }
    ];
    return (
      <React.Fragment>
        <div className="page-info-header">
          <div className="container-fluid">
            <div className="page-display-title">Default Services</div>
            <div className="text-right flex1">
              <button onClick={this.toggleEditServiceModal} className="btn btn-success create-item"><i className="ion ion-md-add-circle"></i> <span>Add New Service</span></button>
            </div>
          </div>
        </div>
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <ServiceListTable editService={this.editService} services={services} />
                </div>
              </div>
            </div>
          </div>
        </div>
        <CreateServiceModal
          isOpen={this.state.openModal}
          toggle={this.toggleEditServiceModal}
          onServiceSubmit={this.onNewServiceSubmit}
          setActiveStep={this.setActiveStep}
          activeStep={this.state.activeStep}
        />
      </React.Fragment>
    );
  }
}

export default DefaultServices;
