import React, { Component } from "react";
import { Modal, ModalBody } from "reactstrap";
// import Cropper from "react-cropper";
// import "cropperjs/dist/cropper.css";
import Dropzone from "react-dropzone";

class ImageUploadModal extends Component {
  constructor(props) {
    super(props);
    this.handleDrop = this.handleDrop.bind(this);
    this.cancel = this.cancel.bind(this);
    this.toggle = this.toggle.bind(this);
    this.applyImage = this.applyImage.bind(this);
    this.getExtension = this.getExtension.bind(this);
    this.isAllowedExtention = this.isAllowedExtention.bind(this);
    this.imageFile = null;
    // this.onCrop = this.onCrop.bind(this);
    this.state = {
      imageUrl: null,
      error: false,
      imageDetails: null,
      errorMessage: "Please upload a valid image",
    };
  }
  getExtension(filename) {
    var parts = filename.split(".");
    return parts[parts.length - 1];
  }

  isAllowedExtention(filename) {
    var ext = this.getExtension(filename);
    if (this.props.allowedExtentions.indexOf(ext.toLowerCase()) != -1) {
      return true;
    }
    return false;
  }
  cancel() {
    this.setState({ imageUrl: null, error: false });
    this.imageFile = null;
    this.props.setShowImageUploadModal(false);
  }
  toggle() {
    this.setState({ imageUrl: null, error: false });
    this.imageFile = null;
    this.props.setShowImageUploadModal(!this.props.showImageUploadModal);
  }
  handleDrop(acceptedFiles) {
    // console.log(acceptedFiles);
    // this.props.uploadLogo(acceptedFiles[0]);
    this.setState({
      error: false,
      errorMessage: "",
    });
    if ("allowedExtentions" in this.props && acceptedFiles[0]) {
      if (this.isAllowedExtention(acceptedFiles[0].name)) {
      } else {
        this.setState({
          error: true,
          errorMessage: "Please upload a valid file type",
        });
        return;
      }
    }
    if (!acceptedFiles[0]) {
      this.setState({
        error: true,
        errorMessage: "Please upload a valid file type",
      });
      return;
    }
    if (acceptedFiles[0]) {
      if (acceptedFiles[0].size / 1048576 > 2) {
        this.setState({
          error: true,
          errorMessage: "Please upload a file less than 2MB",
        });
        return false;
      }
    }
    this.imageFile = acceptedFiles[0];
    this.setState({ imageDetails: acceptedFiles[0] });
    // console.log(acceptedFiles[0]);
    const reader = new FileReader();
    reader.onabort = () => console.log("file reading was aborted");
    reader.onerror = () => console.log("file reading has failed");
    reader.onload = () => {
      // Do whatever you want with the file contents
      this.setState({
        imageUrl: reader.result,
      });
    };
    console.log(acceptedFiles[0]);
    if (acceptedFiles[0]) {
      reader.readAsDataURL(acceptedFiles[0]);
    }
  }
  applyImage() {
    if ("allowedExtentions" in this.props && this.imageFile) {
      if (this.isAllowedExtention(this.state.imageDetails.name)) {
        this.props.uploadImage(this.imageFile);
      } else {
        this.setState({
          error: true,
        });
      }
    } else {
      if (this.imageFile) {
        this.props.uploadImage(this.imageFile);
      }
    }
    this.imageFile = null;
    this.setState({ imageUrl: null });
  }
  // onCrop() {
  // console.log(this.cropperRef);
  // const imageElement = this.cropperRef.current;
  // const cropper = imageElement.cropper;
  // console.log(cropper.getCroppedCanvas().toDataURL());
  // };
  render() {
    return (
      <Modal isOpen={this.props.showImageUploadModal} toggle={this.toggle}>
        <ModalBody>
          <h4>Upload {this.props.type}</h4>
          {this.state.error ? (
            <p style={{ color: "red" }}>{this.state.errorMessage}</p>
          ) : null}
          <div className="separator mb-2" />
          <div className="p-3">
            {!this.state.imageUrl && (
              <Dropzone
                accept="image/*"
                multiple={false}
                maxFiles={1}
                onDrop={this.handleDrop}
              >
                {({ getRootProps, getInputProps }) => (
                  <div {...getRootProps({ className: "dropzone" })}>
                    <input {...getInputProps()} />
                    <p>Drag n drop image, or click to select image</p>
                  </div>
                )}
              </Dropzone>
            )}
            {this.state.imageUrl && (
              <div className="upload-image-preview-div">
                {
                  <img
                    className="upload-image-preview"
                    src={this.state.imageUrl}
                  />
                }
                {/*<Cropper
								src={this.state.imageUrl}
								style={{ width: "100%" }}
								// Cropper.js options
								aspectRatio={16 / 9} 
								guides={false}
								crop={this.onCrop}
								ref={(ref) => { this.cropperRef = ref; }}
							/>*/}
                <span
                  onClick={() => this.setState({ imageUrl: null })}
                  className="remove-upload-image-preview"
                >
                  <i className="ion ion-ios-close-circle"></i>
                </span>
              </div>
            )}
          </div>
          <div className="separator mt-3 mb-3" />
          <div className="row fieldset">
            <div className="col-12 text-right">
              <button onClick={this.cancel} className="btn">
                Cancel
              </button>
              <button
                onClick={this.applyImage}
                className="btn create-item ml-2"
              >
                OK
              </button>
            </div>
          </div>
        </ModalBody>
      </Modal>
    );
  }
}

export default ImageUploadModal;
