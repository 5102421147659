import React from "react";
import { CustomInput } from "reactstrap";
import InputPhone from "./InputPhone";
import OrganisationAddress from "./OrganisationAddress";
import { formatAddress } from "../Utils";
import { connect } from "react-redux";

const OrganisationPartner = props => {
	return (
		<React.Fragment>
			<div className="fieldset-group">
				<label className="fieldset-group-label">Partner {props.index + 1}</label>
				{!props.readOnly && <button onClick={() => props.deletePartner(props.partnerCode)} className="btn btn-sm btn-danger delete-fieldset-group"><i className="ion ion-md-trash mr-1"></i>Delete Partner</button>}
				<div className="row">
					<div className="col-12">
						<div className="row fieldset">
							<div className="col-12 text-right">
								<CustomInput id={`partner${props.partnerCode}IsAusorisedSignatory`} type="switch" onChange={(e) => props.readOnly ? null : props.onInputChange(`partner${props.partnerCode}IsAuthorisedSignatory`, e.target.checked)} checked={props.isAuthorisedSignatory || false} label="Authorised Signatory" />
							</div>
						</div>
					</div>
					<div className="col-6">
						<div className="row fieldset">
							<div className="col-4 text-right">
								<label className={`fieldset-label ${props.readOnly ? "" : "required"}`}>First Name</label>
							</div>
							<div className="col-8">
								<input readOnly={props.readOnly} value={props.firstName || ""} onChange={(e) => props.onInputChange(`partner${props.partnerCode}FirstName`, e.target.value)} className="input-text" type="text" placeholder="First Name" />
								{!props.readOnly && props.validator.message(`partner${props.partnerCode}FirstName`, props.firstName, `_required|_name`, { className: 'text-danger' })}
							</div>
						</div>
					</div>
					<div className="col-6">
						<div className="row fieldset">
							<div className="col-4 text-right">
								<label className={`fieldset-label ${props.readOnly ? "" : "required"}`}>Last Name</label>
							</div>
							<div className="col-8">
								<input readOnly={props.readOnly} value={props.lastName || ""} onChange={(e) => props.onInputChange(`partner${props.partnerCode}LastName`, e.target.value)} className="input-text" type="text" placeholder="Last Name" />
								{!props.readOnly && props.validator.message(`partner${props.partnerCode}LastName`, props.lastName, `_required|_name`, { className: 'text-danger' })}
							</div>
						</div>
					</div>
					<div className="col-6">
						<div className="row fieldset">
							<div className="col-4 text-right">
								<label className={`fieldset-label`}>Phone</label>
							</div>
							<div className="col-8">
								<InputPhone readOnly={props.readOnly} phoneFieldName={`partner${props.partnerCode}Phone`} dialCodeFieldName={`partner${props.partnerCode}DialCode`} placeholder="Phone" dialCode={props.dialCode} phone={props.phone} validator={props.validator} onChange={props.onInputChange} />
							</div>
						</div>
					</div>
					<div className="col-6">
						<div className="row fieldset">
							<div className="col-4 text-right">
								<label className={`fieldset-label ${props.readOnly ? "" : "required"}`}>Email</label>
							</div>
							<div className="col-8">
								<input readOnly={props.readOnly} value={props.email || ""} onChange={(e) => props.onInputChange(`partner${props.partnerCode}Email`, e.target.value)} className="input-text" type="text" placeholder="Email" />
								{!props.readOnly && props.validator.message(`partner${props.partnerCode}Email`, props.email, `_required|_email`, { className: 'text-danger' })}
							</div>
						</div>
					</div>
					<div className="col-12">
						<div className="row fieldset">
							<div className="col-2 text-right">
								<label className={`fieldset-label ${props.readOnly ? "" : "required"}`}>Residential Address</label>
							</div>
							<div className="col-10">
								<OrganisationAddress
									addressHeader="Residential Address"
									addressPrefix={`partner${props.partnerCode}`}
									addressHeader="Residential Address"
									addressPrefix={`partner${props.partnerCode}`}
									addressSearch={props.addressSearch}
									premises={props.premises}
									addressLine1={props.addressLine1}
									addressLine2={props.addressLine2}
									locality={props.locality}
									region={props.region}
									country={props.country}
									postcode={props.postcode}
									onInputChange={props.onInputChange}
									readOnly={props.readOnly}
								/>
								{!props.readOnly && props.validator.message(`partner${props.partnerCode}Address`, formatAddress(props.premises, props.addressLine1, props.addressLine2, props.locality, props.region, props.country, props.postcode), '_required', { className: 'text-danger' })}
							</div>
						</div>
					</div>
				</div>
			</div>
		</React.Fragment>
	);
}

export default OrganisationPartner;
