import React, { Component } from "react";
import Select from "react-select";
import { formatCurrency, formatValue } from "../Utils";

class RequiredGlobalPricingDrivers extends Component {
    componentDidUpdate(prevPeops) {
        for (let item of this.props.pricingDrivers) {
            if (item.type === "Constant") {
                this.props.onInputChange(`globalPricingDriver-${item._id}`, item.value)
            }
        }
    }
    reg = /^\d+$/;

    pricingDriverKeys = []

    renderDependents(props, currDriver, inputVariableName, key, driverId, categoryKey) {
        const options = (item) => {
            let optionsArr = []
            if (item.type === "Variation") {
                const optionsMap = item?.variations?.map((ele) => {
                    optionsArr.push({ value: ele._id, label: ele.name })
                })
                return optionsArr
            }
            if (item.type === "Slab") {
                const optionsMap = item?.slabs?.map((ele) => {
                    if (["UnitBased", "BlockBased"].includes(ele.type)) {
                        optionsArr.push({ value: ele._id, label: `${ele.from}-${ele.to}` });
                    }
                    else if (["IncrementalUnit", "IncrementalBlock"].includes(ele.type)) {
                        optionsArr.push({ value: ele._id, label: "Other" })
                    }
                })
                return optionsArr
            }
        }

        const removeNestedNonSelectedDrivers = (dependent, splitKey) => {
            const currDriverFromDependencyList = props?.dependencyArray?.filter(ele => ele?._id === dependent?._id && ele?.serviceId === dependent?.service && ele?.dependents?.length > 0)[0]
            if (currDriverFromDependencyList && currDriverFromDependencyList.dependents.length > 0) {
                let variationsMap = currDriverFromDependencyList?.variations.map((variation) => {
                    let dependentsArray = currDriverFromDependencyList?.dependents?.filter(ele => ele?.dependencyVariation === variation.name)
                    if (dependentsArray?.length > 0) {
                        let dependentsMap = dependentsArray?.map((dependent) => {
                            if (dependent.service === splitKey) {
                                let customDriverId = `customPackagedPricingDriver-${categoryKey}-${key}-${dependent._id}`
                                let removeCustomDriverId = this.pricingDriverKeys.filter(e => e !== customDriverId)
                                this.pricingDriverKeys = removeCustomDriverId
                                removeNestedNonSelectedDrivers(dependent, splitKey)
                            }
                        })
                    }
                })
            }
        }

        const removeNonSelectedDrivers = (currDriverFromDependencyList, splitKey, inputVarName) => {
            const nonSelectedVariations = currDriverFromDependencyList?.variations?.filter(ele => ele?._id !== props?.parentProps[inputVarName])
            let variationsMap = nonSelectedVariations?.map((variation) => {
                let dependentsArray = currDriverFromDependencyList?.dependents?.filter(ele => ele?.dependencyVariation === variation.name)
                if (dependentsArray?.length > 0) {
                    let dependentsMap = dependentsArray?.map((dependent) => {
                        if (dependent.service === splitKey) {
                            let customDriverId = `customPackagedPricingDriver-${categoryKey}-${key}-${dependent._id}`
                            let removeCustomDriverId = this.pricingDriverKeys.filter(e => e !== customDriverId)
                            this.pricingDriverKeys = removeCustomDriverId
                            removeNestedNonSelectedDrivers(dependent, splitKey)
                        }
                    })
                }
            })
        }

        const nestedDependentsJsx = (dependentsArray, driverInputVariableName, driversJsx) => {
            let nestedDriverInputVariableName
            let splitKey = key.split("_")[0]
            const nestedDependents = dependentsArray.map((elem) => {
                let nestedDriverFromDependencyList = props?.dependencyArray.filter(e => e._id === elem._id && e.name === elem.name && e.serviceId == splitKey)
                if (nestedDriverFromDependencyList?.length > 0) {
                    const selectedVarName = nestedDriverFromDependencyList[0]?.variations?.filter(ele => ele?._id === props?.parentProps[driverInputVariableName])[0]?.name
                    const nestedDependentsArray = nestedDriverFromDependencyList[0]?.dependents?.filter(ele => ele?.dependencyVariation === selectedVarName)
                    removeNonSelectedDrivers(nestedDriverFromDependencyList[0], splitKey, driverInputVariableName)
                    if (!nestedDependentsArray.length) {
                        if (selectedVarName) {
                            removeNonSelectedDrivers(nestedDriverFromDependencyList[0], splitKey, driverInputVariableName)
                        }
                        if (nestedDriverFromDependencyList?.dependents?.length > 0) {
                            nestedDriverFromDependencyList.dependents.map((dependent) => {
                                if (dependent.service === splitKey) {
                                    let customDriverId = `customPackagedPricingDriver-${categoryKey}-${key}-${dependent._id}`
                                    let removeCustomDriverId = this.pricingDriverKeys.filter(e => e !== customDriverId)
                                    this.pricingDriverKeys = removeCustomDriverId
                                }
                            })
                        }
                    }
                    if (nestedDependentsArray?.length > 0) {
                        let nestedDependentJsx = nestedDependentsArray?.map((el) => {
                            nestedDriverInputVariableName = `customPackagedPricingDriver-${categoryKey}-${key}-${el._id}`
                            let _nestedDriverInputVariableName = `customPackagedPricingDriver-${categoryKey}-${key}-${el._id}`
                            let findKey = this.pricingDriverKeys.find(key => key === _nestedDriverInputVariableName)
                            !findKey && this.pricingDriverKeys.push(_nestedDriverInputVariableName);
                            //   setDefaultVariation(el, nestedDriverInputVariableName)
                            driversJsx.push(
                                <div key={el?._id} style={{ fontSize: "13.56px" }} className="default-value-div mt-2 mb-2">
                                    <label style={{ color: "#274b8a" }} className="required">{el?.name}</label>

                                    {el.type === "Quantity" && <input
                                        value={props.parentProps[_nestedDriverInputVariableName] || ""}
                                        onChange={(e) => {
                                            if (!this.reg.test(e.target.value) && e.target.value != "") return;
                                            props.onInputChange(_nestedDriverInputVariableName, e.target.value);
                                        }}
                                        type="text"
                                        className="input-text"
                                        placeholder={el.name}
                                    />}
                                    {el.type === "Quantity" && props.validator.message(_nestedDriverInputVariableName, props.parentProps[_nestedDriverInputVariableName], `_required|_numeric|_min:0,num`, { className: 'text-danger' })}

                                    {(el.type === "Variation" || el.type === "Slab") && <Select
                                        options={options(el, _nestedDriverInputVariableName)}
                                        onChange={selectedOption => { props.onInputChange(_nestedDriverInputVariableName, selectedOption.value); }}
                                    // value={(props.parentProps[nestedDriverInputVariableName] ? options(el)?.filter(option => option.value === props.parentProps[nestedDriverInputVariableName]) : "")}
                                    />}
                                    {(el.type === "Variation" || el.type === "Slab") && props.validator.message(_nestedDriverInputVariableName, props.parentProps[_nestedDriverInputVariableName], `_required`, { className: 'text-danger' })}

                                    {el.type === "Slab" && el.slabs.length > 0 && el.slabs[el.slabs.length - 1]._id === props.parentProps[_nestedDriverInputVariableName] && ["IncrementalBlock", "IncrementalUnit"].includes(el.slabs[el.slabs.length - 1].type) &&
                                        <React.Fragment>
                                            <input
                                                value={props.parentProps[`${_nestedDriverInputVariableName}-${options(el)[el.slabs.length - 1].value}_other`] || ""}
                                                onChange={(e) => {
                                                    if (!this.reg.test(e.target.value) && e.target.value != "") return;
                                                    props.onInputChange(`${_nestedDriverInputVariableName}-${options(el)[el.slabs.length - 1].value}_other`, e.target.value)
                                                }}
                                                type="text"
                                                className="input-text other-input-text"
                                                placeholder="Enter Other Value"
                                            />
                                            {props.validator.message(`${_nestedDriverInputVariableName}-${options(el)[el.slabs.length - 1].value}_other`, props.parentProps[`${_nestedDriverInputVariableName}-${options(el)[el.slabs.length - 1].value}_other`], `_required|_numeric`, { className: 'text-danger' })}
                                        </React.Fragment>
                                    }

                                </div>
                            )
                            nestedDependentsJsx(nestedDependentsArray, _nestedDriverInputVariableName, driversJsx)
                        })
                    }
                }
            })
        }

        const currDriverFromDependencyList = props?.dependencyArray?.filter(ele => ele?._id === currDriver?._id && ele?.serviceId === currDriver?.service && ele?.dependents?.length > 0)[0]
        if (currDriverFromDependencyList) {
            const selectedVariationName = currDriverFromDependencyList?.variations?.filter(ele => ele?._id === props?.parentProps[inputVariableName])[0]?.name
            const dependentsArray = currDriverFromDependencyList?.dependents?.filter(ele => ele?.dependencyVariation === selectedVariationName)
            let splitKey = key.split("_")[0]
            removeNonSelectedDrivers(currDriverFromDependencyList, splitKey, inputVariableName)
            if (!dependentsArray.length) {
                if (selectedVariationName) {
                    removeNonSelectedDrivers(currDriverFromDependencyList, splitKey, inputVariableName)
                }
                if (currDriverFromDependencyList?.dependents?.length > 0) {
                    currDriverFromDependencyList.dependents.map((dependent) => {
                        if (dependent.service === splitKey) {
                            let customDriverId = `customPackagedPricingDriver-${categoryKey}-${key}-${dependent._id}`
                            let removeCustomDriverId = this.pricingDriverKeys.filter(e => e !== customDriverId)
                            this.pricingDriverKeys = removeCustomDriverId
                        }
                    })
                }
            }
            if (dependentsArray?.length > 0) {
                let driversJsx = [],
                    driverInputVariableName,
                    dependentsJsx = dependentsArray?.map((item) => {
                        driverInputVariableName = `customPackagedPricingDriver-${categoryKey}-${key}-${item._id}`
                        let _driverInputVariableName = `customPackagedPricingDriver-${categoryKey}-${key}-${item._id}`
                        let findKey = this.pricingDriverKeys.find(key => key === _driverInputVariableName)
                        !findKey && this.pricingDriverKeys.push(_driverInputVariableName);
                        //   setDefaultVariation(item, _driverInputVariableName)
                        driversJsx.push(
                            <div key={item?._id} style={{ fontSize: "13.56px" }} className="default-value-div mt-2 mb-2">
                                <label style={{ color: "#274b8a" }} className="required">{item?.name}</label>

                                {item.type === "Quantity" && <input
                                    value={props.parentProps[_driverInputVariableName] || ""}
                                    onChange={(e) => {
                                        if (!this.reg.test(e.target.value) && e.target.value != "") return;
                                        props.onInputChange(_driverInputVariableName, e.target.value)
                                    }}
                                    type="text"
                                    className="input-text"
                                    placeholder={item.name}
                                />}
                                {item.type === "Quantity" && props.validator.message(_driverInputVariableName, props.parentProps[_driverInputVariableName], `_required|_numeric|_min:0,num`, { className: 'text-danger' })}

                                {(item.type === "Variation" || item.type === "Slab") && <Select
                                    options={options(item, _driverInputVariableName)}
                                    onChange={selectedOption => { props.onInputChange(_driverInputVariableName, selectedOption.value); }}
                                // value={(props.parentProps[_driverInputVariableName] ? options(item)?.filter(option => option.value === props.parentProps[_driverInputVariableName]) : "")}
                                />}
                                {(item.type === "Variation" || item.type === "Slab") && props.validator.message(_driverInputVariableName, props.parentProps[_driverInputVariableName], `_required`, { className: 'text-danger' })}

                                {item.type === "Slab" && item.slabs.length > 0 && item.slabs[item.slabs.length - 1]._id === props.parentProps[_driverInputVariableName] && ["IncrementalBlock", "IncrementalUnit"].includes(item.slabs[item.slabs.length - 1].type) &&
                                    <React.Fragment>
                                        <input
                                            value={props.parentProps[`${_driverInputVariableName}-${options(item)[item.slabs.length - 1].value}_other`] || ""}
                                            onChange={(e) => {
                                                if (!this.reg.test(e.target.value) && e.target.value != "") return;
                                                props.onInputChange(`${_driverInputVariableName}-${options(item)[item.slabs.length - 1].value}_other`, e.target.value)
                                            }}
                                            type="text"
                                            className="input-text other-input-text"
                                            placeholder="Enter Other Value"
                                        />
                                        {props.validator.message(`${_driverInputVariableName}-${options(item)[item.slabs.length - 1].value}_other`, props.parentProps[`${_driverInputVariableName}-${options(item)[item.slabs.length - 1].value}_other`], `_required|_numeric`, { className: 'text-danger' })}
                                    </React.Fragment>
                                }

                            </div>
                        )
                        nestedDependentsJsx(dependentsArray, _driverInputVariableName, driversJsx)
                    })
                return driversJsx
            }
        }
    }

    render() {
        console.log('this.props - ', this.props);
        let jsx = [];
        if (this.props.parentProps.activePricingOption === "customPackagedPricing") {
            for (let categoryKey in this.props.parentProps.servicesByCategory) {
                if (this.props.parentProps.customPackagedPricingDriversServicesByCategory && this.props.parentProps.customPackagedPricingDriversServicesByCategory[categoryKey]) {
                    for (let key in this.props.parentProps.customPackagedPricingDriversServicesByCategory[categoryKey]['services']) {
                        for (let driver of this.props.parentProps.customPackagedPricingDriversServicesByCategory[categoryKey]['services'][key].pricingDrivers) {
                            if (!driver.isDependent) {
                                let inputVariableName = `customPackagedPricingDriver-${categoryKey}-${key}-${driver._id}`
                                let findKey = this.pricingDriverKeys.find(key => key === inputVariableName)
                                !findKey && this.pricingDriverKeys.push(inputVariableName)
                                let options;
                                if (driver.type === "Variation") {
                                    options = driver.variations.map(variationItem => ({ value: variationItem._id, label: variationItem.name }));
                                }
                                else if (driver.type === "Slab") {
                                    options = driver.slabs.map(slabItem => {
                                        if (["UnitBased", "BlockBased"].includes(slabItem.type))
                                            return ({ value: slabItem._id, label: `${slabItem.from}-${slabItem.to}` });
                                        else if (["IncrementalUnit", "IncrementalBlock"].includes(slabItem.type))
                                            return ({ value: slabItem._id, label: "Other" })
                                    });
                                }
                                else if (driver.type === "Number Range") {
                                    options = driver.number_ranges.map(numberRangeItem => ({ value: numberRangeItem._id, label: `${numberRangeItem.from}-${numberRangeItem.to}` }));
                                }
                                jsx.push(
                                    <div key={driver._id} className="default-value-div mb-3">
                                        {driver.type != "Constant" && <label>{driver.name}</label>}
                                        {driver.type === "Quantity" && <input
                                            value={this.props.parentProps[inputVariableName] || ""}
                                            onChange={(e) => {
                                                if (!this.reg.test(e.target.value) && e.target.value != "") return;
                                                this.props.onInputChange(inputVariableName, e.target.value)
                                            }}
                                            type="text"
                                            className="input-text"
                                            placeholder={driver.name}
                                        />}
                                        {driver.type === "Quantity" && this.props.validator.message(inputVariableName, this.props.parentProps[inputVariableName], `_required|_numeric|_min:0,num`, { className: 'text-danger' })}
                                        {driver.type === "Constant" && <input
                                            value={this.props.parentProps[inputVariableName] || ""}
                                            type="hidden"
                                            className="input-text"
                                            readOnly
                                        />}
                                        {driver.type === "Constant" && this.props.validator.message(inputVariableName, this.props.parentProps[inputVariableName], `_required`, { className: 'text-danger' })}
                                        {driver.type === "Variation" && <Select
                                            options={options}
                                            onChange={selectedOption => this.props.onInputChange(inputVariableName, selectedOption.value)}
                                            value={this.props.parentProps[inputVariableName] ? options.filter(option => option.value === this.props.parentProps[inputVariableName]) : ""}
                                        />}

                                        {/* ----------render_Dependents--------------------- */}
                                        {driver.type === "Variation" && this.renderDependents(this.props, driver, inputVariableName, key, driver._id, categoryKey)}
                                        {/* ---------------------------------------------- */}

                                        {driver.type === "Slab" && <Select
                                            options={options}
                                            onChange={selectedOption => this.props.onInputChange(inputVariableName, selectedOption.value)}
                                            value={this.props.parentProps[inputVariableName] ? options.filter(option => option.value === this.props.parentProps[inputVariableName]) : ""}
                                        />}
                                        {driver.type === "Number Range" && <Select
                                            options={options}
                                            onChange={selectedOption => this.props.onInputChange(inputVariableName, selectedOption.value)}
                                            value={this.props.parentProps[inputVariableName] ? options.filter(option => option.value === this.props.parentProps[inputVariableName]) : ""}
                                        />}
                                        {(driver.type === "Variation" || driver.type === "Slab" || driver.type === "Number Range") && this.props.validator.message(inputVariableName, this.props.parentProps[inputVariableName], `_required`, { className: 'text-danger' })}
                                        {driver.type === "Slab" && driver.slabs.length > 0 && driver.slabs[driver.slabs.length - 1]._id === this.props.parentProps[inputVariableName] && ["IncrementalBlock", "IncrementalUnit"].includes(driver.slabs[driver.slabs.length - 1].type) && <React.Fragment>
                                            <input
                                                value={this.props.parentProps[`${inputVariableName}-${options[driver.slabs.length - 1].value}_other`] || ""}
                                                onChange={(e) => {
                                                    if (!this.reg.test(e.target.value) && e.target.value != "") return;
                                                    this.props.onInputChange(`${inputVariableName}-${options[driver.slabs.length - 1].value}_other`, e.target.value)
                                                }}
                                                type="text"
                                                className="input-text other-input-text"
                                                placeholder="Enter Other Value"
                                            />
                                            {this.props.validator.message(`${inputVariableName}-${options[driver.slabs.length - 1].value}_other`, this.props.parentProps[`${inputVariableName}-${options[driver.slabs.length - 1].value}_other`], `_required|_numeric`, { className: 'text-danger' })}
                                        </React.Fragment>}
                                    </div>
                                );
                                this.pricingDriverKeys.length > 0 && JSON.stringify(this.pricingDriverKeys) !== JSON.stringify(this.props.parentProps.customPackagedPricingDrivers) && this.props.onInputChange('customPackagedPricingDrivers', this.pricingDriverKeys)
                            }
                        }
                    }
                }
            }
        }

        const idUsed = [];
        // console.log('this.props.pricingDrivers - ', this.props.pricingDrivers);
        this.props.pricingDrivers.forEach((_item, index) => {
            let item = _item;
            // if(item.coupled && item.globalDriverId) {
            //     item = item.globalDriverId;
            // }
            const getGlobalDriverId = (item) => item && item.globalDriverId ? item.globalDriverId._id : item._id;
            if (idUsed.includes(getGlobalDriverId(_item))) return;
            let inputVariableName = `globalPricingDriver-${item._id}`;
            let changeVariableNames = [`globalPricingDriver-${item._id}`];
            const howMany = this.props.pricingDrivers.filter(c => getGlobalDriverId(c) === getGlobalDriverId(_item) && c._id !== _item._id);
            // console.log('howMany - ', howMany)
            if (howMany.length > 0) changeVariableNames = [...changeVariableNames, ...howMany.map(c => `globalPricingDriver-${c._id}`)];
            // console.log('changeVariableNames - ', changeVariableNames);
            // if(howMany.length > 0) changeVariableNames = new Array(howMany.length + 1).fill(`globalPricingDriver-${item._id}`);
            idUsed.push(getGlobalDriverId(_item));
            // console.log('idUsed - ', idUsed);
            let options;
            let defaultVariation;
            let defaultSlab;
            // console.log('item - ', item);
            if (item.type === "Variation") {
                options = item.variations.map(variationItem => ({ value: variationItem._id, label: variationItem.name }));
                defaultVariation = item?.variations?.find((iv) => iv.is_default === true)
                defaultVariation = {
                    value: defaultVariation?._id,
                    label: defaultVariation?.name
                }
                if (!options.filter(option => option.value === this.props.parentProps[inputVariableName]).length) {
                    this.props.onInputChange(changeVariableNames, defaultVariation.value)
                }
                changeVariableNames.forEach(name => {
                    if (!this.props.parentProps[name]) {
                        this.props.onInputChange(changeVariableNames, defaultVariation.value);
                    }
                })
            }
            else if (item.type === "Slab") {
                options = item.slabs.map(slabItem => {
                    if (["UnitBased", "BlockBased"].includes(slabItem.type))
                        return ({ value: slabItem._id, label: `${formatValue(slabItem.from)} - ${formatValue(slabItem.to)}` });
                    else if (["IncrementalUnit", "IncrementalBlock"].includes(slabItem.type))
                        return ({ value: slabItem._id, label: "Other" })
                });
                defaultSlab = item?.slabs?.find((iv) => iv.is_default === true)
                defaultSlab = {
                    value: defaultSlab?._id,
                    label: defaultSlab?.name
                }
                if (!options.filter(option => option.value === this.props.parentProps[inputVariableName]).length) {
                    this.props.onInputChange(changeVariableNames, defaultSlab.value)
                }
                changeVariableNames.forEach(name => {
                    if (!this.props.parentProps[name]) {
                        this.props.onInputChange(changeVariableNames, defaultSlab.value);
                    }
                })
            }
            else if (item.type === "Number Range") {
                options = item.number_ranges.map(numberRangeItem => ({ value: numberRangeItem._id, label: `${numberRangeItem.from}-${numberRangeItem.to}` }));
            }
            jsx.push(
                <div key={item._id} className="default-value-div mb-3">
                    {item.type != "Constant" && <label>{item.name}</label>}
                    {item.type === "Quantity" && <input
                        value={this.props.parentProps[inputVariableName] || ""}
                        onChange={(e) => {
                            if (!this.reg.test(e.target.value) && e.target.value != "") return;
                            this.props.onInputChange(changeVariableNames, e.target.value)
                        }}
                        type="text"
                        className="input-text"
                        placeholder={item.name}
                    />}
                    {item.type === "Quantity" && this.props.validator.message(inputVariableName, this.props.parentProps[inputVariableName], `_required|_numeric|_min:0,num`, { className: 'text-danger' })}
                    {item.type === "Constant" && <input
                        value={this.props.parentProps[inputVariableName] || ""}
                        type="hidden"
                        className="input-text"
                        readOnly
                    />}
                    {item.type === "Constant" && this.props.validator.message(inputVariableName, this.props.parentProps[inputVariableName], `_required`, { className: 'text-danger' })}
                    {item.type === "Variation" && <Select
                        options={options}
                        onChange={selectedOption => {
                            this.props.onInputChange(changeVariableNames, selectedOption.value)
                        }}
                        value={this.props.parentProps[inputVariableName] ? options.filter(option => {
                            return option.value === this.props.parentProps[inputVariableName]
                        }) : ""}
                    />}
                    {item.type === "Slab" && <Select
                        options={options}
                        onChange={selectedOption => this.props.onInputChange(changeVariableNames, selectedOption.value)}
                        value={this.props.parentProps[inputVariableName] ? options.filter(option => option.value === this.props.parentProps[inputVariableName]) : ""}
                    />}
                    {item.type === "Number Range" && <Select
                        options={options}
                        onChange={selectedOption => this.props.onInputChange(changeVariableNames, selectedOption.value)}
                        value={this.props.parentProps[inputVariableName] ? options.filter(option => option.value === this.props.parentProps[inputVariableName]) : ""}
                    />}
                    {(item.type === "Variation" || item.type === "Slab" || item.type === "Number Range") && this.props.validator.message(inputVariableName, this.props.parentProps[inputVariableName], `_required`, { className: 'text-danger' })}
                    {item.type === "Slab" && item.slabs.length > 0 && item.slabs[item.slabs.length - 1]._id === this.props.parentProps[inputVariableName] && ["IncrementalBlock", "IncrementalUnit"].includes(item.slabs[item.slabs.length - 1].type) && <React.Fragment>
                        <input
                            value={this.props.parentProps[`${inputVariableName}-${options[item.slabs.length - 1].value}_other`] || ""}
                            onChange={(e) => {
                                if (!this.reg.test(e.target.value) && e.target.value != "") return;
                                this.props.onInputChange(`${inputVariableName}-${options[item.slabs.length - 1].value}_other`, e.target.value)
                            }}
                            type="text"
                            className="input-text other-input-text"
                            placeholder="Enter Other Value"
                        />
                        {this.props.validator.message(`${inputVariableName}-${options[item.slabs.length - 1].value}_other`, this.props.parentProps[`${inputVariableName}-${options[item.slabs.length - 1].value}_other`], `_required|_numeric`, { className: 'text-danger' })}
                    </React.Fragment>}
                </div>
            );
        });
        return (jsx.length > 0 || !this.props.showNoInfoBlock) ? jsx : <div className="text-center">No Information Required!</div>;
    }
}

export default RequiredGlobalPricingDrivers;
