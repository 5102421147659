import React from "react";

const FullPageHeadingContent = (props) => {
	return (
		<React.Fragment>
			<div className="row fieldset">
				<div className="col-2">
					<label className="fieldset-label required">Heading</label>
				</div>
				<div className="col-10">
					<input value={props.heading || ""} onChange={(e) => props.onInputChange(`element${props.code}Heading`, e.target.value)} className="input-text" type="text" placeholder="Heading" />
					{props.validator.message(`element${props.code}Heading`, props.heading, '_required', { className: 'text-danger' })}
				</div>
			</div>
			<div className="row fieldset">
				<div className="col-2">
					<label className="fieldset-label">Short Description</label>
				</div>
				<div className="col-10">
					<input value={props.description || ""} onChange={(e) => props.onInputChange(`element${props.code}Description`, e.target.value)} className="input-text" type="text" placeholder="Short Description" />
				</div>
			</div>
		</React.Fragment>
	);
}

export default FullPageHeadingContent;
