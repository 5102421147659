import {
  SET_QUOTE_LOADING,
  ON_QUOTE_INPUT_CHANGE,
  ADD_SERVICE_TO_A_LA_CARTE_PACKAGE,
  REMOVE_SERVICE_FROM_A_LA_CARTE_PACKAGE,
  SET_QUOTE_ACTIVE_STEP,
  PREVIEW_QUOTE,
  PREVIEW_QUOTE_SUCCESS,
  SEND_QUOTATION,
  SEND_QUOTATION_SUCCESS,
  SAVE_QUOTATION_AS_DRAFT,
  SAVE_QUOTATION_AS_DRAFT_SUCCESS,
  SKIP_QUOTATION,
  SKIP_QUOTATION_SUCCESS,
  SET_SHOW_CREATE_NEW_QUOTE_SUCCESS_MODAL,
  SET_SHOW_ADD_CUSTOM_PACKAGE_MODAL,
  CALCULATE_PACKAGE_PRICE,
  CALCULATE_PACKAGE_PRICE_SUCCESS,
  RESET_CREATE_NEW_QUOTE_FORM,
  GET_QUOTES,
  GET_QUOTES_SUCCESS,
  GET_QUOTE,
  GET_QUOTE_SUCCESS,
  GET_SENT_QUOTES,
  GET_SENT_QUOTES_SUCCESS,
  GET_SKIP_QUOTES,
  GET_SKIP_QUOTES_SUCCESS,
  ADD_PACKAGED_PRICING_PACKAGE,
  REMOVE_PACKAGED_PRICING_PACKAGE,
  REVIEW_PACKAGED_PRICING_PACKAGES,
  REVIEW_PACKAGED_PRICING_PACKAGES_SUCCESS,
  REVIEW_CUSTOM_PACKAGED_PRICING_PACKAGES,
  REVIEW_CUSTOM_PACKAGED_PRICING_PACKAGES_SUCCESS,
  GET_PRICING_DRIVERS_BY_PACKAGE_IDS,
  GET_PRICING_DRIVERS_BY_PACKAGE_IDS_SUCCESS,
  GET_PRICING_DRIVERS_FOR_CUSTOM_PACKAGED_PRICING,
  GET_PRICING_DRIVERS_FOR_CUSTOM_PACKAGED_PRICING_SUCCESS,
  ADD_CUSTOM_PACKAGE,
  EDIT_ADD_CUSTOM_PACKAGE_MODAL,
  SET_ADD_CUSTOM_PACKAGE_MODAL_SERVICES,
  SEARCH_QUOTE,
  SEARCH_QUOTE_SUCCESS,
  GET_QUOTE_STATUS,
  GET_QUOTE_CLIENT,
  GET_QUOTE_CLIENT_SUCCESS,
  RESET_SEARCH_INPUT,
} from "./actionTypes";

const initialState = {
  loading: false,
  activeStep: 1,
  activePricingOption: "aLaCartePricing",
  aLaCarteServices: [],
  aLaCartePricingDrivers: [],
  previewQuotePDFUrl: null,
  previewContractPDFUrl: null,
  calculatedPackagePrice: {},
  recurringTotal: 0,
  oneOffTotal: 0,
  paymentFrequency: "monthly",
  quotes: [],
  searchedQuotes: [],
  sentQuotes: [],
  skipQuotes: [],
  pagination: {},
  packagedPricingSelectedPackages: [],
  reviewPackagedPricingSelectedPackages: {},
  reviewPackagedPricingServicesByCategory: {},
  reviewPackagedPricingGlobalPricingDrivers: {},
  customPackages: {},
  customPackagedPricingDrivers: [],
  customPackagedPricingDriversServicesByCategory: {},
  addCustomPackageModalName: "",
  addCustomPackageModalServices: [],
  addCustomPackageModalMode: "add",
  addCustomPackageModalId: null,
  showFullBreakdown: true,
  skipQuote: null,
  tempPricingDrivers: {},
  tempDiscountedPrice: 0,
  tempDiscountedPriceOneOff: 0
};

const quote = (state = initialState, action) => {
  let newState;
  switch (action.type) {
    case SET_QUOTE_LOADING:
      return { ...state, loading: action.payload };
    case ON_QUOTE_INPUT_CHANGE:
      console.log("action.payload - ", action.payload);
      let tPricingDrivers = state.tempPricingDrivers;
      // if (action.payload.isPricingDrivers) {
      // 	tPricingDrivers = {
      // 		...tPricingDrivers,
      // 		[action.payload.field]: action.payload.value
      // 	}
      // }
      if (action.payload.field instanceof Array) {
        let obj = action.payload.field.reduce((acc, cur) => {
          acc[cur] = action.payload.value;
          return acc;
        }, {});
        console.log("obj - ", obj);
        return { ...state, ...obj, tempPricingDrivers: tPricingDrivers };
      }
      return {
        ...state,
        [action.payload.field]: action.payload.value,
        tempPricingDrivers: tPricingDrivers,
      };
    case ADD_SERVICE_TO_A_LA_CARTE_PACKAGE:
      newState = {
        ...state,
        aLaCarteServices: [...state.aLaCarteServices, action.payload.service],
        aLaCartePricingDrivers: [...state.aLaCartePricingDrivers].concat(
          action.payload.pricingDriverKeys
        ),
      };
      action.payload.pricingDriverKeys.forEach((item) => {
        newState[item] = null;
      });
      return newState;
    case REMOVE_SERVICE_FROM_A_LA_CARTE_PACKAGE:
      return {
        ...state,
        aLaCarteServices: state.aLaCarteServices.filter(
          (item) => item !== action.payload.service
        ),
        aLaCartePricingDrivers: state.aLaCartePricingDrivers.filter(
          (item) => !action.payload.pricingDriverKeys.includes(item)
        ),
      };
    case SET_QUOTE_ACTIVE_STEP:
      return { ...state, activeStep: action.payload };
    case PREVIEW_QUOTE:
      return { ...state, loading: true, previewQuotePDFUrl: null };
    case PREVIEW_QUOTE_SUCCESS:
      return {
        ...state,
        loading: false,
        previewQuotePDFUrl: action.payload.quoteUrl,
      };
    case SEND_QUOTATION:
      return { ...state, loading: true };
    case SEND_QUOTATION_SUCCESS:
      return {
        ...state,
        loading: false,
        showCreateNewQuoteSuccessModal: true,
        createNewQuoteSuccessModalMessage:
          "Proposal has been sent successfully!",
      };
    case SKIP_QUOTATION:
      return { ...state, loading: true };
    case SKIP_QUOTATION_SUCCESS:
      return { ...state, loading: false, skipQuote: action.payload };
    case SAVE_QUOTATION_AS_DRAFT:
      return { ...state, loading: true };
    case SAVE_QUOTATION_AS_DRAFT_SUCCESS:
      return {
        ...initialState,
        loading: false,
        showCreateNewQuoteSuccessModal: true,
        createNewQuoteSuccessModalMessage: "Proposal has been saved as draft!",
      };
    case SET_SHOW_CREATE_NEW_QUOTE_SUCCESS_MODAL:
      return { ...state, showCreateNewQuoteSuccessModal: action.payload };
    case SET_SHOW_ADD_CUSTOM_PACKAGE_MODAL:
      return { ...state, showAddCustomPackageModal: action.payload };
    case CALCULATE_PACKAGE_PRICE:
      return { ...state, loading: true };
    case CALCULATE_PACKAGE_PRICE_SUCCESS:
      // console.log();
      let packageOneOffPrice = action.payload.packageOneOffPrice ?? state.packageOneOffPrice
      let packageRecurringPrice = action.payload.packageRecurringPrice ?? state.packageRecurringPrice;
      if (
        action.payload.packageOneOffPrice == null &&
        action.payload.oneOffTotal > 0 &&
        (packageOneOffPrice == 0 || packageOneOffPrice == null) &&
        state.tempDiscountedPriceOneOff != 100
      ) {
        packageOneOffPrice = action.payload.oneOffTotal;
      }
      if (
        (action.payload.packageRecurringPrice == null || action.payload.packageRecurringPrice == 0) &&
        action.payload.recurringTotal > 0 &&
        (packageRecurringPrice == 0 || packageRecurringPrice == null) &&
        state.tempDiscountedPrice != 100
      ) {
        packageRecurringPrice = action.payload.recurringTotal;
      }
      return {
        ...state,
        loading: false,
        calculatedPackagePrice: action.payload.calculatedPackagePrice,
        recurringTotal: action.payload.recurringTotal,
        oneOffTotal: action.payload.oneOffTotal,
        packageOneOffPrice,
        packageRecurringPrice,
      };
    case GET_QUOTES:
      return { ...state, loading: true };
    case GET_QUOTE_STATUS:
      newState = {
        ...state,
        loading: false,
        quoteStatus: action.payload.data.result.status,
      };
      return newState;
    case GET_QUOTES_SUCCESS:
      return {
        ...state,
        loading: false,
        quotes: action.payload.quotes,
        pagination: {
          count: action.payload.count,
          page_num: action.payload.page_num,
          total_pages: action.payload.total_pages,
        },
      };
    case GET_SENT_QUOTES:
      return { ...state, loading: true };
    case GET_SENT_QUOTES_SUCCESS:
      return { ...state, loading: false, sentQuotes: action.payload.quotes };
    case GET_SKIP_QUOTES:
      return { ...state, loading: true };
    case GET_SKIP_QUOTES_SUCCESS:
      return { ...state, loading: false, skipQuotes: action.payload.quotes };
    case GET_QUOTE:
      return { ...state, loading: true };
    case GET_QUOTE_SUCCESS:
      newState = {
        ...state,
        loading: false,
        quoteReference: `${action.payload.prefix}-${action.payload.code}`,
        quoteClient: action.payload.client,
        quotePdfLink: action.payload.pdf_link,
        // quoteClientData: action.payload.data.client,
        quoteData: action.payload.data,
        quoteTemplate: action.payload.template,
        activePricingOption: action.payload.data.type,
        paymentFrequency: action.payload.payment_frequency,
        showFullBreakdown: action.payload.show_full_breakdown,
      };
      newState.quoteId = action.payload._id;
      newState.tempDiscountedPrice = parseFloat(
        action.payload.data.tempDiscountedPrice ?? 0
      ).toFixed(2);
      newState.tempDiscountedPriceOneOff = parseFloat(
        action.payload.data.tempDiscountedPriceOneOff ?? 0
      ).toFixed(2);
      if (action.payload.data.type === "aLaCartePricing") {
        newState.aLaCarteServices = [];
        newState.aLaCartePricingDrivers = [];
        newState.packageRecurringPrice = action.payload.recurring_total;
        newState.recurringTotal = 0;
        newState.packageOneOffPrice = action.payload.one_off_total;
        newState.oneOffTotal = 0;
        if (
          ((action.payload.data.selectedServices &&
            Object.keys(action.payload.data.selectedServices).length == 0) ||
            !action.payload.data.selectedServices) &&
          action.payload.data?.tempSelectedServices
        ) {
          newState.aLaCarteServices = action.payload.data.tempSelectedServices;

          if (action.payload.data.tempPricingDrivers) {
            newState.tempPricingDrivers =
              action.payload.data.tempPricingDrivers;
            for (let pricingDriverKey in action.payload.data
              .tempPricingDrivers) {
              newState.aLaCartePricingDrivers.push(pricingDriverKey);
              newState[pricingDriverKey] =
                action.payload.data.tempPricingDrivers[pricingDriverKey];
            }
          }
        } else {
          for (let categoryKey in action.payload.data.selectedServices) {
            for (let serviceKey in action.payload.data.selectedServices[
              categoryKey
            ].services) {
              newState.aLaCarteServices.push(`${categoryKey}-${serviceKey}`);
              for (let pricingDriverKey in action.payload.data.selectedServices[
                categoryKey
              ].services[serviceKey].pricingDrivers) {
                newState.aLaCartePricingDrivers.push(
                  `pricingDriver-${categoryKey}-${serviceKey}-${pricingDriverKey}`
                );
                let value = action.payload.data.selectedServices[categoryKey].services[
                  serviceKey
                ].pricingDrivers[pricingDriverKey].value
                let otherValue = action.payload.data.selectedServices[categoryKey].services[
                  serviceKey
                ].pricingDrivers[pricingDriverKey].other;
                newState[
                  `pricingDriver-${categoryKey}-${serviceKey}-${pricingDriverKey}`
                ] = value
                if (otherValue) {
                  newState[
                    `pricingDriver-${categoryKey}-${serviceKey}-${pricingDriverKey}-${value}_other`
                  ] = otherValue;
                }
              }
            }
          }
        }
      } else if (action.payload.data.type === "packagedPricing") {
        newState.packagedPricingSelectedPackages = action.payload.data
          .packagedPricingSelectedPackages
          ? Object.keys(action.payload.data.packagedPricingSelectedPackages)
          : [];
        // newState.packagedPricingSelectedPackages = action.payload.data.packagedPricingSelectedPackages;
      } else if (action.payload.data.type === "customPackagedPricing") {
        if (action.payload.data.packagedPricingSelectedPackages) {
          newState.packagedPricingSelectedPackages = action.payload.data
            .customPackages
            ? Object.keys(
              action.payload.data.packagedPricingSelectedPackages
            ).filter(
              (item) =>
                !action.payload.data.customPackages.hasOwnProperty(item)
            )
            : Object.keys(action.payload.data.packagedPricingSelectedPackages);
          // newState.packagedPricingSelectedPackages = action.payload.data.customPackages ? action.payload.data.packagedPricingSelectedPackages.filter(item => !action.payload.data.customPackages.hasOwnProperty(item)) : action.payload.data.packagedPricingSelectedPackages;
        } else {
          newState.packagedPricingSelectedPackages = [];
        }
        // newState.packagedPricingSelectedPackages = action.payload.data.packagedPricingSelectedPackages;
        // newState.packagedPricingSelectedPackages = Object.keys(action.payload.data.packagedPricingSelectedPackages).filter(item => !action.payload.data.customPackages.hasOwnProperty(item));
        newState.customPackages = action.payload.data.customPackages
          ? action.payload.data.customPackages
          : {};
        for (let driverKey in action.payload.data
          .customPackagedPricingDrivers) {
          newState[`customPackagedPricingDriver-${driverKey}`] =
            action.payload.data.customPackagedPricingDrivers[driverKey];
        }
      }
      if (action.payload.data.globalPricingDrivers) {
        for (let driverKey in action.payload.data.globalPricingDrivers) {
          newState[`globalPricingDriver-${driverKey}`] =
            action.payload.data.globalPricingDrivers[driverKey].value;
          if (action.payload.data.globalPricingDrivers[driverKey].other) {
            newState[
              `globalPricingDriver-${driverKey}-${action.payload.data.globalPricingDrivers[driverKey].value}_other`
            ] = action.payload.data.globalPricingDrivers[driverKey].other;
          }
        }
      }
      return newState;
    case ADD_PACKAGED_PRICING_PACKAGE:
      return {
        ...state,
        packagedPricingSelectedPackages: [
          ...state.packagedPricingSelectedPackages,
          action.payload,
        ],
      };
    case REMOVE_PACKAGED_PRICING_PACKAGE:
      return {
        ...state,
        packagedPricingSelectedPackages:
          state.packagedPricingSelectedPackages.filter(
            (item, index) => item !== action.payload
          ),
      };
    case REVIEW_PACKAGED_PRICING_PACKAGES:
      return { ...state, loading: true };
    case REVIEW_PACKAGED_PRICING_PACKAGES_SUCCESS:
      return {
        ...state,
        loading: false,
        reviewPackagedPricingSelectedPackages: action.payload.packages,
        reviewPackagedPricingServicesByCategory:
          action.payload.servicesByCategory,
        reviewPackagedPricingGlobalPricingDrivers:
          action.payload.globalPricingDrivers,
      };
    case REVIEW_CUSTOM_PACKAGED_PRICING_PACKAGES:
      return { ...state, loading: true };
    case REVIEW_CUSTOM_PACKAGED_PRICING_PACKAGES_SUCCESS:
      return {
        ...state,
        loading: false,
        reviewPackagedPricingSelectedPackages: action.payload.packages,
        reviewPackagedPricingServicesByCategory:
          action.payload.servicesByCategory,
        reviewPackagedPricingGlobalPricingDrivers:
          action.payload.globalPricingDrivers,
      };
    case GET_PRICING_DRIVERS_BY_PACKAGE_IDS:
      return { ...state, loading: true };
    case GET_PRICING_DRIVERS_BY_PACKAGE_IDS_SUCCESS:
      return { ...state, loading: false };
    case GET_PRICING_DRIVERS_FOR_CUSTOM_PACKAGED_PRICING:
      return { ...state, loading: true };
    case GET_PRICING_DRIVERS_FOR_CUSTOM_PACKAGED_PRICING_SUCCESS:
      newState = {
        ...state,
        loading: false,
        customPackagedPricingDriversServicesByCategory: action.payload,
      };
      newState.customPackagedPricingDrivers = [];
      for (let categoryKey in action.payload) {
        if (action.payload[categoryKey].services) {
          for (let serviceKey in action.payload[categoryKey].services) {
            if (
              action.payload[categoryKey].services[serviceKey].pricingDrivers
            ) {
              for (let pricingDriver of action.payload[categoryKey].services[
                serviceKey
              ].pricingDrivers) {
                newState.customPackagedPricingDrivers.push(
                  `customPackagedPricingDriver-${categoryKey}-${serviceKey}-${pricingDriver._id}`
                );
              }
            }
          }
        }
      }
      return newState;
    case ADD_CUSTOM_PACKAGE:
      return {
        ...state,
        customPackages: {
          ...state.customPackages,
          [action.payload.code]: {
            name: action.payload.name,
            services: action.payload.services,
          },
        },
        showAddCustomPackageModal: false,
        addCustomPackageModalMode: "add",
        addCustomPackageModalServices: [],
        addCustomPackageModalName: "",
        addCustomPackageModalId: null,
      };
    case EDIT_ADD_CUSTOM_PACKAGE_MODAL:
      return {
        ...state,
        addCustomPackageModalId: action.payload,
        addCustomPackageModalMode: "edit",
        showAddCustomPackageModal: true,
        addCustomPackageModalName: state.customPackages[action.payload].name,
        addCustomPackageModalServices:
          state.customPackages[action.payload].services,
      };
    case SET_ADD_CUSTOM_PACKAGE_MODAL_SERVICES:
      return {
        ...state,
        addCustomPackageModalServices: action.payload,
      };
    case SEARCH_QUOTE_SUCCESS:
      return { ...state, searchedQuotes: action.payload };
    case RESET_CREATE_NEW_QUOTE_FORM:
      return initialState;
    case GET_QUOTE_CLIENT:
      return { ...state, loading: true };
    case GET_QUOTE_CLIENT_SUCCESS:
      newState = {
        ...state,
        loading: false,
        quoteReference: `${action.payload.prefix}-${action.payload.code}`,
        quoteClient: action.payload.client,
        quotePdfLink: action.payload.pdf_link,
        // quoteClientData: action.payload.data.client,
        quoteData: action.payload.data,
        quoteTemplate: action.payload.template,
        activePricingOption: action.payload.data.type,
        paymentFrequency: action.payload.payment_frequency,
        showFullBreakdown: action.payload.show_full_breakdown,
      };
      newState.quoteId = action.payload._id;
      newState.tempDiscountedPrice = parseFloat(
        action.payload.data.tempDiscountedPrice ?? 0
      );
      newState.tempDiscountedPriceOneOff = parseFloat(
        action.payload.data.tempDiscountedPriceOneOff ?? 0
      );
      if (action.payload.data.type === "aLaCartePricing") {
        newState.aLaCarteServices = [];
        newState.aLaCartePricingDrivers = [];
        newState.packageRecurringPrice = action.payload.recurring_total;
        newState.packageOneOffPrice = action.payload.one_off_total;
        if (
          ((action.payload.data.selectedServices &&
            Object.keys(action.payload.data.selectedServices).length == 0) ||
            !action.payload.data.selectedServices) &&
          action.payload.data?.tempSelectedServices
        ) {
          newState.aLaCarteServices = action.payload.data.tempSelectedServices;

          if (action.payload.data.tempPricingDrivers) {
            newState.tempPricingDrivers =
              action.payload.data.tempPricingDrivers;
            for (let pricingDriverKey in action.payload.data
              .tempPricingDrivers) {
              newState.aLaCartePricingDrivers.push(pricingDriverKey);
              newState[pricingDriverKey] =
                action.payload.data.tempPricingDrivers[pricingDriverKey];
            }
          }
        } else {
          for (let categoryKey in action.payload.data.selectedServices) {
            for (let serviceKey in action.payload.data.selectedServices[
              categoryKey
            ].services) {
              newState.aLaCarteServices.push(`${categoryKey}-${serviceKey}`);
              for (let pricingDriverKey in action.payload.data.selectedServices[
                categoryKey
              ].services[serviceKey].pricingDrivers) {
                newState.aLaCartePricingDrivers.push(
                  `pricingDriver-${categoryKey}-${serviceKey}-${pricingDriverKey}`
                );
                newState[
                  `pricingDriver-${categoryKey}-${serviceKey}-${pricingDriverKey}`
                ] =
                  action.payload.data.selectedServices[categoryKey].services[
                    serviceKey
                  ].pricingDrivers[pricingDriverKey].value;
              }
            }
          }
        }
      } else if (action.payload.data.type === "packagedPricing") {
        newState.packagedPricingSelectedPackages = action.payload.data
          .packagedPricingSelectedPackages
          ? Object.keys(action.payload.data.packagedPricingSelectedPackages)
          : [];
        // newState.packagedPricingSelectedPackages = action.payload.data.packagedPricingSelectedPackages;
      } else if (action.payload.data.type === "customPackagedPricing") {
        if (action.payload.data.packagedPricingSelectedPackages) {
          newState.packagedPricingSelectedPackages = action.payload.data
            .customPackages
            ? Object.keys(
              action.payload.data.packagedPricingSelectedPackages
            ).filter(
              (item) =>
                !action.payload.data.customPackages.hasOwnProperty(item)
            )
            : Object.keys(action.payload.data.packagedPricingSelectedPackages);
          // newState.packagedPricingSelectedPackages = action.payload.data.customPackages ? action.payload.data.packagedPricingSelectedPackages.filter(item => !action.payload.data.customPackages.hasOwnProperty(item)) : action.payload.data.packagedPricingSelectedPackages;
        } else {
          newState.packagedPricingSelectedPackages = [];
        }
        // newState.packagedPricingSelectedPackages = action.payload.data.packagedPricingSelectedPackages;
        // newState.packagedPricingSelectedPackages = Object.keys(action.payload.data.packagedPricingSelectedPackages).filter(item => !action.payload.data.customPackages.hasOwnProperty(item));
        newState.customPackages = action.payload.data.customPackages
          ? action.payload.data.customPackages
          : {};
        for (let driverKey in action.payload.data
          .customPackagedPricingDrivers) {
          newState[`customPackagedPricingDriver-${driverKey}`] =
            action.payload.data.customPackagedPricingDrivers[driverKey];
        }
      }
      if (action.payload.data.globalPricingDrivers) {
        for (let driverKey in action.payload.data.globalPricingDrivers) {
          newState[`globalPricingDriver-${driverKey}`] =
            action.payload.data.globalPricingDrivers[driverKey].value;
          if (action.payload.data.globalPricingDrivers[driverKey].other) {
            newState[
              `globalPricingDriver-${driverKey}-${action.payload.data.globalPricingDrivers[driverKey].value}_other`
            ] = action.payload.data.globalPricingDrivers[driverKey].other;
          }
        }
      }
      return newState;
    case RESET_SEARCH_INPUT:
      return {
        ...state,
        quoteSearchInput: ""
      }
    default:
      return state;
  }
};

export default quote;
