import React, { useState } from "react";
import { formatCurrency } from "../Utils";

const ReviewServices = (props) => {
  const [show, setShow] = useState(false);
  let jsx = [];
  let total = 0;
  let calculatePriceMethod = false;
  let calculatePriceDigit = 0;
  console.log('spec_check_current', props);
  let yearlyPrice = {
    yearly: (val) => val,
    halfYearly: (val) => val * 2,
    quarterly: (val) => val * 4,
    monthly: (val) => val * 12,
  };
  let calculateRecurringPrice = {
    yearly: (val) => val,
    halfYearly: (val) => val / 2,
    quarterly: (val) => val / 4,
    monthly: (val) => val / 12,
  };
  if ('paymentFrequency' in props && props.paymentFrequency !== props.currentPaymentFrequency) {
    calculatePriceMethod = true;
  }
  for (let categoryKey in props.calculatedPackagePrice) {
    let serviceJsx = [];
    for (let serviceKey in props.calculatedPackagePrice[categoryKey].services) {
      let serviceChargeType = serviceKey.split("_")[1];
      if (serviceChargeType === props.chargeType) {
        let pricingDriverJsx = [];
        if (
          props.calculatedPackagePrice[categoryKey].services[
            serviceKey
          ].hasOwnProperty("pricingDrivers")
        ) {
          for (let pricingDriverKey in props.calculatedPackagePrice[categoryKey]
            .services[serviceKey].pricingDrivers) {
            let pricingDriverValue;
            if (
              props.calculatedPackagePrice[categoryKey].services[serviceKey]
                .pricingDrivers[pricingDriverKey].type === "Quantity"
            ) {
              pricingDriverValue =
                props.calculatedPackagePrice[categoryKey].services[serviceKey]
                  .pricingDrivers[pricingDriverKey].value;
            } else if (
              props.calculatedPackagePrice[categoryKey].services[serviceKey]
                .pricingDrivers[pricingDriverKey].type === "Variation"
            ) {
              for (let variation of props.calculatedPackagePrice[categoryKey]
                .services[serviceKey].pricingDrivers[pricingDriverKey]
                .variations) {
                if (
                  variation._id ===
                  props.calculatedPackagePrice[categoryKey].services[serviceKey]
                    .pricingDrivers[pricingDriverKey].value
                ) {
                  pricingDriverValue = variation.name;
                  break;
                }
              }
            } else if (
              props.calculatedPackagePrice[categoryKey].services[serviceKey]
                .pricingDrivers[pricingDriverKey].type === "Slab"
            ) {
              for (let slab of props.calculatedPackagePrice[categoryKey]
                .services[serviceKey].pricingDrivers[pricingDriverKey].slabs) {
                if (
                  slab._id ===
                  props.calculatedPackagePrice[categoryKey].services[serviceKey]
                    .pricingDrivers[pricingDriverKey].value
                ) {
                  if (slab.type === "BlockBased")
                    pricingDriverValue = `${slab.from}-${slab.to}`;
                  else if (slab.type === "IncrementalBlock")
                    pricingDriverValue =
                      props.calculatedPackagePrice[categoryKey].services[
                        serviceKey
                      ].pricingDrivers[pricingDriverKey].other;
                  break;
                }
              }
            }
            pricingDriverJsx
              .push
              // <div key={`${categoryKey}-${serviceKey}-${pricingDriverKey}`}>{props.calculatedPackagePrice[categoryKey].services[serviceKey].pricingDrivers[pricingDriverKey].name}: <b>{pricingDriverValue}</b></div>
              ();
          }
        }
        let serviceAmount = props.calculatedPackagePrice[categoryKey].services[serviceKey].servicePrice;
        if (calculatePriceMethod) {
          console.log('spec_spec_cecepecp', props);
          console.log('spec_spec_cecepecp', serviceAmount, calculatePriceMethod, props.paymentFrequency, props.currentPaymentFrequency, yearlyPrice[props.paymentFrequency](serviceAmount), calculateRecurringPrice[props.currentPaymentFrequency](yearlyPrice[props.paymentFrequency](serviceAmount)))
          serviceAmount = calculateRecurringPrice[props.currentPaymentFrequency](yearlyPrice[props.paymentFrequency](serviceAmount));
        }
        serviceJsx.push(
          <tr key={`${categoryKey}-${serviceKey}`}>
            <td>
              <div>
                {props.calculatedPackagePrice[categoryKey].services[serviceKey]
                  .serviceName
                  ? props.calculatedPackagePrice[categoryKey].services[
                    serviceKey
                  ].serviceName.substring(0, 45)
                  : ""}
              </div>
              <div className="package-variables">{pricingDriverJsx}</div>
            </td>
            <td>
              {props.flag &&
                formatCurrency(
                  serviceAmount
                )}
            </td>
          </tr>
        );
        total += +serviceAmount;
      }
    }
    if (serviceJsx.length > 0) {
      jsx.push(
        <tr
          key={`${categoryKey}-${jsx.length}`}
          className="a-la-carte-services-review-head-row"
        >
          <th colSpan={2}>
            {props.calculatedPackagePrice[categoryKey].categoryName.substring(
              0,
              30
            )}
          </th>
        </tr>
      );
      jsx = jsx.concat(serviceJsx);
    }
  }
  // discountedPrice
  if (jsx.length > 0) {
    let discountedPrice = props.discountedPrice;
    if (discountedPrice) {
      // if (calculatePriceMethod) {
      //   discountedPrice = calculateRecurringPrice[props.currentPaymentFrequency](yearlyPrice[props.paymentFrequency](discountedPrice));
      // } else if (!calculatePriceMethod && 'paymentFrequency' in props && props.paymentFrequency != '' && props.paymentFrequency == props.currentPaymentFrequency) {
      //   discountedPrice = calculateRecurringPrice[props.currentPaymentFrequency](discountedPrice);
      // }
    }
    let discount = discountedPrice
      ? +total - +discountedPrice
      : +total;
    console.log('spec_djsc_dis', discount, discountedPrice, total);
    if (discount >= 0) {
      jsx.push(
        <tr key={`total-${jsx.length}`} className="head-row">
          <td>Net Total</td>
          <td>{formatCurrency(total)}</td>
        </tr>
      );
    }
    discount!==0 && 
    jsx.push(
      <tr key={`discount-${jsx.length}`} className="head-grey-row">
        <td>Discount</td>
        <td>(-) {formatCurrency(discount)}</td>
      </tr>
    );
    discount!==0 &&
    jsx.push(
      <tr key={`discounted-total-${jsx.length}`} className="head-row">
        <td>{discount >= 0 ? "Discounted Total" : "Net Total"}</td>
        <td>
          {formatCurrency(
            discountedPrice ||
              ("discountPercentage" in props && props.discountPercentage == 100)
              ? discountedPrice
                ? discountedPrice
                : 0
              : total
          )}
        </td>
      </tr>
    );
    // let vat = props.isVatRegistered ? parseFloat((props.discountedPrice ? +props.discountedPrice : +total)*0.2).toFixed(2) : 0;
    let vat =
      window.localStorage.getItem("isVatRegistered") === "true"
        ? parseFloat(
          (discountedPrice >= 0 ? +discountedPrice : +total) * 0.2
        ).toFixed(2)
        : 0;
    if (window.localStorage.getItem("isVatRegistered") === "true") {
      jsx.push(
        <tr key={`vat-${jsx.length}`} className="head-grey-row">
          <td>VAT</td>
          <td>{formatCurrency(vat)}</td>
        </tr>
      );
      jsx.push(
        <tr key={`final-total-${jsx.length}`} className="head-row">
          <td>Grand Total</td>
          <td>
            {formatCurrency(
              (discountedPrice >= 0 ? +discountedPrice : +total) + +vat
            )}
          </td>
        </tr>
      );
    }
  }
  return jsx.length === 0 ? null : (
    <div className="row">
      <div className="col-12">
        <h3>{props.title}</h3>
        <div className="separator mb-3" />
        {props.children}
        {props.children && <div className="mb-3" />}
        <table className="table table-hover a-la-carte-services-review-table mb-4">
          <thead>
            <tr>
              <td>Services</td>
              <td>Fees (£)</td>
            </tr>
          </thead>
          <tbody>{jsx}</tbody>
        </table>
      </div>
    </div>
  );
};

export default ReviewServices;
