import React, { Component } from "react";
import { Modal, ModalBody } from 'reactstrap';
import SimpleReactValidator from 'simple-react-validator';
import SweetAlert from "react-bootstrap-sweetalert";
import { connect } from "react-redux";
import { onInputChange, createAccessKey, setShowCreateAccessKeyModal, setShowCreateNewAccessKeySuccessModal, resetAccessKeyForm } from "../../store/accessKey/actions";

class CreateAccessKeyModal extends Component {
	constructor(props) {
		super(props);
		this.validator = new SimpleReactValidator();
		this.cancel = this.cancel.bind(this);
		this.toggle = this.toggle.bind(this);
		this.validator.hideMessages();
		this.formatAndSubmitData = this.formatAndSubmitData.bind(this);
	}
	cancel() {
		this.props.setShowCreateAccessKeyModal(false);
		this.validator.hideMessages();
	}
	toggle() {
		this.props.resetAccessKeyForm();
		this.props.setShowCreateAccessKeyModal(!this.props.showCreateAccessKeyModal);
		this.validator.hideMessages();
	}
	formatAndSubmitData() {
		if (this.validator.allValid()) {
			let data = {
				name: this.props.accessKeyName
			}
			this.props.createAccessKey(data);
		} else {
			this.validator.showMessages();
			this.forceUpdate();
		}
	}
	render() {
		return (
			<React.Fragment>
				<Modal isOpen={this.props.showCreateAccessKeyModal} toggle={this.toggle}>
					<ModalBody>
						<h4>Create Access Key</h4>
						<div className="separator mb-2" />
						<div className="p-3">
							<div className="row fieldset">
								<div className="col-2 text-right">
								    <label className="fieldset-label required">Name</label>
								</div>
								<div className="col-10">
								    <input value={this.props.accessKeyName || ""} onChange={(e) => this.props.onInputChange("accessKeyName", e.target.value)} className="input-text" type="text" placeholder="Access Key Name" />
								    {this.validator.message('Access Key Name', this.props.accessKeyName, 'required', { className: 'text-danger' })}
								</div>
							</div>
						</div>
						<div className="separator mt-3 mb-3" />
						<div className="row fieldset">
							<div className="col-12 text-right">
								<button onClick={this.cancel} className="btn">Cancel</button>
								<button onClick={this.formatAndSubmitData} className="btn create-item ml-2">Create Access Key</button>
							</div>
						</div>
					</ModalBody>
				</Modal>
				{this.props.showCreateNewAccessKeySuccessModal && <SweetAlert
	                title=""
	                success
	                confirmBtnBsStyle="success"
	                onConfirm={() => { this.props.setShowCreateNewAccessKeySuccessModal(false); this.props.setShowCreateAccessKeyModal(false); }}
	            >
	                <div className="mb-2 success-alert-message">Access Key generated successfully!</div>
	            </SweetAlert>}
			</React.Fragment>
		);
	}
}

const mapStateToProps = state => state.AccessKey;

export default connect(mapStateToProps, { onInputChange, createAccessKey, setShowCreateAccessKeyModal, setShowCreateNewAccessKeySuccessModal, resetAccessKeyForm })(CreateAccessKeyModal);
