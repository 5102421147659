import React, { Component } from "react";
import "./style.css";
import "./style-m.css";
import logoImg from "./assets/outbooks-logo.webp";
import newPasswordImg from "./assets/icon-key.png";
import iconFacebook from "./assets/Icon-Facebook-White.png";
import iconX from "./assets/Icon-X-White.png";
import iconLinkedin from "./assets/Icon-Linkedin-White.png";
import iconYoutube from "./assets/Icon-YouTube-White.png";
import { Row, Col, Card, Alert } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import Loader from "../../components/Loader";
import { withRouter, Link } from "react-router-dom";
import {
  userResetPassword,
  resetForgotPasswordForm,
  validateResetToken,
} from "../../store/actions";
import logoDark from "../../assets/images/outbooks.png";
import SweetAlert from "react-bootstrap-sweetalert";

class ResetPasswordNew extends Component {
  constructor(props) {
    super(props);
    this.state = {
      password: "",
      confirmPassword: "",
      passwordError: "",
      redirectToLogin: false,
      isPasswordValid: false, // Flag to track if password meets criteria
      isLengthValid: false,
      hasLetter: false,
      hasNumber: false,
      hasSpecialChar: false,
      showSuccessAlert: false, // Added state to control the SweetAlert visibility
      redirectToLogin: false,
      passwordCriteria: true,
      isVisible: true, // Define isVisible state variable for mbMenu
    };
  }

  handleValidSubmit = (event, values) => {
    const { password, confirmPassword, isPasswordValid } = this.state;

    // Check if passwords match
    if (password !== confirmPassword) {
      this.setState({
        passwordError: "Password and Confirm Password do not match.",
        isPasswordValid: false,
      });
      return;
    }

    // Check if password meets criteria
    const passwordPattern =
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{10,}$/;
    const isValidPassword = passwordPattern.test(password);

    // if (!isValidPassword) {
    //   this.setState({
    //     passwordError:
    //       "Your password must be a minimum of 10 characters long and include at least one letter, one number, and one special character (@$!%*#?&).",
    //     isPasswordValid: false,
    //   });
    //   return;
    // }
    if (
      password.length >= 8 &&
      /[a-zA-Z]/.test(password) &&
      /\d/.test(password) &&
      /[-@$!%*#?&]/.test(password)
    ) {
      this.props.userResetPassword(
        this.props.match.params.userId,
        password,
        this.props.match.params.token,
        (response) => {
          var isSuccess = response.data.success;
          if (isSuccess === true) {
            this.setState({ showSuccessAlert: true, passwordCriteria: false });
          } else {
            this.setState({ showSuccessAlert: false });
          }
        }
      );
    } else {
      return;
    }

    // this.setState({
    //   resetSuccessMsg: "Password reset successful. Redirecting to login...",
    //   isPasswordValid: true,
    // });

    // setTimeout(() => {
    //   this.setState({ redirectToLogin: true });
    // }, 3000);
  };

  handlePasswordChange = (event) => {
    const password = event.target.value;
    const isLengthValid = password.length >= 8;
    const hasLetter = /[a-zA-Z]/.test(password);
    const hasNumber = /\d/.test(password);
    const hasSpecialChar = /[-@$!%*#?&]/.test(password);

    const isPasswordValid =
      isLengthValid && hasLetter && hasNumber && hasSpecialChar;

    this.setState({
      password,
      passwordError: "",
      isPasswordValid,
      isLengthValid,
      hasLetter,
      hasNumber,
      hasSpecialChar,
    });
  };

  handleConfirmPasswordChange = (event) => {
    this.setState({ confirmPassword: event.target.value, passwordError: "" });
  };

  closeSuccessAlert = () => {
    this.setState({ showSuccessAlert: false }, () => {
      this.setState({ redirectToLogin: true });
    });
  };

  toggleVisibility = () => {
    const divElement = document.getElementById('mbMenu');
    if (divElement) {
      divElement.style.display = this.isVisible ? 'none' : 'block';
      //setIsVisible(!isVisible);
      this.setState(prevState => ({ isVisible: !prevState.isVisible }));
    }
  };

  render() {
    const {
      password,
      confirmPassword,
      passwordError,
      redirectToLogin,
      isPasswordValid,
      isLengthValid,
      hasLetter,
      hasNumber,
      hasSpecialChar,
      passwordCriteria,
    } = this.state;
    const isPasswordMatched = password === confirmPassword;

    if (redirectToLogin) {
      return <Redirect to="/signIn" />;
    }

    return (
      <React.Fragment>
        <div>
        <header className="lnheader">
            <div className="lnnav-bar">
              <div className="lnlogo">
                <a className="lna" href="https://outbooks.com/proposal/">
                  <img src={logoImg} alt="Outbooks" />
                </a>
                <div className="mbMenuCont">
                  <button className="mbTgl" onClick={this.toggleVisibility}>&#9776;</button>

                  <div id="mbMenu" className="lnmenu mb-lnmenu" style={{ display: this.state.isVisible ? 'none' : 'block' }}>
                    <ul className="lnul">
                      <li>
                        <a className="lna" href="https://outbooks.com/proposal/">HOME</a>
                      </li>
                      <li className="tosb-nav">
                        <a className="lna" href="https://outbooks.com/proposal/features/">FEATURES &#x25BE;</a>
                        <ul class="sb-nav">
                          <li><a className="lna" href="https://outbooks.com/proposal/engagement-letter/">Engagement Letter</a></li>
                          <li><a className="lna" href="https://outbooks.com/proposal/professional-proposal-renewals/">Professional Proposal & Renewal</a></li>
                          <li><a className="lna" href="https://outbooks.com/proposal/consistent-pricing/">Consistent Pricing</a></li>
                          <li><a className="lna" href="https://outbooks.com/proposal/client-payment/">Client Payment</a></li>
                        </ul>
                      </li>
                      <li>
                        <a className="lna" href="https://outbooks.com/proposal/contact-us/">CONTACT US</a>
                      </li>
                      <li>
                        <a className="lna" href="/signIn">Login</a>
                      </li>
                      <li>
                        <a className="lna" href="/signup">Signup</a>
                      </li>
                      <li>
                        <a className="lna" href="https://calendly.com/amit-outbooks/outbooksproposal" target="_Blank">Book a Free Demo</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              
              <div className="lnmenu lp-lnmenu">
                <ul className="lnul">
                  <li>
                    <a className="lna" href="https://outbooks.com/proposal/">HOME</a>
                  </li>
                  <li>
                    <a className="lna" href="https://outbooks.com/proposal/features/">FEATURES &#x25BE;</a>
                    <ul class="sb-nav">
                      <li><a className="lna" href="https://outbooks.com/proposal/engagement-letter/">Engagement Letter</a></li>
                      <li><a className="lna" href="https://outbooks.com/proposal/professional-proposal-renewals/">Professional Proposal & Renewal</a></li>
                      <li><a className="lna" href="https://outbooks.com/proposal/consistent-pricing/">Consistent Pricing</a></li>
                      <li><a className="lna" href="https://outbooks.com/proposal/client-payment/">Client Payment</a></li>
                    </ul>
                  </li>
                  <li>
                    <a className="lna" href="https://outbooks.com/proposal/contact-us/">CONTACT US</a>
                  </li>
                </ul>
              </div>
              <div className="lnbtn lp-lnmenu">
                <a href="signup"  class="top3btn t3btn3">SIGNUP</a>
                <a href="signIn"  class="top3btn t3btn2">LOGIN</a>   
                <a href="https://calendly.com/amit-outbooks/outbooksproposal" target="_blank" class="top3btn t3btn1">BOOK A FREE DEMO</a>
              </div>
            </div>
          </header>

          <div className="lnmain-content">
            <section className="lnsection-one">
              {/* {this.props.loading || this.state.showSuccessAlert === true ? (
                <Loader />
              ) : null} */}
              <div className="lnlogin-panel">
                <a className="lna" name="login"></a>
                <div className="lnlogin-right">
                  <table className="lntable" border="0" cellPadding={5}>
                    <tbody>
                      <tr>
                        <td vertical-align="middle" width={300} align="center">
                          <img
                            src={newPasswordImg}
                            alt="Proposl Tool Reset Password"
                          />
                        </td>
                        <td width={25}></td>
                        <td>
                          <div className="sign-ar">
                            <AvForm onValidSubmit={this.handleValidSubmit}>
                              <div className="row-f">
                                <h1 className="lnh1 sign-f darkh">Reset Password</h1>
                              </div>
                              {passwordCriteria && (
                                <div>
                                  <div className="row-f">
                                    <p className="lnp p-text sign-f">
                                      Please enter a new password. Password must
                                      meet the following criteria
                                    </p>
                                  </div>
                                  {/* <div className="row-f marb">
                                    <p className="p-text red sign-f">
                                      Minimum of 8 characters long.
                                      <br />
                                      Include at least one letter.
                                      <br />
                                      Include at least one number.
                                      <br />
                                      Include at least one special
                                      character(-@$!%*#?&)
                                    </p>
                                  </div> */}
                                  <div className="row-f">
                                  <ul className="lnul list-unstyled">
                                    {/* Minimum length criterion */}
                                    <li
                                      className={`text-${
                                        isLengthValid ? "success" : "danger"
                                      }`}
                                    >
                                      Minimum of 8 characters long{" "}
                                      {isLengthValid ? (
                                        <span>&#10004;</span>
                                      ) : (
                                        ""
                                      )}
                                    </li>
                                    {/* Include at least one letter criterion */}
                                    <li
                                      className={`text-${
                                        hasLetter ? "success" : "danger"
                                      }`}
                                    >
                                      Include at least one letter{" "}
                                      {hasLetter ? <span>&#10004;</span> : ""}
                                    </li>
                                    {/* Include at least one number criterion */}
                                    <li
                                      className={`text-${
                                        hasNumber ? "success" : "danger"
                                      }`}
                                    >
                                      Include at least one number{" "}
                                      {hasNumber ? <span>&#10004;</span> : ""}
                                    </li>
                                    {/* Include at least one special character criterion */}
                                    <li
                                      className={`text-${
                                        hasSpecialChar ? "success" : "danger"
                                      }`}
                                    >
                                      Include at least one special character
                                      (-@$!%*#?&){" "}
                                      {hasSpecialChar ? (
                                        <span>&#10004;</span>
                                      ) : (
                                        ""
                                      )}
                                    </li>
                                  </ul>
                                  </div>
                                </div>
                              )}

                              {/* Response message */}
                              {this.props.resetError &&
                              this.props.resetError ? (
                                <Alert
                                  color="danger"
                                  style={{
                                    marginTop: "13px",
                                    border: "none",
                                    backgroundColor: "transparent",
                                    color: "#F9060E",
                                  }}
                                >
                                  {this.props.resetError}
                                </Alert>
                              ) : null}
                              {this.props.resetSuccessMsg ? (
                                <Alert
                                  color="success"
                                  style={{
                                    marginTop: "13px",
                                    border: "none",
                                    backgroundColor: "transparent",
                                    
                                  }}
                                >
                                  {this.props.resetSuccessMsg}
                                </Alert>
                              ) : null}

                              {passwordError && (
                                <Alert
                                  color="danger"
                                  style={{
                                    marginBottom: "10px",
                                    border: "none",
                                    backgroundColor: "transparent",
                                    color: "#F9060E",
                                  }}
                                >
                                  {passwordError}
                                </Alert>
                              )}
                              {/* Response message */}

                              <div className="row-f">
                                <AvField
                                  type="password"
                                  name="new-password-1"
                                  className="lninput lntext sign-f big"
                                  placeholder="New Password*"
                                  value={password}
                                  onChange={this.handlePasswordChange}
                                  validate={{
                                    required: {
                                      value: true,
                                      errorMessage: "This field is required.",
                                    },
                                  }}
                                />
                              </div>
                              <div className="row-f">
                                <AvField
                                  type="password"
                                  name="confirmPassword"
                                  className="lninput lntext sign-f big"
                                  placeholder="Confirm New Password*"
                                  value={confirmPassword}
                                  onChange={this.handleConfirmPasswordChange}
                                  validate={{
                                    required: {
                                      value: true,
                                      errorMessage: "This field is required.",
                                    },
                                  }}
                                />
                              </div>

                              <div className="row-f">
                                <button
                                  type="submit"
                                  className="lnpage-btn lnlogin sign-f"
                                >
                                  SUBMIT
                                </button>
                              </div>
                              <div className="row-f">
                                <div className="tc-div sign-f">
                                  <span className="tc-2 sign-f">
                                    {" "}
                                    &nbsp;Remember It?
                                  </span>{" "}
                                  &nbsp;{" "}
                                  <a className="lna" href="https://proposal.outbooks.com/signin">
                                    <strong>SIGN IN HERE</strong>
                                  </a>
                                </div>
                              </div>
                            </AvForm>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </section>
          </div>

          <footer className="lnfooter">
            <div className="lncol-1">
              <div className="lnlogo">
                <a className="lna" href="https://outbooks.com/proposal/">
                  <img src={logoImg} alt="Outbooks Proposal" />
                </a>
              </div>
              <div className="lnmenu">
                <ul className="lnul lnnav-list">
                  <li>
                    <a className="lna" href="https://outbooks.com/proposal/">HOME</a>
                  </li>
                  <li>
                    <a className="lna" href="https://proposal.outbooks.com/signup">SIGN UP</a>
                  </li>
                  <li>
                    <a className="lna" href="https://outbooks.com/proposal/features/">FEATURES</a>
                  </li>
                  <li>
                    <a className="lna" href="https://outbooks.com/proposal/contact-us/">CONTACT US</a>
                  </li>
                </ul>
              </div>
              <p className="lnp lncopy-right">
                Copyright © Outbooks Proposal {new Date().getFullYear()} | All Rights Reserved
              </p>
            </div>
            <div className="lncol-2"><div className="lnmenu footer-m1">
                <ul className="lnul lnnav-list">
                    <li>
                      <a className="lna" href="https://outbooks.com/proposal/contact-us">Help Center</a>
                    </li>
                    <li>
                      <a className="lna" href="https://outbooks.com/proposal/articles/">Blogs</a>
                    </li>
                    <li>
                      <a className="lna" href="https://outbooks.com/proposal/terms-and-conditions/">Terms and Conditions</a>
                    </li>
                  </ul>
              </div></div>
            <div className="lncol-3">
              <p className="lnp lnadr">
                Suite 18, Winsor & Newton Building,
                <br />
                Whitefriars Avenue, Harrow HA3 5RN
              </p>
              <p className="lnp lnregno">Registration No: 10746177</p>
              <ul className="lnul lncontact-links">
                <li>
                  <a className="lna" href="mailto:info@outbooks.com">
                    &#9993; &nbsp; info@outbooks.com
                  </a>
                </li>
                <li>
                  <a className="lna" href="tel:+443300578597">
                    &#9743; &nbsp; +44 330 057 8597
                  </a>
                </li>
              </ul>
              <ul className="lnul lnsocial-links">
                <li>
                  <a className="lna" href="https://www.facebook.com/profile.php?id=61556567720993">
                    <img src={iconFacebook} alt="Facebook" />
                  </a>
                </li>
                <li>
                  <a className="lna" href="https://www.linkedin.com/company/outbooksproposal/">
                    <img src={iconLinkedin} alt="Linkedin" />
                  </a>
                </li>
                <li>
                  <a className="lna" href="https://twitter.com/outbookproposal">
                    <img src={iconX} alt="Twitter / X" />
                  </a>
                </li>
                <li>
                  <a className="lna" href="https://www.youtube.com/channel/UCCucVxt5QuYrJI6SDCDW7sQ">
                    <img src={iconYoutube} alt="Youtube" />
                  </a>
                </li>
              </ul>
              <a
                href="https://outbooks.com/proposal/contact-us/"
                className="lna lnpage-btn"
              >
                CONTACT US
              </a>
            </div>
          </footer>
        </div>
        {this.state.showSuccessAlert && (
          <SweetAlert
            title=""
            success
            confirmBtnBsStyle="success"
            // onConfirm={this.clearModal}
            onConfirm={this.closeSuccessAlert}
          >
            <div className="mb-2 success-alert-message">
              Success! Your password has been changed successfully.
              {this.state.userEmail}
            </div>
          </SweetAlert>
        )}
      </React.Fragment>
    );
  }
}

const mapStatetoProps = (state) => {
  const { resetError, resetSuccessMsg, loading } = state.ForgetPassword;
  return { resetError, resetSuccessMsg, loading };
};

export default withRouter(
  connect(mapStatetoProps, {
    userResetPassword,
    resetForgotPasswordForm,
    validateResetToken,
  })(ResetPasswordNew)
);
