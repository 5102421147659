import React, { Component } from "react";
import Select from "react-select";
import { getQuoteClient } from "../store/quote/actions";
import {
  reviewContractClient,
  sendContractClient,
  saveContractAsDraftClient,
} from "../store/contract/actions";
import { getTemplatesClient } from "../store/template/actions";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import {
  buildCalculatePricingJson,
  buildGlobalPricingDriversJson,
  formatCurrency,
} from "../Utils";
import ReviewServices from "../components/ReviewServices";
import Loader from "../components/Loader";

class GenerateContractClientPackaged extends Component {
  constructor(props) {
    super(props);
    this.state = {
      getOtherData: true,
      signEasyUrl: "",
      loading: true,
      selectedPackage: null,
      templateLoaded: false,
      contractSent: false,
    };
    this.sendContract = this.sendContract.bind(this);
    this.getSignatoriesData = this.getSignatoriesData.bind(this);
    this.draftContract = this.draftContract.bind(this);
    this.selectedReviewContract = this.selectedReviewContract.bind(this);
  }
  componentDidMount() {
    this.props.getQuoteClient(atob(this.props.match.params.id));
  }
  getSignatoriesData() {
    let signatoriesData = [];
    // for (let signatory of this.props.currentOrganisation.signatories) {
    // signatoriesData.push({
    //   firstName: this.props.quoteData.client.contact.first_name,
    //   lastName: this.props.quoteData.client.contact.last_name,
    //   email: this.props.quoteData.client.contact.email,
    //   signaturePosition: "right",
    // });
    if (this.props.quoteData.client.business_type == 'llp' || this.props.quoteData.client.business_type.toLowerCase() == 'ltd') {
      const officers = this.props.quoteData.client.officers.filter(x => x.is_authorised_signatory == true);
      if (officers.length > 0) {
        officers.map((officer) => {
          signatoriesData.push({
            firstName: officer.first_name,
            lastName: officer.last_name,
            email: officer.email,
            signaturePosition: "right",
          });
        })
      } else {
        signatoriesData.push({
          firstName: this.props.quoteData.client.officers[0].first_name,
          lastName: this.props.quoteData.client.officers[0].last_name,
          email: this.props.quoteData.client.officers[0].email,
          signaturePosition: "right",
        });
      }
    } else if (this.props.quoteData.client.business_type == 'partnership') {
      const officers = this.props.quoteData.client.officers.filter(x => x.is_authorised_signatory == true);
      if (officers.length > 0) {
        officers.map((officer) => {
          signatoriesData.push({
            firstName: officer.first_name,
            lastName: officer.last_name,
            email: officer.email,
            signaturePosition: "right",
          });
        })
      } else {
        signatoriesData.push({
          firstName: this.props.quoteData.client.officers[0].first_name,
          lastName: this.props.quoteData.client.officers[0].last_name,
          email: this.props.quoteData.client.officers[0].email,
          signaturePosition: "right",
        });
      }
    } else {
      signatoriesData.push({
        firstName: this.props.quoteData.client.contact.first_name,
        lastName: this.props.quoteData.client.contact.last_name,
        email: this.props.quoteData.client.contact.email,
        signaturePosition: "right",
      });
    }
    // }
    return signatoriesData;
    // return this.props.quoteData.client.officers.filter;
  }

  componentDidUpdate(prevProps) {
    console.log("updatedProps", this.props);
    if (this.state.loading && prevProps.quoteData != this.props.quoteData && !this.state.templateLoaded) {
      this.props.getTemplatesClient(this.props.quoteData.organisation._id);
      this.setState({
        templateLoaded: true,
      });
    }
    // if (prevProps.templates != this.props.templates) {
    //   this.sendContract();
    // }
    if (prevProps.templates != this.props.templates) {
      this.setState({
        selectedPackage: this.props.match.params.packageId
      }, () => {
        this.selectedReviewContract(this.props.match.params.packageId);
      });
    }
    if (this.props.readyToSendContract && !this.state.contractSent) {
      this.setState({
        contractSent: true,
      });
      this.sendContract();
    }
    // if (this.state.getOtherData) {
    //   this.setState(
    //     {
    //       getOtherData: false,
    //     },
    //     () => {
    //       let reviewPackageData = {
    //         source: "fromQuote",
    //         // paymentFrequency: this.props.paymentFrequency,
    //       };
    //       reviewPackageData.quoteId = atob(this.props.match.params.id);
    //       reviewPackageData.packageRecurringPrice = parseFloat(
    //         this.props.packageRecurringPrice
    //       );
    //       reviewPackageData.quoteType = this.props.selectedQuoteType;
    //       reviewPackageData.services = buildCalculatePricingJson(
    //         this.props?.contractServices,
    //         this.props?.servicesByCategory,
    //         this.props?.contractPricingDrivers,
    //         this.props,
    //         this.props?.paymentFrequency
    //       );
    //       if (
    //         this.props.selectedQuoteType === "packagedPricing" ||
    //         this.props.selectedQuoteType === "customPackagedPricing"
    //       ) {
    //         reviewPackageData.quotePackageId = this.props.contractQuotePackage;
    //         reviewPackageData.quotePackageName = this.props.sentQuotes.filter(
    //           (item) => item._id === this.props.contractQuote
    //         )[0]?.data?.packagedPricingSelectedPackages[
    //           this.props.contractQuotePackage
    //         ].name;
    //       }
    // this.props.reviewContract(reviewPackageData);
    //     }
    //   );
    // }
    if (
      this.props.signEasyUrl &&
      this.state.signEasyUrl != this.props.signEasyUrl
    ) {
      window.location.href = this.props.signEasyUrl;
    }
  }
  sendContract() {
    const totalsOneOff = { [this.state.selectedPackage]: { total: 0 } };
    const totalsRecurring = { [this.state.selectedPackage]: { total: 0 } };
    if (this.props.quoteData.type === "customPackagedPricing") {
      for (let categoryKey in this.props.quoteData.packagedPricingServicesByCategory) {
        for (let serviceKey in this.props.quoteData.packagedPricingServicesByCategory[categoryKey].services) {
          let serviceChargeType = serviceKey.split("_")[1];
          if (serviceChargeType === 'recurring') {
            totalsRecurring[this.state.selectedPackage].total += +(this.props.quoteData.packagedPricingServicesByCategory[
              categoryKey
            ].services[serviceKey].packages.hasOwnProperty(this.state.selectedPackage)
              ? this.props.quoteData.packagedPricingServicesByCategory[
                categoryKey].services[serviceKey].packages[this.state.selectedPackage].servicePrice
              : 0);
          } else {
            totalsOneOff[this.state.selectedPackage].total += +(this.props.quoteData.packagedPricingServicesByCategory[
              categoryKey
            ].services[serviceKey].packages.hasOwnProperty(this.state.selectedPackage)
              ? this.props.quoteData.packagedPricingServicesByCategory[
                categoryKey].services[serviceKey].packages[this.state.selectedPackage].servicePrice
              : 0);
          }
        }
      }
    }
    this.setState({
      loading: true,
    });
    const now = new Date();
    const templateId = this.props?.quoteData.organisation[`${this.props.quoteData.template.client_business_type}_template_id`];
    const data = {
      source: "fromQuote",
      template: templateId ? templateId : this.props.templates.find(
        (x) =>
          x.client_business_type ==
          this.props.quoteData.template.client_business_type &&
          x.type == "contract"
      )._id,
      selectedServices:
        this.props.quoteData?.selectedServices ?? this.props.reviewedPrice,
      creationDate: [now.getFullYear(), now.getMonth(), now.getDate()],
      showFullBreakdown: this.props.quoteData.showFullBreakdown,
      signatories: this.getSignatoriesData(),
      paymentMethod: this.props.quoteData.payment_method,
      tnc: this.props.tnc,
      tncType: this.props.tncType,
    };
    if (this.props.quoteData.type === "customPackagedPricing") {
      const discountPercentage = parseFloat(
        (
          (this.props.quoteData.packagedPricingSelectedPackages[this.state.selectedPackage].recurringOriginalPrice -
            this.props.quoteData.packagedPricingSelectedPackages[this.state.selectedPackage].recurringDefaultPrice) * 100
        ) / this.props.quoteData.packagedPricingSelectedPackages[this.state.selectedPackage].recurringOriginalPrice
      ).toFixed(2);
      const totalPrice = totalsRecurring[this.state.selectedPackage].total;
      const discountAmount = (totalPrice * discountPercentage / 100);
      data.packageRecurringPrice = parseFloat(totalPrice - discountAmount).toFixed(2);
      const discountPercentageOneOff = parseFloat(
        (
          (this.props.quoteData.packagedPricingSelectedPackages[this.state.selectedPackage].oneOffOriginalPrice -
            this.props.quoteData.packagedPricingSelectedPackages[this.state.selectedPackage].oneOffDefaultPrice) * 100
        ) / this.props.quoteData.packagedPricingSelectedPackages[this.state.selectedPackage].oneOffOriginalPrice
      ).toFixed(2);
      const totalPriceOneOff = totalsRecurring[this.state.selectedPackage].total;
      const discountAmountOneOff = (totalPriceOneOff * discountPercentageOneOff / 100);
      data.packageOneOffPrice = parseFloat(totalPriceOneOff - discountAmountOneOff).toFixed(2);
    } else {
      // data.packageRecurringPrice =
      //   this.props.quoteData.packageRecurringPrice ||
      //   this.props.quoteData.final_recurring_total ||
      //   this.props.quoteData.recurringTotal ||
      //   this.props?.recurringTotal;
      // data.packageOneOffPrice =
      //   this.props.quoteData.packageOneOffPrice ||
      //   this.props.quoteData.final_oneOff_total ||
      //   this.props.quoteData.oneOffTotal ||
      //   this.props?.oneOffTotal;
      if (this.props.quoteData.packagedPricingSelectedPackages[this.state.selectedPackage].recurringDefaultPrice > 0) {
        data.packageRecurringPrice = this.props.quoteData.packagedPricingSelectedPackages[this.state.selectedPackage].recurringDefaultPrice;
      } else {
        data.packageRecurringPrice =
          this.props.quoteData.packageRecurringPrice ||
          this.props.quoteData.final_recurring_total ||
          this.props.quoteData.recurringTotal ||
          this.props?.recurringTotal;
      }
      if (this.props.quoteData.packagedPricingSelectedPackages[this.state.selectedPackage].oneOffDefaultPrice > 0) {
        data.packageOneOffPrice = this.props.quoteData.packagedPricingSelectedPackages[this.state.selectedPackage].oneOffDefaultPrice;
      } else {
        data.packageOneOffPrice =
          this.props.quoteData.packageOneOffPrice ||
          this.props.quoteData.final_oneOff_total ||
          this.props.quoteData.oneOffTotal ||
          this.props?.oneOffTotal;
      }
    }

    if (this.props?.tncType === "pdf") {
      data["template_url"] = this.props.template_url;
    }

    data.paymentFrequency =
      this.props.quoteData.paymentFrequency &&
        this.props.quoteData.paymentFrequency.trim().length > 0
        ? this.props.quoteData.paymentFrequency
        : "yearly";
    data.quoteId = atob(this.props.match.params.id);
    data.quoteType = this.props.quoteData.type;
    let globalDrivers = [];
    for (let key in this.props.quoteData.globalPricingDrivers) {
      globalDrivers.push({
        ...this.props.quoteData.globalPricingDrivers[key],
        _id: key,
      });
    }
    data.globalPricingDrivers = buildGlobalPricingDriversJson(
      globalDrivers,
      this.props.quoteData
    );
    if (
      this.props.quoteData.type === "packagedPricing" ||
      this.props.quoteData.type === "customPackagedPricing"
    ) {
      data.quotePackageId = this.state.selectedPackage;
      data.quotePackageName =
        this.props.quoteData?.packagedPricingSelectedPackages[
          this.state.selectedPackage
        ].name;
    }
    data.isNew = true;
    data.claims = {
      org: {
        id: this.props.quoteData.organisation._id,
      },
      userId: this.props.quoteData.user._id,
    };
    this.props.sendContractClient(data);
  }
  draftContract() {
    this.setState({
      loading: true,
    });
    const now = new Date();
    const data = {
      source: "fromQuote",
      template: this.props.templates.find(
        (x) =>
          x.client_business_type ==
          this.props.quoteData.template.client_business_type &&
          x.type == "contract"
      )._id,
      selectedServices:
        this.props.quoteData?.selectedServices ?? this.props.reviewedPrice,
      creationDate: [now.getFullYear(), now.getMonth(), now.getDate()],
      showFullBreakdown: this.props.quoteData.showFullBreakdown,
      packageRecurringPrice:
        this.props.quoteData.packageRecurringPrice ||
        this.props.quoteData.final_recurring_total ||
        this.props.quoteData.recurringTotal,
      packageOneOffPrice:
        this.props.quoteData.packageOneOffPrice ||
        this.props.quoteData.final_oneOff_total ||
        this.props.quoteData.oneOffTotal,
      signatories: this.getSignatoriesData(),
      paymentMethod: this.props.quoteData.payment_method,
      tnc: this.props.tnc,
      tncType: this.props.tncType,
    };

    if (this.props?.tncType === "pdf") {
      data["template_url"] = this.props.template_url;
    }

    data.paymentFrequency =
      this.props.quoteData.paymentFrequency &&
        this.props.quoteData.paymentFrequency.trim().length > 0
        ? this.props.quoteData.paymentFrequency
        : "yearly";
    data.quoteId = atob(this.props.match.params.id);
    data.quoteType = this.props.quoteData.type;
    let globalDrivers = [];
    for (let key in this.props.quoteData.globalPricingDrivers) {
      globalDrivers.push({
        ...this.props.quoteData.globalPricingDrivers[key],
        _id: key,
      });
    }
    data.globalPricingDrivers = buildGlobalPricingDriversJson(
      globalDrivers,
      this.props.quoteData
    );
    // if (
    //   this.props.quoteData.type === "packagedPricing" ||
    //   this.props.quoteData.type === "customPackagedPricing"
    // ) {
    //   data.quotePackageId = this.props.contractQuotePackage;
    //   data.quotePackageName = this.props.sentQuotes.filter(
    //     (item) => item._id === this.props.contractQuote
    //   )[0]?.data?.packagedPricingSelectedPackages[
    //     this.props.contractQuotePackage
    //   ].name;
    // }
    data.isNew = true;
    data.claims = {
      org: {
        id: this.props.quoteData.organisation._id,
      },
      userId: this.props.quoteData.user._id,
    };
    this.props.saveContractAsDraftClient(data);
    this.setState({
      loading: false,
    });
    window.location.href = "/";
  }
  selectedReviewContract(selectedPackage) {
    let data = {
      source: "fromQuote",
      // paymentFrequency: this.props.paymentFrequency,
    };

    data.quoteId = atob(this.props.match.params.id);
    data.packageRecurringPrice = null;
    data.quoteType = this.props.quoteData.type;
    data.services = buildCalculatePricingJson(
      this.props.quoteData?.contractServices ?? [],
      this.props.quoteData?.servicesByCategory ?? [],
      this.props.quoteData?.contractPricingDrivers ?? [],
      this.props.quoteData,
      this.props.quoteData?.paymentFrequency
    );
    if (
      this.props.quoteData.type === "packagedPricing" ||
      this.props.quoteData.type === "customPackagedPricing"
    ) {
      data.quoteType = this.props.quoteData.type;
      data.quotePackageId = selectedPackage;
      data.quotePackageName =
        this.props.quoteData?.packagedPricingSelectedPackages[
          selectedPackage
        ].name;
    }
    data.claims = {
      org: {
        id: this.props.quoteData.organisation._id,
      },
      userId: this.props.quoteData.user._id,
    };
    // console.log(data, this.state.selectedPackage);
    this.props.reviewContractClient(data);
  }
  render() {
    console.log("spec_check_data", this.props);
    const quotePackageOptions =
      this.props?.quoteData?.type &&
        (this.props?.quoteData?.type === "packagedPricing" ||
          this.props?.quoteData?.type === "customPackagedPricing")
        ? Object.entries(
          this.props?.quoteData?.packagedPricingSelectedPackages
        ).map((item) => ({ value: item[0], label: item[1].name }))
        : [];
    return this.state.loading ? (
      <Loader />
    ) : (
      <div style={{ padding: "30px" }}>
        {this.props.quoteData.type === "packagedPricing" ||
          this.props.quoteData.type === "customPackagedPricing" ? (
          <div className="row fieldset">
            <div className="col-4 text-right">
              <label className="fieldset-label required">Select Package</label>
            </div>
            <div className="col-8">
              <Select
                options={quotePackageOptions}
                onChange={(selectedOption) => {
                  this.setState({ selectedPackage: selectedOption.value });
                  this.selectedReviewContract(selectedOption.value);
                }}
                value={
                  this.state.selectedPackage
                    ? quotePackageOptions.filter(
                      (option) => option.value === this.state.selectedPackage
                    )
                    : ""
                }
              />
            </div>
          </div>
        ) : null}
        {this.props.quoteData?.packageRecurringPrice ||
          this.props?.recurringTotal ? (
          <ReviewServices
            isVatRegistered={this.props.quoteData.vatRegistered}
            flag={false}
            chargeType="recurring"
            title="Recurring Services"
            calculatedPackagePrice={
              this.props.quoteData?.selectedServices ?? this.props.reviewedPrice
            }
            discountedPrice={
              this.props.quoteData?.packageRecurringPrice ??
              this.props.recurringTotal
            }
          ></ReviewServices>
        ) : null}
        {this.props.quoteData?.packageOneOffPrice || this.props?.oneOffTotal ? (
          <ReviewServices
            isVatRegistered={this.props.quoteData.vatRegistered}
            flag={false}
            chargeType="oneoff"
            title="One Off Services"
            calculatedPackagePrice={
              this.props.quoteData?.selectedServices ??
              this.props?.reviewedPrice
            }
            discountedPrice={
              this.props.quoteData?.packageOneOffPrice ??
              this.props?.oneOffTotal
            }
          ></ReviewServices>
        ) : null}
        {((this.props.quoteData.type == "packagedPricing" ||
          this.props.quoteData.type == "customPackagedPricing") &&
          this.state.selectedPackage) ||
          (this.props.quoteData.type != "packagedPricing" &&
            this.props.quoteData.type != "customPackagedPricing") ? (
          <>
            <div>
              If you are happy with this proposal please click Yes to accept the
              proposal and sign the Letter of Engagement.
            </div>
            <button
              onClick={this.sendContract}
              className="btn btn-success create-item"
            >
              Yes
            </button>
            <button onClick={this.draftContract} className="btn">
              No
            </button>
          </>
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { currentOrganisation } = state.Organisation;
  const { servicesByCategory, requiredGlobalPricingDrivers } = state.Service;
  const serviceLoading = state.Service.loading;
  const packageLoading = state.Package.loading;
  const packagePagination = state.Package.pagination;
  const clientLoading = state.Client.loading;
  const templateLoading = state.Template.loading;
  const { clients } = state.Client;
  const { templates } = state.Template;
  const { packages } = state.Package;
  return {
    currentOrganisation,
    servicesByCategory,
    requiredGlobalPricingDrivers,
    packageLoading,
    clientLoading,
    serviceLoading,
    templateLoading,
    clients,
    templates,
    packages,
    packagePagination,
    ...state.Quote,
    ...state.Contract,
  };
};

export default withRouter(
  connect(mapStateToProps, {
    getQuoteClient,
    getTemplatesClient,
    reviewContractClient,
    sendContractClient,
    saveContractAsDraftClient,
  })(GenerateContractClientPackaged)
);
