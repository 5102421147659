import {
  ADD_OFFICER,
  ADD_PARTNER,
  COMPANY_SEARCH_FAILED,
  COMPANY_SEARCH_SUCCESS,
  CONFIRM_PAYMEMT,
  CREATE_ORGANISATION,
  DELETE_OFFICER,
  DELETE_PARTNER,
  GET_MY_ORGANISATION,
  GET_MY_ORGANISATION_SUCCESS,
  GET_SUPER_ADMIN_ORGANISATIONS,
  GET_SUPER_ADMIN_ORGANISATIONS_SUCCESS,
  ON_INPUT_CHANGE,
  RESET_ORGANISATION_FORM,
  SELECT_COMPANY_SEARCH_ITEM,
  SELECT_COMPANY_SEARCH_ITEM_FAILED,
  SELECT_COMPANY_SEARCH_ITEM_SUCCESS,
  SET_ACTIVE_STEP,
  SET_CURRENT_ORGANISATION,
  SET_ORGANISATION_FORM_FROM_CLIENT_DATA,
  SET_ORGANISATION_LOADING,
  SET_SHOW_IMAGE_UPLOAD_MODAL,
  SET_SHOW_UPDATE_ORGANISATION_SUCCESS_MODAL,
  SET_SHOW_UPDATE_PAYMENT_METHOD_SUCCESS_MODAL,
  UPDATE_MY_ORGANISATION,
  UPDATE_MY_ORGANISATION_SUCCESS,
  UPDATE_PAYMENT_METHOD,
  UPDATE_PAYMENT_METHOD_SUCCESS,
  UPLOAD_LOGO,
  UPLOAD_LOGO_SUCCESS,
  UPLOAD_SIGNATURE,
  UPLOAD_SIGNATURE_SUCCESS,
  GET_PRICING_SETTINGS,
  GET_PRICING_SETTINGS_SUCCESS,
  UPDATE_PRICING_SETTINGS,
  UPDATE_PRICING_SETTINGS_SUCCESS,
  GET_SIGNATURE_BLOCKS,
  GET_SIGNATURE_BLOCKS_SUCCESS,
  GET_TNC_TEMPLATES,
  GET_TNC_TEMPLATES_SUCCESS,
  DELETE_SIGNATORY,
  ADD_SIGNATORY,
  SET_SIGNATORIES,
  GET_EMAIL_CONFIGS,
  GET_EMAIL_CONFIGS_SUCCESS,
  UPDATE_EMAIL_CONFIGS,
  UPDATE_EMAIL_CONFIGS_SUCCESS,
} from "./actionTypes";

const initialState = {
  currentOrganisation: {},
  loading: false,
  pricingSettingsLoading: false,
  activeStep: 1,
  isVisible:"",
  tradingName: "",
  tradingStartDate: null,
  companySearchItems: [],
  partnerCodes: [],
  officerCodes: [],
  website: "",
  vatRegistered: null,
  vatNumber: "",
  businessTagline: "",
  affiliatedAccountingBodyName: "",
  websiteOfAccountingBody: "",
  businessType: "soleTrader",
  brandColor: "#00afef",
  showImageUploadModal: false,
  showUpdateOrganisationSuccessModal: false,
  showUpdatePaymentMethodSuccessModal: false,
  organisations: [],
  pagination: {},
  currentSubscription: null,
  interval: "month",
  subscriptionPackage: null,
  minPricing: 0,
  minPricingMonth: 0,
  maxDiscount: 0,
  contract_email_method: "PDF",
  payment_method: "",
  tncOrg: "",
  tncTemplateOrg: null,
  signatories: [],
  paymentMethod: "",
  e_sign_per_month: 0,
  left_signed_contracts: 0,
  emailConfigsLoading: false,
  quote_cc: "",
  quote_bcc: "",
  contract_cc: "",
  contract_bcc: "",
  individual_template_id: '',
  soleTrader_template_id: '',
  partnership_template_id: '',
  llp_template_id: '',
  ltd_template_id: '',
};

const organisation = (state = initialState, action) => {
  let newState;
  switch (action.type) {
    case SET_ORGANISATION_LOADING:
      return { ...state, loading: action.payload };
    case ON_INPUT_CHANGE:
      return { ...state, [action.payload.field]: action.payload.value };
    case SET_ACTIVE_STEP:
      return { ...state, activeStep: action.payload };
    case ADD_PARTNER:
      return {
        ...state,
        partnerCodes: [...state.partnerCodes, action.payload],
        [`partner${action.payload}IsNew`]: true,
      };
    case DELETE_PARTNER:
      delete state[`partner${action.payload}FirstName`];
      delete state[`partner${action.payload}LastName`];
      delete state[`partner${action.payload}Phone`];
      delete state[`partner${action.payload}DialCode`];
      delete state[`partner${action.payload}Email`];
      delete state[`partner${action.payload}Address`];
      delete state[`partner${action.payload}AddressLine1`];
      delete state[`partner${action.payload}AddressLine2`];
      delete state[`partner${action.payload}Locality`];
      delete state[`partner${action.payload}Premises`];
      delete state[`partner${action.payload}Region`];
      delete state[`partner${action.payload}City`];
      delete state[`partner${action.payload}State`];
      delete state[`partner${action.payload}Country`];
      delete state[`partner${action.payload}Postcode`];
      return {
        ...state,
        partnerCodes: state.partnerCodes.filter(
          (item) => item !== action.payload
        ),
      };
    case ADD_OFFICER:
      return {
        ...state,
        officerCodes: [...state.officerCodes, action.payload],
        [`officer${action.payload}IsNew`]: true,
      };
    case DELETE_OFFICER:
      delete state[`officer${action.payload}FirstName`];
      delete state[`officer${action.payload}LastName`];
      delete state[`officer${action.payload}Role`];
      delete state[`officer${action.payload}AppointedOn`];
      delete state[`officer${action.payload}Nationality`];
      delete state[`officer${action.payload}CountryOfResidence`];
      delete state[`officer${action.payload}Phone`];
      delete state[`officer${action.payload}DialCode`];
      delete state[`officer${action.payload}Email`];
      delete state[`officer${action.payload}Address`];
      delete state[`officer${action.payload}AddressLine1`];
      delete state[`officer${action.payload}AddressLine2`];
      delete state[`officer${action.payload}Locality`];
      delete state[`officer${action.payload}Premises`];
      delete state[`officer${action.payload}Region`];
      delete state[`officer${action.payload}City`];
      delete state[`officer${action.payload}State`];
      delete state[`officer${action.payload}Country`];
      delete state[`officer${action.payload}Postcode`];
      return {
        ...state,
        officerCodes: state.officerCodes.filter(
          (item) => item !== action.payload
        ),
      };
    // case COMPANY_SEARCH:
    // 	return { ...state, companyName: action.payload }
    case COMPANY_SEARCH_SUCCESS:
      return { ...state, companySearchItems: action.payload };
    case COMPANY_SEARCH_FAILED:
      return { ...state, companySearchItems: [] };
    case SELECT_COMPANY_SEARCH_ITEM:
      return {
        ...state,
        loading: true,
        companyName: action.payload.title,
        companyNumber: action.payload.companyNumber,
      };
    case SELECT_COMPANY_SEARCH_ITEM_SUCCESS:
      newState = {
        ...state,
        loading: false,
        companyName: action.payload.companyName,
        companyNumber: action.payload.companyNumber,
        companyIncorporationDate: action.payload.incorporationDate,
        companyStatus: action.payload.companyStatus,
        companyType: action.payload.companyType,
        companySicCodes: action.payload.sicCodes,
        companyPremises: action.payload.registeredOfficeAddress.premises,
        companyAddressLine1:
          action.payload.registeredOfficeAddress.address_line_1,
        companyAddressLine2:
          action.payload.registeredOfficeAddress.address_line_2,
        companyLocality: action.payload.registeredOfficeAddress.locality,
        companyRegion: action.payload.registeredOfficeAddress.region,
        companyCountry: action.payload.registeredOfficeAddress.country,
        companyPostcode: action.payload.registeredOfficeAddress.postal_code,
        tradingName: action.payload.companyName,
        tradingStartDate: new Date(action.payload.incorporationDate),
        tradingPremises: action.payload.registeredOfficeAddress.premises,
        tradingAddressLine1:
          action.payload.registeredOfficeAddress.address_line_1,
        tradingAddressLine2:
          action.payload.registeredOfficeAddress.address_line_2,
        tradingLocality: action.payload.registeredOfficeAddress.locality,
        tradingRegion: action.payload.registeredOfficeAddress.region,
        tradingCountry: action.payload.registeredOfficeAddress.country,
        tradingPostcode: action.payload.registeredOfficeAddress.postal_code,
      };
      const officerCodes = [];
      action.payload.officers.forEach((officer) => {
        newState[`officer${officer.linkId}${officer.role}FirstName`] =
          officer.firstName;
        newState[`officer${officer.linkId}${officer.role}LastName`] =
          officer.lastName;
        newState[`officer${officer.linkId}${officer.role}Role`] = officer.role;
        newState[`officer${officer.linkId}${officer.role}AppointedOn`] =
          officer.appointedOn;
        newState[`officer${officer.linkId}${officer.role}Nationality`] =
          officer.nationality;
        newState[`officer${officer.linkId}${officer.role}CountryOfResidence`] =
          officer.countryOfResidence;
        newState[`officer${officer.linkId}${officer.role}Phone`] = null;
        newState[`officer${officer.linkId}${officer.role}DialCode`] = null;
        newState[`officer${officer.linkId}${officer.role}Email`] = null;
        newState[`officer${officer.linkId}${officer.role}Premises`] =
          officer.address.premises;
        newState[`officer${officer.linkId}${officer.role}AddressLine1`] =
          officer.address.address_line_1;
        newState[`officer${officer.linkId}${officer.role}AddressLine2`] =
          officer.address.address_line_2;
        newState[`officer${officer.linkId}${officer.role}Locality`] =
          officer.address.locality;
        newState[`officer${officer.linkId}${officer.role}Region`] =
          officer.address.region;
        newState[`officer${officer.linkId}${officer.role}Country`] =
          officer.address.country;
        newState[`officer${officer.linkId}${officer.role}Postcode`] =
          officer.address.postal_code;
        officerCodes.push(`${officer.linkId}${officer.role}`);
      });
      newState["officerCodes"] = officerCodes;
      return newState;
    case SELECT_COMPANY_SEARCH_ITEM_FAILED:
      delete state.companyName;
      delete state.companyNumber;
      delete state.companyIncorporationDate;
      delete state.companyStatus;
      delete state.companyType;
      delete state.companySicCodes;
      return { ...state };
    case CREATE_ORGANISATION:
      return { ...state, loading: true };
    case SET_ORGANISATION_FORM_FROM_CLIENT_DATA:
      newState = { ...state };
      newState.website = action.payload.website;
      switch (action.payload.business_type) {
        case "individual":
          if (action.payload.officers && action.payload.officers.length > 0) {
            newState.individualFirstName =
              action.payload.officers[0].first_name;
            newState.individualLastName = action.payload.officers[0].last_name;
            newState.individualEmail = action.payload.officers[0].email;
            if (
              action.payload.officers[0].phone &&
              action.payload.officers[0].phone.number &&
              action.payload.officers[0].phone.code
            ) {
              newState.individualPhone =
                action.payload.officers[0].phone.number;
              newState.individualDialCode =
                action.payload.officers[0].phone.code;
            }
            newState.individualPremises =
              action.payload.officers[0].address.premises;
            newState.individualAddressLine1 =
              action.payload.officers[0].address.address_line_1;
            newState.individualAddressLine2 =
              action.payload.officers[0].address.address_line_2;
            newState.individualLocality =
              action.payload.officers[0].address.locality;
            newState.individualRegion =
              action.payload.officers[0].address.region;
            newState.individualPostcode =
              action.payload.officers[0].address.postcode;
            newState.individualCountry =
              action.payload.officers[0].address.country;
          }
          break;
        case "soleTrader":
          newState.contactFirstName = action.payload.contact.first_name;
          newState.contactLastName = action.payload.contact.last_name;
          newState.contactEmail = action.payload.contact.email;
          if (
            action.payload.contact.phone &&
            action.payload.contact.phone.number &&
            action.payload.contact.phone.code
          ) {
            newState.contactPhone = action.payload.contact.phone.number;
            newState.contactDialCode = action.payload.contact.phone.code;
          }
          newState.tradingName = action.payload.trading_business_name;
          newState.isVisible = action.payload.isVisible;
          newState.tradingPremises = action.payload.trading_address.premises;
          newState.tradingAddressLine1 =
            action.payload.trading_address.address_line_1;
          newState.tradingAddressLine2 =
            action.payload.trading_address.address_line_2;
          newState.tradingLocality = action.payload.trading_address.locality;
          newState.tradingRegion = action.payload.trading_address.region;
          newState.tradingPostcode = action.payload.trading_address.postcode;
          newState.tradingCountry = action.payload.trading_address.country;
          newState.vatRegistered = action.payload.vatRegistered;
          newState.vatNumber = action.payload.vatNumber;
          if (action.payload.officers && action.payload.officers.length > 0) {
            newState.soleTraderFirstName =
              action.payload.officers[0].first_name;
            newState.soleTraderLastName = action.payload.officers[0].last_name;
            newState.soleTraderEmail = action.payload.officers[0].email;
            if (
              action.payload.officers[0].phone &&
              action.payload.officers[0].phone.number &&
              action.payload.officers[0].phone.code
            ) {
              newState.soleTraderPhone =
                action.payload.officers[0].phone.number;
              newState.soleTraderDialCode =
                action.payload.officers[0].phone.code;
            }
            newState.soleTraderPremises =
              action.payload.officers[0].residential_address.premises;
            newState.soleTraderAddressLine1 =
              action.payload.officers[0].residential_address.address_line_1;
            newState.soleTraderAddressLine2 =
              action.payload.officers[0].residential_address.address_line_2;
            newState.soleTraderLocality =
              action.payload.officers[0].residential_address.locality;
            newState.soleTraderRegion =
              action.payload.officers[0].residential_address.region;
            newState.soleTraderPostcode =
              action.payload.officers[0].residential_address.postcode;
            newState.soleTraderCountry =
              action.payload.officers[0].residential_address.country;
          }
          break;
        case "partnership":
          // newState.contactFirstName = action.payload.contact.first_name;
          // newState.contactLastName = action.payload.contact.last_name;
          // newState.contactEmail = action.payload.contact.email;
          // if (
          //   action.payload.contact.phone &&
          //   action.payload.contact.phone.number &&
          //   action.payload.contact.phone.code
          // ) {
          //   newState.contactPhone = action.payload.contact.phone.number;
          //   newState.contactDialCode = action.payload.contact.phone.code;
          // }
          newState.isVisible = action.payload.isVisible;
          newState.tradingName = action.payload.trading_business_name;
          newState.tradingPremises = action.payload.trading_address.premises;
          newState.tradingAddressLine1 =
            action.payload.trading_address.address_line_1;
          newState.tradingAddressLine2 =
            action.payload.trading_address.address_line_2;
          newState.tradingLocality = action.payload.trading_address.locality;
          newState.tradingRegion = action.payload.trading_address.region;
          newState.tradingPostcode = action.payload.trading_address.postcode;
          newState.tradingCountry = action.payload.trading_address.country;
          newState.vatRegistered = action.payload.vatRegistered;
          newState.vatNumber = action.payload.vatNumber;
          newState.partnerCodes = [];
          if (action.payload.officers && action.payload.officers.length > 0) {
            action.payload.officers.forEach((officer) => {
              newState.partnerCodes.push(officer._id);
              newState[`partner${officer._id}IsNew`] = false;
              newState[`partner${officer._id}IsAuthorisedSignatory`] =
                officer.is_authorised_signatory;
              newState[`partner${officer._id}FirstName`] = officer.first_name;
              newState[`partner${officer._id}LastName`] = officer.last_name;
              newState[`partner${officer._id}Email`] = officer.email;
              if (officer.phone && officer.phone.number && officer.phone.code) {
                newState[`partner${officer._id}Phone`] = officer.phone.number;
                newState[`partner${officer._id}DialCode`] = officer.phone.code;
              }
              if (officer.residential_address) {
                newState[`partner${officer._id}Premises`] =
                  officer.residential_address.premises;
                newState[`partner${officer._id}AddressLine1`] =
                  officer.residential_address.address_line_1;
                newState[`partner${officer._id}AddressLine2`] =
                  officer.residential_address.address_line_2;
                newState[`partner${officer._id}Locality`] =
                  officer.residential_address.locality;
                newState[`partner${officer._id}Region`] =
                  officer.residential_address.region;
                newState[`partner${officer._id}Postcode`] =
                  officer.residential_address.postcode;
                newState[`partner${officer._id}Country`] =
                  officer.residential_address.country;
              }
            });
          }
          break;
        case "llp":
        case "ltd":
          // newState.contactFirstName = action.payload.contact.first_name;
          // newState.contactLastName = action.payload.contact.last_name;
          // newState.contactEmail = action.payload.contact.email;
          // if (
          //   action.payload.contact.phone &&
          //   action.payload.contact.phone.number &&
          //   action.payload.contact.phone.code
          // ) {
          //   newState.contactPhone = action.payload.contact.phone.number;
          //   newState.contactDialCode = action.payload.contact.phone.code;
          // }
          newState.tradingName = action.payload.trading_business_name;
          newState.isVisible = action.payload.isVisible;
          newState.tradingPremises = action.payload.trading_address.premises;
          newState.tradingAddressLine1 =
            action.payload.trading_address.address_line_1;
          newState.tradingAddressLine2 =
            action.payload.trading_address.address_line_2;
          newState.tradingLocality = action.payload.trading_address.locality;
          newState.tradingRegion = action.payload.trading_address.region;
          newState.tradingPostcode = action.payload.trading_address.postcode;
          newState.tradingCountry = action.payload.trading_address.country;
          newState.vatRegistered = action.payload.vatRegistered;
          newState.vatNumber = action.payload.vatNumber;

          newState.companyName = action.payload.company.company_name;
          newState.companyType =
            action.payload.company.company_type ||
            action.payload.company.company_Type;
          newState.companyStatus = action.payload.company.company_status;
          newState.companyNumber = action.payload.company.company_number;
          newState.companyIncorporationDate =
            action.payload.company.incorporation_date;
          newState.companyIncorporatedIn =
            action.payload.company.incorporated_in;

          newState.companyPremises =
            action.payload.company.registered_office_address.premises;
          newState.companyAddressLine1 =
            action.payload.company.registered_office_address.address_line_1;
          newState.companyAddressLine2 =
            action.payload.company.registered_office_address.address_line_2;
          newState.companyLocality =
            action.payload.company.registered_office_address.locality;
          newState.companyRegion =
            action.payload.company.registered_office_address.region;
          newState.companyPostcode =
            action.payload.company.registered_office_address.postcode;
          newState.companyCountry =
            action.payload.company.registered_office_address.country;

          newState.officerCodes = [];
          if (action.payload.officers && action.payload.officers.length > 0) {
            action.payload.officers.forEach((officer) => {
              newState.officerCodes.push(officer._id);
              newState[`officer${officer._id}IsNew`] = false;
              newState[`officer${officer._id}IsAuthorisedSignatory`] =
                officer.is_authorised_signatory;
              newState[`officer${officer._id}FirstName`] = officer.first_name;
              newState[`officer${officer._id}LastName`] = officer.last_name;
              newState[`officer${officer._id}Role`] = officer.officer_role;
              newState[`officer${officer._id}AppointedOn`] =
                officer.appointed_on;
              newState[`officer${officer._id}Email`] = officer.email;
              if (officer.phone && officer.phone.number && officer.phone.code) {
                newState[`officer${officer._id}Phone`] = officer.phone.number;
                newState[`officer${officer._id}DialCode`] = officer.phone.code;
              }
              if (officer.correspondence_address) {
                newState[`officer${officer._id}Premises`] =
                  officer.correspondence_address.premises;
                newState[`officer${officer._id}AddressLine1`] =
                  officer.correspondence_address.address_line_1;
                newState[`officer${officer._id}AddressLine2`] =
                  officer.correspondence_address.address_line_2;
                newState[`officer${officer._id}Locality`] =
                  officer.correspondence_address.locality;
                newState[`officer${officer._id}Region`] =
                  officer.correspondence_address.region;
                newState[`officer${officer._id}Postcode`] =
                  officer.correspondence_address.postcode;
                newState[`officer${officer._id}Country`] =
                  officer.correspondence_address.country;
              }
            });
          }
          break;
        default:
          break;
      }
      return newState;
    case UPDATE_PAYMENT_METHOD:
      return { ...state, loading: true };
    case UPDATE_PAYMENT_METHOD_SUCCESS:
      newState = {
        ...state,
        loading: false,
        showUpdatePaymentMethodSuccessModal: true,
      };
      newState.paymentMethod = action.payload.paymentMethod;
      const payM = action.payload.paymentMethod;
      if (payM) {
        newState.billing_name = payM.billing_details.name;
        newState.billing_email = payM.billing_details.email;

        const addressArr = [
          payM.billing_details.address.line1,
          payM.billing_details.address.line2,
          payM.billing_details.address.city,
          payM.billing_details.address.state,
          payM.billing_details.address.postal_code,
          payM.billing_details.address.country,
        ];
        newState.billing_address = addressArr.filter((c) => c).join(" ");
      }
      return newState;
    case SET_SHOW_UPDATE_PAYMENT_METHOD_SUCCESS_MODAL:
      return { ...state, showUpdatePaymentMethodSuccessModal: false };
    case GET_MY_ORGANISATION:
      return { ...state, loading: true };
    case GET_MY_ORGANISATION_SUCCESS:
      newState = { ...state, loading: false };
      console.log("action.payload - ", action.payload);
      if (!action.payload) return newState;
      newState.website = action.payload.website;
      if (
        action.payload.phone &&
        action.payload.phone.number &&
        action.payload.phone.code
      ) {
        newState.phone = action.payload.phone.number;
        newState.dialCode = action.payload.phone.code;
      }
      newState.email = action.payload.email;
      newState.isVisible = action.payload.isVisible;
      newState.logoUrl = action.payload.logo_url;
      newState.signatureUrl = action.payload.signature_url;
      newState.signatoryName = action.payload.signatory_name;
      newState.brandColor = action.payload.brand_color;
      newState.businessType = action.payload.business_type;

      newState.businessTagline = action.payload.businessTagline;
      newState.affiliatedAccountingBodyName =
        action.payload.affiliatedAccountingBodyName;
      newState.websiteOfAffiliatedAccountingBody =
        action.payload.websiteOfAffiliatedAccountingBody;

      newState.subscriptionPackage = action.payload.package;
      newState.trialPending = action.payload.package?.trial_pending;
      newState.customerId = action.payload.customer_id;
      newState.paymentMethods = action.payload.paymentMethod?.data;
      const _payM = action.payload.paymentMethod?.data[0];
      if (_payM) {
        newState.billing_name = _payM.billing_details.name;
        newState.billing_email = _payM.billing_details.email;

        const addressArr = [
          _payM.billing_details.address.line1,
          _payM.billing_details.address.line2,
          _payM.billing_details.address.city,
          _payM.billing_details.address.state,
          _payM.billing_details.address.postal_code,
          _payM.billing_details.address.country,
        ];
        newState.billing_address = addressArr.filter((c) => c).join(" ");
      }
      newState.customer = action.payload.customer;
      newState.invoices = action.payload.invoices;
      newState.currentSubscription = action.payload.subscription;
      newState.subscription = action.payload.subscription;

      if (action.payload.subscription) {
        newState.allow_prepare_quote =
          action.payload.subscription.metadata.prepare_quote === "true";
        newState.allow_prepare_contract =
          action.payload.subscription.metadata.prepare_contract === "true";
        newState.allow_send_quote =
          action.payload.subscription.metadata.send_quote === "true";
        newState.allow_send_contract =
          action.payload.subscription.metadata.send_contract === "true" && action.payload.subscription.metadata.sign_contract.toString() === "true";
        newState.e_sign_per_month =
          +action.payload.subscription.metadata.e_sign_per_month;
        newState.allow_sign_contract =
          action.payload.subscription.metadata.sign_contract.toString() === "true";
      } else {
        newState.allow_prepare_quote =
          action.payload.package?.plan?.prepare_quote.toString() === "true";
        newState.allow_prepare_contract =
          action.payload.package?.plan?.prepare_contract.toString() === "true";
        newState.allow_send_quote =
          action.payload.package?.plan?.send_quote.toString() === "true";
        newState.allow_send_contract =
          action.payload.package?.plan?.send_contract.toString() === "true" && action.payload.package?.plan?.send_contract.toString() === "true";
        newState.e_sign_per_month =
          +action.payload.package?.plan?.e_sign_per_month;
        newState.allow_sign_contract =
          action.payload.package?.plan?.sign_contract.toString() === "true";
      }
      newState.left_signed_contracts = action.payload.leftSignedContracts;

      newState.tradingName = action.payload.trading_business_name;
      newState.tradingStartDate = new Date(action.payload.trading_start_date);
      newState.tradingPremises = action.payload.trading_address.premises;
      newState.tradingAddressLine1 =
        action.payload.trading_address.address_line_1;
      newState.tradingAddressLine2 =
        action.payload.trading_address.address_line_2;
      newState.tradingLocality = action.payload.trading_address.locality;
      newState.tradingRegion = action.payload.trading_address.region;
      newState.tradingPostcode = action.payload.trading_address.postcode;
      newState.tradingCountry = action.payload.trading_address.country;
      newState.vatRegistered = action.payload.vatRegistered;
      newState.vatNumber = action.payload.vatNumber;
      newState.currency = action.payload.currency;

      switch (action.payload.business_type) {
        case "soleTrader":
          if (action.payload.officers && action.payload.officers.length > 0) {
            newState.soleTraderFirstName =
              action.payload.officers[0].first_name;
            newState.soleTraderLastName = action.payload.officers[0].last_name;
            newState.soleTraderEmail = action.payload.officers[0].email;
            if (
              action.payload.officers[0].phone &&
              action.payload.officers[0].phone.number &&
              action.payload.officers[0].phone.code
            ) {
              newState.soleTraderPhone =
                action.payload.officers[0].phone.number;
              newState.soleTraderDialCode =
                action.payload.officers[0].phone.code;
            }
            newState.soleTraderPremises =
              action.payload.officers[0].residential_address.premises;
            newState.soleTraderAddressLine1 =
              action.payload.officers[0].residential_address.address_line_1;
            newState.soleTraderAddressLine2 =
              action.payload.officers[0].residential_address.address_line_2;
            newState.soleTraderLocality =
              action.payload.officers[0].residential_address.locality;
            newState.soleTraderRegion =
              action.payload.officers[0].residential_address.region;
            newState.soleTraderPostcode =
              action.payload.officers[0].residential_address.postcode;
            newState.soleTraderCountry =
              action.payload.officers[0].residential_address.country;
          }
          break;
        case "partnership":
          newState.partnerCodes = [];
          if (action.payload.officers && action.payload.officers.length > 0) {
            action.payload.officers.forEach((officer) => {
              newState.partnerCodes.push(officer._id);
              newState[`partner${officer._id}IsNew`] = false;
              newState[`partner${officer._id}IsAuthorisedSignatory`] =
                officer.is_authorised_signatory;
              newState[`partner${officer._id}FirstName`] = officer.first_name;
              newState[`partner${officer._id}LastName`] = officer.last_name;
              newState[`partner${officer._id}Email`] = officer.email;
              if (officer.phone && officer.phone.number && officer.phone.code) {
                newState[`partner${officer._id}Phone`] = officer.phone.number;
                newState[`partner${officer._id}DialCode`] = officer.phone.code;
              }
              if (officer.residential_address) {
                newState[`partner${officer._id}Premises`] =
                  officer.residential_address.premises;
                newState[`partner${officer._id}AddressLine1`] =
                  officer.residential_address.address_line_1;
                newState[`partner${officer._id}AddressLine2`] =
                  officer.residential_address.address_line_2;
                newState[`partner${officer._id}Locality`] =
                  officer.residential_address.locality;
                newState[`partner${officer._id}Region`] =
                  officer.residential_address.region;
                newState[`partner${officer._id}Postcode`] =
                  officer.residential_address.postcode;
                newState[`partner${officer._id}Country`] =
                  officer.residential_address.country;
              }
            });
          }
          break;
        case "llp":
        case "ltd":
          newState.companyName = action.payload.company.company_name;
          newState.companyType =
            action.payload.company.company_type ||
            action.payload.company.company_Type;
          newState.companyNumber = action.payload.company.company_number;
          newState.companyIncorporationDate =
            action.payload.company.incorporation_date;
          newState.companyIncorporatedIn =
            action.payload.company.incorporated_in;

          newState.companyPremises =
            action.payload.company.registered_office_address.premises;
          newState.companyAddressLine1 =
            action.payload.company.registered_office_address.address_line_1;
          newState.companyAddressLine2 =
            action.payload.company.registered_office_address.address_line_2;
          newState.companyLocality =
            action.payload.company.registered_office_address.locality;
          newState.companyRegion =
            action.payload.company.registered_office_address.region;
          newState.companyPostcode =
            action.payload.company.registered_office_address.postcode;
          newState.companyCountry =
            action.payload.company.registered_office_address.country;

          newState.officerCodes = [];
          if (action.payload.officers && action.payload.officers.length > 0) {
            action.payload.officers.forEach((officer) => {
              newState.officerCodes.push(officer._id);
              newState[`officer${officer._id}IsNew`] = false;
              newState[`officer${officer._id}IsAuthorisedSignatory`] =
                officer.is_authorised_signatory;
              newState[`officer${officer._id}FirstName`] = officer.first_name;
              newState[`officer${officer._id}LastName`] = officer.last_name;
              newState[`officer${officer._id}Role`] = officer.officer_role;
              newState[`officer${officer._id}AppointedOn`] =
                officer.appointed_on;
              newState[`officer${officer._id}Email`] = officer.email;
              if (officer.phone && officer.phone.number && officer.phone.code) {
                newState[`officer${officer._id}Phone`] = officer.phone.number;
                newState[`officer${officer._id}DialCode`] = officer.phone.code;
              }
              if (officer.correspondence_address) {
                newState[`officer${officer._id}Premises`] =
                  officer.correspondence_address.premises;
                newState[`officer${officer._id}AddressLine1`] =
                  officer.correspondence_address.address_line_1;
                newState[`officer${officer._id}AddressLine2`] =
                  officer.correspondence_address.address_line_2;
                newState[`officer${officer._id}Locality`] =
                  officer.correspondence_address.locality;
                newState[`officer${officer._id}Region`] =
                  officer.correspondence_address.region;
                newState[`officer${officer._id}Postcode`] =
                  officer.correspondence_address.postcode;
                newState[`officer${officer._id}Country`] =
                  officer.correspondence_address.country;
              }
            });
          }
          break;
        default:
          break;
      }
      return newState;
    case UPDATE_MY_ORGANISATION:
      return { ...state, loading: true };
    case UPDATE_MY_ORGANISATION_SUCCESS:
      return {
        ...state,
        loading: false,
        showUpdateOrganisationSuccessModal: true,
      };
    case SET_SHOW_IMAGE_UPLOAD_MODAL:
      return {
        ...state,
        showImageUploadModal: action.payload.isVisible,
        imageUploadModalType: action.payload.type,
      };
    case UPLOAD_LOGO:
      return { ...state, loading: true };
    case UPLOAD_LOGO_SUCCESS:
      return {
        ...state,
        loading: false,
        showImageUploadModal: false,
        logoUrl: action.payload,
      };
    case UPLOAD_SIGNATURE:
      return { ...state, loading: true };
    case UPLOAD_SIGNATURE_SUCCESS:
      return {
        ...state,
        loading: false,
        showImageUploadModal: false,
        signatureUrl: action.payload,
      };
    case SET_SHOW_UPDATE_ORGANISATION_SUCCESS_MODAL:
      return { ...state, showUpdateOrganisationSuccessModal: action.payload };
    case SET_CURRENT_ORGANISATION:
      console.log("currentOrganisation - ", action.payload.currentOrganisation);
      let signatoryBlocks = {};
      let signatorieskeys = [];
      if (action.payload.currentOrganisation?.signatories) {
        const tp = action.payload.currentOrganisation?.signatories?.map(
          (item, index) => {
            signatoryBlocks = {
              ...signatoryBlocks,
              [`signatory${index}FirstName`]: item.firstName,
              [`signatory${index}LastName`]: item.lastName,
              [`signatory${index}Email`]: item.email,
              [`signatory${index}SignaturePosition`]: item.signaturePosition,
            };
            signatorieskeys.push(index);
            return false;
          }
        );
      }
      return {
        ...state,
        currentOrganisation: action.payload.currentOrganisation,
        currentSubscription: action.payload.currentSubscription,
        contract_email_method:
          action.payload.currentOrganisation?.contract_email_method ?? "PDF",
        individual_template_id: action.payload.currentOrganisation.individual_template_id,
        soleTrader_template_id: action.payload.currentOrganisation.soleTrader_template_id,
        partnership_template_id: action.payload.currentOrganisation.partnership_template_id,
        llp_template_id: action.payload.currentOrganisation.llp_template_id,
        ltd_template_id: action.payload.currentOrganisation.ltd_template_id,
        payment_method:
          action.payload.currentOrganisation?.payment_method ?? "",
        tncOrg: action.payload.currentOrganisation?.tncOrg,
        tncTemplateOrg: action.payload.currentOrganisation?.tncTemplateOrg,
        signatories: signatorieskeys,
        ...signatoryBlocks,
      };
    case GET_SUPER_ADMIN_ORGANISATIONS:
      return { ...state, loading: true };
    case CONFIRM_PAYMEMT:
      return {
        ...state,
        loading: false,
        confirmPayment: true,
        clientSecret: action.payload.clientSecret,
        organisationId: action.payload.organisationId,
        isSetup: action.payload.isSetup,
      };
    case GET_SUPER_ADMIN_ORGANISATIONS_SUCCESS:
      console.log(
        "action.payload.organisations = ",
        action.payload.organisations
      );
      return {
        ...state,
        loading: false,
        organisations: action.payload.organisations,
        pagination: {
          count: action.payload.count,
          page_num: action.payload.page_num,
          total_pages: action.payload.total_pages,
        },
      };
    case RESET_ORGANISATION_FORM:
      return {
        ...initialState,
        currentSubscription: state.currentSubscription,
        interval: state.interval,
        subscriptionPackage: state.subscriptionPackage,
        minPricing: state.minPricing,
        minPricingMonth: state.minPricingMonth,
        maxDiscount: state.maxDiscount,
      };
    case UPDATE_PRICING_SETTINGS:
      return { ...state, ...action.payload, loading: true };
    case UPDATE_PRICING_SETTINGS_SUCCESS:
      return {
        ...state,
        loading: false,
        showUpdateOrganisationSuccessModal: true,
      };
    case GET_PRICING_SETTINGS:
      return { ...state, pricingSettingsLoading: true };
    case GET_PRICING_SETTINGS_SUCCESS:
      return { ...state, pricingSettingsLoading: false, ...action.payload };
    case GET_SIGNATURE_BLOCKS:
      return { ...state, loading: true };
    case GET_SIGNATURE_BLOCKS_SUCCESS:
      return { ...state, loading: false, signatureBlocks: action.payload };
    case ADD_SIGNATORY:
      return { ...state, signatories: [...state.signatories, action.payload] };
    case SET_SIGNATORIES:
      newState = { ...state, signatories: [] };
      for (let signatory of action.payload) {
        newState.signatories.push(signatory.id);
        newState[`signatory${signatory.id}FirstName`] = signatory.firstName;
        newState[`signatory${signatory.id}LastName`] = signatory.lastName;
        newState[`signatory${signatory.id}Email`] = signatory.email;
        newState[`signatory${signatory.id}SignaturePosition`] =
          signatory.signaturePosition;
      }
      return newState;
    case GET_TNC_TEMPLATES:
      return { ...state, loading: true };
    case GET_TNC_TEMPLATES_SUCCESS:
      return { ...state, loading: false, tncTemplates: action.payload };
    case DELETE_SIGNATORY:
      return {
        ...state,
        signatories: state.signatories.filter(
          (item, index) => item !== action.payload
        ),
      };
    case UPDATE_EMAIL_CONFIGS:
      return { ...state, ...action.payload, loading: true };
    case UPDATE_EMAIL_CONFIGS_SUCCESS:
      return {
        ...state,
        loading: false,
        showUpdateOrganisationSuccessModal: true,
      };
    case GET_EMAIL_CONFIGS:
      return { ...state, emailConfigsLoading: true };
    case GET_EMAIL_CONFIGS_SUCCESS:
      return { ...state, emailConfigsLoading: false, ...action.payload };
    default:
      return state;
  }
};

export default organisation;
