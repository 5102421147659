import {
  SET_SERVICE_LOADING,
  ON_SERVICE_INPUT_CHANGE,
  SET_SERVICE_ACTIVE_STEP,
  ADD_PRICING_DRIVER,
  DELETE_PRICING_DRIVER,
  ADD_VARIATION_TO_PRICING_DRIVER,
  DELETE_VARIATION_FROM_PRICING_DRIVER,
  ADD_SLAB_TO_PRICING_DRIVER,
  DELETE_SLAB_FROM_PRICING_DRIVER,
  ADD_NUMBER_RANGE_TO_PRICING_DRIVER,
  DELETE_NUMBER_RANGE_FROM_PRICING_DRIVER,
  GET_SERVICE,
  GET_SERVICE_SUCCESS,
  GET_CATEGORIES,
  GET_CATEGORIES_SUCCESS,
  SET_SHOW_CREATE_CATEGORY_MODAL,
  CREATE_NEW_SERVICE,
  SUBMIT_SERVICE,
  CREATE_NEW_SERVICE_SUCCESS,
  SUBMIT_SERVICE_SUCCESS,
  SET_SHOW_CREATE_NEW_SERVICE_SUCCESS_MODAL,
  CREATE_NEW_CATEGORY,
  CREATE_NEW_CATEGORY_SUCCESS,
  UPDATE_CATEGORY,
  UPDATE_CATEGORY_SUCCESS,
  SET_SHOW_CREATE_NEW_CATEGORY_SUCCESS_MODAL,
  GET_SERVICES_BY_CATEGORY,
  GET_SERVICES_BY_CATEGORY_SUCCESS,
  RESET_CREATE_NEW_SERVICE_FORM,
  GET_GLOBAL_PRICING_DRIVERS,
  GET_GLOBAL_PRICING_DRIVERS_SUCCESS,
  GET_GLOBAL_PRICING_DRIVER,
  GET_GLOBAL_PRICING_DRIVER_SUCCESS,
  SET_SHOW_CREATE_GLOBAL_PRICING_DRIVER_MODAL,
  SET_SHOW_CREATE_GLOBAL_PRICING_DRIVER_SUCCESS_MODAL,
  SET_SHOW_UPDATE_SERVICE_CONFIRM_MODAL,
  SUBMIT_GLOBAL_PRICING_DRIVER,
  SUBMIT_GLOBAL_PRICING_DRIVER_SUCCESS,
  GET_GLOBAL_DRIVERS_BY_SERVICES,
  GET_GLOBAL_DRIVERS_BY_SERVICES_SUCCESS,
  RESET_PRICING_DRIVER_FORM,
  SET_SHOW_CREATE_GLOBAL_CONSTANT_MODAL,
  SUBMIT_GLOBAL_CONSTANT,
  SUBMIT_GLOBAL_CONSTANT_SUCCESS,
  ON_SERVICE_DRIVER_INPUT_CHANGE,
  ON_SERVICE_GLOBAL_DRIVER_INPUT_CHANGE,
  CREATE_NEW_CATEGORY_FAIL,
  CREATE_NEW_SERVICE_FAIL,
  SET_SHOW_CREATE_NEW_CATEGORY_FAILED_MODAL,
  SET_SHOW_CREATE_NEW_SERVICE_FAILED_MODAL,
  SUBMIT_GLOBAL_CONSTANT_FAIL,
  SET_SHOW_CREATE_GLOBAL_PRICING_DRIVER_FAIL_MODAL,
  CREATE_NEW_GLOBAL_DRIVER_FAIL,
  SET_SHOW_CREATE_NEW_GLOBAL_DRIVER_FAILED_MODAL,
  SET_SHOW_CHANGE_GLOBAL_PRICING_DRIVER_VALUES_MODAL
} from "./actionTypes";

const initialState = {
  loading: false,
  linkedServices: [],
  showCreateNewCategorySuccessModal: false,
  showCreateNewCategoryFailedModal: false,
  showCreateNewGlobalDriverFailedModal: false,
  showCreateNewServiceFailedModal: false,
  showCreateNewServiceSuccessModal: false,
  showChangeGlobalPricingDriverValuesModal: false,
  activeStep: 1,
  servicePricingType: "fixedPricing",
  driverCodes: [],
  categories: [],
  showCreateCategoryModal: false,
  showCreateGlobalPricingDriverModal: false,
  showCreateGlobalPricingDriverSuccessModal: false,
  showCreateGlobalPricingDriverFailModal: false,
  showUpdateServiceConfirmModal: false,
  createCategoryModalType: "create",
  createCategoryModalEditId: null,
  createGlobalPricingDriverModalType: "edit",
  servicesByCategory: {},
  pagination: {},
  globalDrivers: [],
  requiredGlobalPricingDrivers: [],
  showCreateGlobalConstantModal: false,
  showCreateGlobalConstantSuccessModal: false,
  createGlobalConstantModalType: "edit",
};

const service = (state = initialState, action) => {
  let newState;
  let variationsKey;
  let slabsKey;
  let numberRangesKey;
  switch (action.type) {
    case SET_SERVICE_LOADING:
      return { ...state, loading: action.payload };
    case ON_SERVICE_INPUT_CHANGE:
      return { ...state, [action.payload.field]: action.payload.value };
    case SET_SERVICE_ACTIVE_STEP:
      return { ...state, activeStep: action.payload };
    case ADD_PRICING_DRIVER:
      return {
        ...state,
        driverCodes: [...state.driverCodes, action.payload],
        [`driver${action.payload}Name`]: "",
        [`driver${action.payload}IsNew`]: true,
      };
    case DELETE_PRICING_DRIVER:
      newState = {
        ...state,
        driverCodes: state.driverCodes.filter(
          (item) => item !== action.payload
        ),
      };
      return newState;
    case ADD_VARIATION_TO_PRICING_DRIVER:
      newState = { ...state };
      variationsKey = `driver${action.payload.pricingDriverCode}Variation`;
      if (newState.hasOwnProperty(`${variationsKey}s`)) {
        newState[
          `${variationsKey}${action.payload.variationCode}IsDefault`
        ] = false;
        newState[`${variationsKey}s`] = [
          ...newState[`${variationsKey}s`],
          action.payload.variationCode,
        ];
      } else {
        newState[`${variationsKey}s`] = [];
        newState[
          `${variationsKey}${action.payload.variationCode}IsDefault`
        ] = true;
        newState[`${variationsKey}s`].push(action.payload.variationCode);
      }
      return newState;
    case DELETE_VARIATION_FROM_PRICING_DRIVER:
      newState = { ...state };
      variationsKey = `driver${action.payload.pricingDriverCode}Variations`;
      newState[variationsKey] = newState[variationsKey].filter(
        (item) => item !== action.payload.variationCode
      );
      delete newState[
        `driver${action.payload.pricingDriverCode}Variation${action.payload.variationCode}IsNew`
      ];
      delete newState[
        `driver${action.payload.pricingDriverCode}Variation${action.payload.variationCode}Name`
      ];
      delete newState[
        `driver${action.payload.pricingDriverCode}Variation${action.payload.variationCode}Price`
      ];
      delete newState[
        `driver${action.payload.pricingDriverCode}Variation${action.payload.variationCode}IsDefault`
      ];
      return newState;
    case ADD_SLAB_TO_PRICING_DRIVER:
      newState = { ...state };
      slabsKey = `driver${action.payload.pricingDriverCode}Slab`;
      if (newState.hasOwnProperty(`${slabsKey}s`)) {
        newState[`${slabsKey}${action.payload.slabCode}IsDefault`] = false;
        newState[`${slabsKey}s`] = [
          ...newState[`${slabsKey}s`],
          action.payload.slabCode,
        ];
      } else {
        newState[`${slabsKey}s`] = [];
        newState[`${slabsKey}${action.payload.slabCode}IsDefault`] = true;
        newState[`${slabsKey}s`].push(action.payload.slabCode);
      }
      if (newState[`${slabsKey}s`].length > 1) {
        let prevSlabKey =
          newState[`${slabsKey}s`][newState[`${slabsKey}s`].length - 2];
        newState[
          `driver${action.payload.pricingDriverCode}Slab${action.payload.slabCode}From`
        ] =
          +newState[
          `driver${action.payload.pricingDriverCode}Slab${prevSlabKey}To`
          ] + 1;
      }
      return newState;

    case DELETE_SLAB_FROM_PRICING_DRIVER:
      newState = { ...state };
      slabsKey = `driver${action.payload.pricingDriverCode}Slabs`;
      newState[slabsKey] = newState[slabsKey].filter(
        (item) => item !== action.payload.slabCode
      );
      delete newState[
        `driver${action.payload.pricingDriverCode}Slab${action.payload.slabCode}IsNew`
      ];
      delete newState[
        `driver${action.payload.pricingDriverCode}Slab${action.payload.slabCode}Type`
      ];
      delete newState[
        `driver${action.payload.pricingDriverCode}Slab${action.payload.slabCode}From`
      ];
      delete newState[
        `driver${action.payload.pricingDriverCode}Slab${action.payload.slabCode}To`
      ];
      delete newState[
        `driver${action.payload.pricingDriverCode}Slab${action.payload.slabCode}Incrementer`
      ];
      delete newState[
        `driver${action.payload.pricingDriverCode}Slab${action.payload.slabCode}IsDefault`
      ];
      return newState;

    case ADD_NUMBER_RANGE_TO_PRICING_DRIVER:
      newState = { ...state };
      numberRangesKey = `driver${action.payload.pricingDriverCode}NumberRanges`;
      if (newState.hasOwnProperty(numberRangesKey))
        newState[numberRangesKey] = [
          ...newState[numberRangesKey],
          action.payload.numberRangeCode,
        ];
      else {
        newState[numberRangesKey] = [];
        newState[numberRangesKey].push(action.payload.numberRangeCode);
      }
      return newState;
    case DELETE_NUMBER_RANGE_FROM_PRICING_DRIVER:
      newState = { ...state };
      numberRangesKey = `driver${action.payload.pricingDriverCode}NumberRanges`;
      newState[numberRangesKey] = newState[numberRangesKey].filter(
        (item) => item !== action.payload.numberRangeCode
      );
      delete newState[
        `driver${action.payload.pricingDriverCode}NumberRange${action.payload.numberRangeCode}IsNew`
      ];
      delete newState[
        `driver${action.payload.pricingDriverCode}NumberRange${action.payload.numberRangeCode}From`
      ];
      delete newState[
        `driver${action.payload.pricingDriverCode}NumberRange${action.payload.numberRangeCode}To`
      ];
      delete newState[
        `driver${action.payload.pricingDriverCode}NumberRange${action.payload.numberRangeCode}Price`
      ];
      return newState;
    case GET_SERVICE:
      return { ...state, loading: true };
    case GET_SERVICE_SUCCESS:
      newState = {
        ...state,
        activeStep: 1,
        serviceId: action.payload.service._id,
        serviceName: action.payload.service.name,
        serviceChargeType: action.payload.service.charge_type,
        servicePricingType: action.payload.service.pricing_type,
        serviceDescription: action.payload.service.description,
        serviceCategories: action.payload.categories,
        serviceAffectedPackages: action.payload.affectedPackages,
        loading: false,
        QuoteCount: action.payload.QuoteCount,
        ContractCount: action.payload.ContractCount
      };
      if (action.payload.service.pricing_type === "fixedPricing") {
        newState.fixedPrice = action.payload.service.fixed_price;
      } else if (
        action.payload.service.pricing_type === "formulaBasedPricing"
      ) {
        newState.servicePricingFormula = action.payload.service.pricing_formula;
      }
      newState.driverCodes = [];
      newState.globalServiceDrivers = [];
      newState.globalServiceDriverCodes = [];
      action.payload.pricingDrivers.forEach((item, index) => {
        if (item.driverScopeInService === 'global') {
          newState.globalServiceDriverCodes.push(item._id);
          return newState.globalServiceDrivers.push(item);
        }
        newState.driverCodes.push(item._id);
        newState[`driver${item._id}IsNew`] = false;
        newState[`driver${item._id}Name`] = item.name;
        newState[`driver${item._id}Type`] = item.type;
        newState[`driver${item._id}isDependent`] = item.isDependent || false;
        newState[`driver${item._id}dependancyDriver`] =
          item.dependancyDriver || "";
        newState[`driver${item._id}dependencyVariation`] =
          item.dependencyVariation || "";
        newState[`driver${item._id}Variations`] = [];
        newState[`driver${item._id}Slabs`] = [];
        newState[`driver${item._id}NumberRanges`] = [];
        if (item.type === "Variation") {
          item.variations.forEach((variationItem, variationIndex) => {
            newState[`driver${item._id}Variations`].push(variationItem._id);
            newState[
              `driver${item._id}Variation${variationItem._id}IsNew`
            ] = false;
            newState[`driver${item._id}Variation${variationItem._id}Name`] =
              variationItem.name;
            newState[`driver${item._id}Variation${variationItem._id}Price`] =
              variationItem.price;
            newState[
              `driver${item._id}Variation${variationItem._id}IsDefault`
            ] = variationItem.is_default;
          });
        } else if (item.type === "Slab") {
          item.slabs.forEach((slabItem, slabIndex) => {
            newState[`driver${item._id}Slabs`].push(slabItem._id);
            newState[`driver${item._id}Slab${slabItem._id}IsNew`] = false;
            newState[`driver${item._id}Slab${slabItem._id}Type`] =
              slabItem.type;
            newState[`driver${item._id}Slab${slabItem._id}Price`] =
              slabItem.price;
            newState[`driver${item._id}Slab${slabItem._id}From`] =
              slabItem.from;
            newState[`driver${item._id}Slab${slabItem._id}To`] = slabItem.to;
            newState[`driver${item._id}Slab${slabItem._id}Incrementer`] =
              slabItem.incrementer;
            newState[`driver${item._id}Slab${slabItem._id}IsDefault`] =
              slabItem.is_default;
          });
        } else if (item.type === "Number Range") {
          item.number_ranges.forEach((numberRangeItem, numberRangeIndex) => {
            newState[`driver${item._id}NumberRanges`].push(numberRangeItem._id);
            newState[
              `driver${item._id}NumberRange${numberRangeItem._id}IsNew`
            ] = false;
            newState[`driver${item._id}NumberRange${numberRangeItem._id}From`] =
              numberRangeItem.from;
            newState[`driver${item._id}NumberRange${numberRangeItem._id}To`] =
              numberRangeItem.to;
            newState[
              `driver${item._id}NumberRange${numberRangeItem._id}Price`
            ] = numberRangeItem.price;
          });
        }
      });
      return newState;
    case RESET_CREATE_NEW_SERVICE_FORM:
      return initialState;
    case GET_CATEGORIES:
      return { ...state, loading: true };
    case GET_CATEGORIES_SUCCESS:
      return {
        ...state,
        categories: action.payload.categories,
        pagination: {
          count: action.payload.count,
          page_num: action.payload.page_num,
          total_pages: action.payload.total_pages,
        },
        loading: false,
      };
    case SET_SHOW_CREATE_CATEGORY_MODAL:
      newState = {
        ...state,
        showCreateCategoryModal: action.payload.isVisible,
        createCategoryModalType: action.payload.type,
        createCategoryModalEditId: action.payload.editId,
      };
      if (action.payload.type === "edit") {
        newState["createNewCategoryName"] =
          newState.categories[action.payload.index].name;
        newState["createNewCategoryDescription"] =
          newState.categories[action.payload.index].description;
      } else {
        newState["createNewCategoryName"] = "";
        newState["createNewCategoryDescription"] = "";
      }
      return newState;
    case CREATE_NEW_SERVICE:
    case SET_SHOW_CHANGE_GLOBAL_PRICING_DRIVER_VALUES_MODAL:
      return { ...state, showChangeGlobalPricingDriverValuesModal: action.payload.isVisible, createGlobalPricingDriverModalType: 'edit' }
    case SUBMIT_SERVICE:
      return { ...state, loading: true };
    case CREATE_NEW_SERVICE_SUCCESS:
    case SUBMIT_SERVICE_SUCCESS:
      return {
        ...state,
        loading: false,
        showCreateNewServiceSuccessModal: true,
      };
    case SET_SHOW_CREATE_NEW_SERVICE_SUCCESS_MODAL:
      return { ...state, showCreateNewServiceSuccessModal: action.payload };
    case CREATE_NEW_CATEGORY:
    case UPDATE_CATEGORY:
      return { ...state, loading: true };
    case CREATE_NEW_CATEGORY_FAIL:
      return (newState = {
        ...state,
        loading: false,
        showCreateNewCategoryFailedModal: true,
      });
    case CREATE_NEW_SERVICE_FAIL:
      return (newState = {
        ...state,
        loading: false,
        showCreateNewServiceFailedModal: true,
      });
    case CREATE_NEW_CATEGORY_SUCCESS:
    case UPDATE_CATEGORY_SUCCESS:
      newState = {
        ...state,
        categories: action.payload.categories,
        pagination: {
          count: action.payload.count,
          page_num: action.payload.page_num,
          total_pages: action.payload.total_pages,
        },
        loading: false,
        showCreateNewCategoryFailedModal: false,
        showCreateNewCategorySuccessModal: true,
      };
      if (newState.showCreateCategoryModal) {
        newState.serviceCategories = newState.hasOwnProperty(
          "serviceCategories"
        )
          ? [...newState.serviceCategories, action.payload.categoryId]
          : new Array(action.payload.categoryId);
      }
      return newState;
    case SET_SHOW_CREATE_NEW_CATEGORY_SUCCESS_MODAL:
      return { ...state, showCreateNewCategorySuccessModal: action.payload };
    case SET_SHOW_CREATE_NEW_CATEGORY_FAILED_MODAL:
      return { ...state, showCreateNewCategoryFailedModal: action.payload };
    case SET_SHOW_CREATE_NEW_SERVICE_FAILED_MODAL:
      return { ...state, showCreateNewServiceFailedModal: action.payload };
    case CREATE_NEW_GLOBAL_DRIVER_FAIL:
      return (newState = {
        ...state,
        loading: false,
        showCreateNewGlobalDriverFailedModal: true,
      });
    case SET_SHOW_CREATE_NEW_GLOBAL_DRIVER_FAILED_MODAL:
      return { ...state, showCreateNewGlobalDriverFailedModal: action.payload };
    case GET_SERVICES_BY_CATEGORY:
      return { ...state, loading: true };
    case GET_SERVICES_BY_CATEGORY_SUCCESS:
      return { ...state, servicesByCategory: action.payload, loading: false };
    case GET_GLOBAL_PRICING_DRIVERS:
      return { ...state, loading: true };
    case GET_GLOBAL_PRICING_DRIVERS_SUCCESS:
      return { ...state, loading: false, globalDrivers: action.payload };
    case GET_GLOBAL_PRICING_DRIVER:
      return { ...state, loading: true };
    case GET_GLOBAL_PRICING_DRIVER_SUCCESS:
      newState = { ...state, loading: false };
      newState.driverId = action.payload.driver._id;
      newState.driverName = action.payload.driver.name;
      newState.driverType = action.payload.driver.type;
      newState.isDependent = action.payload.driver.isDependent || false;
      newState.dependancyDriver = action.payload.driver.dependancyDriver || "";
      newState.dependencyVariation =
        action.payload.driver.dependencyVariation || "";
      newState.driverValue = action.payload.driver.value;
      newState.driverVariations = [];
      newState.driverSlabs = [];
      newState.linkedServices = action.payload.linkedServices;
      newState.driverNumberRanges = [];
      if (action.payload.driver.type === "Variation") {
        action.payload.driver.variations.forEach(
          (variationItem, variationIndex) => {
            newState[`driverVariations`].push(variationItem._id);
            newState[`driverVariation${variationItem._id}IsNew`] = false;
            newState[`driverVariation${variationItem._id}Name`] =
              variationItem.name;
            newState[`driverVariation${variationItem._id}Price`] =
              variationItem.price;
            newState[`driverVariation${variationItem._id}IsDefault`] =
              variationItem.is_default;
            newState[`driverVariation${variationItem._id}IsDeCoupled`] =
              variationItem.decoupled;
          }
        );
      } else if (action.payload.driver.type === "Slab") {
        action.payload.driver.slabs.forEach((slabItem, slabIndex) => {
          newState[`driverSlabs`].push(slabItem._id);
          newState[`driverSlab${slabItem._id}IsNew`] = false;
          newState[`driverSlab${slabItem._id}Type`] = slabItem.type;
          newState[`driverSlab${slabItem._id}Price`] = slabItem.price;
          newState[`driverSlab${slabItem._id}From`] = slabItem.from;
          newState[`driverSlab${slabItem._id}To`] = slabItem.to;
          newState[`driverSlab${slabItem._id}Incrementer`] =
            slabItem.incrementer;
          newState[`driverSlab${slabItem._id}IsDeCoupled`] =
            slabItem.decoupled;
          newState[`driverSlab${slabItem._id}IsDefault`] = slabItem.is_default;
        });
      } else if (action.payload.driver.type === "Number Range") {
        action.payload.driver.number_ranges.forEach(
          (numberRangeItem, numberRangeIndex) => {
            newState[`driverNumberRanges`].push(numberRangeItem._id);
            newState[`driverNumberRange${numberRangeItem._id}IsNew`] = false;
            newState[`driverNumberRange${numberRangeItem._id}From`] =
              numberRangeItem.from;
            newState[`driverNumberRange${numberRangeItem._id}To`] =
              numberRangeItem.to;
            newState[`driverNumberRange${numberRangeItem._id}Price`] =
              numberRangeItem.price;
          }
        );
      }
      return newState;
    case SET_SHOW_CREATE_GLOBAL_PRICING_DRIVER_MODAL:
      return {
        ...state,
        showCreateGlobalPricingDriverModal: action.payload.isVisible,
        createGlobalPricingDriverModalType: action.payload.type,
      };
    case SET_SHOW_CREATE_GLOBAL_PRICING_DRIVER_SUCCESS_MODAL:
      return {
        ...state,
        showCreateGlobalPricingDriverSuccessModal: action.payload,
      };
    case SET_SHOW_CREATE_GLOBAL_PRICING_DRIVER_FAIL_MODAL:
      return {
        ...state,
        showCreateGlobalPricingDriverFailModal: false,
      };
    case SET_SHOW_UPDATE_SERVICE_CONFIRM_MODAL:
      return { ...state, showUpdateServiceConfirmModal: action.payload };
    case SUBMIT_GLOBAL_PRICING_DRIVER:
      return { ...state, loading: true };
    case SUBMIT_GLOBAL_PRICING_DRIVER_SUCCESS:
      return {
        ...state,
        loading: false,
        showCreateGlobalPricingDriverSuccessModal: true,
        globalDrivers: action.payload,
      };
    case GET_GLOBAL_DRIVERS_BY_SERVICES:
      console.log('loading - ', true);
      return { ...state, loading: true };
    case GET_GLOBAL_DRIVERS_BY_SERVICES_SUCCESS:
      return {
        ...state,
        loading: false,
        requiredGlobalPricingDrivers: action.payload,
      };
    case RESET_PRICING_DRIVER_FORM:
      newState = { ...state };
      delete newState["driverName"];
      delete newState["driverType"];
      delete newState["driverVariations"];
      delete newState["driverSlabs"];
      delete newState["driverValue"];
      return newState;
    case SET_SHOW_CREATE_GLOBAL_CONSTANT_MODAL:
      return {
        ...state,
        showCreateGlobalConstantModal: action.payload.isVisible,
        createGlobalConstantModalType: action.payload.type,
      };
    case SUBMIT_GLOBAL_CONSTANT:
      return { ...state, loading: true };
    case SUBMIT_GLOBAL_CONSTANT_FAIL:
      return {
        ...state,
        loading: false,
        showCreateGlobalPricingDriverSuccessModal: false,
        showCreateGlobalPricingDriverFailModal: true,
      };
    case SUBMIT_GLOBAL_CONSTANT_SUCCESS:
      return {
        ...state,
        loading: false,
        showCreateGlobalPricingDriverSuccessModal: true,
        globalDrivers: action.payload,
        showCreateGlobalPricingDriverFailModal: false,
      };

    case ON_SERVICE_DRIVER_INPUT_CHANGE:
      newState = { ...state };
      newState[`driver${action.payload.code}${action.payload.value}s`].map(
        (dv) => {
          if (dv === action.payload.id) {
            newState[
              `driver${action.payload.code}${action.payload.value}${dv}IsDefault`
            ] = action.payload.item;
          } else {
            newState[
              `driver${action.payload.code}${action.payload.value}${dv}IsDefault`
            ] = false;
          }
        }
      );
      return newState;

    case ON_SERVICE_GLOBAL_DRIVER_INPUT_CHANGE:
      newState = { ...state };
      newState[`driver${action.payload.code}s`].map((dv) => {
        if (dv === action.payload.id) {
          newState[`driver${action.payload.code}${dv}IsDefault`] =
            action.payload.value;
        } else {
          newState[`driver${action.payload.code}${dv}IsDefault`] = false;
        }
      });
      return newState;
    default:
      return state;
  }
};

export default service;
