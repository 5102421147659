import {
    SET_ROLE_MANAGEMENT_LOADING,
    GET_ROLE_MANAGEMENT_PAGE,
    GET_ROLE_MANAGEMENT_PAGE_SUCCESS,
    GET_ROLE_MANAGEMENT_PAGE_FAIL,
    SET_ACTIVE_ROLE,
    SET_ACTIVE_ROLE_SUCCESS,
    SET_ACTIVE_ROLE_FAIL,
    SET_ROLE_PERMISSION,
    SET_SHOW_CREATE_ROLE_MODAL,
    CREATE_NEW_ROLE,
    CREATE_NEW_ROLE_SUCCESS,
    CREATE_NEW_ROLE_FAIL,
    SAVE_ROLE_CHANGES,
    SAVE_ROLE_CHANGES_SUCCESS,
    SAVE_ROLE_CHANGES_FAIL,
    DELETE_ROLE,
    DELETE_ROLE_SUCCESS,
    DELETE_ROLE_FAIL,
    OPEN_DELETE_ROLE_POPUP,
    CLOSE_DELETE_ROLE_POPUP,
    GET_MY_ORGANISATIONS,
    GET_MY_ORGANISATIONS_SUCCESS,
    GET_MY_ORGANISATIONS_FAIL
} from './actionTypes';

export const setRoleManagementLoading = (isLoading) => {
    return {
        type: SET_ROLE_MANAGEMENT_LOADING,
        payload: isLoading
    }
}

export const getRoleManagementPage = () => {
    return {
        type: GET_ROLE_MANAGEMENT_PAGE
    }
}

export const getRoleManagementPageSuccess = (roles, permissionModuleMap) => {
    return {
        type: GET_ROLE_MANAGEMENT_PAGE_SUCCESS,
        payload: {
        	roles,
            permissionModuleMap
        }
    }
}

export const getRoleManagementPageFail = () => {
    return {
        type: GET_ROLE_MANAGEMENT_PAGE_FAIL
    }
}

export const setActiveRole = (activeRole) => {
	return {
		type: SET_ACTIVE_ROLE,
		payload: activeRole
	}
}

export const setActiveRoleSuccess = (permissions) => {
    return {
        type: SET_ACTIVE_ROLE_SUCCESS,
        payload: {
            permissions
        }
    }
}

export const setActiveRoleFail = () => {
    return {
        type: SET_ACTIVE_ROLE_FAIL
    }
}

export const setRolePermission = (permissionId, permissionState) => {
    return {
        type: SET_ROLE_PERMISSION,
        payload: {
            permissionId,
            permissionState
        }
    }
}

export const setShowCreateRoleModal = (visibility) => {
    return {
        type: SET_SHOW_CREATE_ROLE_MODAL,
        payload: visibility
    }
}

export const createNewRole = (role) => {
    return {
        type: CREATE_NEW_ROLE,
        payload: role
    }
}

export const createNewRoleSuccess = (roles, newRoleSlug) => {
    return {
        type: CREATE_NEW_ROLE_SUCCESS,
        payload: {
            roles,
            newRoleSlug
        }
    }
}

export const createNewRoleFail = () => {
    return {
        type: CREATE_NEW_ROLE_FAIL
    }
}

export const saveRoleChanges = (activeRole, activeRolePermissions) => {
    return {
        type: SAVE_ROLE_CHANGES,
        payload: {
            activeRole,
            activeRolePermissions
        }
    }
}

export const saveRoleChangesSuccess = () => {
    return {
        type: SAVE_ROLE_CHANGES_SUCCESS
    }
}

export const saveRoleChangesFail = () => {
    return {
        type: SAVE_ROLE_CHANGES_FAIL
    }
}

export const deleteRole = (role) => {
    return {
        type: DELETE_ROLE,
        payload: role
    }
}

export const deleteRoleSuccess = (roles) => {
    return {
        type: DELETE_ROLE_SUCCESS,
        payload: {
            roles
        }
    }
}

export const deleteRoleFail = () => {
    return {
        type: DELETE_ROLE_FAIL
    }
}

export const openDeleteRolePopup = (role) => {
    return {
        type: OPEN_DELETE_ROLE_POPUP,
        payload: role
    }
}

export const closeDeleteRolePopup = () => {
    return {
        type: CLOSE_DELETE_ROLE_POPUP
    }
}

export const getMyOrganisations = (pageNum = 0) => {
    return {
        type: GET_MY_ORGANISATIONS,
        payload: pageNum
    }
}

export const getMyOrganisationsSuccess = (organisations) => {
    return {
        type: GET_MY_ORGANISATIONS_SUCCESS,
        payload: organisations
    }
}

// export const getRoleManagementPageFail = () => {
//     return {
//         type: GET_ROLE_MANAGEMENT_PAGE_FAIL
//     }
// }
