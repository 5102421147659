export const SET_TEMPLATE_LOADING = 'SET_TEMPLATE_LOADING';
export const ON_SUBSCRIPTION_PACKAGE_INPUT_CHANGE = 'ON_SUBSCRIPTION_PACKAGE_INPUT_CHANGE';
export const MOVE_ELEMENT = 'MOVE_ELEMENT';
export const ADD_ELEMENT_BLOCK = 'ADD_ELEMENT_BLOCK';
export const SUBMIT_SUBSCRIPTION_PACKAGE = 'SUBMIT_SUBSCRIPTION_PACKAGE';
export const DELETE_SUBSCRIPTION_PACKAGE = 'DELETE_SUBSCRIPTION_PACKAGE';
export const TOGGLE_SUBSCRIPTION_PACKAGE = 'TOGGLE_SUBSCRIPTION_PACKAGE';
export const TOGGLE_SUBSCRIPTION_PACKAGE_SUCCESS = 'TOGGLE_SUBSCRIPTION_PACKAGE_SUCCESS';
export const TOGGLE_SUBSCRIPTION = 'TOGGLE_SUBSCRIPTION';
export const TOGGLE_SUBSCRIPTION_SUCCESS = 'TOGGLE_SUBSCRIPTION_SUCCESS';
export const DELETE_SUBSCRIPTION_PACKAGE_SUCCESS = 'DELETE_SUBSCRIPTION_PACKAGE_SUCCESS';
export const UPDATE_SUBSCRIPTION_PACKAGE = 'UPDATE_SUBSCRIPTION_PACKAGE';
export const SUBMIT_SUBSCRIPTION_PACKAGE_SUCCESS = 'SUBMIT_SUBSCRIPTION_PACKAGE_SUCCESS';
export const GET_TEMPLATES = 'GET_TEMPLATES';
export const GET_INVOICES = 'GET_INVOICES';
export const GET_INVOICES_SUCCESS = 'GET_INVOICES_SUCCESS';
export const SEARCH_INVOICES = 'SEARCH_INVOICES';
export const SEARCH_INVOICES_SUCCESS = 'SEARCH_INVOICES_SUCCESS';
export const GET_SUBSCRIPTION_PACKAGES_SUCCESS = 'GET_SUBSCRIPTION_PACKAGES_SUCCESS';
export const GET_SUBSCRIPTION_PACKAGES = 'GET_SUBSCRIPTION_PACKAGES';
export const GET_SUBSCRIPTION_PACKAGE = 'GET_SUBSCRIPTION_PACKAGE';
export const GET_SUBSCRIPTION_PACKAGE_SUCCESS = 'GET_SUBSCRIPTION_PACKAGE_SUCCESS';
export const RESET_CREATE_NEW_TEMPLATE_FORM = 'RESET_CREATE_NEW_TEMPLATE_FORM';
export const SET_SHOW_CREATE_NEW_TEMPLATE_SUCCESS_MODAL = 'SET_SHOW_CREATE_NEW_TEMPLATE_SUCCESS_MODAL';
export const SET_SHOW_CREATE_NEW_SUBSCRIPTION_PACKAGE_SUCCESS_MODAL = 'SET_SHOW_CREATE_NEW_SUBSCRIPTION_PACKAGE_SUCCESS_MODAL';
export const SET_SHOW_CREATE_NEW_SUBSCRIPTION_SUCCESS_MODAL = 'SET_SHOW_CREATE_NEW_SUBSCRIPTION_SUCCESS_MODAL';
export const CREATE_SUBSCRIPTION_PACKAGE_FAIL = "CREATE_SUBSCRIPTION_PACKAGE_FAIL";
export const SET_SHOW_CREATE_NEW_TEMPLATE_FAILED_MODAL = "SET_SHOW_CREATE_NEW_TEMPLATE_FAILED_MODAL";
export const GET_DEFAULT_SUBSCRIPTION_PACKAGE = "GET_DEFAULT_SUBSCRIPTION_PACKAGE";
export const GET_DEFAULT_SUBSCRIPTION_PACKAGE_SUCCESS = "GET_DEFAULT_SUBSCRIPTION_PACKAGE_SUCCESS";
