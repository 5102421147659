import React, { Component } from "react";
import { Row, Col, Card, Alert, TabContent, TabPane } from "reactstrap";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import Select from "react-select";
import DatePicker from "react-date-picker";
import SimpleReactValidator from "simple-react-validator";
import {
  businessTypes,
  vatValues,
  currencyValues,
  incorporatedInCountryOptions,
} from "../config";
import AutoCompleteInput from "../components/AutoCompleteInput";
import InputPhone from "../components/InputPhone";
import OrganisationAddress from "../components/OrganisationAddress";
import OrganisationPartner from "../components/OrganisationPartner";
import OrganisationOfficer from "../components/OrganisationOfficer";
import StepperWidget from "../components/StepperWidget";
import ImageUploadModal from "../components/Settings/ImageUploadModal";
// import LogoUploadModal from "../components/Settings/LogoUploadModal";
// import SignatureUploadModal from "../components/Settings/SignatureUploadModal";
import {
  onInputChange,
  setActiveStep,
  addPartner,
  deletePartner,
  addOfficer,
  deleteOfficer,
  companySearch,
  selectCompanySearchItem,
  createOrganisation,
  setShowImageUploadModal,
  uploadLogo,
  uploadSignature,
  resetOrganisationForm,
} from "../store/organisation/actions";
import {getSubscriptionPackages} from '../store/subscriptionPackage/actions';
import { CardElement } from "@stripe/react-stripe-js";
import {
  loadScript,
  utcDateToCurrentDate,
  formatAddress,
  formatDate,
  getValidator,
  getPhoneObject, formatCurrency,
} from "../Utils";

import Loader from "../components/Loader";
import logoDark from "../assets/images/outbooks0.png";
import {SetupPaymentModal} from "../components/SetupPaymentModal";
import {switchOrganisation} from "../store/auth/login/actions";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import {getDashboard} from "../store/dashboard/actions";
import Checkbox from "@mui/material/Checkbox";

class CreateNewOrganisation extends Component {
  constructor(props) {
    super(props);
    this.validator = getValidator();
    this.createNewOrganisation = this.createNewOrganisation.bind(this);
    this.renderPartners = this.renderPartners.bind(this);
    this.renderOfficers = this.renderOfficers.bind(this);
    this.validatePartners = this.validatePartners.bind(this);
    this.validateOfficers = this.validateOfficers.bind(this);
    this.nextStep = this.nextStep.bind(this);
    this.backStep = this.backStep.bind(this);
    this.updateStepperWarnings = this.updateStepperWarnings.bind(this);
    this.renderTradingDetails = this.renderTradingDetails.bind(this);
    this.getUploadImageFunction = this.getUploadImageFunction.bind(this);
    this.state = {
      warningSteps: [],
      valid: true,
      openSetupPaymentModal: false,
      cardErrorMessage: null,
      currentActiveStep: 0,
    };
    this.cancel = this.cancel.bind(this);
    this.customBackStep = this.customBackStep.bind(this);
  }
  componentDidMount() {
    this.props.resetOrganisationForm();
    this.props.getDashboard();
    this.props.onInputChange("currency", "GBP");
  }
  componentWillUnmount() {
    this.props.resetOrganisationForm();
  }
  componentDidUpdate(prevProps) {
    if (
      this.props.businessType !== prevProps.businessType ||
      this.props.tradingName !== prevProps.tradingName ||
      this.props.tradingStartDate !== prevProps.tradingStartDate ||
      this.props.vatRegistered !== prevProps.vatRegistered ||
      this.props.vatNumber !== prevProps.vatNumber ||
      this.props.currency !== prevProps.currency ||
      // this.props.affiliatedAccountingBodyName!==prevProps.affiliatedAccountingBodyName ||
      this.props.soleTraderFirstName !== prevProps.soleTraderFirstName ||
      this.props.soleTraderLastName !== prevProps.soleTraderLastName ||
      this.props.soleTraderEmail !== prevProps.soleTraderEmail ||
      this.props.soleTraderPhone !== prevProps.soleTraderPhone ||
      this.props.tradingPremises !== prevProps.tradingPremises ||
      this.props.tradingAddressLine1 !== prevProps.tradingAddressLine1 ||
      this.props.tradingAddressLine2 !== prevProps.tradingAddressLine2 ||
      this.props.tradingLocality !== prevProps.tradingLocality ||
      this.props.tradingRegion !== prevProps.tradingRegion ||
      this.props.tradingCountry !== prevProps.tradingCountry ||
      this.props.tradingPostcode !== prevProps.tradingPostcode ||
      this.props.soleTraderPremises !== prevProps.soleTraderPremises ||
      this.props.soleTraderAddressLine1 !== prevProps.soleTraderAddressLine1 ||
      this.props.soleTraderAddressLine2 !== prevProps.soleTraderAddressLine2 ||
      this.props.soleTraderLocality !== prevProps.soleTraderLocality ||
      this.props.soleTraderRegion !== prevProps.soleTraderRegion ||
      this.props.soleTraderCountry !== prevProps.soleTraderCountry ||
      this.props.soleTraderPostcode !== prevProps.soleTraderPostcode ||
      this.props.companyIncorporatedIn !== prevProps.companyIncorporatedIn ||
      this.props.companyPremises !== prevProps.companyPremises ||
      this.props.companyAddressLine1 !== prevProps.companyAddressLine1 ||
      this.props.companyAddressLine2 !== prevProps.companyAddressLine2 ||
      this.props.companyLocality !== prevProps.companyLocality ||
      this.props.companyRegion !== prevProps.companyRegion ||
      this.props.companyCountry !== prevProps.companyCountry ||
      this.props.companyPostcode !== prevProps.companyPostcode ||
      this.props.partnerCodes !== prevProps.partnerCodes ||
      this.props.officerCodes !== prevProps.officerCodes ||
      this.props.activeStep !== prevProps.activeStep
    ) {
      this.updateStepperWarnings();
    }
    if(this.props.activeStep !== prevProps.activeStep && this.props.activeStep === 4) {
      this.props.getSubscriptionPackages();
    }

    if(this.props.clientSecret !== prevProps.clientSecret && this.props.clientSecret) {
      this.props.onInputChange('loading', true);
      // const confirmPayment = this.props.stripe?.confirmCardPayment('pi_3N05uvSAq3KXUSAJ0tuRkNXC_secret_G5UDLlrxwc3LBJcJetHXZjQuc')
      if(!this.props.isSetup){
        this.props.stripe.confirmCardPayment(this.props.clientSecret)
          .then(confirmPayment => {
            console.log('confirmPayment - ', confirmPayment);
            this.props.onInputChange('loading', false);
            this.props.switchOrganisation(this.props.organisationId);
          })
      } else {
        this.props.stripe.confirmSetup({
          clientSecret: this.props.clientSecret,
          confirmParams: {
            // Return URL where the customer should be redirected after the SetupIntent is confirmed.
            return_url: 'http://localhost:3000/Subscription',
          },
          redirect: 'if_required'
        })
          .then(confirmPayment => {
            this.props.onInputChange('loading', false);
            console.log('confirmPayment - ', confirmPayment);
            this.props.switchOrganisation(this.props.organisationId);
          })
      }

    }
  }
  updateStepperWarnings() {
    console.log('1')
  }
  validatePartners() {
    let valid = true;
    for (let code of this.props.partnerCodes) {
      if (
        !(
          this.validator.fields[`partner${code}FirstName`] &&
          this.validator.fields[`partner${code}LastName`] &&
          this.validator.fields[`partner${code}Email`] &&
          this.validator.fields[`partner${code}Phone`] &&
          this.validator.fields[`partner${code}Address`]
        )
    ) {
        valid = false;
        break;
      }
    }
    return valid;
  }
  validateOfficers() {
    let valid = true;
    for (let code of this.props.officerCodes) {
      if (
        !(
          this.validator.fields[`officer${code}FirstName`] &&
          this.validator.fields[`officer${code}LastName`] &&
          this.validator.fields[`officer${code}Email`] &&
          this.validator.fields[`officer${code}Phone`] &&
          this.validator.fields[`officer${code}Address`] &&
          this.validator.fields[`officer${code}Role`] &&
          this.validator.fields["Website"]
        )
      ) {
        valid = false;
        break;
      }
    }
    return valid;
  }
  async createNewOrganisation() {
    console.log('createNewOrganisation - ', this.validator);
    if (
      this.validator.fields["Trading Name"] &&
      this.validator.fields["Trading Start Date"] &&
      this.validator.fields["Trading Address"] &&
      this.validator.fields["Currency"] &&
      this.validator.fields["Contact Email"] &&
      this.validator.fields["phone"] &&
      (this.props.businessType === "soleTrader"
        ? this.validator.fields["First Name"] &&
          this.validator.fields["Last Name"] &&
          this.validator.fields["phone"] &&
          this.validator.fields["Email"] &&
          this.validator.fields["Residential Address"]
        : true) &&
      (this.props.businessType === "partnership"
        ? this.validatePartners() && this.validator.fields["partnersArray"]
        : true) &&
      (["ltd", "llp"].includes(this.props.businessType)
        ? this.validateOfficers() && this.validator.fields["officersArray"]
        : true)
    ) {
      const data = {
        businessType: this.props.businessType,
        tradingDetails: {
          name: this.props.tradingName,
          startDate: utcDateToCurrentDate(this.props.tradingStartDate),
          address: {
            premises: this.props.tradingPremises,
            addressLine1: this.props.tradingAddressLine1,
            addressLine2: this.props.tradingAddressLine2,
            locality: this.props.tradingLocality,
            region: this.props.tradingRegion,
            country: this.props.tradingCountry,
            postcode: this.props.tradingPostcode,
          },
        },
        logoUrl: this.props.logoUrl,
        signatureUrl: this.props.signatureUrl,
        signatoryName: this.props.signatoryName,
        brandColor: this.props.brandColor,
        website: this.props.website,
        phone: getPhoneObject(this.props.dialCode, this.props.phone),
        // phone: (this.props.phone && this.props.phone!=="") ? ({code: this.props.dialCode, number: this.props.phone}) : null,
        email: this.props.email,
        vatRegistered: this.props.vatRegistered,
        vatNumber: this.props.vatNumber,
        currency: this.props.currency,
        businessTagline: this.props.businessTagline,
        affiliatedAccountingBodyName: this.props.affiliatedAccountingBodyName,
        websiteOfAffiliatedAccountingBody:
          this.props.websiteOfAffiliatedAccountingBody,
      };
      switch (this.props.businessType) {
        case "soleTrader":
          let soleTrader = {
            firstName: this.props.soleTraderFirstName,
            lastName: this.props.soleTraderLastName,
            phone: getPhoneObject(
              this.props.soleTraderDialCode,
              this.props.soleTraderPhone
            ),
            // phone: {code: this.props.soleTraderDialCode, number: this.props.soleTraderPhone},
            email: this.props.soleTraderEmail,
            residentialAddress: {
              premises: this.props.soleTraderPremises,
              addressLine1: this.props.soleTraderAddressLine1,
              addressLine2: this.props.soleTraderAddressLine2,
              locality: this.props.soleTraderLocality,
              region: this.props.soleTraderRegion,
              country: this.props.soleTraderCountry,
              postcode: this.props.soleTraderPostcode,
            },
          };
          data.soleTrader = soleTrader;
          break;
        case "partnership":
          data.partners = [];
          this.props.partnerCodes.forEach((partnerCode) => {
            let partner = {
              isAuthorisedSignatory:
                this.props[`partner${partnerCode}IsAuthorisedSignatory`] ||
                false,
              firstName: this.props[`partner${partnerCode}FirstName`],
              lastName: this.props[`partner${partnerCode}LastName`],
              phone: getPhoneObject(
                this.props[`partner${partnerCode}DialCode`],
                this.props[`partner${partnerCode}Phone`]
              ),
              // phone: {code: this.props[`partner${partnerCode}DialCode`], number: this.props[`partner${partnerCode}Phone`]},
              email: this.props[`partner${partnerCode}Email`],
              residentialAddress: {
                premises: this.props[`partner${partnerCode}Premises`],
                addressLine1: this.props[`partner${partnerCode}AddressLine1`],
                addressLine2: this.props[`partner${partnerCode}AddressLine2`],
                locality: this.props[`partner${partnerCode}Locality`],
                region: this.props[`partner${partnerCode}Region`],
                country: this.props[`partner${partnerCode}Country`],
                postcode: this.props[`partner${partnerCode}Postcode`],
              },
            };
            data.partners.push(partner);
          });
          break;
        case "llp":
        case "ltd":
          if (this.validator.fields["Website"]) {
            data.officers = [];
            this.props.officerCodes.forEach((officerCode) => {
              let officer = {
                isAuthorisedSignatory:
                  this.props[`officer${officerCode}IsAuthorisedSignatory`] ||
                  false,
                firstName: this.props[`officer${officerCode}FirstName`],
                lastName: this.props[`officer${officerCode}LastName`],
                role: this.props[`officer${officerCode}Role`],
                appointedOn: this.props[`officer${officerCode}AppointedOn`],
                nationality: this.props[`officer${officerCode}Nationality`],
                countryOfResidence:
                  this.props[`officer${officerCode}CountryOfResidence`],
                phone: getPhoneObject(
                  this.props[`officer${officerCode}DialCode`],
                  this.props[`officer${officerCode}Phone`]
                ),
                // phone: {code: this.props[`officer${officerCode}DialCode`], number: this.props[`officer${officerCode}Phone`]},
                email: this.props[`officer${officerCode}Email`],
                correspondenceAddress: {
                  premises: this.props[`officer${officerCode}Premises`],
                  addressLine1: this.props[`officer${officerCode}AddressLine1`],
                  addressLine2: this.props[`officer${officerCode}AddressLine2`],
                  locality: this.props[`officer${officerCode}Locality`],
                  region: this.props[`officer${officerCode}Region`],
                  country: this.props[`officer${officerCode}Country`],
                  postcode: this.props[`officer${officerCode}Postcode`],
                },
              };
              data.officers.push(officer);
            });
            data.company = {
              name: this.props.companyName,
              number: this.props.companyNumber,
              incorporatedIn: this.props.companyIncorporatedIn,
            };
          } else {
            this.validator.showMessages();
            this.forceUpdate();
            break;
          }
          break;
        default:
          break;
      }

      // const payment = await this.props.stripe.createPaymentInte
      //
      try {
        if(this.state.selectedPlan?.is_default) {
          data.package = {
            _id: this.state.selectedPlan?.id,
            is_default: this.state.selectedPlan?.is_default,
            expired_in_month: 1,
            is_active: false
          };
          this.props.createOrganisation(data, this.props.history);
          this.setState({openSetupPaymentModal: false})
          return;
        }

        if(this.props.role === 'superadmin') {
          data.package = {
            _id: this.state.selectedPlan?.id,
            expired_in_month: 1,
            is_active: false,
            interval: this.props.interval,
            in_price_of_month_offer_enabled: this.state.in_price_of_month_offer_enabled?._id === this.state.selectedPlan?.id,
            discounted_price_for_month_enabled: this.state.discounted_price_for_month_enabled?._id === this.state.selectedPlan?.id,
            discounted_price_offer_enabled: this.state.discounted_price_enabled?._id === this.state.selectedPlan?.id,
            discount_enabled: this.state.discount_enabled?._id === this.state.selectedPlan?.id,
          };
          this.props.createOrganisation(data, this.props.history);
          return;
        }

        this.props.onInputChange('loading', true)
        const paymentMethod = await this.props.stripe?.createPaymentMethod({
          type: "card",
          card: this.props.elements?.getElement(CardElement),
          billing_details: {
            name: data.tradingDetails.name,
            email: data.email,
            phone: this.props.dialCode + ' ' + this.props.phone,
            address: {
              line1: data.tradingDetails.address.addressLine1,
              line2: data.tradingDetails.address.addressLine2,
              postal_code: data.tradingDetails.address.postcode,
              city: data.tradingDetails.address.locality,
              state: data.tradingDetails.address.region
            }
          },
        });

        data.package = {
          _id: this.state.selectedPlan?.id,
          expired_in_month: 1,
          is_active: false,
          interval: this.props.interval,
          in_price_of_month_offer_enabled: this.state.in_price_of_month_offer_enabled?._id === this.state.selectedPlan?.id,
          discounted_price_for_month_enabled: this.state.discounted_price_for_month_enabled?._id === this.state.selectedPlan?.id,
          discounted_price_offer_enabled: this.state.discounted_price_enabled?._id === this.state.selectedPlan?.id,
          discount_enabled: this.state.discount_enabled?._id === this.state.selectedPlan?.id,
        };

        console.log('paymentMethod - ', paymentMethod);

        if(paymentMethod.error) {
          this.props.onInputChange('loading', false)
          return this.setState({cardErrorMessage: paymentMethod.error.message});
        }

        data.paymentMethodId = paymentMethod.paymentMethod.id;

        this.props.createOrganisation(data, this.props.history);
        this.setState({openSetupPaymentModal: false})

      } catch(e) {
        console.log('e - ', e);
      }
    } else {
      this.validator.showMessages();
      this.updateStepperWarnings();
      this.forceUpdate();
    }
  }
  getSubscriptionPackagePriceValue(item, original) {
    if(original) return {year: item.fee_per_year, month: item.fee_per_month, isDiscounted: false}
    const isDiscounted = this.state.discount_enabled;
    const isDiscountedPrice = this.state.discounted_price_enabled;
    if(isDiscounted) return {year: this.getDiscountedValue(item.fee_per_year, item.offers.discount.value), month: this.getDiscountedValue(item.fee_per_month, item.offers.discount.value)};
    if(isDiscountedPrice) return {year: item.offers.discounted_price?.value, month: item.offers.discounted_price?.value / 12}
    return {year: item.fee_per_year, month: item.fee_per_month, isDiscounted: false};
  }
  getDiscountedValue(value, perc) {
    return value - ((perc * value) / 100).toFixed(2);
  }
  renderPartners() {
    let jsx = [];
    let index = 0;
    for (let partnerCode of this.props.partnerCodes) {
      jsx.push(
        <OrganisationPartner
          key={partnerCode}
          index={index++}
          onInputChange={this.props.onInputChange}
          partnerCode={partnerCode}
          isAuthorisedSignatory={
            this.props[`partner${partnerCode}IsAuthorisedSignatory`]
          }
          firstName={this.props[`partner${partnerCode}FirstName`]}
          lastName={this.props[`partner${partnerCode}LastName`]}
          dialCode={this.props[`partner${partnerCode}DialCode`]}
          phone={this.props[`partner${partnerCode}Phone`]}
          email={this.props[`partner${partnerCode}Email`]}
          addressSearch={this.props[`partner${partnerCode}AddressSearch`]}
          premises={this.props[`partner${partnerCode}Premises`]}
          addressLine1={this.props[`partner${partnerCode}AddressLine1`]}
          addressLine2={this.props[`partner${partnerCode}AddressLine2`]}
          locality={this.props[`partner${partnerCode}Locality`]}
          region={this.props[`partner${partnerCode}Region`]}
          postcode={this.props[`partner${partnerCode}Postcode`]}
          country={this.props[`partner${partnerCode}Country`]}
          deletePartner={this.props.deletePartner}
          validator={this.validator}
        />
      );
    }
    return jsx;
  }
  renderOfficers() {
    let jsx = [];
    let index = 0;
    for (let officerCode of this.props.officerCodes) {
      console.log("officerCode", officerCode);
      jsx.push(
        <OrganisationOfficer
          key={`${officerCode}-${this.props[`officer${officerCode}Role`]}`}
          index={index++}
          onInputChange={this.props.onInputChange}
          officerCode={officerCode}
          isAuthorisedSignatory={
            this.props[`officer${officerCode}IsAuthorisedSignatory`]
          }
          firstName={this.props[`officer${officerCode}FirstName`]}
          lastName={this.props[`officer${officerCode}LastName`]}
          role={this.props[`officer${officerCode}Role`]}
          appointedOn={this.props[`officer${officerCode}AppointedOn`]}
          nationality={this.props[`officer${officerCode}Nationality`]}
          countryOfResidence={
            this.props[`officer${officerCode}CountryOfResidence`]
          }
          dialCode={this.props[`officer${officerCode}DialCode`]}
          phone={this.props[`officer${officerCode}Phone`]}
          email={this.props[`officer${officerCode}Email`]}
          addressSearch={this.props[`officer${officerCode}AddressSearch`]}
          premises={this.props[`officer${officerCode}Premises`]}
          addressLine1={this.props[`officer${officerCode}AddressLine1`]}
          addressLine2={this.props[`officer${officerCode}AddressLine2`]}
          locality={this.props[`officer${officerCode}Locality`]}
          region={this.props[`officer${officerCode}Region`]}
          postcode={this.props[`officer${officerCode}Postcode`]}
          country={this.props[`officer${officerCode}Country`]}
          deleteOfficer={this.props.deleteOfficer}
          validator={this.validator}
        />
      );
    }
    return jsx;
  }
  generateNewPartnerCode() {
    return new Date().getTime();
  }
  generateNewOfficerCode() {
    return new Date().getTime();
  }
  renderTradingDetails() {
    return (
      <React.Fragment>
        <div className="row fieldset">
          <div className="col-4 text-right">
            <label className="fieldset-label required">Trading Name</label>
          </div>
          <div className="col-8">
            <input
              value={this.props.tradingName || ""}
              className="input-text"
              onChange={(e) =>
                this.props.onInputChange("tradingName", e.target.value)
              }
              type="text"
              placeholder="Trading Name"
            />
            {this.validator.message(
              "Trading Name",
              this.props.tradingName,
              "required",
              { className: "text-danger" }
            )}
          </div>
        </div>
        <div className="row fieldset">
          <div className="col-4 text-right">
            <label className="fieldset-label required">
              Trading Start Date
            </label>
          </div>
          <div className="col-8">
            <DatePicker
              yearPlaceholder="yyyy"
              monthPlaceholder="MM"
              dayPlaceholder="dd"
              format="dd-MM-yyyy"
              onChange={(date) =>
                this.props.onInputChange("tradingStartDate", date)
              }
              value={
                this.props.tradingStartDate ? this.props.tradingStartDate : null
              }
            />
            {this.validator.message(
              "Trading Start Date",
              this.props.tradingStartDate,
              "required|_date",
              { className: "text-danger" }
            )}
          </div>
        </div>
        <div className="row fieldset">
          <div className="col-4 text-right">
            <label className="fieldset-label required">Trading Address</label>
          </div>
          <div className="col-8">
            <OrganisationAddress
              addressHeader="Trading Address"
              addressPrefix="trading"
              addressSearch={this.props.tradingAddressSearch}
              premises={this.props.tradingPremises}
              addressLine1={this.props.tradingAddressLine1}
              addressLine2={this.props.tradingAddressLine2}
              locality={this.props.tradingLocality}
              region={this.props.tradingRegion}
              country={this.props.tradingCountry}
              postcode={this.props.tradingPostcode}
              onInputChange={this.props.onInputChange}
            />
            {this.validator.message(
              "Trading Address",
              formatAddress(
                this.props.tradingPremises,
                this.props.tradingAddressLine1,
                this.props.tradingAddressLine2,
                this.props.tradingLocality,
                this.props.tradingRegion,
                this.props.tradingCountry,
                this.props.tradingPostcode
              ),
              "required",
              { className: "text-danger" }
            )}
          </div>
        </div>
      </React.Fragment>
    );
  }
  renderESignnature() {
    return (
      <div className="fieldset-group">
        <label className="fieldset-group-label">E Signature</label>
        <div className="row fieldset">
          <div className="col-4 text-right">
            <label className="fieldset-label">Signatory Name</label>
          </div>
          <div className="col-8">
            <input
              value={this.props.signatoryName || ""}
              className="input-text"
              onChange={(e) =>
                this.props.onInputChange("signatoryName", e.target.value)
              }
              type="text"
              placeholder="Signatory Name"
            />
          </div>
        </div>
        <div className="row fieldset">
          <div className="col-4 text-right">
            <label className="fieldset-label">Signature Image</label>
          </div>
          <div className="col-8">
            {this.props.signatureUrl && (
              <div className="organisation-details-image-div">
                <img
                  src={this.props.signatureUrl}
                  className="organisation-details-image"
                />
                <span
                  onClick={() => this.props.onInputChange("signatureUrl", null)}
                  className="remove-organisation-details-image-preview"
                >
                  <i className="ion ion-ios-close-circle"></i>
                </span>
              </div>
            )}
            {!this.props.signatureUrl && (
              <button
                onClick={() =>
                  this.props.setShowImageUploadModal(true, "Signature")
                }
                className="btn btn-primary"
              >
                Upload Signature
              </button>
            )}
            <br />
          </div>
          <div className="col-4 text-right"></div>
          <div className="col-8">
            <i>
              <small>
                Supported filetypes are .jpg, .jpeg, .png, .svg up to a file
                size of 2MB.
              </small>
            </i>
          </div>
        </div>
      </div>
    );
  }
  getUploadImageFunction() {
    switch (this.props.imageUploadModalType) {
      case "Logo":
        return this.props.uploadLogo;
      case "Signature":
        return this.props.uploadSignature;
      default:
        return null;
    }
  }
  async nextStep() {
    // this.setState({
    //   valid: true,
    // });
    // this.setState({warningSteps:[]})
    // this.validator.hideMessages()
    // this.props.setActiveStep(this.props.activeStep + 1);
    // this.forceUpdate();
    //
    // return;

    if (this.props.activeStep === 1) {
      if( this.props.businessType === "soleTrader" || this.props.businessType === "partnership"){
        if (
          this.validator.fieldValid("Trading Name") &&
          this.validator.fieldValid("Trading Start Date") &&
          this.validator.fieldValid("Trading Address")
        ) {

          this.setState({
            valid: true,
          });
          this.setState({warningSteps:[]})
          this.validator.hideMessages()
          this.props.setActiveStep(this.props.activeStep + 1);
          this.forceUpdate();
          
        } else {
          this.setState({
            valid: false,
          });
          this.setState({warningSteps:[1]})
          this.validator.showMessages();
          this.forceUpdate();
        }
      }
      else if( this.props.businessType === "llp" || this.props.businessType === "ltd"){
        if (
          this.validator.fieldValid("Trading Name") &&
          this.validator.fieldValid("Trading Start Date") &&
          this.validator.fieldValid("Trading Address") &&
          this.validator.fieldValid("Company Incorporated In")&&
          this.validator.fieldValid("Company Incorporation Date") &&
          this.validator.fieldValid("Company Number") &&
          this.validator.fieldValid("Entity Type")
        ) {

          this.setState({
            valid: true,
          });
          this.validator.hideMessages()
          this.props.setActiveStep(this.props.activeStep + 1);
          this.setState({warningSteps:[]})
        } else {
          this.setState({
            valid: false,
          });
          this.setState({warningSteps:[1]})
          this.validator.showMessages();
          this.forceUpdate();
        }
      }
    } 
    else if (this.props.activeStep === 2) {
      console.log("this.props - ", this.props, this.validator);
      if(this.props.businessType === "soleTrader")
      {
        if (
          this.validator.fieldValid("First Name") &&
          this.validator.fieldValid("Last Name") &&
          this.validator.fieldValid("Email") &&
          this.validator.fieldValid("Residential Address") &&
          this.validator.fieldValid("soleTraderPhone")
        ) {

          this.setState({
            valid: true,
          });
          this.validator.hideMessages()
          this.props.setActiveStep(this.props.activeStep + 1);
          this.setState({warningSteps:[]})
        } else {
          this.setState({
            valid: false,
          });
          this.setState({warningSteps:[2]})
          this.validator.showMessages();
          this.forceUpdate();
        }
      } else if(this.props.businessType === 'partnership') {
        const isValid = Object.keys(this.validator.fields).every(c => {
          let arr = ['FirstName', 'LastName', 'Email', 'Phone', 'Address'];
          let cond = c.startsWith('partner') && this.props.partnerCodes.some(b => c.includes(b)) && arr.some(k => c.endsWith(k));
          if(cond) {
            return this.validator.fields[c];
          }
          return true;
        });

        if(isValid && this.validator.fields.partnersArray) {
          this.setState({
            valid: true,
          });
          this.validator.hideMessages()
          this.props.setActiveStep(this.props.activeStep + 1);
          this.setState({warningSteps:[]})
        } else {
          this.setState({
            valid: false,
          });
          this.setState({warningSteps:[2]})
          this.validator.showMessages();
          this.forceUpdate();
        }
      } else if(this.props.businessType === 'llp' || this.props.businessType === 'ltd') {
        console.log('this.validator - ', this.validator);
        console.log('this.props - ', this.props);
        const isValid = Object.keys(this.validator.fields).every(c => {
          let arr = ['FirstName', 'LastName', 'Email', 'Phone', 'Address', 'AppointedOn', 'Role'];
          let cond = c.startsWith('officer') && this.props.officerCodes.some(b => c.includes(b)) && arr.some(k => c.endsWith(k));
          // console.log('cond - ', cond, c);
          if(cond) {
            return this.validator.fields[c];
          }
          return true;
        });

        if(isValid && this.validator.fields.officersArray) {
          this.setState({
            valid: true,
          });
          this.validator.hideMessages()
          this.props.setActiveStep(this.props.activeStep + 1);
          this.setState({warningSteps:[]})
        } else {
          this.setState({
            valid: false,
          });
          this.setState({warningSteps:[2]})
          this.validator.showMessages();
          this.forceUpdate();
        }
      } else {
        this.props.setActiveStep(this.props.activeStep + 1);
        this.setState({warningSteps:[]})
      }

    }
    else {
      if (
        this.validator.fieldValid("Contact Email") &&
        this.validator.fieldValid("phone")
      ) {

        this.setState({
          valid: true,
        });
        this.validator.hideMessages()
        this.props.setActiveStep(this.props.activeStep + 1);
        this.setState({warningSteps:[]})
      } else {
        this.setState({
          valid: false,
        });
        this.setState({warningSteps:[3]})
        this.validator.showMessages();
        this.forceUpdate();
      }
      // console.log("step Count :: ", this.props.activeStep);
      // this.props.setActiveStep(this.props.activeStep + 1);
    }
  }
  backStep() {
    console.log("step Count :: ", this.props.activeStep);
    this.setState({warningSteps:[]})
    this.props.setActiveStep(this.props.activeStep - 1);
  }
  handlePackageSelection(id, is_default, name, price, interval, fee_per_year) {
    if(is_default) {
      return this.setState({selectedPlan: {id, is_default}}, () => {
        this.handlePay();
      });
    }
    let _price = price;
    if(this.props.interval === 'month') _price = price / 12;
    if(this.props.interval === 'halfYear') _price = price / 2;
    if(this.props.interval === 'quarter') _price = price / 4;

    console.log('this.props. role = ', this.props.role);

    if(this.props.role === 'superadmin') {
      return this.setState({selectedPlan: {id, is_default, interval: this.props.interval, price: _price, name, fee_per_year}}, () => {
        this.handlePay();
      });
    }

    this.setState({openSetupPaymentModal: true, selectedPlan: {id, is_default, interval: this.props.interval, price: _price, name, fee_per_year}});
  }
  async handlePay() {
    this.createNewOrganisation();
  }
  cancel() {
    this.props.setActiveStep(1);
    this.props.history.goBack();
  }
  customBackStep(stepNumber) {
    this.setState({
      warningSteps: [],
      currentActiveStep: this.props.activeStep,
    });
    this.props.setActiveStep(stepNumber);
  }
  render() {
    const soleTraderSteps = [
      "Basic Information",
      "Sole Trader Details",
      "Other Information",
      "Choose Plan"
    ];
    const partnershipSteps = [
      "Basic Information",
      "Partner Details",
      "Other Information",
      "Choose Plan"
    ];
    const ltdSteps = [
      "Basic Information",
      "Officer Details",
      "Other Information",
      "Choose Plan"
    ];
    const llpSteps = [
      "Basic Information",
      "Officer Details",
      "Other Information",
      "Choose Plan"
    ];
    let activeStepCount = 1;
    return (
      <React.Fragment>
        {(this.props.subPackagesLoading || this.props.logLoading || this.props.loading) && <Loader />}
        <div className="container-fluid new-item-page-container">
          <div className="new-item-page-nav">
            <i
              onClick={this.cancel}
              className="ion ion-md-arrow-round-back back-button"
            ></i>
          </div>
          <div className="new-item-page-content">
            <div className="row form-row">
              <div className="col-12">
                <h3>Create New Practice</h3>
                <StepperWidget
                  warningSteps={this.state.warningSteps}
                  onClick={(stepNumber) => this.customBackStep(stepNumber)}
                  activeStep={this.props.activeStep}
                  steps={eval(this.props.businessType + "Steps")}
                  currentActiveStep={this.state.currentActiveStep}
                  custom={true}
                />
                <TabContent activeTab={this.props.activeStep}>
                  <TabPane tabId={1} className="p-3">
                    <div className="row fieldset">
                      <div className="col-4 text-right">
                        <label className="fieldset-label required">
                          Business Type
                        </label>
                      </div>
                      <div className="col-8">
                        <Select
                          options={businessTypes}
                          value={businessTypes?.length === 1 ? businessTypes[0] :
                            this.props.businessType
                              ? businessTypes.filter(
                                  (option) =>
                                    option.value === this.props.businessType
                                )
                              : ""
                          }
                          onChange={(selectedOption) =>
                            this.props.onInputChange(
                              "businessType",
                              selectedOption.value
                            )
                          }
                        />
                      </div>
                    </div>
                    {["soleTrader", "partnership"].includes(
                      this.props.businessType
                    ) && (
                      <React.Fragment>
                        {/*<Input label="Trading Name" value={this.props.tradingName || ""} placeholder="Trading Name" onChange={(e) => this.props.onInputChange("tradingName", e.target.value)} />*/}
                        {this.renderTradingDetails()}
                        {this.renderESignnature()}
                      </React.Fragment>
                    )}
                    {["llp", "ltd"].includes(this.props.businessType) && (
                      <React.Fragment>
                        <div className="row fieldset company-search">
                          <div className="col-4 text-right">
                            <label className="fieldset-label">
                              Search Company
                            </label>
                          </div>
                          <div className="col-8">
                            <AutoCompleteInput
                              value={this.props.companySearchInput || ""}
                              onInputChange={this.props.onInputChange}
                              onSelect={this.props.selectCompanySearchItem}
                              search={this.props.companySearch}
                              name="companySearchInput"
                              placeholder="Search by Company Name or Company Number"
                              items={this.props.companySearchItems.filter(
                                (item) => item.companyType
                              )}
                              valueProperty="title"
                            />
                          </div>
                        </div>
                        <div className="row fieldset">
                          <div className="col-4 text-right">
                            <label className="fieldset-label">
                              Company Name
                            </label>
                          </div>
                          <div className="col-8">
                            <input
                              value={this.props.companyName || ""}
                              readOnly
                              className="input-text"
                              type="text"
                              placeholder="Company Name"
                            />
                            {this.validator.message(
                              "Company Name",
                              this.props.companyName,
                              "required",
                              { className: "text-danger" }
                            )}
                          </div>
                        </div>
                        <div className="row fieldset">
                          <div className="col-4 text-right">
                            <label className="fieldset-label">
                              Entity Type
                            </label>
                          </div>
                          <div className="col-8">
                            <input
                              value={this.props.companyType}
                              readOnly
                              className="input-text"
                              type="text"
                              placeholder="Entity Type"
                            />
                            {this.validator.message(
                              "Entity Type",
                              this.props.companyType,
                              "",
                              { className: "text-danger" }
                            )}
                          </div>
                        </div>
                        <div className="row fieldset">
                          <div className="col-4 text-right">
                            <label className="fieldset-label">
                              Company Number
                            </label>
                          </div>
                          <div className="col-8">
                            <input
                              value={this.props.companyNumber || ""}
                              readOnly
                              className="input-text"
                              type="text"
                              placeholder="Company Number"
                            />
                            {this.validator.message(
                              "Company Number",
                              this.props.companyNumber,
                              "required",
                              { className: "text-danger" }
                            )}
                          </div>
                        </div>
                        <div className="row fieldset">
                          <div className="col-4 text-right">
                            <label className="fieldset-label">
                              Registered Office Address
                            </label>
                          </div>
                          <div className="col-8">
                            <OrganisationAddress
                              addressHeader="Registered Office Address"
                              addressPrefix="company"
                              addressSearch={this.props.companyAddressSearch}
                              premises={this.props.companyPremises}
                              addressLine1={this.props.companyAddressLine1}
                              addressLine2={this.props.companyAddressLine2}
                              locality={this.props.companyLocality}
                              region={this.props.companyRegion}
                              country={this.props.companyCountry}
                              postcode={this.props.companyPostcode}
                              onInputChange={this.props.onInputChange}
                              readOnly
                            />
                          </div>
                        </div>
                        <div className="row fieldset">
                          <div className="col-4 text-right">
                            <label className="fieldset-label">
                              Incorporation Date
                            </label>
                          </div>
                          <div className="col-8">
                            <input
                              value={
                                this.props.companyIncorporationDate
                                  ? formatDate(
                                      this.props.companyIncorporationDate
                                    )
                                  : ""
                              }
                              readOnly
                              className="input-text"
                              type="text"
                              placeholder="Incorporation Date"
                            />
                            {this.validator.message(
                              "Company Incorporation Date",
                              this.props.companyIncorporationDate,
                              "required",
                              { className: "text-danger" }
                            )}
                          </div>
                        </div>
                        <div className="row fieldset">
                          <div className="col-4 text-right">
                            <label className="fieldset-label required">
                              Incorporated In
                            </label>
                          </div>
                          <div className="col-8">
                            <Select
                              options={incorporatedInCountryOptions}
                              value={incorporatedInCountryOptions?.length === 1 ? incorporatedInCountryOptions[0] :
                                this.props.companyIncorporatedIn
                                  ? incorporatedInCountryOptions.filter(
                                      (option) =>
                                        option.value ===
                                        this.props.companyIncorporatedIn
                                    )
                                  : ""
                              }
                              onChange={(selectedOption) =>
                                this.props.onInputChange(
                                  "companyIncorporatedIn",
                                  selectedOption.value
                                )
                              }
                            />
                            {this.validator.message(
                              "Company Incorporated In",
                              this.props.companyIncorporatedIn,
                              "required",
                              { className: "text-danger" }
                            )}
                          </div>
                        </div>

                        <div className="fieldset-group">
                          <label className="fieldset-group-label">
                            Trading Details
                          </label>
                          {this.renderTradingDetails()}
                        </div>
                        {this.renderESignnature()}
                      </React.Fragment>
                    )}
                  </TabPane>
                  {this.props.businessType === "soleTrader" && (
                    <TabPane tabId={++activeStepCount} className="p-3">
                      <div className="row fieldset">
                        <div className="col-4 text-right">
                          <label className="fieldset-label required">
                            First Name
                          </label>
                        </div>
                        <div className="col-8">
                          <input
                            value={this.props.soleTraderFirstName || ""}
                            onChange={(e) =>
                              this.props.onInputChange(
                                "soleTraderFirstName",
                                e.target.value
                              )
                            }
                            className="input-text"
                            type="text"
                            placeholder="First Name"
                          />
                          {this.validator.message(
                            "First Name",
                            this.props.soleTraderFirstName,
                            "required|max:50|_name",
                            { className: "text-danger" }
                          )}
                        </div>
                      </div>
                      <div className="row fieldset">
                        <div className="col-4 text-right">
                          <label className="fieldset-label required">
                            Last Name
                          </label>
                        </div>
                        <div className="col-8">
                          <input
                            value={this.props.soleTraderLastName || ""}
                            onChange={(e) =>
                              this.props.onInputChange(
                                "soleTraderLastName",
                                e.target.value
                              )
                            }
                            className="input-text"
                            type="text"
                            placeholder="Last Name"
                          />
                          {this.validator.message(
                            "Last Name",
                            this.props.soleTraderLastName,
                            "required|max:50|_name",
                            { className: "text-danger" }
                          )}
                        </div>
                      </div>
                      <div className="row fieldset">
                        <div className="col-4 text-right">
                          <label className="fieldset-label required">
                            Phone
                          </label>
                        </div>
                        <div className="col-8">
                          <InputPhone
                            required={true}
                            placeholder="Phone"
                            phoneFieldName="soleTraderPhone"
                            dialCodeFieldName="soleTraderDialCode"
                            dialCode={this.props.soleTraderDialCode}
                            phone={this.props.soleTraderPhone}
                            validator={this.validator}
                            onChange={this.props.onInputChange}
                          />
                        </div>
                        {/*{this.validator.message(*/}
                        {/*    "Phone",*/}
                        {/*    this.props.soleTraderPhone,*/}
                        {/*    "_required",*/}
                        {/*    { className: "text-danger" }*/}
                        {/*  )}*/}
                      </div>
                      <div className="row fieldset">
                        <div className="col-4 text-right">
                          <label className="fieldset-label required">
                            Email
                          </label>
                        </div>
                        <div className="col-8">
                          <input
                            value={this.props.soleTraderEmail || ""}
                            onChange={(e) =>
                              this.props.onInputChange(
                                "soleTraderEmail",
                                e.target.value
                              )
                            }
                            className="input-text"
                            type="email"
                            placeholder="Email"
                          />
                          {this.validator.message(
                            "Email",
                            this.props.soleTraderEmail,
                            "_required|_email",
                            { className: "text-danger" }
                          )}
                        </div>
                      </div>
                      <div className="row fieldset">
                        <div className="col-4 text-right">
                          <label className="fieldset-label required">
                            Residential Address
                          </label>
                        </div>
                        <div className="col-8">
                          <OrganisationAddress
                            addressHeader="Residential Address"
                            addressPrefix="soleTrader"
                            addressSearch={this.props.soleTraderAddressSearch}
                            premises={this.props.soleTraderPremises}
                            addressLine1={this.props.soleTraderAddressLine1}
                            addressLine2={this.props.soleTraderAddressLine2}
                            locality={this.props.soleTraderLocality}
                            region={this.props.soleTraderRegion}
                            country={this.props.soleTraderCountry}
                            postcode={this.props.soleTraderPostcode}
                            onInputChange={this.props.onInputChange}
                          />
                          {this.validator.message(
                            "Residential Address",
                            formatAddress(
                              this.props.soleTraderPremises,
                              this.props.soleTraderAddressLine1,
                              this.props.soleTraderAddressLine2,
                              this.props.soleTraderLocality,
                              this.props.soleTraderRegion,
                              this.props.soleTraderCountry,
                              this.props.soleTraderPostcode
                            ),
                            "required",
                            { className: "text-danger" }
                          )}
                        </div>
                      </div>
                    </TabPane>
                  )}
                  {this.props.businessType === "partnership" && (
                    <TabPane tabId={++activeStepCount} className="p-3">
                      {this.renderPartners()}
                      {this.validator.message(
                        "partnersArray",
                        this.props.partnerCodes,
                        "_requiredArray:partner",
                        { className: "text-center minimum-array-1" }
                      )}
                      <div className="row fieldset">
                        <div className="col-12 text-right">
                          <button
                            onClick={() => {
                              this.validator.hideMessages();
                              this.props.addPartner(
                                this.generateNewPartnerCode()
                              )
                            }}
                            className="btn btn-sm create-item"
                          >
                            <i className="ion ion-md-add-circle"></i>Add Partner
                          </button>
                        </div>
                      </div>
                    </TabPane>
                  )}
                  {["llp", "ltd"].includes(this.props.businessType) && (
                    <TabPane tabId={++activeStepCount} className="p-3">
                      {this.renderOfficers()}
                      {this.validator.message(
                        "officersArray",
                        this.props.officerCodes,
                        "_requiredArray:officer",
                        { className: "text-center minimum-array-1" }
                      )}
                      <div className="row fieldset">
                        <div className="col-12 text-right">
                          <button
                            onClick={() => {
                              this.validator.hideMessages();
                              this.props.addOfficer(
                                this.generateNewOfficerCode()
                              )
                            }}
                            className="btn btn-sm create-item"
                          >
                            <i className="ion ion-md-add-circle"></i>Add Officer
                          </button>
                        </div>
                      </div>
                    </TabPane>
                  )}

                  <TabPane tabId={++activeStepCount} className="p-3">
                    <div className="row fieldset">
                      <div className="col-4 text-right">
                        <label className="fieldset-label ">
                          VAT Registered
                        </label>
                      </div>
                      <div className="col-8">
                        <Select
                          options={vatValues}
                          value={vatValues?.length === 1 ? vatValues[0] :
                            typeof this.props.vatRegistered === "boolean"
                              ? vatValues.filter(
                                  (option) =>
                                    option.value === this.props.vatRegistered
                                )
                              : vatValues.filter(
                                  (option) => option.value === false
                                )
                          }
                          onChange={(selectedOption) =>
                            this.props.onInputChange(
                              "vatRegistered",
                              selectedOption.value
                            )
                          }
                        />
                        {/* {this.validator.message('vat Registered', this.props.vatRegistered, 'required', { className: 'text-danger' })} */}
                      </div>
                    </div>
                    {this.props.vatRegistered && (
                      <div className="row fieldset">
                        <div className="col-4 text-right">
                          <label className="fieldset-label ">VAT Number</label>
                        </div>
                        <div className="col-8">
                          <input
                            value={this.props.vatNumber || ""}
                            onChange={(e) =>
                              this.props.onInputChange(
                                "vatNumber",
                                e.target.value
                              )
                            }
                            className="input-text"
                            type="text"
                            placeholder="VAT Number"
                          />
                          {/* {this.validator.message('vat Number', this.props.vatNumber, 'required|alpha_num', { className: 'text-danger' })} */}
                        </div>
                      </div>
                    )}
                    <div className="row fieldset">
                      <div className="col-4 text-right">
                        <label className="fieldset-label required">
                          Preferred Currency
                        </label>
                      </div>
                      <div className="col-8">
                        <Select
                          options={currencyValues}
                          value={currencyValues?.length === 1 ? currencyValues[0] :
                            this.props.currency
                              ? currencyValues.filter(
                                  (option) =>
                                    option.value === this.props.currency
                                )
                              : ""
                          }
                          onChange={(selectedOption) =>
                            this.props.onInputChange(
                              "currency",
                              selectedOption.value
                            )
                          }
                        />
                        {this.validator.message(
                          "Currency",
                          this.props.currency,
                          "",
                          { className: "text-danger" }
                        )}
                      </div>
                    </div>
                    <div className="row fieldset">
                      <div className="col-4 text-right">
                        <label className="fieldset-label">Website</label>
                      </div>
                      <div className="col-8">
                        <input
                          onChange={(e) =>
                            this.props.onInputChange("website", e.target.value)
                          }
                          value={this.props.website || ""}
                          className="input-text"
                          type="text"
                          placeholder="Website"
                        />
                        {this.validator.message(
                          "Website",
                          this.props.website,
                          "_url",
                          { className: "text-danger" }
                        )}
                      </div>
                    </div>
                    <div className="row fieldset">
                      <div className="col-4 text-right">
                        <label className="fieldset-label required">Contact Email</label>
                      </div>
                      <div className="col-8">
                        <input
                          value={this.props.email || ""}
                          onChange={(e) =>
                            this.props.onInputChange("email", e.target.value)
                          }
                          className="input-text"
                          type="text"
                          placeholder="Contact Email"
                        />
                        {this.validator.message(
                          "Contact Email",
                          this.props.email,
                          "_required|_email",
                          { className: "text-danger" }
                        )}
                      </div>
                    </div>
                    <div className="row fieldset">
                      <div className="col-4 text-right">
                        <label className="fieldset-label required">Contact Phone</label>
                      </div>
                      <div className="col-8">
                        <InputPhone
                          placeholder="Phone"
                          phoneFieldName="phone"
                          dialCodeFieldName="dialCode"
                          dialCode={this.props.dialCode}
                          required
                          phone={this.props.phone}
                          validator={this.validator}
                          onChange={this.props.onInputChange}
                        />
                      </div>
                    </div>
                    <div className="row fieldset">
                      <div className="col-4 text-right">
                        <label className="fieldset-label">Logo</label>
                      </div>
                      <div className="col-8">
                        {this.props.logoUrl && (
                          <div className="organisation-details-logo-div">
                            <img
                              src={this.props.logoUrl}
                              className="organisation-details-logo"
                            />
                            <span
                              onClick={() =>
                                this.props.onInputChange("logoUrl", null)
                              }
                              className="remove-organisation-details-logo-preview"
                            >
                              <i className="ion ion-ios-close-circle"></i>
                            </span>
                          </div>
                        )}
                        {!this.props.logoUrl && (
                          <>
                            <button
                              onClick={() =>
                                this.props.setShowImageUploadModal(true, "Logo")
                              }
                              className="btn btn-primary"
                            >
                              Upload Logo
                            </button>
                            <br />
                            <i>
                              <small>
                                Supported filetypes are .jpg, .jpeg, .png, .svg
                                up to a file size of 2MB.
                              </small>
                            </i>
                          </>
                        )}
                      </div>
                    </div>
                    <div className="row fieldset">
                      <div className="col-4 text-right">
                        <label className="fieldset-label">Brand Colour</label>
                      </div>
                      <div className="col-8">
                        {this.props.brandColor && (
                          <input
                            value={this.props.brandColor || ""}
                            onChange={(e) =>
                              this.props.onInputChange(
                                "brandColor",
                                e.target.value
                              )
                            }
                            className="input-text"
                            type="color"
                            placeholder="Brand Colour"
                          />
                        )}
                      </div>
                    </div>
                    <div className="row fieldset">
                      <div className="col-4 text-right">
                        <label className="fieldset-label">
                          Business Tagline
                        </label>
                      </div>
                      <div className="col-8">
                        <input
                          value={this.props.businessTagline || ""}
                          onChange={(e) =>
                            this.props.onInputChange(
                              "businessTagline",
                              e.target.value
                            )
                          }
                          className="input-text"
                          type="text"
                          placeholder="Business Tagline"
                        />
                      </div>
                    </div>
                    <div className="row fieldset">
                      <div className="col-4 text-right">
                        <label className="fieldset-label">
                          Affiliated Accounting Body Name
                        </label>
                      </div>
                      <div className="col-8">
                        <input
                          value={this.props.affiliatedAccountingBodyName || ""}
                          onChange={(e) =>
                            this.props.onInputChange(
                              "affiliatedAccountingBodyName",
                              e.target.value
                            )
                          }
                          className="input-text"
                          type="text"
                          placeholder="Affiliated Accounting Body Name"
                        />
                        {/* {this.validator.message('affiliatedAccountingBodyName', this.props.affiliatedAccountingBodyName, '_required', { className: 'text-danger' })} */}
                      </div>
                    </div>
                    <div className="row fieldset">
                      <div className="col-4 text-right">
                        <label className="fieldset-label">
                          Website of Affiliated Accounting Body
                        </label>
                      </div>
                      <div className="col-8">
                        <input
                          value={
                            this.props.websiteOfAffiliatedAccountingBody || ""
                          }
                          onChange={(e) =>
                            this.props.onInputChange(
                              "websiteOfAffiliatedAccountingBody",
                              e.target.value
                            )
                          }
                          className="input-text"
                          type="text"
                          placeholder="Website of Affiliated Accounting Body"
                        />
                      </div>
                    </div>
                  </TabPane>

                  <TabPane tabId={++activeStepCount} className="p-0">
                    <div className="container p-0">
                      <div className="table-rep-plugin">
                        <div className="table-responsive mb-0" data-pattern="priority-columns">
                          <table className="table table-striped pricing-table">
                            <tbody>
                            <tr>
                              <td width="30%" className="pricing-table-text" style={{verticalAlign: 'middle', padding: '10px'}}>
                                <Link to="/">
                                  <img src={logoDark} height="50" alt="logo"/>
                                </Link>
                              </td>
                              {this.props.subscriptionPackages.map((item, ind) => (
                                <td width="17.5%" style={{whiteSpace: 'nowrap'}} >
                                  <div className="pricing-table-item pachd1" style={{background: ind % 2 === 0 ? '#0598ce' : '#00AFEF'}}>
                                    <div className="pricing-table-item-head">
                                      <p>{item.name}</p>
                                    </div>
                                  </div>
                                </td>
                              ))}
                            </tr>
                            <tr className="pricing-table-list">
                              <td>&nbsp;</td>
                              {this.props.subscriptionPackages.map((item, ind) => (
                                <td style={{background: ind % 2 === 0 ? '#f3f3f3' : '#ffffff'}}>&nbsp;</td>
                              ))}
                            </tr>
                            <tr className="pricing-table-list">
                              <td>Prepare Proposal</td>
                              {this.props.subscriptionPackages.map((item, ind) => (
                                item.prepare_quote ? <td style={{background: ind % 2 === 0 ? '#f3f3f3' : '#ffffff'}}><span className="fa fa-check"></span></td> : <td style={{background: ind % 2 === 0 ? '#f3f3f3' : '#ffffff'}}><span className="fa fa-times"></span></td>
                              ))}
                            </tr>
                            <tr className="pricing-table-list">
                              <td>Prepare Engagement Letter</td>
                              {this.props.subscriptionPackages.map((item, ind) => (
                                item.prepare_contract ? <td style={{background: ind % 2 === 0 ? '#f3f3f3' : '#ffffff'}}><span className="fa fa-check"></span></td> : <td style={{background: ind % 2 === 0 ? '#f3f3f3' : '#ffffff'}}><span className="fa fa-times"></span></td>
                              ))}
                            </tr>
                            <tr className="pricing-table-list">
                              <td>Send Proposal</td>
                              {this.props.subscriptionPackages.map((item, ind) => (
                                item.send_quote ? <td style={{background: ind % 2 === 0 ? '#f3f3f3' : '#ffffff'}}><span className="fa fa-check"></span></td> : <td style={{background: ind % 2 === 0 ? '#f3f3f3' : '#ffffff'}}><span className="fa fa-times"></span></td>
                              ))}
                            </tr>
                            <tr className="pricing-table-list">
                              <td>Send Engagement Letter</td>
                              {this.props.subscriptionPackages.map((item, ind) => (
                                item.send_contract ? <td style={{background: ind % 2 === 0 ? '#f3f3f3' : '#ffffff'}}><span className="fa fa-check"></span></td> : <td style={{background: ind % 2 === 0 ? '#f3f3f3' : '#ffffff'}}><span className="fa fa-times"></span></td>
                              ))}
                            </tr>
                            <tr className="pricing-table-list">
                              <td>Digitally Sign the Engagement Letter</td>
                              {this.props.subscriptionPackages.map((item, ind) => (
                                item.sign_contract ? <td style={{background: ind % 2 === 0 ? '#f3f3f3' : '#ffffff'}}><span className="fa fa-check"></span></td> : <td style={{background: ind % 2 === 0 ? '#f3f3f3' : '#ffffff'}}><span className="fa fa-times"></span></td>
                              ))}
                            </tr>
                            <tr className="pricing-table-list">
                              <td>Esignatures per month</td>
                              {this.props.subscriptionPackages.map((item, ind) => (
                                <td style={{background: ind % 2 === 0 ? '#f3f3f3' : '#ffffff'}}>{item.e_sign_per_month ?? 0}</td>
                              ))}
                            </tr>
                            <tr className="pricing-table-list">
                              <td>Fee per month</td>
                              {this.props.subscriptionPackages.map((item, ind) => (
                                <td style={{background: ind % 2 === 0 ? '#f3f3f3' : '#ffffff'}}>
                                  {/*{this.getSubscriptionPackagePriceValue(item).isDiscounted ?*/}
                                  {/*  <>*/}
                                  {/*    <p style={{textDecoration: 'line-through', color: 'red', marginBottom: '5px'}}>£ {item.fee_per_month ?? 0}</p>*/}
                                  {/*    <p style={{color: 'darkseagreen', marginBottom: '5px'}}>£ {this.getSubscriptionPackagePriceValue(item).month ?? 0}</p>*/}
                                  {/*  </> :*/}
                                  {/*  <p>£ {this.getSubscriptionPackagePriceValue(item).month ?? 0}</p>*/}
                                  {/*}*/}
                                  <p>£ {this.getSubscriptionPackagePriceValue(item, true).month ?? 0}</p>
                                </td>
                              ))}
                            </tr>
                            <tr className="pricing-table-list">
                              <td>Fee per Year</td>
                              {this.props.subscriptionPackages.map((item, ind) => (
                                <td style={{background: ind % 2 === 0 ? '#f3f3f3' : '#ffffff'}}>
                                  {/*{this.getSubscriptionPackagePriceValue(item).isDiscounted ?*/}
                                  {/*  <>*/}
                                  {/*    <p style={{textDecoration: 'line-through', color: 'red', marginBottom: '5px'}}>£ {item.fee_per_year ?? 0}</p>*/}
                                  {/*    <p style={{color: 'darkseagreen', marginBottom: '5px'}}>£ {this.getSubscriptionPackagePriceValue(item).year ?? 0}</p>*/}
                                  {/*  </> :*/}
                                  {/*  <p>£ {this.getSubscriptionPackagePriceValue(item).year ?? 0}</p>*/}
                                  {/*}*/}
                                  <p>£ {this.getSubscriptionPackagePriceValue(item, true).year ?? 0}</p>
                                </td>
                              ))}
                            </tr>
                            <tr className="pricing-table-list">
                              <td>Month Offer</td>
                              {this.props.subscriptionPackages.map((item, ind) => (
                                <td style={{background: ind % 2 === 0 ? '#f3f3f3' : '#ffffff'}}>
                                  {item.offers.month_discount?.enabled ?
                                    <>
                                      <p style={{color: 'dodgerblue', marginBottom: '1px', whiteSpace: 'nowrap'}}>
                                        <Checkbox disabled={this.state.discounted_price_enabled?._id === item._id || this.state.discount_enabled?._id === item._id} checked={this.state.in_price_of_month_offer_enabled?._id === item._id} onChange={e => {
                                          this.setState({in_price_of_month_offer_enabled: e.target.checked ? item : false});
                                        }}/>
                                        Get {item.offers.month_discount?.value.got_months} months
                                      </p>
                                      <p style={{color: 'dodgerblue', marginBottom: 0}}>in the price of {item.offers.month_discount?.value.in_price_of_month} months</p>
                                    </> :
                                    null
                                  }
                                </td>
                              ))}
                            </tr>
                            {/*<tr className="pricing-table-list">*/}
                            {/*  <td>Discounted Price For Month</td>*/}
                            {/*  {this.props.subscriptionPackages.map((item, ind) => (*/}
                            {/*    <td style={{background: ind % 2 === 0 ? '#f3f3f3' : '#ffffff'}}>*/}
                            {/*      {item.offers.discounted_price_for_month?.enabled ?*/}
                            {/*        <>*/}
                            {/*          <p style={{color: 'dodgerblue', marginBottom: '1px', whiteSpace: 'nowrap'}}>*/}
                            {/*            <Checkbox checked={this.state.discounted_price_for_month_enabled?._id === item._id} onChange={e => {*/}
                            {/*              this.setState({discounted_price_for_month_enabled: e.target.checked ? item : false});*/}
                            {/*            }}/>*/}
                            {/*            Get {item.offers.discounted_price_for_month?.value.discounted_price_for_month_count} months*/}
                            {/*          </p>*/}
                            {/*          <p style={{color: 'dodgerblue', marginBottom: 0}}>in {item.offers.discounted_price_for_month?.value.discounted_price_for_month_price}</p>*/}
                            {/*        </> :*/}
                            {/*        null*/}
                            {/*      }*/}
                            {/*    </td>*/}
                            {/*  ))}*/}
                            {/*</tr>*/}
                            <tr className="pricing-table-list">
                              <td>Discounted Price</td>
                              {this.props.subscriptionPackages.map((item, ind) => (
                                <td style={{background: ind % 2 === 0 ? '#f3f3f3' : '#ffffff'}}>
                                  {item.offers.discounted_price?.enabled ?
                                    <>
                                      <p style={{color: 'dodgerblue', marginBottom: '1px', whiteSpace: 'nowrap'}}>
                                        <Checkbox disabled={this.state.discount_enabled?._id === item._id || this.state.in_price_of_month_offer_enabled?._id === item._id} checked={this.state.discounted_price_enabled?._id === item._id} onChange={e => {
                                          this.setState({discounted_price_enabled: e.target.checked ? item : false});
                                        }}/>
                                        Get Package at <br />Discounted Price {formatCurrency(item.offers.discounted_price?.value)}
                                      </p>
                                    </> :
                                    null
                                  }
                                </td>
                              ))}
                            </tr><tr className="pricing-table-list">
                              <td>Discount</td>
                              {this.props.subscriptionPackages.map((item, ind) => (
                                <td style={{background: ind % 2 === 0 ? '#f3f3f3' : '#ffffff'}}>
                                  {item.offers.discount?.enabled ?
                                    <>
                                      <p style={{color: 'dodgerblue', marginBottom: '1px', whiteSpace: 'nowrap'}}>
                                        <Checkbox disabled={this.state.discounted_price_enabled?._id === item._id || this.state.in_price_of_month_offer_enabled?._id === item._id} checked={this.state.discount_enabled?._id === item._id} onChange={e => {
                                          this.setState({discount_enabled: e.target.checked ? item : false});
                                        }}/>
                                        {item.offers.discount?.value}% Off
                                      </p>
                                    </> :
                                    null
                                  }
                                </td>
                              ))}
                            </tr>
                            <tr className="pricing-table-list">
                              <td>First Months Free</td>
                              {this.props.subscriptionPackages.map((item, ind) => (
                                <td style={{background: ind % 2 === 0 ? '#f3f3f3' : '#ffffff'}}>
                                  {item.offers.months_free?.enabled ?
                                    <>
                                      <p style={{color: 'dodgerblue', marginBottom: '1px', whiteSpace: 'nowrap'}}>
                                        First {item.offers.months_free?.value} months free
                                      </p>
                                    </> :
                                    null
                                  }
                                </td>
                              ))}
                            </tr>
                            <tr className="pricing-table-list">
                              <td>&nbsp;</td>
                              {this.props.subscriptionPackages.map((item, ind) => (
                                <td style={{background: ind % 2 === 0 ? '#f3f3f3' : '#ffffff', whiteSpace: 'nowrap'}}>
                                  <button type="button" className="btn btn-free btn-block" data-toggle="modal"
                                          data-target="#exampleModalCenter" onClick={() => {
                                            console.log('this.getSubscriptionPackagePriceValue(item) - ', this.getSubscriptionPackagePriceValue(item, true));
                                    this.handlePackageSelection(item._id, item.is_default, item.name, this.getSubscriptionPackagePriceValue(item).year, this.props.interval, item.fee_per_year)
                                  }}>
                                    Select Package
                                  </button>
                                </td>
                              ))}
                              {/*<td>*/}
                              {/*  <button type="button" className="btn btn-statup btn-block" data-toggle="modal"*/}
                              {/*          data-target="#exampleModalCenter">*/}
                              {/*    Select Package*/}
                              {/*  </button>*/}

                              {/*</td>*/}
                              {/*<td>*/}
                              {/*  <button type="button" className="btn btn-small btn-block" data-toggle="modal"*/}
                              {/*          data-target="#exampleModalCenter">*/}
                              {/*    Select Package*/}
                              {/*  </button>*/}
                              {/*</td>*/}
                              {/*<td>*/}
                              {/*  <button type="button" className="btn btn-medium btn-block" data-toggle="modal"*/}
                              {/*          data-target="#exampleModalCenter">*/}
                              {/*    Select Package*/}
                              {/*  </button>*/}
                              {/*</td>*/}
                            </tr>
                            <tr className="pricing-table-list">
                              <td>Frequency</td>
                              <td colSpan={this.props.subscriptionPackages?.length}>
                                <RadioGroup
                                  row
                                  aria-labelledby="demo-row-radio-buttons-group-label"
                                  className={"interval-radio-group"}
                                  name="interval-radio-group"
                                  onChange={e => this.props.onInputChange('interval', e.target.value)}
                                  value={this.props.interval}
                                >
                                  <FormControlLabel disabled={this.state.in_price_of_month_offer_enabled?._id} value="month" control={<Radio />} label="Monthly" />
                                  <FormControlLabel disabled={this.state.in_price_of_month_offer_enabled?._id} value="quarter" control={<Radio />} label="Quarterly" />
                                  <FormControlLabel disabled={this.state.in_price_of_month_offer_enabled?._id} value="halfYear" control={<Radio />} label="Half yearly" />
                                  <FormControlLabel disabled={this.state.in_price_of_month_offer_enabled?._id} value="year" control={<Radio />} label="Yearly" />
                                </RadioGroup>
                              </td>
                            </tr>
                            <tr className="pricing-table-list">
                              <td className="lastbox1">&nbsp;</td>
                              {this.props.subscriptionPackages.map(item => (
                                <td>&nbsp;</td>
                              ))}
                              {/*<td>&nbsp;</td>*/}
                              {/*<td>&nbsp;</td>*/}
                              {/*<td className="lastbox">&nbsp;</td>*/}
                            </tr>
                            </tbody>
                          </table>

                        </div>
                      </div>
                    </div>
                  </TabPane>
                </TabContent>
                <div className="separator mt-3 mb-3" />
                <div className="row fieldset">
                  <div className="col-12 text-right">
                    <button onClick={this.cancel} className="btn">
                      Cancel
                    </button>
                    {this.props.activeStep > 1 && (
                      <button
                        onClick={this.backStep}
                        className="btn create-item ml-2"
                      >
                        Back
                      </button>
                    )}
                    {this.props.activeStep <
                      eval(this.props.businessType + "Steps").length && (
                      <button
                        onClick={this.nextStep}
                        className="btn create-item ml-2"
                      >
                        Next
                      </button>
                    )}
                    {/*{this.props.activeStep === 4 && (*/}
                    {/*  <button*/}
                    {/*    className="btn create-item ml-2"*/}
                    {/*    onClick={this.createNewOrganisation}*/}
                    {/*  >*/}
                    {/*    Create Practice*/}
                    {/*  </button>*/}
                    {/*)}*/}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ImageUploadModal
          uploadImage={this.getUploadImageFunction()}
          type={this.props.imageUploadModalType}
          showImageUploadModal={this.props.showImageUploadModal}
          setShowImageUploadModal={this.props.setShowImageUploadModal}
          allowedExtentions={["jpg", "png", "jpeg", "svg"]}
        />
        <SetupPaymentModal
          monthOffer={this.state.in_price_of_month_offer_enabled?._id === this.state.selectedPlan?.id ? this.state.in_price_of_month_offer_enabled : null}
          discountedOffer={this.state.discounted_price_for_month_enabled?._id === this.state.selectedPlan?.id ? this.state.discounted_price_for_month_enabled : null}
          discountedPriceOffer={this.state.discounted_price_enabled?._id === this.state.selectedPlan?.id ? this.state.discounted_price_enabled : null}
          discount={this.state.discount_enabled?._id === this.state.selectedPlan?.id ? this.state.discount_enabled : null}
          selectedPlan={this.state.selectedPlan}
          cardErrorMessage={this.state.cardErrorMessage}
          onChange={this.props.onInputChange}
          parentProps={this.props}
          handlePay={this.handlePay.bind(this)}
          open={this.state.openSetupPaymentModal}
          setOpen={(e) => {this.setState({openSetupPaymentModal: e})}}
        />
        {this.props.loading && <Loader />}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  const {subscriptionPackages, loading} = state.SubscriptionPackage;
  let subPackages = subscriptionPackages.filter(c => c.is_active);
  const {logLoading} = state.Login;
  const {role} = state.Dashboard;
  return {...state.Organisation, role, logLoading, subPackagesLoading: loading, subscriptionPackages: subPackages};
}


export default withRouter(
  connect(mapStateToProps, {
    onInputChange,
    setActiveStep,
    addPartner,
    deletePartner,
    addOfficer,
    deleteOfficer,
    companySearch,
    selectCompanySearchItem,
    createOrganisation,
    setShowImageUploadModal,
    getSubscriptionPackages,
    uploadLogo,
    uploadSignature,
    resetOrganisationForm,
    switchOrganisation,
    getDashboard
  })(CreateNewOrganisation)
);
