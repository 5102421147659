
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import logoDark from "../../assets/images/outbooks.png";
import {getFirebaseBackend} from "../../helpers/authUtils";
import {
    getMyOrganisation,
    onInputChange,
    setShowUpdatePaymentMethodSuccessModal,
    updatePaymentMethod
} from "../../store/organisation/actions";
import {
    onInputChange as onSubscribeInputChange, setShowCancelSubscriptionModal,
} from "../../store/subscription/actions";
import Loader from "../../components/Loader";
import {debounce, formatCurrencyOfYourChoice, formatDateinDDMMYY} from "../../Utils";
import SweetAlert from "react-bootstrap-sweetalert";
import ChooseSubscriptionPackage from "../../components/ChooseSubscriptionPackage";
import {
    handleCard, handleCardSuccess,
    setShowChangeSubscriptionSuccessModal,
    cancelSubscription,
    setShowHandleCardSuccessModal
} from "../../store/subscription/actions";
import {CardElement} from "@stripe/react-stripe-js";
import {Row} from "reactstrap";
import {SetupPaymentModal} from "../../components/SetupPaymentModal";
import {getInvoices, searchInvoices} from "../../store/subscriptionPackage/actions";


class Billing extends Component {
    constructor(props) {
        super(props);
        this.func = debounce(this.props.searchInvoices, 800);
        const fireBaseBackend = getFirebaseBackend();
        this.organisationId = fireBaseBackend.getOrganistionId();
        this.state = {
            open: false,
            inputCardStatus: null,
            cardErrorMessage: null,
            cardError: null,
            addPaymentWarning: false,
            openSetupPaymentModal: false,
            deleteCard: null,
            changedSubscriptionSuccessModal: null
        }
    }
    componentDidMount() {
        this.props.getMyOrganisation();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.handleCardAction !== this.props.handleCardAction && this.props.handleCardAction) {
            console.log('showHandleCardSuccessModal - ', this.props.handleCardAction);

            this.props.stripe.confirmSetup({
                clientSecret: this.props.handleCardAction,
                confirmParams: {
                    // Return URL where the customer should be redirected after the SetupIntent is confirmed.
                    return_url: 'http://localhost:3000/Subscription',
                },
                redirect: 'if_required'
            })
              .then(result => {
                  console.log('result - ', result);
                  if (result.error) {
                      // Inform the customer that there was an error.
                      this.props.onSubscribeInputChange('loading', false);
                      return this.setState({cardError: result.error.message});
                  }
                  this.props.handleCardSuccess();
                  // this.props.getMyOrganisation();
              });
        }
        if(prevProps.handlePaymentIntent !== this.props.handlePaymentIntent && this.props.handlePaymentIntent) {
            console.log('showHandleCardSuccessModal - ', this.props.handlePaymentIntent);

            this.props.stripe.confirmPayment({
                clientSecret: this.props.handlePaymentIntent,
                confirmParams: {
                    // Return URL where the customer should be redirected after the SetupIntent is confirmed.
                    return_url: 'http://localhost:3000/Subscription',
                },
                redirect: 'if_required'
            })
              .then(result => {
                  console.log('result - ', result);
                  this.props.onSubscribeInputChange('loading', false);
                  if (result.error) {
                      // Inform the customer that there was an error.
                      return this.setState({cardError: result.error.message});
                  }
                  this.setState({changedSubscriptionSuccessModal: true});
                  // this.props.getMyOrganisation();
              });
        }
        if(prevProps.handleSetupIntent !== this.props.handleSetupIntent && this.props.handleSetupIntent) {
            console.log('handleSetupIntent - ', this.props.handleSetupIntent);

            this.props.stripe.confirmSetup({
                clientSecret: this.props.handleSetupIntent,
                confirmParams: {
                    // Return URL where the customer should be redirected after the SetupIntent is confirmed.
                    return_url: 'http://localhost:3000/Subscription',
                },
                redirect: 'if_required'
            })
              .then(result => {
                  console.log('result - ', result);
                  this.props.onSubscribeInputChange('loading', false);
                  if (result.error) {
                      // Inform the customer that there was an error.
                      return this.setState({cardError: result.error.message});
                  }
                  this.setState({changedSubscriptionSuccessModal: true});
                  // this.props.getMyOrganisation();
              });
        }
        if(prevProps.customerId !== this.props.customerId && this.props.customerId) {
            this.props.searchInvoices({limit: 10, page_num: 0, customer: this.props.customerId});
        }
    }

    filterInvoices(e) {
        console.log('this.props.customer - ', this.props.customer);
        if(!this.props.customer?.invoice_prefix) return;
        this.func({limit: 10, page_num: 0, customer: this.props.customerId, number: e.target.value?.length === 0 ? undefined : this.props.customer?.invoice_prefix + '-' + e.target.value});
    }

    handleUpdatePaymentMethod() {
        const data = {
            name: this.props.billing_name,
            email: this.props.billing_email,
            address: this.props.billing_address,
            paymentMethodId: this.props.paymentMethods[0].id
        }

        this.props.updatePaymentMethod(data);
    }

    handleChangePlanButton(e) {
        e.preventDefault();

        this.setState({open: true});
    }

    handleCancelSubscriptionButton(e) {
        e.preventDefault();
        this.setState({cancelSubscriptionModal: true});
    }

    handleEditCardClick(e) {
        e.preventDefault();
        this.setState({inputCardStatus: 'edit'});
    }

    handleAddCardClick(e) {
        e.preventDefault();
        this.setState({inputCardStatus: 'new'});
    }

    async handleSaveCard(e) {
        e.preventDefault();
        this.setState({cardErrorMessage: null});
        this.props.onSubscribeInputChange('loading', true);
        const result = await this.props.stripe.createPaymentMethod({
            type: 'card',
            card: this.props.elements?.getElement(CardElement),
            billing_details: {
                name: this.props.billing_name,
                email: this.props.billing_email,
                address: this.props.billing_address
            }
        })

        console.log('result - ', result);

        if(result.error) {
            this.props.onSubscribeInputChange('loading', false);
            return this.setState({cardErrorMessage: result.error.message});
        }

        this.props.handleCard({
            customer: this.props.customerId,
            old_pm_id: this.props.paymentMethods[0]?.id,
            new_pm_id: result.paymentMethod.id
        });


        this.setState({inputCardStatus: null});
    }

    render() {
        const paymentMethods = this.props.paymentMethods?.map(paymentMethod => {
            const card = paymentMethod?.card;
            let obj = {value: 'XXXX XXXX XXXX XXXX', card_type: 'VISA'}
            if(card) {
                const cardNumber = card.last4;
                obj = {
                    value: 'XXXX XXXX XXXX ' + cardNumber,
                    last4: card.last4,
                    card_type: card.networks.available[0],
                    id: paymentMethod.id
                };
            }
            return obj
        }) ?? [];

        const hasNoPaymentMethod = !paymentMethods || paymentMethods.length === 0;

        const invoiceList = this.props.invoices?.data.map(item => ({
            number: item.number,
            date: item.created,
            description: item.description,
            amount: item.total / 100,
            currency: item.currency,
            paid: item.paid,
            download: item.invoice_pdf
        }));

        let interval = 'Yearly';
        console.log('this.props.subscription - ', this.props.subscription);
        if(this.props.subscription?.plan) {
            const plan = this.props.subscription?.plan;
            if(plan.interval === 'month') {
                if(plan.interval_count === 6) interval = 'Half Yearly';
                if(plan.interval_count === 3) interval = 'Quarterly';
                if(plan.interval_count === 1) interval = 'Monthly';
            }
        } else {
            interval = null;
        }

        return (
            <React.Fragment>
                {(this.props.loading || this.props.subLoading || this.props.orgLoading) && <Loader />}
                <div className="page-info-header">
                    <div className="container-fluid">
                        <div className="page-display-title">Subscription Details</div>
                    </div>
                </div>
                <div className="container-fluid">
                <div style={{marginTop:"75px",marginBottom:"95px"}}>
                    <div className="row">
                        <div className="col-md-4">
                            <div className="box-typ1">
                                <div class="box-hd">
                                    <h2>Current Plan Details</h2>
                                </div>
                                <div className="box-content">
                                    <table className="table pacg-detail">
                                        <tr>
                                            <td>Plan:</td>
                                            <td>{this.props.subscriptionPackage?.plan?.name}</td>
                                        </tr>
                                        <tr>
                                            <td>E-sign</td>
                                            <td>{this.props.e_sign_per_month}</td>
                                        </tr>
                                        <tr>
                                            <td>Payment Mode:</td>
                                            <td>{interval ?? 'N/A'}</td>
                                        </tr>
                                        <tr>
                                            <td>Renewal Date:</td>
                                            <td>{this.props.subscriptionPackage?.renewal_date ? formatDateinDDMMYY(new Date(this.props.subscriptionPackage?.renewal_date)) : 'N/A'}</td>
                                        </tr>
                                    </table>
                                    <div className="box-btn">
                                        <a onClick={this.handleChangePlanButton.bind(this)} href=""
                                           className="btn btn-primary btn-sm mr-3">Change Plan</a>
                                        {!this.props.subscriptionPackage?.plan?.is_default && <a href="" onClick={this.handleCancelSubscriptionButton.bind(this)}
                                            className="btn btn-secondary btn-sm">Cancel Subscription</a>}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-8">
                            <div className="box-typ1">
                                <div class="box-hd">
                                    <h2>Billing Credit/Debit Card</h2>
                                </div>
                                <div className="box-typ2-content">
                                    <div className="card-box">
                                        {paymentMethods.map(paymentMethod => (
                                          <div className="form-group mt-2 ">
                                              <div className="input-group">
                                                  <input className="form-control" type="text" disabled={true}
                                                         contentEditable={false} value={paymentMethod.value}
                                                         placeholder="0000 0000 0000 0000" autoComplete="email"/>
                                                  <div className="input-group-append">
                                                    <span className="input-group-text">
                                                        {/*<i className={paymentMethod.card_type?.toLowerCase() === 'mastercard' ? "fa fa-cc-mastercard" : paymentMethod.card_type?.toLowerCase() === 'visa' ? "fa fa-cc-visa" : "fa fa-credit-card"}></i>*/}
                                                        <i className="fa fa-credit-card"></i>
                                                    </span>
                                                  </div>
                                                  {paymentMethods.length !== 1 && <a href="#" className="text-danger trash-btn" onClick={e => {
                                                      e.preventDefault();
                                                      this.setState({
                                                          deleteCard: {
                                                              last4: paymentMethod.last4,
                                                              id: paymentMethod.id
                                                          }
                                                      })
                                                  }}>
                                                      <i className="fa fa-trash"></i>
                                                  </a>}
                                              </div>
                                          </div>
                                        ))}
                                        {this.state.inputCardStatus && (
                                          <div className="form-group mt-2 ">
                                              <div className="input-group stripe-card-input" style={{
                                                  border: '1px solid lightgrey',
                                                  background: 'white',
                                                  borderRadius: '4px'
                                              }}>
                                                  <CardElement options={{
                                                      style: {width: '100%', margin: '20px'}
                                                  }} onChange={() => {
                                                      this.setState({cardErrorMessage: null})
                                                  }} />
                                              </div>
                                          </div>
                                        )}
                                        {this.state.cardErrorMessage && <Row className="align-items-center stripe-card-input">
                                            <p style={{padding: '0 20px', color: 'red'}}>{this.state.cardErrorMessage}</p>
                                        </Row>}
                                        <div className="card-btn">
                                            {this.state.inputCardStatus ? (
                                              <>
                                                  <a href="#" onClick={this.handleSaveCard.bind(this)}
                                                     className="btn btn-primary btn-sm mr-3"><i
                                                    className="fa fa-edit"></i>Save</a>
                                                  <a href="#" onClick={() => this.setState({inputCardStatus: null})}
                                                     className="btn btn-secondary btn-sm "><i
                                                    className="fa fa-edit"></i>Cancel</a>
                                              </>
                                            ) : <a href="#" onClick={this.handleEditCardClick.bind(this)} className="btn btn-secondary btn-sm ml-3"><i
                                              className={this.props.paymentMethods?.length > 0 ? "fa fa-edit" : "fa fa-plus"}></i>{this.props.paymentMethods?.length > 0 ? 'Edit' : 'Add New'}</a>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="box-typ1">
                                <div className="box-hd">
                                    <h2>Billing Details</h2>
                                </div>
                                <div className="box-typ2-content row">
                                    <div className="col-md-6">
                                        <div className="form-group row">
                                            <div className="col-md-4">
                                                <label className="control-label">Company Name<span className="text-danger pl-1">*</span></label>
                                            </div>
                                            <div className="col-md-8">
                                                <input value={this.props.billing_name} onChange={(e) => {
                                                    this.props.onInputChange('billing_name', e.target.value)
                                                }} type="text" className="form-control" />
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <div className="col-md-4">
                                                <label className="control-label">Email<span className="text-danger pl-1">*</span></label>
                                            </div>
                                            <div className="col-md-8">
                                                <input value={this.props.billing_email} onChange={(e) => {
                                                    this.props.onInputChange('billing_email', e.target.value)
                                                }} type="text" className="form-control" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group row">
                                            <div className="col-md-4">
                                                <label className="control-label">Address<span className="text-danger pl-1">*</span></label>
                                            </div>
                                            <div className="col-md-8">
                                                <textarea value={this.props.billing_address} onChange={(e) => {
                                                    this.props.onInputChange('billing_address', e.target.value)
                                                }} className="form-control"></textarea>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-12 text-right">
                                        <a href="#" onClick={this.handleUpdatePaymentMethod.bind(this)} className="btn btn-primary btn-sm">Save</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="box-typ1">
                                <div className="box-hd">
                                    <h2>Invoices</h2>
                                </div>
                                <div className="box-typ2-content row">
                                    <div className="col-md-3">
                                        <div className="form-group position-relative">
                                            <input type="text" onChange={this.filterInvoices.bind(this)} placeholder={"Search Invoice"} className="form-control" />
                                            <i className="fa fa-search serchicon"></i>
                                        </div>
                                    </div>
                                    <div class="col-md-12">
                                        <div className="table-responsive">
                                            <table className="table table-striped ">
                                                <thead className="thead-dark ">
                                                    <tr>
                                                        <th scope="col">Invoice Number</th>
                                                        <th scope="col">Invoice Date</th>
                                                        <th scope="col">Description</th>
                                                        <th scope="col" className="text-right">Amount</th>
                                                        <th scope="col">Paid / Unpaid</th>
                                                        <th scope="col" className="text-center">Download Invoice</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {this.props.subInvoices?.map(item => (
                                                      <tr>
                                                          <td>{item.number}</td>
                                                          <td>{new Date(item.date).toLocaleDateString()}</td>
                                                          <td>{item.description}</td>
                                                          <td className="text-right">{formatCurrencyOfYourChoice(item.currency)(item.amount)}</td>
                                                          <td>{item.paid ? 'Paid' : 'Unpaid'}</td>
                                                          <td className="text-center">
                                                              <a href={item.download} className="btn btn-secondary btn-xs "><i className="fa fa-download"></i></a>
                                                          </td>
                                                      </tr>
                                                    ))}
                                                </tbody>
                                            </table>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {<ChooseSubscriptionPackage paymentMethods={paymentMethods} stripe={this.props.stripe} elements={this.props.elements} hasNoPaymentMethod={hasNoPaymentMethod} open={this.state.open} setOpen={(open) => this.setState({open})} />}
                    {this.props.showUpdatePaymentMethodSuccessModal && <SweetAlert
                      title=""
                      success
                      confirmBtnBsStyle="success"
                      onConfirm={() => {
                          this.props.setShowUpdatePaymentMethodSuccessModal(false);
                      }}
                    >
                        <div className="mb-2 success-alert-message">Payment Method has been updated successfully!</div>
                    </SweetAlert>}
                    {this.props.showChangeSubscriptionSuccessModal && <SweetAlert
                      title=""
                      success
                      confirmBtnBsStyle="success"
                      onConfirm={() => {
                          this.props.setShowChangeSubscriptionSuccessModal(false);
                          this.props.getMyOrganisation();
                          this.props.searchInvoices({limit: 10, page_num: 0, customer: this.props.customerId});
                      }}
                    >
                        <div className="mb-2 success-alert-message">Subscription Plan has been changed successfully!</div>
                    </SweetAlert>}
                    {this.props.showHandleCardSuccessModal && <SweetAlert
                      title=""
                      success
                      confirmBtnBsStyle="success"
                      onConfirm={() => {
                          this.props.setShowHandleCardSuccessModal(false);
                          this.props.getMyOrganisation();
                          this.props.searchInvoices({limit: 10, page_num: 0, customer: this.props.customerId});
                      }}
                    >
                        <div className="mb-2 success-alert-message">Credit/Debit Card has been updated successfully!</div>
                    </SweetAlert>}
                    {this.props.showCancelSubscriptionSuccessModal && <SweetAlert
                      title=""
                      success
                      confirmBtnBsStyle="success"
                      onConfirm={() => {
                          this.props.setShowCancelSubscriptionModal(false);
                          this.props.getMyOrganisation();
                          this.props.searchInvoices({limit: 10, page_num: 0, customer: this.props.customerId});
                          window.location.reload();
                      }}
                    >
                        <div className="mb-2 success-alert-message">Subscription has been canceled successfully!</div>
                    </SweetAlert>}
                    {this.state.changedSubscriptionSuccessModal && <SweetAlert
                      title=""
                      success
                      confirmBtnBsStyle="success"
                      onConfirm={() => {
                          this.setState({changedSubscriptionSuccessModal: null})
                          this.props.getMyOrganisation();
                          this.props.searchInvoices({limit: 10, page_num: 0, customer: this.props.customerId});
                      }}
                    >
                        <div className="mb-2 success-alert-message">Subscription has been changed successfully!</div>
                    </SweetAlert>}
                    {this.state.cardError && <SweetAlert
                      title=""
                      error
                      confirmBtnBsStyle="error"
                      onConfirm={() => {
                          this.setState({cardError: null})
                      }}
                    >
                        <div className="mb-2 success-alert-message">{this.state.cardError}</div>
                    </SweetAlert>}
                    {this.state.deleteCard && <SweetAlert
                      title=""
                      error
                      showCancel
                      showConfirm
                      confirmBtnBsStyle="error"
                      onCancel={() => {
                          this.setState({deleteCard: null})
                      }}
                      onConfirm={() => {
                          console.log('confirming the delete');
                          this.props.handleCard({
                              delete: true,
                              customer: this.props.customerId,
                              old_pm_id: this.state.deleteCard.id
                          });
                          this.setState({deleteCard: null})
                      }}
                    >
                        <div className="mb-2 success-alert-message">Are you sure you want to delete the card ending with {this.state.deleteCard.last4}.</div>
                    </SweetAlert>}
                    {this.state.cancelSubscriptionModal && <SweetAlert
                      title=""
                      error
                      showConfirm
                      showCancel
                      confirmBtnText="Yes"
                      cancelBtnText="No"
                      confirmBtnBsStyle="success"
                      reverseButtons
                      onCancel={() => {
                          this.setState({cancelSubscriptionModal: null})
                      }}
                      onConfirm={() => {
                          console.log('confirming the delete');
                          this.props.cancelSubscription({
                              customer: this.props.customerId
                          });
                          this.setState({cancelSubscriptionModal: null})
                      }}
                    >
                        <div className="mb-2 success-alert-message">Are you sure you want to cancel your subscription?</div>
                    </SweetAlert>}
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    const { users, pagination, loading } = state.User;
    const {invoices: subInvoices} = state.SubscriptionPackage;
    const { showChangeSubscriptionSuccessModal, loading: subLoading, handleSetupIntent, handlePaymentIntent, showCancelSubscriptionSuccessModal, showHandleCardSuccessModal, handleCardAction } = state.Subscription;
    const { subscriptionPackage, customer, paymentMethods, invoices, loading: orgLoading, e_sign_per_month, left_signed_contracts, ...orgs } = state.Organisation;
    return { users, pagination, customer, showChangeSubscriptionSuccessModal, subInvoices, e_sign_per_month, left_signed_contracts, handleSetupIntent, handlePaymentIntent, showCancelSubscriptionSuccessModal, showHandleCardSuccessModal, handleCardAction, paymentMethods, invoices, subscriptionPackage, loading, subLoading, orgLoading, ...orgs }
}

export default connect(mapStateToProps, {getMyOrganisation, searchInvoices, getInvoices, cancelSubscription, setShowCancelSubscriptionModal, onSubscribeInputChange, handleCard, handleCardSuccess, setShowHandleCardSuccessModal, updatePaymentMethod, onInputChange, setShowChangeSubscriptionSuccessModal, setShowUpdatePaymentMethodSuccessModal})(Billing);
