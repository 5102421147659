export default [
  {
    title: "Dashboard",
    to: "/dashboard",
    roles: ["superadmin", "admin"],
    icon: "ion-md-analytics",
  },
  /*{
    title: "Services",
    to: "/default-services",
    roles: ["superadmin"],
    icon: "ti-home"
  },*/
  {
    title: "Organisations",
    to: "/organisations",
    roles: ["superadmin"],
    icon: "ion-md-business",
  },
  {
    title: "Users",
    to: "/users",
    roles: ["superadmin"],
    icon: "ion-ios-people",
  },
  {
    title: "Pricing",
    to: "/pricing",
    roles: ["superadmin"],
    icon: "ion-ios-cash",
  },
  {
    title: "Prospects",
    to: "/clients",
    roles: ["admin"],
    icon: "ion-md-contacts",
  },
  {
    title: "Proposals",
    to: "/quotes",
    roles: ["admin"],
    icon: "ion-ios-list-box",
  },
  {
    title: "Engagement Letter",
    to: "/contracts",
    roles: ["admin"],
    icon: "ion-ios-filing",
  },
  {
    title: "Configure",
    to: "/#",
    roles: ["admin"],
    icon: "ion-ios-construct",
    childStateName: "ServicesState",
    children: [
      {
        title: "Service Categories",
        to: "/categories",
        roles: ["admin"],
      },
      {
        title: "Services",
        to: "/services",
        roles: ["admin"],
      },
      {
        title: "Packages",
        to: "/packages",
        roles: ["admin"],
      },
      {
        title: "Global Constants",
        to: "/settings/global-constants",
        roles: ["admin"],
      },
      {
        title: "Global Pricing Drivers",
        to: "/settings/global-pricing-drivers",
        roles: ["admin"],
      },
      {
        title: "Templates",
        to: "/templates",
        roles: ["admin"],
      },
      {
        title: "Terms And Conditions",
        to: "/terms-and-conditions",
        roles: ["admin"],
      },
      {
        title: "Email Templates",
        to: "/email-template",
        roles: ["admin"],
      },
      {
        title: "Email Config",
        to: "/email-config",
        roles: ["admin"],
      },
    ],
  },
  {
    title: "Configure",
    to: "/#",
    roles: ["superadmin"],
    icon: "ion-ios-construct",
    childStateName: "PredefinedTemplatesState",
    children: [
      {
        title: "Predefined Service Categories",
        to: "/categories",
        roles: ["superadmin"],
      },
      {
        title: "Predefined Services",
        to: "/services",
        roles: ["superadmin"],
      },
      {
        title: "Predefined Packages",
        to: "/packages",
        roles: ["superadmin"],
      },
      {
        title: "Predefined Global Constants",
        to: "/settings/global-constants",
        roles: ["superadmin"],
      },
      {
        title: "Predefined Global Pricing Drivers",
        to: "/settings/global-pricing-drivers",
        roles: ["superadmin"],
      },
      {
        title: "Predefined Templates",
        to: "/templates",
        roles: ["superadmin"],
      },
      {
        title: "Predefined Terms & Conditions",
        to: "/terms-and-conditions",
        roles: ["superadmin"],
      },
      {
        title: "Predefined Email Templates",
        to: "/email-template",
        roles: ["superadmin"],
      },
    ],
  },
  {
    title: "Settings",
    to: "/settings",
    roles: ["admin"],
    icon: "ion-md-settings",
    childStateName: "SettingsState",
    children: [
      {
        title: "Update Practice Details",
        to: "/settings/organisation-details",
        roles: ["admin"],
      },
      {
        title: "Users",
        to: "/settings/users",
        roles: ["admin"],
      },
      // {
      //   title: "Global Constants",
      //   to: "/settings/global-constants",
      //   roles: ["admin"]
      // },
      // {
      //   title: "Global Pricing Drivers",
      //   to: "/settings/global-pricing-drivers",
      //   roles: ["admin"]
      // },
      {
        title: "Payment Gateways",
        to: "/settings/payment-gateways",
        roles: ["admin"],
      },
      {
        title: "Access Keys",
        to: "/settings/access-keys",
        roles: ["admin"],
      },
      {
        title: "Activity Logs",
        to: "/activity-logs",
        roles: ["admin"],
      },
      {
        title: "Pricing Settings",
        to: "/settings/pricing-settings",
        roles: ["admin"],
      },
      // {
      //   title: "Templates",
      //   to: "/templates",
      //   roles: ["admin"]
      // },
      // {
      //   title: "Terms And Conditions",
      //   to: "/terms-and-conditions",
      //   roles: ["admin"]
      // }
    ],
  },
  {
    title: "Subscription",
    to: "/Subscription",
    roles: ["superadmin"],
    icon: "ion-md-card",
    children: [
      {
        title: "Packages",
        to: "/SubscriptionPackage",
        roles: ["superadmin"],
      },
      {
        title: "Users",
        to: "/Practice",
        roles: ["superadmin"],
      },
      {
        title: "Invoices",
        to: "/Invoice",
        roles: ["superadmin"],
      },
    ],
  },
  /*{
    title: "Access Management",
    to: "#",
    roles: ["superadmin"],
    icon: "ti-home",
    childStateName: "AccessManagementState",
    children: [
      {
        title: "Role Management",
        to: "/access-management/role-management",
        roles: ["superadmin"]
      },
    ]
  }*/
];
