import React, { Component } from "react";
import { Row, Col, Card, Alert } from "reactstrap";
import { withRouter, Link } from "react-router-dom";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { connect } from "react-redux";

import Loader from "../../components/Loader";
import {
  loginUser,
  loginUserWithProvider,
  resetLoginForm,
} from "../../store/actions";
import { onInputChange } from "../../store/auth/login/actions";
// import images
import bg from "../../assets/images/bg.jpg";
import logoDark from "../../assets/images/outbooks.png";

import { getValidator } from "../../Utils";
import googleLogo from "../../assets/images/login-with-icon/google circle.png";
import microsoftLogo from "../../assets/images/login-with-icon/microsoft circle.png";
import "../../assets/scss/custom/components/_loginWithLogo.scss";

class Login extends Component {
  constructor(props) {
    super(props);
    this.validator = getValidator();
    this.handleValidSubmit = this.handleValidSubmit.bind(this);
  }
  handleValidSubmit(event, values) {
    if (this.validator.allValid()) {
      this.props.loginUser(
        this.props.email,
        this.props.password,
        this.props.history
      );
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
    event.preventDefault();
  }
  componentDidMount() {
    this.props.resetLoginForm();
  }
  componentWillUnmount() {
    this.props.resetLoginForm();
  }

  handleMicrosoftLogin = () => {
    this.props.loginUserWithProvider("microsoft", this.props.history);
  };

  handleGoogleLogin = () => {
    this.props.loginUserWithProvider("google", this.props.history);
  };

  render() {
    return (
      <React.Fragment>
        {" "}
        <div className="accountbg"></div>
        <div className="wrapper-page font-sans account-page-full">
          <Card className="shadow-none">
            <div className="card-block">
              {this.props.loading ? <Loader /> : null}
              <div className="account-box">
                <div className="card-box no-width-set shadow-none p-4">
                  <div className="p-2">
                    <div className="text-center mt-4">
                      <Link to="/">
                        <img src={logoDark} height="22" alt="logo" />
                      </Link>
                    </div>

                    <h4 className="font-size-18 mt-5 text-center">
                      Welcome Back !
                    </h4>
                    <p className="text-muted text-center">
                      Sign in to continue to Outbooks.
                    </p>
                    {this.props.error ? (
                      <Alert color="danger">{this.props.error}</Alert>
                    ) : null}
                    <form onSubmit={this.handleValidSubmit}>
                      <div className="fieldset">
                        <label className="required">Email</label>
                        <input
                          name="email"
                          value={this.props.email || ""}
                          onChange={(e) =>
                            this.props.onInputChange("email", e.target.value)
                          }
                          className="input-text"
                          type="text"
                          placeholder="Email"
                        />
                        {this.validator.message(
                          "Email",
                          this.props.email,
                          "required|email",
                          { className: "text-danger" }
                        )}
                      </div>
                      <div className="fieldset">
                        <label className="required">Password</label>
                        <input
                          name="password"
                          value={this.props.password || ""}
                          onChange={(e) =>
                            this.props.onInputChange("password", e.target.value)
                          }
                          className="input-text"
                          type="password"
                          placeholder="Password"
                        />
                        {this.validator.message(
                          "Password",
                          this.props.password,
                          "required",
                          { className: "text-danger" }
                        )}
                      </div>
                      <Row>
                        <Col sm={6}>
                          &nbsp;
                          {/* <div className="custom-control custom-checkbox">
                              <input type="checkbox" className="custom-control-input" id="customControlInline" />
                              <label className="custom-control-label" for="customControlInline">Remember me</label>
                          </div> */}
                        </Col>
                        <Col sm={6} className="text-right">
                          <button
                            className="btn btn-primary w-md waves-effect waves-light"
                            onClick={this.handleValidSubmit}
                          >
                            Log In
                          </button>
                        </Col>
                      </Row>
                    </form>

                    {/* LOGIN WITH  */}
                    <div className="center-line">
                      <div className="line"></div>
                      <div className="or">OR</div>
                      <div className="line"></div>
                    </div>
                    <div>
                      <div>Login with</div>
                      <div className="login-icons">
                        {/* Google Login Button  */}
                        <button
                          className="login-button"
                          onClick={() => {
                            this.handleGoogleLogin();
                          }}
                        >
                          <img
                            src={googleLogo}
                            alt="Google Icon"
                            className="login-icon"
                            title="Google"
                          />
                        </button>

                        {/* Microsoft Login Button  */}
                        <button
                          className="login-button microsoft-button"
                          onClick={() => {
                            this.handleMicrosoftLogin();
                          }}
                        >
                          <img
                            src={microsoftLogo}
                            alt="Microsoft Icon"
                            className="login-icon"
                            title="Microsoft"
                          />
                        </button>
                      </div>
                    </div>

                    {/* LOGIN WITH  */}

                    <Row className="mt-2 mb-0">
                      <div className="col-12 mt-2">
                        <Link to="/forget-password">
                          <i className="mdi mdi-lock"></i> Forgot your password?
                        </Link>
                      </div>
                      <div className="col-12 mt-2">
                        Don't have an account ?{" "}
                        <Link
                          to="register"
                          className="font-weight-medium text-primary"
                        >
                          {" "}
                          Sign up now{" "}
                        </Link>{" "}
                      </div>
                    </Row>

                    <div className="mt-5 pt-4 text-center">
                      <p>
                        © {new Date().getFullYear()} Outbooks
                        {/* Crafted with{" "}
                        <i className="mdi mdi-heart text-danger"></i> by
                        StartupFlux */}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Card>
        </div>
      </React.Fragment>
    );
  }
}

const mapStatetoProps = (state) => {
  const { email, password, error, loading } = state.Login;
  return { email, password, error, loading };
};

export default withRouter(
  connect(mapStatetoProps, {
    loginUser,
    onInputChange,
    loginUserWithProvider,
    resetLoginForm,
  })(Login)
);
