import React, { useEffect, Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { TabContent, TabPane } from "reactstrap";
import Select from "react-select";
import SweetAlert from "react-bootstrap-sweetalert";
import Loader from "../components/Loader";
import ReviewServices from "../components/ReviewServices";
import SelectServices from "../components/SelectServices";
import StepperWidget from "../components/StepperWidget";
import RequiredGlobalPricingDrivers from "../components/RequiredGlobalPricingDrivers";
import {
  setActiveStep,
  onInputChange,
  submitPackage,
  addServiceToPackage,
  removeServiceFromPackage,
  resetCreateNewPackageForm,
  getPackage,
  setShowCreateNewPackageSuccessModal,
  setShowCreateNewPackageFailedModal,
} from "../store/package/actions";
import {
  getServicesByCategory,
  getGlobalDriversByServices,
} from "../store/service/actions";
import { calculatePackagePrice } from "../store/quote/actions";
import {
  buildCalculatePricingJson,
  buildGlobalPricingDriversJson,
  getValidator,
  checkPricingDriversValid,
  checkGlobalPricingDriversValid,
} from "../../src/Utils";

class CreateNewPackage extends Component {
  constructor(props) {
    super(props);
    this.validator = getValidator();
    this.cancel = this.cancel.bind(this);
    this.nextStep = this.nextStep.bind(this);
    this.backStep = this.backStep.bind(this);
    this.formatDataAndSubmitPackage =
      this.formatDataAndSubmitPackage.bind(this);
    this.updateStepperWarnings = this.updateStepperWarnings.bind(this);
    this.state = {
      warningSteps: [],
      checkOfservices: false,
      createNewPackageSteps: [
        "Basic Information",
        "Select Services",
        // "Additional Information",
        "Pricing Information",
      ]
    };
  }
  componentDidMount() {
    this.props.getServicesByCategory();
    // if (this.type==="new") {
    this.props.resetCreateNewPackageForm();
    // }
    // else if (this.type==="edit") {
    //     this.props.getPackage(this.props.match.params.id);
    // }
  }
  componentDidUpdate(prevProps) {
    if (
      this.props.servicesByCategory !== prevProps.servicesByCategory &&
      this.type === "edit"
    ) {
      this.props.getPackage(this.props.match.params.id);
    }
    if (
      this.props.activeStep !== prevProps.activeStep &&
      this.props.activeStep === 3
    ) {
      this.props.getGlobalDriversByServices(this.props.services);
    }
    if (
      (this.props.activeStep != prevProps.activeStep &&
        this.props.activeStep == 4) ||
      (this.props.activeStep == 3 && this.props.requiredGlobalPricingDrivers.length == 0) &&
      !this.props.apiCalled
    ) {
      let services = buildCalculatePricingJson(
        this.props.services,
        this.props.servicesByCategory,
        this.props.pricingDrivers,
        this.props
      );
      let globalDriversJson = buildGlobalPricingDriversJson(
        this.props.requiredGlobalPricingDrivers,
        this.props
      );
      this.props.calculatePackagePrice(services, globalDriversJson);
      this.props.onInputChange('apiCalled', true);
    }
    let updateStepperWarnings = false;
    if (
      this.props.packageName !== prevProps.packageName ||
      this.props.packageIsActive !== prevProps.packageIsActive ||
      this.props.packageRecurringDefaultPrice !==
      prevProps.packageRecurringDefaultPrice ||
      this.props.packageRecurringMinPrice !==
      prevProps.packageRecurringMinPrice ||
      this.props.packageOneOffDefaultPrice !==
      prevProps.packageOneOffDefaultPrice ||
      this.props.packageOneOffMinPrice !== prevProps.packageOneOffMinPrice
    ) {
      updateStepperWarnings = true;
    }
    if (!updateStepperWarnings) {
      if (
        this.props.pricingDrivers.length !== prevProps.pricingDrivers.length
      ) {
        updateStepperWarnings = true;
      } else {
        for (let pricingDriver of this.props.pricingDrivers) {
          if (
            this.props[pricingDriver] !== prevProps[pricingDriver] ||
            this.props[
            `${pricingDriver}-${this.props[pricingDriver]}_other`
            ] !==
            prevProps[`${pricingDriver}-${prevProps[pricingDriver]}_other`]
          ) {
            updateStepperWarnings = true;
            break;
          }
        }
      }
    }
    if (!updateStepperWarnings) {
      if (
        this.props.requiredGlobalPricingDrivers.length !==
        prevProps.requiredGlobalPricingDrivers.length
      ) {
        updateStepperWarnings = true;
      } else {
        for (let pricingDriver of this.props.requiredGlobalPricingDrivers) {
          if (
            this.props[`globalPricingDriver-${pricingDriver._id}`] !==
            prevProps[`globalPricingDriver-${pricingDriver._id}`] ||
            this.props[
            `globalPricingDriver-${pricingDriver._id}-${this.props[`globalPricingDriver-${pricingDriver._id}`]
            }_other`
            ] !==
            prevProps[
            `globalPricingDriver-${pricingDriver._id}-${prevProps[`globalPricingDriver-${pricingDriver._id}`]
            }_other`
            ]
          ) {
            updateStepperWarnings = true;
            break;
          }
        }
      }
    }
    if (updateStepperWarnings) {
      this.updateStepperWarnings();
    }
    if (
      prevProps.requiredGlobalPricingDrivers.length !=
      this.props.requiredGlobalPricingDrivers.length ||
      (this.props.requiredGlobalPricingDrivers.length > 0 &&
        this.state.createNewPackageSteps.length == 3)) {
      if (this.props.requiredGlobalPricingDrivers.length <= 0) {
        this.setState({
          createNewPackageSteps: [
            "Basic Information",
            "Select Services",
            // "Additional Information",
            "Pricing Information",
          ]
        });
      } else {
        this.setState({
          createNewPackageSteps: [
            "Basic Information",
            "Select Services",
            "Additional Information",
            "Pricing Information",
          ]
        });
      }
    }
  }
  updateStepperWarnings() {
    if (this.validator.messagesShown) {
      let warningSteps = [];
      if (
        !this.validator.fields["Package Name"] ||
        !this.validator.fields["Is Active"]
      ) {
        warningSteps.push(1);
      }
      if (
        !checkPricingDriversValid(
          this.props.pricingDrivers,
          this.props.servicesByCategory,
          this.validator,
          this.props
        ) &&
        this.props.activeStep == "2"
      ) {
        warningSteps.push(2);
      }
      if (this.props.services.length === 0 && this.props.activeStep == "2") {
        console.log(warningSteps);
        warningSteps.push(2);
      }

      if (
        this.props.activeStep == "3" &&
        !checkGlobalPricingDriversValid(
          this.props.requiredGlobalPricingDrivers,
          this.validator,
          this.props
        )
      ) {
        warningSteps.push(3);
      }
      if (
        this.props.activeStep == "4" &&
        !(
          (this.props.recurringTotal > 0
            ? this.validator.fields["Recurring Default Price"] &&
            this.validator.fields["Recurring Min Price"]
            : true) &&
          (this.props.oneOffTotal > 0
            ? this.validator.fields["One Off Default Price"] &&
            this.validator.fields["One Off Min Price"]
            : true)
        )
      ) {
        warningSteps.push(4);
      }
      if (warningSteps.join() !== this.state.warningSteps.join()) {
        this.setState({
          warningSteps,
        });
      }
    }
  }
  cancel() {
    this.props.history.goBack();
  }
  nextStep() {
    if (
      this.props.activeStep == "1" &&
      this.validator.fieldValid("Package Name") &&
      this.validator.fieldValid("Is Active")
    ) {
      this.props.setActiveStep(this.props.activeStep + 1);
    } else if (
      this.props.activeStep == "2" &&
      checkPricingDriversValid(
        this.props.pricingDrivers,
        this.props.servicesByCategory,
        this.validator,
        this.props
      )
    ) {
      let next = true;
      if (this.props.pricingDrivers.length === 0) {
        next = true;
      }
      if (this.props.services.length > 0) {
        if (next) {
          this.props.setActiveStep(this.props.activeStep + 1);
        } else {
          this.setState({
            valid: true,
          });
          this.validator.showMessages();
        }
      } else {
        this.setState({
          valid: false,
        });
      }
      this.setState({ checkOfservices: true });
    }

    //     {
    //     this.props.setActiveStep(this.props.activeStep+1);
    // }
    else if (
      this.props.activeStep == "3" &&
      checkGlobalPricingDriversValid(
        this.props.requiredGlobalPricingDrivers,
        this.validator,
        this.props
      )
    ) {
      this.props.setActiveStep(this.props.activeStep + 1);
if(this.type==="new"){

        this.props.onInputChange("packageRecurringDefaultPrice", "");
        this.props.onInputChange("packageRecurringMinPrice","");
        this.props.onInputChange("packageOneOffDefaultPrice","");
        this.props.onInputChange("packageOneOffMinPrice","");
      }
    } else {
      this.validator.showMessages();
      this.updateStepperWarnings();
    }
  }
  backStep() {
    this.props.onInputChange('apiCalled', false);
    this.props.setActiveStep(this.props.activeStep - 1);
  }
  formatDataAndSubmitPackage() {
    if (
      this.validator.fields["Package Name"] &&
      this.validator.fields["Is Active"] &&
      checkPricingDriversValid(
        this.props.pricingDrivers,
        this.props.servicesByCategory,
        this.validator,
        this.props
      ) &&
      checkGlobalPricingDriversValid(
        this.props.requiredGlobalPricingDrivers,
        this.validator,
        this.props
      ) &&
      (this.props.recurringTotal > 0
        ? this.validator.fields["Recurring Default Price"] &&
        this.validator.fields["Recurring Min Price"]
        : true) &&
      (this.props.oneOffTotal > 0
        ? this.validator.fields["One Off Default Price"] &&
        this.validator.fields["One Off Min Price"]
        : true)
    ) {
      const data = {
        name: this.props.packageName,
        isActive: this.props.packageIsActive,
        recurringDefaultPrice: this.props.packageRecurringDefaultPrice,
        recurringMinPrice: this.props.packageRecurringMinPrice,
        oneOffDefaultPrice: this.props.packageOneOffDefaultPrice,
        oneOffMinPrice: this.props.packageOneOffMinPrice,
      };
      if (this.type === "edit") {
        data.id = this.props.packageId;
      }
      let recurringServiceCount = 0;
      let oneOffServiceCount = 0;
      let services = {};
      this.props.services.forEach((item, index) => {
        let categoryServiceSplit = item.split("-");
        let serviceChargeType = categoryServiceSplit[1].split("_")[1];
        if (serviceChargeType === "recurring") {
          recurringServiceCount++;
        } else if (serviceChargeType === "oneoff") {
          oneOffServiceCount++;
        }
        if (services.hasOwnProperty(categoryServiceSplit[0])) {
          services[categoryServiceSplit[0]][categoryServiceSplit[1]] = {};
        } else {
          services[categoryServiceSplit[0]] = {};
          services[categoryServiceSplit[0]][categoryServiceSplit[1]] = {};
        }
        // if (categoryServiceSplit[2]==="recurring") {
        //     services[categoryServiceSplit[0]][categoryServiceSplit[1]].serviceChargeType = "recurring";
        // } else if (categoryServiceSplit[2]==="oneoff") {
        //     services[categoryServiceSplit[0]][categoryServiceSplit[1]].serviceChargeType = "oneoff";
        // }
      });
      this.props.pricingDrivers.forEach((item, index) => {
        let pricingDriverSplit = item.split("-");
        // if (!services[pricingDriverSplit[1]][pricingDriverSplit[2]].hasOwnProperty("defaults")) {
        //     services[pricingDriverSplit[1]][pricingDriverSplit[2]].defaults = {}
        // }
        services[pricingDriverSplit[1]][pricingDriverSplit[2]][
          pricingDriverSplit[3]
        ] = this.props[item];
        if (this.props[`${item}-${this.props[item]}_other`]) {
          services[pricingDriverSplit[1]][pricingDriverSplit[2]][
            `${this.props[item]}_other`
          ] = this.props[`${item}-${this.props[item]}_other`];
        }
      });
      data.servicesByCategory = services;
      data.globalPricingDrivers = buildGlobalPricingDriversJson(
        this.props.requiredGlobalPricingDrivers,
        this.props
      );
      if (recurringServiceCount === 0) {
        data.recurringDefaultPrice = 0;
        data.recurringMinPrice = 0;
      } else if (oneOffServiceCount === 0) {
        data.oneOffDefaultPrice = 0;
        data.oneOffMinPrice = 0;
      }
      this.props.submitPackage(data, this.type);
    } else {
      this.validator.showMessages();
      this.updateStepperWarnings();
      // this.forceUpdate();
    }
  }
  componentWillUnmount() {
    this.props.resetCreateNewPackageForm();
  }
  render() {
    let categoryId,
      categoryName,
      serviceId,
      serviceChargeType,
      serviceName,
      pricingDrivers = [],
      dependencyArray = [];
    const categoryIds = Object.keys(this?.props?.servicesByCategory).map(
      (key) => key
    );

    const categoriesArray = Object.values(this?.props?.servicesByCategory).map(
      (category, idx) => {
        categoryId = categoryIds[idx];
        categoryName = category.categoryName;
        if (category?.services?.length > 0) {
          const servicesArray = category?.services?.map((service, idx) => {
            serviceId = service.id;
            serviceChargeType = service.chargeType;
            serviceName = service.name;
            if (service?.is_active && service?.pricingDrivers?.length > 0) {
              pricingDrivers = service?.pricingDrivers;
              const pricingDriversArray = service?.pricingDrivers?.map(
                (pricingDriver, idx) => {
                  if (
                    pricingDriver?.is_deleted === false &&
                    pricingDriver?.isDependent === true
                  ) {
                    const findDriver = pricingDrivers.filter(
                      (item) => item.name === pricingDriver.dependancyDriver
                    )[0];
                    const findDependency = dependencyArray.filter(
                      (item) =>
                        item.categoryId === categoryId &&
                        item.serviceId === serviceId &&
                        item.serviceChargeType === serviceChargeType &&
                        item.name === findDriver.name
                    );
                    if (findDependency?.length) {
                      const appendDependent = dependencyArray
                        .find((e) => e._id === findDependency[0]._id)
                        .dependents.push(pricingDriver);
                    } else {
                      dependencyArray.push({
                        ...findDriver,
                        categoryId,
                        categoryName,
                        serviceId,
                        serviceChargeType,
                        serviceName,
                        dependents: [pricingDriver],
                      });
                    }
                  }
                }
              );
            }
          });
        }
      }
    );

    const ongoing_Recurring_DependencyArray = dependencyArray.filter(
      (item) =>
        item.serviceChargeType === "recurring" ||
        item.serviceChargeType === "recurring/oneoff"
    );
    const oneOff_Adhoc_DependencyArray = dependencyArray.filter(
      (item) =>
        item.serviceChargeType === "oneoff" ||
        item.serviceChargeType === "recurring/oneoff"
    );

    this.type = this.props.match.path
      .replace("/packages/", "")
      .replace("/:id", "");
    const texts = {
      new: {
        header: "Create New Package",
        submitButton: "Create Package",
        actionName: "created",
      },
      edit: {
        header: "Edit Package",
        submitButton: "Update Package",
        actionName: "updated",
      },
    };
    const packageIsActiveOptions = [
      { value: true, label: "Yes" },
      { value: false, label: "No" },
    ];
    // const createNewPackageSteps = ["Basic Information", "Select Recurring Services", "Select One-Off Services", "Additional Information", "Pricing Information"];
    const createNewPackageSteps = [
      "Basic Information",
      "Select Services",
      "Additional Information",
      "Pricing Information",
    ];
    let activeStepCount = 1;
    return (
      <React.Fragment>
        {(this.props.loading || this.props.serviceLoading) && <Loader />}
        <div className="container-fluid new-item-page-container">
          <div className="new-item-page-nav">
            <i
              onClick={this.cancel}
              className="ion ion-md-arrow-round-back back-button"
            ></i>
          </div>
          <div className="new-item-page-content">
            <div className="row form-row">
              <div className="col-12">
                <h3>{texts[this.type].header}</h3>
                <StepperWidget
                  warningSteps={this.state.warningSteps}
                  onClick={this.props.setActiveStep}
                  activeStep={this.props.activeStep}
                  steps={this.state.createNewPackageSteps}
                  custom={true}
                />
                <TabContent activeTab={this.props.activeStep}>
                  <TabPane tabId={activeStepCount++} className="p-3">
                    <div className="row fieldset">
                      <div className="col-4 text-right">
                        <label className="fieldset-label required">
                          Package Name
                        </label>
                      </div>
                      <div className="col-8">
                        <input
                          value={this.props.packageName || ""}
                          onChange={(e) =>
                            this.props.onInputChange(
                              "packageName",
                              e.target.value
                            )
                          }
                          className="input-text"
                          type="text"
                          placeholder="Package Name"
                        />
                        {this.validator.message(
                          "Package Name",
                          this.props.packageName,
                          "required",
                          { className: "text-danger" }
                        )}
                      </div>
                    </div>
                    <div className="row fieldset">
                      <div className="col-4 text-right">
                        <label className="fieldset-label required">
                          Is Active?
                        </label>
                      </div>
                      <div className="col-8">
                        <Select
                          options={packageIsActiveOptions}
                          onChange={(selectedOption) =>
                            this.props.onInputChange(
                              "packageIsActive",
                              selectedOption.value
                            )
                          }
                          value={
                            packageIsActiveOptions?.length === 1
                              ? packageIsActiveOptions[0]
                              : this.props.packageIsActive ||
                                typeof this.props.packageIsActive === "boolean"
                                ? packageIsActiveOptions.filter(
                                  (option) =>
                                    option.value === this.props.packageIsActive
                                )
                                : ""
                          }
                        />
                        {this.validator.message(
                          "Is Active",
                          this.props.packageIsActive,
                          "required",
                          { className: "text-danger" }
                        )}
                      </div>
                    </div>
                  </TabPane>

                  <TabPane
                    style={{ pointerEvents: "none !important" }}
                    tabId={activeStepCount++}
                    className="p-3"
                  >
                    <div className="row">
                      <div className="col-6 border-right-separator">
                        <h4>Ongoing/Recurring Services</h4>
                        <div className="separator mb-2" />
                        <SelectServices
                          mode="create_editPackage"
                          chargeType="recurring"
                          parentProps={this.props}
                          dependencyArray={ongoing_Recurring_DependencyArray}
                          addService={this.props.addServiceToPackage}
                          removeService={this.props.removeServiceFromPackage}
                          onInputChange={this.props.onInputChange}
                          servicesByCategory={this.props.servicesByCategory}
                          services={this.props.services}
                          validator={this.validator}
                        />
                      </div>
                      <div className="col-6">
                        <h4>One-Off/Ad hoc Services</h4>
                        <div className="separator mb-2" />
                        <SelectServices
                          mode="create_editPackage"
                          chargeType="oneoff"
                          parentProps={this.props}
                          dependencyArray={oneOff_Adhoc_DependencyArray}
                          addService={this.props.addServiceToPackage}
                          removeService={this.props.removeServiceFromPackage}
                          onInputChange={this.props.onInputChange}
                          servicesByCategory={this.props.servicesByCategory}
                          services={this.props.services}
                          validator={this.validator}
                        />
                      </div>
                      {!this.state.valid &&
                        this.state.checkOfservices &&
                        this.props.services.length === 0 ? (
                        <p
                          style={{
                            textAlign: "center",
                            color: "red",
                            width: "100%",
                          }}
                        >
                          Please select atleast one service
                        </p>
                      ) : null}
                    </div>
                  </TabPane>
                  {this.props.requiredGlobalPricingDrivers.length > 0 ? (
                    <TabPane tabId={activeStepCount++} className="p-3">
                      <RequiredGlobalPricingDrivers
                        pricingDrivers={this.props.requiredGlobalPricingDrivers}
                        parentProps={this.props}
                        onInputChange={this.props.onInputChange}
                        validator={this.validator}
                        showNoInfoBlock
                      />
                    </TabPane>
                  ) : null}
                  <TabPane tabId={activeStepCount++} className="p-3">
                    {!(
                      checkPricingDriversValid(
                        this.props.pricingDrivers,
                        this.props.servicesByCategory,
                        this.validator,
                        this.props
                      ) &&
                      checkGlobalPricingDriversValid(
                        this.props.requiredGlobalPricingDrivers,
                        this.validator,
                        this.props
                      )
                    ) ? (
                      <div className="text-center text-danger">
                        Please input all valid drivers
                      </div>
                    ) : (
                      <React.Fragment>
                        <ReviewServices
                          isVatRegistered={
                            this.props.currentOrganisation.vatRegistered
                          }
                          discountedPrice={
                            this.props.packageRecurringDefaultPrice || this.props.recurringTotal
                          }
                          chargeType="recurring"
                          title="Recurring Services"
                          calculatedPackagePrice={
                            this.props.calculatedPackagePrice
                          }
                        >
                          <div className="row fieldset">
                            <div className="col-2 text-right">
                              <label className="fieldset-label">
                                Original Price (£)
                              </label>
                            </div>
                            <div className="col-10">
                              <input
                                value={
                                  this.props.recurringTotal
                                    ? parseFloat(
                                      this.props.recurringTotal.toFixed(2)
                                    )
                                    : ""
                                }
                                readOnly
                                className="input-text"
                                type="text"
                                placeholder="Original Price (£)"
                              />
                            </div>
                          </div>
                          <div className="row fieldset">
                            <div className="col-2 text-right">
                              <label className="fieldset-label">
                                Default Discount (%)
                              </label>
                            </div>
                            <div className="col-4">
                              <input
                                value={
                                  this.props.packageRecurringDefaultPrice
                                    ? parseFloat(
                                      ((this.props.recurringTotal -
                                        this.props
                                          .packageRecurringDefaultPrice) *
                                        100) /
                                      this.props.recurringTotal
                                    ).toFixed(2)
                                    : ""
                                }
                                readOnly
                                className="input-text"
                                type="text"
                                placeholder="Default Discount (%)"
                              />
                            </div>
                            <div className="col-2 text-right">
                              <label className="fieldset-label required">
                                Default Price (£)
                              </label>
                            </div>
                            <div className="col-4">
                              <input
                                value={
                                  this.props.packageRecurringDefaultPrice || ""
                                }
                                onChange={(e) =>
                                  this.props.onInputChange(
                                    "packageRecurringDefaultPrice",
                                    e.target.value
                                  )
                                }
                                className="input-text"
                                type="text"
                                placeholder="Default Price (£)"
                              />
                              {this.validator.message(
                                "Recurring Default Price",
                                this.props.packageRecurringDefaultPrice,
                                `required|numeric|min:0,num|max:${this.props.recurringTotal},num`,
                                { className: "text-danger" }
                              )}
                            </div>
                          </div>
                          <div className="row fieldset">
                            <div className="col-2 text-right">
                              <label className="fieldset-label">
                                Max Discount (%)
                              </label>
                            </div>
                            <div className="col-4">
                              <input
                                value={
                                  this.props.packageRecurringMinPrice
                                    ? parseFloat(
                                      ((this.props.recurringTotal -
                                        this.props.packageRecurringMinPrice) *
                                        100) /
                                      this.props.recurringTotal
                                    ).toFixed(2)
                                    : ""
                                }
                                readOnly
                                className="input-text"
                                type="text"
                                placeholder="Max Discount (%)"
                              />
                            </div>
                            <div className="col-2 text-right">
                              <label className="fieldset-label required">
                                Min Price (£)
                              </label>
                            </div>
                            <div className="col-4">
                              <input
                                value={
                                  this.props.packageRecurringMinPrice || ""
                                }
                                onChange={(e) =>
                                  this.props.onInputChange(
                                    "packageRecurringMinPrice",
                                    e.target.value
                                  )
                                }
                                className="input-text"
                                type="text"
                                placeholder="Min Price (£)"
                              />
                              {this.validator.message(
                                "Recurring Min Price",
                                this.props.packageRecurringMinPrice,
                                `required|numeric|min:0,num|max:${this.props.packageRecurringDefaultPrice},num`,
                                { className: "text-danger" }
                              )}
                            </div>
                          </div>
                        </ReviewServices>
                        <ReviewServices
                          isVatRegistered={
                            this.props.currentOrganisation.vatRegistered
                          }
                          discountedPrice={this.props.packageOneOffDefaultPrice || this.props.oneOffTotal}
                          chargeType="oneoff"
                          title="One-Off Services"
                          calculatedPackagePrice={
                            this.props.calculatedPackagePrice
                          }
                        >
                          <div className="row fieldset">
                            <div className="col-2 text-right">
                              <label className="fieldset-label">
                                Original Price (£)
                              </label>
                            </div>
                            <div className="col-10">
                              <input
                                value={this.props?.oneOffTotal?.toFixed(2) || ""}
                                readOnly
                                className="input-text"
                                type="text"
                                placeholder="Original Price (£)"
                              />
                            </div>
                          </div>
                          <div className="row fieldset">
                            <div className="col-2 text-right">
                              <label className="fieldset-label">
                                Default Discount (%)
                              </label>
                            </div>
                            <div className="col-4">
                              <input
                                value={
                                  this.props.packageOneOffDefaultPrice
                                    ? parseFloat(
                                      ((this.props.oneOffTotal -
                                        this.props
                                          .packageOneOffDefaultPrice) *
                                        100) /
                                      this.props.oneOffTotal
                                    ).toFixed(2)
                                    : ""
                                }
                                readOnly
                                className="input-text"
                                type="text"
                                placeholder="Default Discount (%)"
                              />
                            </div>
                            <div className="col-2 text-right">
                              <label className="fieldset-label required">
                                Default Price (£)
                              </label>
                            </div>
                            <div className="col-4">
                              <input
                                value={
                                  this.props.packageOneOffDefaultPrice || ""
                                }
                                onChange={(e) =>
                                  this.props.onInputChange(
                                    "packageOneOffDefaultPrice",
                                    e.target.value
                                  )
                                }
                                className="input-text"
                                type="text"
                                placeholder="Default Price (£)"
                              />
                              {this.validator.message(
                                "One Off Default Price",
                                this.props.packageOneOffDefaultPrice,
                                `required|numeric|min:0,num|max:${this.props.oneOffTotal},num`,
                                { className: "text-danger" }
                              )}
                            </div>
                          </div>
                          <div className="row fieldset">
                            <div className="col-2 text-right">
                              <label className="fieldset-label">
                                Max Discount (%)
                              </label>
                            </div>
                            <div className="col-4">
                              <input
                                value={
                                  this.props.packageOneOffMinPrice
                                    ? parseFloat(
                                      ((this.props.oneOffTotal -
                                        this.props.packageOneOffMinPrice) *
                                        100) /
                                      this.props.oneOffTotal
                                    ).toFixed(2)
                                    : ""
                                }
                                readOnly
                                className="input-text"
                                type="text"
                                placeholder="Max Discount (%)"
                              />
                            </div>
                            <div className="col-2 text-right">
                              <label className="fieldset-label required">
                                Min Price (£)
                              </label>
                            </div>
                            <div className="col-4">
                              <input
                                value={this.props.packageOneOffMinPrice || ""}
                                onChange={(e) =>
                                  this.props.onInputChange(
                                    "packageOneOffMinPrice",
                                    e.target.value
                                  )
                                }
                                className="input-text"
                                type="text"
                                placeholder="Min Price (£)"
                              />
                              {this.validator.message(
                                "One Off Min Price",
                                this.props.packageOneOffMinPrice,
                                `required|numeric|min:0,num|max:${this.props.packageOneOffDefaultPrice},num`,
                                { className: "text-danger" }
                              )}
                            </div>
                          </div>
                        </ReviewServices>
                      </React.Fragment>
                    )}
                  </TabPane>
                </TabContent>
                <div className="separator mt-3 mb-3" />
                <div className="row fieldset">
                  <div className="col-12 text-right">
                    <button onClick={this.cancel} className="btn">
                      Cancel
                    </button>
                    {this.props.activeStep > 1 && (
                      <button
                        onClick={this.backStep}
                        className="btn create-item ml-2"
                      >
                        Back
                      </button>
                    )}
                    {this.props.activeStep < this.state.createNewPackageSteps.length ? (
                      <button
                        onClick={this.nextStep}
                        className="btn create-item ml-2"
                      >
                        Next
                      </button>
                    ) : null}
                    {this.props.activeStep === this.state.createNewPackageSteps.length ? (
                      <button
                        className="btn create-item ml-2"
                        onClick={this.formatDataAndSubmitPackage}
                      >
                        {texts[this.type].submitButton}
                      </button>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {this.props.showCreateNewPackageSuccessModal && (
          <SweetAlert
            title=""
            success
            confirmBtnBsStyle="success"
            onConfirm={() => {
              this.props.setShowCreateNewPackageSuccessModal(false);
              this.props.history.replace("/packages");
            }}
          >
            <div className="mb-2 success-alert-message">
              Package <b>{this.props.packageName || ""}</b> has been{" "}
              {texts[this.type].actionName} successfully!
            </div>
          </SweetAlert>
        )}
        {this.props.showCreateNewPackageFailedModal && (
          <SweetAlert
            title=""
            danger
            confirmBtnBsStyle="success"
            onConfirm={() =>
              this.props.setShowCreateNewPackageFailedModal(false)
            }
          >{`Package with name ${this.props.packageName} already exists!`}</SweetAlert>
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  const { currentOrganisation } = state.Organisation;
  const { servicesByCategory, requiredGlobalPricingDrivers } = state.Service;
  const serviceLoading = state.Service.loading;
  const { calculatedPackagePrice, recurringTotal, oneOffTotal } = state.Quote;
  return {
    currentOrganisation,
    servicesByCategory,
    requiredGlobalPricingDrivers,
    serviceLoading,
    calculatedPackagePrice,
    recurringTotal,
    oneOffTotal,
    ...state.Package,
  };
};

export default withRouter(
  connect(mapStateToProps, {
    setActiveStep,
    onInputChange,
    submitPackage,
    addServiceToPackage,
    removeServiceFromPackage,
    resetCreateNewPackageForm,
    getPackage,
    getServicesByCategory,
    setShowCreateNewPackageSuccessModal,
    calculatePackagePrice,
    getGlobalDriversByServices,
    setShowCreateNewPackageFailedModal,
  })(CreateNewPackage)
);
