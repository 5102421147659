import {
  GET_NOTIFICATIONS,
  GET_NOTIFICATIONS_SUCCESS,
  LOAD_MORE_NOTIFICATIONS,
  LOAD_MORE_NOTIFICATIONS_SUCCESS,
  UPDATE_NOTIFICATIONS_READ_STATUS,
  UPDATE_NOTIFICATIONS_READ_STATUS_SUCCESS,
} from "./actionTypes";

export const getNotifications = () => {
  return {
    type: GET_NOTIFICATIONS,
  };
};

export const getNotificationsSuccess = (
  notifications,
  unreadNotificationsCount,
  totalCount
) => {
  return {
    type: GET_NOTIFICATIONS_SUCCESS,
    payload: {
      notifications,
      unreadNotificationsCount,
      totalCount,
    },
  };
};

export const loadMoreNotifications = (lastId) => {
  return {
    type: LOAD_MORE_NOTIFICATIONS,
    payload: lastId,
  };
};

export const loadMoreNotificationsSuccess = (notifications) => {
  return {
    type: LOAD_MORE_NOTIFICATIONS_SUCCESS,
    payload: notifications,
  };
};

export const updateNotificationsReadStatus = () => {
  return {
    type: UPDATE_NOTIFICATIONS_READ_STATUS,
  };
};

export const updateNotificationsReadStatusSuccess = () => {
  return {
    type: UPDATE_NOTIFICATIONS_READ_STATUS_SUCCESS,
  };
};
