import React from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import { Modal, ModalBody } from 'reactstrap';
import { connect } from "react-redux";
import { setShowApiErrorModal } from "../store/common/actions";

const ApiErrorModal = props => {
	return (
		props.showApiErrorModal ? <SweetAlert
			title=""
			danger
			confirmBtnBsStyle="success"
			onConfirm={() => props.setShowApiErrorModal(false)}
		>
			{props.message}
		</SweetAlert> : null
	);
}

const mapStateToProps = state => {
  const { showApiErrorModal, message } = state.Common;
  return { showApiErrorModal, message };
}

export default connect(mapStateToProps, {setShowApiErrorModal})(ApiErrorModal);
