import {
  SET_ORGANISATION_LOADING,
  ON_INPUT_CHANGE,
  SET_ACTIVE_STEP,
  ADD_PARTNER,
  DELETE_PARTNER,
  ADD_OFFICER,
  DELETE_OFFICER,
  COMPANY_SEARCH,
  COMPANY_SEARCH_SUCCESS,
  COMPANY_SEARCH_FAILED,
  SELECT_COMPANY_SEARCH_ITEM,
  SELECT_COMPANY_SEARCH_ITEM_SUCCESS,
  SELECT_COMPANY_SEARCH_ITEM_FAILED,
  CREATE_ORGANISATION,
  CONFIRM_PAYMEMT,
  UPDATE_PAYMENT_METHOD,
  UPDATE_PAYMENT_METHOD_SUCCESS,
  CREATE_ORGANISATION_SUCCESS,
  CREATE_ORGANISATION_FAILED,
  SET_SHOW_UPDATE_PAYMENT_METHOD_SUCCESS_MODAL,
  RESET_ORGANISATION_FORM,
  SET_ORGANISATION_FORM_FROM_CLIENT_DATA,
  GET_MY_ORGANISATION,
  GET_MY_ORGANISATION_SUCCESS,
  UPDATE_MY_ORGANISATION,
  UPDATE_MY_ORGANISATION_SUCCESS,
  SET_SHOW_IMAGE_UPLOAD_MODAL,
  UPLOAD_LOGO,
  UPLOAD_LOGO_SUCCESS,
  UPLOAD_SIGNATURE,
  UPLOAD_SIGNATURE_SUCCESS,
  SET_SHOW_UPDATE_ORGANISATION_SUCCESS_MODAL,
  SET_CURRENT_ORGANISATION,
  GET_SUPER_ADMIN_ORGANISATIONS,
  GET_SUPER_ADMIN_ORGANISATIONS_SUCCESS,
  GET_PRICING_SETTINGS,
  GET_PRICING_SETTINGS_SUCCESS,
  UPDATE_PRICING_SETTINGS,
  UPDATE_PRICING_SETTINGS_SUCCESS,
  ADD_SIGNATORY,
  DELETE_SIGNATORY,
  SET_SIGNATORIES,
  ON_INPUT_CHANGE_CURRENT,
  GET_EMAIL_CONFIGS,
  GET_EMAIL_CONFIGS_SUCCESS,
  UPDATE_EMAIL_CONFIGS,
  UPDATE_EMAIL_CONFIGS_SUCCESS,
} from "./actionTypes";
import { SET_SHOW_CREATE_NEW_SUBSCRIPTION_PACKAGE_SUCCESS_MODAL } from "../subscriptionPackage/actionTypes";

export const setOrganisationLoading = (isLoading) => {
  return {
    type: SET_ORGANISATION_LOADING,
    payload: isLoading,
  };
};

export const setShowUpdatePaymentMethodSuccessModal = (isVisible) => {
  return {
    type: SET_SHOW_UPDATE_PAYMENT_METHOD_SUCCESS_MODAL,
    payload: isVisible,
  };
};

export const onInputChange = (field, value) => {
  return {
    type: ON_INPUT_CHANGE,
    payload: {
      field,
      value,
    },
  };
};

export const setActiveStep = (step) => {
  return {
    type: SET_ACTIVE_STEP,
    payload: step,
  };
};

export const addPartner = (partnerCode) => {
  return {
    type: ADD_PARTNER,
    payload: partnerCode,
  };
};

export const deletePartner = (partnerCode) => {
  return {
    type: DELETE_PARTNER,
    payload: partnerCode,
  };
};

export const addOfficer = (officerCode) => {
  return {
    type: ADD_OFFICER,
    payload: officerCode,
  };
};

export const deleteOfficer = (officerCode) => {
  return {
    type: DELETE_OFFICER,
    payload: officerCode,
  };
};

export const companySearch = (search) => {
  return {
    type: COMPANY_SEARCH,
    payload: search,
  };
};

export const companySearchSuccess = (items) => {
  return {
    type: COMPANY_SEARCH_SUCCESS,
    payload: items,
  };
};

export const companySearchFailed = () => {
  return {
    type: COMPANY_SEARCH_FAILED,
  };
};

export const selectCompanySearchItem = (item) => {
  return {
    type: SELECT_COMPANY_SEARCH_ITEM,
    payload: item,
  };
};

export const selectCompanySearchItemSuccess = (item) => {
  return {
    type: SELECT_COMPANY_SEARCH_ITEM_SUCCESS,
    payload: item,
  };
};

export const selectCompanySearchItemFailed = () => {
  return {
    type: SELECT_COMPANY_SEARCH_ITEM_FAILED,
  };
};

export const createOrganisation = (data, history) => {
  return {
    type: CREATE_ORGANISATION,
    payload: { data, history },
  };
};

export const updatePaymentMethod = (data) => {
  return {
    type: UPDATE_PAYMENT_METHOD,
    payload: data,
  };
};

export const updatePaymentMethodSuccess = (data) => {
  return {
    type: UPDATE_PAYMENT_METHOD_SUCCESS,
    payload: data,
  };
};

export const updateMyOrganisation = (data) => {
  return {
    type: UPDATE_MY_ORGANISATION,
    payload: data,
  };
};

export const updateMyOrganisationSuccess = () => {
  return {
    type: UPDATE_MY_ORGANISATION_SUCCESS,
  };
};

export const resetOrganisationForm = () => {
  return {
    type: RESET_ORGANISATION_FORM,
  };
};

export const setOrganisationFormFromClientData = (data) => {
  return {
    type: SET_ORGANISATION_FORM_FROM_CLIENT_DATA,
    payload: data,
  };
};

export const getMyOrganisation = () => {
  return {
    type: GET_MY_ORGANISATION,
  };
};

export const getMyOrganisationSuccess = (data) => {
  return {
    type: GET_MY_ORGANISATION_SUCCESS,
    payload: data,
  };
};

export const setShowImageUploadModal = (isVisible, type = null) => {
  return {
    type: SET_SHOW_IMAGE_UPLOAD_MODAL,
    payload: {
      isVisible,
      type,
    },
  };
};

export const uploadLogo = (fileObject) => {
  return {
    type: UPLOAD_LOGO,
    payload: fileObject,
  };
};

export const uploadLogoSuccess = (logoUrl) => {
  return {
    type: UPLOAD_LOGO_SUCCESS,
    payload: logoUrl,
  };
};

export const uploadSignature = (fileObject) => {
  return {
    type: UPLOAD_SIGNATURE,
    payload: fileObject,
  };
};

export const uploadSignatureSuccess = (signatureUrl) => {
  return {
    type: UPLOAD_SIGNATURE_SUCCESS,
    payload: signatureUrl,
  };
};

export const setShowUpdateOrganisationSuccessModal = (isVisible) => {
  return {
    type: SET_SHOW_UPDATE_ORGANISATION_SUCCESS_MODAL,
    payload: isVisible,
  };
};

export const setCurrentOrganisation = (organisation) => {
  return {
    type: SET_CURRENT_ORGANISATION,
    payload: organisation,
  };
};

export const getSuperAdminOrganisations = (pageNum = 0, text = "") => {
  return {
    type: GET_SUPER_ADMIN_ORGANISATIONS,
    payload: { text, pageNum },
  };
};

export const getSuperAdminOrganisationsSuccess = (data) => {
  return {
    type: GET_SUPER_ADMIN_ORGANISATIONS_SUCCESS,
    payload: data,
  };
};

export const confirmPayment = (payload, history) => {
  return {
    type: CONFIRM_PAYMEMT,
    payload,
  };
};

// export const createOrganisationSuccess = (organisationId) => {
// 	return {
// 		type: CREATE_ORGANISATION_SUCCESS,
// 		payload: organisationId
// 	}
// }

// export const createOrganisationFailed = () => {
// 	return {
// 		type: CREATE_ORGANISATION_FAILED
// 	}
// }

export const getPricingSettings = () => {
  return {
    type: GET_PRICING_SETTINGS,
  };
};

export const getPricingSettingsSuccess = (data) => {
  return {
    type: GET_PRICING_SETTINGS_SUCCESS,
    payload: data,
  };
};

export const updatePricingSettings = (data) => {
  return {
    type: UPDATE_PRICING_SETTINGS,
    payload: data,
  };
};

export const updatePricingSettingsSuccess = () => {
  return {
    type: UPDATE_PRICING_SETTINGS_SUCCESS,
  };
};

export const addSignatory = (code) => {
  return {
    type: ADD_SIGNATORY,
    payload: code,
  };
};

export const deleteSignatory = (code) => {
  return {
    type: DELETE_SIGNATORY,
    payload: code,
  };
};

export const setSignatories = (signatories = []) => {
  return {
    type: SET_SIGNATORIES,
    payload: signatories,
  };
};

export const onInputChangeCurrent = (field, value) => {
  return {
    type: ON_INPUT_CHANGE_CURRENT,
    payload: {
      field,
      value,
    },
  };
};

export const getEmailConfigs = () => {
  return {
    type: GET_EMAIL_CONFIGS,
  };
};

export const getEmailConfigsSuccess = (data) => {
  return {
    type: GET_EMAIL_CONFIGS_SUCCESS,
    payload: data,
  };
};

export const updateEmailConfigs = (data) => {
  return {
    type: UPDATE_EMAIL_CONFIGS,
    payload: data,
  };
};

export const updateEmailConfigsSuccess = () => {
  return {
    type: UPDATE_EMAIL_CONFIGS_SUCCESS,
  };
};