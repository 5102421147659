import React, { Component } from "react";
import { generateEmailTemplate } from "../store/emailTemplate/actions";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

class GenerateEmailTemplates extends Component {
  constructor(props) {
    super(props);
  }
  componentDidMount() {
    this.props.generateEmailTemplate();
  }
  render() {
    return (
        <p>working</p>
    );
  }
}

const mapStateToProps = (state) => {
  
};

export default withRouter(
  connect(mapStateToProps, {
    generateEmailTemplate
  })(GenerateEmailTemplates)
);
