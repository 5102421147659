import React, { Component } from "react";
import Select from "react-select";
import SweetAlert from "react-bootstrap-sweetalert";
import { connect } from "react-redux";
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { getValidator } from "../Utils";
import { templateTypes, clientBusinessTypes, businessTypes } from "../config";
import { getFirebaseBackend } from "../helpers/authUtils";
import { onInputChange, addElementBlock, moveElement, deleteElement, submitTemplate, setShowCreateNewTemplateSuccessModal, resetCreateNewTemplateForm, getTemplate,setShowCreateNewTemplateFailedModal} from "../store/template/actions";
import ElementBlock from "../components/Template/ElementBlock";
import TncBlock from "../components/Template/TncBlock";
import AddElementBlock from "../components/Template/AddElementBlock";
import IndividualVariableHelper from "../components/Template/VariableHelper/IndividualVariableHelper";
import SoleTraderVariableHelper from "../components/Template/VariableHelper/SoleTraderVariableHelper";
import PartnershipVariableHelper from "../components/Template/VariableHelper/PartnershipVariableHelper";
import CompanyVariableHelper from "../components/Template/VariableHelper/CompanyVariableHelper";
import Loader from "../components/Loader";

class CreateNewTemplate extends Component {
	constructor(props) {
		super(props);
		this.userRole = getFirebaseBackend().getUserRole();
		this.renderElementBlocks = this.renderElementBlocks.bind(this);
		this.validateElementBlocks = this.validateElementBlocks.bind(this);
		this.formatAndSubmitTemplate = this.formatAndSubmitTemplate.bind(this);
		this.hasSignatureBlock = this.hasSignatureBlock.bind(this);
		this.cancel = this.cancel.bind(this);
		this.validator = getValidator();
	}
	cancel() {
		this.props.history.goBack();
	}
	generateElementBlockCode() {
		return `${new Date().getTime()}`;
	}
	renderElementBlocks() {
		return (
			<DndProvider backend={HTML5Backend}>
				{this.props.elementBlocks.map((item, index) => {
					let properties = {};
					switch(this.props[`element${item}Type`]) {
						case "heading":
							properties.heading = this.props[`element${item}Heading`];
							break;
						case "textBlock":
							properties.textBlock = this.props[`element${item}TextBlock`];
							break;
						case "fullPageHeading":
							properties.heading = this.props[`element${item}Heading`];
							properties.description = this.props[`element${item}Description`];
							break;
						case "signatureBlock":
							properties.sectionName = this.props[`element${item}SectionName`];
							break;
						default:
							break;
					}
					return <ElementBlock validator={this.validator} deleteElement={() => this.props.deleteElement(item)} elementProps={properties} moveElement={this.props.moveElement} elementType={this.props[`element${item}Type`]} code={item} index={index} onInputChange={this.props.onInputChange} key={index} />
				})}
			</DndProvider>
		);
	}
	hasSignatureBlock() {
		let hasBlock = false;
		for (let item of this.props.elementBlocks) {
			if (this.props[`element${item}Type`]==="signatureBlock") {
				hasBlock = true;
				break;
			}
		}
		console.log(hasBlock);
		return hasBlock;
	}
	validateElementBlocks() {
		let isValid = true;
		if (this.props.templateType==="tnc") {
			return isValid;
		}
		for (let item of this.props.elementBlocks) {
			switch (this.props[`element${item}Type`]) {
				case "heading":
					if (!this.validator.fields[`element${item}Type`] || !this.validator.fields[`element${item}Heading`]) {
						isValid = false;
					}
					break;
				case "textBlock":
					if (!this.validator.fields[`element${item}Type`]) {
						isValid = false;
					}
					break;
				case "fullPageHeading":
					if (!this.validator.fields[`element${item}Type`] || !this.validator.fields[`element${item}Heading`]) {
						isValid = false;
					}
					break;
				case "pageBreak":
					if (!this.validator.fields[`element${item}Type`]) {
						isValid = false;
					}
					break;
				case "serviceDescriptions":
					if (!this.validator.fields[`element${item}Type`]) {
						isValid = false;
					}
					break;
				case "servicePricingTable":
					if (!this.validator.fields[`element${item}Type`]) {
						isValid = false;
					}
					break;
				case "signatureBlock":
					if (!this.validator.fields[`element${item}Type`]) {
						isValid = false;
					}
					break;
				default:
					break;
			}
			if (!isValid) {
				break;
			}
		}
		return isValid;
	}
	formatAndSubmitTemplate() {
		console.log('this.props - ', this.props);
		if (
			this.validateElementBlocks() &&
			this.validator.fields["Template Name"] &&
			this.validator.fields["Template Type"] &&
			this.validator.fields["Prospect Business Type"] &&
			(this.userRole==="superadmin" ? this.validator.fields["Organisation Business Type"] : true) &&
			(this.props.templateType==="contract" ? this.hasSignatureBlock() : true)
		) {
			let templateData = {
				name: this.props.templateName,
				type: this.props.templateType,
				clientBusinessType: this.props.clientBusinessType,
				organisationBusinessType: this.props.businessType,
				template: []
			};
			if (this.type==="edit") {
				templateData.id = this.props.templateId;
			}
			if (this.props.templateType==="tnc") {
				templateData.template.push({
					type: "tnc",
					tncBlock: this.props.tncBlock
				});
			} 
			else {
				for (let item of this.props.elementBlocks) {
					let properties = {};
					switch (this.props[`element${item}Type`]) {
						case "heading":
							properties.type = "heading";
							properties.heading = this.props[`element${item}Heading`];
							break;
						case "textBlock":
							properties.type = "textBlock"
							properties.textBlock = this.props[`element${item}TextBlock`];
							break;
						case "fullPageHeading":
							properties.type = "fullPageHeading";
							properties.heading = this.props[`element${item}Heading`];
							properties.description = this.props[`element${item}Description`];
							break;
						case "pageBreak":
							properties.type = "pageBreak";
							break;
						case "serviceDescriptions":
							properties.type = "serviceDescriptions";
							break;
						case "servicePricingTable":
							properties.type = "servicePricingTable";
							break;
						case "statementOfFacts":
							properties.type = "statementOfFacts";
							break;
						case "signatureBlock":
							properties.type = "signatureBlock";
							properties.sectionName = this.props[`element${item}SectionName`];
							break;
						default:
							break;
					}
					templateData.template.push(properties);
				}
			}
			this.props.submitTemplate(templateData, this.type);
		} else {
			this.validator.showMessages();
			this.forceUpdate();
		}
	}
	componentDidMount() {
		this.props.resetCreateNewTemplateForm();
		if (this.type==="edit") {
			this.props.getTemplate(this.props.match.params.id);        
		}
	}
	componentWillUnmount() {
		this.props.resetCreateNewTemplateForm();
	}
	render() {
		this.type = this.props.match.path.replace("/templates/", "").replace("/:id", "");
		const texts = {
			new: {
				header: "Create New Template",
				submitButton: "Create Template",
				actionName: "created"
			},
			edit: {
				header: "Edit Template",
				submitButton: "Update Template",
				actionName: "updated"
			}
		}
		return (
			<React.Fragment>
				{this.props.loading && <Loader />}
				<div className="container-fluid new-item-page-container">
					<div className="new-item-page-nav"><i onClick={this.cancel} className="ion ion-md-arrow-round-back back-button"></i></div>
					<div className="new-item-page-content">
						<div className="row form-row">
							<div className="col-12">
								<h3>{texts[this.type].header}</h3>
								<div className="separator mt-3" />
								<div className="p-3">
									<div className="row fieldset">
										<div className="col-4 text-right">
											<label className="fieldset-label required">Template Name</label>
										</div>
										<div className="col-8">
											<input value={this.props.templateName || ""} onChange={(e) => this.props.onInputChange("templateName", e.target.value)} className="input-text" type="text" placeholder="Template Name" />
											{this.validator.message('Template Name', this.props.templateName, 'required', { className: 'text-danger' })}
										</div>
									</div>
									<div className="row fieldset">
										<div className="col-4 text-right">
											<label className="fieldset-label required">Template Type</label>
										</div>
										<div className="col-8">
											<Select
												options={templateTypes}
												onChange={selectedOption => this.props.onInputChange("templateType", selectedOption.value)}
												value={this.props.templateType ? templateTypes.filter(option => option.value === this.props.templateType) : ""}
											/>
											{this.validator.message('Template Type', this.props.templateType, 'required', { className: 'text-danger' })}
										</div>
									</div>
									<div className="row fieldset">
										<div className="col-4 text-right">
											<label className="fieldset-label required">Prospect Business Type</label>
										</div>
										<div className="col-8">
											<Select
												options={clientBusinessTypes}
												onChange={selectedOption => this.props.onInputChange("clientBusinessType", selectedOption.value)}
												value={this.props.clientBusinessType ? clientBusinessTypes.filter(option => option.value === this.props.clientBusinessType) : ""}
											/>
											{this.validator.message('Prospect Business Type', this.props.clientBusinessType, 'required', { className: 'text-danger' })}
										</div>
									</div>
									{this.userRole==="superadmin" && <div className="row fieldset">
										<div className="col-4 text-right">
											<label className="fieldset-label required">Organisation Business Type</label>
										</div>
										<div className="col-8">
											<Select
												options={businessTypes}
												onChange={selectedOption => this.props.onInputChange("businessType", selectedOption.value)}
												value={this.props.businessType ? businessTypes.filter(option => option.value === this.props.businessType) : ""}
											/>
											{this.validator.message('Organisation Business Type', this.props.businessType, 'required', { className: 'text-danger' })}
										</div>
									</div>}
									<div className="row fieldset">
										<div className="col-12">
											{this.props.templateType && <h4 className="mt-2">Template Content</h4>}
											<div className="separator mb-3" />
											{(["quote", "contract"].includes(this.props.templateType)) && <React.Fragment>
												{this.props.clientBusinessType==="individual" && <IndividualVariableHelper businessType={this.props.businessType} />}
												{this.props.clientBusinessType==="soleTrader" && <SoleTraderVariableHelper businessType={this.props.businessType} />}
												{this.props.clientBusinessType==="partnership" && <PartnershipVariableHelper businessType={this.props.businessType} />}
												{(this.props.clientBusinessType==="ltd" || this.props.clientBusinessType==="llp") && <CompanyVariableHelper businessType={this.props.businessType} />}
												{this.renderElementBlocks()}
												<AddElementBlock onClick={() => this.props.addElementBlock(this.generateElementBlockCode())} />
											</React.Fragment>}
											{this.props.templateType==="tnc" && <TncBlock tncBlock={this.props.tncBlock} onInputChange={this.props.onInputChange} />}
										</div>
									</div>
									{
										this.props.templateType==="contract" &&
										this.validator.messagesShown &&
										!this.hasSignatureBlock() &&
										<div className="minimum-array-1">At least 1 Signatory Block is required for Engagement Letter template</div>
									}
								</div>
								<div className="separator mt-3 mb-3" />
                                <div className="row fieldset">
                                    <div className="col-12 text-right">
                                    	<button onClick={this.cancel} className="btn">Cancel</button>
										<button onClick={this.formatAndSubmitTemplate} className="btn create-item">{texts[this.type].submitButton}</button>
                                    </div>
                                </div>
							</div>
						</div>
					</div>
				</div>
				{this.props.showCreateNewTemplateSuccessModal && <SweetAlert
                    title=""
                    success
                    confirmBtnBsStyle="success"
                    onConfirm={() => { this.props.setShowCreateNewTemplateSuccessModal(false); this.props.history.replace("/templates") }}
                >
                    <div className="mb-2 success-alert-message">Template <b>{this.props.templateName||""}</b> has been {texts[this.type].actionName} successfully!</div>
                </SweetAlert>}
				{this.props.showCreateNewTemplateFailedModal && (
					<SweetAlert
					  title=""
					  danger
					  confirmBtnBsStyle="success"
					  onConfirm={() =>
						this.props.setShowCreateNewTemplateFailedModal(false)
					  }
					>{`Template with name ${this.props.templateName} already exists!`}</SweetAlert>
				  )}
			</React.Fragment>
		);
	}
}

const mapStateToProps = state => state.Template;

export default connect(mapStateToProps, { onInputChange, addElementBlock, moveElement, deleteElement, submitTemplate, setShowCreateNewTemplateSuccessModal, resetCreateNewTemplateForm, getTemplate,setShowCreateNewTemplateFailedModal})(CreateNewTemplate);
