import React from "react";

const renderList = (props) => (item, index) => {
  return (
    <li key={index}>
      <div
        className={`step ${index + 1 <= props.activeStep ? "" : "disabled"} ${
          index + 1 <= props.activeStep ? "" : "cursor-not-allowed"
        }`}
        onClick={() =>
          index + 1 < props.activeStep
            ? // ("currentActiveStep" in props && index + 1 <= props.currentActiveStep)
              props.onClick(index + 1)
            : null
        }
      >
        <span className="stepCount">{index + 1}</span>
        <span className="stepTitle">{item}</span>
        {props.warningSteps && props.warningSteps.includes(index + 1) && (
          <i className="ion ion-ios-warning stepper-warning-step"></i>
        )}
      </div>
    </li>
  );
};

export default (props) => {
  return (
    <div
      className="steps"
      style={{ pointerEvents: props.custom ? "all" : "none" }}
    >
      <ul className="steps-list">{props.steps.map(renderList(props))}</ul>
    </div>
  );
};
