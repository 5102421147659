import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Loader from "../components/Loader";
import { getEmailTemplates } from "../store/emailTemplate/actions";
import { setShowConfirmDeleteModal } from "../store/delete/actions";
import EmailTemplateListTable from "../components/EmailTemplateListTable";
import ConfirmDeleteModal from "../components/ConfirmDeleteModal";
import DeleteSuccessModal from "../components/DeleteSuccessModal";
const delObject = "Email Template";
class EmailTemplate extends Component {
  componentDidMount() {
    this.props.getEmailTemplates();
  }
  render() {
    return (
      <React.Fragment>
        {this.props.loading && <Loader />}
        <div className="page-info-header">
          <div className="container-fluid">
            <div className="page-display-title">Email Template</div>
            <div className="text-right flex1">
              <button
                onClick={() => this.props.history.push("/email-template/new")}
                className="btn btn-success create-item" disabled
              >
                <i className="ion ion-md-add-circle"></i>{" "}
                <span>Add New Email Template</span>
              </button>
            </div>
          </div>
        </div>
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="card cus-card">
                <div className="card-body">
                  <EmailTemplateListTable
                    delete={this.props.setShowConfirmDeleteModal}
                    emailTemplates={this.props.emailTemplates}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <ConfirmDeleteModal />
        <DeleteSuccessModal delObject={delObject} />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  const { emailTemplates, loading } = state.EmailTemplate;
  return {
    emailTemplates,
    loading,
  };
};

export default withRouter(
  connect(mapStateToProps, {
    getEmailTemplates,
    setShowConfirmDeleteModal,
  })(EmailTemplate)
);
