import { takeEvery, fork, put, all, call } from 'redux-saga/effects';
import axios from 'axios';

import {
    GET_ACCESS_KEYS,
    CREATE_ACCESS_KEY
} from "./actionTypes";

import {
    getAccessKeysSuccess, createAccessKeySuccess, resetAccessKeyForm
} from './actions';
import { setShowApiErrorModal } from '../common/actions';

// AUTH related methods
import { getFirebaseBackend } from '../../helpers/authUtils';

const fireBaseBackend = getFirebaseBackend();

function* getAccessKeys() {
    try {
        const accessToken = yield fireBaseBackend.getAccessToken();
        const response = yield axios({
            method: "GET",
            url: `access-keys`,
            headers: {authorization: `Bearer ${accessToken}`}
        });
        if (response.data.success) {
            yield put(getAccessKeysSuccess(response.data.result));
        } else {
            // yield put(createOrganisationFailed());
        }
    } catch (error) {
        yield put(setShowApiErrorModal());
        console.log(error);
        // if (error.response && error.response.data && error.response.data.error) {
        //     yield put(loginError(error.response.data.error));
        // } else {
        //     yield put(loginError("Something went Wrong!"));
        // }
        // yield put(createOrganisationFailed());
    }
}

function* createAccessKey({payload: data}) {
    try {
        const accessToken = yield fireBaseBackend.getAccessToken();
        const response = yield axios({
            method: "POST",
            url: `create-new-access-key`,
            headers: {authorization: `Bearer ${accessToken}`},
            data
        });
        if (response.data.success) {
            yield put(createAccessKeySuccess(response.data.result));
            yield put(resetAccessKeyForm());
        } else {
            // yield put(createOrganisationFailed());
        }
    } catch (error) {
        yield put(setShowApiErrorModal());
        console.log(error);
        // if (error.response && error.response.data && error.response.data.error) {
        //     yield put(loginError(error.response.data.error));
        // } else {
        //     yield put(loginError("Something went Wrong!"));
        // }
        // yield put(createOrganisationFailed());
    }
}

export function* watchGetAccessKeys() {
    yield takeEvery(GET_ACCESS_KEYS, getAccessKeys);
}

export function* watchCreateAccessKey() {
    yield takeEvery(CREATE_ACCESS_KEY, createAccessKey);
}

function* accessKeySaga() {
    yield all([
        fork(watchGetAccessKeys),
        fork(watchCreateAccessKey)
    ]);
}

export default accessKeySaga;
