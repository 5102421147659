import { takeEvery, fork, put, all, call } from 'redux-saga/effects';
import axios from 'axios';

import {
    GET_PAYMENT_GATEWAYS,
    SAVE_PAYMENT_GATEWAY
} from "./actionTypes";

import {
    getPaymentGatewaysSuccess,
    savePaymentGatewaySuccess
} from './actions';
import { setShowApiErrorModal } from '../common/actions';

// AUTH related methods
import { getFirebaseBackend } from '../../helpers/authUtils';

const fireBaseBackend = getFirebaseBackend();

function* getPaymentGateways() {
    try {
    	const accessToken = yield fireBaseBackend.getAccessToken();
        const response = yield axios({
            method: "GET",
            url: `settings/payment-gateways`,
            headers: {authorization: `Bearer ${accessToken}`}
        });
        if (response.data.success) {
        	yield put(getPaymentGatewaysSuccess(response.data.result));
        } else {
            // yield put(createOrganisationFailed());
        }
    } catch (error) {
        yield put(setShowApiErrorModal());
        console.log(error);
        // if (error.response && error.response.data && error.response.data.error) {
        //     yield put(loginError(error.response.data.error));
        // } else {
        //     yield put(loginError("Something went Wrong!"));
        // }
        // yield put(createOrganisationFailed());
    }
}

function* savePaymentGateway({payload: data}) {
    try {
        const accessToken = yield fireBaseBackend.getAccessToken();
        const response = yield axios({
            method: "POST",
            url: `settings/update-payment-gateway`,
            headers: {authorization: `Bearer ${accessToken}`},
            data
        });
        if (response.data.success) {
            yield put(savePaymentGatewaySuccess());
        } else {
            // yield put(createOrganisationFailed());
        }
    } catch (error) {
        yield put(setShowApiErrorModal());
        console.log(error);
        // if (error.response && error.response.data && error.response.data.error) {
        //     yield put(loginError(error.response.data.error));
        // } else {
        //     yield put(loginError("Something went Wrong!"));
        // }
        // yield put(createOrganisationFailed());
    }
}

export function* watchGetPaymentGateways() {
    yield takeEvery(GET_PAYMENT_GATEWAYS, getPaymentGateways);
}

export function* watchSavePaymentGateway() {
    yield takeEvery(SAVE_PAYMENT_GATEWAY, savePaymentGateway);
}

function* settingSaga() {
    yield all([
        fork(watchGetPaymentGateways),
        fork(watchSavePaymentGateway),
    ]);
}

export default settingSaga;
