import SimpleReactValidator from "simple-react-validator";
import { getFirebaseBackend } from "./helpers/authUtils";

export const loadScript = (url, callback) => {
  let script = document.createElement("script");
  script.type = "text/javascript";

  if (script.readyState) {
    script.onreadystatechange = function () {
      if (script.readyState === "loaded" || script.readyState === "complete") {
        script.onreadystatechange = null;
        callback();
      }
    };
  } else {
    script.onload = () => callback();
  }

  script.src = url;
  document.getElementsByTagName("head")[0].appendChild(script);
};

export const utcDateToCurrentDate = (date) => {
  if (typeof date === "string") date = new Date();
  return `${date.getFullYear()}-${("0" + (date.getMonth() + 1)).slice(-2)}-${(
    "0" + date.getDate()
  ).slice(-2)}`;
};

export const ucFirst = (word) => word.charAt(0).toUpperCase() + word.slice(1); // Upper Case First Letter

export const buildCalculatePricingJson = (
  services,
  servicesByCategory,
  pricingDrivers,
  props,
  paymentFrequency
) => {
  let json = {};
  json.paymentFrequency = paymentFrequency;
  console.log("paymentFrequency - ", paymentFrequency);
  services.forEach((item, index) => {
    let categoryServiceSplit = item.split("-");
    if (json.hasOwnProperty(categoryServiceSplit[0])) {
      json[categoryServiceSplit[0]].services[categoryServiceSplit[1]] = {
        a: 1,
      };
    } else {
      json[categoryServiceSplit[0]] = {
        categoryName: servicesByCategory[categoryServiceSplit[0]].categoryName,
        services: {},
      };
      json[categoryServiceSplit[0]].services[categoryServiceSplit[1]] = {
        a: 1,
      };
    }
    // if (categoryServiceSplit[2]==="recurring") {
    //   json[categoryServiceSplit[0]].services[categoryServiceSplit[1]].serviceChargeType = "recurring";
    // } else if (categoryServiceSplit[2]==="oneoff") {
    //   json[categoryServiceSplit[0]].services[categoryServiceSplit[1]].serviceChargeType = "oneoff";
    // }
  });
  pricingDrivers.forEach((item, index) => {
    let pricingDriverSplit = item.split("-");
    if (
      json[pricingDriverSplit[1]].services[
        pricingDriverSplit[2]
      ].hasOwnProperty("pricingDrivers")
    ) {
      json[pricingDriverSplit[1]].services[
        pricingDriverSplit[2]
      ].pricingDrivers[pricingDriverSplit[3]] = { value: props[item] };
    } else {
      json[pricingDriverSplit[1]].services[
        pricingDriverSplit[2]
      ].pricingDrivers = { [pricingDriverSplit[3]]: { value: props[item] } };
    }
    if (props[`${item}-${props[item]}_other`]) {
      json[pricingDriverSplit[1]].services[
        pricingDriverSplit[2]
      ].pricingDrivers[pricingDriverSplit[3]].other =
        props[`${item}-${props[item]}_other`];
    }
  });
  for (let categoryKey in servicesByCategory) {
    for (let service of servicesByCategory[categoryKey].services) {
      if (json[categoryKey] && json[categoryKey].services[service.id]) {
        json[categoryKey].services[service.id].serviceName = service.name;
        // json[categoryKey].services[service.id].serviceChargeType = service.chargeType;
        // json[categoryKey].services[service.id].serviceChargeType = categoryServiceSplit[2];
        if (service.pricingDrivers) {
          for (let pricingDriver of service.pricingDrivers) {
            if (
              json[categoryKey].services[service.id].pricingDrivers &&
              json[categoryKey].services[service.id].pricingDrivers[
              pricingDriver._id
              ]
            ) {
              json[categoryKey].services[service.id].pricingDrivers[
                pricingDriver._id
              ].name = pricingDriver.name;
              json[categoryKey].services[service.id].pricingDrivers[
                pricingDriver._id
              ].type = pricingDriver.type;
              if (pricingDriver.type === "Variation") {
                json[categoryKey].services[service.id].pricingDrivers[
                  pricingDriver._id
                ].variations = pricingDriver.variations;
              }
              if (pricingDriver.type === "Slab") {
                json[categoryKey].services[service.id].pricingDrivers[
                  pricingDriver._id
                ].slabs = pricingDriver.slabs;
              }
              if (pricingDriver.type === "Number Range") {
                json[categoryKey].services[service.id].pricingDrivers[
                  pricingDriver._id
                ].numberRanges = pricingDriver.number_ranges;
              }
            }
          }
        }
      }
    }
  }
  return json;
};

export const buildGlobalPricingDriversJson = (pricingDrivers, props) => {
  let json = {};
  console.log("pricingDrivers - ", pricingDrivers, props);
  pricingDrivers.forEach((_item, index) => {
    let item = _item;
    // if(_item.coupled && _item.globalDriverId) {
    //   item = _item.globalDriverId;
    // }
    if (!json.hasOwnProperty(item._id)) {
      json[item._id] = {};
    }
    if (props[`globalPricingDriver-${item._id}`]) {
      json[item._id].value = props[`globalPricingDriver-${item._id}`];
    } else {
      json[item._id].value = item.value;
    }
    // json[item._id].value = props[`globalPricingDriver-${item._id}`];
    json[item._id].name = item.name;
    json[item._id].type = item.type;
    json[item._id].services =
      !json[item._id].services || json[item._id].services.length === 0
        ? item.services
        : json[item._id].services.concat(item.services);
    json[item._id].services = [...new Set(json[item._id].services)];
    if (item.type === "Variation") {
      json[item._id].variations = item.variations;
    } else if (item.type === "Slab") {
      json[item._id].slabs = item.slabs;
      json[item._id].other = item.other;
    } else if (item.type === "Number Range") {
      json[item._id].number_ranges = item.number_ranges;
    }
    if (
      props[
      `globalPricingDriver-${item._id}-${props[`globalPricingDriver-${item._id}`]
      }_other`
      ]
    ) {
      json[item._id].other =
        props[
        `globalPricingDriver-${item._id}-${props[`globalPricingDriver-${item._id}`]
        }_other`
        ];
    }
  });
  return json;
};

export const isAddressEmpty = (
  premises,
  addressLine1,
  addressLine2,
  locality,
  region,
  country,
  postcode
) =>
  (premises === "" || !premises) &&
  (addressLine1 === "" || !addressLine1) &&
  (addressLine2 === "" || !addressLine2) &&
  (locality === "" || !locality) &&
  (region === "" || !region) &&
  (country === "" || !country) &&
  (postcode === "" || !postcode);

export const formatAddress = (
  premises,
  addressLine1,
  addressLine2,
  locality,
  region,
  country,
  postcode
) =>
  `${premises && premises !== ""
    ? `${premises}${isNaN(premises) ? ", " : " "}`
    : ""
    }${addressLine1 && addressLine1 !== "" ? `${addressLine1}, ` : ""}${addressLine2 && addressLine2 !== "" ? `${addressLine2}, ` : ""
    }${locality && locality !== "" ? `${locality}, ` : ""}${region && region !== "" ? `${region}, ` : ""
    }${country && country !== "" ? `${country}, ` : ""}${postcode && postcode !== "" ? `${postcode}, ` : ""
    }`.slice(0, -2);

export const formatDate = (date) => {
  let splitDate = date.split("T")[0].split("-");
  return `${splitDate[2]}-${splitDate[1]}-${splitDate[0]}`;
};

export const formatDateinDDMMYY = (date) => {
  let objectDate = new Date(date);

  let day =
    objectDate.getDate() > 9
      ? objectDate.getDate()
      : "0" + objectDate.getDate();

  let month =
    objectDate.getMonth() + 1 > 9
      ? objectDate.getMonth() + 1
      : "0" + (objectDate.getMonth() + 1);

  let year = objectDate.getFullYear();

  return day + "/" + month + "/" + year;
};

export const sleep = (ms) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

export const getValidator = () => {
  return new SimpleReactValidator({
    validators: {
      _required: {
        // name the rule
        message: "This field is required.",
        rule: (val, params, validator) => !validator.helpers.isBlank(val),
        required: true, // optional
      },
      _email: {
        message: "This field must be a valid email address.",
        rule: (val, params, validator) =>
          validator.helpers.testRegex(
            val,
            /^[A-Z0-9.!#$%&'*+-/=?^_`{|}~]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i
          ),
      },
      _phone: {
        message: "This field must be a valid phone number.",
        rule: (val, params, validator) => {
          if (params[0] !== "+44")
            return validator.helpers.testRegex(val, /^\d{7,14}$/);
          else return validator.helpers.testRegex(val, /^0?\d{10}$/);
        },
      },
      _name: {
        message: "This field must be a valid name.",
        rule: (val, params, validator) =>
          validator.helpers.testRegex(
            val,
            /^(?![0-9\s]*$)[a-zA-Z0-9!@#$%^&*()+_.-\s]+$/
          ),
      },
      // ^[a-zA-Z0-9!@#$&()\\-`.+,/\"]*$
      // a-zA-Z0-9\s]+$/
      // /^(?![0-9]*$)[a-zA-Z0-9]+$/ OR /^[A-Za-z\s]*$/
      _url: {
        message: "This field must be an url.",
        rule: (val, params, validator) =>
          validator.helpers.testRegex(
            val,
            /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/i
          ),
      },
      // _requiredVariation: {  // name the rule
      //   message: 'At least 1 variation is required.',
      //   rule: (val, params, validator) => !validator.helpers.isBlank(val),
      //   required: true  // optional
      // },
      _requiredArray: {
        // name the rule
        message: "At least 1 :param is required.",
        rule: (val, params, validator) => !validator.helpers.isBlank(val),
        messageReplace: (message, params) =>
          message.replace(":param", params[0]),
        required: true, // optional
      },
      _requiredArrayLength: {
        // name the rule
        message: "At least 1 authorised :param is required.",
        rule: (val, params, validator) => val.length > 0,
        messageReplace: (message, params) =>
          message.replace(":param", params[0]),
        required: true, // optional
      },
      _numeric: {
        message: "This field must be a number.",
        rule: (val, params, validator) =>
          validator.helpers.testRegex(val, /^\-?\d*\.?\d+$/),
      },
      _min: {
        message: "The field must not be less than :min.",
        rule: (val, params, validator) =>
          validator.helpers.size(val, params[1]) >= parseFloat(params[0]),
        messageReplace: (message, params) => message.replace(":min", params[0]),
      },
      _minPricing: {
        message: "The min. one off price can not be lower than £:min.",
        rule: (val, params, validator) =>
          validator.helpers.size(val, params[1]) >= parseFloat(params[0]),
        messageReplace: (message, params) => message.replace(":min", params[0]),
      },
      _minPricingMonth: {
        message: "The min. monthly price can not be lower than £:min.",
        rule: (val, params, validator) =>
          validator.helpers.size(val, params[1]) >= parseFloat(params[0]),
        messageReplace: (message, params) => message.replace(":min", params[0]),
      },
      _maxDiscount: {
        message: "The discount should not be greater than :max%.",
        rule: (val, params, validator) =>
          validator.helpers.size(val, params[1]) <= parseFloat(params[0]),
        messageReplace: (message, params, validator) =>
          message.replace(":max", params[0]),
      },
      _greater: {
        message: "The field must not be less than :min.",
        rule: (val, params, validator) =>
          validator.helpers.size(val, params[1]) > parseFloat(params[0]),
        messageReplace: (message, params) => message.replace(":min", params[0]),
      },
      _max: {
        message: "The field may not be greater than :max.",
        rule: (val, params, validator) =>
          validator.helpers.size(val, params[1]) <= parseFloat(params[0]),
        messageReplace: (message, params, validator) =>
          message.replace(":max", params[0]),
      },
      _date: {
        message: "Invalid Date",
        rule: (val, params, validator) => {
          let maxDate = new Date();
          maxDate.setMonth(maxDate.getMonth() + 3);
          return val > new Date(0) && val < maxDate;
        },
        messageReplace: (message, params, validator) =>
          message.replace(":max", params[0]),
      },
    },
  });
};

export const checkPricingDriversValid = (
  pricingDrivers,
  servicesByCategory,
  validator,
  parentProps
) => {
  let pricingDriversValid = true;
  console.log("pricingDrivers - ", pricingDrivers);
  for (let pricingDriver of pricingDrivers) {
    console.log("pricingDriver - ", validator, pricingDriver);
    if (!validator.fields[pricingDriver]) {
      pricingDriversValid = false;
      break;
    }
    let pricingDriverSplit = pricingDriver.split("-");
    let serviceId = pricingDriverSplit[2].split("_")[0];
    let slabs = servicesByCategory[pricingDriverSplit[1]].services
      .filter((item) => item.id === serviceId)[0]
      .pricingDrivers.filter(
        (item) => item._id === pricingDriverSplit[3]
      )[0].slabs;
    if (slabs) {
      slabs = slabs.filter((item) => item._id === parentProps[pricingDriver]);
      if (
        slabs.length > 0 &&
        slabs[0].type === "IncrementalBlock" &&
        !validator.fields[
        `${pricingDriver}-${parentProps[pricingDriver]}_other`
        ]
      ) {
        pricingDriversValid = false;
        break;
      }
    }
  }
  console.log("pricingDriversValid - ", pricingDriversValid);
  return pricingDriversValid;
};

export const checkGlobalPricingDriversValid = (
  pricingDrivers,
  validator,
  parentProps
) => {
  let pricingDriversValid = true;
  console.log("pricingDrivers - ", pricingDrivers);

  const involvedGlobalDriverIds = pricingDrivers.filter(
    (c) => validator.fields[`globalPricingDriver-${c._id}`]
  );

  const getGlobalDriverId = (item) =>
    item && item.globalDriverId ? item.globalDriverId._id : item._id;

  const pricingDriverIds = pricingDrivers.reduce((acc, cur) => {
    const involvedDriver = involvedGlobalDriverIds.find(
      (c) => getGlobalDriverId(c) === getGlobalDriverId(cur)
    );
    if (!involvedDriver) return acc;
    acc[cur._id] = involvedDriver._id;
    return acc;
  }, {});

  console.log("pricingDriverIds - ", pricingDriverIds);

  // return true;
  for (let pricingDriver of pricingDrivers) {
    let idToCheck = pricingDriver._id;
    // if(pricingDriver.coupled && pricingDriver.globalDriverId) {
    //   idToCheck = pricingDriver.globalDriverId._id
    // }
    if (
      !validator.fields[`globalPricingDriver-${idToCheck}`] &&
      !validator.fields[`globalPricingDriver-${pricingDriverIds[idToCheck]}`]
    ) {
      console.log(
        "validator.fields - ",
        validator.fields,
        pricingDriver,
        idToCheck
      );
      pricingDriversValid = false;
      break;
    }
    let slabs = pricingDriver.slabs;
    if (slabs) {
      slabs = slabs.filter(
        (item) => item._id === parentProps[`globalPricingDriver-${idToCheck}`]
      );
      if (
        slabs.length > 0 &&
        slabs[0].type === "IncrementalBlock" &&
        !validator.fields[
        `globalPricingDriver-${idToCheck}-${parentProps[`globalPricingDriver-${idToCheck}`]
        }_other`
        ]
      ) {
        pricingDriversValid = false;
        break;
      }
    }
  }
  return pricingDriversValid;
};

export const stringifyNumber = (n) => {
  const special = [
    "zeroth",
    "first",
    "second",
    "third",
    "fourth",
    "fifth",
    "sixth",
    "seventh",
    "eighth",
    "ninth",
    "tenth",
    "eleventh",
    "twelfth",
    "thirteenth",
    "fourteenth",
    "fifteenth",
    "sixteenth",
    "seventeenth",
    "eighteenth",
    "nineteenth",
  ];
  const deca = [
    "twent",
    "thirt",
    "fort",
    "fift",
    "sixt",
    "sevent",
    "eight",
    "ninet",
  ];
  let numStr;
  if (n < 20) return ucFirst(special[n]);
  if (n % 10 === 0) return ucFirst(deca[Math.floor(n / 10) - 2] + "ieth");
  return ucFirst(deca[Math.floor(n / 10) - 2] + "y-" + special[n % 10]);
};

export const getPaymentFrequencyMulitplier = (paymentFrequency) => {
  let frequencyMultiplier = 1;
  if (paymentFrequency) {
    switch (paymentFrequency) {
      case "yearly":
        frequencyMultiplier = 1;
        break;
      case "halfYearly":
        frequencyMultiplier = 0.5;
        break;
      case "quarterly":
        frequencyMultiplier = 0.25;
        break;
      case "monthly":
        frequencyMultiplier = 1 / 12;
        break;
      default:
        frequencyMultiplier = 1;
        break;
    }
  }
  return frequencyMultiplier;
};

export function debounce(callback, delay) {
  let timeout;
  return function (...params) {
    clearTimeout(timeout);
    timeout = setTimeout(() => callback(...params), delay);
  };
}

export const formatCurrency = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "GBP",
}).format;

export const formatCurrencyOfYourChoice = (currency) =>
  new Intl.NumberFormat("en-US", {
    style: "currency",
    currency,
  }).format;

export const formatValue = (value) => {
  const val = value ? +value.toString().split(",").join("") : 0;

  if (isNaN(val)) return value;

  return new Intl.NumberFormat("en-US", {}).format(val);
};

export const getUserRole = () => {
  return getFirebaseBackend().getUserRole();
};

export const getPhoneObject = (dialCode, number) => {
  if (
    dialCode &&
    number &&
    dialCode === "+44" &&
    number.length === 11 &&
    number[0] === "0"
  ) {
    return { code: dialCode, number: number.slice(1) };
  } else if (dialCode && number && number !== "") {
    return { code: dialCode, number };
  } else {
    return null;
  }
};
// export const calculatePackagePrice = (data) => {
//   const fireBaseBackend = getFirebaseBackend();
//   const accessToken = fireBaseBackend.getAccessToken();
//   axios({
//     method: "POST",
//     url: "calculate-package-price",
//     headers: {authorization: `Bearer ${accessToken}`},
//     data
//   })
//   .then(response => {
//     console.log(response.data);
//   })
//   .catch(err => {
//     console.log(err);
//   });
// }
