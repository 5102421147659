import {
  FORGET_PASSWORD,
  FORGET_PASSWORD_SUCCESS,
  FORGET_PASSWORD_ERROR,
  RESET_PASSWORD,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_ERROR,
  RESET_FORGET_PASSWORD_FORM,
  VALIDATE_RESET_TOKEN,
  VALIDATE_RESET_TOKEN_SUCCESS,
  VALIDATE_RESET_TOKEN_FAILURE,
} from "./actionTypes";

export const userForgetPassword = (email, callback) => {
  return {
    type: FORGET_PASSWORD,
    payload: { email, callback },
  };
};

export const userForgetPasswordSuccess = (message) => {
  return {
    type: FORGET_PASSWORD_SUCCESS,
    payload: message,
  };
};

export const userForgetPasswordError = (message) => {
  return {
    type: FORGET_PASSWORD_ERROR,
    payload: message,
  };
};

export const userResetPassword = (userId, password, token, callback) => {
  return {
    type: RESET_PASSWORD,
    payload: {
      userId,
      password,
      token,
      callback,
    },
  };
};

export const userResetPasswordSuccess = (message) => {
  return {
    type: RESET_PASSWORD_SUCCESS,
    payload: message,
  };
};

export const userResetPasswordError = (message) => {
  return {
    type: RESET_PASSWORD_ERROR,
    payload: message,
  };
};

export const resetForgotPasswordForm = () => {
  return {
    type: RESET_FORGET_PASSWORD_FORM,
  };
};

export const validateResetToken = (userId, token) => {
  return {
    type: VALIDATE_RESET_TOKEN,
    payload: {
      userId,
      token,
    },
  };
};

export const validateResetTokenFailure = () => {
  return {
    type: VALIDATE_RESET_TOKEN_FAILURE,
    payload:
      "The link has expired.Please request a new link if you want to reset password again.",
  };
};

export const validateResetTokenSuccess = () => {
  return {
    type: VALIDATE_RESET_TOKEN_SUCCESS,
  };
};
