import {
  ACTIVATE_USER,
  ACTIVATE_USER_SUCCESSFUL,
  ACTIVATE_USER_FAILED,
  VALIDATE_TOKEN_ACTIVATE_USER,
  VALIDATE_ACTIVE_TOKEN_SUCCESS,
  VALIDATE_ACTIVE_TOKEN_FAILED,
} from "./actionTypes";

const initialState = {
  activationError: null,
  message: null,
  loading: false,
  validateTokenLoading: true,
  tokenExpired: false,
};

const account = (state = initialState, action) => {
  switch (action.type) {
    case ACTIVATE_USER:
      state = {
        ...state,
        loading: true,
        activationError: null,
      };
      break;
    case ACTIVATE_USER_SUCCESSFUL:
      state = {
        ...state,
        loading: false,
        activationError: null,
      };
      break;
    case ACTIVATE_USER_FAILED:
      state = {
        ...state,
        loading: false,
        activationError: action.payload,
      };
      break;
    case VALIDATE_ACTIVE_TOKEN_SUCCESS:
      state = {
        ...state,
        validateTokenLoading: false,
        tokenExpired: false,
      };
      break;
    case VALIDATE_ACTIVE_TOKEN_FAILED:
      state = {
        ...state,
        validateTokenLoading: false,
        tokenExpired: true,
      };
      break;
    case VALIDATE_TOKEN_ACTIVATE_USER:
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default account;
