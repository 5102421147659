import {
    REGISTER_USER,
    REGISTER_USER_SUCCESSFUL,
    REGISTER_USER_FAILED,
    TOGGLE_REGISTRATION_MODAL,
    ON_REGISTER_INPUT_CHANGE,
    REGISTER_ERROR,
    RESET_REGISTER_FORM
} from './actionTypes';

export const onInputChange = (field, value) => {
    return {
        type: ON_REGISTER_INPUT_CHANGE,
        payload: {
            field,
            value
        }
    }
}

export const registerUser = (firstName, lastName, email, phone, country, history) => {
    return {
        type: REGISTER_USER,
        payload: { firstName, lastName, email, phone, country, history }
    }
}

export const registerUserSuccessful = (user) => {
    return {
        type: REGISTER_USER_SUCCESSFUL,
        payload: user
    }
}


export const registerUserFailed = (user) => {
    return {
        type: REGISTER_USER_FAILED,
        payload: user
    }
}

export const toggleRegistrationModal = () => {
    return {
        type: TOGGLE_REGISTRATION_MODAL
    }
}

export const registerError = (error) => {
    return {
        type: REGISTER_ERROR,
        payload: error
    }
}

export const resetRegisterForm = () => {
    return {
        type: RESET_REGISTER_FORM
    }
}

