import React, { useEffect, useState } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import { Modal, ModalBody } from "reactstrap";
import { connect } from "react-redux";
import {
  setShowConfirmActiveDeActiveModal,
  activeDeActiveObject,
} from "../store/activeDeActive/actions";
import Loader from "./Loader";

const ConfirmActiveDeActiveModal = (props) => {
  const [flag, setFlag] = useState(false);

  useEffect(() => {
    if (props.servicesAffectedPackages) {
      setTimeout(() => {
        setFlag(true);
      }, 1000);
    }
    return () => {
      setFlag(false);
    };
  }, [props.showConfirmActiveDeActiveModal]);

  if (props.showConfirmActiveDeActiveModal && !flag) {
    return <Loader />;
  }

  return props.showConfirmActiveDeActiveModal ? (

    <SweetAlert
      title=""
      warning={props.servicesAffectedPackages?.length > 0 || props?.ContractCount > 0 || props?.QuoteCount > 0}
      danger={props.servicesAffectedPackages?.length == 0 && (!props?.ContractCount || props?.ContractCount == 0) && (!props?.QuoteCount || props?.QuoteCount == 0)}
      cancelBtnBsStyle={
        props.servicesAffectedPackages?.length > 0 || props?.ContractCount > 0 || props?.QuoteCount > 0 ? "warning" : "danger"
      }
      confirmBtnBsStyle="success"
      showCancel
      showConfirm={props.servicesAffectedPackages?.length > 0 || props?.ContractCount > 0 || props?.QuoteCount > 0 ? false : true}
      onConfirm={() => {
        props.activeDeActiveObject(props.id, props.module);
        props.setShowConfirmActiveDeActiveModal(false);
        setFlag(false);
      }}
      onCancel={() => {
        props.setShowConfirmActiveDeActiveModal(false);
        setFlag(false);
      }}
    >
      <>
        {props.servicesAffectedPackages?.length > 0 || props?.ContractCount > 0 || props?.QuoteCount > 0 ? (
          <div className="mb-2 success-alert-message text-left">
            {props.servicesAffectedPackages?.length > 0 ? (
              <>
                <div style={{ marginBottom: "1rem" }}>
                  This service is used in below packages. You must remove the service from these packages before you can deactivate it.
                </div>
                <ul>
                  {props.servicesAffectedPackages &&
                    props.servicesAffectedPackages.length > 0 &&
                    props.servicesAffectedPackages.map((item) => (
                      <li key={item.id}>{item.name}</li>
                    ))}
                </ul>
              </>
            ) : (
              <>
                <div style={{ marginBottom: "1rem" }}>
                  {`This service is used in some ${props?.QuoteCount > 0 ? `Quotes` : 'Contracts'}. You must remove the service from those ${props?.QuoteCount > 0 ? `Quotes` : 'Contracts'} before you can deactivate it.`}
                </div>
              </>
            )}
          </div>
        )
          :
          <div>Are you sure you want to deactive this?</div>
        }
      </>
    </SweetAlert>
  ) : null;
};

const mapStateToProps = (state) => {
  const { showConfirmActiveDeActiveModal, id, module } = state.ActiveDeActive;
  return { showConfirmActiveDeActiveModal, id, module };
};

export default connect(mapStateToProps, {
  setShowConfirmActiveDeActiveModal,
  activeDeActiveObject,
})(ConfirmActiveDeActiveModal);
