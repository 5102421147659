import { select, takeEvery, fork, put, all, call } from 'redux-saga/effects';
import axios from 'axios';

import {
    GET_PACKAGES,
    SUBMIT_PACKAGE,
    GET_PACKAGE,
    GET_GLOBAL_DRIVERS_FOR_CREATE_PACKAGE
} from "./actionTypes";

import {
    getPackagesSuccess, submitPackageSuccess, getPackageSuccess,createPackageFailed
} from './actions';
import { setShowApiErrorModal } from '../common/actions';

// AUTH related methods
import { getFirebaseBackend } from '../../helpers/authUtils';

const fireBaseBackend = getFirebaseBackend();

function* getPackages({ payload: page_num }) {
    try {
        const accessToken = yield fireBaseBackend.getAccessToken();
        const response = yield axios({
            method: "GET",
            url: `packages?page_num=${page_num}`,
            headers: {authorization: `Bearer ${accessToken}`}
        });
        if (response.data.success) {
            yield put(getPackagesSuccess(response.data.result));
        } else {
            // yield put(createOrganisationFailed());
        }
    } catch (error) {
        yield put(setShowApiErrorModal());
        // if (error.response && error.response.data && error.response.data.error) {
        //     yield put(loginError(error.response.data.error));
        // } else {
        //     yield put(loginError("Something went Wrong!"));
        // }
        // yield put(createOrganisationFailed());
    }
}

function* submitPackage({payload: {data, type}}) {
    try {
        const accessToken = yield fireBaseBackend.getAccessToken();
        let url;
        switch(type) {
            case "new":
                url = "create-new-package";
                break;
            case "edit":
                url = "update-package";
                break;
            default:
                break;
        }
        const response = yield axios({
            method: "POST",
            url,
            headers: {authorization: `Bearer ${accessToken}`},
            data
        });
        if (response.data.success) {
            yield put(submitPackageSuccess());
        } else {
            yield put(createPackageFailed(response.data.error));
            // yield put(createOrganisationFailed());
        }
    } catch (error) {
        yield put(setShowApiErrorModal());
        // if (error.response && error.response.data && error.response.data.error) {
        //     yield put(loginError(error.response.data.error));
        // } else {
        //     yield put(loginError("Something went Wrong!"));
        // }
        // yield put(createOrganisationFailed());
    }
}

function* getPackage({payload: id}) {
    try {
        const accessToken = yield fireBaseBackend.getAccessToken();
        const response = yield axios({
            method: "GET",
            url: `package/${id}`,
            headers: {authorization: `Bearer ${accessToken}`}
        });
        if (response.data.success) {
            const getServiceStore = state => state.Service;
            const serviceStore = yield select(getServiceStore);         
            yield put(getPackageSuccess(response.data.result, serviceStore.servicesByCategory));
        } else {
            // yield put(createOrganisationFailed());
        }
    } catch (error) {
        yield put(setShowApiErrorModal());
        console.log(error);
        // if (error.response && error.response.data && error.response.data.error) {
        //     yield put(loginError(error.response.data.error));
        // } else {
        //     yield put(loginError("Something went Wrong!"));
        // }
        // yield put(createOrganisationFailed());
    }
}

export function* watchGetPackages() {
    yield takeEvery(GET_PACKAGES, getPackages);
}

export function* watchSubmitPackage() {
    yield takeEvery(SUBMIT_PACKAGE, submitPackage);
}

export function* watchGetPackage() {
    yield takeEvery(GET_PACKAGE, getPackage);
}

function* serviceSaga() {
    yield all([
        fork(watchGetPackages),
        fork(watchSubmitPackage),
        fork(watchGetPackage)
    ]);
}

export default serviceSaga;

