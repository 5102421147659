export const SET_TERMS_AND_CONDITION_TEMPLATE_LOADING = 'SET_TERMS_AND_CONDITION_TEMPLATE_LOADING';
export const ON_TERMS_AND_CONDITION_TEMPLATE_INPUT_CHANGE = 'ON_TERMS_AND_CONDITION_TEMPLATE_INPUT_CHANGE';
export const GET_TERMS_AND_CONDITION_TEMPLATES = 'GET_TERMS_AND_CONDITION_TEMPLATES';
export const GET_TERMS_AND_CONDITION_TEMPLATES_SUCCESS = 'GET_TERMS_AND_CONDITION_TEMPLATES_SUCCESS';
export const GET_TERMS_AND_CONDITION_TEMPLATE = 'GET_TERMS_AND_CONDITION_TEMPLATE';
export const GET_TERMS_AND_CONDITION_TEMPLATE_SUCCESS = 'GET_TERMS_AND_CONDITION_TEMPLATE_SUCCESS';
export const SUBMIT_TERMS_AND_CONDITION_TEMPLATE = 'SUBMIT_TERMS_AND_CONDITION_TEMPLATE';
export const SUBMIT_TERMS_AND_CONDITION_TEMPLATE_SUCCESS = 'SUBMIT_TERMS_AND_CONDITION_TEMPLATE_SUCCESS';
export const RESET_CREATE_NEW_TERMS_AND_CONDITION_TEMPLATE_FORM = 'RESET_CREATE_NEW_TERMS_AND_CONDITION_TEMPLATE_FORM';
export const SET_SHOW_CREATE_NEW_TERMS_AND_CONDITION_SUCCESS_MODAL = 'SET_SHOW_CREATE_NEW_TERMS_AND_CONDITION_SUCCESS_MODAL';
export const UPLOAD_TERMS_AND_CONDITION_TEMPLATE = 'UPLOAD_TERMS_AND_CONDITION_TEMPLATE';
export const UPLOAD_TERMS_AND_CONDITION_TEMPLATE_SUCCESS = 'UPLOAD_TERMS_AND_CONDITION_TEMPLATE_SUCCESS';
export const DELETE_TERMS_AND_CONDITION_TEMPLATE = 'DELETE_TERMS_AND_CONDITION_TEMPLATE';
export const DELETE_TERMS_AND_CONDITION_TEMPLATE_SUCCESS = 'DELETE_TERMS_AND_CONDITION_TEMPLATE_SUCCESS';
export const CREATE_NEW_TERMS_AND_CONDITIONS_FAIL = "CREATE_NEW_TERMS_AND_CONDITIONS_FAIL";
export const SET_SHOW_CREATE_NEW_TERMS_AND_CONDITIONS_FAILED_MODAL = "SET_SHOW_CREATE_NEW_TERMS_AND_CONDITIONS_FAILED_MODAL";
