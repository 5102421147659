import {
	SET_PACKAGE_LOADING,
	ON_PACKAGE_INPUT_CHANGE,
	SET_PACKAGE_ACTIVE_STEP,
	GET_PACKAGES,
	GET_PACKAGES_SUCCESS,
	SUBMIT_PACKAGE,
	SUBMIT_PACKAGE_SUCCESS,
	SET_SHOW_CREATE_NEW_PACKAGE_SUCCESS_MODAL,
	ADD_SERVICE_TO_PACKAGE,
	REMOVE_SERVICE_FROM_PACKAGE,
	GET_PACKAGE,
	GET_PACKAGE_SUCCESS,
	RESET_CREATE_NEW_PACKAGE_FORM,
	CREATE_NEW_PACKAGE_FAIL,
	SET_SHOW_CREATE_NEW_PACKAGE_FAILED_MODAL
} from "./actionTypes";

const initialState = {
	loading: false,
	linkedServices:[],
	activeStep: 1,
	showCreateNewPackageSuccessModal: false,
	showCreateNewPackageFailedModal: false,
	packages: [],
	services: [],
	pricingDrivers: [],
	pagination: {}
}

const _package = (state = initialState, action) => {
	let newState;
	switch (action.type) {
        case SET_PACKAGE_LOADING:
            return {...state, loading: action.payload}
		case ON_PACKAGE_INPUT_CHANGE:
			if(action.payload.field instanceof Array) {
				let obj = action.payload.field.reduce((acc, cur) => {
						acc[cur] = action.payload.value
						return acc;
				}, {})
				console.log('obj - ', obj);
				return {...state, ...obj};
		}
			return {...state, [action.payload.field]: action.payload.value};
		case SET_PACKAGE_ACTIVE_STEP:
			return { ...state, activeStep: action.payload }
		case GET_PACKAGES:
			return {...state, loading: true}
		case GET_PACKAGES_SUCCESS:
			return { ...state, loading: false, packages: action.payload.packages, pagination: action.payload }
		case SUBMIT_PACKAGE:
			return { ...state, loading: true }
		case SUBMIT_PACKAGE_SUCCESS:
			return { ...state, loading: false, showCreateNewPackageSuccessModal: true }
		case SET_SHOW_CREATE_NEW_PACKAGE_SUCCESS_MODAL:
			return { ...state, showCreateNewPackageSuccessModal: action.payload }
		case CREATE_NEW_PACKAGE_FAIL:
			return (newState = {
					...state,
					loading: false,
					showCreateNewPackageFailedModal: true,
				  });
		case SET_SHOW_CREATE_NEW_PACKAGE_FAILED_MODAL:
            return { ...state, showCreateNewPackageFailedModal: action.payload };
		case ADD_SERVICE_TO_PACKAGE:
			newState = { ...state, services: [...state.services, action.payload.service], pricingDrivers: [...state.pricingDrivers].concat(action.payload.pricingDriverKeys) }
			action.payload.pricingDriverKeys.forEach(item => {
				newState[item] = null;
			});
			return newState
		case REMOVE_SERVICE_FROM_PACKAGE:
			return { ...state, services: state.services.filter(item => item!==action.payload.service), pricingDrivers: state.pricingDrivers.filter(item => !action.payload.pricingDriverKeys.includes(item)) }
		case GET_PACKAGE:
			return { ...state, loading: true }
		case GET_PACKAGE_SUCCESS:
			// console.log(action.payload);
			// console.log(action.payload.servicesByCategory);
			newState = { ...state, loading: false };
			newState.activeStep = 1;
			newState.packageId = action.payload.package._id;
			newState.packageName = action.payload.package.name;
			newState.packageIsActive = action.payload.package.is_active;
			newState.packageRecurringDefaultPrice = action.payload.package.recurring_default_price;
			newState.packageRecurringMinPrice = action.payload.package.recurring_min_price;
			newState.packageOneOffDefaultPrice = action.payload.package.one_off_default_price;
			newState.packageOneOffMinPrice = action.payload.package.one_off_min_price;
			// newState.linkedServices = action.payload.linkedServices;			
			newState.services = [];
			action.payload.packageServiceMap.forEach((item, index) => {
				newState.services.push(`${item.category}-${item.service}_${item.charge_type}`);
				for (let key in item.defaults) {
					if (!key.includes("_other")) {
						let inputVariableName = `pricingDriver-${item.category}-${item.service}_${item.charge_type}-${key}`;
						newState[inputVariableName] = item.defaults[key];
						if (item.defaults[`${item.defaults[key]}_other`]) {
							newState[`${inputVariableName}-${item.defaults[key]}_other`] = item.defaults[`${item.defaults[key]}_other`];
						}
					}
				}
			});
			let requiredPricingDrivers = new Set();
			for (let categoryKey in action.payload.servicesByCategory) {
				for (let service of action.payload.servicesByCategory[categoryKey].services) {
					for (let chargeType of ["recurring", "oneoff"]) {
						if (newState.services.includes(`${categoryKey}-${service.id}_${chargeType}`)) {
							if (service.hasOwnProperty("pricingDrivers")) {
								for (let key of service.pricingDrivers) {
									if(!key.globalDriverId) {
									requiredPricingDrivers.add(`pricingDriver-${categoryKey}-${service.id}_${chargeType}-${key._id}`)
								}
							}
							}
						}
					}
				}
			}
			newState.pricingDrivers = Array.from(requiredPricingDrivers);
			if (action.payload.package.global_defaults) {
				for (let driverKey in action.payload.package.global_defaults) {
					newState[`globalPricingDriver-${driverKey}`] = action.payload.package.global_defaults[driverKey].value;
					if (action.payload.package.global_defaults[driverKey].other) {
						newState[`globalPricingDriver-${driverKey}-${action.payload.package.global_defaults[driverKey].value}_other`] = action.payload.package.global_defaults[driverKey].other;
					}
				}
			}
			return newState
		case RESET_CREATE_NEW_PACKAGE_FORM:
			// newState = { ...state }
			// newState.activeStep = 1;
			// delete newState.packageId;
			// delete newState.packageName;
			// delete newState.packageIsActive;
			// newState.services = [];
			// newState.pricingDrivers = [];
			// return newState
			return initialState;
		default:
			return state;
	}
}

export default _package;
