import React from "react";
import { formatCurrency } from "../../../Utils";

const ReviewPackagesStep = (props) => {
  let tableHeaderRowItems = [<td key="header-0">Services</td>];
  let packageCount = 0;
  let totals = {};
  for (let packageKey in props.packages) {
    tableHeaderRowItems.push(
      <td key={`header-${packageKey}`}>
        {props.packages[packageKey].name
          ? props.packages[packageKey].name.substring(0, 30)
          : ""}
      </td>
    );
    totals[packageKey] = { total: 0 };
    packageCount++;
  }
  let jsx = [];
  for (let categoryKey in props.servicesByCategory) {
    let categoryJsx = (
      <tr key={categoryKey} className="a-la-carte-services-review-head-row">
        <th colSpan={packageCount + 1}>
          {props.servicesByCategory[categoryKey].categoryName
            ? props.servicesByCategory[categoryKey].categoryName.substring(
                0,
                30
              )
            : ""}
        </th>
      </tr>
    );
    let serviceJsx = [];
    for (let serviceKey in props.servicesByCategory[categoryKey].services) {
      let serviceChargeType = serviceKey.split("_")[1];
      if (serviceChargeType === props.chargeType) {
        let servicePriceJsx = [];
        for (let packageKey in props.packages) {
          let pricingDriverJsx = [];
          if (
            props.servicesByCategory[categoryKey].services[
              serviceKey
            ].packages.hasOwnProperty(packageKey) &&
            props.servicesByCategory[categoryKey].services[serviceKey].packages[
              packageKey
            ].hasOwnProperty("pricingDrivers")
          ) {
            for (let pricingDriverKey in props.servicesByCategory[categoryKey]
              .services[serviceKey].packages[packageKey].pricingDrivers) {
              let pricingDriverValue;
              if (
                props.servicesByCategory[categoryKey].services[serviceKey]
                  .packages[packageKey].pricingDrivers[pricingDriverKey]
                  .type === "Quantity"
              ) {
                pricingDriverValue =
                  props.servicesByCategory[categoryKey].services[serviceKey]
                    .packages[packageKey].pricingDrivers[pricingDriverKey]
                    .value;
              } else if (
                props.servicesByCategory[categoryKey].services[serviceKey]
                  .packages[packageKey].pricingDrivers[pricingDriverKey]
                  .type === "Variation"
              ) {
                for (let variation of props.servicesByCategory[categoryKey]
                  .services[serviceKey].packages[packageKey].pricingDrivers[
                  pricingDriverKey
                ].variations) {
                  if (
                    variation._id ===
                    props.servicesByCategory[categoryKey].services[serviceKey]
                      .packages[packageKey].pricingDrivers[pricingDriverKey]
                      .value
                  ) {
                    pricingDriverValue = variation.name;
                    break;
                  }
                }
              } else if (
                props.servicesByCategory[categoryKey].services[serviceKey]
                  .packages[packageKey].pricingDrivers[pricingDriverKey]
                  .type === "Slab"
              ) {
                for (let slab of props.servicesByCategory[categoryKey].services[
                  serviceKey
                ].packages[packageKey].pricingDrivers[pricingDriverKey].slabs) {
                  if (
                    slab._id ===
                    props.servicesByCategory[categoryKey].services[serviceKey]
                      .packages[packageKey].pricingDrivers[pricingDriverKey]
                      .value
                  ) {
                    if (slab.type === "BlockBased")
                      pricingDriverValue = `${slab.from}-${slab.to}`;
                    else if (slab.type === "IncrementalBlock")
                      pricingDriverValue =
                        props.servicesByCategory[categoryKey].services[
                          serviceKey
                        ].packages[packageKey].pricingDrivers[pricingDriverKey]
                          .other;
                    break;
                  }
                }
              }
              pricingDriverJsx
                .push
                // <div key={`${categoryKey}-${serviceKey}-${packageKey}-${pricingDriverKey}`}>{props.servicesByCategory[categoryKey].services[serviceKey].packages[packageKey].pricingDrivers[pricingDriverKey].name}: <b>{pricingDriverValue}</b></div>
                ();
            }
          }
          servicePriceJsx.push(
            <td key={`service-price-${packageKey}`}>
              <div>
                {props.servicesByCategory[categoryKey].services[
                  serviceKey
                ].packages.hasOwnProperty(packageKey) &&
                  props.flag && (
                    <span>
                      {formatCurrency(
                        props.servicesByCategory[categoryKey].services[
                          serviceKey
                        ].packages[packageKey].servicePrice
                      )}
                    </span>
                  )}
              </div>
              <div className="package-variables">{pricingDriverJsx}</div>
            </td>
          );
          totals[packageKey].total += +(props.servicesByCategory[
            categoryKey
          ].services[serviceKey].packages.hasOwnProperty(packageKey)
            ? props.servicesByCategory[categoryKey].services[serviceKey]
                .packages[packageKey].servicePrice
            : 0);
        }
        serviceJsx.push(
          <tr key={serviceKey}>
            <td>
              <div>
                {props.servicesByCategory[categoryKey].services[serviceKey]
                  .serviceName
                  ? props.servicesByCategory[categoryKey].services[
                      serviceKey
                    ].serviceName.substring(0, 30)
                  : ""}
              </div>
              <div className="package-variables">
                {/*<div>Employee Count: 1</div>
								<div>Invoice Count: 1-100</div>*/}
              </div>
            </td>
            {servicePriceJsx}
          </tr>
        );
      }
    }
    if (serviceJsx.length > 0) {
      jsx = jsx.concat(categoryJsx, serviceJsx);
    }
  }
  if (jsx.length > 0) {
    let totalJsx = [];
    let discountJsx = [];
    let afterDiscountJsx = [];
    let vatJsx = [];
    let finalTotalJsx = [];
    let discount = [];
    for (let totalKey in totals) {
      if (props.chargeType === "recurring") {
        totals[totalKey].afterDiscount = parseFloat(
          props.packages[totalKey].recurringDefaultPrice
        ).toFixed(2);
        totals[totalKey].discount = parseFloat(
          totals[totalKey].total - +totals[totalKey].afterDiscount
        ).toFixed(2);
      } else if (props.chargeType === "oneoff") {
        totals[totalKey].afterDiscount = parseFloat(
          props.packages[totalKey].oneOffDefaultPrice
        ).toFixed(2);
        totals[totalKey].discount = parseFloat(
          totals[totalKey].total - +totals[totalKey].afterDiscount
        ).toFixed(2);
      }
      if (totals[totalKey].discount >= 0) {
        totalJsx.push(
          <td key={`total-${totalKey}`}>
            {formatCurrency(totals[totalKey].total)}
          </td>
        );
        totals[totalKey].discount !==0 &&
        discountJsx.push(
          <td key={`discount-${totalKey}`}>
            (-) {formatCurrency(totals[totalKey].discount)}
          </td>
        );
      }

      afterDiscountJsx.push(
        <td key={`after-discount-${totalKey}`}>
          {formatCurrency(totals[totalKey].afterDiscount)}
        </td>
      );

      totals[totalKey].vat =
        window.localStorage.getItem("isVatRegistered") === "true"
          ? parseFloat(+totals[totalKey].afterDiscount * 0.2).toFixed(2)
          : 0;
      vatJsx.push(
        <td key={`vat-${totalKey}`}>{formatCurrency(totals[totalKey].vat)}</td>
      );

      totals[totalKey].finalTotal = parseFloat(
        +totals[totalKey].total -
          +totals[totalKey].discount +
          +totals[totalKey].vat
      ).toFixed(2);
      finalTotalJsx.push(
        <td key={`final-total-${totalKey}`}>
          {formatCurrency(totals[totalKey].finalTotal)}
        </td>
      );
    }
    if (totalJsx.length > 0) {
      jsx.push(
        <tr key={`total-${jsx.length}`} className="head-row">
          <td>Net Total</td>
          {totalJsx}
        </tr>
      );
    }
    // if (discountJsx.length > 0) {
    //   jsx.push(
    //     <tr key={`discount-${jsx.length}`} className="head-grey-row">
    //       <td>Discount</td>
    //       {discountJsx}
    //     </tr>
    //   );
    // }
  //   for (let totalKey in totals) {
  //     const discountValue = totals[totalKey].discount;
  //     // Check if discount value is greater than 0.00
  //     if (parseFloat(discountValue) > 0.00) {
  //         if (discountJsx.length > 0) {
  //             jsx.push(
  //                 <tr key={`discount-${jsx.length}`} className="head-grey-row">
  //                     <td>Discount</td>
  //                     {discountJsx}
  //                 </tr>
  //             );
  //             // // Break the loop after adding the discount JSX once
  //             // break;
  //         }
  //         jsx.push(
  //           <tr key={`discounted-total-${jsx.length}`} className="head-row">
  //             <td>{discountJsx.length >= 0 ? "Discounted Total" : "Net Total"}</td>
  //             {afterDiscountJsx}
  //           </tr>
  //         );
  //     }
  // }

  let hasDiscount = false; // Flag to track if the "Discount" row has been added
  for (let totalKey in totals) {
    const discountValue = totals[totalKey].discount;
  
    // Check if discount value is greater than 0.00
    if (parseFloat(discountValue) > 0.00) {
      if (!hasDiscount) {
        // Add the "Discount" row only once
        jsx.push(
          <tr key={`discount-${jsx.length}`} className="head-grey-row">
            <td>Discount</td>
            {discountJsx}
          </tr>
        );
       
  
      // Add the "Discounted Total" row
      jsx.push(
        <tr key={`discounted-total-${jsx.length}`} className="head-row">
          <td>{discountJsx.length > 0 ? "Discounted Total" : "Net Total"}</td>
          {afterDiscountJsx}
        </tr>
      );
      hasDiscount = true; // Set the flag to true
    }
    }
  }
    
    if (window.localStorage.getItem("isVatRegistered") === "true") {
      jsx.push(
        <tr key={`vat-${jsx.length}`} className="head-grey-row">
          <td>VAT</td>
          {vatJsx}
        </tr>
      );
      jsx.push(
        <tr key={`final-total-${jsx.length}`} className="head-row">
          <td>Grand Total</td>
          {finalTotalJsx}
        </tr>
      );
    }
  }
  return jsx.length === 0 ? null : (
    <div className="row">
      <div className="col-12">
        <h3>{props.title}</h3>
        <div className="separator mb-3" />
        {props.children}
        {props.children && <div className="mb-3" />}
        <table className="table table-hover a-la-carte-services-review-table mb-4">
          <thead>
            <tr>{tableHeaderRowItems}</tr>
          </thead>
          <tbody>{jsx}</tbody>
        </table>
      </div>
    </div>
  );
};

export default ReviewPackagesStep;
