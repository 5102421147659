import React, { useEffect } from "react";
import Select from "react-select";
import dialCodeOptions from "../dialCodes";

const InputPhone = props => {
	useEffect(() => {
		if (!props.dialCode && props.onChange) {
			props.onChange(props.dialCodeFieldName, "+44");
		}
	});
	return (
		<div className="phone-input-div">
			<Select
				className="phone-input-country-code"
				options={dialCodeOptions}
				value={props.dialCode ? dialCodeOptions.filter(option => option.value === props.dialCode) : ""}
				onChange={selectedOption => props.onChange(props.dialCodeFieldName, selectedOption.value)}
				isDisabled={props.readOnly}
			/>
			<div className="phone-input-number-div">
				<input readOnly={props.readOnly} value={props.phone || ""} onChange={(e) => props.onChange(props.phoneFieldName, e.target.value)} className="input-text" type="text" placeholder={props.placeholder} />
				{!props.readOnly && props.validator.message(props.phoneFieldName, props.phone, `${props.required ? "_required|" : ""}_phone:${props.dialCode}`, { className: 'text-danger' })}
			</div>
		</div>
	);
}

export default InputPhone;
