import {
    SET_ROLE_MANAGEMENT_LOADING,
    GET_ROLE_MANAGEMENT_PAGE,
    GET_ROLE_MANAGEMENT_PAGE_SUCCESS,
    GET_ROLE_MANAGEMENT_PAGE_FAIL,
    SET_ACTIVE_ROLE,
    SET_ACTIVE_ROLE_SUCCESS,
    SET_ACTIVE_ROLE_FAIL,
    SET_ROLE_PERMISSION,
    SET_SHOW_CREATE_ROLE_MODAL,
    CREATE_NEW_ROLE,
    CREATE_NEW_ROLE_SUCCESS,
    CREATE_NEW_ROLE_FAIL,
    SAVE_ROLE_CHANGES,
    SAVE_ROLE_CHANGES_SUCCESS,
    SAVE_ROLE_CHANGES_FAIL,
    DELETE_ROLE,
    DELETE_ROLE_SUCCESS,
    DELETE_ROLE_FAIL,
    OPEN_DELETE_ROLE_POPUP,
    CLOSE_DELETE_ROLE_POPUP,
    GET_MY_ORGANISATIONS_SUCCESS,
    GET_MY_ORGANISATIONS_FAIL
} from './actionTypes';

const initialState = {
    error: null,
    loading: false,
    roles: [],
    permissionModuleMap: {},
    activeRole: 'admin',
    activeRolePermissions: [],
    showCreateRoleModal: false,
    deleteRolePopupSlug: null,
    myOrganisations: []
}

const roleManagement = (state = initialState, action) => {
    switch (action.type) {
        case SET_ROLE_MANAGEMENT_LOADING:
            return {...state, loading: action.payload}
        case GET_ROLE_MANAGEMENT_PAGE:
            state = {
                ...state,
                loading: true
            }
            break;
        case GET_ROLE_MANAGEMENT_PAGE_SUCCESS:
            state = {
                ...state,
                loading: false,
                roles: action.payload.roles,
                permissionModuleMap: action.payload.permissionModuleMap
            }
            break;
        case GET_ROLE_MANAGEMENT_PAGE_FAIL:
            state = {
                ...state,
                loading: false
            }
            break;
        case SET_ACTIVE_ROLE:
            state = {
                ...state,
                loading: true,
                activeRole: action.payload
            }
            break;
        case SET_ACTIVE_ROLE_SUCCESS:
            state = {
                ...state,
                loading: false,
                activeRolePermissions: action.payload.permissions
            }
            break;
        case SET_ACTIVE_ROLE_FAIL:
            state = {
                ...state,
                loading: false
            }
            break;
        case SET_ROLE_PERMISSION:
            let activeRolePermissions = [...state.activeRolePermissions];
            if (!action.payload.permissionState) {
                activeRolePermissions = activeRolePermissions.filter(item => item!==action.payload.permissionId)
            } else {
                if (!activeRolePermissions.includes(action.payload.permissionId)) {
                    activeRolePermissions.push(action.payload.permissionId);
                }
            }
            state = {
                ...state,
                activeRolePermissions
            }
            break;
        case SET_SHOW_CREATE_ROLE_MODAL:
            state = {
                ...state,
                showCreateRoleModal: action.payload
            }
            break;
        case CREATE_NEW_ROLE:
            state = {
                ...state,
                loading: true
            }
            break;
        case CREATE_NEW_ROLE_SUCCESS:
            state = {
                ...state,
                loading: false,
                roles: action.payload.roles,
                activeRole: action.payload.newRoleSlug,
                activeRolePermissions: [],
                showCreateRoleModal: false
            }
            break;
        case CREATE_NEW_ROLE_FAIL:
            state = {
                ...state,
                loading: false
            }
            break;
        case SAVE_ROLE_CHANGES:
            state = {
                ...state,
                loading: true
            }
            break;
        case SAVE_ROLE_CHANGES_SUCCESS:
            state = {
                ...state,
                loading: false
            }
            break;
        case SAVE_ROLE_CHANGES_FAIL:
            state = {
                ...state,
                loading: false
            }
            break;
        case DELETE_ROLE:
            state = {
                ...state,
                loading: true
            }
            break;
        case DELETE_ROLE_SUCCESS:
            state = {
                ...state,
                loading: false,
                roles: action.payload.roles,
                deleteRolePopupSlug: null
            }
            break;
        case DELETE_ROLE_FAIL:
            state = {
                ...state,
                loading: false,
                deleteRolePopupSlug: null
            }
            break;
        case OPEN_DELETE_ROLE_POPUP:
            state = {
                ...state,
                deleteRolePopupSlug: action.payload
            }
            break;
        case CLOSE_DELETE_ROLE_POPUP:
            state = {
                ...state,
                deleteRolePopupSlug: null
            }
            break;
        case GET_MY_ORGANISATIONS_SUCCESS:
            state = {
                ...state,
                myOrganisations: action.payload
            }
            break;
        default:
            state = { ...state };
            break;
    }
    return state;
}

export default roleManagement;