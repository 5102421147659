import React from "react";
import MyEditor from "../MyEditor";

const TextBlockContent = (props) => {
	return (
		<div className="row fieldset">
			<div className="col-12">
				<h5 className="mt-2">Content</h5>
				<div className="separator mb-3" />
				<MyEditor
					value={props.textBlock}
					variable={`element${props.code}TextBlock`}
					onChange={props.onInputChange}
				/>
			</div>
		</div>
	);
}

export default TextBlockContent;
