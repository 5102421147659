import React, { useEffect } from "react";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";
import Loader from "../components/Loader";
import ReviewServices from "../components/ReviewServices";
import ReviewPackagesStep from "../components/Quote/PackagedPricing/ReviewPackagesStep";
import CustomReviewPackagesStep from "../components/Quote/CustomPackagedPricing/ReviewPackagesStep";
import ViewQuoteClientDetails from "../components/Quote/ViewQuote/ViewQuoteClientDetails";
import { getQuote, setActiveStep } from "../store/quote/actions";
import { useDispatch, useSelector } from "react-redux";

function cancel() {
  this.history.goBack();
}
const ViewQuote = (props) => {
  const dispatch = useDispatch();
  const quoteStatus = useSelector((state) => state?.Quote?.quoteStatus);
  useEffect(() => {
    props.getQuote(props.match.params.id);
    dispatch(getQuote(props.match.params.id));
  }, []);
  if (!quoteStatus) return <Loader />;
  return (
    <React.Fragment>
      {props.loading && <Loader />}
      <div className="page-info-header">
        <div className="container-fluid">
          <div className="page-display-title">
            <button
              style={{ background: "#5b626b", color: "white" }}
              onClick={cancel.bind(props)}
              className="btn btn-secondary"
            >
              Back
            </button>
          </div>
        </div>
      </div>
      <div className="page-info-header">
        <div className="container-fluid">
          <div className="page-display-title">
          Proposal: #{props.quoteReference}
          </div>
          {quoteStatus !== "draft" && (
            <div className="text-right flex1">
              <Link
                to={`/quotes/view-pdf/${btoa(props.quoteId)}`}
                target="_blank"
                className="btn btn-success create-item"
              >
                <i className="ion ion-md-download"></i>{" "}
                <span>Download Proposal</span>
              </Link>
            </div>
          )}
        </div>
      </div>
      <div className="container-fluid">
        <div style={{ marginTop: "75px", marginBottom: "95px" }}>
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <Nav tabs>
                    <NavItem>
                      <NavLink
                        onClick={() => props.setActiveStep(1)}
                        active={props.activeStep === 1}
                      >
                        Prospect Details
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        onClick={() => props.setActiveStep(2)}
                        active={props.activeStep === 2}
                      >
                        Selected Services
                      </NavLink>
                    </NavItem>
                  </Nav>
                  {props.quoteData && (
                    <TabContent activeTab={props.activeStep} className="p-3">
                      <TabPane tabId={1}>
                        <ViewQuoteClientDetails data={props.quoteData.client} />
                      </TabPane>
                      <TabPane tabId={2}>
                        <div className="row">
                          <div className="col-12">
                            {props.activePricingOption ===
                              "aLaCartePricing" && (
                                <ReviewServices
                                  chargeType="recurring"
                                  title="Recurring Services"
                                  calculatedPackagePrice={
                                    props.quoteData.selectedServices
                                  }
                                  flag={true}
                                  discountedPrice={
                                    props.quoteData.packageRecurringPrice
                                  }
                                />
                              )}
                            {props.activePricingOption ===
                              "aLaCartePricing" && (
                                <ReviewServices
                                  chargeType="oneoff"
                                  title="One-Off Services"
                                  calculatedPackagePrice={
                                    props.quoteData.selectedServices
                                  }
                                  flag={true}
                                  discountedPrice={
                                    props.quoteData.packageOneOffPrice
                                  }
                                />
                              )}
                            {(props.activePricingOption === "packagedPricing") && (
                              <ReviewPackagesStep
                                packages={
                                  props.quoteData
                                    .packagedPricingSelectedPackages
                                }
                                servicesByCategory={
                                  props.quoteData
                                    .packagedPricingServicesByCategory
                                }
                                flag={true}
                                chargeType="recurring"
                                title="Recurring Services"
                              />
                            )}
                            {(props.activePricingOption === "packagedPricing") && (
                              <ReviewPackagesStep
                                packages={
                                  props.quoteData
                                    .packagedPricingSelectedPackages
                                }
                                servicesByCategory={
                                  props.quoteData
                                    .packagedPricingServicesByCategory
                                }
                                flag={true}
                                chargeType="oneoff"
                                title="One-Off Services"
                              />
                            )}
                            {(props.activePricingOption ===
                              "customPackagedPricing") && (
                                <CustomReviewPackagesStep
                                  packages={
                                    props.quoteData
                                      .packagedPricingSelectedPackages
                                  }
                                  servicesByCategory={
                                    props.quoteData
                                      .packagedPricingServicesByCategory
                                  }
                                  flag={true}
                                  chargeType="recurring"
                                  title="Recurring Services"
                                />
                              )}
                            {(props.activePricingOption ===
                              "customPackagedPricing") && (
                                <CustomReviewPackagesStep
                                  packages={
                                    props.quoteData
                                      .packagedPricingSelectedPackages
                                  }
                                  servicesByCategory={
                                    props.quoteData
                                      .packagedPricingServicesByCategory
                                  }
                                  flag={true}
                                  chargeType="oneoff"
                                  title="One-Off Services"
                                />
                              )}
                          </div>
                        </div>
                      </TabPane>
                    </TabContent>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => state.Quote;

export default withRouter(
  connect(mapStateToProps, { getQuote, setActiveStep })(ViewQuote)
);
