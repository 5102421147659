import React, { useEffect } from 'react';

const GoogleAnalytics = () => {
  useEffect(() => {
    const trackingId = 'G-K9DP2F5EC1';

    const script = document.createElement('script');
    script.src = `https://www.googletagmanager.com/gtag/js?id=${trackingId}`;
    script.async = true;
    document.head.appendChild(script);

    // Initialize gtag and set the tracking configuration
    window.dataLayer = window.dataLayer || [];
    function gtag() {
      window.dataLayer.push(arguments);
    }
    gtag('js', new Date());
    gtag('config', trackingId);
  }, []);

  return null;
};

export default GoogleAnalytics;
