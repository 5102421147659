import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import logoDark from "../../assets/images/outbooks.png";


class Subscription extends Component {
    componentDidMount() {
    }
    render() {
        return (
            <React.Fragment>
                <div className="container-fluid">
                    <div class="col-md-12 text-center">
                        <h1 class="hd-typ1">Business Setup</h1>
                    </div>
                    <div className="table-rep-plugin">
                        <div className="table-responsive mb-0" data-pattern="priority-columns">
                            <table className="table table-striped pricing-table">
                                <tbody>
                                    <tr>
                                        <td width="30%" class="pricing-table-text">
                                            <Link to="/">
                                                <img src={logoDark} height="22" alt="logo" />
                                            </Link>
                                        </td>
                                        <td width="17.5%">
                                            <div class="pricing-table-item pachd1">
                                                <div class="pricing-table-item-head">
                                                    <p>Free</p>
                                                </div>
                                            </div>
                                        </td>
                                        <td width="17.5%">
                                            <div class="pricing-table-item pachd2">
                                                <div class="pricing-table-item-head">
                                                    <p>Startup</p>
                                                </div>
                                            </div>
                                        </td>
                                        <td width="17.5%">
                                            <div class="pricing-table-item pachd3   ">
                                                <div class="pricing-table-item-head">
                                                    <p>Small Practice</p>
                                                </div>
                                            </div>
                                        </td>
                                        <td width="17.5%">
                                            <div class="pricing-table-item pachd4   ">
                                                <div class="pricing-table-item-head">
                                                    <p>Medium Sized
                                                        Practice</p>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr className="pricing-table-list">
                                        <td>&nbsp;</td>
                                        <td>&nbsp;</td>
                                        <td>&nbsp;</td>
                                        <td>&nbsp;</td>
                                        <td>&nbsp;</td>
                                    </tr>
                                    <tr className="pricing-table-list">
                                        <td>Prepare Proposal</td>
                                        <td><span className="fa fa-check"></span></td>
                                        <td><span className="fa fa-check"></span></td>
                                        <td><span className="fa fa-check"></span></td>
                                        <td><span className="fa fa-check"></span></td>
                                    </tr>
                                    <tr className="pricing-table-list">
                                        <td>Prepare Engagement Letter</td>
                                        <td><span className="fa fa-check"></span></td>
                                        <td><span className="fa fa-check"></span></td>
                                        <td><span className="fa fa-check"></span></td>
                                        <td><span className="fa fa-check"></span></td>
                                    </tr>
                                    <tr className="pricing-table-list">
                                        <td>Send Proposal</td>
                                        <td><span className="fa fa-check"></span></td>
                                        <td><span className="fa fa-check"></span></td>
                                        <td><span className="fa fa-check"></span></td>
                                        <td><span className="fa fa-check"></span></td>
                                    </tr>
                                    <tr className="pricing-table-list">
                                        <td>Send Engagement Letter</td>
                                        <td><span className="fa fa-check"></span></td>
                                        <td><span className="fa fa-check"></span></td>
                                        <td><span className="fa fa-check"></span></td>
                                        <td><span className="fa fa-check"></span></td>
                                    </tr>
                                    <tr className="pricing-table-list">
                                        <td>Digitally Sign the Engagement Letter</td>
                                        <td><span class="fa fa-times"></span></td>
                                        <td><span class="fa fa-check"></span></td>
                                        <td><span class="fa fa-check"></span></td>
                                        <td><span class="fa fa-check"></span></td>
                                    </tr>
                                    <tr className="pricing-table-list">
                                        <td>Esignatures per month</td>
                                        <td>0</td>
                                        <td>3</td>
                                        <td>10</td>
                                        <td>25</td>
                                    </tr>
                                    <tr className="pricing-table-list">
                                        <td>Fee per month</td>
                                        <td>£ 0</td>
                                        <td>£ 5</td>
                                        <td>£ 10</td>
                                        <td>£ 15</td>
                                    </tr>
                                    <tr className="pricing-table-list">
                                        <td>Fee per Year</td>
                                        <td>£ 0</td>
                                        <td>£ 30</td>
                                        <td>£ 50</td>
                                        <td>£ 100</td>
                                    </tr>
                                    <tr className="pricing-table-list">
                                        <td>&nbsp;</td>
                                        <td>&nbsp;</td>
                                        <td>&nbsp;</td>
                                        <td>&nbsp;</td>
                                        <td>&nbsp;</td>
                                    </tr>
                                    <tr className="pricing-table-list">
                                        <td>&nbsp;</td>
                                        <td>
                                            <button type="button" className="btn btn-free btn-block" data-toggle="modal" data-target="#exampleModalCenter">
                                                Select Package
                                            </button>
                                        </td>
                                        <td>
                                            <button type="button" className="btn btn-statup btn-block" data-toggle="modal" data-target="#exampleModalCenter">
                                                Select Package
                                            </button>

                                        </td>
                                        <td>
                                            <button type="button" className="btn btn-small btn-block" data-toggle="modal" data-target="#exampleModalCenter">
                                                Select Package
                                            </button>
                                        </td>
                                        <td>
                                            <button type="button" class="btn btn-medium btn-block" data-toggle="modal" data-target="#exampleModalCenter">
                                                Select Package
                                            </button>
                                        </td>
                                    </tr>
                                    <tr className="pricing-table-list">
                                        <td className="lastbox1">&nbsp;</td>
                                        <td>&nbsp;</td>
                                        <td>&nbsp;</td>
                                        <td>&nbsp;</td>
                                        <td className="lastbox">&nbsp;</td>
                                    </tr>
                                </tbody>
                            </table>

                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    const { users, pagination, loading } = state.User;
    return { users, pagination, loading }
}

export default connect(mapStateToProps, {})(Subscription);
