import { takeEvery, fork, put, all, call } from "redux-saga/effects";
import axios from "axios";

// Login Redux States
import {
  FORGET_PASSWORD,
  RESET_PASSWORD,
  VALIDATE_RESET_TOKEN,
} from "./actionTypes";
import {
  userForgetPasswordSuccess,
  userForgetPasswordError,
  userResetPasswordSuccess,
  userResetPasswordError,
  validateResetTokenSuccess,
  validateResetTokenFailure,
} from "./actions";
// import { setShowApiErrorModal } from '../../common/actions';

//AUTH related methods
import { getFirebaseBackend } from "../../../helpers/authUtils";

const fireBaseBackend = getFirebaseBackend();

//If user is send successfully send mail link then dispatch redux action's are directly from here.
function* forgetUser({ payload: { email, callback } }) {
  try {
    const forgotPwdResponse = yield axios({
      method: "POST",
      url: "forgot-password",
      data: {
        email: email.toLocaleLowerCase(),
      },
    });
    if (forgotPwdResponse.data.success) {
      yield put(
        userForgetPasswordSuccess(
          //"Password Reset link has been sent to your email!"
          "Success"
        )
      );
    } else {
      yield put(userForgetPasswordError(forgotPwdResponse.data.error));
    }
    if (callback) {
      callback(forgotPwdResponse);
    }
  } catch (error) {
    if (error.response && error.response.data && error.response.data.error) {
      yield put(userForgetPasswordError(error.response.data.error));
    } else {
      yield put(userForgetPasswordError("Something went Wrong!"));
    }
  }
}

function* resetPassword({ payload: { userId, password, token, callback } }) {
  try {
    const resetPwdResponse = yield axios({
      method: "POST",
      url: "reset-password",
      data: {
        userId,
        password,
        token,
      },
    });
    if (resetPwdResponse.data.success) {
      yield put(
        userResetPasswordSuccess(
          // "Your Password has been reset successfully. Please login to continue!"
          "Success"
        )
      );
    } else {
      yield put(userResetPasswordError(resetPwdResponse.data.error));
    }
    if (callback) {
      callback(resetPwdResponse);
    }
  } catch (error) {
    if (error.response && error.response.data && error.response.data.error) {
      yield put(userResetPasswordError(error.response.data.error));
    } else {
      yield put(userResetPasswordError("Something went Wrong!"));
    }
  }
}

function* validateUserToken({ payload: { userId, token } }) {
  try {
    const resetPwdResponse = yield axios({
      method: "POST",
      url: "validate-reset-token",
      data: {
        userId,
        token,
      },
    });
    if (resetPwdResponse.data.success) {
      yield put(validateResetTokenSuccess());
    } else {
      yield put(validateResetTokenFailure());
    }
  } catch (error) {
    yield put(validateResetTokenFailure());
  }
}

export function* watchUserPasswordForget() {
  yield takeEvery(FORGET_PASSWORD, forgetUser);
}

export function* watchUserPasswordReset() {
  yield takeEvery(RESET_PASSWORD, resetPassword);
}

export function* watchValidateToken() {
  yield takeEvery(VALIDATE_RESET_TOKEN, validateUserToken);
}

function* forgetPasswordSaga() {
  yield all([
    fork(watchUserPasswordForget),
    fork(watchUserPasswordReset),
    fork(watchValidateToken),
  ]);
}

export default forgetPasswordSaga;
