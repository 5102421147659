import React, { useEffect, useState } from "react";
import { withRouter, useLocation, Link } from "react-router-dom";
import { connect } from "react-redux";
import { TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";
import Loader from "../components/Loader";
import ReviewServices from "../components/ReviewServices";
import ViewQuoteClientDetails from "../components/Quote/ViewQuote/ViewQuoteClientDetails";
import {
  getContract,
  downloadContract,
  setActiveStep,
} from "../store/contract/actions";
import { useDispatch, useSelector } from "react-redux";

function cancel() {
  this.history.goBack();
}
const ViewContract = (props) => {
  const dispatch = useDispatch();
  const contractStatus = useSelector(
    (state) => state?.Contract?.contractStatus
  );
  const contractPdf = useSelector((state) => state?.Contract?.contractPdf);

  useEffect(() => {
    props.getContract(props.match.params.id);
    dispatch(getContract(props.match.params.id));
  }, []);

  if (!contractStatus) return <Loader />;
  // console.log(contractStatus)
  // console.log(contractPdf)
  return (
    <React.Fragment>
      {props.loading && <Loader />}
      <div className="page-info-header">
        <div className="container-fluid">
          <div className="page-display-title">
            <button
              style={{ background: "#5b626b", color: "white" }}
              onClick={cancel.bind(props)}
              className="btn btn-secondary"
            >
              Back
            </button>
          </div>
        </div>
      </div>
      <div className="page-info-header">
        <div className="container-fluid">
          <div className="page-display-title">
            Contract: #{props.contractReference}
          </div>

          {contractStatus !== "draft" && (
            <div className="text-right flex1">
              <Link
                to={`/contracts/view-pdf/${btoa(props.contractId)}`}
                className="btn btn-success create-item"
                target="_blank"
              >
                <i className="ion ion-md-download"></i>{" "}
                <span>Download Engagement Letter</span>
              </Link>
              {/* <button
                onClick={() => props.downloadContract(props.match.params.id)}
                className="btn btn-success create-item"
              >
                <i className="ion ion-md-download"></i>{" "}
                <span>Download Contract</span>
              </button> */}
            </div>
          )}
          {/*
				{contractStatus !== 'draft' &&
						<div className="text-right flex1">
						<button onClick={() => props.downloadContract(props.match.params.id)} className="btn btn-success create-item"><i className="ion ion-md-download"></i> <span>Download Contract</span></button>
						
					</div>
					} 
				*/}
        </div>
      </div>
      <div className="container-fluid">
        <div style={{ marginTop: "75px", marginBottom: "95px" }}>
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <Nav tabs>
                    <NavItem>
                      <NavLink
                        onClick={() => props.setActiveStep(1)}
                        active={props.activeStep === 1}
                      >
                        Prospect/Client Details
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        onClick={() => props.setActiveStep(2)}
                        active={props.activeStep === 2}
                      >
                        Selected Services
                      </NavLink>
                    </NavItem>
                  </Nav>
                  {props.contractData && (
                    <TabContent activeTab={props.activeStep} className="p-3">
                      <TabPane tabId={1}>
                        {
                          <ViewQuoteClientDetails
                            data={props.contractData.client}
                          />
                        }
                      </TabPane>
                      <TabPane tabId={2}>
                        <div className="row">
                          <div className="col-12">
                            <ReviewServices
                              chargeType="recurring"
                              title="Recurring Services"
                              calculatedPackagePrice={
                                props.contractData.selectedServices
                              }
                              flag={true}
                              discountedPrice={
                                props.contractData.packageRecurringPrice
                              }
                            />
                            <ReviewServices
                              chargeType="oneoff"
                              title="One-Off Services"
                              calculatedPackagePrice={
                                props.contractData.selectedServices
                              }
                              flag={true}
                              discountedPrice={
                                props.contractData.packageOneOffPrice
                              }
                            />
                          </div>
                        </div>
                      </TabPane>
                    </TabContent>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => state.Contract;

export default withRouter(
  connect(mapStateToProps, { getContract, downloadContract, setActiveStep })(
    ViewContract
  )
);
