export const SET_TEMPLATE_LOADING = 'SET_TEMPLATE_LOADING';
export const ON_INPUT_CHANGE = 'ON_TEMPLATE_INPUT_CHANGE';
export const MOVE_ELEMENT = 'MOVE_ELEMENT';
export const ADD_ELEMENT_BLOCK = 'ADD_ELEMENT_BLOCK';
export const DELETE_ELEMENT = 'DELETE_ELEMENT';
export const GET_TEMPLATES = 'GET_TEMPLATES';
export const GET_SUBSCRIPTION_PACKAGES_SUCCESS = 'GET_SUBSCRIPTION_PACKAGES_SUCCESS';
export const GET_SUBSCRIPTION_PACKAGES = 'GET_SUBSCRIPTION_PACKAGES';
export const GET_TEMPLATE = 'GET_TEMPLATE';
export const HANDLE_CARD = 'HANDLE_CARD';
export const HANDLE_CARD_SUCCESS = 'HANDLE_CARD_SUCCESS';
export const HANDLE_CARD_ACTION = 'HANDLE_CARD_ACTION';
export const HANDLE_PAYMENT_INTENT = 'HANDLE_PAYMENT_INTENT';
export const HANDLE_SETUP_INTENT = 'HANDLE_SETUP_INTENT';
export const CHANGE_SUBSCRIPTION = 'CHANGE_SUBSCRIPTION';
export const CHANGE_SUBSCRIPTION_SUCCESS = 'CHANGE_SUBSCRIPTION_SUCCESS';
export const GET_TEMPLATE_SUCCESS = 'GET_TEMPLATE_SUCCESS';
export const CANCEL_SUBSCRIPTION = 'CANCEL_SUBSCRIPTION';
export const CANCEL_SUBSCRIPTION_SUCCESS = 'CANCEL_SUBSCRIPTION_SUCCESS';
export const SUBMIT_TEMPLATE = 'SUBMIT_TEMPLATE';
export const SUBMIT_TEMPLATE_SUCCESS = 'SUBMIT_TEMPLATE_SUCCESS';
export const RESET_CREATE_NEW_TEMPLATE_FORM = 'RESET_CREATE_NEW_TEMPLATE_FORM';
export const SET_SHOW_HANDLE_CARD_SUCCESS_MODAL = 'SET_SHOW_HANDLE_CARD_SUCCESS_MODAL';
export const SET_SHOW_CREATE_NEW_TEMPLATE_SUCCESS_MODAL = 'SET_SHOW_CREATE_NEW_TEMPLATE_SUCCESS_MODAL';
export const CREATE_NEW_TEMPLATE_FAIL = "CREATE_NEW_TEMPLATE_FAIL";
export const SET_SHOW_CREATE_NEW_TEMPLATE_FAILED_MODAL = "SET_SHOW_CREATE_NEW_TEMPLATE_FAILED_MODAL";
export const SET_SHOW_CHANGE_SUBSCRIPTION_SUCCESS_MODAL = "SET_SHOW_CHANGE_SUBSCRIPTION_SUCCESS_MODAL";
export const SET_SHOW_CANCEL_SUBSCRIPTION_SUCCESS_MODAL = "SET_SHOW_CANCEL_SUBSCRIPTION_SUCCESS_MODAL";
