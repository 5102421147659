import { takeEvery, fork, put, all, call } from "redux-saga/effects";
import axios from "axios";

import {
  GET_USERS,
  INVITE_USER,
  ACCEPT_INVITE,
  UPDATE_USER_ROLE,
  UPDATE_USER,
  VALIDATE_ACCEPT_TOKEN,
} from "./actionTypes";
import { loginError } from "../auth/login/actions";
import { registerError } from "../auth/register/actions";

import {
  getUsersSuccess,
  inviteUserSuccess,
  userError,
  updateUserRoleSuccess,
  updateUserSuccess,
  validateUserAcceptTokenFail,
  validateUserAcceptTokenSuccess,
} from "./actions";
import { setShowApiErrorModal } from "../common/actions";

// AUTH related methods
import { getFirebaseBackend } from "../../helpers/authUtils";

const fireBaseBackend = getFirebaseBackend();

function* getUsers({ payload: page_num }) {
  try {
    const accessToken = yield fireBaseBackend.getAccessToken();
    const response = yield axios({
      method: "GET",
      url: `users?page_num=${page_num}`,
      headers: { authorization: `Bearer ${accessToken}` },
    });
    if (response.data.success) {
      yield put(getUsersSuccess(response.data.result));
    } else {
      // yield put(createOrganisationFailed());
    }
  } catch (error) {
    yield put(setShowApiErrorModal());
    // if (error.response && error.response.data && error.response.data.error) {
    //     yield put(loginError(error.response.data.error));
    // } else {
    //     yield put(loginError("Something went Wrong!"));
    // }
    // yield put(createOrganisationFailed());
  }
}

function* updateUserRole({ payload: data }) {
  try {
    const accessToken = yield fireBaseBackend.getAccessToken();
    const response = yield axios({
      method: "POST",
      url: `update-user-role`,
      headers: { authorization: `Bearer ${accessToken}` },
      data,
    });
    if (response.data.success) {
      yield put(getUsersSuccess(response.data.result));
      yield put(updateUserRoleSuccess());
    } else {
      // yield put(createOrganisationFailed());
    }
  } catch (error) {
    yield put(setShowApiErrorModal());
    // if (error.response && error.response.data && error.response.data.error) {
    //     yield put(loginError(error.response.data.error));
    // } else {
    //     yield put(loginError("Something went Wrong!"));
    // }
    // yield put(createOrganisationFailed());
  }
}

function* inviteUser({ payload: data }) {
  try {
    const accessToken = yield fireBaseBackend.getAccessToken();
    const response = yield axios({
      method: "POST",
      url: `invite-user`,
      headers: { authorization: `Bearer ${accessToken}` },
      data,
    });
    if (response.data.success) {
      yield put(inviteUserSuccess());
    } else {
      yield put(setShowApiErrorModal(true, response.data.error));
      // yield put(createOrganisationFailed());
    }
  } catch (error) {
    yield put(setShowApiErrorModal());
    // if (error.response && error.response.data && error.response.data.error) {
    //     yield put(loginError(error.response.data.error));
    // } else {
    //     yield put(loginError("Something went Wrong!"));
    // }
    // yield put(createOrganisationFailed());
  }
}

function* acceptInvite({ payload: { token, history } }) {
  try {
    const response = yield axios({
      method: "GET",
      url: `accept-invite/${token}`,
    });
    if (response.data.success) {
      if (response.data.accountExist) {
        // window.location.href = "/login"
        yield put(
          loginError(
            "Your request has been accepted. Looks like you already have account. Login to continue!"
          )
        );
        history.push("/signIn");
      } else {
        // window.location.href = "/register"
        yield put(
          registerError(
            "Your request has been accepted. Looks like you dont have an account. Please register to continue!"
          )
        );
        history.push("/register");
      }
    } else {
      yield put(userError(response.data.error));
      history.push("/error");
    }
  } catch (err) {
    yield put(setShowApiErrorModal());
    // history.push("/error")
    console.log("err :: ", err);
    yield put(userError("Something Went Wrong!"));
    history.push("/error");
  }
}

function* validateUserAcceptToken({ payload: { token } }) {
  try {
    const response = yield axios({
      method: "GET",
      url: `validate-accept-token/${token}`,
    });
    if (response.data.success) {
      yield put(validateUserAcceptTokenSuccess(token));
    } else {
      yield put(validateUserAcceptTokenFail(token));
      //   history.push("/error");
    }
  } catch (err) {
    yield put(validateUserAcceptTokenFail(token));
    // yield put(setShowApiErrorModal());
    // history.push("/error")
    // console.log("err :: ", err);
    // yield put(userError("Something Went Wrong!"));
    // history.push("/error");
  }
}

function* updateUser({ payload: data }) {
  try {
    const accessToken = yield fireBaseBackend.getAccessToken();
    const response = yield axios({
      method: "POST",
      url: `update-user`,
      headers: { authorization: `Bearer ${accessToken}` },
      data,
    });
    if (response.data.success) {
      yield put(getUsersSuccess(response.data.result));
      yield put(updateUserSuccess());
    } else {
      // yield put(createOrganisationFailed());
    }
  } catch (error) {
    yield put(setShowApiErrorModal());
  }
}

export function* watchGetUsers() {
  yield takeEvery(GET_USERS, getUsers);
}

export function* watchInviteUser() {
  yield takeEvery(INVITE_USER, inviteUser);
}

export function* watchAcceptInvite() {
  yield takeEvery(ACCEPT_INVITE, acceptInvite);
}

export function* watchValidateToken() {
  yield takeEvery(VALIDATE_ACCEPT_TOKEN, validateUserAcceptToken);
}

export function* watchUpdateUserRole() {
  yield takeEvery(UPDATE_USER_ROLE, updateUserRole);
}

export function* watchUpdateUser() {
  yield takeEvery(UPDATE_USER, updateUser);
}

function* userSaga() {
  yield all([
    fork(watchGetUsers),
    fork(watchInviteUser),
    fork(watchAcceptInvite),
    fork(watchUpdateUserRole),
    fork(watchUpdateUser),
    fork(watchValidateToken),
  ]);
}

export default userSaga;
