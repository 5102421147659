import { takeEvery, fork, put, all, call } from "redux-saga/effects";
import axios from "axios";

import { DELETE_OBJECT } from "./actionTypes";

import { deleteObjectSuccess } from "./actions";
import { setShowApiErrorModal } from "../common/actions";

import {
  getServicesByCategorySuccess,
  getCategoriesSuccess,
  getGlobalPricingDriversSuccess,
} from "../service/actions";

import { getClientsSuccess } from "../client/actions";

import { getTemplatesSuccess } from "../template/actions";

import { getTermsAndConditionTemplatesSuccess } from "../termsAndConditionTemplate/actions";

import { getPackagesSuccess } from "../package/actions";

import { getAccessKeysSuccess } from "../accessKey/actions";

import { getUsersSuccess } from "../user/actions";

// AUTH related methods
import { getFirebaseBackend } from "../../helpers/authUtils";
import { getEmailTemplateSuccess, getEmailTemplatesSuccess } from "../actions";

const fireBaseBackend = getFirebaseBackend();

function* deleteObject({ payload: { id, module } }) {
  try {
    const accessToken = yield fireBaseBackend.getAccessToken();
    const response = yield axios({
      method: "POST",
      url: `delete`,
      headers: { authorization: `Bearer ${accessToken}` },
      data: {
        id,
        module,
      },
    });
    if (response.data.success) {
      switch (module) {
        case "service":
          yield put(getServicesByCategorySuccess(response.data.result));
          break;
        case "package":
          yield put(getPackagesSuccess(response.data.result));
          break;
        case "category":
          yield put(getCategoriesSuccess(response.data.result));
          break;
        case "client":
          yield put(getClientsSuccess(response.data.result));
          break;
        case "template":
          yield put(getTemplatesSuccess(response.data.result));
          break;
        case "tncTemplate":
          yield put(getTermsAndConditionTemplatesSuccess(response.data.result));
          break;
        case "globalPricingDriver":
          yield put(getGlobalPricingDriversSuccess(response.data.result));
          break;
        case "accessKey":
          yield put(getAccessKeysSuccess(response.data.result));
          break;
        case "user":
          yield put(getUsersSuccess(response.data.result));
          break;
        case "email-template":
          yield put(getEmailTemplatesSuccess(response.data.result));
          break;
        default:
          break;
      }
      yield put(deleteObjectSuccess());
    } else {
      // yield put(createOrganisationFailed());
    }
  } catch (error) {
    if (module === "client") {
      yield put(
        setShowApiErrorModal(
          true,
          "This Prospect has linked records. It cannot be deleted."
        )
      );
    } else if (module === "category") {
      yield put(
        setShowApiErrorModal(true, "This service category has linked services. It cannot be deleted. To delete service category, please first delete all linked services.")
      );
    } else {
      yield put(setShowApiErrorModal());
    }
    console.log(error);
    // if (error.response && error.response.data && error.response.data.error) {
    //     yield put(loginError(error.response.data.error));
    // } else {
    //     yield put(loginError("Something went Wrong!"));
    // }
    // yield put(createOrganisationFailed());
  }
}

export function* watchDeleteObject() {
  yield takeEvery(DELETE_OBJECT, deleteObject);
}

function* deleteSaga() {
  yield all([fork(watchDeleteObject)]);
}

export default deleteSaga;
