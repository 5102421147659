import { takeEvery, fork, put, all, call } from "redux-saga/effects";
import axios from "axios";

import { GET_AUDIT_TRAILS, LOAD_MORE_AUDIT_TRAILS } from "./actionTypes";

import { getAuditTrailsSuccess, loadMoreAuditTrailsSuccess } from "./actions";
import { setShowApiErrorModal } from "../common/actions";

// AUTH related methods
import { getFirebaseBackend } from "../../helpers/authUtils";

const fireBaseBackend = getFirebaseBackend();

function* getAuditTrails() {
  try {
    const accessToken = yield fireBaseBackend.getAccessToken();
    const response = yield axios({
      method: "GET",
      url: `activity-logs`,
      headers: { authorization: `Bearer ${accessToken}` },
    });
    if (response.data.success) {
      yield put(
        getAuditTrailsSuccess(
          response.data.result,
          response.data?.totalCount ?? 0
        )
      );
    } else {
      // yield put(createOrganisationFailed());
    }
  } catch (error) {
    yield put(setShowApiErrorModal());
    console.log(error);
    // if (error.response && error.response.data && error.response.data.error) {
    //     yield put(loginError(error.response.data.error));
    // } else {
    //     yield put(loginError("Something went Wrong!"));
    // }
    // yield put(createOrganisationFailed());
  }
}

function* loadMoreAuditTrails({ payload: lastId }) {
  try {
    const accessToken = yield fireBaseBackend.getAccessToken();
    const response = yield axios({
      method: "GET",
      url: `activity-logs/load-more?lastid=${lastId}`,
      headers: { authorization: `Bearer ${accessToken}` },
    });
    if (response.data.success) {
      yield put(loadMoreAuditTrailsSuccess(response.data.result));
    } else {
      // yield put(createOrganisationFailed());
    }
  } catch (error) {
    yield put(setShowApiErrorModal());
    console.log(error);
    // if (error.response && error.response.data && error.response.data.error) {
    //     yield put(loginError(error.response.data.error));
    // } else {
    //     yield put(loginError("Something went Wrong!"));
    // }
    // yield put(createOrganisationFailed());
  }
}

export function* watchGetAuditTrails() {
  yield takeEvery(GET_AUDIT_TRAILS, getAuditTrails);
}

export function* watchLoadMoreAuditTrails() {
  yield takeEvery(LOAD_MORE_AUDIT_TRAILS, loadMoreAuditTrails);
}

function* auditTrailSaga() {
  yield all([fork(watchGetAuditTrails), fork(watchLoadMoreAuditTrails)]);
}

export default auditTrailSaga;
