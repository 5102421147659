import React, {useState, useEffect, useRef} from "react";

const keydownListener = (activeItem, setActiveItem, setInputValue, listRef, showAutoCompleteDivVisible, setShowAutoCompleteDivVisible, props) => (event) => {
	if (!showAutoCompleteDivVisible && props.items.length>0 && event.key!=="Escape") {
		setShowAutoCompleteDivVisible(true);
	}
	else if (["ArrowDown", "ArrowUp"].includes(event.key)) {
		const itemCount = listRef.current.childElementCount;
		let nextItem;
		if (event.key==="ArrowDown")
			nextItem = (activeItem<itemCount-1) ? activeItem+1 : 0;
		else
			nextItem = (activeItem>0) ? activeItem-1 : itemCount-1;
		setActiveItem(nextItem);
	}
	else if (event.key==="Enter" && props.items.length>0) {
		props.onInputChange(props.name, (props.valueProperty ? props.items[activeItem][props.valueProperty] : props.items))
		props.onSelect(props.items[activeItem]);
		setShowAutoCompleteDivVisible(false);
	} else if (event.key==="Escape") {
		setShowAutoCompleteDivVisible(false);
	}
}

const renderItems = (setActiveItem, setInputValue, props) => (item, index) => <li key={index} onMouseDown={() => props.onSelect(item)} onMouseEnter={() => setActiveItem(index)}>{props.valueProperty ? props.valueProperty.split(",").map(property => item[property]).join("-") : item}</li>

const AutoCompleteInput = props => {
	const [activeItem, setActiveItem] = useState(0);
	const [prevActiveItem, setPrevActiveItem] = useState(null);
	const [showAutoCompleteDivVisible, setShowAutoCompleteDivVisible] = useState(false); 
	const [prevShowAutoCompleteDivVisible, setPrevShowAutoCompleteDivVisible] = useState(null);
	const [inputValue, setInputValue] = useState("");
	const [prevInputValue, setPrevInputValue] = useState(null);
	const listRef = useRef(null);
	const inputRef = useRef(null);
	useEffect(() => {
		try {
			if (prevActiveItem!==activeItem && showAutoCompleteDivVisible) {
				if (prevActiveItem!==null)
					listRef.current.childNodes[prevActiveItem].classList.remove("active");
				listRef.current.childNodes[activeItem].classList.add("active");
				setPrevActiveItem(activeItem);
			}
			if (showAutoCompleteDivVisible!==prevShowAutoCompleteDivVisible) {
				setActiveItem(0);
				setPrevShowAutoCompleteDivVisible(showAutoCompleteDivVisible);
			}
			if (inputValue!==prevInputValue) {
				setPrevInputValue(inputValue);
			}
		} catch(error) {
			// ignore error
		}
	}, [activeItem, showAutoCompleteDivVisible, inputValue]);
	return (
		<React.Fragment>
			<input
				ref={inputRef}
				onBlur={() => setShowAutoCompleteDivVisible(false)}
				onFocus={() => setShowAutoCompleteDivVisible(true && props.items.length>0)}
				onClick={() => setActiveItem(0)}
				onKeyDown={keydownListener(activeItem, setActiveItem, setInputValue, listRef, showAutoCompleteDivVisible, setShowAutoCompleteDivVisible, props)}
				className={`input-text ${props.className}`}
				type="text"
				placeholder={props.placeholder}
				value={props.value}
				onChange={e => { props.onInputChange(props.name, e.target.value); props.search(e.target.value); }}
				autoComplete={`${props.name}-${new Date().getTime()}`}
			/>
			<div className={`autocomplete-input-div ${(showAutoCompleteDivVisible && props.items.length && props.value.length>=3)?"show":""}`}>
				<ul ref={listRef}>
					{props.items.map(renderItems(setActiveItem, setInputValue, props))}
				</ul>
			</div>
		</React.Fragment>
	);
}

export default AutoCompleteInput;
