import React, { useState } from "react";
import { getFirebaseBackend } from "../../../helpers/authUtils";

const AccountantVariables = (props) => {
  const [copiedMessage, setCopiedMessage] = useState("");
  const [cursorPosition, setCursorPosition] = useState({ x: 0, y: 0 });

  const handleVariableClick = (variable, event) => {
    // Copy to clipboard
    navigator.clipboard.writeText(variable);

    // Set cursor position
    setCursorPosition({ x: event.clientX, y: event.clientY });

    // Show the message
    setCopiedMessage("Variable copied!");

    // Clear the message after some time (e.g., 2 seconds)
    setTimeout(() => {
      setCopiedMessage("");
    }, 2000);
  };

  console.log("props.businessType", props.businessType);
  const firebaseBackend = getFirebaseBackend();
  const businessType = props.businessType
    ? props.businessType
    : firebaseBackend.getClaims().org.businessType;
  console.log("businessType", businessType);
  if (businessType === "soleTrader") {
    return (
      <React.Fragment>
        <div>
          <span
            className="badge badge-secondary"
            onClick={(e) =>
              handleVariableClick("$Accountant.SoleTrader.FirstName$", e)
            }
            style={{ cursor: "pointer" }}
          >
            $Accountant.SoleTrader.FirstName$
          </span>
          <span
            className="badge badge-secondary"
            onClick={(e) =>
              handleVariableClick("$Accountant.SoleTrader.LastName$", e)
            }
            style={{ cursor: "pointer" }}
          >
            $Accountant.SoleTrader.LastName$
          </span>
          <span
            className="badge badge-secondary"
            onClick={(e) =>
              handleVariableClick("$Accountant.SoleTrader.Email$", e)
            }
            style={{ cursor: "pointer" }}
          >
            $Accountant.SoleTrader.Email$
          </span>
          <span
            className="badge badge-secondary"
            onClick={(e) =>
              handleVariableClick("$Accountant.SoleTrader.Phone$", e)
            }
            style={{ cursor: "pointer" }}
          >
            $Accountant.SoleTrader.Phone$
          </span>
        </div>
        <div>
          <span
            className="badge badge-secondary"
            onClick={(e) => handleVariableClick("$Accountant.TradingName$", e)}
            style={{ cursor: "pointer" }}
          >
            $Accountant.TradingName$
          </span>
          <span
            className="badge badge-secondary"
            onClick={(e) =>
              handleVariableClick("$Accountant.TradingAddress$", e)
            }
            style={{ cursor: "pointer" }}
          >
            $Accountant.TradingAddress$
          </span>
          <span
            className="badge badge-secondary"
            onClick={(e) =>
              handleVariableClick("$Accountant.TradingAddressWithLineBreak$", e)
            }
            style={{ cursor: "pointer" }}
          >
            $Accountant.TradingAddressWithLineBreak$
          </span>
        </div>
        <div>
          <span
            className="badge badge-secondary"
            onClick={(e) =>
              handleVariableClick("$Accountant.SoleTrader.Address$", e)
            }
            style={{ cursor: "pointer" }}
          >
            $Accountant.SoleTrader.Address$
          </span>
          <span
            className="badge badge-secondary"
            onClick={(e) =>
              handleVariableClick(
                "$Accountant.SoleTrader.AddressWithLineBreak$",
                e
              )
            }
            style={{ cursor: "pointer" }}
          >
            $Accountant.SoleTrader.AddressWithLineBreak$
          </span>
        </div>
        <div>
          <span
            className="badge badge-secondary"
            onClick={(e) =>
              handleVariableClick(
                "$Accountant.AffiliatedAccountingBodyName$",
                e
              )
            }
            style={{ cursor: "pointer" }}
          >
            $Accountant.AffiliatedAccountingBodyName$
          </span>
          <span
            className="badge badge-secondary"
            onClick={(e) =>
              handleVariableClick("$Accountant.CountryIncorporatedIn$", e)
            }
            style={{ cursor: "pointer" }}
          >
            $Accountant.CountryIncorporatedIn$
          </span>
          <span
            className="badge badge-secondary"
            onClick={(e) =>
              handleVariableClick("$CurrentDate$", e)
            }
            style={{ cursor: "pointer" }}
          >
            $CurrentDate$
          </span>
        </div>
        {/* Display the copied message */}
        {copiedMessage && (
          <div
            style={{
              position: "fixed",
              top: cursorPosition.y - 30 + "px",
              left: cursorPosition.x + "px",
              fontSize: "0.8em", // Adjust the font size as needed
              color: "green",
              padding: "5px",
              backgroundColor: "#f0f8ff", // Light blue background color
              borderRadius: "5px",
              boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.5)", // Box shadow for a subtle effect
            }}
          >
            {copiedMessage}
          </div>
        )}
      </React.Fragment>
    );
  } else if (businessType === "partnership") {
    return (
      <React.Fragment>
        <div>
          <span
            className="badge badge-secondary"
            onClick={(e) =>
              handleVariableClick("$Accountant.Partner.FirstName$", e)
            }
            style={{ cursor: "pointer" }}
          >
            $Accountant.Partner.FirstName$
          </span>
          <span
            className="badge badge-secondary"
            onClick={(e) =>
              handleVariableClick("$Accountant.Partner.LastName$", e)
            }
            style={{ cursor: "pointer" }}
          >
            $Accountant.Partner.LastName$
          </span>
          <span
            className="badge badge-secondary"
            onClick={(e) =>
              handleVariableClick("$Accountant.Partner.Email$", e)
            }
            style={{ cursor: "pointer" }}
          >
            $Accountant.Partner.Email$
          </span>
          <span
            className="badge badge-secondary"
            onClick={(e) =>
              handleVariableClick("$Accountant.Partner.Phone$", e)
            }
            style={{ cursor: "pointer" }}
          >
            $Accountant.Partner.Phone$
          </span>
        </div>
        <div>
          <span
            className="badge badge-secondary"
            onClick={(e) => handleVariableClick("$Accountant.TradingName$", e)}
            style={{ cursor: "pointer" }}
          >
            $Accountant.TradingName$
          </span>
          <span
            className="badge badge-secondary"
            onClick={(e) =>
              handleVariableClick("$Accountant.TradingAddress$", e)
            }
            style={{ cursor: "pointer" }}
          >
            $Accountant.TradingAddress$
          </span>
          <span
            className="badge badge-secondary"
            onClick={(e) =>
              handleVariableClick("$Accountant.TradingAddressWithLineBreak$", e)
            }
            style={{ cursor: "pointer" }}
          >
            $Accountant.TradingAddressWithLineBreak$
          </span>
        </div>
        <div>
          <span
            className="badge badge-secondary"
            onClick={(e) =>
              handleVariableClick("$Accountant.Partner.Address$", e)
            }
            style={{ cursor: "pointer" }}
          >
            $Accountant.Partner.Address$
          </span>
          <span
            className="badge badge-secondary"
            onClick={(e) =>
              handleVariableClick(
                "$Accountant.Partner.AddressWithLineBreak$",
                e
              )
            }
            style={{ cursor: "pointer" }}
          >
            $Accountant.Partner.AddressWithLineBreak$
          </span>
        </div>
        <div>
          <span
            className="badge badge-secondary"
            onClick={(e) =>
              handleVariableClick(
                "$Accountant.AffiliatedAccountingBodyName$",
                e
              )
            }
            style={{ cursor: "pointer" }}
          >
            $Accountant.AffiliatedAccountingBodyName$
          </span>
          <span
            className="badge badge-secondary"
            onClick={(e) =>
              handleVariableClick("$Accountant.CountryIncorporatedIn$", e)
            }
            style={{ cursor: "pointer" }}
          >
            $Accountant.CountryIncorporatedIn$
          </span>
          <span
            className="badge badge-secondary"
            onClick={(e) =>
              handleVariableClick("$CurrentDate$", e)
            }
            style={{ cursor: "pointer" }}
          >
            $CurrentDate$
          </span>
        </div>
        {/* Display the copied message */}
        {copiedMessage && (
          <div
            style={{
              position: "fixed",
              top: cursorPosition.y - 30 + "px",
              left: cursorPosition.x + "px",
              fontSize: "0.8em", // Adjust the font size as needed
              color: "green",
              padding: "5px",
              backgroundColor: "#f0f8ff", // Light blue background color
              borderRadius: "5px",
              boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.5)", // Box shadow for a subtle effect
            }}
          >
            {copiedMessage}
          </div>
        )}
      </React.Fragment>
    );
  } else if (["ltd", "llp"].includes(businessType)) {
    return (
      <React.Fragment>
        <div>
          <span
            className="badge badge-secondary"
            onClick={(e) =>
              handleVariableClick("$Accountant.Officer.FirstName$", e)
            }
            style={{ cursor: "pointer" }}
          >
            $Accountant.Officer.FirstName$
          </span>
          <span
            className="badge badge-secondary"
            onClick={(e) =>
              handleVariableClick("$Accountant.Officer.LastName$", e)
            }
            style={{ cursor: "pointer" }}
          >
            $Accountant.Officer.LastName$
          </span>
          <span
            className="badge badge-secondary"
            onClick={(e) =>
              handleVariableClick("$Accountant.Officer.Email$", e)
            }
            style={{ cursor: "pointer" }}
          >
            $Accountant.Officer.Email$
          </span>
          <span
            className="badge badge-secondary"
            onClick={(e) =>
              handleVariableClick("$Accountant.Officer.Phone$", e)
            }
            style={{ cursor: "pointer" }}
          >
            $Accountant.Officer.Phone$
          </span>
        </div>
        <div>
          <span
            className="badge badge-secondary"
            onClick={(e) => handleVariableClick("$Accountant.TradingName$", e)}
            style={{ cursor: "pointer" }}
          >
            $Accountant.TradingName$
          </span>
          <span
            className="badge badge-secondary"
            onClick={(e) =>
              handleVariableClick("$Accountant.TradingAddress$", e)
            }
            style={{ cursor: "pointer" }}
          >
            $Accountant.TradingAddress$
          </span>
          <span
            className="badge badge-secondary"
            onClick={(e) =>
              handleVariableClick("$Accountant.TradingAddressWithLineBreak$", e)
            }
            style={{ cursor: "pointer" }}
          >
            $Accountant.TradingAddressWithLineBreak$
          </span>
        </div>
        <div>
          <span
            className="badge badge-secondary"
            onClick={(e) => handleVariableClick("$Accountant.Company.Name$", e)}
            style={{ cursor: "pointer" }}
          >
            $Accountant.Company.Name$
          </span>
          <span
            className="badge badge-secondary"
            onClick={(e) =>
              handleVariableClick("$Accountant.Company.Number$", e)
            }
            style={{ cursor: "pointer" }}
          >
            $Accountant.Company.Number$
          </span>
          <span
            className="badge badge-secondary"
            onClick={(e) =>
              handleVariableClick("$Accountant.Company.RegisteredAddress$", e)
            }
            style={{ cursor: "pointer" }}
          >
            $Accountant.Company.RegisteredAddress$
          </span>
          <span
            className="badge badge-secondary"
            onClick={(e) =>
              handleVariableClick(
                "$Accountant.Company.RegisteredAddressWithLineBreak$",
                e
              )
            }
            style={{ cursor: "pointer" }}
          >
            $Accountant.Company.RegisteredAddressWithLineBreak$
          </span>
        </div>
        <div>
          <span
            className="badge badge-secondary"
            onClick={(e) =>
              handleVariableClick(
                "$Accountant.AffiliatedAccountingBodyName$",
                e
              )
            }
            style={{ cursor: "pointer" }}
          >
            $Accountant.AffiliatedAccountingBodyName$
          </span>
          <span
            className="badge badge-secondary"
            onClick={(e) =>
              handleVariableClick("$Accountant.CountryIncorporatedIn$", e)
            }
            style={{ cursor: "pointer" }}
          >
            $Accountant.CountryIncorporatedIn$
          </span>
          <span
            className="badge badge-secondary"
            onClick={(e) =>
              handleVariableClick("$CurrentDate$", e)
            }
            style={{ cursor: "pointer" }}
          >
            $CurrentDate$
          </span>
        </div>
        <div className="separator mt-2 mb-2"></div>
        {/* Display the copied message */}
        {copiedMessage && (
          <div
            style={{
              position: "fixed",
              top: cursorPosition.y - 30 + "px",
              left: cursorPosition.x + "px",
              fontSize: "0.8em", // Adjust the font size as needed
              color: "green",
              padding: "5px",
              backgroundColor: "#f0f8ff", // Light blue background color
              borderRadius: "5px",
              boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.5)", // Box shadow for a subtle effect
            }}
          >
            {copiedMessage}
          </div>
        )}
      </React.Fragment>
    );
  } else if (businessType == "reset-password") {
    return (
      <React.Fragment>
        <div>
          <span
            className="badge badge-secondary"
            onClick={(e) => handleVariableClick("$AppUrl$", e)}
            style={{ cursor: "pointer" }}
          >
            $AppUrl$
          </span>
          <span
            className="badge badge-secondary"
            onClick={(e) => handleVariableClick("$firstName$", e)}
            style={{ cursor: "pointer" }}
          >
            $firstName$
          </span>
          <span
            className="badge badge-secondary"
            onClick={(e) => handleVariableClick("$resetPasswordUrl$", e)}
            style={{ cursor: "pointer" }}
          >
            $resetPasswordUrl$
          </span>
          <span
            className="badge badge-secondary"
            onClick={(e) => handleVariableClick("$loginUrl$", e)}
            style={{ cursor: "pointer" }}
          >
            $loginUrl$
          </span>
        </div>
        {/* Display the copied message */}
        {copiedMessage && (
          <div
            style={{
              position: "fixed",
              top: cursorPosition.y - 30 + "px",
              left: cursorPosition.x + "px",
              fontSize: "0.8em", // Adjust the font size as needed
              color: "green",
              padding: "5px",
              backgroundColor: "#f0f8ff", // Light blue background color
              borderRadius: "5px",
              boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.5)", // Box shadow for a subtle effect
            }}
          >
            {copiedMessage}
          </div>
        )}
      </React.Fragment>
    );
  } else if (businessType == "quote") {
    return (
      <React.Fragment>
        <div>
          <span
            className="badge badge-secondary"
            onClick={(e) => handleVariableClick("$firstName$", e)}
            style={{ cursor: "pointer" }}
          >
            $firstName$
          </span>
          <span
            className="badge badge-secondary"
            onClick={(e) => handleVariableClick("$quoteRef$", e)}
            style={{ cursor: "pointer" }}
          >
            $quoteRef$
          </span>
          <span
            className="badge badge-secondary"
            onClick={(e) => handleVariableClick("$User.firstName$", e)}
            style={{ cursor: "pointer" }}
          >
            $User.firstName$
          </span>
          <span
            className="badge badge-secondary"
            onClick={(e) => handleVariableClick("$User.lastName$", e)}
            style={{ cursor: "pointer" }}
          >
            $User.lastName$
          </span>
          <span
            className="badge badge-secondary"
            onClick={(e) => handleVariableClick("$ServiceDescription$", e)}
            style={{ cursor: "pointer" }}
          >
            $ServiceDescription$
          </span>
        </div>
        {/* Display the copied message */}
        {copiedMessage && (
          <div
            style={{
              position: "fixed",
              top: cursorPosition.y - 30 + "px",
              left: cursorPosition.x + "px",
              fontSize: "0.8em", // Adjust the font size as needed
              color: "green",
              padding: "5px",
              backgroundColor: "#f0f8ff", // Light blue background color
              borderRadius: "5px",
              boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.5)", // Box shadow for a subtle effect
            }}
          >
            {copiedMessage}
          </div>
        )}
      </React.Fragment>
    );
  } else if (businessType == "contract") {
    return (
      <React.Fragment>
        <div>
          <span
            className="badge badge-secondary"
            onClick={(e) => handleVariableClick("$signingUrl$", e)}
            style={{ cursor: "pointer" }}
          >
            $signingUrl$
          </span>
          <span
            className="badge badge-secondary"
            onClick={(e) => handleVariableClick("$owner.firstName$", e)}
            style={{ cursor: "pointer" }}
          >
            $owner.firstName$
          </span>
          <span
            className="badge badge-secondary"
            onClick={(e) => handleVariableClick("$owner.lastName$", e)}
            style={{ cursor: "pointer" }}
          >
            $owner.lastName$
          </span>
          <span
            className="badge badge-secondary"
            onClick={(e) =>
              handleVariableClick("$organisation.trading_business_name$", e)
            }
            style={{ cursor: "pointer" }}
          >
            $organisation.trading_business_name$
          </span>
          <span
            className="badge badge-secondary"
            onClick={(e) => handleVariableClick("$organisation.logo_url$", e)}
            style={{ cursor: "pointer" }}
          >
            $organisation.logo_url$
          </span>
          <span
            className="badge badge-secondary"
            onClick={(e) => handleVariableClick("$signatory.firstName$", e)}
            style={{ cursor: "pointer" }}
          >
            $signatory.firstName$
          </span>
          {/* <span className="badge badge-secondary">$signatories$</span> */}
          <span
            className="badge badge-secondary"
            onClick={(e) => handleVariableClick("$documentName$", e)}
            style={{ cursor: "pointer" }}
          >
            $documentName$
          </span>
        </div>
        {/* Display the copied message */}
        {copiedMessage && (
          <div
            style={{
              position: "fixed",
              top: cursorPosition.y - 30 + "px",
              left: cursorPosition.x + "px",
              fontSize: "0.8em", // Adjust the font size as needed
              color: "green",
              padding: "5px",
              backgroundColor: "#f0f8ff", // Light blue background color
              borderRadius: "5px",
              boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.5)", // Box shadow for a subtle effect
            }}
          >
            {copiedMessage}
          </div>
        )}
      </React.Fragment>
    );
  } else if (businessType == "invitation") {
    return (
      <React.Fragment>
        <div>
          <span
            className="badge badge-secondary"
            onClick={(e) => handleVariableClick("$appUrl$", e)}
            style={{ cursor: "pointer" }}
          >
            $appUrl$
          </span>
          <span
            className="badge badge-secondary"
            onClick={(e) => handleVariableClick("$firstName$", e)}
            style={{ cursor: "pointer" }}
          >
            $firstName$
          </span>
          <span
            className="badge badge-secondary"
            onClick={(e) => handleVariableClick("$activateUrl$", e)}
            style={{ cursor: "pointer" }}
          >
            $activateUrl$
          </span>
        </div>
        {/* Display the copied message */}
        {copiedMessage && (
          <div
            style={{
              position: "fixed",
              top: cursorPosition.y - 30 + "px",
              left: cursorPosition.x + "px",
              fontSize: "0.8em", // Adjust the font size as needed
              color: "green",
              padding: "5px",
              backgroundColor: "#f0f8ff", // Light blue background color
              borderRadius: "5px",
              boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.5)", // Box shadow for a subtle effect
            }}
          >
            {copiedMessage}
          </div>
        )}
      </React.Fragment>
    );
  } else if (businessType == "quote_pdf") {
    return (
      <React.Fragment>
        <div>
          <span
            className="badge badge-secondary"
            onClick={(e) => handleVariableClick("$firstName$", e)}
            style={{ cursor: "pointer" }}
          >
            $firstName$
          </span>
          <span
            className="badge badge-secondary"
            onClick={(e) => handleVariableClick("$quoteRef$", e)}
            style={{ cursor: "pointer" }}
          >
            $quoteRef$
          </span>
          <span
            className="badge badge-secondary"
            onClick={(e) => handleVariableClick("$User.firstName$", e)}
            style={{ cursor: "pointer" }}
          >
            $User.firstName$
          </span>
          <span
            className="badge badge-secondary"
            onClick={(e) => handleVariableClick("$User.lastName$", e)}
            style={{ cursor: "pointer" }}
          >
            $User.lastName$
          </span>
        </div>
        {/* Display the copied message */}
        {copiedMessage && (
          <div
            style={{
              position: "fixed",
              top: cursorPosition.y - 30 + "px",
              left: cursorPosition.x + "px",
              fontSize: "0.8em", // Adjust the font size as needed
              color: "green",
              padding: "5px",
              backgroundColor: "#f0f8ff", // Light blue background color
              borderRadius: "5px",
              boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.5)", // Box shadow for a subtle effect
            }}
          >
            {copiedMessage}
          </div>
        )}
      </React.Fragment>
    );
  } else if (businessType == "contract_viewed") {
    return (
      <React.Fragment>
        <div>
          <span
            className="badge badge-secondary"
            onClick={(e) => handleVariableClick("$signatory.first_name$", e)}
            style={{ cursor: "pointer" }}
          >
            $signatory.first_name$
          </span>
          <span
            className="badge badge-secondary"
            onClick={(e) => handleVariableClick("$signatory.last_name$", e)}
            style={{ cursor: "pointer" }}
          >
            $signatory.last_name$
          </span>
          <span
            className="badge badge-secondary"
            onClick={(e) => handleVariableClick("$owner.first_name$", e)}
            style={{ cursor: "pointer" }}
          >
            $owner.first_name$
          </span>
          <span
            className="badge badge-secondary"
            onClick={(e) => handleVariableClick("$event$", e)}
            style={{ cursor: "pointer" }}
          >
            $event$
          </span>
          <span
            className="badge badge-secondary"
            onClick={(e) => handleVariableClick("$contractCode$", e)}
            style={{ cursor: "pointer" }}
          >
            $contractCode$
          </span>
        </div>
        {/* Display the copied message */}
        {copiedMessage && (
          <div
            style={{
              position: "fixed",
              top: cursorPosition.y - 30 + "px",
              left: cursorPosition.x + "px",
              fontSize: "0.8em", // Adjust the font size as needed
              color: "green",
              padding: "5px",
              backgroundColor: "#f0f8ff", // Light blue background color
              borderRadius: "5px",
              boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.5)", // Box shadow for a subtle effect
            }}
          >
            {copiedMessage}
          </div>
        )}
      </React.Fragment>
    );
  } else if (businessType == "contract_accepted") {
    return (
      <React.Fragment>
        <div>
          <span
            className="badge badge-secondary"
            onClick={(e) => handleVariableClick("$signatory.last_name$", e)}
            style={{ cursor: "pointer" }}
          >
            $signatory.last_name$
          </span>
          <span
            className="badge badge-secondary"
            onClick={(e) => handleVariableClick("$owner.first_name$", e)}
            style={{ cursor: "pointer" }}
          >
            $owner.first_name$
          </span>
          <span
            className="badge badge-secondary"
            onClick={(e) => handleVariableClick("$contractCode$", e)}
            style={{ cursor: "pointer" }}
          >
            $contractCode$
          </span>
          <span
            className="badge badge-secondary"
            onClick={(e) => handleVariableClick("$signatory.first_name$", e)}
            style={{ cursor: "pointer" }}
          >
            $signatory.first_name$
          </span>
          <span
            className="badge badge-secondary"
            onClick={(e) =>
              handleVariableClick("$organisation.trading_business_name$", e)
            }
            style={{ cursor: "pointer" }}
          >
            $organisation.trading_business_name$
          </span>
          <span
            className="badge badge-secondary"
            onClick={(e) => handleVariableClick("$paymentGateway.name$", e)}
            style={{ cursor: "pointer" }}
          >
            $paymentGateway.name$
          </span>
          <span
            className="badge badge-secondary"
            onClick={(e) => handleVariableClick("$paymentGateway.link$", e)}
            style={{ cursor: "pointer" }}
          >
            $paymentGateway.link$
          </span>
        </div>
        {/* Display the copied message */}
        {copiedMessage && (
          <div
            style={{
              position: "fixed",
              top: cursorPosition.y - 30 + "px",
              left: cursorPosition.x + "px",
              fontSize: "0.8em", // Adjust the font size as needed
              color: "green",
              padding: "5px",
              backgroundColor: "#f0f8ff", // Light blue background color
              borderRadius: "5px",
              boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.5)", // Box shadow for a subtle effect
            }}
          >
            {copiedMessage}
          </div>
        )}
      </React.Fragment>
    );
  } else if (businessType == "contract_declined") {
    return (
      <React.Fragment>
        <div>
          <span
            className="badge badge-secondary"
            onClick={(e) => handleVariableClick("$signatory.last_name$", e)}
            style={{ cursor: "pointer" }}
          >
            $signatory.last_name$
          </span>
          <span
            className="badge badge-secondary"
            onClick={(e) => handleVariableClick("$signatory.first_name$", e)}
            style={{ cursor: "pointer" }}
          >
            $signatory.first_name$
          </span>
          <span
            className="badge badge-secondary"
            onClick={(e) => handleVariableClick("$owner.first_name$", e)}
            style={{ cursor: "pointer" }}
          >
            $owner.first_name$
          </span>
          <span
            className="badge badge-secondary"
            onClick={(e) => handleVariableClick("$contractCode$", e)}
            style={{ cursor: "pointer" }}
          >
            $contractCode$
          </span>
          <span
            className="badge badge-secondary"
            onClick={(e) => handleVariableClick("$reason$", e)}
            style={{ cursor: "pointer" }}
          >
            $reason$
          </span>
        </div>
        {/* Display the copied message */}
        {copiedMessage && (
          <div
            style={{
              position: "fixed",
              top: cursorPosition.y - 30 + "px",
              left: cursorPosition.x + "px",
              fontSize: "0.8em", // Adjust the font size as needed
              color: "green",
              padding: "5px",
              backgroundColor: "#f0f8ff", // Light blue background color
              borderRadius: "5px",
              boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.5)", // Box shadow for a subtle effect
            }}
          >
            {copiedMessage}
          </div>
        )}
      </React.Fragment>
    );
  }
  return null;
};

export default AccountantVariables;
