import {
	SET_SHOW_API_ERROR_MODAL,
	GET_AVAILABLE_PAYMENT_METHODS,
	SET_SHOW_UPGRADE_MODAL,
	GET_AVAILABLE_PAYMENT_METHODS_SUCCESS
} from "./actionTypes";

export const setShowApiErrorModal = (isVisible=true, message="Something went wrong!") => {
	return {
		type: SET_SHOW_API_ERROR_MODAL,
		payload: {
			isVisible,
			message
		}
	}
}

export const setShowUpgradeModal = (isVisible= true) => {
	return {
		type: SET_SHOW_UPGRADE_MODAL,
		payload: {
			isVisible
		}
	}
}

export const getAvailablePaymentMethods = () => {
	return {
		type: GET_AVAILABLE_PAYMENT_METHODS
	}
}

export const getAvailablePaymentMethodsSuccess = (methods) => {
	return {
		type: GET_AVAILABLE_PAYMENT_METHODS_SUCCESS,
		payload: methods
	}
}
