import { takeEvery, fork, put, all, call } from "redux-saga/effects";
import axios from "axios";

import {
  SUBMIT_CLIENT,
  GET_CLIENTS,
  GET_CLIENT,
  SEARCH_CLIENT,
} from "./actionTypes";

import {
  submitClientSuccess,
  getClientsSuccess,
  getClientSuccess,
  searchClientSuccess,
} from "./actions";
import { setShowApiErrorModal } from "../common/actions";

import { setOrganisationFormFromClientData } from "../organisation/actions";

// AUTH related methods
import { getFirebaseBackend } from "../../helpers/authUtils";

const fireBaseBackend = getFirebaseBackend();

function* submitClient({ payload: { data, type } }) {
  try {
    const accessToken = yield fireBaseBackend.getAccessToken();
    let url;
    switch (type) {
      case "create":
        url = "create-new-client";
        break;
      case "edit":
        url = "update-client";
        break;
      default:
        break;
    }
    const response = yield axios({
      method: "POST",
      url,
      headers: { authorization: `Bearer ${accessToken}` },
      data,
    });
    if (response.data.success) {
      yield put(submitClientSuccess(response.data.result));
    } else {
      // yield put(createOrganisationFailed());
    }
  } catch (error) {
    yield put(setShowApiErrorModal());
    console.log(error);
    // if (error.response && error.response.data && error.response.data.error) {
    //     yield put(loginError(error.response.data.error));
    // } else {
    //     yield put(loginError("Something went Wrong!"));
    // }
    // yield put(createOrganisationFailed());
  }
}

function* getClients({ payload: page_num }) {
  try {
    const accessToken = yield fireBaseBackend.getAccessToken();
    const response = yield axios({
      method: "GET",
      url: `clients?page_num=${page_num}`,
      headers: { authorization: `Bearer ${accessToken}` },
    });
    if (response.data.success) {
      yield put(getClientsSuccess(response.data.result));
    } else {
      // yield put(createOrganisationFailed());
    }
  } catch (error) {
    yield put(setShowApiErrorModal());
    console.log(error);
    // if (error.response && error.response.data && error.response.data.error) {
    //     yield put(loginError(error.response.data.error));
    // } else {
    //     yield put(loginError("Something went Wrong!"));
    // }
    // yield put(createOrganisationFailed());
  }
}

function* getClient({ payload: id }) {
  try {
    const accessToken = yield fireBaseBackend.getAccessToken();
    const response = yield axios({
      method: "GET",
      url: `client/${id}`,
      headers: { authorization: `Bearer ${accessToken}` },
    });
    if (response.data.success) {
      yield put(setOrganisationFormFromClientData(response.data.result));
      yield put(
        getClientSuccess(
          response.data.result._id,
          response.data.result.business_type
        )
      );
    } else {
      // yield put(createOrganisationFailed());
    }
  } catch (error) {
    yield put(setShowApiErrorModal());
    console.log(error);
    // if (error.response && error.response.data && error.response.data.error) {
    //     yield put(loginError(error.response.data.error));
    // } else {
    //     yield put(loginError("Something went Wrong!"));
    // }
    // yield put(createOrganisationFailed());
  }
}

function* searchClient({ payload: query }) {
  try {
    const accessToken = yield fireBaseBackend.getAccessToken();
    const response = yield axios({
      method: "GET",
      url: `search/client?query=${query}`,
      headers: { authorization: `Bearer ${accessToken}` },
    });
    if (response.data.success) {
      console.log(response.data);
      yield put(searchClientSuccess(response.data.result));
    } else {
      // yield put(createOrganisationFailed());
    }
  } catch (error) {
    yield put(setShowApiErrorModal());
    console.log(error);
    // if (error.response && error.response.data && error.response.data.error) {
    //     yield put(loginError(error.response.data.error));
    // } else {
    //     yield put(loginError("Something went Wrong!"));
    // }
    // yield put(createOrganisationFailed());
  }
}

export function* watchSubmitClient() {
  yield takeEvery(SUBMIT_CLIENT, submitClient);
}

export function* watchGetClients() {
  yield takeEvery(GET_CLIENTS, getClients);
}

export function* watchGetClient() {
  yield takeEvery(GET_CLIENT, getClient);
}

export function* watchSearchClient() {
  yield takeEvery(SEARCH_CLIENT, searchClient);
}

function* clientSaga() {
  yield all([
    fork(watchSubmitClient),
    fork(watchGetClients),
    fork(watchGetClient),
    fork(watchSearchClient),
  ]);
}

export default clientSaga;
