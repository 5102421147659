import { takeEvery, fork, put, all, call } from "redux-saga/effects";
import axios from "axios";

import {
  GET_EMAIL_TEMPLATES,
  GET_EMAIL_TEMPLATES_SUCCESS,
  GET_EMAIL_TEMPLATE,
  GET_EMAIL_TEMPLATE_SUCCESS,
  SUBMIT_EMAIL_TEMPLATE,
  UPLOAD_EMAIL_TEMPLATE,
  GENERATE_EMAIL_TEMPLATES,
} from "./actionTypes";

import {
  getEmailTemplatesSuccess,
  getEmailTemplateSuccess,
  submitEmailTemplateSuccess,
  uploadEmailTemplateSuccess,
  createEmailFailed,
} from "./actions";
import { setShowApiErrorModal } from "../common/actions";

// AUTH related methods
import { getFirebaseBackend } from "../../helpers/authUtils";

const fireBaseBackend = getFirebaseBackend();

function* getEmailTemplates() {
  try {
    const accessToken = yield fireBaseBackend.getAccessToken();
    const response = yield axios({
      method: "GET",
      url: `email-templates`,
      headers: { authorization: `Bearer ${accessToken}` },
    });
    if (response.data.success) {
      yield put(getEmailTemplatesSuccess(response.data.result));
    } else {
      // yield put(createOrganisationFailed());
    }
  } catch (error) {
    yield put(setShowApiErrorModal());
    console.log(error);
    // if (error.response && error.response.data && error.response.data.error) {
    //     yield put(loginError(error.response.data.error));
    // } else {
    //     yield put(loginError("Something went Wrong!"));
    // }
    // yield put(createOrganisationFailed());
  }
}

function* generateEmailTemplates() {
  try {
    const accessToken = yield fireBaseBackend.getAccessToken();
    const response = yield axios({
      method: "GET",
      url: `generate-email-templates`,
      headers: { authorization: `Bearer ${accessToken}` },
    });
    if (response.data.success) {
      yield put(getEmailTemplatesSuccess(response.data.result));
    }
  } catch (error) {
    yield put(setShowApiErrorModal());
  }
}

function* getEmailTemplate({ payload: id }) {
  try {
    const accessToken = yield fireBaseBackend.getAccessToken();
    const response = yield axios({
      method: "GET",
      url: `template/${id}`,
      headers: { authorization: `Bearer ${accessToken}` },
    });
    if (response.data.success) {
      yield put(getEmailTemplateSuccess(response.data.result));
    } else {
      // yield put(createOrganisationFailed());
    }
  } catch (error) {
    yield put(setShowApiErrorModal());
    // if (error.response && error.response.data && error.response.data.error) {
    //     yield put(loginError(error.response.data.error));
    // } else {
    //     yield put(loginError("Something went Wrong!"));
    // }
    // yield put(createOrganisationFailed());
  }
}

function* submitEmailTemplate({ payload: { data, type } }) {
  try {
    const accessToken = yield fireBaseBackend.getAccessToken();
    let url;
    switch (type) {
      case "new":
        url = "create-new-template";
        break;
      case "edit":
        url = "update-template";
        break;
      default:
        break;
    }
    const response = yield axios({
      method: "POST",
      url,
      headers: { authorization: `Bearer ${accessToken}` },
      data,
    });
    if (response.data.success) {
      yield put(submitEmailTemplateSuccess());
    } else {
      yield put(createEmailFailed(response.data.error));
      // yield put(createOrganisationFailed());
    }
  } catch (error) {
    yield put(setShowApiErrorModal());
    // if (error.response && error.response.data && error.response.data.error) {
    //     yield put(loginError(error.response.data.error));
    // } else {
    //     yield put(loginError("Something went Wrong!"));
    // }
    // yield put(createOrganisationFailed());
  }
}

export function* watchGetEmailTemplates() {
  yield takeEvery(GET_EMAIL_TEMPLATES, getEmailTemplates);
}

export function* watchGetEmailTemplate() {
  yield takeEvery(GET_EMAIL_TEMPLATE, getEmailTemplate);
}

export function* watchSubmitEmailTemplate() {
  yield takeEvery(SUBMIT_EMAIL_TEMPLATE, submitEmailTemplate);
}

export function* watchGenerateEmailTemplates() {
  yield takeEvery(GENERATE_EMAIL_TEMPLATES, generateEmailTemplates);
}

function* EmailTemplateSaga() {
  yield all([
    fork(watchGetEmailTemplates),
    fork(watchGetEmailTemplate),
    fork(watchSubmitEmailTemplate),
    fork(watchGenerateEmailTemplates),
  ]);
}

export default EmailTemplateSaga;
