import React, { Component } from "react";
import { connect } from "react-redux";
import {
  getPaymentGateways,
  savePaymentGateway,
  onInputChange,
  setSavePaymentKeySuccessModal,
} from "../../store/setting/actions";
import Loader from "../../components/Loader";
import SweetAlert from "react-bootstrap-sweetalert";

class PaymentGateways extends Component {
  constructor(props) {
    super(props);
    this.saveStripeGateway = this.saveStripeGateway.bind(this);
    this.saveGoCardlessGateway = this.saveGoCardlessGateway.bind(this);
    this.state = {
      stripe_publishable_keyError: false,
      stripe_secret_keyError: false,
      gocardless_access_tokenError: false,
    };
  }
  componentDidMount() {
    this.props.getPaymentGateways();
  }
  saveStripeGateway() {
    this.setState({
      gocardless_access_tokenError: false,
    });
    if (
      this.props.stripe_publishable_key === undefined ||
      this.props.stripe_secret_key === undefined
    ) {
      if (this.props.stripe_publishable_key === undefined) {
        this.setState({
          stripe_publishable_keyError: true,
        });
      }
      if (this.props.stripe_secret_key === undefined) {
        this.setState({
          stripe_secret_keyError: true,
        });
      }
      return;
    }
    if (
      this.props.stripe_publishable_key.trim() === "" ||
      this.props.stripe_secret_key.trim() === ""
    ) {
      if (this.props.stripe_publishable_key.trim() === "") {
        this.setState({
          stripe_publishable_keyError: true,
        });
      }
      if (this.props.stripe_secret_key.trim() === "") {
        this.setState({
          stripe_secret_keyError: true,
        });
      }
    } else {
      this.setState({
        stripe_publishable_keyError: false,
        stripe_secret_keyError: false,
      });
      this.props.savePaymentGateway({
        gateway: "Stripe",
        stripe_publishable_key: this.props.stripe_publishable_key,
        stripe_secret_key: this.props.stripe_secret_key,
      });
    }
  }
  saveGoCardlessGateway() {
    this.setState({
      stripe_publishable_keyError: false,
      stripe_secret_keyError: false,
    });
    if (this.props.gocardless_access_token === undefined) {
      this.setState({
        gocardless_access_tokenError: true,
      });
      return;
    }
    if (this.props.gocardless_access_token.trim() === "") {
      this.setState({
        gocardless_access_tokenError: true,
      });
    } else {
      this.props.savePaymentGateway({
        gateway: "GoCardless",
        gocardless_access_token: this.props.gocardless_access_token,
      });
    }
  }
  render() {
    return (
      <React.Fragment>
        <div className="page-info-header">
          <div className="container-fluid">
            <div className="page-display-title">Payment Gateways</div>
          </div>
        </div>
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="card cus-card">
                <div className="card-body">
                  <div className="row">
                    <div className="col-4">
                      <div className="card">
                        <div className="card-header text-center">
                          <img
                            className="payment-gateway-logo"
                            src="/gocardless-logo.png"
                          />
                        </div>
                        <div className="card-body">
                          <label>
                            Access Token<span style={{ color: "red" }}>*</span>:
                          </label>
                          <input
                            className="input-text"
                            placeholder="Access Token"
                            type="password"
                            value={this.props.gocardless_access_token || ""}
                            onChange={(e) =>
                              this.props.onInputChange(
                                "gocardless_access_token",
                                e.target.value.trim()
                              )
                            }
                          />
                          {this.state.gocardless_access_tokenError ? (
                            <p style={{ color: "red" }}>
                              This field is required!
                            </p>
                          ) : null}
                        </div>
                        <div className="card-footer text-right">
                          <button
                            onClick={this.saveGoCardlessGateway}
                            className="btn btn-success" disabled
                          >
                            Save
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="col-4">
                      <div className="card">
                        <div className="card-header text-center">
                          <img
                            className="payment-gateway-logo"
                            src="/stripe-logo.png"
                          />
                        </div>
                        <div className="card-body">
                          <label>
                            Publishable Key{" "}
                            <span style={{ color: "red" }}>*</span>:
                          </label>
                          <input
                            className="input-text mb-3"
                            placeholder="Publishable Key"
                            value={this.props.stripe_publishable_key || ""}
                            onChange={(e) =>
                              this.props.onInputChange(
                                "stripe_publishable_key",
                                e.target.value.trim()
                              )
                            }
                          />
                          {this.state.stripe_publishable_keyError ? (
                            <p style={{ color: "red" }}>
                              This field is required!
                            </p>
                          ) : null}
                          <label>
                            Secret Key<span style={{ color: "red" }}>*</span>:
                          </label>
                          <input
                            className="input-text"
                            placeholder="Secret Key"
                            type="password"
                            value={this.props.stripe_secret_key || ""}
                            onChange={(e) =>
                              this.props.onInputChange(
                                "stripe_secret_key",
                                e.target.value.trim()
                              )
                            }
                          />
                          {this.state.stripe_secret_keyError ? (
                            <p style={{ color: "red" }}>
                              This field is required!
                            </p>
                          ) : null}
                        </div>
                        <div className="card-footer text-right">
                          <button
                            onClick={this.saveStripeGateway}
                            className="btn btn-success" disabled
                          >
                            Save
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {this.props.savePaymentKeySuccessModal && (
          <SweetAlert
            title=""
            success
            confirmBtnBsStyle="success"
            onConfirm={() => {
              this.props.setSavePaymentKeySuccessModal(false);
              this.props.history.replace("/settings/payment-gateways");
            }}
          >
            <div className="mb-2 success-alert-message">
              {this.props.savePaymentKeySuccessModalMessage}
            </div>
          </SweetAlert>
        )}
        {this.props.loading && <Loader />}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  const {
    stripe_publishable_key,
    stripe_secret_key,
    gocardless_access_token,
    loading,
    savePaymentKeySuccessModal,
    savePaymentKeySuccessModalMessage,
  } = state.Setting;
  return {
    stripe_publishable_key,
    stripe_secret_key,
    gocardless_access_token,
    loading,
    savePaymentKeySuccessModal,
    savePaymentKeySuccessModalMessage,
  };
};

export default connect(mapStateToProps, {
  getPaymentGateways,
  savePaymentGateway,
  onInputChange,
  setSavePaymentKeySuccessModal,
})(PaymentGateways);
