import React, { Component } from 'react'
import { Row, Col, Card, CardBody, Alert } from "reactstrap";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";

import { userError } from "../store/actions";

// img
import errorImg from "../assets/images/error.png";

class ErrorPage extends Component {
    constructor(props) {
        super(props)
        console.log("props :: ", props.error)
    }

    render() {
        return (
            <React.Fragment>
                <div className="authentication-bg d-flex align-items-center pb-0 vh-100">
                    <div className="content-center w-100">
                        <div className="container">
                            <Card className="mo-mt-2">
                                <CardBody>
                                    <Row className="align-items-center">
                                        <Col lg="4" className="ml-auto">
                                            <div className="ex-page-content">
                                                {/* <h1 className="text-dark display-1 mt-4">404!</h1> */}
                                                <h4 className="mb-4">Error</h4>
                                                {this.props.error ? (
                                                    <p color="danger">{this.props.error}</p>
                                                ) : null}
                                                {/* <p className="mb-5">
                                                    It will be as simple as Occidental in fact, it will be
                                                    Occidental to an English person
                        </p> */}
                                                <Link
                                                    className="btn btn-primary mb-5 waves-effect waves-light"
                                                    to="/"
                                                >
                                                    <i className="mdi mdi-home"></i> Back to Login
                        </Link>
                                            </div>
                                        </Col>
                                        <Col lg="5" className="mx-auto">
                                            <img
                                                src={errorImg}
                                                alt=""
                                                className="img-fluid mx-auto d-block"
                                            />
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

// export default ErrorPage
const mapStatetoProps = state => {
    const { error, loading } = state.User;
    return { error, loading };
};

export default withRouter(connect(mapStatetoProps, { userError })(ErrorPage));