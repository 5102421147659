import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { stateFromHTML } from 'draft-js-import-html';
import { onInputChange, submitTextTemplate, resetCreateNewTextTemplateForm, getTextTemplate } from "../store/textTemplate/actions";
import MyEditor from "../components/MyEditor";
import Loader from "../components/Loader";

class CreateNewTextTemplate extends Component {
	constructor(props) {
		super(props);
		this.cancel = this.cancel.bind(this);
		this.formatDataAndSubmitTextTemplate = this.formatDataAndSubmitTextTemplate.bind(this);
	}
	cancel() {
		this.props.history.goBack();
	}
	formatDataAndSubmitTextTemplate() {
		const data = {
			name: this.props.textTemplateName,
			description: this.props.textTemplateDescription,
			content: this.props.textTemplateContent
		}
		if (this.type==="edit") {
			data.id = this.props.textTemplateId;
		}
		this.props.submitTextTemplate(data, this.type);
	}
	componentDidMount() {
		if (this.type==="new") {
			this.props.resetCreateNewTextTemplateForm();
		}
		else if (this.type==="edit") {
			this.props.getTextTemplate(this.props.match.params.id);        
		}
	}
	render() {
		this.type = this.props.match.path.replace("/text-templates/", "").replace("/:id", "");
		const texts = {
			new: {
				header: "Create New Text Template",
				submitButton: "Create Template",
				actionName: "created"
			},
			edit: {
				header: "Edit Text Template",
				submitButton: "Update Template",
				actionName: "updated"
			}
		}
		return (
			<React.Fragment>
				{this.props.loading && <Loader />}
				<div className="container-fluid new-item-page-container">
					<div className="new-item-page-nav"><i onClick={this.cancel} className="ion ion-md-arrow-round-back back-button"></i></div>
					<div className="new-item-page-content">
						<div className="row form-row">
							<div className="col-12">
								<h3>{texts[this.type].header}</h3>
								<div className="separator mt-3" />
								<div className="p-3">
									<div className="row fieldset">
										<div className="col-4 text-right">
											<label className="fieldset-label required">Template Name</label>
										</div>
										<div className="col-8">
											<input onChange={(e) => this.props.onInputChange("textTemplateName", e.target.value)} value={this.props.textTemplateName || ""} className="input-text" type="text" placeholder="Template Name" />
										</div>
									</div>
									<div className="row fieldset">
										<div className="col-4 text-right">
											<label className="fieldset-label">Template Description</label>
										</div>
										<div className="col-8">
											<textarea onChange={(e) => this.props.onInputChange("textTemplateDescription", e.target.value)} value={this.props.textTemplateDescription || ""} className="input-text" placeholder="Template Description"></textarea>
										</div>
									</div>
									<div className="row fieldset">
										<div className="col-12">
											<h4 className="mt-2">Template Content</h4>
											<div className="separator mb-3" />
											{((this.props.textTemplateId && this.type==="edit") || (this.type==="new")) && !this.props.loading && <MyEditor
												value={this.props.textTemplateContent}
												variable={"textTemplateContent"}
												onChange={this.props.onInputChange}
											/>}
										</div>
									</div>
								</div>
								<div className="separator mt-3 mb-3" />
                                <div className="row fieldset">
                                    <div className="col-12 text-right">
                                    	<button onClick={this.cancel} className="btn">Cancel</button>
										<button onClick={this.formatDataAndSubmitTextTemplate} className="btn create-item">{texts[this.type].submitButton}</button>
                                    </div>
                                </div>
							</div>
						</div>
					</div>
				</div>
			</React.Fragment>
		);
	}
}

const mapStateToProps = state => state.TextTemplate;

export default withRouter(connect(mapStateToProps, { onInputChange, submitTextTemplate, resetCreateNewTextTemplateForm, getTextTemplate })(CreateNewTextTemplate));
