import {
  SET_USER_LOADING,
  ON_USER_INPUT_CHANGE,
  GET_USERS,
  GET_USERS_SUCCESS,
  INVITE_USER,
  INVITE_USER_SUCCESS,
  SET_SHOW_INVITE_USER_MODAL,
  SET_SHOW_INVITE_NEW_USER_SUCCESS_MODAL,
  SET_SHOW_EDIT_USER_MODAL,
  ACCEPT_INVITE,
  USER_INVITE_ERROR,
  UPDATE_USER_ROLE,
  UPDATE_USER_ROLE_SUCCESS,
  SET_SHOW_EDIT_USER_SUCCESS_MODAL,
  RESET_INVITE_USER_FORM,
  UPDATE_USER,
  UPDATE_USER_SUCCESS,
  VALIDATE_ACCEPT_TOKEN,
  VALIDATE_ACCEPT_TOKEN_SUCCESS,
  VALIDATE_ACCEPT_TOKEN_FAIL,
} from "./actionTypes";

const initialState = {
  loading: false,
  users: [],
  showInviteUserModal: false,
  showInviteNewUserSuccessModal: false,
  showEditUserModal: false,
  showEditUserSuccessModal: false,
  error: "",
  pagination: {},
  validateLoading: true,
  tokenValid: false,
};

const user = (state = initialState, action) => {
  let newState;
  switch (action.type) {
    case SET_USER_LOADING:
      return { ...state, loading: action.payload };
    case ON_USER_INPUT_CHANGE:
      return { ...state, [action.payload.field]: action.payload.value };
    case GET_USERS:
      return { ...state, users: [], loading: true };
    case GET_USERS_SUCCESS:
      return {
        ...state,
        users: action.payload.users,
        pagination: {
          count: action.payload.count,
          page_num: action.payload.page_num,
          total_pages: action.payload.total_pages,
        },
        loading: false,
      };
    case INVITE_USER:
      return { ...state, loading: true };
    case INVITE_USER_SUCCESS:
      return { ...state, loading: false, showInviteNewUserSuccessModal: true };
    case SET_SHOW_INVITE_USER_MODAL:
      return { ...state, showInviteUserModal: action.payload };
    case SET_SHOW_INVITE_NEW_USER_SUCCESS_MODAL:
      return { ...state, showInviteNewUserSuccessModal: action.payload };
    case SET_SHOW_EDIT_USER_MODAL:
      return {
        ...state,
        showEditUserModal: action.payload.isVisible,
        editUserEmail: action.payload.editUserEmail,
        editUserIndex: action.payload.index,
        editUserRole: state.users[action.payload.index]
          ? state.users[action.payload.index].role_slug
          : "",
      };
    case VALIDATE_ACCEPT_TOKEN:
      return { ...state, validateLoading: true };
    case VALIDATE_ACCEPT_TOKEN_SUCCESS:
      return { ...state, validateLoading: false, tokenValid: true };
    case VALIDATE_ACCEPT_TOKEN_FAIL:
      return { ...state, validateLoading: false, tokenValid: false };
    case ACCEPT_INVITE:
      return { ...state, loading: true };
    case USER_INVITE_ERROR:
      return { ...state, error: action.payload, loading: false };
    case UPDATE_USER_ROLE:
      return { ...state, loading: true };
    case UPDATE_USER_ROLE_SUCCESS:
      return { ...state, loading: false, showEditUserSuccessModal: true };
    case SET_SHOW_EDIT_USER_SUCCESS_MODAL:
      return { ...state, showEditUserSuccessModal: action.payload };
    case RESET_INVITE_USER_FORM:
      newState = { ...state };
      delete newState["inviteNewUserFirstName"];
      delete newState["inviteNewUserLastName"];
      delete newState["inviteNewUserEmail"];
      delete newState["inviteNewUserRole"];
      return newState;
    case UPDATE_USER:
      return { ...state, loading: true };
    case UPDATE_USER_SUCCESS:
      return { ...state, loading: false, showEditUserSuccessModal: true };
    default:
      return state;
  }
};

export default user;
