import React, { Component } from 'react';
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Loader from "../components/Loader";
import { getTextTemplates } from "../store/textTemplate/actions";
import TextTemplateListTable from "../components/TextTemplateListTable";

class TextTemplates extends Component {
  componentDidMount() {
    this.props.getTextTemplates();
  }
  render() {
    return (
      <React.Fragment>
        {this.props.loading && <Loader />}
        <div className="page-info-header">
          <div className="container-fluid">
            <div className="page-display-title">Text Templates</div>
            <div className="text-right flex1">
                <button onClick={() => this.props.history.push("/text-templates/new")} className="btn btn-success create-item"><i className="ion ion-md-add-circle"></i> <span>Add New Text Template</span></button>
              </div>
          </div>
        </div>
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <TextTemplateListTable
                    textTemplates={this.props.textTemplates}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  const {
    textTemplates, loading
  } = state.TextTemplate;
  return {
    textTemplates, loading
  };
}

export default withRouter(connect(mapStateToProps, { getTextTemplates })(TextTemplates));
