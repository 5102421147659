import { takeEvery, fork, put, all, call } from 'redux-saga/effects';
import axios from 'axios';

//Account Redux states
import { REGISTER_USER } from './actionTypes';
import { registerUserSuccessful, registerUserFailed } from './actions';
// import { setShowApiErrorModal } from '../../common/actions';

//AUTH related methods
import { getFirebaseBackend } from '../../../helpers/authUtils';

const fireBaseBackend = getFirebaseBackend();

function* registerUser({ payload: { firstName, lastName, email, phone, country, history } }) {
    try {
    	const registerResponse = yield axios({
            method: "POST",
            url: "register",
            data: {
            	firstName,
            	lastName,
                email: email.toLocaleLowerCase(),
                phone,
                country
            }
        });
    	if (registerResponse.data.success) {
    		// 2. set name params in redux of confirm email page
    		// 1. redirect to confirm email page
            yield put(registerUserSuccessful(registerResponse.data.result));
    	} else {
            yield put(registerUserFailed(registerResponse.data.error));
        }
     //    const response = yield call(fireBaseBackend.registerUser, user.email, user.password);
     //    const token = yield fireBaseBackend.getToken();
     //    console.log(token);
     //    // console.log(response);
     //    yield call(axios, {
	    //     	headers: {authorization: `Bearer ${token}`},
	    //     method: "GET",
	    //     url: "test"
	    // });
     //    yield put(registerUserSuccessful(response));
     //    history.push('/dashboard');
    } catch (error) {
    	console.log(error);
        if (error.response && error.response.data && error.response.data.error) {
            yield put(registerUserFailed(error.response.data.error));
        } else {
            yield put(registerUserFailed("Something went Wrong!"));
        }
    }
}

export function* watchUserRegister() {
    yield takeEvery(REGISTER_USER, registerUser);
}

function* accountSaga() {
    yield all([
        fork(watchUserRegister)
    ]);
}

export default accountSaga;