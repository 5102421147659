import React from "react";

const DirectDebitSetupSuccess = (props) => {
	return (
		<div className="text-center mt-5">
			<div className="direct-debit-success-block">
				<i className="ion ion-md-checkmark-circle direct-debit-success-icon"></i>
				<h1 className="direct-debit-success-message">Direct Debit set up successfully</h1>
			</div>
			<div className="mt-3">
				<a href="/" className="btn btn-primary"><i class="ti-home mr-2"></i>Home</a>
			</div>
		</div>
	);
}

export default DirectDebitSetupSuccess;
