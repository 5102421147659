import {
  SET_CONTRACT_LOADING,
  ON_CONTRACT_INPUT_CHANGE,
  GET_CONTRACTS,
  GET_CONTRACTS_SUCCESS,
  GET_CONTRACT,
  GET_CONTRACT_SUCCESS,
  DOWNLOAD_CONTRACT,
  DOWNLOAD_CONTRACT_SUCCESS,
  SET_CONTRACT_ACTIVE_STEP,
  ADD_SERVICE_TO_CONTRACT,
  REMOVE_SERVICE_FROM_CONTRACT,
  PREVIEW_CONTRACT,
  PREVIEW_CONTRACT_SUCCESS,
  REVIEW_CONTRACT,
  REVIEW_CONTRACT_SUCCESS,
  SAVE_CONTRACT_AS_DRAFT,
  SAVE_CONTRACT_AS_DRAFT_SUCCESS,
  SEND_CONTRACT,
  SEND_CONTRACT_SUCCESS,
  RESET_CREATE_NEW_CONTRACT_FORM,
  SET_SHOW_CREATE_NEW_CONTRACT_SUCCESS_MODAL,
  GET_SIGNATURE_BLOCKS,
  GET_SIGNATURE_BLOCKS_SUCCESS,
  ADD_SIGNATORY,
  DELETE_SIGNATORY,
  SET_SIGNATORIES,
  GET_TNC_TEMPLATES,
  GET_TNC_TEMPLATES_SUCCESS,
  SEARCH_CONTRACT,
  SEARCH_CONTRACT_SUCCESS,
  GET_CONTRACT_STATUS,
  GET_CONTRACT_PDF_URL,
  SEND_CONTRACT_CLIENT,
  SEND_CONTRACT_CLIENT_SUCCESS,
  SAVE_CONTRACT_AS_DRAFT_CLIENT,
  SAVE_CONTRACT_AS_DRAFT_CLIENT_SUCCESS,
  REVIEW_CONTRACT_CLIENT,
  REVIEW_CONTRACT_CLIENT_SUCCESS,
  RESET_CONTRACT_SEARCH_INPUT,
} from "./actionTypes";

const initialState = {
  loading: false,
  contractSource: "fromScratch",
  paymentFrequency: "yearly",
  contracts: [],
  searchedContracts: [],
  tncTemplates: [],
  contractServices: [],
  contractPricingDrivers: [],
  pagination: {},
  reviewedPrice: {},
  recurringTotal: 0,
  oneOffTotal: 0,
  signatories: [],
  activeStep: 1,
  tnc: "",
  tncTemplate: null,
  showFullBreakdown: true,
  tempPricingDrivers: {},
  signEasyUrl: "",
  readyToSendContract: false,
  currentPaymentFrequency: "monthly",
  recurringDiscountPercentage: 0,
  oneOffDiscountPercentage: 0,
  OriginalContractQuotePackage: null,
  prevContractQuotePackage: false,
  contractQuotePackage: null
};

const contract = (state = initialState, action) => {
  let newState;
  switch (action.type) {
    case SET_CONTRACT_LOADING:
      return { ...state, loading: action.payload };
    case ON_CONTRACT_INPUT_CHANGE:
      console.log("action.payload - ", action.payload);
      let tPricingDrivers = state.tempPricingDrivers;
      if (action.payload.isPricingDrivers) {
        tPricingDrivers = {
          ...tPricingDrivers,
          [action.payload.field]: action.payload.value,
        };
      }
      if (action.payload.field instanceof Array) {
        let obj = action.payload.field.reduce((acc, cur) => {
          acc[cur] = action.payload.value;
          return acc;
        }, {});
        console.log("obj - ", obj);
        return { ...state, ...obj, tempPricingDrivers: tPricingDrivers };
      }
      return {
        ...state,
        [action.payload.field]: action.payload.value,
        tempPricingDrivers: tPricingDrivers,
      };
    case ADD_SERVICE_TO_CONTRACT:
      newState = {
        ...state,
        contractServices: [...state.contractServices, action.payload.service],
        contractPricingDrivers: [...state.contractPricingDrivers].concat(
          action.payload.pricingDriverKeys
        ),
      };
      action.payload.pricingDriverKeys.forEach((item) => {
        newState[item] = null;
      });
      return newState;
    case REMOVE_SERVICE_FROM_CONTRACT:
      return {
        ...state,
        contractServices: state.contractServices.filter(
          (item) => item !== action.payload.service
        ),
        contractPricingDrivers: state.contractPricingDrivers.filter(
          (item) => !action.payload.pricingDriverKeys.includes(item)
        ),
      };
    case GET_CONTRACTS:
      return { ...state, loading: true };
    case GET_CONTRACTS_SUCCESS:
      return {
        ...state,
        loading: false,
        contracts: action.payload.contracts,
        pagination: {
          count: action.payload.count,
          page_num: action.payload.page_num,
          total_pages: action.payload.total_pages,
        },
      };
    case GET_CONTRACT:
      return { ...state, loading: true };
    case GET_CONTRACT_STATUS:
      newState = {
        ...state,
        loading: false,
        contractStatus: action.payload.data.result.status,
      };
      return newState;
    case GET_CONTRACT_PDF_URL:
      newState = {
        ...state,
        loading: false,
        contractPdf: action.payload.data.result.pdf_link,
      };
      return newState;
    case GET_CONTRACT_SUCCESS:
      console.log("action.payload - ", action.payload);
      newState = {
        ...state,
        loading: false,
        contractReference: `${action.payload.prefix}-${action.payload.code}`,
        contractClient: action.payload.client,
        contractData: action.payload.data,
        tnc: action.payload.data.tnc,
        tncType: action.payload.data.tncType,
        contractTemplate: action.payload.template,
        paymentFrequency: action.payload.data.paymentFrequency,
        currentPaymentFrequency: action.payload.data.paymentFrequency,
        contractPaymentMethod: action.payload.payment_method,
        tncTemplate: action.payload.tncTemplate,
        showFullBreakdown: action.payload.show_full_breakdown,
        packageRecurringPrice: action.payload.data.packageRecurringPrice,
        packageOneOffPrice: action.payload.data.packageOneOffPrice,
        recurringDiscountPercentage: action.payload.data?.recurringDiscountPercentage ?? 0,
        oneOffDiscountPercentage: action.payload.data?.oneOffDiscountPercentage ?? 0,
        oneOffTotal: action.payload.data?.oneOffTotal,
        recurringTotal: action.payload.data?.recurringTotal,
      };
      newState.contractId = action.payload._id;
      newState.contractSource = action.payload.data.source;
      if (action.payload.data.source === "fromQuote") {
        newState.contractQuote = action.payload.data.quoteId;
        newState.selectedQuoteType = action.payload.data.quoteType;
        if (
          action.payload.data.quoteType === "packagedPricing" ||
          action.payload.data.quoteType === "customPackagedPricing"
        ) {
          newState.contractQuotePackage = action.payload.data.quotePackageId;
          newState.OriginalContractQuotePackage = action.payload.data.quotePackageId;
        }
      }
      newState.contractServices = [];
      newState.contractPricingDrivers = [];
      newState.signatories = [];
      if (action.payload.data.tempPricingDrivers) {
        newState.tempPricingDrivers = action.payload.data.tempPricingDrivers;
        for (let pricingDriverKey in action.payload.data.tempPricingDrivers) {
          newState[pricingDriverKey] =
            action.payload.data.tempPricingDrivers[pricingDriverKey];
        }
      }
      for (let categoryKey in action.payload.data.selectedServices) {
        for (let serviceKey in action.payload.data.selectedServices[categoryKey]
          .services) {
          newState.contractServices.push(`${categoryKey}-${serviceKey}`);
          for (let pricingDriverKey in action.payload.data.selectedServices[
            categoryKey
          ].services[serviceKey].pricingDrivers) {
            newState.contractPricingDrivers.push(
              `pricingDriver-${categoryKey}-${serviceKey}-${pricingDriverKey}`
            );
            newState[
              `pricingDriver-${categoryKey}-${serviceKey}-${pricingDriverKey}`
            ] =
              action.payload.data.selectedServices[categoryKey].services[
                serviceKey
              ].pricingDrivers[pricingDriverKey].value;
          }
        }
      }
      action.payload.signatories.forEach((item, index) => {
        newState.signatories.push(index);
        newState[`signatory${index}FirstName`] = item.firstName;
        newState[`signatory${index}LastName`] = item.lastName;
        newState[`signatory${index}Email`] = item.email;
        newState[`signatory${index}SignaturePosition`] = item.signaturePosition;
      });
      if (action.payload.data.globalPricingDrivers) {
        for (let driverKey in action.payload.data.globalPricingDrivers) {
          newState[`globalPricingDriver-${driverKey}`] =
            action.payload.data.globalPricingDrivers[driverKey].value;
          if (action.payload.data.globalPricingDrivers[driverKey].other) {
            newState[
              `globalPricingDriver-${driverKey}-${action.payload.data.globalPricingDrivers[driverKey].value}_other`
            ] = action.payload.data.globalPricingDrivers[driverKey].other;
          }
        }
      }
      return newState;
    case DOWNLOAD_CONTRACT:
      return { ...state, loading: true };
    case DOWNLOAD_CONTRACT_SUCCESS:
      return { ...state, loading: false };
    case SET_CONTRACT_ACTIVE_STEP:
      return { ...state, activeStep: action.payload };
    case PREVIEW_CONTRACT:
      return { ...state, loading: true, previewContractPDFUrl: null };
    case PREVIEW_CONTRACT_SUCCESS:
      return {
        ...state,
        loading: false,
        previewContractPDFUrl: action.payload.contractUrl,
      };
    case REVIEW_CONTRACT:
      return { ...state, loading: true };
    case REVIEW_CONTRACT_SUCCESS:
      console.log("REVIEW_CONTRACT_SUCCESS - ", action);
      let totalRecurringPrice = action.payload?.packageRecurringPrice ?? 0;
      let totalOneOffPrice = action.payload?.packageOneOffPrice ?? action.payload.final_oneOff_total;
      console.log("REVIEW_CONTRACT_SUCCESS - ", totalRecurringPrice, totalOneOffPrice, action);
      if (state.selectedQuoteType == 'customPackagedPricing' || state.selectedQuoteType == 'packagedPricing') {
        totalRecurringPrice = action.payload.final_recurring_total ?? action.payload.packageRecurringPrice;
        totalOneOffPrice = action.payload.final_one_off_total ?? action.payload.packageOneOffPrice;
        console.log("REVIEW_CONTRACT_SUCCESS - ", totalRecurringPrice, totalOneOffPrice, action);
        if (state.prevContractQuotePackage) {
          if (totalRecurringPrice != action.payload.recurringTotal) {
            state.recurringDiscountPercentage = parseFloat(((action.payload.recurringTotal - totalRecurringPrice) * 100) / action.payload.recurringTotal).toFixed(2);
            state.recurringDiscountPercentage = state.recurringDiscountPercentage >= 0 ? state.recurringDiscountPercentage : 0;
          }
          console.log("REVIEW_CONTRACT_SUCCESS - ", totalRecurringPrice, totalOneOffPrice, action);
          if (totalOneOffPrice != action.payload.oneOffTotal) {
            state.oneOffDiscountPercentage = parseFloat(((action.payload.oneOffTotal - totalOneOffPrice) * 100) / action.payload.oneOffTotal).toFixed(2);
            state.oneOffDiscountPercentage = state.oneOffDiscountPercentage >= 0 ? state.oneOffDiscountPercentage : 0;
          }
        }
        console.log("REVIEW_CONTRACT_SUCCESS - ", totalRecurringPrice, totalOneOffPrice, action);
      }
      if (!totalRecurringPrice) {
        totalRecurringPrice = 0;
      }
      console.log("REVIEW_CONTRACT_SUCCESS - ", totalRecurringPrice, totalOneOffPrice, action);
      if (state.packageOneOffPrice <= state?.oneOffTotal && state.oneOffDiscountPercentage > 0) {
        totalOneOffPrice = state.oneOffDiscountPercentage ? action.payload?.oneOffTotal - ((action.payload?.oneOffTotal * state.oneOffDiscountPercentage) / 100) : action.payload?.oneOffTotal;
        totalOneOffPrice = parseFloat(totalOneOffPrice).toFixed(2);
      }
      console.log("REVIEW_CONTRACT_SUCCESS - ", totalRecurringPrice, totalOneOffPrice, action);
      if (state.packageRecurringPrice <= state.recurringTotal && state.recurringDiscountPercentage > 0) {
        totalRecurringPrice = state.recurringDiscountPercentage ? action.payload.recurringTotal - ((action.payload.recurringTotal * state.recurringDiscountPercentage) / 100) : action.payload.recurringTotal;
        totalRecurringPrice = parseFloat(totalRecurringPrice).toFixed(2);
      }
      console.log("REVIEW_CONTRACT_SUCCESS - ", totalRecurringPrice, totalOneOffPrice, action);
      if (totalRecurringPrice == 0 && state.recurringDiscountPercentage != 100) {
        totalRecurringPrice = action.payload.recurringTotal;
      }
      if (totalOneOffPrice == 0 && state.oneOffDiscountPercentage != 100) {
        totalOneOffPrice = action.payload.oneOffTotal;
      }
      console.log("REVIEW_CONTRACT_SUCCESS - ", totalRecurringPrice, totalOneOffPrice, action);
      // if (state.contractStatus == 'draft') {
      //   totalOneOffPrice = state.packageOneOffPrice;
      //   totalRecurringPrice = state.packageRecurringPrice;
      // }
      console.log("REVIEW_CONTRACT_SUCCESS - ", totalRecurringPrice, totalOneOffPrice, action);
      if (state.contractStatus == 'draft' && state.packageRecurringPrice > state.recurringTotal) {
        totalRecurringPrice = state.packageRecurringPrice;
      }
      console.log("REVIEW_CONTRACT_SUCCESS - ", totalRecurringPrice, totalOneOffPrice, action);
      if (state.contractStatus == 'draft' && state.packageOneOffPrice > state.oneOffTotal) {
        totalOneOffPrice = state.packageOneOffPrice;
      }
      console.log("REVIEW_CONTRACT_SUCCESS - ", totalRecurringPrice, totalOneOffPrice, action);
      if (state.recurringDiscountPercentage > 0) {
        totalRecurringPrice = action.payload.recurringTotal - ((action.payload.recurringTotal * state.recurringDiscountPercentage) / 100);
      }
      console.log("REVIEW_CONTRACT_SUCCESS - ", totalRecurringPrice, totalOneOffPrice, action);
      if (state.oneOffDiscountPercentage > 0) {
        totalOneOffPrice = action.payload.oneOffTotal - ((action.payload.oneOffTotal * state.oneOffDiscountPercentage) / 100);
      }
      console.log("REVIEW_CONTRACT_SUCCESS - ", totalRecurringPrice, totalOneOffPrice, action);
      newState = {
        ...state,
        loading: false,
        reviewedPrice: action.payload.calculatedPackagePrice,
        // packageRecurringPrice: action.payload.final_recurring_total,
        //oneOff
        // packageOneOffPrice: action.payload.final_one_off_total,
        packageOneOffPrice: totalOneOffPrice,
        oneOffTotal: action.payload?.oneOffTotal ?? 0,
        final_oneOff_total: totalOneOffPrice,
        final_recurring_total: totalRecurringPrice,
        //recurring
        packageRecurringPrice: totalRecurringPrice,
        recurringTotal: action.payload.recurringTotal,
        OriginalPackageRecurringPrice: totalRecurringPrice,
        OriginalRecurringTotal: action.payload.recurringTotal,
        globalPricingDrivers: action.payload.globalPricingDrivers,
        oneOffDiscountPercentage: state.oneOffDiscountPercentage,
        recurringDiscountPercentage: state.recurringDiscountPercentage,
        prevContractQuotePackage: false,
        OriginalContractQuotePackage: state?.contractQuotePackage,
      };
      if (action.payload.payment_frequency) {
        newState.paymentFrequency = action.payload.payment_frequency;
        newState.currentPaymentFrequency = action.payload.payment_frequency;
        newState.reviewedPrice.paymentFrequency = action.payload.payment_frequency;
      } else {
        newState.paymentFrequency =
          action.payload.calculatedPackagePrice.paymentFrequency;
        newState.currentPaymentFrequency = action.payload.calculatedPackagePrice.paymentFrequency;
        newState.reviewedPrice.paymentFrequency = action.payload.calculatedPackagePrice.paymentFrequency;
      }
      return newState;
    case SEND_CONTRACT:
      return { ...state, loading: true };
    case SEND_CONTRACT_SUCCESS:
      return {
        ...state,
        loading: false,
        showCreateNewContractSuccessModal: true,
        createNewContractSuccessModalMessage:
          "Engagement Letter has been sent successfully!",
      };
    case SAVE_CONTRACT_AS_DRAFT:
      return { ...state, loading: true };
    case SAVE_CONTRACT_AS_DRAFT_SUCCESS:
      return {
        ...state,
        loading: false,
        showCreateNewContractSuccessModal: true,
        createNewContractSuccessModalMessage:
          "Engagement Letter has been saved as draft!",
      };
    case RESET_CREATE_NEW_CONTRACT_FORM:
      return initialState;
    case SET_SHOW_CREATE_NEW_CONTRACT_SUCCESS_MODAL:
      return { ...state, showCreateNewContractSuccessModal: action.payload };
    case GET_SIGNATURE_BLOCKS:
      return { ...state, loading: true };
    case GET_SIGNATURE_BLOCKS_SUCCESS:
      return { ...state, loading: false, signatureBlocks: action.payload };
    case ADD_SIGNATORY:
      return { ...state, signatories: [...state.signatories, action.payload] };
    case SET_SIGNATORIES:
      console.log('action.payload SET_SIGNATORIES - ', action.payload);
      newState = { ...state, signatories: [] };
      for (let signatory of action.payload) {
        newState.signatories.push(signatory.id);
        newState[`signatory${signatory.id}FirstName`] = signatory.firstName;
        newState[`signatory${signatory.id}LastName`] = signatory.lastName;
        newState[`signatory${signatory.id}Email`] = signatory.email;
        newState[`signatory${signatory.id}SignaturePosition`] =
          signatory.signaturePosition;
      }
      return newState;
    case GET_TNC_TEMPLATES:
      return { ...state, loading: true };
    case GET_TNC_TEMPLATES_SUCCESS:
      return { ...state, loading: false, tncTemplates: action.payload };
    case DELETE_SIGNATORY:
      return {
        ...state,
        signatories: state.signatories.filter(
          (item, index) => item !== action.payload
        ),
      };
    case SEARCH_CONTRACT_SUCCESS:
      return { ...state, searchedContracts: action.payload };
    case SEND_CONTRACT_CLIENT:
      return { ...state, loading: true };
    case SEND_CONTRACT_CLIENT_SUCCESS:
      return { ...state, loading: false, signEasyUrl: action.payload };
    case SAVE_CONTRACT_AS_DRAFT_CLIENT:
      return { ...state, loading: true };
    case SAVE_CONTRACT_AS_DRAFT_CLIENT_SUCCESS:
      return initialState;
    case REVIEW_CONTRACT_CLIENT:
      return { ...state, loading: true };
    case REVIEW_CONTRACT_CLIENT_SUCCESS:
      console.log("REVIEW_CONTRACT_SUCCESS - ", state);
      let totalRecurringPriceClient = action.payload.packageRecurringPrice;
      let totalOneOffPriceClient = action.payload.packageOneOffPrice;
      if (state.selectedQuoteType == 'customPackagedPricing' || state.selectedQuoteType == 'packagedPricing') {
        totalRecurringPriceClient = action.payload.final_recurring_total ?? action.payload.packageRecurringPrice;
        totalOneOffPriceClient = action.payload.final_one_off_total ?? action.payload.packageOneOffPrice;
      }
      newState = {
        ...state,
        loading: false,
        reviewedPrice: action.payload.calculatedPackagePrice,
        // packageRecurringPrice: action.payload.final_recurring_total,
        //oneOff
        // packageOneOffPrice: action.payload.final_one_off_total,
        // packageOneOffPrice: action.payload.oneOffTotal,
        oneOffTotal: totalOneOffPriceClient,
        final_oneOff_total: action.payload.final_oneOff_total,
        final_recurring_total: action.payload.final_recurring_total,
        //recurring
        packageRecurringPrice: parseFloat(totalRecurringPriceClient).toFixed(2),
        recurringTotal: action.payload.recurringTotal,
        OriginalPackageRecurringPrice: parseFloat(totalRecurringPriceClient).toFixed(2),
        OriginalRecurringTotal: action.payload.recurringTotal,
        globalPricingDrivers: action.payload.globalPricingDrivers,
        readyToSendContract: true,
      };
      if (action.payload.payment_frequency) {
        newState.paymentFrequency = action.payload.payment_frequency;
        newState.currentPaymentFrequency = action.payload.payment_frequency;
      } else {
        newState.paymentFrequency =
          action.payload.calculatedPackagePrice.paymentFrequency;
        newState.currentPaymentFrequency = action.payload.calculatedPackagePrice.paymentFrequency
      }
      return newState;
    case RESET_CONTRACT_SEARCH_INPUT:
      return {
        ...state,
        contractSearchInput: ''
      }
    default:
      return state;
  }
};

export default contract;
