import React, { useState, useEffect } from "react";
import Select from "react-select";
import { driverOptions, slabOptions } from "../config";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import { arrayMoveImmutable } from "array-move";
import { formatValue } from "../Utils";

function generateCode() {
  return new Date().getTime();
}

function renderVariations(item, index, props) {
  return (
    <div
      key={index}
      style={{
        zIndex: !props?.parentProps?.driverCodes?.length ? 9999 : "auto",
      }}
      className="fieldset-group-child"
    >
      <label className="fieldset-group-label">Variation {index + 1}</label>
      <button
        disabled={props.editValuesOnly}
        onClick={() => {
          props.deleteVariationFromPricingDriver(props.code, item.code);
        }}
        className="btn btn-sm btn-danger delete-fieldset-group"
      >
        <i className="ion ion-md-trash mr-1"></i>Delete Variation
      </button>
      <div className="row">
        <div className="col-6">
          <div className="fieldset2">
            <label className="fieldset-label required">Variation Name</label>
            <input
              disabled={props.editValuesOnly}
              value={item.name || ""}
              onChange={(e) => {
                props.onChange(
                  `driver${props.code}Variation${item.code}Name`,
                  e.target.value
                );
              }}
              className="input-text"
              type="text"
              placeholder="Variation Name"
            />
            {props.validator.message(
              `driver${props.code}Variation${item.code}Name`,
              props.parentProps[`driver${props.code}Variation${item.code}Name`],
              "_required",
              { className: "text-danger" }
            )}
          </div>
        </div>
        <div className="col-6">
          <div className="fieldset2">
            <label className="fieldset-label required">Variation Value</label>
            <input
              value={formatValue(item.price)}
              onChange={(e) => {
                const val = +e.target.value.toString().split(",").join("");
                props.onChange(
                  `driver${props.code}Variation${item.code}Price`,
                  val
                );
              }}
              className="input-text"
              type="text"
              placeholder="Variation Value"
            />
            {props.validator.message(
              `driver${props.code}Variation${item.code}Price`,
              props.parentProps[
              `driver${props.code}Variation${item.code}Price`
              ],
              "_required|_numeric",
              { className: "text-danger" }
            )}
          </div>
        </div>
      </div>
      {props.code == "" ? (
        <input
          disabled={props.editValuesOnly}
          className="mr-2"
          type="checkbox"
          id={item.code}
          checked={item.is_default}
          onChange={(e) => {
            props.setDefaultGlobalDriver(
              item.code,
              "Variation",
              e.target.checked
            );
          }}
        />
      ) : (
        <input
          disabled={props.editValuesOnly}
          className="mr-2"
          type="checkbox"
          id={item.code}
          checked={item.is_default}
          onChange={(e) => {
            props.setDefaultDriver(
              item.code,
              props.code,
              "Variation",
              e.target.checked
            );
          }}
        />
      )}

      <label htmlFor={item.code} className="toggle">
        Set to Default
      </label>
    </div>
  );
}

function renderSlabs(item, index, props) {
  return (
    <div key={index} className="fieldset-group">
      <label className="fieldset--label">Slab {index + 1}</label>
      <button
        disabled={this.editValuesOnly}
        onClick={() => this.deleteSlabFromPricingDriver(this.code, item.code)}
        className="btn btn-sm btn-danger delete-fieldset-group"
      >
        <i className="ion ion-md-trash mr-1"></i>Delete Slab
      </button>
      <div className="row">
        <div className="col-6">
          <div className="fieldset2">
            <label className="fieldset-label required">Slab Type</label>
            <Select
              options={slabOptions}
              isDisabled={this.editValuesOnly}
              onChange={(selectedOption) =>
                this.onChange(
                  `driver${this.code}Slab${item.code}Type`,
                  selectedOption.value
                )
              }
              value={
                item.type
                  ? slabOptions.filter((option) => option.value === item.type)
                  : ""
              }
            />
            {this.validator.message(
              `driver${this.code}Slab${item.code}Type`,
              this.parentProps[`driver${this.code}Slab${item.code}Type`],
              "_required",
              { className: "text-danger" }
            )}
          </div>
        </div>
        <div className="col-6">
          <div className="fieldset2">
            <label className="fieldset-label required">
              {["IncrementalBlock", "IncrementalUnit"].includes(item.type)
                ? "Increment Value By"
                : "Value"}
            </label>
            <input
              value={formatValue(item.price)}
              onChange={(e) => {
                const val = +e.target.value.toString().split(",").join("");
                this.onChange(`driver${this.code}Slab${item.code}Price`, val);
              }}
              type="text"
              className="input-text"
              placeholder="Value"
            />
            {this.validator.message(
              `driver${this.code}Slab${item.code}Price`,
              this.parentProps[`driver${this.code}Slab${item.code}Price`],
              "_required|_numeric",
              { className: "text-danger" }
            )}
          </div>
        </div>
        {item.type && (
          <div className="col-6">
            <div className="fieldset2">
              <label className="fieldset-label required">From</label>
              <input
                disabled={this.editValuesOnly}
                readOnly={index > 0}
                value={formatValue(item.from)}
                onChange={(e) => {
                  const val = +e.target.value.toString().split(",").join("");
                  this.onChange(`driver${this.code}Slab${item.code}From`, val);
                }}
                type="text"
                className="input-text"
                placeholder="From"
              />
              {this.validator.message(
                `driver${this.code}Slab${item.code}From`,
                this.parentProps[`driver${this.code}Slab${item.code}From`],
                "_required|_numeric",
                { className: "text-danger" }
              )}
            </div>
          </div>
        )}
        {["UnitBased", "BlockBased"].includes(item.type) && (
          <div className="col-6">
            <div className="fieldset2">
              <label className="fieldset-label required">To</label>
              <input
                disabled={this.editValuesOnly}
                value={formatValue(item.to)}
                onChange={(e) => {
                  const val = +e.target.value.toString().split(",").join("");
                  this.onChange(`driver${this.code}Slab${item.code}To`, val);
                  if (index + 1 !== this.slabs.length) {
                    this.onChange(
                      `driver${this.code}Slab${this.slabs[index + 1].code}From`,
                      Number(val) + Number(1)
                    );
                  }
                  if (item.from > val) {
                    this.validator.messagesShown = true;
                  } else if (item.from === val) {
                    this.validator.messagesShown = true;
                  } else {
                    this.setStateValidators()
                    this.validator.messagesShown = false;
                  }
                }}
                type="text"
                className="input-text"
                placeholder="To"
              />
              {this.validator.message(
                `driver${this.code}Slab${item.code}To`,
                this.parentProps[`driver${this.code}Slab${item.code}To`],
                "_required|_numeric",
                { className: "text-danger" }
              )}
              {this.validator.message(
                `driver${this.code}Slab${item.code}To`,
                this.parentProps[`driver${this.code}Slab${item.code}To`],
                `_numeric|_greater:${this.parentProps[`driver${this.code}Slab${item.code}From`]
                },num`,
                { className: "text-danger" }
              )}
            </div>
          </div>
        )}
        {["IncrementalBlock", "IncrementalUnit"].includes(item.type) && (
          <div className="col-6">
            <div className="fieldset2">
              <label className="fieldset-label required">
                Increment Slab By
              </label>
              <input
                disabled={this.editValuesOnly}
                value={formatValue(item.incrementer)}
                onChange={(e) => {
                  const val = +e.target.value.toString().split(",").join("");
                  const regex = /^[0-9\b]+$/;
                  if (val === "" || regex.test(val)) {
                    this.onChange(
                      `driver${this.code}Slab${item.code}Incrementer`,
                      val
                    );
                  }
                  // if (item.from > val) {
                  //   this.validator.messagesShown = true;
                  // } else if (item.from === val) {
                  //   this.validator.messagesShown = true;
                  // } else {
                  //   this.setStateValidators()
                  //   this.validator.messagesShown = false;
                  // }
                }}
                type="text"
                className="input-text"
                placeholder="Increment Slab By"
              />
              {this.validator.message(
                `driver${this.code}Slab${item.code}Incrementer`,
                this.parentProps[
                `driver${this.code}Slab${item.code}Incrementer`
                ],
                "_required|_numeric",
                { className: "text-danger" }
              )}
              {/* {this.validator.message(
                `driver${this.code}Slab${item.code}Incrementer`,
                this.parentProps[`driver${this.code}Slab${item.code}Incrementer`],
                `_numeric|_greater:${this.parentProps[`driver${this.code}Slab${item.code}From`]
                },num`,
                { className: "text-danger" }
              )} */}
            </div>
          </div>
        )}
      </div>
      {this.code == "" ? (
        <input
          disabled={this.editValuesOnly}
          className="mr-2"
          type="checkbox"
          id={item.code}
          checked={item.is_default}
          onChange={(e) =>
            this.setDefaultGlobalDriver(item.code, "Slab", e.target.checked)
          }
        />
      ) : (
        <input
          disabled={this.editValuesOnly}
          className="mr-2"
          type="checkbox"
          id={item.code}
          checked={item.is_default}
          onChange={(e) =>
            this.setDefaultDriver(
              item.code,
              this.code,
              "Slab",
              e.target.checked
            )
          }
        />
      )}
      <label htmlFor={item.code} className="toggle">
        Set to Default
      </label>
    </div>
  );
}

function renderNumberRanges(item, index) {
  return (
    <div key={index} className="row">
      <div className="col-6">
        <div className="fieldset2">
          <div className="range-input">
            <span>From</span>
            <input
              disabled={this.editValuesOnly}
              value={formatValue(item.from)}
              onChange={(e) => {
                const val = +e.target.value.toString().split(",").join("");
                this.onChange(
                  `driver${this.code}NumberRange${item.code}From`,
                  val
                );
              }}
              type="text"
              className="input-text"
            />
            <span>To</span>
            <input
              disabled={this.editValuesOnly}
              value={formatValue(item.to)}
              onChange={(e) =>
                this.onChange(
                  `driver${this.code}NumberRange${item.code}To`,
                  e.target.value
                )
              }
              type="text"
              className="input-text"
            />
          </div>
        </div>
      </div>
      <div className="col-6">
        <div className="fieldset2">
          <div className="range-input">
            <input
              value={formatValue(item.price)}
              onChange={(e) => {
                const val = +e.target.value.toString().split(",").join("");
                this.onChange(
                  `driver${this.code}NumberRange${item.code}Price`,
                  val
                );
              }}
              className="input-text"
              type="text"
              placeholder="Price per Unit"
            />
            <button
              onClick={() =>
                this.deleteNumberRangeFromPricingDriver(this.code, item.code)
              }
              className="btn btn-danger"
            >
              <i className="ion ion-md-trash"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

const SortableItem = SortableElement((props) =>
  renderVariations(props.value, props.i, props.prop)
);
const SortableList = SortableContainer((props) => {
  return (
    <div>
      {props.items.map((value, index) => (
        <SortableItem
          key={index}
          index={index}
          value={value}
          i={index}
          prop={props.prop}
        />
      ))}
    </div>
  );
});

const PricingDriver = (props) => {
  let driverSelectorsArray = [];
  let driverVariationsArray = [];

  const onSortEnd = ({ oldIndex, newIndex }) => {
    let shuffledIdx = arrayMoveImmutable(
      props.variations,
      oldIndex,
      newIndex
    ).map((item) => item.code);
    props.setDriverVariations(shuffledIdx, props.code);
  };
  const driverIndex = props?.driversArray?.indexOf(
    [props?.driversArray?.find((val) => val.id === props?.code)][0]
  );
  const dependencyDriverIndex = props?.driversArray?.indexOf(
    [
      props?.driversArray?.find(
        (val) =>
          val.name ===
          props?.parentProps[`driver${props?.code}dependancyDriver`]
      ),
    ][0]
  );

  useEffect(() => {
    if (
      (props?.parentProps[`driver${props?.code}dependancyDriver`] &&
        dependencyDriverIndex > driverIndex) ||
      !props.parentProps[`driver${props?.code}isDependent`]
    ) {
      if (props?.setDependencyId) {
        const setDependencyId = props.setDependencyId(props.code, "");
      }
      if (props?.setDependencyVariation) {
        const setDependencyVariation = props.setDependencyVariation(
          props.code,
          ""
        );
      }
    }
    if (dependencyDriverIndex > driverIndex || driverIndex === 0) {
      props.setDependency(props.code, false);
      props.setDependencyId(props.code, "");
      props.setDependencyVariation(props.code, "");
    }
  }, [
    driverIndex,
    dependencyDriverIndex,
    props.parentProps[`driver${props?.code}isDependent`],
  ]);

  const driversList = (driverIndex, append) => {
    let driversArr = [];
    props.driversArray.map((item, idx) => {
      if (idx < driverIndex && item.type === "Variation") {
        if (append) {
          driverSelectorsArray.push({
            id: item.id,
            value: item.name,
            label: item.name,
          });
        } else {
          driversArr.push({ id: item.id, value: item.name, label: item.name });
        }
      }
    });
    if (append) {
      return driverSelectorsArray;
    } else {
      return driversArr;
    }
  };

  const variationsList = () => {
    const selectedDependency = props.driversArray.find(
      (val) =>
        val.name === props.parentProps[`driver${props?.code}dependancyDriver`]
    );
    if (selectedDependency?.variations?.length > 0) {
      const variations = selectedDependency?.variations?.map((item) => {
        driverVariationsArray.push({
          id: item.code,
          value: item.name,
          label: item.name,
        });
      });
      return driverVariationsArray;
    }
    return;
  };

  return (
    <React.Fragment>
      <div className="row">
        <div
          style={{
            zIndex: !props?.parentProps?.driverCodes?.length ? 99999 : "auto",
          }}
          className="col-6"
        >
          <div className="fieldset2">
            <label className="fieldset-label required">Driver Name</label>
            <input
              disabled={props.editValuesOnly}
              value={props.name || ""}
              onChange={(e) =>
                props.onChange(`driver${props.code}Name`, e.target.value)
              }
              className="input-text"
              type="text"
              placeholder="Driver Name"
            />
            {/*props.nameValidator*/}
            {props.validator.message(
              `driver${props.code}Name`,
              props.parentProps[`driver${props.code}Name`],
              "_required",
              { className: "text-danger" }
            )}
          </div>
        </div>
        <div
          style={{
            zIndex: !props?.parentProps?.driverCodes?.length ? 99999 : "auto",
          }}
          className="col-6"
        >
          <div className="fieldset2">
            <label className="fieldset-label required">Driver Type</label>
            <Select
              options={driverOptions}
              onChange={(selectedOption) => {
                props.onChange(`driver${props.code}Type`, selectedOption.value);
              }}
              value={
                props.type
                  ? driverOptions.filter(
                    (option) => option.value === props.type
                  )
                  : ""
              }
              isDisabled={props.editValuesOnly}
            />
            {/*props.typeValidator*/}
            {props.validator.message(
              `driver${props.code}Type`,
              props.parentProps[`driver${props.code}Type`],
              "_required",
              { className: "text-danger" }
            )}
          </div>
        </div>
      </div>

      {props.index > 0 &&
        driverIndex > 0 &&
        driversList(driverIndex, false).length > 0 && (
          <div className="row">
            <div className="col">
              <input
                className="mr-2 mt-2"
                type="checkbox"
                id={props.code}
                checked={props.parentProps[`driver${props.code}isDependent`]}
                onChange={(e) =>
                  props.setDependency(props.code, e.target.checked)
                }
              />
              <label
                htmlFor={props.code}
                style={{ color: "#274b8a" }}
                className="toggle"
              >
                Depends on Previous Driver
              </label>
            </div>
          </div>
        )}

      {props.parentProps[`driver${props.code}isDependent`] &&
        driverIndex > 0 && (
          <div className="row mb-3 pb-3">
            <div className="col-6">
              <label className="fieldset-label required">Select Driver</label>
              <Select
                options={driversList(driverIndex, true)}
                onChange={(selectedOption) => {
                  props.setDependencyId(props.code, selectedOption.value);
                }}
                value={driverSelectorsArray.filter(
                  (option) =>
                    option.value ===
                    props.parentProps[`driver${props.code}dependancyDriver`]
                )}
              />
              {props.validator.message(
                `driver${props.code}dependancyDriver`,
                props.parentProps[`driver${props.code}dependancyDriver`],
                "_required",
                { className: "text-danger" }
              )}
            </div>

            {props?.parentProps[`driver${props?.code}dependancyDriver`] && (
              <div className="col-6">
                <label className="fieldset-label required">
                  Select Variation
                </label>
                <Select
                  options={variationsList()}
                  onChange={(selectedOption) => {
                    props.setDependencyVariation(
                      props.code,
                      selectedOption.value
                    );
                  }}
                  value={driverVariationsArray.filter(
                    (option) =>
                      option.value ===
                      props.parentProps[
                      `driver${props.code}dependencyVariation`
                      ]
                  )}
                />
                {props.validator.message(
                  `driver${props.code}dependencyVariation`,
                  props.parentProps[`driver${props.code}dependencyVariation`],
                  "_required",
                  { className: "text-danger" }
                )}
              </div>
            )}
          </div>
        )}

      {props.type === "Variation" && (
        <React.Fragment>
          {props.validator.message(
            `driver${props.code}Variations`,
            props.variations,
            "_requiredArray:Variation",
            { className: "text-center minimum-array-1" }
          )}
          {/* {props.variations.map(renderVariations.bind(props))} */}

          {
            <SortableList
              items={props.variations}
              lockAxis="y"
              onSortEnd={onSortEnd}
              prop={props}
            />
          }

          <div className="row fieldset">
            <div className="col-12 text-right">
              <button
                disabled={props.editValuesOnly}
                onClick={() =>
                  props.addVariationToPricingDriver(props.code, generateCode())
                }
                className="btn btn-sm create-item"
              >
                <i className="ion ion-md-add-circle"></i>Add Variation
              </button>
            </div>
          </div>
        </React.Fragment>
      )}
      {/*  */}
      {props.type === "Slab" && (
        <React.Fragment>
          {props.validator.message(
            `driver${props.code}Slabs`,
            props.slabs,
            "_requiredArray:Slab",
            { className: "text-center minimum-array-1" }
          )}
          {props.slabs.map(renderSlabs.bind(props))}
          {!(
            props.slabs.length > 0 &&
            props.slabs.filter((x) =>
              ["IncrementalBlock", "IncrementalUnit"].includes(x.type)
            ).length > 0
          ) && (
              <div className="row fieldset">
                <div className="col-12 text-right">
                  <button
                    disabled={
                      props.editValuesOnly || props.slabs.filter((slabs) => slabs.from > slabs.to || !slabs.from || !slabs.to || slabs.from === slabs.to).length > 0 ? true : false}
                    onClick={() =>
                      props.addSlabToPricingDriver(props.code, generateCode())
                    }
                    className="btn btn-sm create-item"
                  >
                    <i className="ion ion-md-add-circle"></i>Add Slab
                  </button>
                </div>
              </div>
            )}
        </React.Fragment>
      )}
      {props.type === "Number Range" && (
        <React.Fragment>
          <div className="fieldset-group">
            <label className="fieldset-group-label">Number Ranges</label>
            {props.numberRanges.map(renderNumberRanges.bind(props))}
          </div>
          <div className="row fieldset">
            <div className="col-12 text-right">
              <button
                disabled={props.editValuesOnly}
                onClick={() =>
                  props.addNumberRangeToPricingDriver(
                    props.code,
                    generateCode()
                  )
                }
                className="btn btn-sm create-item"
              >
                <i className="ion ion-md-add-circle"></i>Add Number Range
              </button>
            </div>
          </div>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default PricingDriver;
