import {
    LOGIN_USER,
    LOGIN_USER_WITH_PROVIDER,
    LOGIN_SUCCESS,
    LOGOUT_USER,
    LOGOUT_USER_SUCCESS,
    LOGIN_ERROR,
    SWITCH_ORGANISATION,
    ON_LOGIN_INPUT_CHANGE,
    RESET_LOGIN_FORM,
} from './actionTypes';

export const onInputChange = (field, value) => {
    return {
        type: ON_LOGIN_INPUT_CHANGE,
        payload: {
            field,
            value
        }
    }
}

export const loginUser = (email, password, history) => {
    return {
        type: LOGIN_USER,
        payload: { email, password, history }
    }
}

export const loginUserWithProvider = (provider, history) => {
    return {
        type: LOGIN_USER_WITH_PROVIDER,
        payload: { provider, history }
    }
}

export const loginSuccess = (user) => {
    return {
        type: LOGIN_SUCCESS,
        payload: user
    }
}

export const logoutUser = (history) => {
    return {
        type: LOGOUT_USER,
        payload: { history }
    }
}

export const logoutUserSuccess = () => {
    return {
        type: LOGOUT_USER_SUCCESS,
        payload: {}
    }
}

export const loginError = (error) => {
    return {
        type: LOGIN_ERROR,
        payload: error
    }
}

export const switchOrganisation = (organisationId, history) => {
    return {
        type: SWITCH_ORGANISATION,
        payload: organisationId
    }
}

export const resetLoginForm = () => {
    return {
        type: RESET_LOGIN_FORM
    }
}
