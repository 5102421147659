import { takeEvery, fork, put, all, call } from 'redux-saga/effects';
import axios from 'axios';

import {
    CANCEL_SUBSCRIPTION,
    CHANGE_SUBSCRIPTION,
    GET_SUBSCRIPTION_PACKAGES,
    HANDLE_CARD,
} from "./actionTypes";

import {
    getSubscriptionPackagesSuccess,
    changeSubscriptionSuccess,
    handleCardSuccess,
    handleCardAction,
    cancelSubscriptionSuccess,
    handlePaymentIntent,
    handleSetupIntent
} from './actions';
import { setShowApiErrorModal } from '../common/actions';

// AUTH related methods
import { getFirebaseBackend } from '../../helpers/authUtils';

const fireBaseBackend = getFirebaseBackend();

function* getSubscriptionPackages() {
    try {
        console.log('ubscription-package')
        const accessToken = yield fireBaseBackend.getAccessToken();
        const response = yield axios({
            method: "GET",
            url: `subscription-packages`,
            headers: {authorization: `Bearer ${accessToken}`}
        });
        if (response.data.success) {
            yield put(getSubscriptionPackagesSuccess(response.data.result));
        } else {
            // yield put(createOrganisationFailed());
        }
    } catch (error) {
        yield put(setShowApiErrorModal());
        console.log(error);
        // if (error.response && error.response.data && error.response.data.error) {
        //     yield put(loginError(error.response.data.error));
        // } else {
        //     yield put(loginError("Something went Wrong!"));
        // }
        // yield put(createOrganisationFailed());
    }
}

function* submitChangeSubscription({payload: data}) {
    try {
        const accessToken = yield fireBaseBackend.getAccessToken();
        const response = yield axios({
            method: "POST",
            url: `change-subscription`,
            headers: {authorization: `Bearer ${accessToken}`},
            data
        });
        if (response.data.success) {
            console.log('response.data.result = ', response.data);
            if(typeof response.data.result === 'object' && 'clientSecret' in response.data.result && 'is_setup' in response.data.result) {
                return yield put(handleSetupIntent(response.data.result));
            }
            if(typeof response.data.result === 'object' && 'clientSecret' in response.data.result) {
                return yield put(handlePaymentIntent(response.data.result));
            }
            yield put(changeSubscriptionSuccess(response.data.result));
        } else {
            // yield put(createOrganisationFailed());
        }
    } catch (error) {
        yield put(setShowApiErrorModal());
        // if (error.response && error.response.data && error.response.data.error) {
        //     yield put(loginError(error.response.data.error));
        // } else {
        //     yield put(loginError("Something went Wrong!"));
        // }
        // yield put(createOrganisationFailed());
    }
}

function* submitCard({payload: data}) {
    try {
        const accessToken = yield fireBaseBackend.getAccessToken();
        const response = yield axios({
            method: "POST",
            url: `handle-subscription-card`,
            headers: {authorization: `Bearer ${accessToken}`},
            data
        });
        if (response.data.success) {
            if(typeof response.data.result === 'object' && 'setupIntent' in response.data.result) {
                return yield put(handleCardAction(response.data.result));
            }
            yield put(handleCardSuccess(response.data.result));
        } else {
            // yield put(createOrganisationFailed());
        }
    } catch (error) {
        yield put(setShowApiErrorModal());
        // if (error.response && error.response.data && error.response.data.error) {
        //     yield put(loginError(error.response.data.error));
        // } else {
        //     yield put(loginError("Something went Wrong!"));
        // }
        // yield put(createOrganisationFailed());
    }
}

function* cancelSubscription({payload: data}) {
    try {
        const accessToken = yield fireBaseBackend.getAccessToken();
        const response = yield axios({
            method: "POST",
            url: `cancel-subscription`,
            headers: {authorization: `Bearer ${accessToken}`},
            data
        });
        if (response.data.success) {
            yield put(cancelSubscriptionSuccess(response.data.result));
        } else {
            // yield put(createOrganisationFailed());
        }
    } catch (error) {
        yield put(setShowApiErrorModal());
        // if (error.response && error.response.data && error.response.data.error) {
        //     yield put(loginError(error.response.data.error));
        // } else {
        //     yield put(loginError("Something went Wrong!"));
        // }
        // yield put(createOrganisationFailed());
    }
}

export function* watchGetSubscriptionPackages() {
    yield takeEvery(GET_SUBSCRIPTION_PACKAGES, getSubscriptionPackages);
}

export function* watchSubmitChangeSubscription() {
    yield takeEvery(CHANGE_SUBSCRIPTION, submitChangeSubscription);
}

export function* watchHandleCard() {
    yield takeEvery(HANDLE_CARD, submitCard);
}

export function* watchCancelSubscription() {
    yield takeEvery(CANCEL_SUBSCRIPTION, cancelSubscription);
}

function* templateSaga() {
    yield all([
        fork(watchGetSubscriptionPackages),
        fork(watchSubmitChangeSubscription),
        fork(watchHandleCard),
        fork(watchCancelSubscription),
    ]);
}

export default templateSaga;
