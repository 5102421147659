import React from "react";
import Select from "react-select";

const RequiredDrivers = (props) => {
    let jsx = [];
    for (let key in props.servicesByCategory) {
        let innerJsx = [];
        let headerJsx = (
            <tr key={`header${key}`} className="head-row">
                <td>{props.servicesByCategory[key].categoryName}</td>
            </tr>
        );
        for (let serviceKey in props.servicesByCategory[key].services) {
        	let service = props.servicesByCategory[key].services[serviceKey];
            let serviceChargeType = serviceKey.split("_")[1];
            if (serviceChargeType===props.chargeType) {
                let pricingDriverJsx = [];
                let pricingDriverKeys = [];
                if (service.hasOwnProperty("pricingDrivers")) {
                    service.pricingDrivers.forEach((item, index) => {
                        let inputVariableName = `customPackagedPricingDriver-${key}-${serviceKey}-${item._id}`;
                        pricingDriverKeys.push(inputVariableName);
                        let options;
                        if (item.type==="Variation") {
                            options = item.variations.map(variationItem => ({value: variationItem._id, label: variationItem.name}));
                        }
                        else if (item.type==="Slab") {
                            options = item.slabs.map(slabItem => {
                                if (["UnitBased", "BlockBased"].includes(slabItem.type))
                                    return ({value: slabItem._id, label: `${slabItem.from}-${slabItem.to}`});
                                else if (["IncrementalUnit", "IncrementalBlock"].includes(slabItem.type))
                                    return ({value: slabItem._id, label: "Other"})
                            });
                        }
                        else if (item.type==="Number Range") {
                            options = item.number_ranges.map(numberRangeItem => ({value: numberRangeItem._id, label: `${numberRangeItem.from}-${numberRangeItem.to}`}));
                        }
                        pricingDriverJsx.push(
                            <div key={item._id} className="default-value-div">
                                <label>{item.name}</label>
                                {item.type==="Quantity" && <input
                                    value={props.parentProps[inputVariableName] || ""}
                                    onChange={(e) => props.onInputChange(inputVariableName, e.target.value)}
                                    type="text"
                                    className="input-text"
                                    placeholder={item.name}
                                />}
                                {item.type==="Quantity" && props.validator.message(inputVariableName, props.parentProps[inputVariableName], `_required|_numeric|_min:0,num`, { className: 'text-danger' })}
                                {item.type==="Variation" && <Select
                                    options={options}
                                    onChange={selectedOption => props.onInputChange(inputVariableName, selectedOption.value)}
                                    value={props.parentProps[inputVariableName] ? options.filter(option => option.value === props.parentProps[inputVariableName]) : ""}
                                />}
                                {item.type==="Slab" && <Select
                                    options={options}
                                    onChange={selectedOption => props.onInputChange(inputVariableName, selectedOption.value)}
                                    value={props.parentProps[inputVariableName] ? options.filter(option => option.value === props.parentProps[inputVariableName]) : ""}
                                />}
                                {item.type==="Number Range" && <Select
                                    options={options}
                                    onChange={selectedOption => props.onInputChange(inputVariableName, selectedOption.value)}
                                    value={props.parentProps[inputVariableName] ? options.filter(option => option.value === props.parentProps[inputVariableName]) : ""}
                                />}
                                {(item.type==="Variation" || item.type==="Slab" || item.type==="Number Range") && props.validator.message(inputVariableName, props.parentProps[inputVariableName], `_required`, { className: 'text-danger' })}
                                {item.type==="Slab" && item.slabs.length>0 && item.slabs[item.slabs.length-1]._id===props.parentProps[inputVariableName] && ["IncrementalBlock", "IncrementalUnit"].includes(item.slabs[item.slabs.length-1].type) && <React.Fragment>
                                    <input
                                        value={props.parentProps[`${inputVariableName}-${options[item.slabs.length-1].value}_other`] || ""}
                                        onChange={(e) => props.onInputChange(`${inputVariableName}-${options[item.slabs.length-1].value}_other`, e.target.value)}
                                        type="text"
                                        className="input-text other-input-text"
                                        placeholder="Enter Other Value"
                                    />
                                    {props.validator.message(`${inputVariableName}-${options[item.slabs.length-1].value}_other`, props.parentProps[`${inputVariableName}-${options[item.slabs.length-1].value}_other`], `_required|_numeric`, { className: 'text-danger' })}
                                </React.Fragment>}
                            </div>
                        );
                    });
                }
                innerJsx.push(
                    <tr key={serviceKey}>
                        <td>
                            <div className="select-row"><i className="ion ion-md-checkbox"></i><span>{service.name ? service.name.substring(0,30) : ''}</span></div>
                            {pricingDriverJsx.length>=0 && <div onClick={e => e.stopPropagation()}>
                                {pricingDriverJsx}
                            </div>}
                        </td>
                    </tr>
                );
            }
        }
        if (innerJsx.length>0) {
            innerJsx.unshift(headerJsx);
            jsx.push(
                <table key={key} className="table table-striped select-service-table table-hover">
                    <tbody>
                        {innerJsx}
                    </tbody>
                </table>
            );
        }
    }
    if (jsx.length===0) {
        return <div className="text-center">No Information Required!</div>
    }
    return jsx;
}

export default RequiredDrivers;
