import React, { Component } from "react";
import { connect } from "react-redux";
import Loader from "../../components/Loader";
import CreateGlobalPricingDriversModal from "../../components/Settings/CreateGlobalPricingDriversModal";
import GlobalPricingDriversTable from "../../components/Settings/GlobalPricingDriversTable";
import { setShowConfirmDeleteModal } from "../../store/delete/actions";
import { getGlobalPricingDriver, getGlobalPricingDrivers, setShowCreateGlobalConstantModal } from "../../store/service/actions";
import CreateGlobalConstantModal from "../../components/Settings/CreateGlobalConstantModal";
import ConstantTable from '../../components/Settings/ConstantTable'

class GlobalConstants extends Component {
    componentDidMount() {
        this.props.getGlobalPricingDrivers('constant');
    }
    render() {
        return (
            <React.Fragment>
                <div className="page-info-header">
                    <div className="container-fluid">
                        <div className="page-display-title">Global Constants</div>
                        <div className="text-right flex1">
                            <button onClick={() => this.props.setShowCreateGlobalConstantModal(true)} className="btn btn-success create-item" disabled><i className="ion ion-md-add-circle"></i> <span>Add New Constant</span></button>
                        </div>
                    </div>
                </div>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="card cus-card">
                                <div className="card-body">
                                    {/* <GlobalPricingDriversTable delete={this.props.setShowConfirmDeleteModal} getGlobalPricingDriver={this.props.getGlobalPricingDriver} setShowCreateGlobalPricingDriverModal={this.props.setShowCreateGlobalPricingDriverModal} drivers={this.props.globalDrivers} /> */}
                                    <ConstantTable delete={this.props.setShowConfirmDeleteModal} getGlobalPricingDriver={this.props.getGlobalPricingDriver} setShowCreateGlobalConstantModal={this.props.setShowCreateGlobalConstantModal} drivers={this.props.globalDrivers} {...this.props}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <CreateGlobalConstantModal />
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    const { globalDrivers, loading ,linkedServices } = state.Service;
    const { showConfirmDeleteModal } = state.Delete;
    return { globalDrivers, loading ,linkedServices,showConfirmDeleteModal };
}

export default connect(mapStateToProps, {
    setShowCreateGlobalConstantModal,
    getGlobalPricingDrivers,
    getGlobalPricingDriver,
    setShowConfirmDeleteModal,
})(GlobalConstants);
