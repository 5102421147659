import { takeEvery, fork, put, all, call } from 'redux-saga/effects';
import axios from 'axios';

import {
    GET_DASHBOARD, GET_FILTER_DASHBOARD_DATA
} from "./actionTypes";

import { getDashboardSuccess, getFilteredDashboardDataAction } from './actions';
import { getAuditTrailsSuccess } from '../auditTrail/actions';
import { setShowApiErrorModal } from '../common/actions';

// import { setOrganisationFormFromClientData } from '../organisation/actions';

// AUTH related methods
import { getFirebaseBackend } from '../../helpers/authUtils';

const fireBaseBackend = getFirebaseBackend();

function* getDashboard() {
    try {
        const accessToken = yield fireBaseBackend.getAccessToken();
        const response = yield axios({
          method: "GET",
          url: `dashboard`,
          headers: { authorization: `Bearer ${accessToken}` },
        });
        if (response.data.success) {
          yield put(getDashboardSuccess(response.data.result));
          yield put(getAuditTrailsSuccess(response.data.result.auditTrails));
          // yield put(getClientSuccess(response.data.result._id, response.data.result.business_type));
        } else {
          // yield put(createOrganisationFailed());
        }
      } catch (error) {
        console.log(error);
        yield put(setShowApiErrorModal());
        // if (error.response && error.response.data && error.response.data.error) {
        //     yield put(loginError(error.response.data.error));
        // } else {
        //     yield put(loginError("Something went Wrong!"));
        // }
        // yield put(createOrganisationFailed());
    }
    
}

function* getFilteredDashboardData({ payload: query }) {
    try {
      const accessToken = yield fireBaseBackend.getAccessToken();
      const response = yield axios({
        method: "GET",
        url: `filter-dashboard-data?data=${query}`,
        headers: { authorization: `Bearer ${accessToken}` },
      });
      if (response.data.success) {
        yield put(getFilteredDashboardDataAction(response.data.result));
        // yield put(getAuditTrailsSuccess(response.data.result.auditTrails));
      } else {
        // yield put(createOrganisationFailed());
      }
    } catch (error) {
      yield put(setShowApiErrorModal());
    }
}

export function* watchGetDashboard() {
    yield takeEvery(GET_DASHBOARD, getDashboard);
}
export function* watchGetFilterDashboardData() {
    yield takeEvery(GET_FILTER_DASHBOARD_DATA, getFilteredDashboardData);
  }

function* dashboardSaga() {
    yield all([
        fork(watchGetDashboard),fork(watchGetFilterDashboardData)
    ]);
}

export default dashboardSaga;
