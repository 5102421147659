export const SET_TEMPLATE_LOADING = "SET_TEMPLATE_LOADING";
export const ON_TEMPLATE_INPUT_CHANGE = "ON_TEMPLATE_INPUT_CHANGE";
export const MOVE_ELEMENT = "MOVE_ELEMENT";
export const ADD_ELEMENT_BLOCK = "ADD_ELEMENT_BLOCK";
export const DELETE_ELEMENT = "DELETE_ELEMENT";
export const GET_TEMPLATES = "GET_TEMPLATES";
export const GET_TEMPLATES_SUCCESS = "GET_TEMPLATES_SUCCESS";
export const GET_TEMPLATE = "GET_TEMPLATE";
export const GET_TEMPLATE_SUCCESS = "GET_TEMPLATE_SUCCESS";
export const SUBMIT_TEMPLATE = "SUBMIT_TEMPLATE";
export const SUBMIT_TEMPLATE_SUCCESS = "SUBMIT_TEMPLATE_SUCCESS";
export const RESET_CREATE_NEW_TEMPLATE_FORM = "RESET_CREATE_NEW_TEMPLATE_FORM";
export const SET_SHOW_CREATE_NEW_TEMPLATE_SUCCESS_MODAL =
  "SET_SHOW_CREATE_NEW_TEMPLATE_SUCCESS_MODAL";
export const CREATE_NEW_TEMPLATE_FAIL = "CREATE_NEW_TEMPLATE_FAIL";
export const SET_SHOW_CREATE_NEW_TEMPLATE_FAILED_MODAL =
  "SET_SHOW_CREATE_NEW_TEMPLATE_FAILED_MODAL";
export const GET_TEMPLATES_CLIENT = "GET_TEMPLATES_CLIENT";
export const GET_TEMPLATES_CLIENTS_SUCCESS = "GET_TEMPLATES_CLIENTS_SUCCESS";
