import { takeEvery, fork, put, all, call } from "redux-saga/effects";
import axios from "axios";

import { ACTIVE_DEACTIVE_OBJECT } from "./actionTypes";

import { activeDeActiveObjectSuccess } from "./actions";
import { setShowApiErrorModal } from "../common/actions";

import {
  getServicesByCategorySuccess,
  getCategoriesSuccess,
  getGlobalPricingDriversSuccess,
} from "../service/actions";

import { getClientsSuccess } from "../client/actions";

import { getTemplatesSuccess } from "../template/actions";

import { getTermsAndConditionTemplatesSuccess } from "../termsAndConditionTemplate/actions";

import { getPackagesSuccess } from "../package/actions";

import { getAccessKeysSuccess } from "../accessKey/actions";

import { getUsersSuccess } from "../user/actions";

// AUTH related methods
import { getFirebaseBackend } from "../../helpers/authUtils";

const fireBaseBackend = getFirebaseBackend();

function* activeDeActiveObject({ payload: { id, module } }) {
  try {
    const accessToken = yield fireBaseBackend.getAccessToken();
    const response = yield axios({
      method: "POST",
      url: `activeDeActive`,
      headers: { authorization: `Bearer ${accessToken}` },
      data: {
        id,
        module,
      },
    });
    if (response.data.success) {
      switch (module) {
        case "service":
          yield put(getServicesByCategorySuccess(response.data.result));
          break;
        case "package":
          yield put(getPackagesSuccess(response.data.result));
          break;
        case "category":
          yield put(getCategoriesSuccess(response.data.result));
          break;
        case "client":
          yield put(getClientsSuccess(response.data.result));
          break;
        case "template":
          yield put(getTemplatesSuccess(response.data.result));
          break;
        case "tncTemplate":
          yield put(getTermsAndConditionTemplatesSuccess(response.data.result));
          break;
        case "globalPricingDriver":
          yield put(getGlobalPricingDriversSuccess(response.data.result));
          break;
        case "accessKey":
          yield put(getAccessKeysSuccess(response.data.result));
          break;
        case "user":
          yield put(getUsersSuccess(response.data.result));
          break;
        default:
          break;
      }
      yield put(activeDeActiveObjectSuccess());
    }
  } catch (error) {
    yield put(setShowApiErrorModal());
    console.log(error);
  }
}

export function* watchActiveDeActiveObject() {
  yield takeEvery(ACTIVE_DEACTIVE_OBJECT, activeDeActiveObject);
}

function* activeDeActiveSaga() {
  yield all([fork(watchActiveDeActiveObject)]);
}

export default activeDeActiveSaga;
