export const SET_CONTRACT_LOADING = "SET_CONTRACT_LOADING";
export const ON_CONTRACT_INPUT_CHANGE = "ON_CONTRACT_INPUT_CHANGE";
export const GET_CONTRACTS = "GET_CONTRACTS";
export const GET_CONTRACTS_SUCCESS = "GET_CONTRACTS_SUCCESS";
export const GET_CONTRACT = "GET_CONTRACT";
export const GET_CONTRACT_STATUS = "GET_CONTRACT_STATUS";
export const GET_CONTRACT_PDF_URL = "GET_CONTRACT_PDF_URL";
export const GET_CONTRACT_SUCCESS = "GET_CONTRACT_SUCCESS";
export const DOWNLOAD_CONTRACT = "DOWNLOAD_CONTRACT";
export const DOWNLOAD_CONTRACT_SUCCESS = "DOWNLOAD_CONTRACT_SUCCESS";
export const SET_CONTRACT_ACTIVE_STEP = "SET_CONTRACT_ACTIVE_STEP";
export const ADD_SERVICE_TO_CONTRACT = "ADD_SERVICE_TO_CONTRACT";
export const REMOVE_SERVICE_FROM_CONTRACT = "REMOVE_SERVICE_FROM_CONTRACT";
export const PREVIEW_CONTRACT = "PREVIEW_CONTRACT";
export const PREVIEW_CONTRACT_SUCCESS = "PREVIEW_CONTRACT_SUCCESS";
export const SAVE_CONTRACT_AS_DRAFT = "SAVE_CONTRACT_AS_DRAFT";
export const SAVE_CONTRACT_AS_DRAFT_SUCCESS = "SAVE_CONTRACT_AS_DRAFT_SUCCESS";
export const SEND_CONTRACT = "SEND_CONTRACT";
export const SEND_CONTRACT_SUCCESS = "SEND_CONTRACT_SUCCESS";
export const RESET_CREATE_NEW_CONTRACT_FORM = "RESET_CREATE_NEW_CONTRACT_FORM";
export const SET_SHOW_CREATE_NEW_CONTRACT_SUCCESS_MODAL =
  "SET_SHOW_CREATE_NEW_CONTRACT_SUCCESS_MODAL";
export const GET_SIGNATURE_BLOCKS = "GET_SIGNATURE_BLOCKS";
export const GET_SIGNATURE_BLOCKS_SUCCESS = "GET_SIGNATURE_BLOCKS_SUCCESS";
export const ADD_SIGNATORY = "ADD_SIGNATORY";
export const DELETE_SIGNATORY = "DELETE_SIGNATORY";
export const SET_SIGNATORIES = "SET_SIGNATORIES";
export const REVIEW_CONTRACT = "REVIEW_CONTRACT";
export const REVIEW_CONTRACT_SUCCESS = "REVIEW_CONTRACT_SUCCESS";
export const GET_TNC_TEMPLATES = "GET_TNC_TEMPLATES";
export const GET_TNC_TEMPLATES_SUCCESS = "GET_TNC_TEMPLATES_SUCCESS";
export const SEARCH_CONTRACT = "SEARCH_CONTRACT";
export const SEARCH_CONTRACT_SUCCESS = "SEARCH_CONTRACT_SUCCESS";
export const SEND_CONTRACT_CLIENT = "SEND_CONTRACT_CLIENT";
export const SEND_CONTRACT_CLIENT_SUCCESS = "SEND_CONTRACT_CLIENT_SUCCESS";
export const SAVE_CONTRACT_AS_DRAFT_CLIENT = "SAVE_CONTRACT_AS_DRAFT_CLIENT";
export const SAVE_CONTRACT_AS_DRAFT_CLIENT_SUCCESS =
  "SAVE_CONTRACT_AS_DRAFT_CLIENT_SUCCESS";
export const REVIEW_CONTRACT_CLIENT = "REVIEW_CONTRACT_CLIENT";
export const REVIEW_CONTRACT_CLIENT_SUCCESS = "REVIEW_CONTRACT_CLIENT_SUCCESS";
export const RESET_CONTRACT_SEARCH_INPUT = "RESET_CONTRACT_SEARCH_INPUT";