import {
  SET_SERVICE_LOADING,
  ON_SERVICE_INPUT_CHANGE,
  SET_SERVICE_ACTIVE_STEP,
  ADD_PRICING_DRIVER,
  DELETE_PRICING_DRIVER,
  ADD_VARIATION_TO_PRICING_DRIVER,
  DELETE_VARIATION_FROM_PRICING_DRIVER,
  ADD_SLAB_TO_PRICING_DRIVER,
  DELETE_SLAB_FROM_PRICING_DRIVER,
  ADD_NUMBER_RANGE_TO_PRICING_DRIVER,
  DELETE_NUMBER_RANGE_FROM_PRICING_DRIVER,
  GET_SERVICE,
  GET_SERVICE_SUCCESS,
  GET_CATEGORIES,
  GET_CATEGORIES_SUCCESS,
  CREATE_NEW_SERVICE,
  SUBMIT_SERVICE,
  CREATE_NEW_SERVICE_SUCCESS,
  SUBMIT_SERVICE_SUCCESS,
  SET_SHOW_CREATE_NEW_SERVICE_SUCCESS_MODAL,
  SET_SHOW_CREATE_CATEGORY_MODAL,
  CREATE_NEW_CATEGORY,
  CREATE_NEW_CATEGORY_SUCCESS,
  UPDATE_CATEGORY,
  UPDATE_CATEGORY_SUCCESS,
  SET_SHOW_CREATE_NEW_CATEGORY_SUCCESS_MODAL,
  SET_SHOW_UPDATE_SERVICE_CONFIRM_MODAL,
  GET_SERVICES_BY_CATEGORY,
  GET_SERVICES_BY_CATEGORY_SUCCESS,
  RESET_CREATE_NEW_SERVICE_FORM,
  GET_GLOBAL_PRICING_DRIVERS,
  GET_GLOBAL_PRICING_DRIVERS_SUCCESS,
  GET_GLOBAL_PRICING_DRIVER,
  GET_GLOBAL_PRICING_DRIVER_SUCCESS,
  SET_SHOW_CREATE_GLOBAL_PRICING_DRIVER_MODAL,
  SET_SHOW_CREATE_GLOBAL_PRICING_DRIVER_SUCCESS_MODAL,
  SUBMIT_GLOBAL_PRICING_DRIVER,
  SUBMIT_GLOBAL_PRICING_DRIVER_SUCCESS,
  GET_GLOBAL_DRIVERS_BY_SERVICES,
  GET_GLOBAL_DRIVERS_BY_SERVICES_SUCCESS,
  RESET_PRICING_DRIVER_FORM,
  SET_SHOW_CREATE_GLOBAL_CONSTANT_MODAL,
  SUBMIT_GLOBAL_CONSTANT,
  SUBMIT_GLOBAL_CONSTANT_SUCCESS,
  UPDATE_SERVICE_ACTIVE_KEY,
  UPDATE_SERVICE_ACTIVE_KEY_SUCCESS,
  ON_SERVICE_DRIVER_INPUT_CHANGE,
  ON_SERVICE_GLOBAL_DRIVER_INPUT_CHANGE,
  CREATE_NEW_CATEGORY_FAIL,
  CREATE_NEW_SERVICE_FAIL,
  SET_SHOW_CREATE_NEW_CATEGORY_FAILED_MODAL,
  SET_SHOW_CREATE_NEW_SERVICE_FAILED_MODAL,
  SUBMIT_GLOBAL_CONSTANT_FAIL,
  SET_SHOW_CREATE_GLOBAL_PRICING_DRIVER_FAIL_MODAL,
  CREATE_NEW_GLOBAL_DRIVER_FAIL,
  SET_SHOW_CREATE_NEW_GLOBAL_DRIVER_FAILED_MODAL,
  SET_SHOW_CHANGE_GLOBAL_PRICING_DRIVER_VALUES_MODAL,

} from "./actionTypes";

export const setServiceLoading = (isLoading) => {
  return {
    type: SET_SERVICE_LOADING,
    payload: isLoading,
  };
};

export const onInputChange = (field, value) => {
  return {
    type: ON_SERVICE_INPUT_CHANGE,
    payload: {
      field,
      value,
    },
  };
};
export const setDefaultDriver = (id, code, value, item) => {
  return {
    type: ON_SERVICE_DRIVER_INPUT_CHANGE,
    payload: {
      id,
      code,
      value,
      item,
    },
  };
};
export const setDefaultGlobalDriver = (id, code, value) => {
  return {
    type: ON_SERVICE_GLOBAL_DRIVER_INPUT_CHANGE,
    payload: {
      id,
      code,
      value,
    },
  };
};
export const setActiveStep = (step) => {
  return {
    type: SET_SERVICE_ACTIVE_STEP,
    payload: step,
  };
};

export const addPricingDriver = (code) => {
  return {
    type: ADD_PRICING_DRIVER,
    payload: code,
  };
};

export const deletePricingDriver = (code) => {
  return {
    type: DELETE_PRICING_DRIVER,
    payload: code,
  };
};

export const addVariationToPricingDriver = (
  pricingDriverCode,
  variationCode
) => {
  return {
    type: ADD_VARIATION_TO_PRICING_DRIVER,
    payload: {
      pricingDriverCode,
      variationCode,
    },
  };
};

export const deleteVariationFromPricingDriver = (
  pricingDriverCode,
  variationCode
) => {
  return {
    type: DELETE_VARIATION_FROM_PRICING_DRIVER,
    payload: {
      pricingDriverCode,
      variationCode,
    },
  };
};

export const addSlabToPricingDriver = (pricingDriverCode, slabCode) => {
  return {
    type: ADD_SLAB_TO_PRICING_DRIVER,
    payload: {
      pricingDriverCode,
      slabCode,
    },
  };
};

export const deleteSlabFromPricingDriver = (pricingDriverCode, slabCode) => {
  return {
    type: DELETE_SLAB_FROM_PRICING_DRIVER,
    payload: {
      pricingDriverCode,
      slabCode,
    },
  };
};

export const addNumberRangeToPricingDriver = (
  pricingDriverCode,
  numberRangeCode
) => {
  return {
    type: ADD_NUMBER_RANGE_TO_PRICING_DRIVER,
    payload: {
      pricingDriverCode,
      numberRangeCode,
    },
  };
};

export const deleteNumberRangeFromPricingDriver = (
  pricingDriverCode,
  numberRangeCode
) => {
  return {
    type: DELETE_NUMBER_RANGE_FROM_PRICING_DRIVER,
    payload: {
      pricingDriverCode,
      numberRangeCode,
    },
  };
};

export const resetCreateNewServiceForm = () => {
  return {
    type: RESET_CREATE_NEW_SERVICE_FORM,
  };
};

export const getService = (id) => {
  return {
    type: GET_SERVICE,
    payload: id,
  };
};

export const getServiceSuccess = ({
  service,
  pricingDrivers,
  categories,
  affectedPackages,
  QuoteCount,
  ContractCount,
}) => {
  return {
    type: GET_SERVICE_SUCCESS,
    payload: {
      service,
      pricingDrivers,
      categories,
      affectedPackages,
      QuoteCount,
      ContractCount,
    },
  };
};

export const getCategories = (pageNum = 0) => {
  return {
    type: GET_CATEGORIES,
    payload: pageNum,
  };
};

export const getCategoriesSuccess = (data) => {
  return {
    type: GET_CATEGORIES_SUCCESS,
    payload: data,
  };
};

export const createService = (data) => {
  return {
    type: CREATE_NEW_SERVICE,
    payload: data,
  };
};

export const submitService = (data, type = "new") => {
  return {
    type: SUBMIT_SERVICE,
    payload: {
      data,
      type,
    },
  };
};

export const createServiceSuccess = () => {
  return {
    type: CREATE_NEW_SERVICE_SUCCESS,
  };
};

export const submitServiceSuccess = () => {
  return {
    type: SUBMIT_SERVICE_SUCCESS,
  };
};

export const setShowCreateNewServiceSuccessModal = (isVisible) => {
  return {
    type: SET_SHOW_CREATE_NEW_SERVICE_SUCCESS_MODAL,
    payload: isVisible,
  };
};

export const setShowCreateCategoryModal = (
  isVisible,
  type = "create",
  editId = null,
  index = null
) => {
  return {
    type: SET_SHOW_CREATE_CATEGORY_MODAL,
    payload: {
      isVisible,
      type,
      editId,
      index,
    },
  };
};

export const createCategory = (data) => {
  return {
    type: CREATE_NEW_CATEGORY,
    payload: data,
  };
};

export const createCategorySuccess = (data) => {
  return {
    type: CREATE_NEW_CATEGORY_SUCCESS,
    payload: data,
  };
};

export const createCategoryFailed = (data) => {
  return {
    type: CREATE_NEW_CATEGORY_FAIL,
    payload: data,
  };
};
export const createServiceFailed = (data) => {
  return {
    type: CREATE_NEW_SERVICE_FAIL,
    payload: data,
  };
};

export const updateCategory = (data) => {
  return {
    type: UPDATE_CATEGORY,
    payload: data,
  };
};

export const updateCategorySuccess = (data) => {
  return {
    type: UPDATE_CATEGORY_SUCCESS,
    payload: data,
  };
};

export const setShowCreateNewCategorySuccessModal = (isVisible) => {
  return {
    type: SET_SHOW_CREATE_NEW_CATEGORY_SUCCESS_MODAL,
    payload: isVisible,
  };
};
export const setShowCreateNewCategoryFailedModal = (isVisible) => {
  return {
    type: SET_SHOW_CREATE_NEW_CATEGORY_FAILED_MODAL,
    payload: isVisible,
  };
};
export const setShowCreateNewServiceFailedModal = (isVisible) => {
  return {
    type: SET_SHOW_CREATE_NEW_SERVICE_FAILED_MODAL,
    payload: isVisible,
  };
};

export const getServicesByCategory = () => {
  return {
    type: GET_SERVICES_BY_CATEGORY,
  };
};

export const getServicesByCategorySuccess = (data) => {
  return {
    type: GET_SERVICES_BY_CATEGORY_SUCCESS,
    payload: data,
  };
};

export const getGlobalPricingDrivers = (type) => {
  return {
    type: GET_GLOBAL_PRICING_DRIVERS,
    payload: type,
  };
};

export const getGlobalPricingDriversSuccess = (drivers) => {
  return {
    type: GET_GLOBAL_PRICING_DRIVERS_SUCCESS,
    payload: drivers,
  };
};

export const getGlobalPricingDriver = (id) => {
  return {
    type: GET_GLOBAL_PRICING_DRIVER,
    payload: id,
  };
};

export const getGlobalPricingDriverSuccess = (driver, linkedServices) => {
  return {
    type: GET_GLOBAL_PRICING_DRIVER_SUCCESS,
    payload: { driver, linkedServices },
  };
};

export const submitGlobalPricingDriver = (driverData, type = "create") => {
  return {
    type: SUBMIT_GLOBAL_PRICING_DRIVER,
    payload: {
      driverData,
      type,
    },
  };
};

export const submitGlobalPricingDriverSuccess = (drivers) => {
  return {
    type: SUBMIT_GLOBAL_PRICING_DRIVER_SUCCESS,
    payload: drivers,
  };
};

export const setShowCreateGlobalPricingDriverModal = (
  isVisible,
  type = "create"
) => {
  return {
    type: SET_SHOW_CREATE_GLOBAL_PRICING_DRIVER_MODAL,
    payload: {
      isVisible,
      type,
    },
  };
};

export const setShowCreateGlobalPricingDriverSuccessModal = (isVisible) => {
  return {
    type: SET_SHOW_CREATE_GLOBAL_PRICING_DRIVER_SUCCESS_MODAL,
    payload: isVisible,
  };
};

export const setShowCreateGlobalPricingDriverFailModal = (isVisible) => {
  return {
    type: SET_SHOW_CREATE_GLOBAL_PRICING_DRIVER_FAIL_MODAL,
    payload: isVisible,
  };
};

export const getGlobalDriversByServices = (services) => {
  return {
    type: GET_GLOBAL_DRIVERS_BY_SERVICES,
    payload: services,
  };
};

export const getGlobalDriversByServicesSuccess = (requiredGlobalDrivers) => {
  return {
    type: GET_GLOBAL_DRIVERS_BY_SERVICES_SUCCESS,
    payload: requiredGlobalDrivers,
  };
};

export const setShowUpdateServiceConfirmModal = (isVisible) => {
  return {
    type: SET_SHOW_UPDATE_SERVICE_CONFIRM_MODAL,
    payload: isVisible,
  };
};

export const resetPricingDriverForm = () => {
  return {
    type: RESET_PRICING_DRIVER_FORM,
  };
};

export const setShowCreateGlobalConstantModal = (
  isVisible,
  type = "create"
) => {
  return {
    type: SET_SHOW_CREATE_GLOBAL_CONSTANT_MODAL,
    payload: {
      isVisible,
      type,
    },
  };
};

export const submitGlobalConstant = (driverData, type = "create") => {
  return {
    type: SUBMIT_GLOBAL_CONSTANT,
    payload: {
      driverData,
      type,
    },
  };
};

export const submitGlobalConstantFail = () => {
  return {
    type: SUBMIT_GLOBAL_CONSTANT_FAIL,
  };
};

export const submitGlobalConstantSuccess = (drivers) => {
  return {
    type: SUBMIT_GLOBAL_CONSTANT_SUCCESS,
    payload: drivers,
  };
};

export const updateServiceActiveKey = (data) => {
  return {
    type: UPDATE_SERVICE_ACTIVE_KEY,
    payload: data,
  };
};

export const updateServiceActiveKeySuccess = (data) => {
  return {
    type: UPDATE_SERVICE_ACTIVE_KEY_SUCCESS,
    payload: data,
  };
};

export const createGlobalDeriverFailed = (data) => {
  return {
    type: CREATE_NEW_GLOBAL_DRIVER_FAIL,
    payload: data,
  };
};

export const setShowCreateNewGlobalDriverFailedModal = (isVisible) => {
  return {
    type: SET_SHOW_CREATE_NEW_GLOBAL_DRIVER_FAILED_MODAL,
    payload: isVisible,
  };
};

export const setShowChangeGlobalPricingDriverValuesModal = (isVisible, type = "create") => {
  return {
    type: SET_SHOW_CHANGE_GLOBAL_PRICING_DRIVER_VALUES_MODAL,
    payload: {
      isVisible
    }
  }
}