import {
	SET_TERMS_AND_CONDITION_TEMPLATE_LOADING,
	ON_TERMS_AND_CONDITION_TEMPLATE_INPUT_CHANGE,
	GET_TERMS_AND_CONDITION_TEMPLATES,
	GET_TERMS_AND_CONDITION_TEMPLATES_SUCCESS,
	GET_TERMS_AND_CONDITION_TEMPLATE,
	GET_TERMS_AND_CONDITION_TEMPLATE_SUCCESS,
	SUBMIT_TERMS_AND_CONDITION_TEMPLATE,
	SUBMIT_TERMS_AND_CONDITION_TEMPLATE_SUCCESS,
	RESET_CREATE_NEW_TERMS_AND_CONDITION_TEMPLATE_FORM,
	SET_SHOW_CREATE_NEW_TERMS_AND_CONDITION_SUCCESS_MODAL,
	UPLOAD_TERMS_AND_CONDITION_TEMPLATE,
	UPLOAD_TERMS_AND_CONDITION_TEMPLATE_SUCCESS,
	DELETE_TERMS_AND_CONDITION_TEMPLATE,
	DELETE_TERMS_AND_CONDITION_TEMPLATE_SUCCESS,
	CREATE_NEW_TERMS_AND_CONDITIONS_FAIL,
	SET_SHOW_CREATE_NEW_TERMS_AND_CONDITIONS_FAILED_MODAL
} from "./actionTypes";

export const setTermsAndConditionTemplateLoading = (isLoading) => {
	return {
		type: SET_TERMS_AND_CONDITION_TEMPLATE_LOADING,
		payload: isLoading
	}
}

export const onInputChange = (field, value) => {
	console.log("field :: ", field)
	console.log("value :: ", value)
	return {
		type: ON_TERMS_AND_CONDITION_TEMPLATE_INPUT_CHANGE,
		payload: {
			field,
			value
		}
	}
}

export const getTermsAndConditionTemplates = () => {
	return {
		type: GET_TERMS_AND_CONDITION_TEMPLATES
	}
}

export const getTermsAndConditionTemplatesSuccess = data => {
	return {
		type: GET_TERMS_AND_CONDITION_TEMPLATES_SUCCESS,
		payload: data
	}
}

export const getTermsAndConditionTemplate = id => {
	return {
		type: GET_TERMS_AND_CONDITION_TEMPLATE,
		payload: id
	}
}

export const getTermsAndConditionTemplateSuccess = data => {
	return {
		type: GET_TERMS_AND_CONDITION_TEMPLATE_SUCCESS,
		payload: data
	}
}

export const submitTermsAndConditionTemplate = (data, type="new") => {
	return {
		type: SUBMIT_TERMS_AND_CONDITION_TEMPLATE,
		payload: {
			data,
			type
		}
	}
}

export const submitTermsAndConditionTemplateSuccess = () => {
	return {
		type: SUBMIT_TERMS_AND_CONDITION_TEMPLATE_SUCCESS
	}
}

export const resetCreateNewTermsAndConditionTemplateForm = () => {
	return {
		type: RESET_CREATE_NEW_TERMS_AND_CONDITION_TEMPLATE_FORM
	}
}

export const setShowCreateNewTermsAndConditionSuccessModal = isVisible => {
	return {
		type: SET_SHOW_CREATE_NEW_TERMS_AND_CONDITION_SUCCESS_MODAL,
		payload: isVisible
	}
}

export const uploadTermsAndConditionTemplate = (file) => {
	return {
		type: UPLOAD_TERMS_AND_CONDITION_TEMPLATE,
		payload: file
	}
}

export const uploadTermsAndConditionTemplateSuccess = (fileUrl) => {
	return {
		type: UPLOAD_TERMS_AND_CONDITION_TEMPLATE_SUCCESS,
		payload: fileUrl
	}
}

export const deleteTermsAndConditionTemplate = () => {
	return {
		type: DELETE_TERMS_AND_CONDITION_TEMPLATE
	}
}

export const deleteTermsAndConditionTemplateSuccess = () => {
	return {
		type: DELETE_TERMS_AND_CONDITION_TEMPLATE_SUCCESS,
	}
}

export const createTermsConditionFailed = (data) => {
	return {
	  type: CREATE_NEW_TERMS_AND_CONDITIONS_FAIL,
	  payload: data,
	};
  };

export const setShowCreateNewTermsConditionFailedModal = (isVisible) => {
	return {
	  type: SET_SHOW_CREATE_NEW_TERMS_AND_CONDITIONS_FAILED_MODAL,
	  payload: isVisible,
	};
  };