import React from "react";
import { Row, Col, Button, Input, Card, CardBody } from "reactstrap";
import { Link } from "react-router-dom";

const getShortDate = (date) => {
  const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
  let d = new Date(date);
  let t = d.toLocaleTimeString();
  let dateString = `${months[d.getMonth()]} ${d.getDate()} (${t.slice(0,-6)} ${t.slice(-2)})`;
  return dateString;
}

const getNotificationHeader = (item) => {
	switch(item.event_name) {
	  case "quoteSent":
	    return "New Proposal Sent";
	  case "contractSent":
	    return "New Engagement Letter Sent";
	  case "contractCompleted":
	    return `Engagement Letter Signed`;
	  default: 
	    return "";
	}
}

const getNotificationDescription = (item) => {
	switch(item.event_name) {
	  case "quoteSent":
	    return `${item.user.first_name} ${item.user.last_name} sent new proposal (${item.metadata.ref}) to ${item.metadata.clientDisplayName}`;
	  case "contractSent":
	    return `${item.user.first_name} ${item.user.last_name} sent new Engagement Letter (${item.metadata.ref}) to ${item.metadata.clientDisplayName}`;
	  case "contractCompleted":
	    return `Engagement Letter ${item.metadata.ref} has been signed and completed`;
	  default: 
	    return "";
	}
}

const renderNotification = (item, index) => {
	return (
      <li key={item._id} className="feed-item">
        <div className="feed-item-list">
          <span className="date"><strong>{getNotificationHeader(item)}</strong><span className="ml-2">[{getShortDate(item.createdAt)}]</span></span>
          <Link to="#" style={{ color: '#5b626b',cursor: 'inherit' }}>
	          <span className="activity-text">
	          	{getNotificationDescription(item)}
	          </span>
          </Link>
        </div>
      </li>
    );
}

const NotificationsTable = (props) => {
	return(
		<ol className="activity-feed">
			{props.notifications.map(renderNotification)}
		</ol>
	)
}

export default NotificationsTable;
