import React from "react";
import DatePicker from "react-date-picker";
import { connect } from "react-redux";
import { CustomInput } from "reactstrap";
import { formatAddress } from "../Utils";
import InputPhone from "./InputPhone";
import OrganisationAddress from "./OrganisationAddress";

const OrganisationOfficer = (props) => {
  return (
    <React.Fragment>
      <div className="fieldset-group">
        <label className="fieldset-group-label">
          Officer {props.index + 1}
        </label>
        {!props.readOnly && (
          <button
            onClick={() => props.deleteOfficer(props.officerCode)}
            className="btn btn-sm btn-danger delete-fieldset-group"
          >
            <i className="ion ion-md-trash mr-1"></i>Delete Officer
          </button>
        )}
        <div className="row">
          <div className="col-12">
            <div className="row fieldset">
              <div className="col-12 text-right">
                <CustomInput
                  id={`officer${props.officerCode}IsAuthorisedSignatory`}
                  type="switch"
                  onChange={(e) =>
                    props.readOnly
                      ? null
                      : props.onInputChange(
                          `officer${props.officerCode}IsAuthorisedSignatory`,
                          e.target.checked
                        )
                  }
                  checked={props.isAuthorisedSignatory || false}
                  label="Authorised Signatory"
                />
              </div>
            </div>
          </div>
          <div className="col-6">
            <div className="row fieldset">
              <div className="col-4 text-right">
                <label
                  className={`fieldset-label ${
                    props.readOnly ? "" : "required"
                  }`}
                >
                  First Name
                </label>
              </div>
              <div className="col-8">
                <input
                  readOnly={props.readOnly}
                  value={props.firstName || ""}
                  onChange={(e) =>
                    props.onInputChange(
                      `officer${props.officerCode}FirstName`,
                      e.target.value
                    )
                  }
                  className="input-text"
                  type="text"
                  placeholder="First Name"
                />
                {!props.readOnly &&
                  props.validator.message(
                    `officer${props.officerCode}FirstName`,
                    props.firstName,
                    `_required|_name`,
                    { className: "text-danger" }
                  )}
              </div>
            </div>
          </div>
          <div className="col-6">
            <div className="row fieldset">
              <div className="col-4 text-right">
                <label
                  className={`fieldset-label ${
                    props.readOnly ? "" : "required"
                  }`}
                >
                  Last Name
                </label>
              </div>
              <div className="col-8">
                <input
                  readOnly={props.readOnly}
                  value={props.lastName || ""}
                  onChange={(e) =>
                    props.onInputChange(
                      `officer${props.officerCode}LastName`,
                      e.target.value
                    )
                  }
                  className="input-text"
                  type="text"
                  placeholder="Last Name"
                />
                {!props.readOnly &&
                  props.validator.message(
                    `officer${props.officerCode}LastName`,
                    props.lastName,
                    `_required|_name`,
                    { className: "text-danger" }
                  )}
              </div>
            </div>
          </div>

          <div className="col-6">
            <div className="row fieldset">
              <div className="col-4 text-right">
                <label
                  className={`fieldset-label ${
                    props.readOnly ? "" : "required"
                  }`}
                >
                  Role
                </label>
              </div>
              <div className="col-8">
                <input
                  readOnly={props.readOnly}
                  value={props.role || ""}
                  onChange={(e) =>
                    props.onInputChange(
                      `officer${props.officerCode}Role`,
                      e.target.value
                    )
                  }
                  autoFocus="autoFocus"
                  className="input-text"
                  type="text"
                  placeholder="Role"
                />
                {!props.readOnly &&
                  props.validator.message(
                    `officer${props.officerCode}Role`,
                    props.role,
                    `_required`,
                    { className: "text-danger" }
                  )}
              </div>
            </div>
          </div>
          <div className="col-6">
            <div className="row fieldset">
              <div className="col-4 text-right">
                <label
                  className={`fieldset-label ${
                    props.readOnly ? "" : "required"
                  }`}
                >
                  Appointed On
                </label>
              </div>
              <div className="col-8">
                <DatePicker
                  yearPlaceholder="yyyy"
                  monthPlaceholder="MM"
                  dayPlaceholder="dd"
                  format="dd-MM-yyyy"
                  onChange={(date) =>
                    props.onInputChange(
                      `officer${props.officerCode}AppointedOn`,
                      date
                    )
                  }
                  value={props.appointedOn ? new Date(props.appointedOn) : null}
                  maxDate={new Date()}
                  className="read-only"
                  readOnly={props.readOnly}
                  disabled={props.readOnly}
                />
                {!props.readOnly &&
                  props.validator.message(
                    `officer${props.officerCode}AppointedOn`,
                    props.appointedOn,
                    `_required`,
                    { className: "text-danger" }
                  )}
              </div>
            </div>
          </div>
          {/*<div className="col-6">
						<div className="row fieldset">
							<div className="col-4 text-right">
								<label className="fieldset-label">Nationality</label>
							</div>
							<div className="col-8">
								<input value={props.nationality || ""} onChange={(e) => props.onInputChange(`officer${props.officerCode}Nationality`, e.target.value)} className="input-text" type="text" placeholder="Nationality" />
							</div>
						</div>
					</div>
					<div className="col-6">
						<div className="row fieldset">
							<div className="col-4 text-right">
								<label className="fieldset-label">Country of Residence</label>
							</div>
							<div className="col-8">
								<input value={props.countryOfResidence || ""} onChange={(e) => props.onInputChange(`officer${props.officerCode}CountryOfResidence`, e.target.value)} className="input-text" type="text" placeholder="Country of Residence" />
							</div>
						</div>
					</div>*/}
          <div className="col-6">
            <div className="row fieldset">
              <div className="col-4 text-right">
                <label className={`fieldset-label`}>Phone</label>
              </div>
              <div className="col-8">
                <InputPhone
                  readOnly={props.readOnly}
                  phoneFieldName={`officer${props.officerCode}Phone`}
                  dialCodeFieldName={`officer${props.officerCode}DialCode`}
                  placeholder="Phone"
                  dialCode={props.dialCode}
                  phone={props.phone}
                  validator={props.validator}
                  onChange={props.onInputChange}
                />
              </div>
            </div>
          </div>
          <div className="col-6">
            <div className="row fieldset">
              <div className="col-4 text-right">
                <label
                  className={`fieldset-label ${
                    props.readOnly ? "" : "required"
                  }`}
                >
                  Email
                </label>
              </div>
              <div className="col-8">
                <input
                  readOnly={props.readOnly}
                  value={props.email || ""}
                  onChange={(e) =>
                    props.onInputChange(
                      `officer${props.officerCode}Email`,
                      e.target.value
                    )
                  }
                  className="input-text"
                  type="text"
                  placeholder="Email"
                />
                {!props.readOnly &&
                  props.validator.message(
                    `officer${props.officerCode}Email`,
                    props.email,
                    `_required|_email`,
                    { className: "text-danger" }
                  )}
              </div>
            </div>
          </div>
          <div className="col-12">
            <div className="row fieldset">
              <div className="col-2 text-right">
                <label
                  className={`fieldset-label ${
                    props.readOnly ? "" : "required"
                  }`}
                >
                  Correspondence Address
                </label>
              </div>
              <div className="col-10">
                <OrganisationAddress
                  addressHeader="Correspondence Address"
                  addressPrefix={`officer${props.officerCode}`}
                  addressSearch={props.addressSearch}
                  premises={props.premises}
                  addressLine1={props.addressLine1}
                  addressLine2={props.addressLine2}
                  locality={props.locality}
                  region={props.region}
                  country={props.country}
                  postcode={props.postcode}
                  onInputChange={props.onInputChange}
                  readOnly={props.readOnly}
                />
                {!props.readOnly &&
                  props.validator.message(
                    `officer${props.officerCode}Address`,
                    formatAddress(
                      props.premises,
                      props.addressLine1,
                      props.addressLine2,
                      props.locality,
                      props.region,
                      props.country,
                      props.postcode
                    ),
                    "_required",
                    { className: "text-danger" }
                  )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default OrganisationOfficer;
