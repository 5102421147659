import { takeEvery, fork, put, all, call } from "redux-saga/effects";
import axios from "axios";

import {
  GET_SERVICE,
  GET_CATEGORIES,
  SUBMIT_SERVICE,
  CREATE_NEW_CATEGORY,
  UPDATE_CATEGORY,
  UPDATE_SERVICE_ACTIVE_KEY,
  GET_SERVICES_BY_CATEGORY,
  GET_GLOBAL_PRICING_DRIVERS,
  GET_GLOBAL_PRICING_DRIVER,
  SUBMIT_GLOBAL_PRICING_DRIVER,
  GET_GLOBAL_DRIVERS_BY_SERVICES,
  SUBMIT_GLOBAL_CONSTANT,
} from "./actionTypes";

import {
  getServiceSuccess,
  getCategoriesSuccess,
  createCategorySuccess,
  updateCategorySuccess,
  updateServiceActiveKeySuccess,
  createServiceSuccess,
  submitServiceSuccess,
  getServicesByCategorySuccess,
  getGlobalPricingDriversSuccess,
  getGlobalPricingDriverSuccess,
  submitGlobalPricingDriverSuccess,
  getGlobalDriversByServicesSuccess,
  submitGlobalConstantSuccess,
  createCategoryFailed,
  createServiceFailed,
  submitGlobalConstantFail,
  createGlobalDeriverFailed
} from "./actions";
import { setShowApiErrorModal } from "../common/actions";

// AUTH related methods
import { getFirebaseBackend } from "../../helpers/authUtils";

const fireBaseBackend = getFirebaseBackend();

function* getService({ payload: id }) {
  try {
    const accessToken = yield fireBaseBackend.getAccessToken();
    const response = yield axios({
      method: "GET",
      url: `service/${id}`,
      headers: { authorization: `Bearer ${accessToken}` },
    });
    if (response.data.success) {
      yield put(getServiceSuccess(response.data.result));
    } else {
      // yield put(createOrganisationFailed());
    }
  } catch (error) {
    yield put(setShowApiErrorModal());
    console.log(error);
    // if (error.response && error.response.data && error.response.data.error) {
    //     yield put(loginError(error.response.data.error));
    // } else {
    //     yield put(loginError("Something went Wrong!"));
    // }
    // yield put(createOrganisationFailed());
  }
}

function* getCategories({ payload: page_num }) {
  try {
    const accessToken = yield fireBaseBackend.getAccessToken();
    const response = yield axios({
      method: "GET",
      url: `categories?page_num=${page_num}`,
      headers: { authorization: `Bearer ${accessToken}` },
    });
    if (response.data.success) {
      yield put(getCategoriesSuccess(response.data.result));
    } else {
      // yield put(createOrganisationFailed());
    }
  } catch (error) {
    yield put(setShowApiErrorModal());
    // if (error.response && error.response.data && error.response.data.error) {
    //     yield put(loginError(error.response.data.error));
    // } else {
    //     yield put(loginError("Something went Wrong!"));
    // }
    // yield put(createOrganisationFailed());
  }
}

function* submitService({ payload: { data, type } }) {
  try {
    const accessToken = yield fireBaseBackend.getAccessToken();
    let url;
    switch (type) {
      case "new":
        url = "create-new-service";
        break;
      case "edit":
        url = "update-service";
        break;
      default:
        break;
    }
    const response = yield axios({
      method: "POST",
      url,
      headers: { authorization: `Bearer ${accessToken}` },
      data,
    });
    if (response.data.success) {
      yield put(submitServiceSuccess());
    } else {
      yield put(createServiceFailed(response.data.error));
      // yield put(createOrganisationFailed());
    }
  } catch (error) {
    yield put(setShowApiErrorModal());
    // if (error.response && error.response.data && error.response.data.error) {
    //     yield put(loginError(error.response.data.error));
    // } else {
    //     yield put(loginError("Something went Wrong!"));
    // }
    // yield put(createOrganisationFailed());
  }
}

function* createNewCategory({ payload: data }) {
  try {
    const accessToken = yield fireBaseBackend.getAccessToken();
    const response = yield axios({
      method: "POST",
      url: `create-new-category`,
      headers: { authorization: `Bearer ${accessToken}` },
      data,
    });
    if (response.data.success) {
      yield put(createCategorySuccess(response.data.result));
    } else {
      yield put(createCategoryFailed(response.data.error));
      // yield put(createOrganisationFailed());
    }
  } catch (error) {
    yield put(setShowApiErrorModal());
    console.log(error);
    // if (error.response && error.response.data && error.response.data.error) {
    //     yield put(loginError(error.response.data.error));
    // } else {
    //     yield put(loginError("Something went Wrong!"));
    // }
    // yield put(createOrganisationFailed());
  }
}

function* updateCategory({ payload: data }) {
  try {
    const accessToken = yield fireBaseBackend.getAccessToken();
    const response = yield axios({
      method: "POST",
      url: `update-category`,
      headers: { authorization: `Bearer ${accessToken}` },
      data,
    });
    if (response.data.success) {
      yield put(updateCategorySuccess(response.data.result));
    } else {
      yield put(createCategoryFailed(response.data.error));
      // yield put(createOrganisationFailed());
    }
  } catch (error) {
    yield put(setShowApiErrorModal());
    // if (error.response && error.response.data && error.response.data.error) {
    //     yield put(loginError(error.response.data.error));
    // } else {
    //     yield put(loginError("Something went Wrong!"));
    // }
    // yield put(createOrganisationFailed());
  }
}

function* updateServiceActiveKey({ payload: data }) {
  try {
    const accessToken = yield fireBaseBackend.getAccessToken();
    const response = yield axios({
      method: "POST",
      url: `update-service-key`,
      headers: { authorization: `Bearer ${accessToken}` },
      data,
    });
    if (response.data.success) {
      yield put(updateServiceActiveKeySuccess(response.data.result));
      yield call(getServicesByCategory);
    }
  } catch (error) {
    yield put(setShowApiErrorModal());
  }
}

function* getServicesByCategory() {
  try {
    const accessToken = yield fireBaseBackend.getAccessToken();
    const response = yield axios({
      method: "GET",
      url: `services-by-category`,
      headers: { authorization: `Bearer ${accessToken}` },
    });
    if (response.data.success) {
      yield put(getServicesByCategorySuccess(response.data.result));
    } else {
      // yield put(createOrganisationFailed());
    }
  } catch (error) {
    yield put(setShowApiErrorModal());
    // if (error.response && error.response.data && error.response.data.error) {
    //     yield put(loginError(error.response.data.error));
    // } else {
    //     yield put(loginError("Something went Wrong!"));
    // }
    // yield put(createOrganisationFailed());
  }
}

function* getGlobalPricingDrivers({ payload: type }) {
  try {
    const accessToken = yield fireBaseBackend.getAccessToken();
    const response = yield axios({
      method: "GET",
      url: `global-pricing-drivers?type=${type}`,
      headers: { authorization: `Bearer ${accessToken}` },
    });
    if (response.data.success) {
      yield put(getGlobalPricingDriversSuccess(response.data.result));
    } else {
      // yield put(createOrganisationFailed());
    }
  } catch (error) {
    yield put(setShowApiErrorModal());
    console.log(error);
    // if (error.response && error.response.data && error.response.data.error) {
    //     yield put(loginError(error.response.data.error));
    // } else {
    //     yield put(loginError("Something went Wrong!"));
    // }
    // yield put(createOrganisationFailed());
  }
}

function* getGlobalPricingDriver({ payload: id }) {
  try {
    const accessToken = yield fireBaseBackend.getAccessToken();
    const response = yield axios({
      method: "GET",
      url: `global-pricing-driver/${id}`,
      headers: { authorization: `Bearer ${accessToken}` },
    });
    if (response.data.success) {
      yield put(
        getGlobalPricingDriverSuccess(
          response.data.result,
          response.data.linkedServices
        )
      );
    } else {
      // yield put(createOrganisationFailed());
    }
  } catch (error) {
    yield put(setShowApiErrorModal());
    console.log(error);
    // if (error.response && error.response.data && error.response.data.error) {
    //     yield put(loginError(error.response.data.error));
    // } else {
    //     yield put(loginError("Something went Wrong!"));
    // }
    // yield put(createOrganisationFailed());
  }
}

function* submitGlobalPricingDriver({ payload: { driverData, type } }) {
  try {
    const accessToken = yield fireBaseBackend.getAccessToken();
    let url;
    switch (type) {
      case "create":
        url = "create-global-pricing-driver";
        break;
      case "edit":
        url = "update-global-pricing-driver";
        break;
      default:
        break;
    }
    const response = yield axios({
      method: "POST",
      url,
      headers: { authorization: `Bearer ${accessToken}` },
      data: driverData,
    });
    if (response.data.success) {
      yield put(submitGlobalPricingDriverSuccess(response.data.result));
    } else {
      yield put(createGlobalDeriverFailed(response.data.error));
      
      // yield put(createOrganisationFailed());
    }
  } catch (error) {
    yield put(setShowApiErrorModal());
    console.log(error);
    // if (error.response && error.response.data && error.response.data.error) {
    //     yield put(loginError(error.response.data.error));
    // } else {
    //     yield put(loginError("Something went Wrong!"));
    // }
    // yield put(createOrganisationFailed());
  }
}

function* getGlobalDriversByServices({ payload: services }) {
  try {
    const accessToken = yield fireBaseBackend.getAccessToken();
    const response = yield axios({
      method: "POST",
      url: `global-drivers-by-services`,
      headers: { authorization: `Bearer ${accessToken}` },
      data: services,
    });
    if (response.data.success) {
      yield put(getGlobalDriversByServicesSuccess(response.data.result));
    } else {
      // yield put(createOrganisationFailed());
    }
  } catch (error) {
    yield put(setShowApiErrorModal());
    console.log(error);
    // if (error.response && error.response.data && error.response.data.error) {
    //     yield put(loginError(error.response.data.error));
    // } else {
    //     yield put(loginError("Something went Wrong!"));
    // }
    // yield put(createOrganisationFailed());
  }
}

function* submitGlobalConstant({ payload: { driverData, type } }) {
  try {
    const accessToken = yield fireBaseBackend.getAccessToken();
    let url;
    switch (type) {
      case "create":
        url = "create-global-constant";
        break;
      case "edit":
        url = "update-global-constant";
        break;
      default:
        break;
    }
    const response = yield axios({
      method: "POST",
      url,
      headers: { authorization: `Bearer ${accessToken}` },
      data: driverData,
    });
    if (response.data.success) {
      yield put(submitGlobalConstantSuccess(response.data.result));
    } else {
      yield put(submitGlobalConstantFail(response.data.result));
      // yield put(createOrganisationFailed());
    }
  } catch (error) {
    yield put(setShowApiErrorModal());
    console.log(error);
  }
}

export function* watchGetService() {
  yield takeEvery(GET_SERVICE, getService);
}

export function* watchGetCategories() {
  yield takeEvery(GET_CATEGORIES, getCategories);
}

export function* watchCreateService() {
  yield takeEvery(SUBMIT_SERVICE, submitService);
}

export function* watchCreateCategory() {
  yield takeEvery(CREATE_NEW_CATEGORY, createNewCategory);
}

export function* watchUpdateCategory() {
  yield takeEvery(UPDATE_CATEGORY, updateCategory);
}

export function* watchUpdateServiceActiveKey() {
  yield takeEvery(UPDATE_SERVICE_ACTIVE_KEY, updateServiceActiveKey);
}

export function* watchGetServicesByCategory() {
  yield takeEvery(GET_SERVICES_BY_CATEGORY, getServicesByCategory);
}

export function* watchGetGlobalPricingDrivers() {
  yield takeEvery(GET_GLOBAL_PRICING_DRIVERS, getGlobalPricingDrivers);
}

export function* watchGetGlobalPricingDriver() {
  yield takeEvery(GET_GLOBAL_PRICING_DRIVER, getGlobalPricingDriver);
}

export function* watchSubmitGlobalPricingDriver() {
  yield takeEvery(SUBMIT_GLOBAL_PRICING_DRIVER, submitGlobalPricingDriver);
}

export function* watchGetGlobalDriversByServices() {
  yield takeEvery(GET_GLOBAL_DRIVERS_BY_SERVICES, getGlobalDriversByServices);
}
// new
export function* watchSubmitGlobalConstant() {
  yield takeEvery(SUBMIT_GLOBAL_CONSTANT, submitGlobalConstant);
}

function* serviceSaga() {
  yield all([
    fork(watchGetService),
    fork(watchGetCategories),
    fork(watchCreateService),
    fork(watchCreateCategory),
    fork(watchUpdateCategory),
    fork(watchUpdateServiceActiveKey),
    fork(watchGetServicesByCategory),
    fork(watchGetGlobalPricingDrivers),
    fork(watchGetGlobalPricingDriver),
    fork(watchSubmitGlobalPricingDriver),
    fork(watchGetGlobalDriversByServices),

    fork(watchSubmitGlobalConstant),
  ]);
}

export default serviceSaga;