import React, { Component } from 'react';
import { connect } from "react-redux";
import { setShowCreateClientModal, getClient, getClients } from "../store/client/actions";
import Paginator from "./Paginator";
import { withRouter } from "react-router-dom";

class ClientsTable extends Component {
	constructor(props) {
		super(props)
		this.renderClients = this.renderClients.bind(this)
		// this.pagination = this.pagination.bind(this)
		
	}
	Capitalize(str){
		return str.charAt(0).toUpperCase() + str.slice(1);
		}
		
	renderClients(item, index) {
		return (
			<tr key={index}>
				{
					item.displayName ?
						<td className="client-item">{item.displayName}</td> :
						null
				}


				<td className="client-item">{this.Capitalize(item.businessType)}</td>
				{/*<td className="client-item">{item.company_name}</td>
			<td className="client-item">{item.email}</td>
			<td className="client-item">{item.phone}</td>*/}
				<td className="text-right">
					<button onClick={() => { this.props.getClient(item._id); this.props.setShowCreateClientModal(true, "edit", item._id, index); }} data-service-id="id-here" data-toggle="tooltip" title="Edit Prospect" disabled className="btn btn-sm btn-dark"><i className="ion ion-md-create" /></button>
					<button onClick={() => this.props.history.push(`/clients/${item._id}`)} data-toggle="tooltip" title="View Prospect" className="btn btn-sm btn-dark ml-2">View<i className="ion ion-md-eye ml-1" /></button>
					{/*<button data-toggle="tooltip" title="Clone Service" className="btn btn-sm btn-dark ml-2"><i className="ion ion-ios-copy" /></button>*/}
					<button onClick={() => this.props.delete(true, item._id, "client")} data-toggle="tooltip" disabled title="Delete Prospect" className="btn btn-sm btn-dark ml-2"><i className="ion ion-md-trash" /></button>
				</td>
			</tr>
		);
	}

	render() {
		return (
			this.props.clients.length > 0 ? (<div className="table-rep-plugin">
				<div className="table-responsive mb-0" data-pattern="priority-columns">
					<table className="table table-striped">
						<thead>
							<tr>
								<th>Prospects Name</th>
								<th>Business Type</th>
								{/*<th>Company Name</th>
			  			<th>Email</th>
			  			<th>Phone</th>*/}
								<th></th>
							</tr>
						</thead>
						<tbody>
							{this.props.clients.map(this.renderClients)}
						</tbody>
					</table>
					<Paginator
						totalPages={this.props.pagination.total_pages}
						pageNum={this.props.pagination.page_num}
						getObjects={this.props.getClients}
					/>
				</div>
			</div>) : (<div className="text-center">No Prospects Found!</div>)
		);
	}
}

export default withRouter(connect(null, { setShowCreateClientModal, getClient, getClients })(ClientsTable));
